import PartnerEnrollmentForm from "../components/partnerEnrollment"

function PartnerEnrollment() {

    return(
        <PartnerEnrollmentForm/>
    )

}

export default PartnerEnrollment