import { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import AppProgressBar from "../../Common/progressBar";
import { US_STATES } from "../../Common/States";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { hideFormatSSN, formatZipcode } from "../../functions/misc";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { GetProfile } from "../../queries/getProfile";
import { GetProfilePrimaryAddress } from "../../queries/GetProfilePrimaryAddress";
import { useNavigate } from "react-router-dom";
import { UpdateProfile } from "../../queries/updateProfile";
import { UpsertProfilePrimaryAddress } from "../../queries/UpsertProfilePrimaryAddress";
import { createEnrollmentAction } from "../../queries/CreateEnrollmentAction";
import Message from "../Message";
import Loading from "../../Common/Loading";
import momentTz from "moment-timezone";
import { AnimatePresence, motion } from 'framer-motion';
import { motionContainer } from "./index";

function PersonalInformation({ progressPercent = 0 }) {
  const [validated, setValidated] = useState(false);
  const [socialSecurity, SetSocialSecurity] = useState("");
  const [hideSocialSecurity, SetHideSocialSecurity] = useState("");

  const { next, enrollmentId, setNextFormId, setS3Bucket, setS3Key } =
    useContext(OrganicClientFormContext);
  const navigate = useNavigate();
  const [DOB, setDOB] = useState({ date: "", month: 0, year: 0 });
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [personInfo, setPersonInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState({});
  const [error, setError] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [reviewInformation, setReviewInformation] = useState(true);
  const client = new clientConfig();
  const months = moment.months();

  let years = [];

  let currentYear = new Date().getFullYear();

  const momentTz = require('moment-timezone');
  const pstDate = momentTz().tz("America/Los_Angeles");
  const pstCurrentDate = pstDate.year(pstDate.year() - 18);

  let startYear = currentYear - 100;
  startYear = startYear || 1980;
  while (startYear <= currentYear - 18) {
    years.push(startYear++);
  }

  useEffect(() => {
    getProfile();
    getProfilePrimaryAddress();
  }, []);

  const setDateOfBirth = (e: any) => {
    setDOB((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "date"
            ? e.target.value
            : e.target.value.replace(/[^\d*]/g, ""),
      };
    });
    setError("");
  };

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
  };

  const getProfile = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setPersonInfo(res.data.GetProfile);
          if (res.data.GetProfile.Birthdate !== null) {
            const birthDate1 = new Date(res.data.GetProfile.Birthdate);
            const birthDate = new Date(
              birthDate1.valueOf() + birthDate1.getTimezoneOffset() * 60 * 1000
            );
            setDOB({
              month: birthDate.getMonth() + 1,
              date: birthDate.getDate().toString(),
              year: birthDate.getFullYear(),
            });
            SetSocialSecurity(hideFormatSSN(res.data.GetProfile.Ssn));
            SetHideSocialSecurity(res.data.GetProfile.Ssn || "");
          }
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddressInfo(res.data.GetProfilePrimaryAddress);
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
        } else {
         setReviewInformation(false);
        }
        setSpinner(false);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const getmonth = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };
  const submitInfo = async () => {
    if (DOB.date === "" || DOB.month === 0 || DOB.year === 0) {
      setError("Enter Date of Birth Correctly");
      return;
    }

    let Birthdate =
      DOB.date.toString().length === 1 ? `0${DOB.date}` : DOB.date;
    let BirthMonth = getmonth(DOB.month);
    let fullDob = `${DOB.year}-${BirthMonth}-${Birthdate}`;
    let date = moment(`${DOB.year}-${BirthMonth}-${Birthdate}`);

    if (date.format("YYYY-MM-DD") > pstCurrentDate.format("YYYY-MM-DD")) {
      setError("You must be 18 years of age or older.");
      return;
    }

    if (date.isValid()) {
      const profileId = await userProfileData();
      const variables = {
        ...personInfo,
        Id: profileId,
        Birthdate: fullDob,
        Ssn: hideSocialSecurity.replaceAll("-", ""),
      };
      client
        .mutate({ mutation: UpdateProfile, variables: variables })
        .then((res: any) => {
          if (res.data.UpdateProfile) {
            submitAddress();
          }
        })
        .catch((error: any) => {
          setSpinner(false);
        });
    } else {
      setError("Enter Date of Birth Correctly");
      return;
    }
  };
  const makeSocialSecurity = (e: any) => {
   
    let temp = hideSocialSecurity.replace(
      hideSocialSecurity.substring(0, 5),
      "*".repeat(hideSocialSecurity.substring(0, 5).length)
    );
    var currentValue = e.target.value.replace(/[^\d*]/g, "");
    var finalValue = "";
    if (hideSocialSecurity.length < currentValue.length) {
      finalValue = hideSocialSecurity
        .concat(currentValue.replace(temp, ""))
        .replace(/[^\d]/g, "");
    } else {
      finalValue = hideSocialSecurity.substring(0, currentValue.length);
    }
    if(finalValue.length===10){
      finalValue= finalValue.substring(0,9)
    }
    SetHideSocialSecurity(finalValue);
    SetSocialSecurity(hideFormatSSN(finalValue));
  };

  const submitAddress = async () => {
    const profileId = await userProfileData();
    const variables = {
      ...addressInfo,
      ProfileId: profileId,
      State: address.state,
      City: address.city,
      StreetAddress1: address.streetAddress1,
      ZipCode: address.zipCode,
      StreetAddress2: address.streetAddress2 ? address.streetAddress2 : null,
    };
    client
      .mutate({ mutation: UpsertProfilePrimaryAddress, variables: variables })
      .then((res: any) => {
        if (res.data.UpsertProfilePrimaryAddress) {
          createEnrollment();
        }
      })
      .catch((error: any) => {
        setSpinner(false);
      });
  };

  const createEnrollment = async () => {
    const profileId = await userProfileData();
    const variables = {
      EnrollmentId: enrollmentId,
      Name: "InformationCompleted",
      EnrollmentProfiles: [
        {
          EnrollmentId: enrollmentId,
          ProfileId: profileId,
          Owner: true
        }
      ]
    };

    client
      .mutate({ mutation: createEnrollmentAction, variables: variables })
      .then((res: any) => {
        if (res.data.CreateEnrollmentAction) {
          setNextFormId(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.Id
          );
          setS3Key(res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Key);
          setS3Bucket(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Bucket
          );
          setSpinner(false);
          next();
        }
      })
      .catch((error: any) => {
        setSpinner(false);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSpinner(true);
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitInfo();
    } else {
      setSpinner(false);
    }

    setValidated(true);
  };

  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <motion.div key={spinner.toString()} {...motionContainer}>
            {spinner ? (
                <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading">
                 {reviewInformation ? "Review Your Information" : "Personal Information"}
                </h1>
                <p className="content-text mb-3">
                 {reviewInformation ? "Please confirm the information provided below." :
                  "We are committed to providing a safe and secure environment. We safeguard your info by encrypting" +
                  " it while we store it in our system. We use SSL encryption when sending your tax returns to the" +
                  " IRS or state agency."}
                </p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3 justify-content-between">
                    <Col>
                      <Row>
                        <Col md={4}>
                          <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label>Month of Birth</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="month"
                              onChange={(e) => setDateOfBirth(e)}
                              value={DOB.month}
                              required
                            >
                              <option value="">-</option>
                              {months.map((month, index) => {
                                return (
                                  <option value={index + 1}>{month}</option>
                                );
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Month of Birth.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group as={Col} controlId="validationCustom02">
                            <Form.Label>Day Of Birth</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              maxLength={2}
                              name="date"
                              onChange={(e) => setDateOfBirth(e)}
                              value={DOB.date}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Day of Birth.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group as={Col} controlId="validationCustom03">
                            <Form.Label>Year of Birth</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="year"
                              onChange={(e) => setDateOfBirth(e)}
                              value={DOB.year}
                              required
                            >
                              <option value="">- </option>
                              {years.map((year) => {
                                return <option value={year}>{year}</option>;
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Year of Birth.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      {error && (
                        <Message message={error} type="error"></Message>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group as={Col} controlId="validationCustom04">
                        <Form.Label>Social Security Number</Form.Label>
                        <Form.Control
                          type="text"
                          pattern=".{11,11}"
                          placeholder=""
                          value={socialSecurity}
                          onChange={(e) => {
                            makeSocialSecurity(e);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Social Security Number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} className="mb-3">
                      <label>
                        Please confirm that your address matches the one on your
                        most recent tax return or IRS notice.
                      </label>
                      <Form.Group as={Col} controlId="validationCustom05">
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="123 Main Street Ave."
                          className="form-control-personal"
                          value={address.streetAddress1}
                          name="streetAddress1"
                          onChange={(e) => setAddressData(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Street Address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="">
                        <Form.Label>Street Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Apt, suite, unit (optional)"
                          className="form-control-personal"
                          value={address.streetAddress2}
                          name="streetAddress2"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={5}>
                      <Form.Group controlId="">
                        <Form.Label>City </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.city}
                          name="city"
                          onChange={(e) => setAddressData(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid City.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={address.state}
                          name="state"
                          onChange={(e) => setAddressData(e)}
                          required
                        >
                          <option value="">- </option>
                          {US_STATES.map((state) => {
                            return (
                              <option value={state.abbreviation}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid State.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group controlId="">
                        <Form.Label>ZIP Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.zipCode}
                          name="zipCode"
                          onChange={(e) => setAddressData(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Zip code.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between mt-5 align-items-center">
                    <span
                      className="letsdolater"
                      onClick={() => navigate("/dashboard")}
                    >
                      Lets do this later...
                    </span>
                    <button
                      type="submit"
                      className="btn-continue"
                      disabled={spinner}
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
            )}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </AnimatePresence>
  );
}

export default PersonalInformation;
