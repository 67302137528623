import gql from "graphql-tag";

export const createProfile = gql`
  mutation createProfile(
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $middleName: String
    $phone: AWSPhone!
    $primaryCognitoUserId: String
    $primaryUserPoolId: String
    $parentId: ID
    $partnerId: ID
    $CallEnabled: Boolean
    $ConsentToShare: Boolean
    $SmsEnabled: Boolean
    $Ad: String
    $AdGroup: String
    $Network: String
    $ReferralCode: String
    $UtmCampaign: String
    $UtmSource: String
    $accountId: ID
  ) {
    CreateProfile(
      Input: {
        Email: $email
        FirstName: $firstName
        LastName: $lastName
        MiddleName: $middleName
        Phone: $phone
        PrimaryCognitoUserId: $primaryCognitoUserId
        PrimaryUserPoolId: $primaryUserPoolId
        ParentId: $parentId
        PartnerId: $partnerId
        CallEnabled: $CallEnabled
        SmsEnabled: $SmsEnabled
        ConsentToShare: $ConsentToShare
        AccountId: $accountId
      }
      SignUpParametersInput: {
        Ad: $Ad
        AdGroup: $AdGroup
        Network: $Network
        ReferralCode: $ReferralCode
        UtmCampaign: $UtmCampaign
        UtmSource: $UtmSource
      }
    ) {
      MiddleName
      Active
      Birthdate
      CreatedAt
      Email
      FirstName
      Id
      LastName
      MiddleName
      Phone
      PrimaryCognitoUserId
      PrimaryUserPoolId
      Ssn
      UpdatedAt
      ParentId
      PartnerId
      AccountId
    }
  }
`;
