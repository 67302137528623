import gql from "graphql-tag";

export const GetAppointmentsByProfileId = gql`
  query GetAppointmentsByProfileId($ProfileId: ID!) {
    GetAppointmentsByProfileId(ProfileId: $ProfileId) {
      AgentId
      AppointmentId
      EndsAt
      ProfileId
      StartAtDisplayDate
      StartAtDisplayTime
      StartsAt
      TimeZone
      TimeZoneShort
      Topic
    }
  }
`;
