import { Card, Col, Row } from "react-bootstrap";
import '../requestcenter.css';
import { DependentModel, InitialDependentModel } from "./TaxOrganizerContext";
import { ReactComponent as EditIcon} from '../../../assets/edit-icon.svg';

const DependentsCard:React.FC<{dependent?: DependentModel, 
        onAction:(actionType: string, dependent:DependentModel) => void}> = (props) => {
    return (
        <Card className="text-left border-0 light-bg w-100">
            <Card.Body className="px-lg-3">
                <div style={{textAlign: 'left'}}>

                <h6 className="ff-Montserrat fw-bold mb-3">
                    {props.dependent? `${props.dependent.firstName} ${props.dependent.lastName}`:'Add New Dependent'}
                </h6>
                {props.dependent && 
                    <>
                    <Row>
                        <Col lg={6} md={6} style={{textAlign: 'left'}}>{'DOB:'}</Col>
                        <Col lg={6} md={6} style={{textAlign: 'right'}}>{'##/##/####'}</Col>
                    </Row>
                    <Row>
                        <Col lg={5} md={5} style={{textAlign: 'left'}}>{'SSN:'}</Col>
                        <Col lg={7} md={7} style={{textAlign: 'right'}}>{'###-###-####'}</Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} style={{textAlign: 'left'}}>{'Relationship:'}</Col>
                        <Col lg={6} md={6} style={{textAlign: 'right'}}>{props.dependent?.relationship}</Col>
                    </Row>
                    </>
                }
                {!props.dependent && 
                    <span className="fw-8">{'Click the button below to add a new dependent'}</span>
                }
                </div>
            </Card.Body>
            <Card.Footer className="cardfooter" onClick={() => props.onAction(props.dependent?'edit':'new' 
                                ,props.dependent?props.dependent:InitialDependentModel)}>
                {props.dependent && 
                    <span className="fs-8"><EditIcon /> {'  Edit Information'}</span>
                }
                {!props.dependent && 
                    <span className="fs-8">{'+ADD Dependent'}</span>
                }
            </Card.Footer>
        </Card>
    )
}

export default DependentsCard;