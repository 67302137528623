import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import Table from "react-bootstrap/Table";
import { FaFilePdf } from "@react-icons/all-files/fa/FaFilePdf";
import { FaSearchPlus } from "@react-icons/all-files/fa/FaSearchPlus";
import { FaFileImage } from "@react-icons/all-files/fa/FaFileImage";
import { Badge } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { clientConfig } from "../../awsClientConfig";
import { Auth } from "aws-amplify";
import { userProfileData } from "../../Common/profileInfo";
import { GetLatestFormsByPartner } from "../../queries/GetLatestFormsByPartner";
import { FaSortAmountUpAlt } from "@react-icons/all-files/fa/FaSortAmountUpAlt";
import { FaSortAmountDown } from "@react-icons/all-files/fa/FaSortAmountDown";
import { SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Loading from "../../Common/Loading";
import onFile from "../../assets/Form-8821-On-File-Flag.svg";

const TableHeader = [
  {
    name: "File",
    key: "Name",
    sort: "asc",
  },
  {
    name: "Last Updated",
    key: "UpdatedAt",
    sort: "asc",
  },
];

function W9Form({ form, setSelectedForm }: any) {
  const { S3Key, S3Bucket } = form;
  const navigate = useNavigate();

  const [agreement, setAgreement] = useState("");

  const isPDF = S3Key?.includes(".pdf");

  useEffect(() => {
    fetchAgreement();
  }, []);

  let contentType = isPDF ? "application/pdf" : "text/html";
  const fetchAgreement = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const serviceAgreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        // tagging: 'ExternalClearance=Profile',
        contentType: contentType,
      });
      setAgreement(serviceAgreement);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  function printImage() {
  

    fetch(agreement)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf", 
      });
      downloadBlob(Agreement.Body, "W-9 Form");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Account Settings</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>Documents</Breadcrumb.Item>
              <Breadcrumb.Item active>W-9 Form</Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24, display: "flex",
    justifyContent: "space-between" }}>
              W-9 Form
            <img src={onFile} alt="onFlie" /></h1>
            <div className="text-end">
                <span className="letsdolater" onClick={printImage}>
                  <FontAwesomeIcon className="" icon={faPrint} /> Print
                </span>
                <span
                  style={{ marginLeft: "10px" }}
                  className="letsdolater"
                  onClick={download}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span>
              </div>
            <div style={{ height: 500, marginTop: 10 }}>
              <iframe
                src={agreement}
                height="100%"
                width="100%"
                title="Agreement"
              ></iframe>
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button  className="btn-continue" onClick={() => setSelectedForm({})}>
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function PartnerServiceAgreement({form, setSelectedForm}: any) {
  const { S3Key, S3Bucket } = form;
  const [irsForm, setIRSForm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

 

  function printImage() {
    

    fetch(irsForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Account Settings</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>Documents</Breadcrumb.Item>
              <Breadcrumb.Item active>Partner Agreement</Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>Partner Agreement</h1>

            <div className="text-end">
                <span className="letsdolater" onClick={printImage}>
                  <FontAwesomeIcon className="" icon={faPrint} /> Print
                </span>
                <span
                  style={{ marginLeft: "10px" }}
                  className="letsdolater"
                  onClick={download}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span>
              </div>  

            <div style={{ marginTop: 10, height: 500}}>
              <iframe
                src={irsForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button onClick={() =>setSelectedForm({})}  className="btn-continue">
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function PartnerDocuments() {
  const client = new clientConfig();
  const [sortHeaders, setSortHeaders] = useState(TableHeader);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const [selectedForm, setSelectedForm] = useState<any>({});

  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true)
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const partnerId = await userProfileData();
      client
        .query({
          query: GetLatestFormsByPartner,
          variables: {
            PartnerId: partnerId,
          },
        })
        .then((res: any) => {
          setLoading(false)
          setForms(res.data.GetLatestFormsByPartner);
        }).catch(() => {
          setLoading(false)
        });
    };

    fetchApi();
  }, []);

  const onSort = (key: string, sortMethod: "asc" | "desc") => {
    const headers = [...sortHeaders];

    headers.forEach((header) => {
      if (header.key === key) {
        header.sort = header.sort === "asc" ? "desc" : "asc";
      }
    });

    const sortForms = orderBy(forms || [], [key], [sortMethod]);
    setForms(sortForms);
    setSortHeaders(headers);
  };

  const onFormClick = (form: any) => {
    setSelectedForm(form);
  };

  if (selectedForm.Name === "FormW9") {
    return <W9Form form={selectedForm} setSelectedForm={setSelectedForm}/>;
  }

  if (selectedForm.Name === "PartnerServiceAgreement") {
    return <PartnerServiceAgreement form={selectedForm} setSelectedForm={setSelectedForm} />;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Account Settings</Breadcrumb.Item>

              <Breadcrumb.Item active>Documents</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="align-items-center">
              <Col lg={9}>
                <h1 className="text-main-dark mb-3 ff-Montserrat fs-4 fw-bold">
                  Documents
                </h1>
              </Col>
            </Row>
            <Card
              style={{
                backgroundColor: "#FAFCFD",
                border: "1px solid #E2E7EA",
              }}
            >
              {loading ? <Loading /> :<Table hover className="myReferral">
                <thead>
                  <tr className="referral-tabel">
                    {sortHeaders.map((header: any) => {
                      return (
                        <th>
                          {header.name}
                          {header.sort === "asc" ? (
                            <span
                              style={{ marginLeft: 5 }}
                              onClick={() => onSort(header.key, "asc")}
                            >
                              <FaSortAmountUpAlt />
                            </span>
                          ) : (
                            <span
                              style={{ marginLeft: 5 }}
                              onClick={() => onSort(header.key, "desc")}
                            >
                              <FaSortAmountDown></FaSortAmountDown>
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {forms.map((data: any) => (
                    <tr>
                      <td className="py-3">
                        <span
                          onClick={() => onFormClick(data)}
                          style={{ cursor: "pointer" }}
                          className="material-blue"
                        >{`${data.Name}`}</span>
                      </td>
                      <td className="py-3">
                        {data.UpdatedAt
                          ? moment(data.UpdatedAt).format("MM/DD/YYYY")
                          : "-"}
                      </td>
                      <td className="py-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <span onClick={() => onFormClick(data)}
                          style={{ cursor: "pointer" }} className="material-blue ms-2">
                            <FaSearchPlus />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerDocuments;
