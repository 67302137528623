import { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppProgressBar from "../../Common/progressBar";
import ReferalClientFormContext from "../referalClient/ReferalClientFormContext";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { createEnrollmentAction } from "../../queries/CreateEnrollmentAction";
import { v4 as uuidv4 } from "uuid";
import { userAccountData, userProfileData } from "../../Common/profileInfo";
import { useNavigate } from "react-router-dom";
import DragDropFiles from "../DragDropFiles";
import Message from "../Message";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import Loading from "../../Common/Loading";

function GovermentPhotoId({ progressPercent = 0 }) {
  const { next, enrollmentId, setNextFormId, setS3Bucket, setS3Key } =
    useContext(ReferalClientFormContext);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const client = new clientConfig();
  const [images, setimages] = useState<any>([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadImages();
  }, []);

  const handleChange = async (file: any) => {
    if (images.length === 3) {
      setError("Only 3 Images are allowed");
      return;
    }
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    setShow(true);
    const extension = getExtension(file.name);
    const ispdf = isPdf(file.name);
    let contentType = ispdf ? "application/pdf" : "image/png";

    const profileId = await userProfileData();
    const accountId = await userAccountData();
    const uuid = uuidv4();

    try {
      await Storage.put(
        `Accounts/${accountId}/Profiles/${profileId}/Identifications/${uuid}.${extension}`,
        file,
        {
          contentType: contentType, // contentType is optional
          customPrefix: { public: "" },
          tagging: "ExternalClearance=Profile",
        }
      ).then((res) => {
        console.log(res);
        loadImages();
        setShow(false);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const createEnrollment = () => {
    setButtonDisable(true);
    const variables = {
      EnrollmentId: enrollmentId,
      Name: "IdentificationUploaded",
    };

    client
      .mutate({ mutation: createEnrollmentAction, variables: variables })
      .then((res: any) => {
        if (res.data.CreateEnrollmentAction) {
          setNextFormId(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.Id
          );
          setS3Key(res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Key);
          setS3Bucket(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Bucket
          );
          next();
          setButtonDisable(false);
        }
      })
      .catch((error: any) => {
        setButtonDisable(false);
      });
  };

  const handleRemoveImage = async (imageUrl: string) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    try {
      await Storage.remove(imageUrl, {
        // contentType: "image/png", // contentType is optional
        customPrefix: { public: "" },
        level: "public",
        // tagging: "ExternalClearance=Profile",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const loadImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    const accountId = await userAccountData();
    try {
      await Storage.list(
        `Accounts/${accountId}/Profiles/${profileId}/Identifications/`,
        {
          // contentType: "image/png", // contentType is optional
          customPrefix: { public: "" },
          level: "public",
          // tagging: "ExternalClearance=Profile",
        }
      ).then((res) => {
        console.log(res);
        getImages(res.results);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const getImages = async (url: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    let getAllImages: any = [];
    url.forEach((element: any) => {
      getAllImages.push(loadSingleImage(element));
    });
    Promise.all(getAllImages).then((values) => {
      setimages(values);
    });
  };

  const loadSingleImage = async (url: any) => {
    const ispdf = isPdf(url.key);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const imageData: string = await Storage.get(url.key, {
      level: "public",
      contentType: contentType,
      customPrefix: { public: "" },
    });
    let imageObj = {
      src: imageData,
      key: url.key,
    };

    return imageObj;
  };

  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            {buttonDisable ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading">Government Photo ID </h1>
                <p className="content-text">
                  Please upload a picture of your government ID (State Issued
                  Drivers License/ID, or Passport).
                </p>

                <p className="content-text">
                  Please check the following to make sure your document can be
                  processed:
                </p>

                <ul className="content-text">
                  <li>
                    The image is clear and not blurry (including your photo
                    and/or barcode)
                  </li>
                  <li>All information is visible (e.g., expiration date)</li>
                  <li>The document is not captured at an angle</li>
                </ul>

                <DragDropFiles onChange={handleChange} />
                <div>
                  {error && <Message type="error" message={error}></Message>}
                </div>
                {/* <div>
                  {images?.map((image: any) => {
                    return (
                      <div>
                        <img src={image?.src} alt="Govt Id" />
                        <FontAwesomeIcon
                          onClick={() => handleRemoveImage(image?.key)}
                          icon={faRemove}
                        />
                      </div>
                    );
                  })}
                </div> */}

                <div>
                  <div className="col px-lg-2 d-flex py-2">
                    {images?.map((image: any) => {
                      return (
                        <div
                          style={{
                            width: 215,
                            height: 142,
                            objectFit: "cover",
                            borderWidth: 2,
                            borderColor: "#ddd",
                            borderStyle: "solid",
                            textAlign: "center",
                            marginLeft: 10,
                          }}
                        >
                          {isPdf(image?.key) ? (
                            <iframe
                              src={image?.src}
                              title="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          ) : (
                            <img
                              src={image?.src}
                              alt="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          )}
                          <a
                            href="/"
                            className="fw-bold text-decoration-none mt-2 delete-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveImage(image?.key);
                            }}
                          >
                            <FaTrashAlt
                              className="me-1"
                              onClick={() => handleRemoveImage(image?.key)}
                            />
                            Delete
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={() => navigate("/dashboard")}
                  >
                    Lets do this later...
                  </span>
                  <button
                    disabled={images.length <= 0 ? true : buttonDisable}
                    className={
                      images.length <= 0 ? "btn-disable" : "btn-continue"
                    }
                    onClick={createEnrollment}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Modal centered show={show}>
        <Modal.Header className="border-0">
          <FontAwesomeIcon className="modal-close" icon={faCircleXmark} />
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center">Upload in Progress...</h6>
        </Modal.Body>
        <Container className="pb-5">
          <Row>
            <Col>
              {" "}
              <AppProgressBar />
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
}

export default GovermentPhotoId;
