import gql from 'graphql-tag';

export const UpdateProfile = gql`
  mutation UpdateProfile(
    $Id: ID!
    $Active: Boolean!
    $Birthdate: AWSDate
    $FirstName: String!
    $LastName: String!
    $MiddleName: String
    $Phone: AWSPhone!
    $Ssn: String
    $Languages: [Language!]
    $CallEnabled: Boolean
    $SmsEnabled: Boolean
    $ConsentToShare: Boolean
  ) {
    UpdateProfile(
      Input: {
        Id: $Id
        Active: $Active
        FirstName: $FirstName
        LastName: $LastName
        Phone: $Phone
        MiddleName: $MiddleName
        Ssn: $Ssn
        CallEnabled: $CallEnabled
        ConsentToShare: $ConsentToShare
        Languages: $Languages
        SmsEnabled: $SmsEnabled
        Birthdate: $Birthdate
      }
    ) {
      Id
      AccountId
      Birthdate
      Ssn
      ConsentToShare
      CallEnabled
      SelfEnrolled
      SmsEnabled
      Active
      Languages
    }
  }
`;
