import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext from './TaxPreparationServiceFormContext';
import CircleCheck from '../../assets/circle-check-fill-green.svg';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { clientConfig } from '../../awsClientConfig';
import { createEnrollmentAction } from '../../queries/CreateEnrollmentAction';
import { userProfileData } from '../../Common/profileInfo';
import { GetTaxReturnIncome } from '../../queries/GetTaxReturnIncome';
import { GetTaxReturnBusinessEntities } from '../../queries/GetTaxReturnBusinessEntities';
import { CalculatedQuotePrice } from '../../graphql/one-api/subscriptions';
import { US_STATES } from '../../Common/States';
import Loading from "../../Common/Loading";
import { fetchQuery, formatPrice } from "../../functions/misc";
import useEnrollmentStore from "../../store/EnrollmentStore";
import PaymentCompletedMessage from "./PaymentCompletedMessage";
import { GetTaxReturn } from "../../queries/GetTaxReturn";
import { CreateProfileChangeRecord } from "../../queries/CreateProfileChangeRecord";

const ServiceQuoteForm = () => {
    const {taxPreparationData, navigateToPage, completedPagesHandler, enrollmentId, nextEnrollmentAction,quotePrice, quotePriceUpdated, setQuotePriceUpdated, quoteId, setQuotePrice } = useContext(TaxPreparationServiceFormContext);
    const client= new clientConfig();
    const [stateReturnNames, setStateReturnNames] = useState<string[]>([]);
    const [businessNames, setBusinessNames] = useState<string[]>([]);
    const [formattedPrice, setFormattedPrice] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState(true);
    const { taxPrepEnrollment } = useEnrollmentStore();

    useEffect(() => {
      const fetchData = async () => {
        await getTaxReturnIncomeFormData();
        await calculateQuotePrice();
        if(taxPreparationData.filingType !== 'Personal'){
          await getTaxReturnBusinessEntitiesData();
        }
      };
      fetchData();

      const timer = setTimeout(() => {
        if (!formattedPrice) {
          fetchQuoteRecord();
          setQuotePriceUpdated(true);
        }
      }, 5000);
      return () => clearTimeout(timer);
    },[]);

  useEffect(() => {
    if (formattedPrice) {
      setShowSpinner(false);
      setQuotePriceUpdated(true);
    }
  }, [formattedPrice])

    const calculateQuotePrice = async () => {
      const profileId = await userProfileData();
      const subscription = API.graphql(
        graphqlOperation(CalculatedQuotePrice, { ProfileId: profileId, QuoteId: quoteId }),
        {}
        // @ts-ignore:
      ).subscribe({
        next: ({ value }: any) => {
          let price = value.data.CalculatedQuotePrice.QuotePrice;
          setQuotePrice(price);
          setFormattedPrice(formatPrice(parseInt(price)));
        },
        error: (error: any) => {
          console.warn(error);
          setShowSpinner(false);
        }
      });
      if (quotePrice !== null && !quotePriceUpdated) {
        setFormattedPrice(formatPrice(parseInt(quotePrice)));
        setQuotePriceUpdated(true);
        return;
      }
      return () => subscription.unsubscribe();
    }

    const fetchQuoteRecord = async () => {
      const profileId = await userProfileData();
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      const { TaxPrepQuote }: any = await fetchQuery(variables, GetTaxReturn);
      const price = TaxPrepQuote.QuotePrice;
      setQuotePrice(price);
      setFormattedPrice(formatPrice(parseInt(price)));
    }

    const getTaxReturnIncomeFormData = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturnIncome, variables: variables });
        if(res.data.GetTaxReturnIncome){
          const {TaxPrepIncome, TaxPrepStateReturn} = res.data.GetTaxReturnIncome;
          if(TaxPrepIncome && TaxPrepIncome.NeedStateReturn === 'Yes'){
            let returnStates: string[] = [];
            if(TaxPrepStateReturn && TaxPrepStateReturn.length > 0) {
              for(let index = 0; index < TaxPrepStateReturn.length; index++){
                const stateObj = US_STATES.find((state:any) => (state.abbreviation === TaxPrepStateReturn[index].State));
                if(stateObj)
                  returnStates.push(stateObj.name);
              }
              setStateReturnNames(returnStates);
            } 
          }
        }
        }catch(e){
          setShowSpinner(false);
        }
    }

    const getTaxReturnBusinessEntitiesData = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturnBusinessEntities, variables: variables });
        if(res.data.GetTaxReturnBusinessEntities){
          const businessEntities = res.data.GetTaxReturnBusinessEntities.TaxPrepBusinessEntity;
          let names: string[] = [];
            if(businessEntities && businessEntities.length > 0) {
              for(let index = 0; index < businessEntities.length; index++){
                names.push(businessEntities[index].Name);
              }
              setBusinessNames(names);
            } 
        }
      }catch(e){}
    }

    const continueHandler = () => {
      if (taxPrepEnrollment?.paymentCompleted) {
        navigateToPage('serviceAgreement1');
        return;
      }
      const variables = {
        EnrollmentId: enrollmentId,
        Name: "QuoteAccepted",
      };
      client
        .mutate({ mutation: createEnrollmentAction, variables: variables })
        .then((res: any) => {
          if (res.data.CreateEnrollmentAction.Enrollment.Id) {
            nextEnrollmentAction(
              res.data.CreateEnrollmentAction.Enrollment?.NextEnrollmentAction
            );
          }
        })
        .catch((error: any) => {
        });
        completedPagesHandler('serviceAgreement1');
        //navigateToPage('serviceAgreement1');
    }

    const onBackHandler = () => {
      if(taxPreparationData.filingType === 'Personal') {
        navigateToPage('incomeForm1');
      }else {
        navigateToPage('incomeForm2');
      }
    }

    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            {showSpinner ? <Loading /> :
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={35} />
                <Row className="justify-content-center">
                  <Col md={12}>
                    <div className="d-flex justify-content-between mb-3">
                      <h1 className="content-box-heading">
                        {'Service Quote'}
                      </h1>
                      <h1 className="content-box-heading">
                        {'$'+formattedPrice}
                      </h1>
                    </div>
                    {taxPrepEnrollment?.paymentCompleted && <PaymentCompletedMessage />}
                    <p className="content-text" style={{fontSize: '12px'}}>
                      {'After assessing your tax return needs, we will be able to complete your taxes for $'+formattedPrice+'. *Please note that if any of the information changes, the price subject to change based on additional findings, returns, or change in scope of work.'}
                    </p>
                    <Row>
                      <ul className="list_items_align">
                        <li className="protection_plan mb-3">
                          <img
                            src={CircleCheck}
                            className="max-auto d-block"
                            alt="tax-report-ready"
                          />
                          <span className='ms-4 fs-8' >Federal Individual Income Tax Return</span>
                        </li>
                        {stateReturnNames.length > 0 &&
                          <li className="protection_plan mb-3">
                            <img
                              src={CircleCheck}
                              className="max-auto d-block"
                              alt="tax-report-ready"
                            />
                            <span className='ms-4 fs-8'>{`Individual Income Tax Return for ${stateReturnNames.join(', ')}`}</span>
                          </li>
                        }
                        {businessNames.length > 0 &&
                          <>
                            <li className="protection_plan mb-3">
                              <img
                                src={CircleCheck}
                                className="max-auto d-block"
                                alt="tax-report-ready"
                              />
                              <span className='ms-4 fs-8'>{`Business Tax Returns for ${businessNames.join(',')}:`}</span>
                            </li>

                            <ul className="list_items_align">
                              <li className="protection_plan mb-3 ms-5">
                                <span className='ms-4 fs-8'>{'Federal Income Tax Form 1065'}</span>
                              </li>
                              <li className="protection_plan mb-3 ms-5">
                                <span className='ms-4 fs-8'>{'State Income Tax Form for CA'}</span>
                              </li>
                              <li className="protection_plan mb-4 ms-5">
                                <span className='ms-4 fs-8'>{'Payroll Tax Form 941'}</span>
                              </li>
                            </ul>
                          </>
                        }
                        <li className="protection_plan mb-3">
                          <img
                            src={CircleCheck}
                            className="max-auto d-block"
                            alt="tax-report-ready"
                          />
                          <span className='ms-4 fs-8'>{'Connect with live tax experts for worry-free tax preparation'}</span>
                        </li>
                        <li className="protection_plan mb-3">
                          <img
                            src={CircleCheck}
                            className="max-auto d-block"
                            alt="tax-report-ready"
                          />
                          <span className='ms-4 fs-8'>{'Get an expert final review before you file to ensure the best return'}</span>
                        </li>
                        <li className="protection_plan mb-3">
                          <img
                            src={CircleCheck}
                            className="max-auto d-block"
                            alt="tax-report-ready"
                          />
                          <span className='ms-4 fs-8'>{'Enjoy a streamlined process with returns filed digitally, and on time'}</span>
                        </li>
                        <li className="protection_plan">
                          <img
                            src={CircleCheck}
                            className="max-auto d-block"
                            alt="tax-report-ready"
                          />
                          <span className='ms-4 fs-8'>{'$10,000 accuracy guarantee'}</span>
                        </li>
                      </ul>
                    </Row>
                    <Row className="justify-content-start">
                      <div className="d-flex justify-content-between mt-5 align-items-center">
                      <span onClick={onBackHandler}>
                        {" "}
                        <span className="letsdolater">Back</span>
                      </span>
                        <button
                          className={"btn-continue fw-bold"}
                          style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                          onClick={continueHandler}>
                          {taxPrepEnrollment?.paymentCompleted ? "Continue" : "Accept and Pay"}
                        </button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            }
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default ServiceQuoteForm;