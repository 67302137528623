import gql from "graphql-tag";

export const CreateProfileChangeRecord = gql`
 mutation CreateProfileChangeRecord(
    $ProfileId: ID!
    $ChangedField: String!
    $ChangedValue: String
    $ChangedBy: ChangedBy!
) {
  CreateProfileChangeRecord(Input: {
    ProfileId: $ProfileId,
    ChangedField: $ChangedField,
    ChangedValue: $ChangedValue,
    ChangedBy: $ChangedBy
  })
    {
      Id
      ProfileId
      ChangedField
      ChangedValue
      ChangedBy
    }
  }`;

export enum ChangedBy {
  Profile = "Profile",
  LegacySystem = "LegacySystem",
}