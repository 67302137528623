import { FaRegTimesCircle } from '@react-icons/all-files/fa/FaRegTimesCircle';
import React from 'react';
import { Modal } from 'react-bootstrap';

const EnrollmentWarningModal:React.FC<{showEnrollmentWarning: boolean, onClose:() => void}> = (props) => {
    return(
        <Modal centered show={props.showEnrollmentWarning} onHide={props.onClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegTimesCircle onClick={props.onClose} />
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4 w-100">
            <div className="text-center" >
             <h6 className="ff-Montserrat fs-4 mt-0" style={{color: "#1589d2"}}>Enrollment  Currently Unavailable</h6>
             <p className="mt-3 content-text text-left">
             You recently unenrolled from your Protection Plan subscription.<br /><br />
             <b>Good news</b> - you will be able to re-enroll in Optima’s best-in-class protection featuring $10,000,000 Lifetime Audit Protection at the conclusion of your current billing cycle!<br /><br />
             Please come back soon to reinstate your Protection Plan and your peace of mind.
            </p>
            </div>
        </Modal.Body>
      </Modal>
    );
}

export default EnrollmentWarningModal;