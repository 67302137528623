import React, { useEffect, useState } from "react";

import "../../pages/navbar.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import Message from "../../components/Message";

const Col1Software = [
  { lable: "Pro Connect", value: "ProConnect" },
  { lable: "Lacerte Tax", value: "LacerteTax" },
  { lable: "Drake Tax", value: "DrakeTax" },
  { lable: "Tax Act", value: "TaxAct" },
  { lable: "Pro Series", value: "ProSeries" },
  { lable: "Other", value: "Other" },
];

const FORM_ERROR = {
  filingSoftware: "",
};

function TaxSoftware({ taxProfessional, setTaxProfessional, submitted, setSoftwareValidated }: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);
    useEffect(() => {
      const validateForm = () => {
        const error = { ...formErrors };

        if (taxProfessional?.FilingSoftware?.length >= 1) {
          error.filingSoftware = "";
        } else {
          error.filingSoftware = "Tax Returns can not be empty";
        }

        setFromErros(error);
      };

      validateForm();
      const isAnyError = taxProfessional?.FilingSoftware?.length >= 1;
      setSoftwareValidated(isAnyError? true: false)
    }, [taxProfessional]);

    const onChange = (event: any) => {
      const filingSoftware = [...taxProfessional?.FilingSoftware];
      const index = filingSoftware.indexOf(event.target.name);
      if (event.target.checked) {
        filingSoftware.push(event.target.name);
      } else if (!event.target.checked) {
        filingSoftware.splice(index, 1);
      }
      const newTaxProfessional = {
        ...taxProfessional,
        ...{ FilingSoftware: [...filingSoftware] },
      };

      setTaxProfessional(newTaxProfessional);
    };

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
           Tax Software You Use
          </h6>
        </div>

        <Form style={{ marginLeft: 10 }} className="mt-1">
          <Row className="mb-3">
            <Row md={6}>
              {Col1Software.map((software) => {
                const checked = taxProfessional?.FilingSoftware?.includes(
                  software.value
                );
                return (
                  <Form.Check
                    onChange={onChange}
                    name={software.value}
                    type="checkbox"
                    label={software.lable}
                    checked={checked}
                  />
                );
              })}
            </Row>
            {submitted && formErrors.filingSoftware && (
              <Message
                message={formErrors.filingSoftware}
                type="error"
              ></Message>
            )}
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default TaxSoftware;
