import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Message from "../components/Message";
import { AnimatePresence, motion } from "framer-motion";

interface FormError {
  passcode: string;
}
const VerifyCodePage = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const [error, setError] = useState<FormError>({ passcode: "" });
  const [submited, setSubmited] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [verified, setVerified] = useState(false);
  const [isresent, setIsResent] = useState(false);

  useEffect(() => {
    validateForm(passcode);
  }, [passcode]);

  const validateForm = (_passcode: string) => {
    let _error = error;
    if (!_passcode) {
      _error["passcode"] = t("verifyCodePage.error.passcode.empty");
    } else {
      _error["passcode"] = "";
    }
    setError(_error);
    setError({ ...error });
  };

  const handleInputPasscode = (e: any) => {
    setPasscode(e.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);
    if (error.passcode) {
      return;
    }

    Auth.confirmSignUp(email?.toLowerCase() || "", passcode)
      .then((res) => {
        setVerified(true);
      })
      .catch(() => {
        let _error = error;
        _error["passcode"] = t(
          "The passcode you entered was incorrect. Please re-enter the code or request a new passcode."
        );
        setError(_error);
        setError({ ...error });
      });
  };

  const resendPasscode = () => {
    setIsResent(false);
    Auth.resendSignUp(email?.toLowerCase() || "")
      .then((res) => {
        setIsResent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const motionContainer = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
    exit: {opacity: 0, transition: { duration: 0.3, ease: "easeInOut" }}
  };

  return (
    <DefaultLayout>
      <AnimatePresence mode="wait">
        {verified ? (
          <motion.div key="verified" variants={motionContainer} initial="hidden" animate="visible">
            <PageBody>
                <FormTitle>{t("verifyCodePage.verifySuccess")}</FormTitle>
                <Button
                  className="w-100"
                  onClick={(e) => {
                    navigate("/login");
                  }}
                >
                  {t("verifyCodePage.btn.signin")}
                </Button>
            </PageBody>
          </motion.div>
        ) : (
          <form onSubmit={handleSubmit}>
            <motion.div key="verify" variants={motionContainer} initial="hidden" animate="visible">
              <PageBody>
                  <FormTitle>{t("verifyCodePage.title")}</FormTitle>
                  <p className="content-text m-0 p-0">{t("verifyCodePage.enterSentence")}</p>
                  <Email>{email}</Email>
                  {isresent && (
                    <motion.div key="isResent" variants={motionContainer} initial="hidden" animate="visible">
                      <Message
                        message={t("verifyCodePage.resentMessage")}
                        type="success"
                      ></Message>
                    </motion.div>
                  )}
                  <FormGroup className="mb-3 mt-3" id="passcode">
                    <FormLabel>{t("verifyCodePage.passcode")}</FormLabel>
                    <Form.Control
                      value={passcode}
                      placeholder="Passcode"
                      required
                      onChange={handleInputPasscode}
                      className={
                        "input-no-bg " +
                        (submited && error.passcode ? "is-invalid" : "")
                      }
                    />
                    {submited && error.passcode && (
                      <motion.div key="errorPasscode" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                        <Message message={error.passcode} type="error"></Message>
                      </motion.div>
                    )}
                  </FormGroup>

                  <Button className="w-100" type="submit">
                    {t("verifyCodePage.btn.verify")}
                  </Button>

                  <NotAccount>
                    <TextButton onClick={resendPasscode}>
                      {t("verifyCodePage.btn.resend")}{" "}
                    </TextButton>
                  </NotAccount>
              </PageBody>
            </motion.div>
          </form>
        )}
        </AnimatePresence>
    </DefaultLayout>
  );
};

export default VerifyCodePage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

const FormTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-text-default);
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;

const TextButton = styled.span`
  display: inline;
  padding: 4px 18px;
  border: 1px solid #cfd8dc;
  border-radius: 16px;
  background: #cfd8dc;
  letter-spacing: -0.12px;
  color: #4c5360;
  opacity: 1;
  cursor: pointer;
`;

const TermSentence = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 1rem;
`;

const NotAccount = styled.div`
  text-align: center;
  margin-top: 18px;
`;

const Email = styled.label`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;
