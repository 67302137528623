import gql from "graphql-tag";

export const CompleteProfileForm = gql`
  mutation CompleteProfileForm(
    $EnrollmentId: ID
    $FormId: ID!
    $ProfileId: ID!
  ) {
    CompleteProfileForm(
      Input: { FormId: $FormId, ProfileId: $ProfileId }
      EnrollmentId: $EnrollmentId
    ) {
      Active
      Id
      Name
      S3Bucket
      S3Key
      S3VersionId
    }
  }
`;
