import React, { useContext, useState } from "react";
import "./signature.css";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AppProgressBar from "../../Common/progressBar";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { Storage } from "aws-amplify";
import { CompleteProfileForm } from "../../queries/CompleteProfileForm";
import Loading from "../../Common/Loading";
import Message from "../Message";
import { Buffer } from 'buffer';
import { AnimatePresence, motion } from "framer-motion";
import { motionContainer } from "./index";

function Signature({ progressPercent = 0 }) {
  const { next, nextFormId, enrollmentId, setS3Bucket, setS3Key } = useContext(
    OrganicClientFormContext
  );
  const client = new clientConfig();
  const [disable, setDisable] = useState(false);
  const [signEmpty, setSignEmpty] = useState(false);
  const [signaturePending, setSignaturePending] = useState(true);

  var signCanvas: SignatureCanvas | null = null;

  const submitSign = async () => {
    if (signCanvas?.isEmpty()) {
      setSignEmpty(true);
      return;
    }
    setDisable(true);
    let signature: any = signCanvas?.getTrimmedCanvas().toDataURL("image/png");

    const profileId = await userProfileData();

    SetS3Config(
      `signatures-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const buf = Buffer.from(
      signature.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    await Storage.put(`Profiles/${profileId}.png`, buf, {
      contentType: "image/png", // contentType is optional
      customPrefix: { public: "" },
      tagging: "ExternalClearance=Profile",
    });

    const variables = {
      ProfileId: profileId,
      FormId: nextFormId,
      EnrollmentId: enrollmentId,
    };

    client
      .mutate({ mutation: CompleteProfileForm, variables: variables })
      .then((res: any) => {
        if (res.data.CompleteProfileForm) {
          setS3Bucket(res.data.CompleteProfileForm?.S3Bucket);
          setS3Key(res.data.CompleteProfileForm?.S3Key);
          setDisable(false);
          next();
        }
      })
      .catch((error: any) => {
        setDisable(false);
      });
  };

  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <motion.div key={disable.toString()} {...motionContainer}>
            {disable ? (
                <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading mb-3">
                  Create Your Signature
                </h1>
                <SignatureCanvas
                  clearOnResize={false}
                  penColor="black"
                  canvasProps={{ className: "sigCanvas" }}
                  ref={(ref) => {
                    signCanvas = ref;
                  }}
                  onBegin={() => {
                    setSignEmpty(false);
                    setSignaturePending(false);
                  }}
                />
                <label className="signature-text d-block text-center">
                  Please draw your signature in the box above
                </label>
                {signEmpty && (
                  <Message
                    type="error"
                    message="Signature is mandatory"
                  ></Message>
                )}

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <button
                    onClick={() => {
                      signCanvas?.clear();
                      setSignaturePending(true);
                    }}
                    className="btn-continue-outline"
                  >
                    Clear Signature
                  </button>
                 <button
                  onClick={submitSign}
                  className={signaturePending ?
                   "btn-disable" :
                   "btn-continue"}
                  disabled={disable}
                 >
                    Submit Signature
                  </button>
                </div>
              </div>
            )}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </AnimatePresence>
  );
}

export default Signature;
