import { useLocation } from "react-router-dom";
import TaxOrganizer from "../components/RequestCenter/TaxOrganizer";

function TaxOrganizerPage() {
    const location = useLocation();
    return (
      <>
        <TaxOrganizer request={location.state} />
      </>
    );
  }
  
  export default TaxOrganizerPage;