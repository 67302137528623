import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppProgressBar from "../../Common/progressBar";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { formatZipcode } from "../../functions/misc";
import { US_STATES } from "../../Common/States";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfilePrimaryAddress } from "../../queries/GetProfilePrimaryAddress";
import { clientConfig } from "../../awsClientConfig";
import Loading from "../../Common/Loading";
import { Button, InputGroup } from "react-bootstrap";
import { ValidatePromotionCode } from "../../queries/ValidationPromotionCode";
import { AnimatePresence, motion } from "framer-motion";
import { motionContainer } from "./index";

function BillingInformation({ progressPercent = 0 }) {
  const { next, setBillingAddress, promoCode, setPromoCode, setPromotionCodeType } = useContext(OrganicClientFormContext);
  const [validated, setValidated] = useState(false);
  const client = new clientConfig();
  const [loading, setLoading] = useState(true);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [controlAttributes, setControlAttributes] = useState({
    isInvalid: false,
    isValid: false
  });

  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  useEffect(() => {
    getProfilePrimaryAddress();
  },[])

  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
  };

  const submitBillingAddress = () => {
    setBillingAddress(address);
    next();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitBillingAddress();
    }

    setValidated(true);
  };

  const showPromoCodeFields = (event: any) => {
    event.preventDefault();
    setShowPromoCode(true);
  } 
  const validatePromoCode = async () => {
   const profileId = await userProfileData();
    client
      .query({
        query: ValidatePromotionCode,
        variables: { Code: promoCode, Type: 'ProtectionPlanEnrollment', ProfileId: profileId },
      })
      .then((res: any) => {
        if (res.data.ValidatePromotionCode?.Active) {
          setControlAttributes({
            isInvalid: false,
            isValid: true
          });
          setPromotionCodeType('ProtectionPlanEnrollment');
        } else {
          setControlAttributes({
            isInvalid: true,
            isValid: false
          });
        }
      })
      .catch((error: any) => {
       setControlAttributes({
        isInvalid: true,
        isValid: false
       });
        console.log(error, "error");
      });
  }

  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <motion.div key={loading.toString()} {...motionContainer}>
        <Row className="justify-content-center">
        {loading ? (
            <Loading />
          ) : (
          <Col md={10} className="content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">Payment Information</h1>
              <p className="content-text">
                Please provide your billing address
              </p>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group as={Col} controlId="validationCustom01">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress1}
                        name="streetAddress1"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Street Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress2}
                        name="streetAddress2"
                        onChange={(e) => setAddressData(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom02">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.city}
                        name="city"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group as={Col} controlId="validationCustom03">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={address.state}
                        name="state"
                        onChange={(e) => setAddressData(e)}
                        required
                      >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom04">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.zipCode}
                        name="zipCode"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid ZIP Code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-start mt-3 align-items-center">
                    {!showPromoCode &&
                    <a href="" onClick={(event) => showPromoCodeFields(event)} className="showPromoCodeFields" style={{fontSize: '0.9rem'}}>Add Promotional Code</a>
                    }
                    {showPromoCode &&
                    <Form.Group>
                      <Form.Label>Promo Code</Form.Label>
                    <InputGroup>
                      <Form.Control style={{width: '300px', backgroundImage: 'none',
                                    fontWeight: 'Bold', color: controlAttributes.isInvalid?'red':controlAttributes.isValid?'green':'black'}}
                        type="text"
                        placeholder=""
                        value={promoCode}
                        name="promoCode"
                        onChange={(event:any) => setPromoCode(event.target.value)}
                        {...controlAttributes}
                      />
                      {!controlAttributes.isValid &&
                      <Button variant="outline-secondary" onClick={validatePromoCode}>
                        <b>Apply</b>
                      </Button>
                      }
                      <Form.Control.Feedback type="invalid" className="fs-6">
                        This code is invalid.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="valid" className="fs-6">
                        A FREE month of Protection has been added to your account.
                      </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    }
                </div>
                <div className="d-flex justify-content-end mt-5 align-items-center">
                  <button type="submit" className="btn-continue">
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          </Col>
          )}
        </Row>
        </motion.div>
      </Container>
    </AnimatePresence>
  );
}

export default BillingInformation;
