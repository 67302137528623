import gql from "graphql-tag";

export const GetAppointmentAvailability = gql`
  query GetAppointmentAvailability(
    $ZipCode: String
    $AppointmentTopic: AppointmentTopicType!
  ) {
    GetAppointmentAvailability(
      Input: { AppointmentTopic: $AppointmentTopic, ZipCode: $ZipCode }
    ) {
      AppointmentLength
      Date
      AvailableTimes {
        AvailabilityTime
        DisplayTime
        TimeZone
        TimeZoneShort
      }
    }
  }
`;
