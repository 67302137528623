import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { useNavigate } from "react-router-dom";
import { userProfileData } from "../../Common/profileInfo";
import { GetAppointmentsByProfileId } from "../../queries/GetAppointmentsByProfileId";
import { clientConfig } from "../../awsClientConfig";
import { CancelAppointment } from "../../queries/CancelAppointment";
import Loading from "../../Common/Loading";
import { Alert } from "react-bootstrap";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

function AppointmentMade() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState<any>(null);
  const [loadData, setLoadData] = useState(true);
  const [meetError, setMeetError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const client = new clientConfig();
  useEffect(() => {
    fetchMeetings();
  }, []);
  const fetchMeetings = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetAppointmentsByProfileId,
        variables: { ProfileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetAppointmentsByProfileId !== null) {
          setMeetings(res.data.GetAppointmentsByProfileId);
          setLoadData(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
        setLoadData(false);
      });
  };

  const confirmCancel = (meetDetails: any) => {
    setSelectedMeeting(meetDetails);
    handleShow();
  };

  const cancelMeeting = () => {
    handleClose();
    setLoadData(true);
    client
      .mutate({
        mutation: CancelAppointment,
        variables: {
          ProfileId: selectedMeeting.ProfileId,
          AppointmentId: selectedMeeting.AppointmentId,
        },
      })
      .then(() => {
        handleClose();
        fetchMeetings();
      })
      .catch(() => {});
  };

  const checkMeetingAvailable = () => {
    if (meetings.length > 0) {
      setMeetError(
        "Try cancelling the current appointment before scheduling a new one."
      );
      setTimeout(() => {
        setMeetError("");
      }, 5000);
    } else navigate("/schedule-appointment");
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            {loadData ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <h1 className="content-box-heading">Appointments</h1>

                <p className="content-text">
                  Schedule a consultation with one of our Tax Associates to
                  examine your current tax situation, IRS collections risk, and
                  tax relief options.
                </p>

                <div className="d-flex  my-5 align-items-center">
                  <button
                    className="btn-continue"
                    style={{ minWidth: "250px" }}
                    onClick={checkMeetingAvailable}
                  >
                    Schedule An Appointment
                  </button>
                </div>
                {meetError && (
                  <Row className="mb-3">
                    <Col lg={12} className="text-center">
                      <Alert variant="danger">
                        <FaCheckCircle className="text-error me-2 fs-6" />
                        {meetError}
                      </Alert>
                    </Col>
                  </Row>
                )}
                {meetings.length > 0 && (
                  <>
                    <h1 className="content-box-heading">
                      Scheduled Appointments
                    </h1>
                    <p className="content-text">
                      Please see below for you existing scheduled appointments.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {meetings.map((meet: any) => {
                        return (
                          <Card className="border-0" style={{ width: "18rem" }}>
                            <Card.Body>
                              <Card.Title className="ff-Montserrat fw-bold my-3">
                                {meet.StartAtDisplayDate}
                              </Card.Title>
                              <p className="content-text">
                                {meet.StartAtDisplayTime +
                                  " " +
                                  meet.TimeZoneShort}
                              </p>
                              <p className="content-text">{meet.Topic}</p>
                              <span
                                className="color-primary d-block mx-auto text-decoration-none cursor-pointer"
                                onClick={() => confirmCancel(meet)}
                              >
                                Cancel Appointment
                              </span>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>{selectedMeeting?.StartAtDisplayDate}</h5>
            <h5>
              {selectedMeeting?.StartAtDisplayTime +
                " " +
                selectedMeeting?.TimeZone}
            </h5>
          </div>
          <div className="d-block mx-auto  text-center">
            <button className="btn-continue mb-2" onClick={cancelMeeting}>
              Cancel Appointment
            </button>
            <span
              className="color-primary  text-decoration-underline d-block mx-auto fw-bold ff-Montserrat cursor-pointer"
              onClick={handleClose}
            >
              Cancel
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AppointmentMade;
