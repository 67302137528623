import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TaskRiskReport({ progressPercent = 0 }) {
  const { next, s3key, s3Bucket } = useContext(PartnerEnrollmentFormContext);
  const [agreement, setAgreement] = useState("");

  const isPDF = s3key?.includes(".pdf");

  useEffect(() => {
    fetchAgreement();
  }, []);

  let contentType = isPDF ? "application/pdf" : "text/html";
  const fetchAgreement = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const serviceAgreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        // tagging: 'ExternalClearance=Profile',
        contentType: contentType,
      });
      console.log(serviceAgreement);
      const html = await fetch(serviceAgreement).then((res) => res.text());

      setAgreement(html);
    } catch (error) {
      console.log("error", error);
    }
  };

 

  function printImage() {
  

    const tempHtml =
    "<html><head><scri" +
    "pt>function step1(){\n" +
    "setTimeout('step2()', 10);}\n" +
    "function step2(){window.print();window.close()}\n" +
    "</scri" +
    "pt></head><body onload='step1()'>\n" +
    "<div dangerouslySetInnerHTML={{ __html:" +
    agreement +
    " }} />" +
    "</body></html>";
  var Pagelink = "about:blank";
  var pwa: any = window.open(Pagelink, "_new");
  pwa.document.open();
  pwa.document.write(tempHtml);
  pwa.document.close();
  }
  const download = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const Agreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "text/html", 
      });
      console.log(Agreement);
      downloadBlob(Agreement.Body, "Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">
                Partner Service Agreement
              </h1>
              <div className="text-end">
                <span className="letsdolater" onClick={printImage}>
                  <FontAwesomeIcon className="" icon={faPrint} /> Print
                </span>
                <span
                  style={{ marginLeft: "10px" }}
                  className="letsdolater"
                  onClick={download}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span>
              </div>
              <div style={{ height: "100vh", overflow: "auto" }}>
                <div dangerouslySetInnerHTML={{ __html: agreement }} />
              </div>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/">
                  Lets do this later...
                </a>
                <button onClick={next} className="btn-continue">
                  Agree & Sign
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TaskRiskReport;
