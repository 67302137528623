import { useContext, useEffect, useState } from "react";
import TaxOrganizerContext, { DependentModel, InitialDependentModel, TaxOrganizerData } from "./TaxOrganizerContext";
import { Col, Form, InputGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes, MAX_FILE_SIZE, UPLOAD_DOCUMENT_HEADER, UPLOAD_DOCUMENT_REQ_DESCRIPTION, UPLOAD_DOCUMENT_REQ_QUESTION } from "../Constants";
import PersonalInformationForm from "./PersonalInformationForm";
import DependentsCard from "./DependentsCard";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import DragDropFiles from "../../DragDropFiles";
import { formatCurrency } from "../../../functions/misc";
import SsnInput from "../../../Common/SsnInput";

const Dependents = () => {
    const [validated, setValidated] = useState(false);
    const {selectedRequest, taxOrganizerData, setTaxOrganizerData} = useContext(TaxOrganizerContext);
    const [showDependentModal, setShowDependentModal] = useState(false);
    const [dependentAction, setDependentAction] = useState('edit');
    const [selectedDependent, setSelectedDependent] = useState<DependentModel>(InitialDependentModel);

    const dependentModalCloseHandler = () => {
        setShowDependentModal(false);
    }

    const dependentModalOpenHandler = (actionType: string, dependent:DependentModel) => {
        setShowDependentModal(true);
        setDependentAction(actionType);
        setSelectedDependent(dependent);
    }

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    const changeDependentHandler = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        setSelectedDependent(prevState => ({...prevState, [fieldName]: fieldValue}));
    }

    const changeSsnHandler = (ssn:string | undefined) => {
        setSelectedDependent(prevState => ({...prevState, 'ssn': ssn?ssn: ''}));
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            dependentAction === 'new'?addDependentToContext(): updateDependentToContext();
        }
        setValidated(true);
    }

    const addDependentToContext = () => {
        setTaxOrganizerData({...taxOrganizerData, 
            dependents: [...taxOrganizerData.dependents, 
                {...selectedDependent, id: taxOrganizerData.dependents.length+1}]});
        setShowDependentModal(false);
        setSelectedDependent(InitialDependentModel);
    }

    const updateDependentToContext = () => {
        const dependents = taxOrganizerData.dependents.filter(dependent => (dependent.id !== selectedDependent.id));
        setTaxOrganizerData({...taxOrganizerData, 
            dependents: [...dependents, selectedDependent ]});
        setShowDependentModal(false);
        setSelectedDependent(InitialDependentModel);
    }

    const removeDependentToContext = () => {
        const dependents = taxOrganizerData.dependents.filter(dependent => (dependent.id !== selectedDependent.id));
        setTaxOrganizerData({...taxOrganizerData, 
            dependents: [...dependents]});
        setShowDependentModal(false);
        setSelectedDependent(InitialDependentModel);
    } 

    return (
        <>
        <div style={{marginBottom: '3rem'}}>
            <Row>
            <Col xl={9} md={9}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name} Dependents
            </h4>
            </Col>
            <Col xl={3} md={3} style={{textAlign: 'right'}}>
                {taxOrganizerData.dependents.length !== 0 &&
                    <button className="btn-continue" onClick={() => dependentModalOpenHandler('new', selectedDependent)}>{'+ADD Dependent'}</button>
                }
            </Col>
            </Row>
            <p className="content-text" style={{marginBottom: '3rem'}}>
                Please provide your personal information to complete the return. If you filed jointly, we will need both individual's information.
            </p>
            <div className="mt-6">
                <Row>
                    {taxOrganizerData.dependents.length !== 0 && 
                        <>
                        {taxOrganizerData.dependents.map((dependent: DependentModel) => {
                            return (
                            <Col lg={3} className="mb-2">
                                <DependentsCard key={dependent.id} dependent= {dependent} onAction={dependentModalOpenHandler}/>
                            </Col>
                            )
                        })}
                        </>
                    }
                    {taxOrganizerData.dependents.length === 0 && 
                         <Col lg={3}>
                            <DependentsCard onAction={dependentModalOpenHandler}/>
                        </Col>
                    }
                </Row>
            </div>
        </div>

        <Modal size="lg" centered show={showDependentModal} onHide={dependentModalCloseHandler}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={dependentModalCloseHandler} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="d-block mx-auto">
            <h6 className="fw-bold content-box-subtitle">{selectedRequest?.Name} Dependent</h6>
            <Form className="mt-4" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col md={5}>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                    required
                    value={selectedDependent?.firstName}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="firstName"
                    type="text"
                    />
                </Col>
                <Col md={2}>
                    <Form.Label>Middle Initial</Form.Label>
                    <Form.Control
                    value={selectedDependent?.middleName}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="middleName"
                    type="text"
                    />
                </Col>
                <Col md={5}>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                    required
                    value={selectedDependent?.lastName}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="lastName"
                    type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4}>
                    <Form.Label>Social Security Number*</Form.Label>
                    <SsnInput
                    required={true}
                    onChangeText={changeSsnHandler}
                    initialValue={selectedDependent?.ssn}
                    name="ssn"
                    />
                </Col>
                <Col md={4}>
                    <Form.Label>Birth Date*</Form.Label>
                    <Form.Control
                    required
                    value={selectedDependent?.birthDate}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="birthDate"
                    type="date"
                    />
                </Col>
                <Col md={4}>
                    <Form.Label>Relationship*</Form.Label>
                    <Form.Select
                    required
                    value={selectedDependent?.relationship}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="relationship"
                    >
                        <option value="">Select One</option>
                        <option value="AdoptedChild">Adopted Child</option>
                        <option value="Parent">Parent</option>
                        <option value="StepChild">Step Child</option>
                        <option value="BioChild">Bio Child</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4}>
                    <Form.Label>Gross Income*</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    required
                    value={selectedDependent?.grossIncome}
                    onChange={(event) => {changeDependentHandler(event)}}
                    onKeyDown={(event) => validateAmount(event)}
                    name="grossIncome"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <Form.Label>Disabled*</Form.Label>
                    <Form.Select
                    required
                    value={selectedDependent?.disabled}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="disabled"
                    >
                        <option value="">Select One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                </Col>
                <Col md={5}>
                    <Form.Label>Full-time Student*</Form.Label>
                    <Form.Select
                    required
                    value={selectedDependent?.fullTimeStudent}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="fullTimeStudent"
                    >
                        <option value="">Select One</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>   
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-4 mb-2">
                <Col md={9}>
                    <Form.Label>How many months has this dependent been living with you? *</Form.Label>
                </Col>
                <Col md={3}>
                    <Form.Select
                    required
                    value={selectedDependent?.dependentLivingWithYouMonths}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="dependentLivingWithYouMonths"
                    >
                        <option value={''}>Select One</option>
                        <option value={'3'}>3 Months</option>
                        <option value={'6'}>6 Months</option>
                        <option value={'9'}>9 Months</option>
                        <option value={'12'}>12 Months</option>
                    </Form.Select>
                </Col>
            </Row>
            <hr />
            <Row className="mt-4 mb-2">
                <Col md={9}>
                    <Form.Label>How many months of this tax year did this dependent have health coverage?*</Form.Label>
                </Col>
                <Col md={3}>
                    <Form.Select
                    required
                    value={selectedDependent?.dependentHealthCoverageMonths}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="dependentHealthCoverageMonths"
                    >
                        <option value={''}>Select One</option>
                        <option value={'3'}>3 Months</option>
                        <option value={'6'}>6 Months</option>
                        <option value={'9'}>9 Months</option>
                        <option value={'12'}>12 Months</option>
                    </Form.Select>
                </Col>
            </Row>
            <hr />
            <Row className="mb-2">
                <Col md={9}>
                    <Form.Label>Who is this Dependent Claimed By: *</Form.Label>
                </Col>
                <Col md={3}>
                    <Form.Label>Disabled*</Form.Label>
                    <Form.Select
                    required
                    value={selectedDependent?.dependentClaimedBy}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="dependentClaimedBy"
                    >
                        <option value={''}>Select One</option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                    </Form.Select>
                </Col>
            </Row>
            {dependentAction === 'new' && 
                <div className="mt-5" style={{textAlign: 'right'}}>
                    <button className="btn-continue-outline me-2" onClick={dependentModalCloseHandler}>Cancel</button>
                    <button className="btn-continue" type="submit">Save</button>
                </div>
                }
                {dependentAction === 'edit' && 
                <div className="mt-5" style={{textAlign: 'right'}}>
                    <button className="btn-continue-outline me-2" onClick={dependentModalCloseHandler}>Cancel</button>
                    <button className="btn-continue me-2" type="submit">Update</button>
                    <button className="btn-red" onClick={removeDependentToContext}>Remove Dependecy</button>
                </div>
                }
        </Form>
                
            </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default Dependents;