import React, { useEffect, useState } from "react";

import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TaxReportimg from "../../assets/tax-report-preparing.svg";
import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { clientConfig } from "../../awsClientConfig";
import { ReferAdditionalServices } from "../../queries/ReferAdditionalServices";
import { isNull } from "lodash";
import { GetTaxRiskReports } from "../../queries/GetTaxRiskReports";
import { TaxRiskReportItem } from "../DashBoards/types";

type TaskRiskReports = {
  NextToken: String | null;
  TaxRiskReports: TaxRiskReportItem[];
};
type res = {
  data: {
    GetTaxRiskReports: TaskRiskReports;
  };
};

function IndividualClient({ lead, onBreadcrumbClick }: any) {
  const navigate = useNavigate();
  const client = new clientConfig();
  const [openModel, setOpenModel] = useState(false);
  const [reportID, setReportID] = useState("");
  const [taxreports, setTaxreports] = useState<TaxRiskReportItem[]>();
  const [emailConfirm,setEmailConfirm] = useState(false)
  const onViewReport = () => {
    navigate("/tax-risk-report-partner", {
      state: { taskRiskReportId: reportID,lead:lead },
    });
  };

  useEffect(() => {
    const fetchtaskReports = async () => {
      await client
        .query({
          query: GetTaxRiskReports,
          variables: {
            ProfileId: lead.ProfileId,
          },
        })
        .then((res: any) => {
          setTaxreports(res.data.GetTaxRiskReports?.TaxRiskReports);
          setReportID(res.data.GetTaxRiskReports.TaxRiskReports[0]?.Id);
        })
        .catch((error: any) => {
          console.log(error);
        });
    };
    fetchtaskReports();
  }, []);

  function formatPhoneNumber(str: any) {
    if (str) {
      const phoneNumber = str.replace(/[^\d]/g, "");
      if (phoneNumber.length < 4) {
        return phoneNumber;
      }

      if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.length < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    }

    return "";
  }

  const paymentStatus = (status: boolean) => {
    let FinalStatus: string = "";

    if (isNull(status)) {
      FinalStatus = "Pending";
      return FinalStatus;
    }
    if (!isNull(status) && status) {
      FinalStatus = "On Hold";
      return FinalStatus;
    }
    if (!isNull(status) && !status) {
      FinalStatus = "Current";
      return FinalStatus;
    }
    return FinalStatus;
  };

  const setReportData = (e: any) => {
    setReportID(e.target.value);
  };

  const dateformat = (date: string) => {
    return moment(new Date(date)).utc().format("MMMM DD, YYYY");
  };
  console.log("report", reportID);
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={onBreadcrumbClick}>
                Referrals
              </Breadcrumb.Item>

              <Breadcrumb.Item active>{`${lead.FirstName} ${lead.LastName}`}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="justify-content-center align-items-center">
              <Col lg={7}>
                <h1
                  style={{ color: "#0A579B", marginBottom: "0px !important" }}
                  className="text-dark-main mb-3 ff-Montserrat fs-4 fw-bold .text-primary"
                >
                  {`${lead.FirstName} ${lead.LastName}`}
                </h1>

                <p className="mb-3 fs-6">
                  Referred:{" "}
                  <strong className="ms-2">
                    {lead.ReferredAt
                      ? moment(lead.ReferredAt).format("MM/DD/YYYY")
                      : "-"}
                  </strong>
                </p>
               <p className="mb-3 fs-6">
                  Enrolled:{" "}
                  <strong className="ms-2">
                    {lead.EnrolledAt
                      ? moment(lead.EnrolledAt).format("MM/DD/YYYY")
                      : "-"}
                  </strong>
                </p>
               <p className="mb-3 fs-6">
                  Phone:{" "}
                  <strong className="ms-2">
                    {formatPhoneNumber(lead.Phone)}
                  </strong>
                </p>
               <p className="mb-3 fs-6">
                  Email: <strong className="ms-2">{lead.Email}</strong>
                </p>
               <p className="mb-3 mt-2 fs-6">
                  Risk Level:{" "}
                  <strong className="ms-2">
                    {lead.TaxRiskReportRiskLevel
                      ? lead.TaxRiskReportRiskLevel
                      : "-"}
                  </strong>
                </p>
                <p style={{ marginBottom: "40px !important" }} className="fs-6">
                  Payment:{" "}
                  <strong className="ms-2">
                    {paymentStatus(lead.PaymentHold)}
                  </strong>
                </p>
                {lead.EnrolledAt ?
                <div
                  style={{ marginTop: "80px" }}
                >
                  <span className="material-blue mt-5 text-decoration-none cursor-pointer" style={{fontWeight: 500}}
                        onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenModel(true);
                  }}>
                    Refer Client for Add’l Services {">"}
                  </span>
                </div>
                : ""}
              </Col>
              <Col lg={4} className="text-left">
                <img
                  src={TaxReportimg}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Select a Tax Risk Report</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      disabled={reportID ? false : true}
                      onChange={setReportData}
                    >
                      {taxreports?.map((report, index) => {
                        return (
                          <option value={report.Id}>
                            {dateformat(report.ReportDate)}
                            {index === 0 && " (Latest)"}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <div className="d-grid">
                    <button
                      onClick={onViewReport}
                      disabled={reportID ? false : true}
                      className="view-report"
                      style={{
                        opacity: reportID ? 1 : .80,
                        backgroundColor: reportID ? "#1d89d2" : "#c9c6c6",
                        borderColor: reportID ? "#1d89d2" : "#c9c6c6"
                      }}
                    >
                      View Client's Report
                    </button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          centered
          show={openModel}
          onHide={() => {
            setOpenModel(false);
          }}
        >
          <Modal.Header className="ms-auto border-0 color-primary">
            <FaRegWindowClose
              onClick={() => {
                setOpenModel(false);
              }}
            />
          </Modal.Header>
          <Modal.Body className="px-5">
            <h5 style={{ textAlign: "center" }} className="color-primary ff-Montserrat fs-2 mt-0">
              Additional Services Referral
            </h5>
            <p className="content-text">
              Thank you for referring your client for additional services. We
              will be contacting{" "}
              {
                <span
                  style={{ fontWeight: "bold" }}
                >{`${lead.FirstName} ${lead.LastName}`}</span>
              }{" "}
              with additional instructions. Click confirm to continue.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="d-flex mx-auto  text-center"
            >
              <button
                onClick={() => {
                  setEmailConfirm(true)
                  console.log(lead);
                  client
                    .mutate({
                      mutation: ReferAdditionalServices,
                      variables: { LeadId: lead.LeadId },
                    })
                    .then(() => {
                      setOpenModel(false);
                      setEmailConfirm(false)
                    })
                    .catch(() => {
                      setOpenModel(false);
                    });
                }}
                disabled={emailConfirm} 
                className="btn-continue mb-2"
              >
                Confirm
              </button>
              <button
                style={{ border: "0px", background: "white", color: "#1589D2" }}
                onClick={() => {
                  setOpenModel(false);
                }}
                className="btn-continue mb-2"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}

export default IndividualClient;
