import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from "./assets/i18n/en/common.json";

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        }
    },
})

export default i18n;