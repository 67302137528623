import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import Table from "react-bootstrap/Table";
import { FaSearchPlus } from "@react-icons/all-files/fa/FaSearchPlus";
import { Modal } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { clientConfig } from "../../awsClientConfig";
import { Auth } from "aws-amplify";
import { userAccountData, userProfileData } from "../../Common/profileInfo";
import { FaSortAmountUpAlt } from "@react-icons/all-files/fa/FaSortAmountUpAlt";
import { FaSortAmountDown } from "@react-icons/all-files/fa/FaSortAmountDown";
import { SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLatestFormsByProfile } from "../../queries/GetLatestFormsByProfile";
import { GetProfile } from "../../queries/getProfile";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import ExclamationCircle from "../../assets/exclamation-circle-fill.svg";
import Loading from "../../Common/Loading";
import { GetFreeTaxRiskReportEligibility } from "../../queries/GetFreeTaxRiskReportEligibility";

const TableHeader = [
  {
    name: "File",
    key: "Name",
    sort: "asc",
  },
  {
    name: "Last Updated",
    key: "UpdatedAt",
    sort: "asc",
  },
];

function W9Form({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const navigate = useNavigate();

  const [agreement, setAgreement] = useState("");

  const isPDF = S3Key?.includes(".pdf");

  useEffect(() => {
    fetchAgreement();
  }, []);

  let contentType = isPDF ? "application/pdf" : "text/html";
  const fetchAgreement = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const serviceAgreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        // tagging: 'ExternalClearance=Profile',
        contentType: contentType,
      });
      setAgreement(serviceAgreement);
    } catch (error) {
      console.log("error", error);
    }
  };

 

  function printImage() {
   

    fetch(agreement)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "W-9 Form");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>W-9 Form</Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>W-9 Form</h1>
            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>
            <div style={{ height: 500, marginTop: 10 }}>
              <iframe
                src={agreement}
                height="100%"
                width="100%"
                title="Agreement"
              ></iframe>
            </div>

            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button className="btn-continue">Close</button>
              <button style={{ marginLeft: 4 }} className="btn-continue">
                Submit a New Form
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function PartnerServiceAgreement({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const [irsForm, setIRSForm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  function printImage() {
   

    fetch(irsForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Partner Agreement</Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>
              Partner Agreement
            </h1>

            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>

            <div style={{ marginTop: 10, height: 500 }}>
              <iframe
                src={irsForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>

            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function Form8821({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const [irsForm, setIRSForm] = useState("");
  const [CafStatus, setCafStatus] = useState("");
  const navigate = useNavigate();
  const client = new clientConfig();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchIRSForm();
    fetchUseProfile();
  }, []);
  const fetchUseProfile = async () => {
    const profileId: any = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setCafStatus(res.data.GetProfile?.CafStatus);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const fetchIRSForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  function printImage() {


    fetch(irsForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "Tax Information Authorization Form 8821");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{"Forms > 8821"}</Breadcrumb.Item>
            </Breadcrumb>



           <div className="d-flex justify-content-between align-items-center">
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>
             Tax Information Authorization Form 8821
            </h1>
            {CafStatus === "Accepted" && (
             <span
              className="badge px-4 pt-2 pb-2 badge-pill badge-success"
              style={{ background: "green" }}
             >
                  On File
                </span>
            )}
            {CafStatus === "Rejected" && (
             <span
              className="badge px-4 pt-2 pb-2 badge-pill badge-danger"
              style={{ background: "red" }}
             >
                  Please Resubmit Form
                </span>
            )}
            {CafStatus === "Processing" && (
             <span
              className="badge px-4 pt-2 pb-2 badge-pill badge-warning"
              style={{ background: "#f7ca0b" }}
             >
                  Processing
                </span>
            )}
           </div>

            <p className="content-text">
              <strong>
                {" "}
                You are currently represented by Optima Tax Relief.
              </strong>
            </p>
            {CafStatus === "Accepted" && (
              <p className="content-text">
                Your Centralized Authorization File (CAF) number has been
                processed with the IRS.
              </p>
            )}
            {CafStatus === "Processing" && (
              <p className="content-text">
                Your Centralized Authorization File (CAF) number is being
                processed with the IRS.
              </p>
            )}
            {CafStatus === "Rejected" && (
              <p className="content-text">
                If you need to resubmit a new Tax Information Authorization Form
                (8821) please click the Submit a New Form button below.
              </p>
            )}

            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>

            <div style={{ marginTop: 10, height: 500 }}>
              <iframe
                src={irsForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>

            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
              {CafStatus === "Rejected" && (
                <button
                  style={{
                    marginLeft: 4,
                    background: "white",
                    color: "#1d89d2",
                  }}
                  className="btn-continue"
                  onClick={handleShow}
                >
                  Submit a New Form
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h1 style={{ color: "#0A579B", textAlign: "center" }}>Warning!</h1>
          <p style={{ textAlign: "center" }}>
           By resubmitting a new Tax Information Authorization Form (8821){" "}
           <strong>you could interrupt or delay current services. </strong>
          </p>
          <p style={{ textAlign: "center" }}>
           Please click “Yes, Submit a New Form” if you wish to proceed.
          </p>
          <div className="d-block mx-auto  text-center">
           <button
            style={{
             backgroundColor: "red",
             border: "2px solid red",
             width: "250px",
             fontWeight: 600,
            }}
            className="btn-continue mb-2"
            onClick={() => {
             navigate("/account-settings-new-report");
            }}
           >
            Yes, Submit a New Form
           </button>
           <span
            className="color-primary  text-decoration-underline d-block mx-auto fw-bold ff-Montserrat cursor-pointer"
            onClick={handleClose}
           >
              No, Cancel Change
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function ProtectionPlanServiceAgreement({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const [irsForm, setIRSForm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  function printImage() {


    fetch(irsForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "Protection Plan Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Protection Plan Service Agreement
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>
              Protection Plan Service Agreement
            </h1>

            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>

            <div style={{ marginTop: 10, height: 500 }}>
              <iframe
                src={irsForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
function TaxPreparationServiceAgreement({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const [taxPrepForm, setTaxPrepForm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaxPreparationForm();
  }, []);

  const fetchTaxPreparationForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setTaxPrepForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  function printImage() {


    fetch(taxPrepForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(Agreement.Body, "Tax Preparation Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
              Client Service Agreement - Tax Preparation
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>
            Client Service Agreement - Tax Preparation
            </h1>

            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>

            <div style={{ marginTop: 10, height: 500 }}>
              <iframe
                src={taxPrepForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}



function TaxRiskReportServiceAgreement({ form, setSelectedForm, state }: any) {
  const { S3Key, S3Bucket } = form;
  const [irsForm, setIRSForm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const irsForm = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "application/pdf",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };



  function printImage() {
   
    fetch(irsForm)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }
  const download = async () => {
    try {
      SetS3Config(S3Bucket, "public");
      const Agreement = await Storage.get(S3Key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "application/pdf",
      });
      downloadBlob(
        Agreement.Body,
        "Client Service Agreement - Protection Plan"
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Client Service Agreement - Protection Plan
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>
              Client Service Agreement - Protection Plan
            </h1>

            <div className="text-end">
              <span className="letsdolater" onClick={printImage}>
                <FontAwesomeIcon className="" icon={faPrint} /> Print
              </span>
              <span
                style={{ marginLeft: "10px" }}
                className="letsdolater"
                onClick={download}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} /> Download
              </span>
            </div>

            <div style={{ marginTop: 10, height: 500 }}>
              <iframe
                src={irsForm}
                height="100%"
                width="100%"
                id="muimh"
                title="test"
              ></iframe>
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function UploadedDocuments({ form, setSelectedForm, state }: any) {
  const navigate = useNavigate();
  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setSelectedForm({})}>
                Documents
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{form?.Name=== 'TaxPrepServiceAgreement'?'Client Service Agreement - Tax Preparation':form?.Name}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 style={{ color: "#0A579B", fontSize: 24 }}>Government Id</h1>

            <div
              style={{
                width: 215,
                height: 142,
                objectFit: "cover",
                borderWidth: 2,
                borderColor: "#ddd",
                borderStyle: "solid",
                textAlign: "center",
                marginLeft: 10,
              }}
            >
              {isPdf(form?.S3Key) ? (
                <iframe
                  src={form?.S3Bucket}
                  title="app-logo"
                  style={{
                    width: 212,
                    height: 138,
                    objectFit: "cover",
                    borderWidth: 2,
                    borderColor: "#ddd",
                  }}
                />
              ) : (
                <img
                  src={form?.S3Bucket}
                  alt="app-logo"
                  style={{
                    width: 212,
                    height: 138,
                    objectFit: "cover",
                    borderWidth: 2,
                    borderColor: "#ddd",
                  }}
                />
              )}
            </div>
            <div className="d-flex justify-content-end mt-5 align-items-center">
              <button
                onClick={() => setSelectedForm({})}
                className="btn-continue"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

function ClientDocuments() {
  const client = new clientConfig();
  const { state } = useLocation();
  const [sortHeaders, setSortHeaders] = useState(TableHeader);
  const [forms, setForms] = useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedForm, setSelectedForm] = useState<any>({});
  const [images, setimages] = useState<any>([]);
  const [CafStatus, setCafStatus] = useState("");

  useEffect(() => {
    const fetchApi = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const partnerId = await userProfileData();
      await client
        .query({
          query: GetLatestFormsByProfile,
          variables: {
            ProfileId: partnerId,
          },
        })
        .then((res: any) => {
          setForms(res.data.GetLatestFormsByProfile);
        });
    };

   if (state) {
    onFormClick(state)
   }

    fetchApi();
    loadImages();
    fetchUseProfile();
  }, []);

  const fetchUseProfile = async () => {
    const profileId: any = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setCafStatus(res.data.GetProfile?.CafStatus);
        }
       setLoading(false);
      })
      .catch((error: any) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const onSort = (key: string, sortMethod: "asc" | "desc") => {
    const headers = [...sortHeaders];

    headers.forEach((header) => {
      if (header.key === key) {
        header.sort = header.sort === "asc" ? "desc" : "asc";
      }
    });

    const sortForms = orderBy(forms || [], [key], [sortMethod]);
    setForms(sortForms);
    const sortFormsIds = orderBy(images || [], [key], [sortMethod]);
    setimages(sortFormsIds);
    setSortHeaders(headers);
  };

  const onFormClick = (form: any) => {
    setSelectedForm(form);
  };

  const loadImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    const accountId = await userAccountData();
    try {
      await Storage.list(
        `Accounts/${accountId}/Profiles/${profileId}/Identifications/`,
        {
          // contentType: "image/png", // contentType is optional
          customPrefix: { public: "" },
          level: "public",
          // tagging: "ExternalClearance=Profile",
        }
      ).then((res) => {
        console.log(res);
        getImages(res.results);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const getImages = async (url: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    let getAllImages: any = [];
    url.forEach((element: any) => {
      getAllImages.push(loadSingleImage(element));
    });
    Promise.all(getAllImages).then((values: any) => {
      // const merged: any = [...forms, ...values];
      // setForms(merged);
      setimages(values);
    });
  };

  const loadSingleImage = async (url: any) => {
    const ispdf = isPdf(url.key);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const imageData: string = await Storage.get(url.key, {
      level: "public",
      contentType: contentType,
      customPrefix: { public: "" },
    });
    let imageObj = {
      S3Bucket: imageData,
      Name: url.key.split("/")[5],
      S3Key: url.key,
      UpdatedAt: url.lastModified,
    };

    return imageObj;
  };

  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }

  const getDocs = () => {
    let docs = [];
    docs = [...forms, ...images];
    return docs;
  };

  if (selectedForm.Name === "FormW9") {
    return <W9Form form={selectedForm} setSelectedForm={setSelectedForm} state={state} />;
  }

  if (selectedForm.Name === "PartnerServiceAgreement") {
    return (
      <PartnerServiceAgreement
        form={selectedForm}
        setSelectedForm={setSelectedForm}
        state={state}
      />
    );
  }

  if (selectedForm.Name === "TaxRiskReportServiceAgreement") {
    return (
      <TaxRiskReportServiceAgreement
        form={selectedForm}
        setSelectedForm={setSelectedForm}
        state={state}
      />
    );
  }

  if (selectedForm.Name === "ProtectionPlanServiceAgreement") {
    return (
      <ProtectionPlanServiceAgreement
        form={selectedForm}
        setSelectedForm={setSelectedForm}
        state={state}
      />
    );
  }

  if(selectedForm.Name === "TaxPrepServiceAgreement") {
    return (
      <TaxPreparationServiceAgreement
        form={selectedForm}
        setSelectedForm={setSelectedForm}
        state={state}
      />
    );
  }

  if (selectedForm.Name === "Form8821") {
    return <Form8821 form={selectedForm} setSelectedForm={setSelectedForm} state={state} />;
  }
  if (selectedForm.Name) {
    return (
      <UploadedDocuments
        form={selectedForm}
        setSelectedForm={setSelectedForm}
        state={state}
      />
    );
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Documents</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="align-items-center">
              <Col lg={9}>
                <h1 className="text-main-dark mb-3 ff-Montserrat fs-4 fw-bold">
                  Documents
                </h1>
              </Col>
            </Row>
            {loading ? (
              <Loading />
            ) : (
            <Card
              style={{
                backgroundColor: "#FAFCFD",
                border: "1px solid #E2E7EA",
              }}
            >
              <Table hover className="myReferral">
                <thead>
                  <tr className="referral-tabel">
                    {sortHeaders.map((header: any) => {
                      return (
                        <th>
                          {header.name}
                          {header.sort === "asc" ? (
                            <span
                              style={{ marginLeft: 5 }}
                              onClick={() => onSort(header.key, "asc")}
                            >
                              <FaSortAmountUpAlt />
                            </span>
                          ) : (
                            <span
                              style={{ marginLeft: 5 }}
                              onClick={() => onSort(header.key, "desc")}
                            >
                              <FaSortAmountDown></FaSortAmountDown>
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {getDocs()?.map((data: any) => (
                    <tr>
                      <td className="py-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <span
                            onClick={() => onFormClick(data)}
                            style={{ cursor: "pointer" }}
                            className="material-blue"
                          >{`${data.Name}`}</span>
                          {CafStatus === "Rejected" &&
                            data.Name === "Form8821" && (
                              <img
                                src={ExclamationCircle}
                                className="me-2 material-blue fs-6"
                                alt="Alert"
                              />
                            )}
                        </div>
                      </td>
                      <td className="py-3">
                        {data.UpdatedAt
                          ? moment(data.UpdatedAt).format("MM/DD/YYYY")
                          : "-"}
                      </td>
                      <td className="py-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="material-blue ms-2"
                            onClick={() => onFormClick(data)}
                          >
                            <FaSearchPlus />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ClientDocuments;
