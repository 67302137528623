import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../pages/footer";
import NavBar from "../pages/navbar";
import { clientConfig } from "../awsClientConfig";
import SideBar from "../../src/pages/sidebar";
import useProfileStore, { useGroupStore, PrimaryGroups } from "../store/ProfileStore";
import { fetchQuery } from "../functions/misc";
import { GetProfile } from "../queries/getProfile";
import { userProfileData } from "./profileInfo";
import PartnerSideBar from "../pages/PartnerSideBar";
import { encodeToBase64, decodeFromBase64 } from "../functions/misc";

export interface TpoIframeUrlType {
  tpoIframeUrl: string,
  setTpoIframeUrl: (url: string) => void
}

const CommonLayout = () => {
  const client = new clientConfig();
  const navigate = useNavigate();
  const [hideSidebar, setHideSidebar] = useState(false);
  const [tpoIframeUrl, setTpoIframeUrl] = useState<string>("");
  const [partner, setPartner] = useState(false);
  const location = useLocation();
  const { partnerProfile, setPartnerProfile, spouseProfile, setSpouseProfile } = useProfileStore();
  const { primaryGroup, setPrimaryGroup } = useGroupStore();

  useEffect(() => {
    userAuthCheck();
  }, []);

  useEffect(() => {
    trackRouteChange();
  }, [location.pathname]);

  const userAuthCheck = async () => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res) {
          if (res.attributes["custom:Group"]) {
            const group = encodeToBase64(res.attributes["custom:Group"]);
            setPrimaryGroup(group);
          }
          console.log("user found");
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        if (error) {
          navigate("/");
        }
      });
    const profileId = await userProfileData();
    const getProfile = await fetchQuery({ profileId: profileId }, GetProfile);
    if (getProfile?.ParentId) {
      setSpouseProfile({
        Id: getProfile.Id,
        AccountId: getProfile.AccountId,
        ParentId: getProfile.ParentId,
        FirstName: getProfile.FirstName,
        LastName: getProfile.LastName,
      })
    }
  };

  const trackRouteChange = () => {
    if (tpoIframeUrl && tpoIframeUrl.includes("login?")) {
      setTpoIframeUrl(`${process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL}/login`)
    }
    if (location.pathname === "/tax-preparation-service-enroll" 
              || location.pathname === "/profileClient"
              || location.pathname === "/profilePartners"
              || location.pathname === "/protection-plan"
              || location.pathname === "/res-protection-plan"
              || location.pathname === "/partner-enrollment") {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  };

  return (
    <>
      <NavBar tpoIframeUrl={tpoIframeUrl} setTpoIframeUrl={setTpoIframeUrl} />
      {!hideSidebar && !spouseProfile?.ParentId && !partnerProfile?.Email && decodeFromBase64(primaryGroup) !== PrimaryGroups.Partner && <SideBar/>}
      {!hideSidebar && decodeFromBase64(primaryGroup) === PrimaryGroups.Partner && <PartnerSideBar/>}
      <div style={{minHeight: '90vh'}}>
      <Outlet context={{tpoIframeUrl, setTpoIframeUrl} satisfies TpoIframeUrlType} />
      </div>
      <Footer />
    </>
  );
};

export default CommonLayout;
