import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppProgressBar from "../../Common/progressBar";
import { clientConfig } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfile } from "../../queries/getProfile";
import { PurchaseProtectionPlan } from "../../queries/PurchaseProtectionPlan";
import Message from "../Message";
import Loading from "../../Common/Loading";
import { useNavigate } from "react-router-dom";
import TaxPreparationServiceFormContext from "./TaxPreparationServiceFormContext";
import { PurchaseTaxReturn } from "../../queries/PurchaseTaxReturn";
import useEnrollmentStore from "../../store/EnrollmentStore";

function PaymentDetails() {
  const {taxPreparationData, setTaxPreparationData, navigateToPage,completedPagesHandler,quoteId, quotePrice  } = useContext(TaxPreparationServiceFormContext);
  var paid = false;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const { taxPrepEnrollment, setTaxPrepEnrollment } = useEnrollmentStore();
  const navigate = useNavigate();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const windowValue: any = window;
  const environmentKey = process.env.REACT_APP_ENVIRONMENTKEY;
  let bottom_description = `Your tax filing is personalized based on the information you\'ve entered. Upon clicking \'Continue\', your payment method will be charged the amount of $${quotePrice?quotePrice:''}`;
  const options = {
    company_name: 'TAX FILING',
    sidebar_bottom_description: bottom_description,
    amount: quotePrice?('$'+quotePrice):'',
    submit_label: 'Continue'
  };

  const client = new clientConfig();

  window.onbeforeunload = function (e) {
    windowValue.SpreedlyExpress.unload();
  };

  window.onpopstate = () => {
    windowValue.SpreedlyExpress.unload();
  };

  let doc: any = document;
  doc.getElementById("spreedly-close-button")?.addEventListener("click", () => {
    closeview();
  });
  doc.getElementById("chooseAnotherPlan")?.addEventListener("click", () => {
    goToProtectionPlan();
  });
  const closeview = () => {
    navigate("/dashboard");
    windowValue.SpreedlyExpress.unload();
    
  };

  const goToProtectionPlan = () => {
    windowValue.SpreedlyExpress.unload();
    
  };

  const handlePaymentToken = async (token: any, formdata: any) => {
    if (!paid) {
      setLoading(true);
      paid = true;
      const profileId = await userProfileData();
      const variables = {
        PaymentMethodToken: token,
        ProfileId: profileId,
        TaxPrepQuoteId: quoteId
      };
      client
        .mutate({ mutation: PurchaseTaxReturn, variables: variables })
        .then((res: any) => {
          if (res.data.PurchaseTaxReturn.Id) {
            setLoading(false);
            setSuccess("Payment Successful");
            setTimeout(() => {
              setSuccess("");
              if(taxPreparationData.jointFiling === 'MarriedFilingJointly') {
                //completedPagesHandler('completed');
                setTaxPrepEnrollment({
                  taxPrepEnrollmentCompleted: taxPrepEnrollment?.taxPrepEnrollmentCompleted,
                  showTaxPrepNextStepsModal: taxPrepEnrollment?.showTaxPrepNextStepsModal,
                  paymentCompleted: true
                });
                navigateToPage('applicationReview');
              }else {
                setTaxPrepEnrollment({
                  taxPrepEnrollmentCompleted: taxPrepEnrollment?.taxPrepEnrollmentCompleted,
                  showTaxPrepNextStepsModal: taxPrepEnrollment?.showTaxPrepNextStepsModal,
                  paymentCompleted: true
                });
                completedPagesHandler('completed');
                navigateToPage('completed');
              }
            }, 5000);
          }
        })
        .catch((error: any) => {
          console.log("error:", error);
          setError("error in payment method");
          setLoading(false);
          paid = false;
          setTimeout(() => {
            windowValue.SpreedlyExpress.unload();
            setError("");
            initializeSprredly();
          }, 5000);
        });
    }
    //completedPagesHandler('completed');
    //navigateToPage('completed');
  };

  const enableButton = () => {
    setLoading(false);
    windowValue.SpreedlyExpress.openView();
  };

  useEffect(() => {
    initializeSprredly();
  }, []);

  const initializeSprredly = async () => {
    windowValue.SpreedlyExpress.onInit(enableButton);
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          windowValue.SpreedlyExpress.init(environmentKey, options, {
            address1: taxPreparationData.billingAddress.streetAddress,
            address2: taxPreparationData.billingAddress.streetAddress2,
            city: taxPreparationData.billingAddress.city,
            state: taxPreparationData.billingAddress.state,
            zip: taxPreparationData.billingAddress.zipCode,
            country: "US",
            email: res.data.GetProfile?.Email,
            // data: "something",
            phone_number: res.data.GetProfile?.Phone,
          });
        }
        windowValue.Spreedly.on('ready', function() {
          windowValue.Spreedly.setPlaceholder('number', '');
        });
      })
      .catch((error: any) => {
        console.log(error, "error");
      });

    windowValue.SpreedlyExpress.onPaymentMethod(handlePaymentToken);
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            {loading ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={75} />
                <h1 className="content-box-heading">Payment Details</h1>
                <p className="content-text">
                  Please provide your billing address
                </p>
                {error && (
                  <Message type="error" message="Failed Payment"></Message>
                )}
                {success && (
                  <Message
                    type="success"
                    message="Payment Successful"
                  ></Message>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentDetails;
