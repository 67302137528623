import { useContext } from "react";
import TaxOrganizerContext, {  } from "./TaxOrganizerContext";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg'; 
import '../requestcenter.css';

const OtherInformation = () => {
    const {selectedRequest, taxOrganizerData, setTaxOrganizerData} = useContext(TaxOrganizerContext);

    

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    const changeDataHandler = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        setTaxOrganizerData({...taxOrganizerData, 
            otherInformation: {...taxOrganizerData.otherInformation, [fieldName]: fieldValue}});
               
    }

    const setTaxCreditOptions = (fieldName: string, option:string) => {
        setTaxOrganizerData({...taxOrganizerData, 
            otherInformation: {...taxOrganizerData.otherInformation, [fieldName]: option}});
               
    }

    const renderTooltip = () => {
        return(
        <Tooltip id="statetaxtooltip">
            <div>
            <h6 className="tff-Montserrat fw-bold mb-2">
                State Tax Help
            </h6>
            <p className="content-text" style={{marginBottom: '3rem'}}>
            Please provide the sum total of state taxes withheld from all the sources of income, in applicable.
            </p>
        </div>
        </Tooltip>
        );
    }

    /*
    <div>
            <h6 className="tff-Montserrat fw-bold mb-2">
                State Tax Help
            </h6>
            <p className="content-text" style={{marginBottom: '3rem'}}>
            Please provide the sum total of state taxes withheld from all the sources of income, in applicable.
            </p>
        </div>
    */

    
    return (
        <div style={{marginBottom: '3rem'}}>
            <Row>
            <Col xl={12} md={12}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name} Other Information
            </h4>
            </Col>
            </Row>
            <p className="content-text" style={{marginBottom: '3rem'}}>
            The information below will assist Optima in understanding some general deductions for this year so we can best assess the next steps in filing your return.
            </p>
            <div className="mt-6">
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                        <Form.Label className="fw-bold">Annual Household Medical Expenses</Form.Label>
                    </Col>
                    <Col md={4}>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                        value={taxOrganizerData?.otherInformation?.medicalExpenses}
                        onChange={(event) => {changeDataHandler(event)}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="medicalExpenses"
                        type="text"
                    />
                    </InputGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                        <Form.Label className="fw-bold">Did you rent or own during this tax year?</Form.Label>
                    </Col>
                    <Col md={4}>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                        value={taxOrganizerData?.otherInformation?.rentInTaxYear}
                        onChange={(event) => {changeDataHandler(event)}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="rentInTaxYear"
                        type="text"
                    />
                    </InputGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                        <Form.Label className="fw-bold">Annual Charitable Contributions</Form.Label>
                    </Col>
                    <Col md={4}>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                        value={taxOrganizerData?.otherInformation?.charitableContributions}
                        onChange={(event) => {changeDataHandler(event)}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="charitableContributions"
                        type="text"
                    />
                    </InputGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                    <Form.Label className="fw-bold">State Taxes Withheld <OverlayTrigger placement="top"
                    overlay={<Tooltip id="statetaxtooltip">
                        <div>
                        <h6 className="tff-Montserrat fw-bold mb-2" style={{textAlign: 'left'}}>
                            State Tax Help
                        </h6>
                        <p className="content-text-white">
                        Please provide the sum total of state taxes withheld from all the sources of income, in applicable.
                        </p>
                    </div>
                        </Tooltip>}><QuestionMark /></OverlayTrigger> </Form.Label>
                    </Col>
                    <Col md={4}>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                        value={taxOrganizerData?.otherInformation?.stateTaxes}
                        onChange={(event) => {changeDataHandler(event)}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="stateTaxes"
                        type="text"
                    />
                    </InputGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                        <Form.Label className="fw-bold">Did you receive the 2021 Advanced Child Tax Credit?</Form.Label>
                    </Col>
                    <Col md={4}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      onClick={() => setTaxCreditOptions("advancedTaxCredit","Yes")}
                      checked={taxOrganizerData?.otherInformation?.advancedTaxCredit === "Yes" ? true : false}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      onClick={() => setTaxCreditOptions("advancedTaxCredit","No")}
                      checked={taxOrganizerData?.otherInformation?.advancedTaxCredit === "No" ? true : false}
                    />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8} className="d-flex align-items-center">
                        <Form.Label className="fw-bold">Did you receive the 3rd Stimulus Payment in the amount of $1,400?</Form.Label>
                    </Col>
                    <Col md={4}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      onChange={() => setTaxCreditOptions("thirdStimulusPayment","Yes")}
                      checked={taxOrganizerData?.otherInformation?.thirdStimulusPayment === "Yes" ? true : false}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      onChange={() => setTaxCreditOptions("thirdStimulusPayment","No")}
                      checked={taxOrganizerData?.otherInformation?.thirdStimulusPayment === "No" ? true : false}
                    />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default OtherInformation;