import { Auth } from "aws-amplify";

const config = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID.
    //identityPoolId: "us-west-2:5f0cfd68-049a-4783-a246-a86f0e129c80",
    // REQUIRED - Amazon Cognito Region.
    region: process.env.REACT_APP_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID.
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string).
    //userPoolWebClientId: "6c7p8ea09vmm0h2ktaggk4jt82",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    awsAppSyncGraphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    awsAppSyncAuthenticationType: "AMAZON_COGNITO_USER_POOLS",
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },


  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_REGION,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),

  },

  Storage: {
    AWSS3: {
      bucket: `documents-${process.env.REACT_APP_MODE}-optima-financial-group`, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_REGION, //OPTIONAL -  Amazon service region
    },
  },
};
export default config;
