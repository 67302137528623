import gql from "graphql-tag";

export const GetPaymentMethodsByAccount = gql`
  query GetPaymentMethodsByAccount(
    $AccountId: ID!
    $Domain: Domain!
  ) {
    GetPaymentMethodsByAccount(
      AccountId: $AccountId
      Domain: $Domain
      Limit: 100
    ) {
      PaymentMethods {
        AchEntityType
        AchNumber
        AchRoutingNumber
        AchType
        Active
        CcExpMonth
        CcExpYear
        CcLastFourDigits
        CcType
        City
        CreatedAt
        Default
        Id
        Name
        State
        StreetAddress1
        StreetAddress2
        Token
        Type
        UpdatedAt
        Vendor
        ZipCode
      }
      NextToken
    }
  }
`;
