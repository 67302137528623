import React, { useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NOT_SUPPORTED_STATES, US_STATES } from "../../Common/States";
import { Button } from "react-bootstrap";
import { ReturnStates } from "./TaxPreparationServiceFormContext";

const StateReturnsForm:React.FC<{
  returnsState:ReturnStates, 
  index: number,
  onValueChange:(event:any, index:number) => void,
  onAdd: ()=> void,
  onRemove: (index: number) => void,
  recordsCount: number,
  paymentCompleted: boolean | undefined}> = ({returnsState, index, onValueChange, onAdd, onRemove, recordsCount, paymentCompleted}) => {
  
  const [showError, setShowError] = useState(false);

  const addHandler = () => {
    if(returnsState.State) {
      setShowError(false);
      onAdd();
    }else {
      setShowError(true);
    }
  }

  const SUPPORTED_STATES = US_STATES.filter((state) => !NOT_SUPPORTED_STATES.includes(state.abbreviation));

  
  return (
        <Form as={Row}>
          {showError && 
          <p className='fs-8 color-red'>Please fill required information in this form.</p>
          }
          <Row className="mb-3 d-flex justify-content-between">
            <Col md={3} lg={3}>
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Label>State</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={returnsState.State}
                  name="State"
                  disabled={paymentCompleted}
                  onChange={(event) => onValueChange(event, index)}
                >
                  <option value="">- </option>
                  {SUPPORTED_STATES.map((state) => {
                    return (
                      <option value={state.abbreviation}>
                        {state.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid State.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={9} lg={9} className="d-flex justify-content-end align-items-center px-4">
              {recordsCount === index+1 && 
              <Button variant='btn btn-primary fw-bold' disabled={paymentCompleted} style={{borderRadius: '28px', paddingInline: '20px'}} onClick={addHandler}>Add</Button>
              }
              {recordsCount !== index+1 && 
              <Button variant='btn btn-danger fw-bold' disabled={paymentCompleted} style={{borderRadius: '28px', paddingInline: '20px'}} onClick={() => onRemove(index)}>Remove</Button>
              }
            </Col>
            </Row>
        </Form>
  );
}

export default StateReturnsForm;
