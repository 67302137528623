import { Auth } from "aws-amplify";
import { Buffer } from "buffer";

export const RedirectToLegacy = async (email: string, onClientPortal?: boolean | undefined) => {
  let jwt = async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken();
    

  jwt().then((token) => {
    let loginUrl = `${
      process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL
    }/login?Token=${token}&Email=${Buffer.from(email).toString("base64")}`;

    if (onClientPortal) {
      window.location.href = loginUrl;
    } else {
      Auth.signOut().then(() => {
       window.location.href = loginUrl;
      });
    }
  });
};

export const IsUserLegacy = async () => {
  const isUserLegacy = await Auth.currentAuthenticatedUser();
  return !!isUserLegacy.attributes["custom:LegacyProfileId"];
};