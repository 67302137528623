import gql from "graphql-tag";

export const CreatePartnerDeletion = gql`
  mutation CreatePartnerDeletion(
    $PartnerId: ID!
    $DeletionReason: DeletionReason!
  ) {
    CreatePartnerDeletion(
      Input: { DeletionReason: $DeletionReason, PartnerId: $PartnerId }
    ) {
      CompletedAt
      CreatedAt
      DeletionReason
      Id
      InitiatedAt
      Status
      UpdatedAt
    }
  }
`;
