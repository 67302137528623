import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppProgressBar from "../../Common/progressBar";
import { Auth } from "aws-amplify";
import useEnrollmentStore from "../../store/EnrollmentStore";
import useProfileStore from "../../store/ProfileStore";

const EnrollCompletedPage = () => {
  const { clearProtectionPlanEnrollment, clearTaxPrepEnrollment } = useEnrollmentStore();
  const { clearPrimaryProfile, clearSpouseProfile } = useProfileStore();
  const navigate = useNavigate();
    const continueHandler = () => {
      Auth.signOut().then((res) => {
        clearProtectionPlanEnrollment();
        clearTaxPrepEnrollment();
        clearPrimaryProfile();
        clearSpouseProfile();
        navigate("/login");
      });
    }

    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={100} />
              <Row className="justify-content-center">
                <Col md={12}>
                  <h1 className="content-box-heading mb-4">
                    Thank You For Completing Your Enrollment!
                  </h1>
                  <p className="content-text">
                    Congratulations and thank you for successfully completing the signature process as a co-client in our tax preparation service enrollment. We are delighted to have you onboard and are committed to providing you with a seamless and efficient tax filing experience.
                  </p>
                  <p className="content-text">
                    Keep an eye on your email for important updates, helpful tax tips, and resources that can assist you throughout the tax season. Our team is dedicated to keeping you informed and empowered in managing your taxes effectively.
                  </p>
                  <Row className="justify-content-start my-4">
                    <div className="d-flex justify-content-end mt-3 align-items-center">
                      <button
                        className={"btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={continueHandler}>
                       Finish
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default EnrollCompletedPage;