import gql from "graphql-tag";

export const GetEnrollmentsByProfile = gql`
  query GetEnrollmentsByProfile($ProfileId: ID!) {
    GetEnrollmentsByProfile(ProfileId: $ProfileId) {
      EnrollmentInitiated
      Enrollments {
        Id
        Status
        Type
        ProtectionPlanName
      }
      IsEligible
    }
  }
`;