import { useContext } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import IncomeExpensesContext from "./IncomeExpensesContext";
import MonthlyIncomeForm from "./MonthlyIncomeForm";
import MonthlyExpensesForm from "./MonthlyExpensesForm";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

const MonthlyExpenses:React.FC<{showExpensesFormError:boolean}> = (props) => {

    const {incomeExpensesData, setIncomeExpensesData, defaultTab} = useContext(IncomeExpensesContext);
    
    const updateClientInfo = (event: any) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        const fieldType = event.target.type;
        if(fieldType === 'radio') {
            fieldName = fieldName.substring(0, fieldName.indexOf('-'));
            fieldValue = event.target.name.substring(event.target.name.indexOf('-')+1);
        }
        setIncomeExpensesData({...incomeExpensesData, clientMonthlyExpenses:{...incomeExpensesData.clientMonthlyExpenses, [fieldName]: fieldValue}});
    }

    const updateSpouseInfo = (event: any) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        const fieldType = event.target.type;
        if(fieldType === 'radio') {
            fieldName = fieldName.substring(0, fieldName.indexOf('-'));
            fieldValue = event.target.name.substring(event.target.name.indexOf('-')+1);
        }
        setIncomeExpensesData({...incomeExpensesData, spouseMonthlyExpenses:{...incomeExpensesData.spouseMonthlyExpenses, [fieldName]: fieldValue}});
    }

    return (
        <div style={{marginBottom: '1rem'}}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                {'Income & Expense Worksheet: Expenses'}
            </h4>
            {props.showExpensesFormError && 
                <Alert variant="danger" className="mt-2">
                <FaCheckCircle className="text-error me-2 fs-6" />
                    {'Please fill all manadatory fields'}
                </Alert>
            }
            <Tabs defaultActiveKey={defaultTab}>
                <Tab eventKey="client" 
                    title={'Client Name'}>
                    <MonthlyExpensesForm inputData={incomeExpensesData.clientMonthlyExpenses} onChangeValue={updateClientInfo}/>
                </Tab>
                {(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly 
                    || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) && 
                <Tab eventKey="spouse" title={'Client Name #2'}>
                    <MonthlyExpensesForm inputData={incomeExpensesData.spouseMonthlyExpenses} onChangeValue={updateSpouseInfo}/>
                </Tab>
                }
            </Tabs>
        </div>
    )
};

export default MonthlyExpenses;