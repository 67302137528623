import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import "../styles.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import appstoreIcon from "../../assets/appstore-icon.png";
import playstoreIcon from "../../assets/playstore-icon.png";
import Table from "react-bootstrap/Table";
import Uptick from "../../assets/Uptick.svg";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import Message from "../../components/Message";
import { GetReferralSummary } from "../../queries/GetReferralSummary";
import { emailIsValid } from "../../functions/misc";
import { GetProtectionPlans } from "../../queries/GetProtectionPlans";
import { CreateLead } from "../../queries/CreateLead";
import { GetLeadsByPartner } from "../../queries/GetLeadsByPartner";
import { GetPartner } from "../../queries/GetPartner";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import ExclamationCircle from "../../assets/exclamation-circle-fill.svg";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';

const LEAD_STAUS = {
  Pending: "badge-pending",
  Enrolled: "badge-enrolled",
  Inactive: "badge-inactive",
};

type Lead = {
  FirstName: string;
  LastName: string;
  DangerFlag: boolean;
  LeadStatus: "Pending" | "Enrolled" | "Inactive";
  LeadId: string;
};

type Summary = {
  activeCount: number | null;
  inactiveCount: number | null;
  nextPayoutAmount: number | null;
  nextPayoutDate: string;
  partnerId: string;
  pendingCount: number | null;
  totalCommissionEarned: number | null;
};

type ReferralSummary = {
  ActiveCount: number | null;
  InactiveCount: number;
  NextPayoutAmount: number;
  NextPayoutDate: string;
  PartnerId: string;
  PendingCount: number;
  TotalCommissionEarned: number;
};

type ReferralSummaryResponse = {
  data: {
    GetReferralSummary: ReferralSummary;
  };
};

function PartnerDashboard() {
  const navigate = useNavigate(); 
  const [summary, setSummary] = useState<Summary>({
    activeCount: null,
    inactiveCount: null,
    nextPayoutAmount: null,
    nextPayoutDate: "",
    partnerId: "",
    pendingCount: null,
    totalCommissionEarned: null,
  });

  const [partnerName, setPartnerName] = useState("");

  const [show, setShow] = useState(false);

  const [recommendedProtectionPlanId, setRecommendedProtectionPlanId] =
    useState();

  const [leads, setLeads] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    checkBox1: false,
    checkBox2: false,
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const client = new clientConfig();

  useEffect(() => {
    const getLeadByPartner = async () => {
      const partnerId = await userProfileData();
      client
        .query({
          query: GetLeadsByPartner,
          variables: { PartnerId: partnerId, Limit: 5, NextToken: "1" },
        })
        .then((res: any) => {
          setLeads(res.data.GetLeadsByPartner.LeadSummaries);
        });
    };

    const getPartner = async () => {
      const partnerId = await userProfileData();
      client
        .query({
          query: GetPartner,
          variables: { PartnerId: partnerId },
        })
        .then((res: any) => {
          setPartnerName(
            `${res.data.GetPartner.FirstName} ${res.data.GetPartner.LastName}`
          );
        });
    };
    getLeadByPartner();

    getPartner();
  }, []);

  useEffect(() => {
    validateFormData(
      profile.firstName,
      profile.lastName,
      profile.email,
      profile.phone
    );
  }, [profile]);

  const validateFormData = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) => {
    let _error = error;
    if (!email) {
      error["email"] = "Email can not be empty";
    } else if (!emailIsValid(email)) {
      error["email"] = "Email is invalid";
    } else {
      error["email"] = "";
    }
    if (!firstName) {
      error["firstName"] = "FirstName can not be empty";
    } else {
      error["firstName"] = "";
    }
    if (!lastName) {
      error["lastName"] = "LastName can not be empty";
    } else {
      error["lastName"] = "";
    }

    if (!phone) {
      error["phone"] = "Phone can not be empty";
    } else {
      error["phone"] = "";
    }
    setError(_error);
    setError({ ...error });
  };

  function formatPhoneNumber(str: any) {
    if (str) {
      const phoneNumber = str.replace(/[^\d]/g, "");
      if (phoneNumber.length < 4) {
        return phoneNumber;
      }

      if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.length < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    }

    return "";
  }

  useEffect(() => {
    const getSummary = async () => {
      const partnerId = await userProfileData();
      client
        .query({
          query: GetReferralSummary,
          variables: { partnerId: partnerId },
        })
        .then((res: any) => {
          setSummary({
            activeCount: res.data.GetReferralSummary.ActiveCount,
            inactiveCount: res.data.GetReferralSummary.InactiveCount,
            nextPayoutAmount: res.data.GetReferralSummary.NextPayoutAmount,
            nextPayoutDate: res.data.GetReferralSummary.NextPayoutDate,
            partnerId: res.data.GetReferralSummary.PartnerId,
            pendingCount: res.data.GetReferralSummary.PendingCount,
            totalCommissionEarned:
              res.data.GetReferralSummary.TotalCommissionEarned,
          });
        });
    };
    getSummary();

    const getPlans = async () => {
      const data:any = await client.query({
        query: GetProtectionPlans,
      });
      const protectionPartnerMonitoring = data.data.GetProtectionPlans.find(
        (plan: any) => plan.Name === "ProtectionPartnerMonitoring"
      );
      setRecommendedProtectionPlanId(protectionPartnerMonitoring.Id);
    };

    getPlans();
  }, []);

  const addReferral = async (event: any) => {
    const partnerId = await userProfileData();
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);

    const variables = {
      Email: profile.email,
      FirstName: profile.firstName,
      IsQualified: profile.checkBox1,
      LastName: profile.lastName,
      PartnerId: partnerId,
      Phone: profile.phone.replace(/[^\d]/g, ""),
      RecommendedProtectionPlanId: recommendedProtectionPlanId,
      ConsentToDisclose: profile.checkBox2,
    };

    client
      .mutate({
        mutation: CreateLead,
        variables,
      })
      .then((res: any) => {
        setProfile({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          checkBox1: false,
          checkBox2: false,
        });
        setSubmited(false);
        setShow(true);
      })
      .catch((err: any) => {
       if (err.errors[0].message.includes("Email is associated with an Account already.")) {
        let _error = error;
        error["email"] = "There is an account associated with this email address.";
        setError(_error);
        setError({ ...error });
        return;
       }
        if (
          err.errors[0].message.includes("Email is associated with an existing Lead.")
        ) {
          let _error = error;
          error["email"] = "Email is associated with an existing Lead.";
          setError(_error);
          setError({ ...error });
        }
      });
  };

  const handleOnChange = (event: any) => {
    const newProfile = { ...profile, [event.target.name]: event.target.value };
    setProfile(newProfile);
  };

  const handleOnCheck = (event: any) => {
    const newProfile = {
      ...profile,
      [event.target.name]: event.target.checked,
    };
    setProfile(newProfile);
  };

 const onLeadClick = (lead: Lead) => {
  navigate('/partner-referrals', { state: lead });
 };

  const motionItem = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

  return (
    <>
      <Container className="mt-3 mb-5 tax-risk-report">
        <Row className="justify-content-center g-2">
          <Col lg={10}>
            <Row className="justify-content-between g-2">
              <Col lg={8} className="d-flex align-items-stretch">
                <Card className="border-0 px-5 gradient-card py-3">
                  <Card.Body>
                    <h4 className="text-white content-text fw-bold mb-4 fs-3">
                      {`Welcome Back, ${partnerName}`}
                    </h4>
                    <p className="content-text text-white border-0">
                      Easily keep track of your client referrals, submit a new
                      referral, and always have your total commissions earned at
                      your fingertips.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Header className="bg-white border-0">
                    <h6
                      style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Commission Earned This Period
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    {typeof(summary.nextPayoutAmount)  === "number" ? <motion.h1 key={summary.nextPayoutAmount}
                                className="material-blue text-center ff-Montserrat" {...motionItem}>
                      {`$${summary.nextPayoutAmount}`}
                    </motion.h1> : <h1 className="material-blue text-center ff-Montserrat invisible">$10.10</h1>
                    }
                    <label className="text-muted text-uppercase text-center d-block">
                      USD
                    </label>

                    <div className="content-text"
                      style={{
                        fontSize: "11px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "14px",
                      }}
                    >
                      This amount is not finalized until 15th of the following
                      month.
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Header className="bg-white border-0">
                    <h6
                      style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Enrolled Clients
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <motion.h1 key={summary.activeCount} className="material-blue text-center ff-Montserrat" {...motionItem}>
                      {summary.activeCount}
                    </motion.h1>
                    {/* 
                    <div className="d-flex justify-content-end align-items-center">
                      <img src={Uptick} className="me-2" alt="Uptick" />
                      <label>+2.6%</label>
                    </div>
                    */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Header className="bg-white border-0">
                    <h6
                     style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Pending Enrollments
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    {typeof(summary.pendingCount)  === "number" ?
                      <motion.h1 className="material-blue text-center ff-Montserrat" {...motionItem}>
                        {summary.pendingCount}
                      </motion.h1>
                      :
                      <h1 className="material-blue text-center ff-Montserrat invisible">10</h1>
                    }
                    {/* 
                    <div className="d-flex justify-content-end align-items-center">
                      <img src={Uptick} className="me-2" alt="Uptick" />
                      <label>+2.6%</label>
                    </div>
                    */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Header className="bg-white border-0">
                    <h6
                      style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Inactive Clients
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <motion.h1 key={summary.inactiveCount} className="text-muted text-center ff-Montserrat" {...motionItem}>
                      {summary.inactiveCount}
                    </motion.h1>
                    {/* 
                    <div className="d-flex justify-content-end align-items-center">
                      <img src={Uptick} className="me-2" alt="Uptick" />
                      <label>+2.6%</label>
                    </div>
                    */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Body>
                    <h6
                      style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Referrals Summary
                    </h6>
                    <p className="content-text text-secondary" style={{fontSize: "0.8rem"}}>
                      For a full listing of your Referrals, click the “View My
                      Referrals" link below.
                    </p>
                      <Table className="mb-0 striped referral-table">
                          <tbody>
                            {leads?.map((lead: Lead) => (
                              <tr key={lead.LeadId}>
                                <motion.td key={lead.LeadId} className="py-3 fs-8" style={{fontWeight: 500}} {...motionItem}>{`${lead.FirstName} ${lead.LastName}`}</motion.td>
                                <td className="text-end py-3">
                                  {lead.DangerFlag && (
                                    <span className="me-2 color-red">
                                      <img
                                        src={ExclamationCircle}
                                        className="me-2 material-blue fs-6"
                                        alt="Alert"
                                      />
                                    </span>
                                  )}
                                  <motion.span
                                    key={lead.LeadStatus}
                                    className={`${
                                      LEAD_STAUS[lead.LeadStatus]
                                    } mx-1`} {...motionItem}
                                  >
                                    {lead.LeadStatus}
                                  </motion.span>
                                 <motion.span
                                  key={lead.LeadId}
                                  role="button"
                                  onClick={() => onLeadClick(lead)}
                                  className="material-blue ms-2 fs-8"
                                  {...motionItem}
                                 >
                                  <FaAngleRight />
                                 </motion.span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                      </Table>
                    <div className="d-flex justify-content-end p-3">
                      <a
                        style={{fontWeight: 500}}
                        href="partner-referrals"
                        className="material-blue text-decoration-none"
                      >
                        View My Referrals {">"}
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={8} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Body>
                    <h6
                      style={{ color: "#4C5360!important", fontWeight: "600" }}
                      className="mt-3 ff-Montserrat fs-6"
                    >
                      Submit a Referral
                    </h6>
                    <p className="content-text text-secondary" style={{fontSize: "0.8rem"}}>
                      Fill out your client’s contact information to refer them
                      to Optima’s Protection Plan.
                    </p>
                    <Form>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="">
                          <Form.Label>
                            First Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            value={profile.firstName}
                            name="firstName"
                            onChange={handleOnChange}
                            type="text"
                          />

                          {submited && error.firstName && (
                            <Message
                              message={error.firstName}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>

                        <Form.Group as={Col} controlId="">
                          <Form.Label>
                            Last Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            value={profile.lastName}
                            name="lastName"
                            onChange={handleOnChange}
                            type="text"
                          />
                          {submited && error.lastName && (
                            <Message
                              message={error.lastName}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="">
                          <Form.Label>
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            name="email"
                            value={profile.email}
                            onChange={handleOnChange}
                            type="text"
                          />
                          {submited && error.email && (
                            <Message
                              message={error.email}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>

                        <Form.Group as={Col} controlId="">
                          <Form.Label>
                            Phone <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            name="phone"
                            value={formatPhoneNumber(profile.phone)}
                            onChange={handleOnChange}
                            type="text"
                          />
                          {submited && error.phone && (
                            <Message
                              message={error.phone}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Row>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          className="text-muted fs-8"
                          checked={profile.checkBox1}
                          name="checkBox1"
                          onChange={handleOnCheck}
                          type="checkbox"
                          label="I hereby represent and warrant that I have obtained the proper consent to disclose Client Information to Optima and its affiliates from the respective Client prior to submitting any Client Information contained in the Referral to Optima."
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          className="text-muted fs-8"
                          checked={profile.checkBox2}
                          name="checkBox2"
                          onChange={handleOnCheck}
                          type="checkbox"
                          label="I’ve reviewed the eligibility requirement with this client."
                        />
                      </Form.Group>
                      <Row>
                        <Col md={8}>
                          <button
                            onClick={() => {
                              navigate("/partner-settings-url-qrcode-sharing");
                            }}
                            className="letsdolater"
                            style={{ border: "none", background: "none", fontWeight: 500 }}
                          >
                            Want to refer multiple customers? Use a URL instead{" "}
                            {">"}
                          </button>
                        </Col>

                        <Col md={4} className="d-flex justify-content-end mb-2 mt-0">
                          <button
                            onClick={addReferral}
                            className="btn-xl"
                          >
                            + Add a Referral
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <p className="text-muted text-center">
            All Referral information is updated daily. Please{" "}
            <a
              href="/partner-settings-contact"
              className="text-decoration-none fw-bold"
            >
              Contact Us
            </a>{" "}
            if you’d like to talk to an Optima account expert.
          </p>
          {/*<Col md={10} className="px-0 my-3">*/}
          {/*  <Row className="g-4  align-items-center">*/}
          {/*    <Col md={12} className="text-center">*/}
          {/*      <p className="content-text text-center">*/}
          {/*        Get the FREE Optima Tax App and access your account on the go!*/}
          {/*      </p>*/}
          {/*      <Row className="justify-content-center">*/}
          {/*        <Col md={2}>*/}
          {/*          <a className="appstore-icon" style={{ cursor: "pointer" }}>*/}
          {/*            <img src={appstoreIcon} className="img-fluid" alt="" />*/}
          {/*          </a>*/}
          {/*        </Col>*/}
          {/*        <Col md={2}>*/}
          {/*          <a className="playstore-icon" style={{ cursor: "pointer" }}>*/}
          {/*            <img src={playstoreIcon} className="img-fluid" alt="" />*/}
          {/*          </a>*/}
          {/*        </Col>*/}
          {/*      </Row>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}
        </Row>
      </Container>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose
            onClick={() => {
              setShow(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h5 style={{ textAlign: "center" }} className="color-primary ff-Montserrat fs-2 mt-0">
            Success!
          </h5>
          <p className="content-text">
            We will contact the referral within 48 hours and keep you updated on
            progress.
          </p>
          <div className="d-block mx-auto  text-center">
            <button
              onClick={() => {
                setShow(false);
              }}
              className="btn-continue mb-2 mt-2"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PartnerDashboard;
