import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Message from "../../components/Message";
import { formatZipcode } from "../../functions/misc";
import { StateOptions } from "../../contants";

const FORM_ERROR = {
  streetAddress1: "",
  zipCode: "",
  state: "",
  city: "",
};
function StorefrontAddress({
  mainOrganizationAddress,
  storeFrontOrganizationAddress,
  setStoreFrontOrganizationAddress,
  submitted,
  setStoreOrganizationValidated,
  setSameAsBusinessAddress
}: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };
      if (storeFrontOrganizationAddress.StreetAddress1) {
        error.streetAddress1 = "";
      } else {
        error.streetAddress1 = "Street Address can not be empty";
      }

      if (storeFrontOrganizationAddress.City) {
        error.city = "";
      } else {
        error.city = "City can not be empty";
      }

      if (storeFrontOrganizationAddress.State) {
        error.state = "";
      } else {
        error.state = "State can not be empty";
      }

      if (storeFrontOrganizationAddress.ZipCode) {
        error.zipCode = "";
      } else {
        error.zipCode = "ZIP Code can not be empty";
      }

      setFromErros(error);

      const isAnyError = Object.values(error).find((item) => Boolean(item));
      setStoreOrganizationValidated(isAnyError ? false : true);
    };
    validateForm();
  }, [storeFrontOrganizationAddress]);

  const onChangeAddress = (event: any) => {
    const newBusinessAddress = {
      ...storeFrontOrganizationAddress,
      [event.target.name]:
        event.target.name != "ZipCode"
          ? event.target.value
          : formatZipcode(event),
    };

    setStoreFrontOrganizationAddress(newBusinessAddress);
  };

  const onChangeUseAddress = (event: any) => {
    const storeAddress = { ...storeFrontOrganizationAddress };
    if (event.target.value === "1") {
      setSameAsBusinessAddress(true);
      storeAddress.City = mainOrganizationAddress.City;
      storeAddress.State = mainOrganizationAddress.State;
      storeAddress.StreetAddress1 = mainOrganizationAddress.StreetAddress1;
      storeAddress.StreetAddress2 = mainOrganizationAddress.StreetAddress2;
      storeAddress.ZipCode = mainOrganizationAddress.ZipCode;

      setStoreFrontOrganizationAddress(storeAddress);

      return;
    }
    storeAddress.City = "";
    storeAddress.State = "AL";
    storeAddress.StreetAddress1 = "";
    storeAddress.StreetAddress2 = "";
    storeAddress.ZipCode = "";
    setSameAsBusinessAddress(false);
    setStoreFrontOrganizationAddress(storeAddress);
  };

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Storefront Address
          </h6>
        </div>
      </Col>
      <Row className="mb-3 justify-content-between">
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>
              Is your storefront address the same as your mailing address?
            </Form.Label>
            <Form.Select
              onChange={onChangeUseAddress}
              aria-label="Default select example"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              name="StreetAddress1"
              onChange={onChangeAddress}
              value={storeFrontOrganizationAddress.StreetAddress1}
              type="text"
              placeholder=""
            />

            {submitted && formErrors.streetAddress1 && (
              <Message
                message={formErrors.streetAddress1}
                type="error"
              ></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Street Address 2</Form.Label>
            <Form.Control
              name="StreetAddress2"
              onChange={onChangeAddress}
              value={storeFrontOrganizationAddress.StreetAddress2}
              type="text"
              placeholder=""
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-between">
        <Col>
          <Form.Group controlId="">
            <Form.Label>City </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="City"
              value={storeFrontOrganizationAddress.City}
              onChange={onChangeAddress}
            />

            {submitted && formErrors.city && (
              <Message message={formErrors.city} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>State</Form.Label>
            <Form.Select
              name="State"
              onChange={onChangeAddress}
              value={storeFrontOrganizationAddress.State}
              aria-label="Default select example"
            >
              {StateOptions.map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </Form.Select>

            {submitted && formErrors.state && (
              <Message message={formErrors.state} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>ZIP Code</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ZipCode"
              value={storeFrontOrganizationAddress.ZipCode}
              onChange={(e) => onChangeAddress(e)}
            />

            {submitted && formErrors.zipCode && (
              <Message message={formErrors.zipCode} type="error"></Message>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}

export default StorefrontAddress;
