import React, {useState} from "react";
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHome, faUser, faComments, faBars} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/OTR_optima_blue_solid.svg";

function PartnerSideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  console.log(location.pathname);
  const homeLinks = ["/partner-dashboard"];
  const homeActive = homeLinks.includes(location.pathname);
  const settingActive = [
    "/partner-settings",
    "/partner-settings-reset-password",
    "/partner-settings-documents",
    "/partner-settings-notifications",
    "/partner-settings-delete-account",
    "/partner-settings-contact",
  ].includes(location.pathname);
  const referralsActive = ["/partner-referrals","/tax-risk-report-partner"].includes(location.pathname);

  const onHome = async () => {
    navigate("/partner-dashboard");
  };

  const onAccountRedirect = async () => {
    navigate("/partner-settings");
  };

  const hamburgerMenu = () => {
    if (hamburgerMenuOpen)
      setHamburgerMenuOpen(false);
    else
      setHamburgerMenuOpen(true);
  };

  return (
    <div className={hamburgerMenuOpen ? "sidebar sidebar-mobile" : "sidebar"}>
      <ul className={hamburgerMenuOpen ? "bg-white" : "sidebar-items-hide"}>
        <li style={{ fontSize: '12px'}} className={homeActive ? "active me-2" : ""} onClick={onHome}>
          <FontAwesomeIcon className="me-2" icon={faHome} />
          Home
        </li>
        <li style={{ fontSize: '12px'}}
          className={settingActive ? "active me-2" : ""}
          onClick={onAccountRedirect}
        >
          <FontAwesomeIcon className="me-2" icon={faUser} />
          My Account
        </li>
        <li style={{ fontSize: '12px'}}
          className={referralsActive ? "active me-2" : ""}
          onClick={() => navigate("/partner-referrals")}
        >
          <FontAwesomeIcon className="me-2" icon={faComments} />
          REFERRALS
        </li>
      </ul>
      <div id="hamburger-menu-div">
        <a id="hamburger-icon" className="pt-4 ps-4" onClick={hamburgerMenu}>
          <FontAwesomeIcon icon={faBars} className="fa-2xl" />
        </a>
        <img
            src={logo}
            alt="app-logo"
            className="sidebar-logo"
        />
      </div>
    </div>
  );
}

export default PartnerSideBar;
