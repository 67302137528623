import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTranslation } from "react-i18next";
import { emailIsValid } from "../functions/misc";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GetProfile } from "../queries/getProfile";
import { clientConfig } from "../awsClientConfig";
import {Buffer} from "buffer";
import { Spinner } from "react-bootstrap";
import { RedirectToLegacy } from "../Common/LegacyLogin";
import PortalClientModal from './PortalClientModal';
import useProfileStore from "../store/ProfileStore";
import { motion } from 'framer-motion';
import useMotionStore from "../store/MotionStore";
import { GetPartner } from "../queries/GetPartner";

interface FormError {
  email: string;
  password: string;
}

const LoginPage = () => {
  const navigate = useNavigate();
  const { reset } = useParams();
  const { t } = useTranslation("common");
  const [error, setError] = useState<FormError>({ email: "", password: "" });
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  // const [rememberMe, setRememberMe] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPortalModal, setShowPortalModal] = useState<boolean>(false);
  const [customTitle, setCustomTitle] = useState('');
  const [customBody, setCustomBody] = useState('');
  const { setPrimaryProfile, setSpouseProfile, setPartnerProfile } = useProfileStore();
  const { setMotionLayout } = useMotionStore();
  const client = new clientConfig();
  let location = useLocation();

  useEffect(() => {
    setMotionLayout({
      layoutLoaded: true
    });
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const number = urlParams.get('t');
    if (number) {
      const cleanNumber = number.replace(/\D/g, '');
      if (!t(`customLoginHeaders.${cleanNumber}.Title`).includes("customLoginHeaders")) {
        setCustomTitle(t(`customLoginHeaders.${cleanNumber}.Title`));
        setCustomBody(t(`customLoginHeaders.${cleanNumber}.Body`));
      }
    }
  }, [location]);

  useEffect(() => {
    validateFormData(email, password);
  }, [email, password]);

  const validateFormData = (_email: string, _password: string) => {
    let _error = error;
    if (!email) {
      error["email"] = t("login.error.email.empty");
    } else if (!emailIsValid(email)) {
      error["email"] = t("login.error.email.invalid");
    } else {
      error["email"] = "";
    }
    if (!password) {
      error["password"] = t("login.error.password.empty");
    } else {
      error["password"] = ""
    }
    setError(_error);
    setError({ ...error });
  };
  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  // const handleRememberMe = (e: any) => {
  //   setRememberMe(!rememberMe);
  // };

  const handleSubmit = async (event: any) => {
    setSubmited(true);
    event.preventDefault();
    event.stopPropagation();
    setShowSpinner(true);
    Auth.signIn(email.toLowerCase(), password)
      .then((res) => {
        setShowSpinner(false);

       if (!res.attributes["custom:FirstSignInTimestamp"]) {
         if (!res.attributes["custom:FirstSignInDateTime"] && res.attributes["custom:LegacyProfileId"]) {
           if (process.env.REACT_APP_LEGACY_PROTECTIONPLAN_ALLOWED === "true") {
             setPrimaryProfile({
               Id: "",
               Email: res.attributes["email"],
               LegacyClient: true
             });
            navigate("/profileClient");
            return;
           } else {
             RedirectToLegacy(res.attributes["email"]);
           }
         }
       }

        if (res.attributes["custom:Id"]) {
          if (res.attributes["custom:LegacyProfileId"]) {
           if (process.env.REACT_APP_LEGACY_PROTECTIONPLAN_ALLOWED === "true") {
             setPrimaryProfile({
               Id: res.attributes["custom:Id"],
               Email: res.attributes["email"],
               LegacyClient: true
             });
            navigate("/dashboard");
           } else {
            RedirectToLegacy(res.attributes["email"])
            return
           }
          } else {
           if (res.attributes["custom:Group"] === "profile") {
            client
             .query({
              query: GetProfile,
              variables: { profileId: res.attributes["custom:Id"] },
             })
             .then((res: any) => {
              if (res.data.GetProfile !== null) {
                if (res.data.GetProfile?.ParentId) {
                  setSpouseProfile({
                    Id: res.data.GetProfile.Id,
                    AccountId: res.data.GetProfile.AccountId,
                    ParentId: res.data.GetProfile.ParentId,
                    FirstName: res.data.GetProfile.FirstName,
                    LastName: res.data.GetProfile.LastName,
                  })
                } else {
                  setPrimaryProfile({
                    Id: res.data.GetProfile.Id,
                    Email: res.attributes?.email,
                    LegacyClient: false
                  })
                }
               navigate("/dashboard");
              }
             });
           } else {
             client
               .query({
                 query: GetPartner,
                 variables: { PartnerId: res.attributes["custom:Id"] },
               }).then((res: any) => {
                 setPartnerProfile({
                   Id: res.data.GetPartner.Id,
                   Email: res.data.GetPartner.Email,
                   ApplicationStatus: res.data.GetPartner.ApplicationStatus
                 });
                 navigate("/partner-enrollment");
             })
           }
           return;
          }
        }
        if (!res.attributes["custom:LegacyProfileId"]) {
          if (res.attributes["custom:Group"] === "profile") {
            setPrimaryProfile({
              Id: "",
              Email: res.attributes?.email,
              LegacyClient: false
            });
            navigate("/profileClient");
          } else {
            navigate("/profilePartners");
          }
        }
      })
      .catch((error) => {
        setShowSpinner(false);
        const code = error.code;
        console.log(error.message);
        switch (code) {
          case "NotAuthorizedException":
            let _error1 = error;
            _error1["password"] = error.message;
            setError(_error1);
            setError({ ...error });
            break;
          case "UserNotConfirmedException":
            return UserNotConfirmedRedirection();
          case "UserNotFoundException":
           if (error.message === 'UserMigration failed with error Legacy Protection Plan Client (Portal User).') {
            setShowPortalModal(true);
            break;
           } else {
            let _error2 = error;
            if (error.message === "UserMigration failed with error Invalid Password.") {
             _error2["password"] = "Incorrect username or password.";
            } else {
             _error2["email"] = "User does not exist";
            }
            setError(_error2);
            setError({ ...error });
           }
            break;
          case "PasswordResetRequiredException":
            Auth.forgotPassword(email.toLowerCase()).then((res) => {
              setShowSpinner(false);
              navigate(
                "/resetPassword/" + Buffer.from(email).toString("base64")
              );
            });
            break;
         case "ResourceNotFoundException":
          if (error.message === "Device does not exist.") {
           setShowSpinner(true);
           localStorage.clear();
          }
           Auth.signIn(email.toLowerCase(), password).then((res) => {
            if (!res.attributes["custom:FirstSignInTimestamp"]) {
              if (!res.attributes["custom:FirstSignInDateTime"] && res.attributes["custom:LegacyProfileId"]) {
                if (process.env.REACT_APP_LEGACY_PROTECTIONPLAN_ALLOWED === "true") {
                  navigate("/profileClient");
                } else {
                  RedirectToLegacy(res.attributes["email"]);
                }
              } else {
                navigate("/dashboard");
              }
            }
           });
           break;
          default:
            let _error3 = error;
            _error3["password"] = error.message;
            setError(_error3);
            setError({ ...error });
            break;
        }
      });
  };

  const UserNotConfirmedRedirection = () => {
    setShowSpinner(true);
    Auth.signOut().then((res) => {
      Auth.resendSignUp(email.toLowerCase())
        .then((res) => {
          setShowSpinner(false);
          navigate(`/verifycode/${email}`);
        })
        .catch((error) => {
          console.log(error);
          setShowSpinner(false);
        });
    });
  };

  const motionContainer = {
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const motionItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <DefaultLayout>
      <PageBody className="page-body">
       {showPortalModal && <PortalClientModal showPortalModal={showPortalModal} setShowPortalModal={setShowPortalModal} />}
        <form onSubmit={handleSubmit}>
          <motion.div variants={motionContainer} initial="hidden" animate="visible">
            <motion.div variants={motionItem} style={{listStyleType: 'none'}}>
            <FormTitle>
              {customTitle ? customTitle : reset !== "reset" ? t("login.title") : t("login.resetTitle")}
            </FormTitle>
            <p className="content-text mb-4">{customBody ? customBody : t("login.enterDetail")}</p>
            </motion.div>
            <FormGroup className="mb-3" id="formBasicEmail">
              <motion.div variants={motionItem} style={{listStyleType: 'none'}}>
              <FormLabel>{t("login.email")}</FormLabel>
              <Form.Control
                required
                type="email"
                value={email}
                onChange={handleInputEmail}
                placeholder="Enter email"
                className={
                  "input-no-bg " + (submited && error.email ? "is-invalid" : "")
                }
              />
              {submited && error.email && (
                <Message message={error.email} type="error"></Message>
              )}
              </motion.div>
            </FormGroup>
            <motion.div variants={motionItem}>
            <FormGroup className="mb-3" id="password">
              <FormLabel>{t("login.password")}</FormLabel>
              <div className="position-relative">
                <Form.Control
                  type={pwdType}
                  value={password}
                  placeholder="Password"
                  required
                  onChange={handleInputPassword}
                  className={
                    "input-no-bg " +
                    (submited && error.password ? "is-invalid" : "")
                  }
                />
                <FontAwesomeIcon
                  onClick={togglePassword}
                  className="addOn"
                  icon={pwdIcon}
                />
              </div>
              {submited && error.password && (
                <Message message={error.password} type="error"></Message>
              )}
            </FormGroup>
            <Row>
              {/* <Col>
                <Form.Group className="mb-3 justify-item-center d-block">
                  <Form.Check
                    type="checkbox"
                    label={t("login.rememberme")}
                    onChange={handleRememberMe}
                    id="rememberme"
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <TextButton
                  className="float-end mb-3 text-decoration-underline"
                  style={{color: "#1589d2"}}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/forgetpassword");
                  }}
                >
                  {t("login.btn.forgotPassword")}
                </TextButton>
              </Col>
            </Row>
          </motion.div>
            <motion.div variants={motionItem}>

            <Button type="submit" disabled={showSpinner}
              className="w-100" variant="primary">
              {showSpinner &&
              <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-4"
              />
              {'  '}
              </>
              }
              {t("login.btn.signin")}
            </Button>
            <TermSentence>
              {t("login.sentence.part1")}
              <ForgotPasswordLink
                href="https://optimataxrelief.com/about-us/terms-of-site/"
                target="_blank"
                rel="noreferrer"
                className="text-reset text-decoration-underline"
              >
                {t("login.terms")}{" "}
              </ForgotPasswordLink>
              {t("login.sentence.part2")}
              <ForgotPasswordLink
                href="https://optimataxrelief.com/about-us/privacy/"
                target="_blank"
                rel="noreferrer"
                className="text-reset text-decoration-underline"
              >
                {t("login.privacy")}{" "}
              </ForgotPasswordLink>
            </TermSentence>
            <NotAccount>
              {t("login.dontHaveAccount")}
              <TextButton
                style={{color: "#1589d2"}}
                onClick={(e) => {
                  navigate("/signup");
                }}
              >
                {t("login.btn.signup")}{" "}
              </TextButton>
            </NotAccount>
            </motion.div>
          </motion.div>
        </form>
      </PageBody>
    </DefaultLayout>
  );
};

export default LoginPage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
`;

const FormTitle = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-text-default);
  font-family: 'Montserrat', sans-serif;
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;
const ForgotPasswordLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const TextButton = styled.span`
  color: var(--color-text-default);
  text-align: center;
  cursor: pointer;
  font-weight: 700;
`;

const TermSentence = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  font-style: italic;
`;

const NotAccount = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 1rem;
`;
