import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { Auth } from "aws-amplify";
import { clientConfig } from "../../awsClientConfig";
import { CreateTaxProfessional } from "../../queries/CreateTaxProfessional";
import { CreatePartnerEnrollementAction } from "../../queries/CreatePartnerEnrollementAction";
import { UpdateTaxProfessional } from "../../queries/UpdateTaxProfessional";
import Message from "../../components/Message";

const Col1Software = [
  { lable: "Pro Connect", value: "ProConnect" },
  { lable: "Lacerte Tax", value: "LacerteTax" },
  { lable: "Drake Tax", value: "DrakeTax" },
];

const Col2Software = [
  { lable: "Tax Act", value: "TaxAct" },
  { lable: "Pro Series", value: "ProSeries" },
  { lable: "Other", value: "Other" },
];

const FORM_ERROR = {
  filingSoftware: "",
};

function PartnerSoftware({ progressPercent = 0 }) {
  const {
    next,
    taxProfessional,
    setTaxProfessional,
    enrollmentId,
    taxProfessionalId,
  } = useContext(PartnerEnrollmentFormContext);

  const client = new clientConfig();

  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional.filingSoftware.length >= 1) {
        error.filingSoftware = "";
      } else {
        error.filingSoftware = "Software can not be empty";
      }

      setFromErros(error);
    };

    validateForm();
  }, [taxProfessional]);

  const onChange = (event: any) => {
    const filingSoftware = [...taxProfessional.filingSoftware];
    const index = filingSoftware.indexOf(event.target.name);
    if (event.target.checked) {
      filingSoftware.push(event.target.name);
    } else if (!event.target.checked) {
      filingSoftware.splice(index, 1);
    }
    const newTaxProfessional = {
      ...taxProfessional,
      ...{ filingSoftware: [...filingSoftware] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onSubmitProfessional = async () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
      return;
    }
    const res = await Auth.currentAuthenticatedUser();
    const partnerId = res.attributes["custom:Id"];
    const variables: any = {
      Bookkeeping: taxProfessional.bookkeeping,
      DisciplinaryAction: taxProfessional.disciplinaryAction,
      EoCoverage: taxProfessional.eoCoverage,
      EoInsurance: taxProfessional.eoInsurance,
      FilingSoftware: taxProfessional.filingSoftware,
      IrsAudit: taxProfessional.irsAudit,
      Languages: taxProfessional.languages,
      Licenses: taxProfessional.licenses,
      PartnerId: partnerId,
      PrepCompetency: taxProfessional.prepCompetency,
      Ptin: taxProfessional.ptin,
      ReturnForms: taxProfessional.returnForms,
      ReturnSchedules: taxProfessional.returnSchedules,
      TaxResolution: taxProfessional.taxResolution,
      TaxReturnsPrepared: taxProfessional.taxReturnsPrepared,
      YearsOfExperience: taxProfessional.yearsOfExperience,
    };

    if(!taxProfessional.eoInsurance) {
      variables.EoCoverage = 0.00
    }

    if(variables.IrsAudit) {
      variables.IrsAuditDesc = taxProfessional.irsAuditDesc
    }

    if(variables.DisciplinaryAction) {
      variables.DisciplinaryActionDesc = taxProfessional.disciplinaryActionDesc
    }

    if (taxProfessionalId) {
      variables.Id = taxProfessionalId;
    }

    client
      .mutate({
        mutation: !taxProfessionalId
          ? CreateTaxProfessional
          : UpdateTaxProfessional,
        variables: variables,
      })
      .then(() => {
        client
          .mutate({
            mutation: CreatePartnerEnrollementAction,
            variables: {
              PartnerEnrollmentId: enrollmentId,
              Name: "ProfessionalInformationCompleted",
            },
          })
          .then(() => {
            next();
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">
                What Software Are You Using to File Tax Returns?
              </h1>
              <p className="content-text">Select all that apply</p>
              <Form className="mt-3">
                <Row className="mb-3">
                  <Col md={3}>
                    {Col1Software.map((software) => {
                      const checked = taxProfessional.filingSoftware.includes(
                        software.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          onChange={onChange}
                          name={software.value}
                          type="checkbox"
                          label={software.lable}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  <Col md={3}>
                    {Col2Software.map((software) => {
                      const checked = taxProfessional.filingSoftware.includes(
                        software.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          onChange={onChange}
                          name={software.value}
                          type="checkbox"
                          label={software.lable}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  {submited && formErrors.filingSoftware && (
                        <Message
                          message={formErrors.filingSoftware}
                          type="error"
                        ></Message>
                      )}
                </Row>
              </Form>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
                <button onClick={onSubmitProfessional} className="btn-continue">
                  Submit Application
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerSoftware;
