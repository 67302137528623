import { createContext } from "react";
interface ContextState {
  next: () => void;
  prev: () => void;
  setNextFormId:(id:string)=>void
  nextFormId:string
  s3key:string
  setS3Key:(id:string)=>void
  s3Bucket:string
  setS3Bucket:(id:string)=>void
}

const NewFormContext = createContext({} as ContextState);

export default NewFormContext;

export const { Provider, Consumer } = NewFormContext;
