import TaxReportForm from "../components/taxReport"


const TaxReportPage = () =>{
    return (
        <TaxReportForm/>
    )

}

export default TaxReportPage