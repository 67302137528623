import gql from "graphql-tag";

export const GetProfileReferral = gql`
    query GetProfileReferral($profileId: ID!) {
        GetProfile(ProfileId: $profileId) {
            EnrollmentSource
            PartnerId
        }
    }
`;

export const GetProfileCaf = gql`
    query GetProfileCaf($profileId: ID!) {
        GetProfile(ProfileId: $profileId) {
            CafStatus
            CafStatusUpdatedAt
            EnrollmentSource
        }
    }
`;

export const GetProfileByEmail = gql`
  query GetProfileByEmail($email: AWSEmail!) {
    GetProfileByEmail(Email: $email) {
      Id
      AccountId
      ParentId
      FirstName
      MiddleName
      LastName
      Phone
    }
  }
`;