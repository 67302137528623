import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as QuestionMark } from '../assets/question-mark-no-fill.svg';

const OverlayHelpTooltip:React.FC<{text: string}> = (props) => {
    return(
        <OverlayTrigger placement="auto"
            overlay={<Tooltip id="statetaxtooltip">
            <div>
                <p className="content-text">
                {props.text}
                </p>
                </div>
        </Tooltip>}><QuestionMark className='ms-1'/></OverlayTrigger>
    )
}

export default OverlayHelpTooltip;