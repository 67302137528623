import { createGlobalStyle } from "styled-components";

export const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const GlobalStyle = createGlobalStyle`
    :root {
        --color-black: #000000;
        --color-dark-gray: #4a4a4a;
        --color-white: #ffffff;
        --color-dark-opacity: ${({ theme } : any) => theme.darkOpacity };
        --color-main-blue: #0A579B;
        --color-blue-light: #76AAE6;
        --color-green: #B1E367;
        --color-danger: #dc3545;
        --color-danger-light: #dc3545;

        --breakpoint-s: 420px;
        --breakpoint-m: 720px;
        --breakpoint-l: 1280px;
        --breakpoint-xl: 1440px;
    }
    html {
        --color-text-default: ${({ theme }) => theme.text87 };
        --color-text-second: ${({ theme }) => theme.text60 };
        --color-success: ${({ theme }) => theme.success };
        --color-warning: ${({ theme }) => theme.warning };
        --color-warning-20: ${({ theme }) => theme.warning20 };

        --shadow-default: 0 2px 34px 0 rgba(0, 0, 0, 0.3);

        --highlight-opacity: var(--color-dark-opacity);

        --padding-button-default: 0.5rem 3rem;

        --z-index-notifications: 10000;
        --z-index-modal: 1000;
        --z-index-header: 50;
        --z-index-date-picker: 200;

        --box-shadow-1: 2px 2px 2px rgba(43, 66, 129, 0.25);
        --box-shadow-2: 5px 5px 5px rgba(43, 66, 129, 0.25);
        --box-shadow-3: 8px 8px 8px rgba(43, 66, 129, 0.25);

        --background: ${({ theme }) => theme.body};
        --background-panel: ${({ theme }) => theme.panel};
        --background-dropzone: ${({ theme }) => theme.panel};
        --header-border: ${({ theme }) => theme.border};
        @media screen and (max-width: ${breakpoints.sm}) {
            --font-size-1: 30px;
            --font-size-2: 28px;
            --font-size-3: 22px;
            --font-size-4: 19px;
            --font-size-5: 17px;
            --font-size-6: 14px;
        }

        @media screen and (min-width: ${breakpoints.sm}) {
            --font-size-1: 32px;
            --font-size-2: 30px;
            --font-size-3: 24px;
            --font-size-4: 21px;
            --font-size-5: 18px;
            --font-size-6: 16px;
        }
        body {
            font-size: 0.8rem;
        }
    }
`;

export default GlobalStyle;
