import React, { useContext } from "react";

import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import appApproved from "../../assets/application-approved.svg";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";

function ApplicationApproved({ progressPercent = 0 }) {
  const { next } = useContext(PartnerEnrollmentFormContext);

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <AppProgressBar progressPercent={progressPercent} />
            <Row className="px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="content-box-heading mb-3">Congratulations!</h1>
                <p className="content-text">
                  You have received preliminary approval! Next step is to
                  complete your enrollment documents to be able to refer
                  clients.
                </p>

                <div className="d-flex mt-5 align-items-center">
                  <button onClick={next} className="btn-continue">
                    Continue
                  </button>
                </div>
              </Col>
              <Col lg={5}>
                <img src={appApproved} alt="Tax-report" className="img-fluid" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ApplicationApproved;
