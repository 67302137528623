import React, { useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { US_STATES } from "../../Common/States";
import { Button } from "react-bootstrap";
import { ReturnStates, StateReturnYears } from "./TaxPreparationServiceFormContext";

const BusinessQuestionnaireReturnsForm:React.FC<{
  returnYearsData:StateReturnYears, 
  index: number,
  onValueChange:(event:any, index:number) => void,
  onAdd: ()=> void,
  onRemove: (index: number) => void,
  recordsCount: number}> = ({returnYearsData, index, onValueChange, onAdd, onRemove, recordsCount}) => {
  
  const [showError, setShowError] = useState(false);

  const addHandler = () => {
    if(returnYearsData.year === 0 || returnYearsData.year === 0 ) {
      setShowError(true);
    }else {
      setShowError(false);
      onAdd();
    }
  }

  let years = [];

  let currentYear = new Date().getFullYear();

  let startYear = currentYear - 100;
  startYear = startYear || 1980;
  while (currentYear >= startYear) {
    years.push(currentYear--);
  }

  
  return (
        <Form as={Row} className="mt-2">
          {showError && 
          <p className='fs-8 color-red'>{'Which tax year and how many quarter(s)?'}</p>
          }
          <Row className="mb-3 d-flex justify-content-between">
            <Col md={3} lg={3}>
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Select
                  aria-label="Default select example"
                  value={returnYearsData.year}
                  name="year"
                  onChange={(event) => onValueChange(event, index)}
                >
                  <option value="0">- </option>
                  {years.map((year) => {
                    return <option value={year}>{year}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3} lg={3}>
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Select
                  aria-label="Default select example"
                  value={returnYearsData.quarter}
                  name="quarter"
                  onChange={(event) => onValueChange(event, index)}
                >
                  <option value="0">Select One</option>
                  <option value="1">1 Quarter</option>
                  <option value="2">2 Quarters</option>
                  <option value="3">3 Quarters</option>
                  <option value="4">4 Quarters</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} lg={6} className="d-flex justify-content-end align-items-center px-4">
              {recordsCount === index+1 && 
              <Button variant='btn btn-primary fw-bold'  style={{borderRadius: '28px', paddingInline: '20px'}} onClick={addHandler}>Add</Button>
              }
              {recordsCount !== index+1 && 
              <Button variant='btn btn-danger fw-bold'  style={{borderRadius: '28px', paddingInline: '20px'}} onClick={() => onRemove(index)}>Remove</Button>
              }
            </Col>
            </Row>
        </Form>
  );
}

export default BusinessQuestionnaireReturnsForm;
