import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import { clientConfig } from "../../awsClientConfig";
import { InitiatePartnerEnrollmentInput } from "../../queries/InitiatePartnerEnrollmentInput";
import { GetPartner } from "../../queries/GetPartner";
import { userProfileData } from "../../Common/profileInfo";

type InitiatePartnerEnrollment = {
  data: {
    InitiatePartnerEnrollment: {
      Id: string;
      CompletedAt: string | null
      NextEnrollmentAction: any;
      NextForm: any;
      Partner: {
        Id: string;
        OrganizationId: null | string;
        TaxProfessional: {
          Id: string;
        } | null;
      };
    };
  };
};

function PartnerProgramAccount() {
  const {
    setOrganizationId,
    setEnrollmentId,
    setTaxProfessionalId,
    setApplicationStatus,
    setTaxProfessional,
    setS3Bucket,
    setS3Key,
    NextEnrollmentAction,
    setNextFormId,
  } = useContext(PartnerEnrollmentFormContext);
  const navigate = useNavigate()
  const client = new clientConfig();
  const [nextAction, setNextAction] = useState('')
  const [loading, setLoding] = useState(false)

  useEffect(() => {
    setLoding(true)
    const intialEnrollement = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const partnerId = await userProfileData();
      const variables = {
        partnerId: partnerId,
      };
      client
        .mutate({
          mutation: InitiatePartnerEnrollmentInput,
          variables: variables,
        })
        .then((res: any) => {
          if (res.data.InitiatePartnerEnrollment.Id) {
            client
              .query({
                query: GetPartner,
                variables: {
                  PartnerId: res.data.InitiatePartnerEnrollment.Partner.Id,
                },
              })
              .then((response: any) => {
                if(res.data.InitiatePartnerEnrollment.CompletedAt) {
                  navigate('/partner-dashboard')
                  return
                }
                setTaxProfessionalId(
                  response.data.GetPartner.TaxProfessional?.Id || ""
                );
                setApplicationStatus(
                  response.data.GetPartner.ApplicationStatus
                );

                const intialtaxProfessional = {
                  licenses:
                    response.data.GetPartner.TaxProfessional?.Licenses || [],
                  ptin: response.data.GetPartner.TaxProfessional?.Ptin || "",
                  eoCoverage:
                    response.data.GetPartner.TaxProfessional?.EoCoverage || 249999,
                  eoInsurance:
                    response.data.GetPartner.TaxProfessional?.EoInsurance ||
                    true,
                  languages:
                    response.data.GetPartner.TaxProfessional?.Languages || [],
                  filingSoftware:
                    response.data.GetPartner.TaxProfessional?.FilingSoftware ||
                    [],
                  yearsOfExperience:
                    response.data.GetPartner.TaxProfessional
                      ?.YearsOfExperience || 5,
                  bookkeeping:
                    response.data.GetPartner.TaxProfessional?.Bookkeeping ||
                    true,
                  taxReturnsPrepared:
                    response.data.GetPartner.TaxProfessional
                      ?.TaxReturnsPrepared || 100,
                  returnSchedules:
                    response.data.GetPartner.TaxProfessional?.ReturnSchedules ||
                    [],
                  returnForms:
                    response.data.GetPartner.TaxProfessional?.ReturnForms || [],
                  irsAudit:
                    response.data.GetPartner.TaxProfessional?.IrsAudit || true,
                  irsAuditDesc:
                    response.data.GetPartner.TaxProfessional?.IrsAuditDesc ||
                    "",
                  disciplinaryAction:
                    response.data.GetPartner.TaxProfessional
                      ?.DisciplinaryAction || true,
                  disciplinaryActionDesc:
                    response.data.GetPartner.TaxProfessional
                      ?.DisciplinaryActionDesc || "",
                  prepCompetency:
                    response.data.GetPartner.TaxProfessional?.PrepCompetency ||
                    true,
                  taxResolution:
                    response.data.GetPartner.TaxProfessional?.TaxResolution ||
                    true,
                };
                setTaxProfessional(intialtaxProfessional);
                setEnrollmentId(res.data.InitiatePartnerEnrollment.Id);
                setOrganizationId(
                  res.data.InitiatePartnerEnrollment.Partner.OrganizationId
                );
                setS3Bucket(
                  res.data.InitiatePartnerEnrollment.NextForm?.S3Bucket
                );
                setS3Key(res.data.InitiatePartnerEnrollment.NextForm?.S3Key);
                if (
                  res.data.InitiatePartnerEnrollment?.NextEnrollmentAction ===
                    `CompleteBusinessInformation` ||
                  res.data.InitiatePartnerEnrollment?.NextEnrollmentAction ===
                    `CompleteProfessionalInformation`
                ) {
                  setNextAction(res.data.InitiatePartnerEnrollment?.NextEnrollmentAction)
                } else {
                  NextEnrollmentAction(
                    res.data.InitiatePartnerEnrollment?.NextEnrollmentAction
                  );
                }
                setNextFormId(res.data.InitiatePartnerEnrollment.NextForm?.Id);
                setLoding(false)
              });
          }
        });
    };

    intialEnrollement();
  }, []);

  const onInitiatePartnerEnrollmentInput = async () => {
    NextEnrollmentAction(nextAction)
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box-bg py-5 px-lg-5">
            <Row className="px-lg-5 justify-content-between">
              {loading ? <div>Loading...</div> : <Col lg={6}>
                <h1 className="content-box-heading mb-3">
                  The Optima Partner Program
                </h1>
                <p className="content-text">
                  The Optima Tax Partner Program provides eligible Partners with
                  the ability to generate year-round revenue by enrolling clients
                  into Optima's post-filing products and preparing tax returns on
                  Optima's behalf.{" "}
                </p>

                <div className="d-grid mt-5">
                  
                    <Button
                      onClick={onInitiatePartnerEnrollmentInput}
                      variant="primary"
                    >
                      Give your clients the help they need
                    </Button>
                  
                </div>
              </Col>}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerProgramAccount;
