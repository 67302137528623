import React, { useEffect, useState } from "react";

import "../../pages/navbar.css";
import "../styles.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import moment from "moment";
import shieldlowrisk from "../../assets/shield-low-risk.svg";
import shieldhighrisk from "../../assets/shield-high-risk.svg";
import appstoreIcon from "../../assets/appstore-icon.png";
import playstoreIcon from "../../assets/playstore-icon.png";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import { getTaxRiskReport } from "../../queries/getTaxRiskReport";
import { TaskRiskReport } from "./type";
import LowRiskIcon from "../../assets/riskGaugeLOW.png";
import HighRiskIcon from "../../assets/riskGaugeHIGH.png";
import MediumIcon from "../../assets/riskGaugeMEDIUM.png";
import { formatCurrency, formatPhone } from "../../functions/misc";
import Loading from "../../Common/Loading";
import {GetProtectionPlanEligibility} from "../../queries/GetProtectionPlanEligibility";
import {userProfileData} from "../../Common/profileInfo";
import {GetProfile} from "../../queries/getProfile";
import {GetPhoneNumbers} from "../../queries/GetPhoneNumbers";
import {GetPartnerWithoutTaxProfessional} from "../../queries/GetPartnerWithoutTaxProfessional";
import {GetOrganization} from "../../queries/GetOrganization";
import ArrowDown from '../../assets/arrow-down.svg';
import ArrowUp from '../../assets/arrow-up.svg';
import { Collapse } from "react-bootstrap";

type res = {
  data: {
    GetTaxRiskReport: TaskRiskReport;
  };
};

function ProtectionPlanReport() {
  const client = new clientConfig();
  const { state } = useLocation();
  const [taskReport, setTaskReport] = useState<TaskRiskReport>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [noticesCollapses, setNoticesCollapses] = useState<any>([]);

  const RISk = {
    Low: {
      icon: LowRiskIcon,
      label: "Low Risk",
      buttonName: "Get Protected!",
      route: "",
      nextStep:
          "Congratulations! IRS records indicate that your account is in good standing. If anything changes, we will let you know.",
    },
    Medium: {
      icon: MediumIcon,
      label: "Medium Risk",
      buttonName: "Get Protected!",
      route: "",
      nextStep:
          `Activity on your IRS account indicates you may be at risk for enforcement. Please contact your representative at ${phoneNumber} for more information.`,
    },
    High: {
      icon: HighRiskIcon,
      label: "High Risk",
      buttonName: "Get a FREE Consultation",
      route: "",
      nextStep:
          `Activity on your IRS account indicates you are at high risk. Please contact your representative at ${phoneNumber} as soon as possible for the recommended next steps.`,
    }
  };

  useEffect(() => {
    const fetchtaskReports = async () => {
      const profileId = await userProfileData();

      await client
        .query({
          query: getTaxRiskReport,
          variables: {
            taxRiskReportId: state.taskRiskReportId,
          },
        })
        .then((res: any) => {
          setTaskReport(res.data.GetTaxRiskReport);

          const notices = res.data.GetTaxRiskReport?.NoticesUpdates;
          const noticesCollapsesArray = notices?.map((_noticeUpdate: any, index: number) => {
            return {index: index, show: false}
          });
          setNoticesCollapses(noticesCollapsesArray);
        })
        .catch((error: any) => {
          console.log(error);
        });

      await client
          .query({
            query: GetProfile,
            variables: { profileId: profileId },
          })
          .then((res: any) => {
            if (res.data.GetProfile !== null) {
              client
                  .query({
                    query: GetPartnerWithoutTaxProfessional,
                    variables: {
                      PartnerId: res.data.GetProfile.PartnerId,
                    },
                  })
                  .then((res: any) => {
                    const organizationId = res.data.GetPartner.OrganizationId;

                    if (organizationId) {
                      client
                          .query({
                            query: GetOrganization,
                            variables: {organizationId},
                          })
                          .then((res: any) => {
                            setPhoneNumber(formatPhone(res.data.GetOrganization.Phone));
                          })
                          .catch(() => {
                          });
                    }
                  });
            }
          })
          .catch((error: any) => {
            console.log(error, "error");
          });

    };

    fetchtaskReports();
  }, [state]);

 
  const toogleCollpase = (index: number, collapseStatus:boolean) => {
    const updatedCollpases = noticesCollapses.filter((collpaseItem:any) => (collpaseItem.index !== index))
    .concat([{index: index, show: collapseStatus}]);
    setNoticesCollapses(updatedCollpases);
  }

  const riskIndicators = taskReport?.RiskIndicators;
  const recentActivity = taskReport?.RecentActivity;
  const noticeUpdates = taskReport?.NoticesUpdates;
  const taxLiabilitiesByYear = taskReport?.TaxLiabilitiesByYear;
  const totalLiability = taskReport?.TotalLiability;


  const auditReviewIcon = riskIndicators?.AuditReview
    ? shieldhighrisk
    : shieldlowrisk;
  const collectionActivityIcon = riskIndicators?.CollectionActivity
    ? shieldhighrisk
    : shieldlowrisk;
  const noticeUpdateIcon = riskIndicators?.NoticeUpdate
    ? shieldhighrisk
    : shieldlowrisk;
  const federalComplianceIcon = riskIndicators?.FederalCompliance
    ? shieldhighrisk
    : shieldlowrisk;

  const riskIcon = riskIndicators?(RISk[riskIndicators?.OverallRisk || "Low"]):undefined;

  return (
    <>
      <Container className="mt-3 mb-5 tax-risk-report">
        <Row className="justify-content-center g-2">
          <Col lg={10}>
            <Row className="justify-content-between g-2">
              <Col lg={6} className="d-flex align-items-stretch">
                {riskIcon?(
                <Card className="border-0 px-5">
                  <Card.Body>
                    <h4 className="text-main-dark ff-Montserrat fw-bold mb-4">
                      {`${state.lead.FirstName} ${state.lead.LastName}`}
                    </h4>
                    <img
                      src={riskIcon?.icon}
                      className="w-75 d-block mx-auto mb-5"
                      alt="report"
                    />
                    <p className="content-text">
                      <span style={{ marginRight: 5 }} className="fw-bold">
                        {riskIcon?.label}:
                      </span>
                      {riskIcon?.nextStep}
                    </p>
                    {/* <div className="text-center">
                      <button
                        className="btn-continue mt-4"
                        onClick={taxReportProceted}
                        style={{ minWidth: 190 }}
                      >
                        {riskIcon.buttonName}
                      </button>
                    </div> */}
                  </Card.Body>
                </Card>
                ):(
                  <div style={{alignContent: 'center'}}>
                      <Loading />
                  </div>
              )}
              </Col>
              <Col lg={6} className="d-flex align-items-stretch">
                <Card className="border-0 px-5">
                  <Card.Body>
                    <Form style={{ marginTop: "2rem", textAlign: "center" }}>
                      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Select aria-label="Default select example">
                          <option>September 15, 2022 (Latest)</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group> */}
                      <label>Total Estimated Federal Liability*</label>
                      <h3 className="color-red fw-bold my-3">
                        {formatCurrency(totalLiability?.Amount)}
                      </h3>
                      <label className="d-block">
                        Balances are subject to daily penalties and interest.
                      </label>
                    </Form>
                    <hr />
                    <div className="justify-content-center text-center">
                    <label className="text-center fw-bold ff-Montserrat py-3">
                      RECENT ACTIVITY
                    </label>
                    </div>
                    <Table className="risk-report">
                      <tbody>
                        <tr>
                          <td><label>Type</label></td>
                          <td className="fw-bold" style={{textAlign:'right'}}>
                          <label>{recentActivity?.Type.replaceAll('_', ' ') || "-"}</label>
                          </td>
                        </tr>
                        <tr>
                          <td><label>Amount</label></td>
                          <td className="fw-bold" style={{textAlign:'right'}}>
                          <label>{recentActivity?.Amount?formatCurrency(recentActivity?.Amount):"-"}</label>
                          </td>
                        </tr>
                        <tr>
                          <td><label>Tax Year</label></td>
                          <td className="fw-bold" style={{textAlign:'right'}}>
                          <label>{recentActivity?.TaxYear || "-"}</label>
                          </td>
                        </tr>
                        <tr>
                          <td><label>Date Received</label></td>
                          <td className="fw-bold" style={{textAlign:'right'}}>
                          <label>{recentActivity?.DateReceived
                              ? moment(recentActivity?.DateReceived).format(
                                  "MMM. DD, YYYY"
                                )
                              : "-"}</label>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <hr />
                    <p style={{ fontSize: 11, fontFamily:'Arial' }} className="content-text">
                      It may take up to 2 business days after the funds have
                      been drafted for your payment to post to your account.
                    </p>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="d-flex align-items-stretch">
                <Card
                  style={{
                    background: riskIndicators?.AuditReview ? " #F7E9E9" : "",
                  }}
                  className="text-center border-0 w-100"
                >
                  <Card.Body>
                    <h6 className="fw-bold ff-Montserrat my-3">AUDIT REVIEW</h6>
                    <img
                      src={auditReviewIcon}
                      className="w-50 d-block mx-auto mb-3"
                      alt="lowrisk"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <Card
                  style={{
                    background: riskIndicators?.CollectionActivity
                      ? " #F7E9E9"
                      : "",
                  }}
                  className="text-center border-0 w-100"
                >
                  <Card.Body className="">
                    <h6 className="fw-bold ff-Montserrat my-3">
                      COLLECTION ACTIVITY
                    </h6>
                    <img
                      src={collectionActivityIcon}
                      className="w-50 d-block mx-auto mb-3"
                      alt="lowrisk"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <Card
                  style={{
                    background: riskIndicators?.NoticeUpdate ? " #F7E9E9" : "",
                  }}
                  className="text-center border-0 w-100"
                >
                  <Card.Body>
                    <h6 className="fw-bold ff-Montserrat my-3">
                      NOTICES & UPDATES
                    </h6>
                    <img
                      src={noticeUpdateIcon}
                      className="w-50 d-block mx-auto mb-3"
                      alt="lowrisk"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <Card
                  style={{
                    background: riskIndicators?.FederalCompliance
                      ? " #F7E9E9"
                      : "",
                  }}
                  className="text-center border-0 w-100"
                >
                  <Card.Body>
                    <h6 className="fw-bold ff-Montserrat my-3">
                      FEDERAL COMPLIANCE
                    </h6>
                    <img
                      src={federalComplianceIcon}
                      className="w-50 d-block mx-auto mb-3"
                      alt="lowrisk"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Body>
                    <h6 className="fw-bold ff-Montserrat mb-3 text-center">
                      NOTICES & UPDATES
                    </h6>
                    <div className="border rounded mb-3">
                      <Table className="mb-0">
                        <tbody>
                          {noticeUpdates?.map((notice, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="fw-bold"><label>Tax Year</label></td>
                                  <td className="fw-bold text-end">
                                    <label>{notice.TaxYear}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="color-red fw-bold">{notice.NoticeIssued}</label>
                                  </td>
                                  <td className="text-end">
                                    {notice.NoticeDescription &&
                                    <span>
                                      {
                                        (noticesCollapses?.find((collpase:any) => collpase.index === index))?.show?
                                        <img
                                        src={ArrowUp}
                                        alt="up"
                                        className="cursor-pointer"
                                        onClick={() => {toogleCollpase(index, false)}}
                                        />
                                        :
                                        <img
                                        src={ArrowDown}
                                        alt="down"
                                        className="cursor-pointer"
                                        onClick={() => {toogleCollpase(index, true)}}
                                        />
                                      }
                                    </span>
                                    }
                                  </td>
                                </tr>
                                <Collapse in={(noticesCollapses?.find((collpase:any) => collpase.index === index))?.show}>
                                <tr>
                                  <td colSpan={2}>
                                    <label>{notice.NoticeDescription}</label>
                                  </td>
                                </tr>
                                </Collapse>
                                <tr>
                                  <td><label>Notice Issued</label></td>

                                  <td className="text-end">
                                  <label>{notice.Date
                                      ? moment(notice.Date).format(
                                          "MMM D, YYYY"
                                        )
                                      : ""}</label>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="d-flex align-items-stretch">
                <Card className="border-0 w-100">
                  <Card.Body>
                    <h6 className="fw-bold ff-Montserrat mb-3 text-center">
                      FEDERAL TAX LIABILITY BY YEAR
                    </h6>
                    <div className="border rounded mb-3">
                      <Table className="mb-0">
                        <tbody>
                          {taxLiabilitiesByYear?.map((taxLiabilities) => {
                            return (
                              <>
                                <tr>
                                  <td className="fw-bold">
                                  <label>{taxLiabilities.TaxYear}</label>
                                  </td>
                                  <td className="fw-bold text-end">
                                    {taxLiabilities.FilingStatus.toLocaleLowerCase() ===
                                    "Unfiled".toLocaleLowerCase() ? (
                                      <span
                                        className="badge-filled"
                                        style={{ background: "#ed2926" }}
                                      >
                                        Not Filed
                                      </span>
                                    ) : (
                                      <span className="badge-filled">
                                        {taxLiabilities.FilingStatus.replaceAll(
                                          "_",
                                          "-"
                                        )}
                                      </span>
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <label
                                      style={{ marginBottom: 10 }}
                                      className="d-block"
                                    >
                                      Current Balance
                                    </label>
                                    <label>CSED</label>
                                  </td>

                                  <td className="text-end">
                                    {" "}
                                    <label
                                      style={{ marginBottom: 10 }}
                                      className="d-block fw-bold"
                                    >
                                      {formatCurrency(taxLiabilities.CurrentBalance)}
                                    </label>
                                    <label>
                                      <small>
                                        {taxLiabilities.EstCsed
                                          ? moment(
                                              taxLiabilities.EstCsed
                                            ).format("MMM D, YYYY")
                                          : "-"}
                                      </small>
                                    </label>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <p className="text-muted text-center mb-0 mt-4">
            *This is a projected balance based on a hypothetical substitute for
            return using income information reported to the Internal Revenue
            Service.
          </p>
          <p className="text-muted text-center">
            Please be advised that if you file or filed jointly, your spouse's tax
            situation may not be fully captured in your report.
          </p>
          {/*<Col md={10} className="px-0 my-3">*/}
          {/*  <Row className="g-4  align-items-center">*/}
          {/*    <Col md={12} className="text-center">*/}
          {/*      <p className="content-text text-center">*/}
          {/*        Get the FREE Optima Tax App and access your account on the go!*/}
          {/*      </p>*/}
          {/*      <Row className="justify-content-center">*/}
          {/*        <Col md={2}>*/}
          {/*          <a className="appstore-icon" style={{ cursor: "pointer" }}>*/}
          {/*            <img src={appstoreIcon} className="img-fluid" alt="" />*/}
          {/*          </a>*/}
          {/*        </Col>*/}
          {/*        <Col md={2}>*/}
          {/*          <a className="playstore-icon" style={{ cursor: "pointer" }}>*/}
          {/*            <img src={playstoreIcon} className="img-fluid" alt="" />*/}
          {/*          </a>*/}
          {/*        </Col>*/}
          {/*      </Row>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}
        </Row>
      </Container>

      
    </>
  );
}

export default ProtectionPlanReport;
