import React, { useContext, useState, useEffect } from "react";

import "../../pages/navbar.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import Message from "../../components/Message";

const Col1Languages = ["English", "Spanish", "Chinese", "Vietnamese"];

const Col2Languages = ["Korean", "Hindi", "French", "Other"];

const FORM_ERROR = {
  language: "",
};

function PartnerLanguage({ progressPercent = 0 }) {
  const { next, taxProfessional, setTaxProfessional } = useContext(
    PartnerEnrollmentFormContext
  );

  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional.languages.length >= 1) {
        error.language = "";
      } else {
        error.language = "Languages can not be empty";
      }

      setFromErros(error);
    };

    validateForm();
  }, [taxProfessional]);

  const onChange = (event: any) => {
    const language = [...taxProfessional.languages];
    const index = language.indexOf(event.target.name);

    if (event.target.checked) {
      language.push(event.target.name);
    } else if (!event.target.checked) {
      language.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ languages: [...language] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onNext = () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
      return;
    }
    next();
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">Languages</h1>
              <p className="content-text">
                Select any language you speak fluently
              </p>
              <Form className="mt-3">
                <Row className="mb-3">
                  <Col md={3}>
                    {Col1Languages.map((language: string) => {
                      const checked = taxProfessional.languages.includes(language);
                      return (
                        <Form.Check
                          className="content-text"
                          onChange={onChange}
                          name={language}
                          type="checkbox"
                          label={language}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  <Col md={3}>
                    {Col2Languages.map((language: string) => {
                      const checked = taxProfessional.languages.includes(language);
                      return (
                        <Form.Check
                          className="content-text"
                          onChange={onChange}
                          name={language}
                          type="checkbox"
                          label={language}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  {submited && formErrors.language && (
                        <Message
                          message={formErrors.language}
                          type="error"
                        ></Message>
                      )}
                </Row>
              </Form>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
                <button onClick={onNext} className="btn-continue">
                  Continue
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerLanguage;
