import React from "react";
import "../../../pages/navbar.css";
import "../../styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { userProfileData } from "../../../Common/profileInfo";
import { GetProfile } from "../../../queries/getProfile";
import { clientConfig } from "../../../awsClientConfig";

function ProtectionPlanEnroll() {
  const navigate = useNavigate();
  const client = new clientConfig();

  const getProtected = async () => {
    const profileId = await userProfileData();
    client
        .query({
          query: GetProfile,
          variables: { profileId: profileId },
        })
        .then((res: any) => {
          if (res.data.GetProfile !== null) {
            if (res.data.GetProfile.EnrollmentSource === "Referral") {
              navigate("/res-protection-plan");
            } else {
              navigate("/protection-plan");
            }
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
  };
  return (
      <>
        <Container className="mt-3 mb-5">
          <Row className="justify-content-center">
            <Col md={10} className="protection-plan-intro-bg py-5 px-lg-5">
              <Row className="px-lg-5 align-items-center">
                <Col lg={6}>
                  <h1 className="content-box-heading mb-3">
                    Get Protected.<br />
                    Stay Protected!
                  </h1>

                  <p className="content-text">
                    With $10 Million Lifetime Audit Defense we've got your back if
                    you're ever audited by the IRS. Our plans also include 
                    Comprehensive Tax Risk Monitoring to keep you updated on key IRS notices,
                    recent activities, and even providing your IRS collections risk level.
                    You can monitor your IRS balance to stay in the know, get discounted tax
                    resolution fees should you ever need them, and professional tax preparation.
                  </p>
                  <div className="d-flex justify-content-between mt-5 align-items-center">
                    <a className="letsdolater" onClick={() => navigate("/dashboard")}>Lets do this later...</a>
                    <button className="btn-continue" style={{width: '50%', fontWeight: 'bold'}} onClick={getProtected}>Get Protected</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default ProtectionPlanEnroll;
