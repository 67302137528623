import React, { useContext, useEffect, useState } from "react";
import "../../../pages/navbar.css";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewFormContext from "./NewFormContext";

import { Storage } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import { clientConfig, SetS3Config } from "../../../awsClientConfig";
import { userProfileData } from "../../../Common/profileInfo";
import { CompleteProfileForm } from "../../../queries/CompleteProfileForm";
import Loading from "../../../Common/Loading";
import { Breadcrumb } from "react-bootstrap";
import { GetFreeTaxRiskReportEligibility } from "../../../queries/GetFreeTaxRiskReportEligibility";
import { InitiateEnrollment } from "../../../queries/InitiateEnrollment";
import { GetProfileCaf } from "../../../graphql/one-api/custom-queries/Profile";

function TaxInformatioAuthorization({ progressPercent = 0 }) {
  const { next, nextFormId, s3key, s3Bucket,prev } =
    useContext(NewFormContext);
  const [irsForm, setIRSForm] = useState("");
  const client = new clientConfig();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const irsForm = await Storage.get(s3key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "image/png",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  const printImage = () => {
    window.print();
  };
  const download = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const Agreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "image/png",
      });
      console.log(Agreement);
      downloadBlob(Agreement.Body, "Form 8821");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  const submitAuth = async () => {
    setSpinner(true)
    console.log(nextFormId)
    const profileId = await userProfileData();
    const variables = {
     ProfileId: profileId,
     FormId: nextFormId
    };

    let TranscriptRequestAttemptsAllowed = true;

   client
    .query({
     query: GetFreeTaxRiskReportEligibility,
     variables: { profileId: profileId },
    })
    .then((res: any) => {
     if (res.data?.GetFreeTaxRiskReportEligibility?.TranscriptRequestAttemptsAllowed === false) {
      TranscriptRequestAttemptsAllowed = false;
     }
    })
    .then(() => {
     client
      .mutate({ mutation: CompleteProfileForm, variables: variables })
       .then((res: any) => {
         if (res.data.CompleteProfileForm) {
          setTimeout(() => {
           client
            .query({
             query: GetProfileCaf,
             variables: { profileId: profileId },
            })
            .then((res: any) => {
             setSpinner(false)
             TranscriptRequestAttemptsAllowed ? navigate("/dashboard") : navigate("/account-settings-documents");
            })
            .catch((error: any) => {
             console.log(error, "error");
            });
          }, 9000);
         }
       })
    })
    .catch((error: any) => {
      console.log(error, "error");
      setSpinner(false)
    });
  };

  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            {spinner ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <Breadcrumb className="customBreadcrumb mt-0">
                  <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings")}
                  >
                    Account Settings
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings-documents")}
                  >
                    Documents
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{"Forms > 8821"}</Breadcrumb.Item>
                </Breadcrumb>
                
                <h1 className="content-box-heading">
                Tax Information Authorization Form 8821
                </h1>
                <div className="text-end">
                  <span className="letsdolater" onClick={printImage}>
                    <FontAwesomeIcon className="" icon={faPrint} /> Print
                  </span>
                  <span
                    style={{ marginLeft: "10px" }}
                    className="letsdolater"
                    onClick={download}
                  >
                    <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                  </span>
                </div>
                <div>
                  <img
                   id={"irsForm"}
                   className="section-to-print"
                    src={irsForm}
                    height="100%"
                    width="100%"
                    alt="Form**21"                  
                  ></img>
                </div>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={prev}
                  >
                    {"<"}Back
                  </span>
                  <button onClick={submitAuth} className="btn-continue" disabled={spinner}>
                  Click to Sign and Date
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxInformatioAuthorization;
