import gql from "graphql-tag";

export const getTaxRiskReport = gql`
  query GetTaxRiskReport($taxRiskReportId: ID!) {
    GetTaxRiskReport(TaxRiskReportId: $taxRiskReportId) {
      Id

      NoticesUpdates {
        Code

        Date

        NoticeIssued

        NoticeDescription

        TaxYear
      }

      ProfileId

      RecentActivity {
        Amount

        TaxYear

        DateReceived

        Type
      }

      ReportDate

      ReportType

      RiskIndicators {
        AuditReview

        CollectionActivity

        FederalCompliance

        NoticeUpdate

        OverallRisk

        OverallRiskDescription
      }

      TaxLiabilitiesByYear {
        CurrentBalance

        EstCsed

        FilingStatus

        TaxYear
      }

      TotalLiability {
        Amount

        TaxAgency

        TaxType
      }
    }
  }
`;
