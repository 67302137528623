import React, { useEffect, useState } from "react";

import { Provider } from "./ReferalClientFormContext";

import ReferralPlan from "./referralPlan";


import BillingInformation from "./billingInformation";
import PaymentDetails from "./paymentDetails";

import EnrolledProtectionPlan from "./enrolledProtectionPlan";
import PersonalInformatiom from "./PersonalInformatiom";
import ServiceReport from "./ServiceReport";
import Signature from "./signature";
import GovermentPhotoIdUploaded from "./governmentPhotoIdUploaded";
import TaxInformatioAuthorization from "./taxInformationAuthorization";
import { userProfileData } from "../../Common/profileInfo";
import { InitiateEnrollment } from "../../queries/InitiateEnrollment";
import { clientConfig } from "../../awsClientConfig";
import Loading from "../../Common/Loading";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";

const renderStep = (step: number, completedPerecent: number) => {
  switch (step) {
    case 0:
      return <ReferralPlan />;
    case 1:
      return <PersonalInformatiom progressPercent={completedPerecent} />;
    case 2:
      return <ServiceReport progressPercent={completedPerecent} />;
    case 3:
      return <Signature progressPercent={completedPerecent} />;
    case 4:
      return <GovermentPhotoIdUploaded progressPercent={completedPerecent} />;
    case 5:
      return <TaxInformatioAuthorization progressPercent={completedPerecent} />;
    case 6:
      return <BillingInformation progressPercent={completedPerecent} />;
    case 7:
      return <PaymentDetails progressPercent={completedPerecent} />;
    case 8:
      return <EnrolledProtectionPlan />;
    case 9:
      return <Loading />;
    default:
      return null;
  }
};


const ReferalClientForm = () => {
  const [enrollmentId, setEnrollmentId] = useState("");
  const [currentStep, setCurrentStep] = useState(9);
  const [nextFormId, setNextFormId] = useState("");
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [billingAddress, setBillingAddress] = useState();
  const [priceDetail, setPriceDetail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const client = new clientConfig();
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      const variables = {
        profileId: profileId,
        type: "ReferralProtectionPlan",
      };
      client
        .mutate({ mutation: InitiateEnrollment, variables: variables })
        .then((res: any) => {
          if (res.data.InitiateEnrollment.Id) {
            setEnrollmentId(res.data.InitiateEnrollment.Id);
            setNextFormId(res.data.InitiateEnrollment.NextForm?.Id);
            setS3Bucket(res.data.InitiateEnrollment.NextForm?.S3Bucket);
            setS3Key(res.data.InitiateEnrollment.NextForm?.S3Key);
            setPriceDetail(res.data.InitiateEnrollment?.ProtectionPlan);
            NextEnrollmentAction(
              res.data.InitiateEnrollment?.NextEnrollmentAction
            );
          }
        })
        .catch((error: any) => {
          navigate("/dashboard");
        });
    };

    fetchData();
  },[]);

  const NextEnrollmentAction = (nextAction: string) => {
    if (nextAction === "CompleteInformation") {
      setCurrentStep(0);
    }
    if (nextAction === "CompleteServiceAgreement") {
      setCurrentStep(2);
    }
    if (nextAction === "UploadIdentification") {
      setCurrentStep(4);
    }
    if (nextAction === "CompleteForm8821") {
      setCurrentStep(5);
    }
    if (nextAction === "CompletePayment") {
      setCurrentStep(6);
    }
    if (!nextAction){
      navigate("/dashboard");
    }
  };

  const next = () => {
    if (currentStep === 8) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const completedPerecent = (currentStep / 8) * 100;

  return (
    <Provider
      value={{
        next,
        enrollmentId,
        setEnrollmentId,
        nextFormId,
        setNextFormId,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
        billingAddress,
        setBillingAddress,
        priceDetail,
      }}
    >
      <main>{renderStep(currentStep, completedPerecent)}</main>
    </Provider>
  );
};
export default ReferalClientForm;
