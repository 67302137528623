import gql from "graphql-tag";

export const UpdateTaxReturnBusinessEntity = gql`
  mutation UpdateTaxReturnBusinessEntity(
    $ClientProfileId: ID!
    $TaxPrepBusinessEntityId: String!
    $ProfileId: String
    $Name: String!
    $FirstYear: String!
    $TaxPrepIncomeTaxFormId: String!
    $Form: IncomeTaxForms!
    $PartnersCount: Int
    $StateReturn: Boolean
    $TaxPrepPayrollTaxForms: [UpdateTaxPrepPayrollTaxFormsInput]!
    $TaxReturnBusinessEntityId: String!
    $GrossIncome: Float!
    $Year: String
    $QuarterStateReturns: [UpdateQuarterStateReturnsInput]
  ) {
    UpdateTaxReturnBusinessEntity(ProfileId: $ClientProfileId,
      Input: {TaxPrepBusinessEntity: {FirstYear: $FirstYear, Id: $TaxPrepBusinessEntityId, Name: $Name, ProfileId: $ProfileId}, 
              TaxPrepIncomeTaxForm: {Form: $Form, Id: $TaxPrepIncomeTaxFormId, PartnersCount: $PartnersCount, StateReturn: $StateReturn}, 
              TaxPrepPayrollTaxForms: $TaxPrepPayrollTaxForms, 
              TaxReturnBusinessEntity: {Id: $TaxReturnBusinessEntityId, Year: $Year, GrossIncome: $GrossIncome},
              QuarterStateReturns: $QuarterStateReturns}
    ) {
      TaxPrepBusinessEntity {
        CreatedAt
        FirstYear
        Id
        Name
        ProfileId
        UpdatedAt
      }
      TaxPrepIncomeTaxForms {
        CreatedAt
        Form
        Id
        PartnersCount
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxPrepPayrollTaxForms {
        CreatedAt
        Form
        Id
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxReturnBusinessEntity {
        BusinessEntityId
        CreatedAt
        GrossIncome
        Id
        TaxReturnId
        UpdatedAt
        Year
      }
    }
  }
`;