import React, { useContext, useState, useEffect } from "react";

import "../../pages/navbar.css";
import { FileUploader } from "react-drag-drop-files";
import { Storage } from "aws-amplify";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SetS3Config } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { v4 as uuidv4 } from "uuid";
import Message from "../../components/Message";
import Form from "react-bootstrap/Form";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import DragDropFiles from "../DragDropFiles";

const FORM_ERROR = {
  files: "",
};

function Insurance({ progressPercent = 0 }) {
  const { next, taxProfessional, setTaxProfessional } = useContext(
    PartnerEnrollmentFormContext
  );

  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);
  const [images, setimages] = useState<any>([]);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (images.length >= 1) {
        error.files = "";
      } else {
        error.files =
          "Upload Your Errors & Omissions Insurance Policy can not be empty";
      }

      setFromErros(error);
    };

    validateForm();
  }, [images]);

  useEffect(() => {
    loadImages();
  }, []);

  const loadImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    try {
      await Storage.list(`Partners/${profileId}/Insurance/`, {
        // contentType: "image/png", // contentType is optional
        customPrefix: { public: "" },
        level: "public",
        // tagging: "ExternalClearance=Profile",
      }).then((res) => {
        console.log(res);
        getImages(res);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const getImages = async (url: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    let getAllImages: any = [];
    url?.results?.forEach((element: any) => {
      getAllImages.push(loadSingleImage(element));
    });
    Promise.all(getAllImages).then((values) => {
      setimages(values);
    });
  };

  const loadSingleImage = async (url: any) => {
    const ispdf = isPdf(url.key);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const imageData: string = await Storage.get(url.key, {
      level: "public",
      contentType: contentType,
      customPrefix: { public: "" },
    });

    let imageObj = {
      src: imageData,
      key: url.key,
    };

    return imageObj;
  };

  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }

  const handleRemoveImage = async (imageUrl: string) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    try {
      await Storage.remove(imageUrl, {
        // contentType: "image/png", // contentType is optional
        customPrefix: { public: "" },
        level: "public",
        // tagging: "ExternalClearance=Profile",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const handleChange = async (file: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const extension = getExtension(file.name);
    const ispdf = isPdf(file.name);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const profileId = await userProfileData();
    const uuid = uuidv4();

    try {
      await Storage.put(`Partners/${profileId}/Insurance/${uuid}.${extension}`, file, {
        contentType: contentType, // contentType is optional
        customPrefix: { public: "" },
        tagging: "ExternalClearance=Partner",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const onChange = (event: any) => {
    const value =
      event.target.name === "eoInsurance"
        ? event.target.value === "1"
          ? true
          : false
        : Number(event.target.value);
    const newTaxProfessional = {
      ...taxProfessional,
      ...{ [event.target.name]: value },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onNext = () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError && taxProfessional.eoInsurance) {
      return;
    }

    next();
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading mb-3">
                Do You Have Errors & Omissions Insurance?{" "}
              </h1>

              <Form>
                <Row className="mb-3 justify-content-between">
                  <Col md={2}>
                    <Form.Select
                      onChange={onChange}
                      name="eoInsurance"
                      aria-label="Default select example"
                      value={taxProfessional.eoInsurance ? "1" : "0"}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Col>
                  <Col md={4}>
                    {taxProfessional.eoInsurance && (
                      <Form.Group controlId="">
                        <Form.Label>Coverage Amount</Form.Label>

                        <Form.Select
                          name="eoCoverage"
                          onChange={onChange}
                          aria-label="Default select example"
                          value={taxProfessional.eoCoverage}
                        >
                          <option value="249999">Less than 250,000</option>
                          <option value="250000">$250,000</option>
                          <option value="500000">$500,000</option>
                          <option value="1000000">$1,000,000</option>
                          <option value="1000001">$1,000,000+</option>
                        </Form.Select>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Form>
              {taxProfessional.eoInsurance && (
                <>
                  <h6 className="ff-Montserrat color-primary">Upload Your Errors & Omissions Insurance Policy</h6>
                  <p className="content-text">
                    Please select and upload a copy of your insurance policy
                    that you currently hold.
                  </p>
                </>
              )}

              {taxProfessional.eoInsurance && (
                <Col md={12} className="mb-3">
                  {/* <FileUploader
                    handleChange={handleChange}
                    name="file"
                    mu
                    // types={fileTypes}
                    children={
                      <>
                        <img
                          src={require("../../assets/placeholder-img.png")}
                          width="120"
                          alt="browse"
                          className="d-block mx-auto"
                        />

                        <h5 className="text-center browse">
                          Browse for a file or drag here
                        </h5>
                        <p className="text-center privacy">
                          Supported File Types: JPG, JPG 2000, PNG, PDF
                        </p>
                      </>
                    }
                  /> */}
                  <DragDropFiles onChange={handleChange} />
                </Col>
              )}
              {taxProfessional.eoInsurance && (
                <div>
                  <div
                    style={{ flexWrap: "wrap" }}
                    className="col px-lg-2 d-flex py-2"
                  >
                    {images?.map((image: any) => {
                      return (
                        <div
                          style={{
                            width: 215,
                            height: 142,
                            objectFit: "cover",
                            borderWidth: 2,
                            borderColor: "#ddd",
                            borderStyle: "solid",
                            textAlign: "center",
                            marginLeft: 10,
                            marginBottom: 50,
                          }}
                        >
                          {isPdf(image?.key) ? (
                            <iframe
                              src={image?.src}
                              title="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          ) : (
                            <img
                              src={image?.src}
                              alt="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          )}
                          <a
                            href="/"
                            className="fw-bold text-decoration-none mt-2 delete-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveImage(image?.key);
                            }}
                          >
                            <FaTrashAlt className="me-1" />
                            Delete
                          </a>
                        </div>
                      );
                    })}
                    {submited && formErrors.files && (
                      <Message
                        message={formErrors.files}
                        type="error"
                      ></Message>
                    )}
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
                <button onClick={onNext} className="btn-continue">
                  Continue
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Insurance;
