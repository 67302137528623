import gql from "graphql-tag";
export const PurchaseProtectionPlan = gql`
  mutation PurchaseProtectionPlan(
    $EnrollmentId: ID!
    $PaymentMethodToken: ID!
    $PromotionCode: String
    $PromotionCodeType: PromotionCodeType
  ) {
    PurchaseProtectionPlan(
      Input: {
        EnrollmentId: $EnrollmentId
        PaymentMethodToken: $PaymentMethodToken
        PromotionCode: $PromotionCode
        PromotionCodeType: $PromotionCodeType
      }
    ) {
      Id
      CreatedAt
      InitiatedAt
      Status
      TranscriptRequestId
      Type
    }
  }
`;
