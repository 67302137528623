import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTranslation } from "react-i18next";
import { emailIsValid } from "../functions/misc";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface FormError {
  email: string;
  passcode: string;
  password: string;
  confirmpassword: string;
  newPasscode: string;
}

const SetPassword = () => {
  const { subId } = useParams();

  const STEP = {
    init: "init",
    sentCode: "sent_code",
    updatePassword: "updatePassword",
    done: "done",
  };
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const [error, setError] = useState<FormError>({
    email: "",
    passcode: "",
    password: "",
    confirmpassword: "",
    newPasscode: "",
  });
  const [submited, setSubmited] = useState(true);
  const [step, setStep] = useState(STEP.sentCode);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [submited2, setSubmited2] = useState(false);
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [confirmPwdIcon, setConfirmPwdIcon] = useState(faEyeSlash);
  const [confirmPwdType, setConfirmPwdType] = useState("password");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [ShowSpinner, setShowSpinner] = useState(true);
  const [isresent, setIsResent] = useState(false);

  var codeSent = false

  useEffect(() => {  
    subId && handleForgotPassword(subId.toString());
  }, [subId]);

  useEffect(() => {
    validateForm(email, verifyCode, password, confirmpassword);
  }, [email, verifyCode, password, confirmpassword]);

  const validateForm = (
    _email: string,
    _passcode: string,
    _password: string,
    _confirmpassword: string
  ) => {
    let _error = error;

    if (!_passcode) {
      _error["passcode"] = t("forgetPage.error.passcode.empty");
    } else {
      _error["passcode"] = "";
    }

    if (!_password) {
      _error["password"] = t("forgetPage.error.password.empty");
    } else {
      _error["password"] = "";
    }

    if (!_confirmpassword) {
      _error["confirmpassword"] = t("forgetPage.error.confirmPassword.empty");
    } else if (_password !== _confirmpassword) {
      _error["confirmpassword"] = t(
        "forgetPage.error.confirmPassword.mismatch"
      );
    } else {
      _error["confirmpassword"] = "";
    }

    setError(_error);
    setError({ ...error });
  };

  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = (value: string) => {
    if(!codeSent){
    codeSent = true
    console.log(value)
    Auth.forgotPassword(value)
      .then((res) => {
        setEmail(res.CodeDeliveryDetails.Destination);
        setShowSpinner(false);
        setEmailSent(true);
        setTimeout(() => {
          setStep(STEP.sentCode);
        }, 2000);
      })
      .catch((error) => {
        const code = error.code;
        switch (code) {
          case "UserNotFoundException" || "InvalidParameterException":
            setShowSpinner(false);
            return userNotFoundException();
          case "LimitExceededException":
            setShowSpinner(false);
            const errorCopy = { ...error };
            errorCopy.email =
              "Attempt limit exceeded, please try after some time.";
            return setError(errorCopy);
          default:
            setShowSpinner(false);
            const errorCopyDef = { ...error };
            errorCopyDef.email = error.message;
            return setError(errorCopyDef);
        }
      });
    }
  };

  const sendCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);
    if (error.email) {
      return;
    }

    setShowSpinner(true);

    handleForgotPassword(email.toLowerCase());
  };

  const userNotFoundException = () => {
    const errorCopy = { ...error };
    errorCopy.email = "Email doesn't exist";
    setError(errorCopy);
  };

  const handleInputPasscode = (e: any) => {
    let _error = error;
    _error["newPasscode"] = "";
    setError(_error);
    setError({ ...error });
    setVerifyCode(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleInputConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  const toggleConfirmPassword = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
      setConfirmPwdIcon(faEye);
      return;
    }
    setConfirmPwdType("password");
    setConfirmPwdIcon(faEyeSlash);
  };

  const resetPassword = () => {
    setSubmited2(true);
    if (error.password || error.confirmpassword) {
      return;
    }

    Auth.forgotPasswordSubmit(subId?.toString() || "", verifyCode, password)
      .then((res) => {
        setTimeout(() => {
          setStep(STEP.done);
        }, 2000);
      })
      .catch((error) => {
        setSubmited2(false);
        const code = error.code;
        if (code === "CodeMismatchException") {
          setStep(STEP.sentCode);
          setIsResent(false);
          const errorCopy = { ...error };
          errorCopy.newPasscode =
            "The code entered is invalid. Please verify the code or request a new passcode.";
          return setError(errorCopy);
        } else if (code === "InvalidPasswordException") {
          const errorCopy = { ...error };
          errorCopy.passcode = error.message;
          return setError(errorCopy);
        } else {
          const errorCopy = { ...error };
          errorCopy.passcode = error.message;
          return setError(errorCopy);
        }
      });
    // Todo: send new password
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);
    if (error.passcode) {
      return;
    }

    setStep(STEP.updatePassword);
  };

  const resendPasscode = () => {
    setIsResent(false);
    const resendEmail = subId ? subId : email?.toLocaleLowerCase();
    Auth.forgotPassword(resendEmail || "")
      .then((res) => {
        setIsResent(true);
      })
      .catch((err) => {
        const code = err.code;
        switch (code) {
          case "LimitExceededException":
            setIsResent(false);
            const errorCopy = { ...error };
            errorCopy.newPasscode =
              "Attempt limit exceeded, please try after some time.";
            return setError(errorCopy);
          default:
            setIsResent(false);
            const errorCopy2 = { ...error };
            errorCopy2.newPasscode = err.message;
            return setError(errorCopy2);
        }
      });
  };

  return (
    <DefaultLayout>
      {step === STEP.done && (
        <PageBody>
          <FormTitle>{t("forgetPage.verifySuccess")}</FormTitle>
          <Button
            className="w-100"
            onClick={(e) => {
              navigate("/login");
            }}
          >
            {t("forgetPage.btn.signin")}
          </Button>
        </PageBody>
      )}
      {step === STEP.init && (
        <PageBody>
          <FormTitle>{t("forgetPage.title")}</FormTitle>
          <p>{t("forgetPage.enterSentence")}</p>
          <FormGroup className="mb-3 mt-3" id="passcode">
            <FormLabel>{t("forgetPage.email")}</FormLabel>
            <Form.Control
              value={email}
              placeholder={t("forgetPage.email")}
              onChange={handleInputEmail}
              className={submited && error.email ? "is-invalid" : ""}
            />
            {submited && error.email && (
              <Message message={error.email} type="error"></Message>
            )}
            {emailSent && (
              <Message
                message={t("forgetPage.sentMail")}
                type="success"
              ></Message>
            )}
          </FormGroup>
          <Button className="w-100" disabled={ShowSpinner} onClick={sendCode}>
            {t("forgetPage.btn.sendCode")}
          </Button>
        </PageBody>
      )}
      {step === STEP.sentCode && (
        <PageBody>
          <FormTitle>{t("verifyCodePage.title")}</FormTitle>
          <p>{t("verifyCodePage.enterSentence")}</p>
          <Email>{email}</Email>
          {isresent && (
            <Message
              message={t("verifyCodePage.resentMessage")}
              type="success"
            ></Message>
          )}
          <FormGroup className="mt-3" id="passcode">
            <FormLabel>{t("verifyCodePage.passcode")}</FormLabel>
            <Form.Control
              value={verifyCode}
              placeholder="Passcode"
              required
              onChange={handleInputPasscode}
              className={
                "input-no-bg " +
                (error.newPasscode || error.email ? "is-invalid" : "")
              }
            />

            {error.newPasscode && (
              <Message message={error.newPasscode} type="error"></Message>
            )}
            {error.email && (
              <Message message={error.email} type="error"></Message>
            )}
          </FormGroup>

          <NotAccount>
            <TextButton onClick={resendPasscode}>
              {t("verifyCodePage.btn.resend")}{" "}
            </TextButton>
          </NotAccount>

          <Button className="w-100" onClick={handleSubmit}>
            {t("verifyCodePage.btn.reEnter")}
          </Button>
        </PageBody>
      )}
      {step === STEP.updatePassword && (
        <PageBody>
          <FormTitle>{t("forgetPage.enterYourPassword")}</FormTitle>
          <p>{t("forgetPage.enterSentenceForPassword")}</p>
          <FormGroup className="" id="password">
            <FormLabel>{t("forgetPage.password")}</FormLabel>
            <div className="position-relative">
              <Form.Control
                value={password}
                type={pwdType}
                placeholder={t("forgetPage.password")}
                onChange={handleInputPassword}
                className={
                  "input-no-bg " +
                  (submited2 && error.password ? "is-invalid" : "")
                }
              />
              <FontAwesomeIcon
                onClick={togglePassword}
                className="addOn"
                icon={pwdIcon}
              />
            </div>
            {submited2 && error.password && (
              <Message message={error.password} type="error"></Message>
            )}
          </FormGroup>
          <FormGroup className="mb-3 mt-2" id="confirmpassword">
            <FormLabel>{t("forgetPage.confirmpassword")}</FormLabel>
            <div className="position-relative">
              <Form.Control
                value={confirmpassword}
                type={confirmPwdType}
                placeholder={t("forgetPage.confirmpassword")}
                onChange={handleInputConfirmPassword}
                className={
                  "input-no-bg " +
                  (submited2 && error.confirmpassword ? "is-invalid" : "")
                }
              />
              <FontAwesomeIcon
                onClick={toggleConfirmPassword}
                className="addOn"
                icon={confirmPwdIcon}
              />
            </div>
            {submited2 && error.confirmpassword && (
              <Message message={error.confirmpassword} type="error"></Message>
            )}

            {submited && error.passcode && (
              <Message message={error.passcode} type="error"></Message>
            )}
          </FormGroup>
          <Button className="w-100" onClick={resetPassword}>
            {t("forgetPage.btn.reset")}
          </Button>
        </PageBody>
      )}
    </DefaultLayout>
  );
};

export default SetPassword;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

const FormTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-text-default);
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;

const NotAccount = styled.div`
  text-align: end;
  margin-bottom: 3rem;
  text-decoration: underline;
  color: #1589d2;
`;

const Email = styled.label`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const TextButton = styled.span`
  text-align: center;
  cursor: pointer;
`;
