import { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext from './TaxPreparationServiceFormContext';
import { FaRegWindowClose } from '@react-icons/all-files/fa/FaRegWindowClose';
import { clientConfig } from '../../awsClientConfig';
import { Auth } from 'aws-amplify';
import { GetTaxReturn } from '../../queries/GetTaxReturn';
import Loading from '../../Common/Loading';
import OverlayHelpTooltip from '../../Common/OverlayHelpTooltip';
import { userProfileData } from '../../Common/profileInfo';
import { CreateTaxReturn } from '../../queries/CreateTaxReturn';
import { UpdateTaxReturn } from '../../queries/UpdateTaxReturn';
import { getCurrentTaxYear } from '../../functions/misc';
import useEnrollmentStore from "../../store/EnrollmentStore";
import PaymentCompletedMessage from "./PaymentCompletedMessage";

const FilingTypeForm = () => {
    const {taxPreparationData, setTaxPreparationData, navigateToPage, enrollmentId,
        setQuoteId, setTaxReturnId,completedPagesHandler,setQuotePrice  } = useContext(TaxPreparationServiceFormContext);
    const [showQuestionsModel, setShowQuestionsModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const client = new clientConfig();
    const [taxReturnData, setTaxReturnData] = useState<any>();
    const [showError, setShowError] = useState(false);
    const { taxPrepEnrollment } = useEnrollmentStore();

    useEffect(() => {
      getTaxReturnData();
    },[]);

    const getTaxReturnData = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      //setIsLoading(true);
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturn, variables: variables });
        if(res.data.GetTaxReturn){
          setTaxReturnData(res.data.GetTaxReturn);
          setTaxPreparationData({...taxPreparationData, 
            'filingType': res.data.GetTaxReturn?.TaxPrepFilingType?.Type, 
            'jointFiling': res.data.GetTaxReturn?.TaxPrepFilingType?.Status});
          setQuoteId(res.data.GetTaxReturn?.TaxReturn?.QuoteId);  
          setTaxReturnId(res.data.GetTaxReturn?.TaxPrepFilingType?.TaxReturnId);
          setQuotePrice(res.data.GetTaxReturn?.TaxPrepQuote?.QuotePrice);
        }
      }catch(e){}finally{
        setIsLoading(false);
      }
    };

    const onChangeHandler = (event:any) => {
      let fieldName = event.target.name;
      let fieldValue = event.target.value;
      const fieldType = event.target.type;
      if(fieldType === 'radio') {
        fieldName = fieldName.substring(0, fieldName.indexOf('-'));
        fieldValue = event.target.name.substring(event.target.name.indexOf('-')+1);
      }
      setTaxPreparationData({...taxPreparationData, [fieldName]: fieldValue});
      //setTaxReturnData((prevState:any) => ({...prevState, TaxPrepFilingType: {...prevState?.TaxPrepFilingType, Type: fieldValue}}));
    }

    const handleClose = () => {
        setShowQuestionsModel(false);
    } 

    const continueHandler = async () => {
      if (taxPrepEnrollment?.paymentCompleted) {
        navigateToPage('incomeForm1');
        return;
      }
      if(taxPreparationData.jointFiling) {
        setShowError(false);
        const profileId = await userProfileData();
        if(taxReturnData?.TaxPrepFilingType?.Id){
          const variables = {
            ClientProfileId: profileId,
            FilingId: taxReturnData?.TaxPrepFilingType?.Id,
            FilingStatus: taxPreparationData.jointFiling,
            Type: taxPreparationData.filingType,
            FilingType: taxPreparationData.filingType,
            Active: taxReturnData.TaxPrepQuote.Active,
            EnrollmentId: enrollmentId,
            PrepQuoteId: taxReturnData.TaxPrepQuote.Id,
            Year: taxReturnData.TaxPrepQuote.Year,
            ProfileId: profileId,
            ReturnId: taxReturnData.TaxReturn.Id,
          };
          try{ 
            const res: any = await client.mutate({ mutation: UpdateTaxReturn, variables: variables });
            if(res.data.UpdateTaxReturn){
              setQuoteId(res.data.UpdateTaxReturn?.TaxReturn?.QuoteId);  
              setTaxReturnId(res.data.UpdateTaxReturn?.TaxPrepFilingType?.TaxReturnId);
              setQuotePrice(res.data.UpdateTaxReturn?.TaxPrepQuote?.QuotePrice);
              setTaxPreparationData({...taxPreparationData, 
                'filingType': res.data.UpdateTaxReturn?.TaxPrepFilingType?.Type, 
                'jointFiling': res.data.UpdateTaxReturn?.TaxPrepFilingType?.Status});
            }
          }catch(e){}
        }else {
          const variables = {
            ClientProfileId: profileId,
            FilingStatus: taxPreparationData.jointFiling,
            Type: taxPreparationData.filingType,
            EnrollmentId: enrollmentId,
            Year: getCurrentTaxYear(),
            ProfileId: profileId,
            Status: 'Pending',
          };
          try{ 
            const res: any = await client.mutate({ mutation: CreateTaxReturn, variables: variables });
            if(res.data.CreateTaxReturn){
              setQuoteId(res.data.CreateTaxReturn?.TaxReturn?.QuoteId);  
              setTaxReturnId(res.data.CreateTaxReturn?.TaxPrepFilingType?.TaxReturnId);
              setQuotePrice(res.data.CreateTaxReturn?.TaxPrepQuote?.QuotePrice);
              setTaxPreparationData({...taxPreparationData, 
                'filingType': res.data.CreateTaxReturn?.TaxPrepFilingType?.Type, 
                'jointFiling': res.data.CreateTaxReturn?.TaxPrepFilingType?.Status});
            }
          }catch(e){}
        }
        completedPagesHandler('incomeForm1');
        navigateToPage('incomeForm1');
      }else {
        setShowError(true);
      }
    }

    return (
        <>
        {isLoading? <Loading /> : 
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
             <AppProgressBar progressPercent={5} />
              <Row className="justify-content-center">
                <Col md={12}>
                  {showError && 
                          <p className='fs-8 color-red mt-3'>Please select either Yes or No.</p>
                    }
                  <div className='d-flex justify-content-between mb-3'>
                    <h1 className="content-box-heading mb-3">
                    {`Do You Want To File Married Filing Jointly For ${getCurrentTaxYear()}?`}
                    {/*'What Type Of Return(s) Do You Need To File?' */} 
                    </h1>

                    {/* 
                    <div style={{marginTop: "2.5rem"}}>
                      <OverlayHelpTooltip  text={'Examples of Federal tax forms that fall under business returns would be 1065(Partnership Income), 1120(C-Corp Income), 1120-S(S-Corp Income), 941(payroll), and 940 (payroll)'}/>
                    </div>
                    */}
                  </div>
                  {taxPrepEnrollment?.paymentCompleted && <PaymentCompletedMessage />}
                  {/* 
                  <p className="content-text" style={{fontSize: '14px'}}>
                  Please note that business tax returns are only needed if you need payroll returns or own/operate a partnership or corporation
                  </p>
                  
                  <Row className="justify-content-start my-4">
                    <Col xl={4} lg={4}>
                    <Form.Select
                      name="filingType"
                      onChange={onChangeHandler}
                      aria-label="Default select example"
                      value={taxPreparationData.filingType}
                    >
                      <option value="Personal">{'Personal'}</option>
                      <option value="Business">{'Business Tax Returns'}</option>
                      <option value="PersonalAndBusiness">{'Personal & Business Tax Returns'}</option>
                    </Form.Select>
                    </Col>
                  </Row>
                  <Row className="justify-content-start mt-2">
                    <Col xl={6} lg={6}>
                      <Form.Label className="fs-10">{`Do you want to file married filing joint for ${getCurrentTaxYear()}?`}</Form.Label>
                    </Col>
                  </Row>
                  */}
                  <Row className="justify-content-start mt-2">
                  <Col md={4} lg={4}>
                        <Form.Check
                          inline
                          type="radio"
                          className='fs-14'
                          label="Yes"
                          name="jointFiling-MarriedFilingJointly"
                          disabled={taxPrepEnrollment?.paymentCompleted}
                          onChange={onChangeHandler}
                          checked={taxPreparationData.jointFiling === 'MarriedFilingJointly'?true:false}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          className='fs-14'
                          label="No"
                          name="jointFiling-Single"
                          disabled={taxPrepEnrollment?.paymentCompleted}
                          onChange={onChangeHandler}
                          checked={taxPreparationData.jointFiling === 'Single'?true:false}
                        />
                    </Col>
                  </Row>
                    <div className="d-flex justify-content-between mt-5 pt-5 align-items-center">
                      <span onClick={() => setShowQuestionsModel(true)}>
                        {" "}
                        <span className="letsdolater">Questions?</span>
                      </span>

                      <button
                        className={"btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={continueHandler}>
                       Continue
                      </button>
                    </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      }
      <Modal centered show={showQuestionsModel} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h5 className="content-box-heading mt-0">Help Information</h5>
        </Modal.Body>
      </Modal>
      </>
    )
}

export default FilingTypeForm;