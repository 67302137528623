import React, { useEffect, useState } from "react";

import "../../pages/navbar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";

import Message from "../../components/Message";

const FORM_ERROR = {
  irsAuditDesc: "",
  disciplinaryActionDesc: "",
};

function PartnerExpreience({
  taxProfessional,
  setTaxProfessional,
  submitted,
  setTaxValidated
}: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional.IrsAudit) {
        if (taxProfessional.IrsAuditDesc) {
          error.irsAuditDesc = "";
        } else {
          error.irsAuditDesc = "IRS Audit Desc Schedules can not be empty";
        }
      } else {
        error.irsAuditDesc = "";
      }

      if (taxProfessional.DisciplinaryAction) {
        if (taxProfessional.DisciplinaryActionDesc) {
          error.disciplinaryActionDesc = "";
        } else {
          error.disciplinaryActionDesc =
            "Disciplinary Action Desc can not be empty";
        }
      } else {
        error.disciplinaryActionDesc = "";
      }

      setFromErros(error);
      const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
      setTaxValidated(isAnyError? false: true)
    };

    validateForm();
  }, [taxProfessional]);

  const onBooleanChange = (event: any) => {
    const value = event.target.value === "1" ? true : false;

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ [event.target.name]: value },
    };

    setTaxProfessional(newTaxProfessional);
  };


  const onDescChange = (event: any) => {
    const newTaxProfessional = {
      ...taxProfessional,
      ...{ [event.target.name]: event.target.value },
    };

    setTaxProfessional(newTaxProfessional);
  };

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Professional Experience
          </h6>
        </div>

        <Form>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Label>Do you provide bookkeeping services?</Form.Label>

              <Form.Select
                name="Bookkeeping"
                onChange={onBooleanChange}
                aria-label="Default select example"
                value={taxProfessional.Bookkeeping ? "1" : "0"}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </Form.Select>
            </Col>
            <Col md={4}>
              <Form.Label>Do you provide tax resolution services?</Form.Label>

              <Form.Select
                name="TaxResolution"
                onChange={onBooleanChange}
                aria-label="Default select example"
                value={taxProfessional.TaxResolution ? "1" : "0"}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </Form.Select>
            </Col>
            <Col md={4}>
              <Form.Label>
                Are you willing to take a tax preparation competency test?
              </Form.Label>

              <Form.Select
                name="PrepCompetency"
                onChange={onBooleanChange}
                aria-label="Default select example"
                value={taxProfessional.PrepCompetency ? "1" : "0"}
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Label>
                Has your practice ever been audited by the IRS?
              </Form.Label>
              <Form.Select
                name="IrsAudit"
                onChange={onBooleanChange}
                aria-label="Default select example"
                value={taxProfessional.IrsAudit ? "1" : "0"}
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Label>
                Have you ever been subject to disciplinary action by any
                regulatory agency?
              </Form.Label>
              <Form.Select
                name="DisciplinaryAction"
                onChange={onBooleanChange}
                aria-label="Default select example"
                value={taxProfessional.DisciplinaryAction ? "1" : "0"}
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6} className="mt-3">
              {taxProfessional.IrsAudit && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>If yes, please describe</Form.Label>
                  <Form.Control
                    onChange={onDescChange}
                    name="IrsAuditDesc"
                    as="textarea"
                    value={taxProfessional.IrsAuditDesc}
                    rows={3}
                  />

                  {submitted && formErrors.irsAuditDesc && (
                    <Message
                      message={formErrors.irsAuditDesc}
                      type="error"
                    ></Message>
                  )}
                </Form.Group>
              )}
            </Col>
            <Col md={6} className="mt-3">
              {taxProfessional.DisciplinaryAction && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>If yes, please describe</Form.Label>
                  <Form.Control
                    onChange={onDescChange}
                    name="DisciplinaryActionDesc"
                    as="textarea"
                    rows={3}
                    value={taxProfessional.DisciplinaryActionDesc}
                  />

                  {submitted && formErrors.disciplinaryActionDesc && (
                    <Message
                      message={formErrors.disciplinaryActionDesc}
                      type="error"
                    ></Message>
                  )}
                </Form.Group>
              )}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default PartnerExpreience;
