import React, { useState, useEffect } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { clientConfig } from "../../awsClientConfig";
import ProfileInfo from "./ProfileInformation";
import BusinessInformation from "./BusinessInformation";
import StoreFrontQuestion from "./StorefrontAddress";
import { GetPartner } from "../../queries/GetPartner";
import { userProfileData } from "../../Common/profileInfo";
import { Auth } from "aws-amplify";
import { UpdatePartner } from "../../queries/updatePartner";
import Message from "../../components/Message";
import { GetOrganization } from "../../queries/GetOrganization";
import { UpdateOrganization } from "../../queries/UpdateOrganization";
import { UpdateOrganizationAddress } from "../../queries/UpdateOrganizationAddress";
import Languages from "./Languages";
import { UpdateTaxProfessional } from "../../queries/UpdateTaxProfessional";
import TaxSoftware from "./TaxSoftware";
import { useNavigate } from "react-router-dom";
import PartnerExpreience from "./PartnerExpreience";
import MailingAddress from "./MailingAddress";
import { CreateOrganizationAddress } from "../../queries/CreateOrganizationAddress";

function PartnerProfile() {
  const [partner, setPartner] = useState<any>({});
  const [partnerProfileValidated, setPartnerProfileValidated] = useState();
  const [organizationValidated, setOrganizationValidated] = useState();
  const [storeOrganizationValidated, setStoreOrganizationValidated] =
    useState();
  const [organizationMailingValidated, setOrganizationMailingValidated] = useState();
  const [submitted, setSubmitted] = useState(false);
  const client = new clientConfig();
  const [success, setSuccess] = useState("");
  const [organizationId, setOrganizationId] = useState();
  const [mainOrganizationAddress, setMainOrganizationAddress] = useState<any>(
    {}
  );
  const navigate = useNavigate();
  const [taxProfessional, setTaxProfessional] = useState<any>({});
  const [organizationAddresses, setOrganizationAddresses] = useState([]);

  const [storeFrontOrganizationAddress, setStoreFrontOrganizationAddress] =
    useState<any>({});

  const [organization, setOrganization] = useState<any>({});
  const [languageValidated, setLanguageValidated] = useState();
  const [taxValidated, setTaxValidated] = useState();
  const [softwareValidated, setSoftwareValidated] = useState();
  const [organizationMailingAddress, setOrganizationMailingAddress] = useState<any>({});
  const [sameAsBusinessAddress, setSameAsBusinessAddress] = useState(false);
  const [mailingAddressType, setMailingAddressType] = useState('Mailing');

  useEffect(() => {
    const fetchApi = async () => {
      if (organizationId) {
        client
          .query({
            query: GetOrganization,
            variables: { organizationId },
          })
          .then((res: any) => {
            setOrganization(res.data.GetOrganization);
           const organizationAddresses = res.data.GetOrganization.OrganizationAddresses;
          setOrganizationAddresses(organizationAddresses);
           let mainAddresses = organizationAddresses.map((address: any) => {
            return address.OrganizationAddressTypes.find(
             (addressTypes: any) => addressTypes.Type === "Main") ? address : undefined
           });
           mainAddresses = mainAddresses?.pop();
           setMainOrganizationAddress(mainAddresses || {});

           let storeAddresses = organizationAddresses.map((address: any) => {
            return address.OrganizationAddressTypes?.find(
             (addressTypes: any) => addressTypes.Type === "Storefront") ? address : undefined
           });
           storeAddresses = storeAddresses.filter((address: any) => {
            return address !== undefined
           })
           storeAddresses = storeAddresses?.pop();
           
            setStoreFrontOrganizationAddress(storeAddresses || {});
          let mailingAddress = organizationAddresses.map((address: any) => {
              return address.OrganizationAddressTypes?.find(
               (addressTypes: any) => addressTypes.Type === "Mailing") ? address : undefined
             });
             mailingAddress = mailingAddress.filter((address: any) => {
              return address !== undefined
             })
             mailingAddress = mailingAddress?.pop();
             setOrganizationMailingAddress(mailingAddress || {});
          })
          .catch((error) => {
           console.log('error', error);
          });
      }
    };

    fetchApi();
  }, [organizationId]);

  useEffect(() => {
    const fetchPartner = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const partnerId = await userProfileData();

      client
        .query({
          query: GetPartner,
          variables: {
            PartnerId: partnerId,
          },
        })
        .then((res: any) => {
          const data = res.data.GetPartner;
          setPartner(data);
          const organizationId = res.data.GetPartner.OrganizationId;
          setOrganizationId(organizationId);
          setTaxProfessional(res.data.GetPartner.TaxProfessional);
        });
    };

    fetchPartner();
  }, []);

  const onSaveChanges = async () => {
    setSubmitted(true);
    if (
      partnerProfileValidated &&
      organizationValidated &&
      storeOrganizationValidated &&
      languageValidated &&
      taxValidated &&
      softwareValidated &&
      organizationMailingValidated
    ) {
      const variables = {
        ...partner,
        Phone: partner?.Phone ? partner?.Phone?.replace(/[^0-9]/g, "") : "",
        Type: partner?.Type === "Yes" ? "TaxProfessionals" : null,
      };
      client
        .mutate({ mutation: UpdatePartner, variables: variables })
        .then((res: any) => {
          if (res.data.UpdatePartner) {
          }
        })
        .catch((error: any) => {});

      const res = await Auth.currentAuthenticatedUser();
      const partnerId = res.attributes["custom:Id"];
      const organVariables = {
        ...organization,
        PartnerId: partnerId,
        Phone: organization?.Phone
          ? organization?.Phone.replace(/[^\d]/g, "")
          : "",
        Storefront: true,
      };
      client
        .mutate({
          mutation: UpdateOrganization,
          variables: organVariables,
        })
        .then(() => {
          const addressVariables = {
            ...mainOrganizationAddress,
          };

          if (mainOrganizationAddress.StreetAddress2) {
            addressVariables.StreetAddress2 =
              mainOrganizationAddress.StreetAddress2;
          } else {
            delete addressVariables.StreetAddress2;
          }
          if(sameAsBusinessAddress){
            addressVariables.OrganizationAddressTypes= [
              { Type: "Main" },
              { Type: "Storefront" },
             ];
          }else {
            addressVariables.OrganizationAddressTypes= [
              { Type: "Main" }
             ];
          }

          if(mailingAddressType === 'Main') {
            addressVariables.OrganizationAddressTypes = [
              ...addressVariables.OrganizationAddressTypes,
              { Type: "Mailing" },
            ]
          }

          client
            .mutate({
              mutation: UpdateOrganizationAddress,
              variables: addressVariables,
            })
            .then(() => {})
            .catch(() => {});

          if(!sameAsBusinessAddress) {
            let storeAddressVariables = {
              ...storeFrontOrganizationAddress,
            };

            if (storeFrontOrganizationAddress.StreetAddress2) {
              storeAddressVariables.StreetAddress2 =
                storeFrontOrganizationAddress.StreetAddress2;
            } else {
              delete storeAddressVariables.StreetAddress2;
            }
            if(!storeAddressVariables.OrganizationId){
              storeAddressVariables = {
                ...storeAddressVariables,
                OrganizationId:organization.Id,
                OrganizationAddressTypes: [
                  { Type: "Storefront" }
                 ]
              }
            }

            if(mailingAddressType === 'Storefront') {
              addressVariables.OrganizationAddressTypes = [
                ...addressVariables.OrganizationAddressTypes,
                { Type: "Mailing" },
              ]
            }

            client
              .mutate({
                mutation: storeAddressVariables.Id?UpdateOrganizationAddress:CreateOrganizationAddress,
                variables: storeAddressVariables,
              })
              .then(() => {})
              .catch((error) => {console.log(error)});
          }
          if(mailingAddressType === 'Mailing'){
            let mailingAddressVariables = {
              ...organizationMailingAddress,
            };

            if (organizationMailingAddress.StreetAddress2) {
              mailingAddressVariables.StreetAddress2 =
              organizationMailingAddress.StreetAddress2;
            } else {
              delete mailingAddressVariables.StreetAddress2;
            }
            if(!mailingAddressVariables.OrganizationId){
              mailingAddressVariables = {
                ...mailingAddressVariables,
                OrganizationId:organization.Id,
                OrganizationAddressTypes: [
                  { Type: "Mailing" }
                 ]
              }
            }

            client
              .mutate({
                mutation: mailingAddressVariables.Id?UpdateOrganizationAddress:CreateOrganizationAddress,
                variables: mailingAddressVariables,
              })
              .then(() => {})
              .catch((error) => {console.log(error)});
          }
          client
            .mutate({
              mutation: UpdateTaxProfessional,
              variables: taxProfessional,
            })
            .then((res: any) => {
              if (res.data.UpdateTaxProfessional) {
                setSuccess("Updated Information");
                setTimeout(() => {
                  setSuccess("");
                }, 5000);
              }
            });
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Account Settings</Breadcrumb.Item>
              <Breadcrumb.Item active>Profile Information</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3 justify-content-between">
              <Col lg={12}>
                <h5
                  style={{ color: "#0A579B" }}
                  className="text-main-dark ff-Montserrat fw-bold mb-3"
                >
                  Profile Information
                </h5>

                <div className="content-text">
                  Update your email, phone or address, tax software and language
                  preferences, and more.
                </div>
              </Col>
            </Row>
            <ProfileInfo
              partner={partner}
              setPartner={setPartner}
              setPartnerProfileValidated={setPartnerProfileValidated}
              submitted={submitted}
            />
            <BusinessInformation
              organization={organization}
              setOrganization={setOrganization}
              submitted={submitted}
              setOrganizationValidated={setOrganizationValidated}
              setMainOrganizationAddress={setMainOrganizationAddress}
              mainOrganizationAddress={mainOrganizationAddress}
            />
            <StoreFrontQuestion
              mainOrganizationAddress={mainOrganizationAddress}
              setStoreFrontOrganizationAddress={
                setStoreFrontOrganizationAddress
              }
              storeFrontOrganizationAddress={storeFrontOrganizationAddress}
              submitted={submitted}
              setStoreOrganizationValidated={setStoreOrganizationValidated}
              setSameAsBusinessAddress={setSameAsBusinessAddress}
            />
            
            <MailingAddress 
              organizationAddresses={organizationAddresses}
              setOrganizationMailingAddress={setOrganizationMailingAddress}
              organizationMailingAddress={organizationMailingAddress}
              setOrganizationMailingValidated={setOrganizationMailingValidated}
              mailingAddressType={mailingAddressType}
              setMailingAddressType={setMailingAddressType}
              submitted={submitted}
            />
            
            <PartnerExpreience
              submitted={submitted}
              taxProfessional={taxProfessional}
              setTaxProfessional={setTaxProfessional}
              setTaxValidated={setTaxValidated}
            ></PartnerExpreience>

            <Languages
              submitted={submitted}
              taxProfessional={taxProfessional}
              setTaxProfessional={setTaxProfessional}
              setLanguageValidated={setLanguageValidated}
            ></Languages>
            <TaxSoftware
              submitted={submitted}
              taxProfessional={taxProfessional}
              setTaxProfessional={setTaxProfessional}
              setSoftwareValidated={setSoftwareValidated}
            ></TaxSoftware>
            <div className="d-flex justify-content-end my-5 align-items-center">
              <button onClick={onSaveChanges} className="btn-continue">
                Save Changes
              </button>
            </div>
            <div className="d-flex justify-content-end my-5 align-items-center">
              {success && <Message type="success" message={success}></Message>}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerProfile;
