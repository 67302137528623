import { createContext } from "react";
import { FilingStatusTypes, Request } from "../Constants";


export type PersonalInformationModel = {
    firstName: string;
    middleName: string;
    lastName: string;
    ssn: string;
    dlNumber: string;
    dlState: string;
    dlIssueDate: string;
    dlExpirationDate: string;
    birthDate: string;
    healthInsurance: string;
    healthCoverageMonths: string;
}

export type DependentModel = {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    ssn: string;
    birthDate: string;
    relationship: string;
    grossIncome: string;
    disabled: string;
    fullTimeStudent: string;
    dependentLivingWithYouMonths: string;
    dependentHealthCoverageMonths: string;
    dependentClaimedBy: string;
}

export type IncomeSourceModel = {
    id: number;
    incomeSourcePerson: string;
    incomeSource: string;
    company: string;
    ein: string;
    occupation: string;
    companyAddress: string;
    companyAddress2: string;
    companyCity: string;
    companyState: string;
    companyZip: string;
}

export type taxOrganizerOtherInfoModel = {
    medicalExpenses: string;
    rentInTaxYear: string;
    charitableContributions: string;
    stateTaxes: string;
    advancedTaxCredit: string;
    thirdStimulusPayment: string;
}

export type TaxOrganizerData = {
    filingStatus: string;
    clientInfo: PersonalInformationModel;
    spouseInfo: PersonalInformationModel;
    dependents: DependentModel[];
    incomeSources: IncomeSourceModel[];
    otherInformation: taxOrganizerOtherInfoModel;
}


export const InitialTaxOrganizerData:TaxOrganizerData = {
    filingStatus: FilingStatusTypes.MarriedFilingSeparately,
    clientInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        ssn: '',
        dlNumber: '',
        dlState: '',
        dlIssueDate: '',
        dlExpirationDate: '',
        birthDate: '',
        healthInsurance: '',
        healthCoverageMonths: ''
    },
    spouseInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        ssn: '',
        dlNumber: '',
        dlState: '',
        dlIssueDate: '',
        dlExpirationDate: '',
        birthDate: '',
        healthInsurance: '',
        healthCoverageMonths: ''
    },
    dependents: [],
    incomeSources: [],
    otherInformation: {
        medicalExpenses: '',
        rentInTaxYear: '',
        charitableContributions: '',
        stateTaxes: '',
        advancedTaxCredit: 'Yes',
        thirdStimulusPayment: 'Yes',
    }

}

export const InitialDependentModel: DependentModel = {
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    ssn: "",
    birthDate: "",
    relationship: "",
    grossIncome: "",
    disabled: "",
    fullTimeStudent: "",
    dependentLivingWithYouMonths: "",
    dependentHealthCoverageMonths: "",
    dependentClaimedBy: ""
}

export const InitialIncomeSourceModel: IncomeSourceModel = {
    id: 0,
    incomeSourcePerson: "",
    incomeSource: "",
    company: "",
    ein: "",
    occupation: "",
    companyAddress: "",
    companyAddress2: "",
    companyCity: "",
    companyState: "",
    companyZip: ""
}

interface ContextState {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    selectedRequest: Request;
    taxOrganizerData: TaxOrganizerData;
    setTaxOrganizerData: (data:TaxOrganizerData) => void;
}

const TaxOrganizerContext = createContext({} as ContextState);

export default TaxOrganizerContext;

export const { Provider, Consumer } = TaxOrganizerContext;