import { ReactElement, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import TaxOrganizerContext, { PersonalInformationModel } from "./TaxOrganizerContext";
import { Alert, Form, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import PersonalInformationForm from "./PersonalInformationForm";
import { ReactComponent as ErrorIcon} from '../../../assets/exclamation-circle-fill.svg';
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

const PersonalInformation:React.FC<{validatedCount: number, onNext: (validated: boolean) => void}> = (props) => {

    const {selectedRequest, taxOrganizerData, setTaxOrganizerData} = useContext(TaxOrganizerContext);

    const [clientValidationError, setClientValidationError] = useState({error: false, errorMessage: ''});
    const [spouseValidationError, setSpouseValidationError] = useState({error: false, errorMessage: ''});
    
    
    const updateClientInfo = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        setTaxOrganizerData({...taxOrganizerData, clientInfo:{...taxOrganizerData.clientInfo, [fieldName]: fieldValue}});
    }

    const updateSpouseInfo = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        setTaxOrganizerData({...taxOrganizerData, spouseInfo:{...taxOrganizerData.spouseInfo, [fieldName]: fieldValue}});
    }

    useEffect(() => {
        if(props.validatedCount !== 0){
            let validated = true;
            if(taxOrganizerData.clientInfo.firstName === "" || taxOrganizerData.clientInfo.lastName === ""){
                setClientValidationError({error: true, errorMessage: 'Please fill manadatory information'});
                validated = false;
            }else {
                setClientValidationError({error: false, errorMessage: ''});
            }
            if(taxOrganizerData.spouseInfo.firstName === "" || taxOrganizerData.spouseInfo.lastName === ""){
                setSpouseValidationError({error: true, errorMessage: 'Please fill manadatory information'});
                validated = false;
            }else {
                setSpouseValidationError({error: false, errorMessage: ''});
            }
            props.onNext(validated);
        }
    },[props.validatedCount]);

    
    return (
        <div style={{marginBottom: '3rem'}}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name}
            </h4>
            <p className="content-text" style={{marginBottom: '2rem'}}>
                Please provide your personal information to complete the return. If you filed jointly, we will need both individual's information.
            </p>
            <h6 className="text-main-dark ff-Montserrat fw-bold mb-2">
              Personal Information
            </h6>
            <Tabs defaultActiveKey="clientInfo">
                <Tab eventKey="clientInfo" 
                    title={(taxOrganizerData.clientInfo.firstName === '')?'Client Name': 
                        <span>{taxOrganizerData.clientInfo.firstName} {taxOrganizerData.clientInfo.lastName} {clientValidationError.error?<ErrorIcon />: ''}</span>}>
                    {clientValidationError.error && 
                    <Alert variant="danger" className="mt-2">
                    <FaCheckCircle className="text-error me-2 fs-6" />
                        {clientValidationError.errorMessage}
                    </Alert>}
                    <PersonalInformationForm inputData={taxOrganizerData.clientInfo} onChangeValue={updateClientInfo}/>
                </Tab>
                {(taxOrganizerData.filingStatus === FilingStatusTypes.MarriedFilingJointly || taxOrganizerData.filingStatus === FilingStatusTypes.MarriedFilingSeparately) && 
                <Tab eventKey="spouseInfo" title={(taxOrganizerData.spouseInfo.firstName === '')?'Client Name #2': 
                <span>{taxOrganizerData.spouseInfo.firstName} {taxOrganizerData.spouseInfo.lastName} {spouseValidationError.error?<ErrorIcon />: ''}</span>}>
                    {spouseValidationError.error && 
                    <Alert variant="danger" className="mt-2">
                    <FaCheckCircle className="text-error me-2 fs-6" />
                        {spouseValidationError.errorMessage}
                    </Alert>}
                    <PersonalInformationForm inputData={taxOrganizerData.spouseInfo} onChangeValue={updateSpouseInfo}/>
                </Tab>
                }
            </Tabs>
        </div>
    )
};

export default PersonalInformation;