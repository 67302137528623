import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FilingStatusTypes } from '../Constants';
import TaxOrganizerContext from "./TaxOrganizerContext";


const FilingStatus = () => {
    const {selectedRequest, taxOrganizerData, setTaxOrganizerData } = useContext(TaxOrganizerContext);

    const filingStatusHandler = (status:string) => {
        setTaxOrganizerData({...taxOrganizerData, filingStatus: status});
         
    }
    return (
        <div style={{marginBottom: '5rem'}}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name}
            </h4>
            <p className="content-text" style={{marginBottom: '3rem'}}>
                Providing accurate information on this easy-to-use Tax Organizer will allow Optima to prepare and file your taxes.
            </p>
            <Form>
                <Row className="mb-2">
                    <Col md={'auto'}>
                    <Form.Group controlId="">
                        <Form.Label className='fw-bold'>
                        <p className="content-text mb-0">
                         What is/was your filing status for the year 2017
                        </p>
                        </Form.Label> 
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Check
                            type="radio"
                            label="Single"
                            onChange={() => filingStatusHandler(FilingStatusTypes.Single)}
                            checked={taxOrganizerData.filingStatus === FilingStatusTypes.Single ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Head of Household"
                            onChange={() => filingStatusHandler(FilingStatusTypes.HeadofHousehold)}
                            checked={taxOrganizerData.filingStatus === FilingStatusTypes.HeadofHousehold ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Married Filing Jointly"
                            onChange={() => filingStatusHandler(FilingStatusTypes.MarriedFilingJointly)}
                            checked={taxOrganizerData.filingStatus === FilingStatusTypes.MarriedFilingJointly ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Married Filing Separately"
                            onChange={() => filingStatusHandler(FilingStatusTypes.MarriedFilingSeparately)}
                            checked={taxOrganizerData.filingStatus === FilingStatusTypes.MarriedFilingSeparately ? true : false}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default FilingStatus;