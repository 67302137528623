import { FaRegTimesCircle } from '@react-icons/all-files/fa/FaRegTimesCircle';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { getCurrentTaxYear } from "../../functions/misc";

type Props = {
  showModal: boolean | undefined;
  onClose: () => void;
}

const TaxPrepEnrollmentCompletedModal = ({ showModal, onClose }: Props) => {
    return(
        <Modal centered show={showModal} onHide={onClose}>
        <Modal.Header className="ms-auto border-0 mb-0 color-primary">
          <FaRegTimesCircle className='fs-6' onClick={onClose} />
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4 w-100 text-center d-flex justify-content-center">
            <div className='text-center'>
             <h6 className="ff-Montserrat fs-4 mt-0" style={{color: "#1589d2"}}>Your Tax Filing has been submitted</h6>
             <p className="mt-3 mx-3 ps-3 content-text text-justify">
               Thank you for submitting your {getCurrentTaxYear()} tax filing with Optima. <br /><br /> Your tax professional
               will be in touch soon to request any additional information needed.
            </p>
            </div>
        </Modal.Body>
      </Modal>
    );
}

export default TaxPrepEnrollmentCompletedModal;