import PartnerDashboard from "../components/Partnerdashboard/partnerDashboard";

function PartnerDashboardPage() {
  return (
    <>
      <PartnerDashboard />
    </>
  );
}

export default PartnerDashboardPage;
