import React, { useState, useEffect } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { GetTermsAndConditions } from "../../queries/GetTermsAndConditions";
import { clientConfig } from "../../awsClientConfig";
import printJS from 'print-js'

type PhoneNumberResponse = {
   data : {
    GetTermsAndConditions: {
      Partner: string
    }
   }
}

function TermsConditions() {
  const navigate = useNavigate();
  const [termsConditions, setTermsConditions] = useState('');

  const client = new clientConfig();

  useEffect(() => {
    client
    .query({
      query: GetTermsAndConditions,
    }).then((data:any) => {
      setTermsConditions(data?.data?.GetTermsAndConditions?.Partner)

    })
  })

  const onPrint = () => {
    printJS("terms&Conditions", 'html')
  }

  function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Help & Support</Breadcrumb.Item>
              <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3 justify-content-between">
              <Col lg={12}>
                <div className="d-flex justify-content-between">
                <h5 style={{ color: "#0A579B" }} className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Terms & Conditions
                </h5>
                <div className="text-end">
                <span onClick={onPrint} className="letsdolater">
                  <FontAwesomeIcon className="" icon={faPrint}/> Print
                </span>
                {/* <span
                  style={{ marginLeft: "10px" }}
                  className="letsdolater"
                  onClick={() => download("TermsConditions", termsConditions)}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span> */}
              </div>
              </div>
               <div id="terms&Conditions">
                 {termsConditions}                 
               </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TermsConditions;
