/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AnsweredQuestion = {
  AnswerKey: QualifyingAnswerKey,
  QuestionKey: QualifyingQuestionKey,
};

export enum QualifyingAnswerKey {
  No = "No",
  Yes = "Yes",
}


export enum QualifyingQuestionKey {
  BusinessOwner = "BusinessOwner",
  BusinessReport = "BusinessReport",
  HomeOwner = "HomeOwner",
  JointTaxReturn = "JointTaxReturn",
  SelfEmployee = "SelfEmployee",
  StockSeller = "StockSeller",
  TaxDeductionMaximization = "TaxDeductionMaximization",
  W2Employee = "W2Employee",
}


export type QualifyingEnrollmentResult = {
  __typename: "QualifyingEnrollmentResult",
  RecommendedProtectionPlan?: ProtectionPlan | null,
  Result: QualifyingAnswerResult,
  ResultExplanation?: string | null,
};

export type ProtectionPlan = {
  __typename: "ProtectionPlan",
  Active: boolean,
  ChargeSalesTax: boolean,
  CreatedAt: string,
  Description?: string | null,
  Id: string,
  Link?: string | null,
  MonthlyPrice: number,
  Name: ProtectionPlanName,
  Title: string,
  UpdatedAt: string,
};

export enum ProtectionPlanName {
  Basic = "Basic",
  Professional = "Professional",
  ProtectionPartnerMonitoring = "ProtectionPartnerMonitoring",
  Standard = "Standard",
}


export enum QualifyingAnswerResult {
  Qualify = "Qualify",
  Unqualify = "Unqualify",
}


export type Organization = {
  __typename: "Organization",
  CreatedAt: string,
  Ein: string,
  Id: string,
  Name: string,
  OrganizationAddresses?:  Array<OrganizationAddress | null > | null,
  Phone: string,
  PhoneExt?: string | null,
  Storefront: boolean,
  Type: OrganizationType,
  UpdatedAt: string,
};

export type OrganizationAddress = {
  __typename: "OrganizationAddress",
  City: string,
  CreatedAt: string,
  Id: string,
  OrganizationId: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  OrganizationAddressTypes: OrganizationAddressType,
  UpdatedAt: string,
  ZipCode: string,
};

export enum UsState {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}


export enum OrganizationAddressType {
  Main = "Main",
  Storefront = "Storefront",
  Mailing = "Mailing",
}


export enum OrganizationType {
  Corporation = "Corporation",
  LimitedLiabilityCompany = "LimitedLiabilityCompany",
  Partnership = "Partnership",
  SCorporation = "SCorporation",
  SoleProprietorship = "SoleProprietorship",
}


export type Partner = {
  __typename: "Partner",
  Active: boolean,
  ApplicationStatus: PartnerApplicationStatus,
  Birthdate?: string | null,
  CallEnabled: boolean,
  ConsentToShare: boolean,
  CreatedAt: string,
  Email: string,
  FirstName: string,
  Id: string,
  LastName: string,
  MiddleName?: string | null,
  OrganizationId?: string | null,
  Phone: string,
  PhoneExt?: number | null,
  PrimaryCognitoUserId: string,
  PrimaryUserPoolId: string,
  ReferralCode: string,
  RegistrationCodeId?: string | null,
  RejectionReason?: RejectionReason | null,
  SecondaryCognitoUserId?: string | null,
  SecondaryUserPoolId?: string | null,
  SmsEnabled: boolean,
  Ssn?: string | null,
  TaxProfessional?: TaxProfessional | null,
  Title: PartnerTitle,
  Type?: PartnerType | null,
  UpdatedAt: string,
};

export enum PartnerApplicationStatus {
  Approved = "Approved",
  Pending = "Pending",
  Processing = "Processing",
  Rejected = "Rejected",
}


export enum RejectionReason {
  BusinessRequirements = "BusinessRequirements",
  CustomerThreshold = "CustomerThreshold",
  LocationRequirement = "LocationRequirement",
  ProfessionalStandards = "ProfessionalStandards",
}


export type TaxProfessional = {
  __typename: "TaxProfessional",
  Bookkeeping: boolean,
  CreatedAt?: string | null,
  DisciplinaryAction: boolean,
  DisciplinaryActionDesc?: string | null,
  EoCoverage: number,
  EoInsurance: boolean,
  FilingSoftware: Array< TaxProfessionalFilingSoftware >,
  Id: string,
  IrsAudit: boolean,
  IrsAuditDesc?: string | null,
  Languages: Array< Language >,
  Licenses: Array< TaxProfessionalLicense >,
  PartnerId: string,
  PrepCompetency: boolean,
  Ptin: string,
  ReturnForms: Array< TaxProfessionalReturnForm >,
  ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
  TaxResolution: boolean,
  TaxReturnsPrepared: number,
  UpdatedAt?: string | null,
  YearsOfExperience: number,
};

export enum TaxProfessionalFilingSoftware {
  DrakeTax = "DrakeTax",
  LacerteTax = "LacerteTax",
  Other = "Other",
  ProConnect = "ProConnect",
  ProSeries = "ProSeries",
  TaxAct = "TaxAct",
}


export enum Language {
  Chinese = "Chinese",
  English = "English",
  French = "French",
  Hindi = "Hindi",
  Korean = "Korean",
  Other = "Other",
  Spanish = "Spanish",
  Vietnamese = "Vietnamese",
}


export enum TaxProfessionalLicense {
  EnrolledAgent = "EnrolledAgent",
  Other = "Other",
}


export enum TaxProfessionalReturnForm {
  Form1040 = "Form1040",
  Form1040x = "Form1040x",
  Form1065 = "Form1065",
  Form1120 = "Form1120",
  Form1120s = "Form1120s",
  Form940 = "Form940",
  Form941 = "Form941",
}


export enum TaxProfessionalReturnSchedule {
  Other = "Other",
  Schedule8821 = "Schedule8821",
  ScheduleA = "ScheduleA",
  ScheduleC = "ScheduleC",
  ScheduleD = "ScheduleD",
  ScheduleE = "ScheduleE",
  ScheduleEic = "ScheduleEic",
  ScheduleF = "ScheduleF",
  ScheduleH = "ScheduleH",
  ScheduleJ = "ScheduleJ",
  ScheduleR = "ScheduleR",
  ScheduleSE = "ScheduleSE",
}


export enum PartnerTitle {
  AuthorizedRepresentative = "AuthorizedRepresentative",
  Owner = "Owner",
}


export enum PartnerType {
  TaxProfessionals = "TaxProfessionals",
}


export type Profile = {
  __typename: "Profile",
  AccountId: string,
  Active: boolean,
  Birthdate?: string | null,
  CafStatus?: ProfileCafStatus | null,
  CallEnabled: boolean,
  ConsentToShare: boolean,
  CreatedAt: string,
  Email: string,
  EnrollmentSource: EnrollmentSource,
  FirstName: string,
  Id: string,
  Languages?: Array< Language > | null,
  LastName: string,
  MiddleName?: string | null,
  PartnerId?: string | null,
  Phone: string,
  PrimaryCognitoUserId: string,
  PrimaryUserPoolId: string,
  SecondaryCognitoUserId?: string | null,
  SecondaryUserPoolId?: string | null,
  SelfEnrolled: boolean,
  SmsEnabled: boolean,
  Ssn?: string | null,
  UpdatedAt: string,
};

export enum ProfileCafStatus {
  Accepted = "Accepted",
  Processing = "Processing",
  Rejected = "Rejected",
}


export enum EnrollmentSource {
  Legacy = "Legacy",
  Organic = "Organic",
  Referral = "Referral",
}


export type CancelAppointmentInput = {
  AppointmentId: string,
  ProfileId: string,
};

export type CompletePartnerFormInput = {
  FormId: string,
  PartnerId: string,
};

export type Form = {
  __typename: "Form",
  Active: boolean,
  CompletedAt?: string | null,
  CreatedAt: string,
  Id: string,
  Name: FormName,
  PartnerForms?:  Array<PartnerForm | null > | null,
  ProfileForms?:  Array<ProfileForm | null > | null,
  S3Bucket?: string | null,
  S3Key?: string | null,
  S3VersionId?: string | null,
  Status?: FormStatus | null,
  TemplateId: string,
  UpdatedAt: string,
};

export enum FormName {
  Form8821 = "Form8821",
  FormW9 = "FormW9",
  PartnerServiceAgreement = "PartnerServiceAgreement",
  ProtectionPlanServiceAgreement = "ProtectionPlanServiceAgreement",
  TaxRiskReportServiceAgreement = "TaxRiskReportServiceAgreement",
}


export type PartnerForm = {
  __typename: "PartnerForm",
  CompletedAt: string,
  FormId: string,
  Id: string,
  Ip: string,
  Owner: boolean,
  PartnerId: string,
  SignatureS3Bucket?: string | null,
  SignatureS3Key?: string | null,
  SignatureS3VersionId?: string | null,
};

export type ProfileForm = {
  __typename: "ProfileForm",
  CompletedAt: string,
  FormId: string,
  Id: string,
  Ip: string,
  Owner: boolean,
  ProfileId: string,
  SignatureS3Bucket?: string | null,
  SignatureS3Key?: string | null,
  SignatureS3VersionId?: string | null,
};

export enum FormStatus {
  Completed = "Completed",
  Pending = "Pending",
  Processing = "Processing",
}


export type CompleteProfileFormInput = {
  FormId: string,
  ProfileId: string,
};

export type CreateAppointmentInput = {
  AppointmentTopic: AppointmentTopicType,
  Notes?: string | null,
  Profile: CreateAppointmentProfileInput,
  StartAt: string,
};

export enum AppointmentTopicType {
  QuestionAboutMyIrsNotice = "QuestionAboutMyIrsNotice",
  ReviewMyReport = "ReviewMyReport",
  WhatsNext = "WhatsNext",
}


export type CreateAppointmentProfileInput = {
  Email: string,
  Name: string,
  Phone: string,
  ProfileId: string,
  ZipCode?: string | null,
};

export type Appointment = {
  __typename: "Appointment",
  AgentId: string,
  AppointmentId: string,
  EndsAt: string,
  ProfileId: string,
  StartAtDisplayDate?: string | null,
  StartAtDisplayTime?: string | null,
  StartsAt: string,
  TimeZone?: string | null,
  TimeZoneShort?: string | null,
  Topic?: string | null,
};

export type CreateEnrollmentActionInput = {
  EnrollmentId: string,
  Name: EnrollmentActionName,
};

export enum EnrollmentActionName {
  Form8821Completed = "Form8821Completed",
  IdentificationUploaded = "IdentificationUploaded",
  InformationCompleted = "InformationCompleted",
  PaymentCompleted = "PaymentCompleted",
  ProtectionPlanSelected = "ProtectionPlanSelected",
  ServiceAgreementCompleted = "ServiceAgreementCompleted",
}


export type EnrollmentAction = {
  __typename: "EnrollmentAction",
  CreatedAt: string,
  Enrollment: Enrollment,
  EnrollmentId: string,
  Id: string,
  Name: EnrollmentActionName,
  UpdatedAt: string,
};

export type Enrollment = {
  __typename: "Enrollment",
  CancellationReason?: EnrollmentCancellationReason | null,
  CancelledAt?: string | null,
  CompletedAt?: string | null,
  CreatedAt: string,
  Id: string,
  InitiatedAt?: string | null,
  NextEnrollmentAction?: NextEnrollmentAction | null,
  NextForm?: Form | null,
  Profile: Profile,
  ProfileId: string,
  ProtectionPlan?: ProtectionPlan | null,
  ProtectionPlanId?: string | null,
  RecommendedProtectionPlan?: ProtectionPlan | null,
  RecommendedProtectionPlanId?: string | null,
  Status: EnrollmentStatus,
  TranscriptRequestId?: string | null,
  Type: EnrollmentType,
  UpdatedAt: string,
};

export enum EnrollmentCancellationReason {
  Expired = "Expired",
  Unqualified = "Unqualified",
}


export enum NextEnrollmentAction {
  AnswerQualifyingQuestions = "AnswerQualifyingQuestions",
  CompleteForm8821 = "CompleteForm8821",
  CompleteInformation = "CompleteInformation",
  CompletePayment = "CompletePayment",
  CompleteServiceAgreement = "CompleteServiceAgreement",
  SelectProtectionPlan = "SelectProtectionPlan",
  UploadIdentification = "UploadIdentification",
}


export enum EnrollmentStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Initiated = "Initiated",
}


export enum EnrollmentType {
  Investigation = "Investigation",
  ProtectionPlan = "ProtectionPlan",
  ReferralProtectionPlan = "ReferralProtectionPlan",
}


export type CreateLeadInput = {
  AccountId?: string | null,
  ConsentToDisclose: boolean,
  Email: string,
  FirstName: string,
  IsQualified: boolean,
  LastName: string,
  PartnerId?: string | null,
  Phone: string,
  PhoneExt?: string | null,
  RecommendedProtectionPlanId?: string | null,
  TaxDebt?: number | null,
};

export type Lead = {
  __typename: "Lead",
  AccountId?: string | null,
  Active: boolean,
  ConsentToDisclose: boolean,
  ConvertedAt?: string | null,
  CreatedAt: string,
  Email: string,
  FirstName: string,
  Id: string,
  IsConverted: boolean,
  IsQualified: boolean,
  LastName: string,
  PartnerId?: string | null,
  Phone: string,
  PhoneExt?: string | null,
  RecommendedProtectionPlan?: ProtectionPlan | null,
  RecommendedProtectionPlanId?: string | null,
  TaxDebt?: number | null,
  UpdatedAt: string,
};

export type CreateNoticeUploadInput = {
  Name: string,
  NoticeAnalyses?: Array< CreateNoticeAnalysisInput > | null,
  NoticeUploadOcrResults?: Array< CreateNoticeUploadOcrResult > | null,
  ProfileId: string,
  S3Bucket: string,
  S3Key: string,
};

export type CreateNoticeAnalysisInput = {
  AmountDue: number,
  Description: string,
  DueDate?: string | null,
  NoticeDate?: string | null,
  NoticeId: string,
  OverDue: boolean,
  RiskLevel: NoticeAnalysisRiskLevel,
};

export enum NoticeAnalysisRiskLevel {
  High = "High",
  HighMedium = "HighMedium",
  Low = "Low",
  LowMedium = "LowMedium",
  Medium = "Medium",
}


export type CreateNoticeUploadOcrResult = {
  OcrService: OcrService,
  OcrServiceApi: OcrServiceApi,
  S3Bucket: string,
  S3Key: string,
};

export enum OcrService {
  AmazonTextract = "AmazonTextract",
}


export enum OcrServiceApi {
  AnalyzeDocument = "AnalyzeDocument",
  DetectDocumentText = "DetectDocumentText",
}


export type NoticeUpload = {
  __typename: "NoticeUpload",
  Active: boolean,
  CreatedAt: string,
  Id: string,
  LatestNoticeAnalysis?: NoticeAnalysis | null,
  Name: string,
  ProfileId: string,
  S3Bucket: string,
  S3Key: string,
  UpdatedAt: string,
};

export type NoticeAnalysis = {
  __typename: "NoticeAnalysis",
  AmountDue: number,
  CreatedAt: string,
  Description: string,
  DueDate?: string | null,
  Id: string,
  Notice: Notice,
  NoticeDate?: string | null,
  NoticeId: string,
  NoticeUploadId: string,
  OverDue: boolean,
  RiskLevel: NoticeAnalysisRiskLevel,
  UpdatedAt: string,
};

export type Notice = {
  __typename: "Notice",
  Active: boolean,
  CreatedAt: string,
  Description: string,
  Id: string,
  Name: string,
  Severity: number,
  UpdatedAt: string,
};

export type CreateOrganizationInput = {
  Ein: string,
  Name: string,
  Phone: string,
  PhoneExt?: string | null,
  Storefront: boolean,
  Type: OrganizationType,
};

export type CreateOrganizationAddressInput = {
  City: string,
  OrganizationId: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  OrganizationAddressTypes?: Array< OrganizationAddressTypesInput > | null,
  ZipCode: string,
};

export type OrganizationAddressTypesInput = {
  Type: OrganizationAddressType,
};

export type CreatePartnerInput = {
  Birthdate?: string | null,
  CallEnabled?: boolean | null,
  ConsentToShare?: boolean | null,
  Email: string,
  FirstName: string,
  LastName: string,
  MiddleName?: string | null,
  Phone: string,
  PhoneExt?: number | null,
  PrimaryCognitoUserId: string,
  PrimaryUserPoolId: string,
  SecondaryCognitoUserId?: string | null,
  SecondaryUserPoolId?: string | null,
  SmsEnabled?: boolean | null,
  Ssn?: string | null,
  Title: PartnerTitle,
  Type?: PartnerType | null,
};

export type CreatePartnerDeletionInput = {
  DeletionReason: DeletionReason,
  PartnerId: string,
};

export enum DeletionReason {
  CreateByMistake = "CreateByMistake",
  DidNotMeetExpectation = "DidNotMeetExpectation",
  DoNotUseApp = "DoNotUseApp",
  FoundAlternative = "FoundAlternative",
  LifeCircumstance = "LifeCircumstance",
  NotSatisfied = "NotSatisfied",
  PoorExperience = "PoorExperience",
  TooExpensive = "TooExpensive",
}


export type PartnerDeletion = {
  __typename: "PartnerDeletion",
  CompletedAt?: string | null,
  CreatedAt: string,
  DeletionReason: DeletionReason,
  Id: string,
  InitiatedAt: string,
  PartnerId: string,
  Status: DeletionStatus,
  UpdatedAt: string,
};

export enum DeletionStatus {
  Completed = "Completed",
  Initiated = "Initiated",
}


export type CreatePartnerEnrollmentActionInput = {
  Name: PartnerEnrollmentActionName,
  PartnerEnrollmentId: string,
};

export enum PartnerEnrollmentActionName {
  ApplicationApproved = "ApplicationApproved",
  BusinessInformationCompleted = "BusinessInformationCompleted",
  FormW9Completed = "FormW9Completed",
  ProfessionalInformationCompleted = "ProfessionalInformationCompleted",
  ServiceAgreementCompleted = "ServiceAgreementCompleted",
}


export type PartnerEnrollmentAction = {
  __typename: "PartnerEnrollmentAction",
  CreatedAt: string,
  Id: string,
  Name: PartnerEnrollmentActionName,
  PartnerEnrollment: PartnerEnrollment,
  PartnerEnrollmentId: string,
  UpdatedAt: string,
};

export type PartnerEnrollment = {
  __typename: "PartnerEnrollment",
  CancelledAt?: string | null,
  CompletedAt?: string | null,
  CreatedAt: string,
  Id: string,
  InitiatedAt?: string | null,
  NextEnrollmentAction?: NextPartnerEnrollmentAction | null,
  NextForm?: Form | null,
  Partner: Partner,
  PartnerId: string,
  Status: PartnerEnrollmentStatus,
  UpdatedAt: string,
};

export enum NextPartnerEnrollmentAction {
  CompleteBusinessInformation = "CompleteBusinessInformation",
  CompleteFormW9 = "CompleteFormW9",
  CompleteProfessionalInformation = "CompleteProfessionalInformation",
  CompleteServiceAgreement = "CompleteServiceAgreement",
  WaitForApplicationApproval = "WaitForApplicationApproval",
}


export enum PartnerEnrollmentStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Initiated = "Initiated",
}


export type CreatePaymentMethodInput = {
  Domain: Domain,
  PaymentMethodToken: string,
  ProfileId: string,
};

export enum Domain {
  ProtectionPlan = "ProtectionPlan",
  TaxPrepOnly = "TaxPrepOnly",
  TaxRelief = "TaxRelief",
}


export type PaymentMethod = {
  __typename: "PaymentMethod",
  AchEntityType?: AchEntityType | null,
  AchNumber?: string | null,
  AchRoutingNumber?: string | null,
  AchType?: AchType | null,
  Active: boolean,
  CcExpMonth?: string | null,
  CcExpYear?: string | null,
  CcLastFourDigits?: string | null,
  CcType?: CcType | null,
  City: string,
  CreatedAt: string,
  Default: boolean,
  Id: string,
  Name: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  Token: string,
  Type: PaymentMethodType,
  UpdatedAt: string,
  Vendor: PaymentMethodVendor,
  ZipCode: string,
};

export enum AchEntityType {
  Business = "Business",
  Personal = "Personal",
}


export enum AchType {
  Checking = "Checking",
  Savings = "Savings",
}


export enum CcType {
  AmericanExpress = "AmericanExpress",
  Discover = "Discover",
  Maestro = "Maestro",
  Master = "Master",
  Visa = "Visa",
}


export enum PaymentMethodType {
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
}


export enum PaymentMethodVendor {
  Spreedly = "Spreedly",
  Transnational = "Transnational",
}


export type CreateProfileInput = {
  AccountId?: string | null,
  Birthdate?: string | null,
  CallEnabled?: boolean | null,
  ConsentToShare?: boolean | null,
  Email: string,
  FirstName: string,
  Languages?: Array< Language > | null,
  LastName: string,
  MiddleName?: string | null,
  PartnerId?: string | null,
  Phone: string,
  PrimaryCognitoUserId: string,
  PrimaryUserPoolId: string,
  SecondaryCognitoUserId?: string | null,
  SecondaryUserPoolId?: string | null,
  SelfEnrolled?: boolean | null,
  SmsEnabled?: boolean | null,
  Ssn?: string | null,
};

export type SignUpParametersInput = {
  Ad?: string | null,
  AdGroup?: string | null,
  Network?: string | null,
  ReferralCode?: string | null,
  UtmCampaign?: string | null,
  UtmSource?: string | null,
};

export type CreateProfileDeletionInput = {
  DeletionReason: DeletionReason,
  ProfileId: string,
};

export type ProfileDeletion = {
  __typename: "ProfileDeletion",
  CompletedAt?: string | null,
  CreatedAt: string,
  DeletionReason: DeletionReason,
  Id: string,
  InitiatedAt: string,
  ProfileId: string,
  Status: DeletionStatus,
  UpdatedAt: string,
};

export type CreateProfileFormInput = {
  FormName: FormName,
  ProfileForms: Array< ProfileFormInput >,
  ProfileId: string,
};

export type ProfileFormInput = {
  Owner: boolean,
  ProfileId: string,
};

export type CreateTaxExtensionInput = {
  Form1040Nr: boolean,
  OutOfCountry: boolean,
  PrimaryEntity: TaxFilingPrimaryEntityInput,
  SpouseEntity?: TaxFilingSpouseEntityInput | null,
  TotalLiability: number,
  TotalPayments: number,
};

export type TaxFilingPrimaryEntityInput = {
  City: string,
  Email: string,
  FirstName: string,
  LastName: string,
  Phone: string,
  Ssn: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  Zipcode: string,
};

export type TaxFilingSpouseEntityInput = {
  FirstName: string,
  LastName: string,
  Ssn: string,
};

export type TaxExtension = {
  __typename: "TaxExtension",
  CreatedAt: string,
  FilingStatus: TaxExtensionFilingStatus,
  FilingType: TaxExtensionFilingType,
  Form1040Nr: boolean,
  Id: string,
  MaritalFilingStatus: TaxExtensionMaritalFilingStatus,
  OutOfCountry: boolean,
  PoBoxAddress?: string | null,
  PrimaryEntity: TaxFilingPrimaryEntity,
  SpouseEntity?: TaxFilingSpouseEntity | null,
  TaxProgramId?: string | null,
  TaxProgramStage: TaxProgramStage,
  TotalLiability: number,
  TotalPayments: number,
  UpdatedAt: string,
  Year: number,
};

export enum TaxExtensionFilingStatus {
  Completed = "Completed",
  Pending = "Pending",
  Rejected = "Rejected",
}


export enum TaxExtensionFilingType {
  Corporate = "Corporate",
  Individual = "Individual",
  Partnership = "Partnership",
  SCorporate = "SCorporate",
}


export enum TaxExtensionMaritalFilingStatus {
  HeadOfHousehold = "HeadOfHousehold",
  MarriedFilingJointly = "MarriedFilingJointly",
  MarriedFilingSeparately = "MarriedFilingSeparately",
  Single = "Single",
}


export type TaxFilingPrimaryEntity = {
  __typename: "TaxFilingPrimaryEntity",
  City: string,
  Email: string,
  FirstName: string,
  LastName: string,
  Ssn: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  Zipcode: string,
};

export type TaxFilingSpouseEntity = {
  __typename: "TaxFilingSpouseEntity",
  FirstName: string,
  LastName: string,
  Ssn: string,
};

export enum TaxProgramStage {
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Delay = "Delay",
  EfileComplete = "EfileComplete",
  FailedProcessing = "FailedProcessing",
  FailedTransmission = "FailedTransmission",
  FailedValidation = "FailedValidation",
  PassedValidation = "PassedValidation",
  Pending = "Pending",
  Queued = "Queued",
  ReadyToSend = "ReadyToSend",
  ReceivedAtLacerte = "ReceivedAtLacerte",
  Rejected = "Rejected",
  SentToAgency = "SentToAgency",
  SentToLacerte = "SentToLacerte",
}


export type CreateTaxProfessionalInput = {
  Bookkeeping: boolean,
  DisciplinaryAction: boolean,
  DisciplinaryActionDesc?: string | null,
  EoCoverage: number,
  EoInsurance: boolean,
  FilingSoftware: Array< TaxProfessionalFilingSoftware >,
  IrsAudit: boolean,
  IrsAuditDesc?: string | null,
  Languages: Array< Language >,
  Licenses: Array< TaxProfessionalLicense >,
  PartnerId: string,
  PrepCompetency: boolean,
  Ptin: string,
  ReturnForms: Array< TaxProfessionalReturnForm >,
  ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
  TaxResolution: boolean,
  TaxReturnsPrepared: number,
  YearsOfExperience: number,
};

export type NoticeAssessment = {
  __typename: "NoticeAssessment",
  AmountDue?: number | null,
  Description?: string | null,
  DueDate?: string | null,
  Error?: string | null,
  Name?: string | null,
  OverDue?: boolean | null,
  ProfileId: string,
  RiskLevel?: NoticeAnalysisRiskLevel | null,
};

export type InitiateEnrollmentInput = {
  ProfileId: string,
  Type: EnrollmentType,
};

export type InitiatePartnerEnrollmentInput = {
  PartnerId: string,
};

export type MigrateProfileInput = {
  AlternativeId: number,
  Birthdate?: string | null,
  CallEnabled?: boolean | null,
  ConsentToShare?: boolean | null,
  Email: string,
  FirstName: string,
  Languages?: Array< Language > | null,
  LastName: string,
  MiddleName?: string | null,
  Phone: string,
  PrimaryCognitoUserId: string,
  PrimaryUserPoolId: string,
  SecondaryCognitoUserId?: string | null,
  SecondaryUserPoolId?: string | null,
  SelfEnrolled?: boolean | null,
  SmsEnabled?: boolean | null,
  Ssn?: string | null,
};

export type MigratedProfile = {
  __typename: "MigratedProfile",
  Id: string,
};

export type PurchaseProtectionPlanInput = {
  EnrollmentId: string,
  PaymentMethodToken: string,
};

export type QualifyEnrollmentRequestInput = {
  AnsweredQuestions: Array< AnsweredQuestion | null >,
  EnrollmentId: string,
};

export type UpdateOrganizationInput = {
  Ein: string,
  Id: string,
  Name: string,
  Phone: string,
  PhoneExt?: string | null,
  Storefront: boolean,
  Type: OrganizationType,
};

export type UpdateOrganizationAddressInput = {
  City: string,
  Id: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  Type: OrganizationAddressType,
  ZipCode: string,
};

export type UpdatePartnerInput = {
  Active: boolean,
  ApplicationStatus?: PartnerApplicationStatus | null,
  Birthdate?: string | null,
  CallEnabled: boolean,
  ConsentToShare: boolean,
  FirstName: string,
  Id: string,
  LastName: string,
  MiddleName?: string | null,
  Phone: string,
  PhoneExt?: number | null,
  RejectionReason?: RejectionReason | null,
  SmsEnabled: boolean,
  Ssn?: string | null,
  Title: PartnerTitle,
  Type?: PartnerType | null,
};

export type UpdatePaymentMethodInput = {
  AchEntityType?: AchEntityType | null,
  AchNumber?: string | null,
  AchRoutingNumber?: string | null,
  AchType?: AchType | null,
  Active: boolean,
  CcExpMonth?: string | null,
  CcExpYear?: string | null,
  CcLastFourDigits?: string | null,
  CcType?: CcType | null,
  City: string,
  Default: boolean,
  Id: string,
  Name: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  Type: PaymentMethodType,
  ZipCode: string,
};

export type UpdateProfileInput = {
  Active: boolean,
  Birthdate?: string | null,
  CallEnabled?: boolean | null,
  ConsentToShare?: boolean | null,
  FirstName: string,
  Id: string,
  Languages?: Array< Language > | null,
  LastName: string,
  MiddleName?: string | null,
  Phone: string,
  SmsEnabled?: boolean | null,
  Ssn?: string | null,
};

export type UpdateTaxProfessionalInput = {
  Bookkeeping: boolean,
  DisciplinaryAction: boolean,
  DisciplinaryActionDesc?: string | null,
  EoCoverage: number,
  EoInsurance: boolean,
  FilingSoftware: Array< TaxProfessionalFilingSoftware >,
  Id: string,
  IrsAudit: boolean,
  IrsAuditDesc?: string | null,
  Languages: Array< Language >,
  Licenses: Array< TaxProfessionalLicense >,
  PrepCompetency: boolean,
  Ptin: string,
  ReturnForms: Array< TaxProfessionalReturnForm >,
  ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
  TaxResolution: boolean,
  TaxReturnsPrepared: number,
  YearsOfExperience: number,
};

export type UpsertProfilePrimaryAddressInput = {
  City: string,
  ProfileId: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  ZipCode: string,
};

export type ProfileAddress = {
  __typename: "ProfileAddress",
  City: string,
  CreatedAt: string,
  Id: string,
  ProfileId: string,
  State: UsState,
  StreetAddress1: string,
  StreetAddress2?: string | null,
  UpdatedAt: string,
  ZipCode: string,
};

export type AppointmentAvailabilityRequest = {
  AppointmentTopic: AppointmentTopicType,
  ZipCode?: string | null,
};

export type AppointmentAvailability = {
  __typename: "AppointmentAvailability",
  AppointmentLength?: string | null,
  AvailableTimes?:  Array<AppointmentAvailabilityItem | null > | null,
  Date?: string | null,
};

export type AppointmentAvailabilityItem = {
  __typename: "AppointmentAvailabilityItem",
  AvailabilityTime?: string | null,
  DisplayTime?: string | null,
  TimeZone?: string | null,
  TimeZoneShort?: string | null,
};

export type DisplayProperty = {
  __typename: "DisplayProperty",
  DisplayText?: string | null,
  Value?: string | null,
};

export type TaxExtensionEligibilityResult = {
  __typename: "TaxExtensionEligibilityResult",
  IneligibilityReason?: TaxExtensionIneligibilityReason | null,
  IsEligible: boolean,
  LastTaxExtension?: TaxExtension | null,
};

export enum TaxExtensionIneligibilityReason {
  DeadlinePassed = "DeadlinePassed",
  ExtensionSubmitted = "ExtensionSubmitted",
}


export type TaxRiskReportEligibilityResult = {
  __typename: "TaxRiskReportEligibilityResult",
  EnrollmentInitiated?: boolean | null,
  IsEligible: boolean,
  LastTaxRiskReport?: SimplifiedTaxRiskReport | null,
};

export type SimplifiedTaxRiskReport = {
  __typename: "SimplifiedTaxRiskReport",
  Id?: string | null,
  OverallRisk?: RiskIndicatorOverallRisk | null,
  OverallRiskDescription?: string | null,
  RequestedAt: string,
  Status: TaxRiskReportStatus,
};

export enum RiskIndicatorOverallRisk {
  High = "High",
  Low = "Low",
  Medium = "Medium",
}


export enum TaxRiskReportStatus {
  Completed = "Completed",
  Failed = "Failed",
  Pending = "Pending",
}


export type LeadSummaryConnection = {
  __typename: "LeadSummaryConnection",
  LeadSummaries?:  Array<LeadSummary > | null,
  NextToken?: string | null,
  TotalTokens?: number | null,
};

export type LeadSummary = {
  __typename: "LeadSummary",
  DangerFlag: boolean,
  Email: string,
  EnrolledAt?: string | null,
  FirstName: string,
  LastName: string,
  LeadId: string,
  LeadStatus: LeadSummaryStatus,
  MiddleName?: string | null,
  PartnerId?: string | null,
  PaymentHold?: boolean | null,
  Phone: string,
  ProfileId?: string | null,
  ReferredAt: string,
  TaxRiskReportRiskLevel?: RiskIndicatorOverallRisk | null,
};

export enum LeadSummaryStatus {
  Enrolled = "Enrolled",
  Inactive = "Inactive",
  Pending = "Pending",
}


export type MonitoringBenefits = {
  __typename: "MonitoringBenefits",
  Items: Array< string >,
  Type: string,
};

export type PaymentMethodConnection = {
  __typename: "PaymentMethodConnection",
  NextToken?: string | null,
  PaymentMethods:  Array<PaymentMethod | null >,
};

export type PhoneNumbers = {
  __typename: "PhoneNumbers",
  PartnerContactUs: string,
  ProfileContactUs: string,
};

export type ProtectionBenefits = {
  __typename: "ProtectionBenefits",
  Items: Array< string >,
  Type: string,
};

export type ProtectionPlanEligibilityResult = {
  __typename: "ProtectionPlanEligibilityResult",
  EnrollmentInitiated?: boolean | null,
  EnrollmentType?: EnrollmentType | null,
  IsEligible: boolean,
  LastTaxRiskReport?: SimplifiedTaxRiskReport | null,
};

export type ProtectionPlanFeatures = {
  __typename: "ProtectionPlanFeatures",
  Features: ProtectionPlanFeature,
  Type: string,
};

export type ProtectionPlanFeature = {
  __typename: "ProtectionPlanFeature",
  Disclaimers?: string | null,
  Groups?: string | null,
};

export type ProtectionPlanSubscription = {
  __typename: "ProtectionPlanSubscription",
  Active: boolean,
  Amount: number,
  CancellationReason?: SubscriptionCancellationReason | null,
  ChargeSalesTax?: boolean | null,
  CreatedAt: string,
  EndAt?: string | null,
  Id: string,
  NextPaymentDate?: string | null,
  PaymentDay?: number | null,
  StartAt: string,
  Status: SubscriptionStatus,
  UpdatedAt: string,
};

export enum SubscriptionCancellationReason {
  Change = "Change",
  CreateByMistake = "CreateByMistake",
  DidNotMeetExpectation = "DidNotMeetExpectation",
  DoNotCall = "DoNotCall",
  FoundAlternative = "FoundAlternative",
  LifeCircumstance = "LifeCircumstance",
  NoLongerNeeded = "NoLongerNeeded",
  NotSatisfied = "NotSatisfied",
  PoorExperience = "PoorExperience",
  TooExpensive = "TooExpensive",
}


export enum SubscriptionStatus {
  Current = "Current",
  Future = "Future",
  PendingCancellationCancelled = "PendingCancellationCancelled",
  PendingChange = "PendingChange",
  PendingEnrollment = "PendingEnrollment",
}


export type ReferralSummary = {
  __typename: "ReferralSummary",
  ActiveCount: number,
  InactiveCount: number,
  NextPayoutAmount: number,
  NextPayoutDate: string,
  PartnerId: string,
  PendingCount: number,
  TotalCommissionEarned: number,
};

export type RegistrationCode = {
  __typename: "RegistrationCode",
  Active: boolean,
  Code: string,
  CreatedAt: string,
  Id: string,
  Redeemed: boolean,
  RedeemedAt?: string | null,
  Type: RegistrationCodeType,
  UpdatedAt: string,
};

export enum RegistrationCodeType {
  Partner = "Partner",
}


export enum ReportRiskIndicatorType {
  AuditReview = "AuditReview",
  CollectionActivity = "CollectionActivity",
  FederalCompliance = "FederalCompliance",
  Notices_Updates = "Notices_Updates",
}


export type ReportRiskIndicator = {
  __typename: "ReportRiskIndicator",
  Content?: string | null,
  Type: ReportRiskIndicatorType,
};

export type TaxRiskReport = {
  __typename: "TaxRiskReport",
  Id: string,
  NoticesUpdates?:  Array<NoticesUpdate | null > | null,
  ProfileId: string,
  RecentActivity?: RecentActivity | null,
  ReportDate: string,
  ReportType: ReportType,
  RiskIndicators?: RiskIndicators | null,
  TaxLiabilitiesByYear?:  Array<TaxLiability | null > | null,
  TotalLiability?: TotalLiability | null,
};

export type NoticesUpdate = {
  __typename: "NoticesUpdate",
  Code?: string | null,
  Date: string,
  NoticeIssued?: string | null,
  NoticeDescription?: string | null,
  TaxYear: number,
};

export type RecentActivity = {
  __typename: "RecentActivity",
  Amount: number,
  DateReceived: string,
  TaxYear: number,
  Type: RecentActivityType,
};

export enum RecentActivityType {
  Payment = "Payment",
  Payment_Levy = "Payment_Levy",
  Payment_Offer_in_Compromise = "Payment_Offer_in_Compromise",
}


export enum ReportType {
  TaxRisk = "TaxRisk",
}


export type RiskIndicators = {
  __typename: "RiskIndicators",
  AuditReview: boolean,
  CollectionActivity: boolean,
  FederalCompliance: boolean,
  NoticeUpdate: boolean,
  OverallRisk: RiskIndicatorOverallRisk,
  OverallRiskDescription: string,
};

export type TaxLiability = {
  __typename: "TaxLiability",
  CurrentBalance: number,
  EstCsed?: string | null,
  FilingStatus: ReturnFilingStatus,
  TaxYear: number,
};

export enum ReturnFilingStatus {
  Amended = "Amended",
  Audit = "Audit",
  CP2000 = "CP2000",
  Filed = "Filed",
  Filed_Audited = "Filed_Audited",
  Filed_CP2000 = "Filed_CP2000",
  Filed_Covid = "Filed_Covid",
  Filed_Current = "Filed_Current",
  Filed_Current_Audit = "Filed_Current_Audit",
  Filed_Possible = "Filed_Possible",
  Filed_Possible_CP2000 = "Filed_Possible_CP2000",
  SFR = "SFR",
  Unfiled = "Unfiled",
}


export type TotalLiability = {
  __typename: "TotalLiability",
  Amount: number,
  TaxAgency: string,
  TaxType: TaxType,
};

export enum TaxType {
  Business = "Business",
  Personal = "Personal",
}


export type TaxRiskReportConnection = {
  __typename: "TaxRiskReportConnection",
  NextToken?: string | null,
  TaxRiskReports:  Array<TaxRiskReportItem | null >,
};

export type TaxRiskReportItem = {
  __typename: "TaxRiskReportItem",
  Id: string,
  OverallRisk: RiskIndicatorOverallRisk,
  OverallRiskDescription: string,
  ReportDate: string,
};

export type TermsAndConditions = {
  __typename: "TermsAndConditions",
  Partner: string,
  Profile: string,
};

export type QualifyEnrollmentResultMutationVariables = {
  enrolmentID: string,
  answeredQuestions: Array< AnsweredQuestion | null >,
};

export type QualifyEnrollmentResultMutation = {
  QualifyEnrollmentRequest?:  {
    __typename: "QualifyingEnrollmentResult",
    Result: QualifyingAnswerResult,
    ResultExplanation?: string | null,
  } | null,
};

export type GetOrganizationPhoneQueryVariables = {
  organizationId: string,
};

export type GetOrganizationPhoneQuery = {
  GetOrganization:  {
    __typename: "Organization",
    Phone: string,
  },
};

export type GetPartnerOrganizationIdQueryVariables = {
  PartnerId: string,
};

export type GetPartnerOrganizationIdQuery = {
  GetPartner?:  {
    __typename: "Partner",
    OrganizationId?: string | null,
  } | null,
};

export type GetProfileReferralQueryVariables = {
  profileId: string,
};

export type GetProfileReferralQuery = {
  GetProfile?:  {
    __typename: "Profile",
    EnrollmentSource: EnrollmentSource,
    PartnerId?: string | null,
  } | null,
};

export type CancelAppointmentMutationVariables = {
  Input: CancelAppointmentInput,
};

export type CancelAppointmentMutation = {
  CancelAppointment?: boolean | null,
};

export type CompletePartnerFormMutationVariables = {
  Input: CompletePartnerFormInput,
  PartnerEnrollmentId?: string | null,
};

export type CompletePartnerFormMutation = {
  CompletePartnerForm?:  {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } | null,
};

export type CompleteProfileFormMutationVariables = {
  EnrollmentId?: string | null,
  Input: CompleteProfileFormInput,
};

export type CompleteProfileFormMutation = {
  CompleteProfileForm?:  {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } | null,
};

export type CreateAppointmentMutationVariables = {
  Input: CreateAppointmentInput,
};

export type CreateAppointmentMutation = {
  CreateAppointment?:  {
    __typename: "Appointment",
    AgentId: string,
    AppointmentId: string,
    EndsAt: string,
    ProfileId: string,
    StartAtDisplayDate?: string | null,
    StartAtDisplayTime?: string | null,
    StartsAt: string,
    TimeZone?: string | null,
    TimeZoneShort?: string | null,
    Topic?: string | null,
  } | null,
};

export type CreateEnrollmentActionMutationVariables = {
  Input?: CreateEnrollmentActionInput | null,
};

export type CreateEnrollmentActionMutation = {
  CreateEnrollmentAction?:  {
    __typename: "EnrollmentAction",
    CreatedAt: string,
    Enrollment:  {
      __typename: "Enrollment",
      CancellationReason?: EnrollmentCancellationReason | null,
      CancelledAt?: string | null,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      InitiatedAt?: string | null,
      NextEnrollmentAction?: NextEnrollmentAction | null,
      ProfileId: string,
      ProtectionPlanId?: string | null,
      RecommendedProtectionPlanId?: string | null,
      Status: EnrollmentStatus,
      TranscriptRequestId?: string | null,
      Type: EnrollmentType,
      UpdatedAt: string,
    },
    EnrollmentId: string,
    Id: string,
    Name: EnrollmentActionName,
    UpdatedAt: string,
  } | null,
};

export type CreateLeadMutationVariables = {
  Input: CreateLeadInput,
};

export type CreateLeadMutation = {
  CreateLead?:  {
    __typename: "Lead",
    AccountId?: string | null,
    Active: boolean,
    ConsentToDisclose: boolean,
    ConvertedAt?: string | null,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    IsConverted: boolean,
    IsQualified: boolean,
    LastName: string,
    PartnerId?: string | null,
    Phone: string,
    PhoneExt?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    TaxDebt?: number | null,
    UpdatedAt: string,
  } | null,
};

export type CreateNoticeUploadMutationVariables = {
  Input: CreateNoticeUploadInput,
  ProfileId: string,
};

export type CreateNoticeUploadMutation = {
  CreateNoticeUpload:  {
    __typename: "NoticeUpload",
    Active: boolean,
    CreatedAt: string,
    Id: string,
    LatestNoticeAnalysis?:  {
      __typename: "NoticeAnalysis",
      AmountDue: number,
      CreatedAt: string,
      Description: string,
      DueDate?: string | null,
      Id: string,
      NoticeDate?: string | null,
      NoticeId: string,
      NoticeUploadId: string,
      OverDue: boolean,
      RiskLevel: NoticeAnalysisRiskLevel,
      UpdatedAt: string,
    } | null,
    Name: string,
    ProfileId: string,
    S3Bucket: string,
    S3Key: string,
    UpdatedAt: string,
  },
};

export type CreateOrganizationMutationVariables = {
  Input: CreateOrganizationInput,
  PartnerId: string,
};

export type CreateOrganizationMutation = {
  CreateOrganization:  {
    __typename: "Organization",
    CreatedAt: string,
    Ein: string,
    Id: string,
    Name: string,
    OrganizationAddresses?:  Array< {
      __typename: "OrganizationAddress",
      City: string,
      CreatedAt: string,
      Id: string,
      OrganizationId: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      OrganizationAddressTypes: OrganizationAddressType,
      UpdatedAt: string,
      ZipCode: string,
    } | null > | null,
    Phone: string,
    PhoneExt?: string | null,
    Storefront: boolean,
    Type: OrganizationType,
    UpdatedAt: string,
  },
};

export type CreateOrganizationAddressMutationVariables = {
  Input: CreateOrganizationAddressInput,
};

export type CreateOrganizationAddressMutation = {
  CreateOrganizationAddress:  {
    __typename: "OrganizationAddress",
    City: string,
    CreatedAt: string,
    Id: string,
    OrganizationId: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    OrganizationAddressTypes: OrganizationAddressType,
    UpdatedAt: string,
    ZipCode: string,
  },
};

export type CreatePartnerMutationVariables = {
  Input: CreatePartnerInput,
};

export type CreatePartnerMutation = {
  CreatePartner?:  {
    __typename: "Partner",
    Active: boolean,
    ApplicationStatus: PartnerApplicationStatus,
    Birthdate?: string | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    LastName: string,
    MiddleName?: string | null,
    OrganizationId?: string | null,
    Phone: string,
    PhoneExt?: number | null,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    ReferralCode: string,
    RegistrationCodeId?: string | null,
    RejectionReason?: RejectionReason | null,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SmsEnabled: boolean,
    Ssn?: string | null,
    TaxProfessional?:  {
      __typename: "TaxProfessional",
      Bookkeeping: boolean,
      CreatedAt?: string | null,
      DisciplinaryAction: boolean,
      DisciplinaryActionDesc?: string | null,
      EoCoverage: number,
      EoInsurance: boolean,
      FilingSoftware: Array< TaxProfessionalFilingSoftware >,
      Id: string,
      IrsAudit: boolean,
      IrsAuditDesc?: string | null,
      Languages: Array< Language >,
      Licenses: Array< TaxProfessionalLicense >,
      PartnerId: string,
      PrepCompetency: boolean,
      Ptin: string,
      ReturnForms: Array< TaxProfessionalReturnForm >,
      ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
      TaxResolution: boolean,
      TaxReturnsPrepared: number,
      UpdatedAt?: string | null,
      YearsOfExperience: number,
    } | null,
    Title: PartnerTitle,
    Type?: PartnerType | null,
    UpdatedAt: string,
  } | null,
};

export type CreatePartnerDeletionMutationVariables = {
  Input: CreatePartnerDeletionInput,
};

export type CreatePartnerDeletionMutation = {
  CreatePartnerDeletion:  {
    __typename: "PartnerDeletion",
    CompletedAt?: string | null,
    CreatedAt: string,
    DeletionReason: DeletionReason,
    Id: string,
    InitiatedAt: string,
    PartnerId: string,
    Status: DeletionStatus,
    UpdatedAt: string,
  },
};

export type CreatePartnerEnrollmentActionMutationVariables = {
  Input?: CreatePartnerEnrollmentActionInput | null,
};

export type CreatePartnerEnrollmentActionMutation = {
  CreatePartnerEnrollmentAction:  {
    __typename: "PartnerEnrollmentAction",
    CreatedAt: string,
    Id: string,
    Name: PartnerEnrollmentActionName,
    PartnerEnrollment:  {
      __typename: "PartnerEnrollment",
      CancelledAt?: string | null,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      InitiatedAt?: string | null,
      NextEnrollmentAction?: NextPartnerEnrollmentAction | null,
      PartnerId: string,
      Status: PartnerEnrollmentStatus,
      UpdatedAt: string,
    },
    PartnerEnrollmentId: string,
    UpdatedAt: string,
  },
};

export type CreatePaymentMethodMutationVariables = {
  Input: CreatePaymentMethodInput,
};

export type CreatePaymentMethodMutation = {
  CreatePaymentMethod:  {
    __typename: "PaymentMethod",
    AchEntityType?: AchEntityType | null,
    AchNumber?: string | null,
    AchRoutingNumber?: string | null,
    AchType?: AchType | null,
    Active: boolean,
    CcExpMonth?: string | null,
    CcExpYear?: string | null,
    CcLastFourDigits?: string | null,
    CcType?: CcType | null,
    City: string,
    CreatedAt: string,
    Default: boolean,
    Id: string,
    Name: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    Token: string,
    Type: PaymentMethodType,
    UpdatedAt: string,
    Vendor: PaymentMethodVendor,
    ZipCode: string,
  },
};

export type CreateProfileMutationVariables = {
  Input: CreateProfileInput,
  SignUpParametersInput?: SignUpParametersInput | null,
};

export type CreateProfileMutation = {
  CreateProfile?:  {
    __typename: "Profile",
    AccountId: string,
    Active: boolean,
    Birthdate?: string | null,
    CafStatus?: ProfileCafStatus | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    EnrollmentSource: EnrollmentSource,
    FirstName: string,
    Id: string,
    Languages?: Array< Language > | null,
    LastName: string,
    MiddleName?: string | null,
    PartnerId?: string | null,
    Phone: string,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SelfEnrolled: boolean,
    SmsEnabled: boolean,
    Ssn?: string | null,
    UpdatedAt: string,
  } | null,
};

export type CreateProfileDeletionMutationVariables = {
  Input: CreateProfileDeletionInput,
};

export type CreateProfileDeletionMutation = {
  CreateProfileDeletion:  {
    __typename: "ProfileDeletion",
    CompletedAt?: string | null,
    CreatedAt: string,
    DeletionReason: DeletionReason,
    Id: string,
    InitiatedAt: string,
    ProfileId: string,
    Status: DeletionStatus,
    UpdatedAt: string,
  },
};

export type CreateProfileFormMutationVariables = {
  Input: CreateProfileFormInput,
};

export type CreateProfileFormMutation = {
  CreateProfileForm?:  {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } | null,
};

export type CreateTaxExtensionMutationVariables = {
  Input: CreateTaxExtensionInput,
  ProfileId: string,
};

export type CreateTaxExtensionMutation = {
  CreateTaxExtension:  {
    __typename: "TaxExtension",
    CreatedAt: string,
    FilingStatus: TaxExtensionFilingStatus,
    FilingType: TaxExtensionFilingType,
    Form1040Nr: boolean,
    Id: string,
    MaritalFilingStatus: TaxExtensionMaritalFilingStatus,
    OutOfCountry: boolean,
    PoBoxAddress?: string | null,
    PrimaryEntity:  {
      __typename: "TaxFilingPrimaryEntity",
      City: string,
      Email: string,
      FirstName: string,
      LastName: string,
      Ssn: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      Zipcode: string,
    },
    SpouseEntity?:  {
      __typename: "TaxFilingSpouseEntity",
      FirstName: string,
      LastName: string,
      Ssn: string,
    } | null,
    TaxProgramId?: string | null,
    TaxProgramStage: TaxProgramStage,
    TotalLiability: number,
    TotalPayments: number,
    UpdatedAt: string,
    Year: number,
  },
};

export type CreateTaxProfessionalMutationVariables = {
  Input: CreateTaxProfessionalInput,
};

export type CreateTaxProfessionalMutation = {
  CreateTaxProfessional:  {
    __typename: "TaxProfessional",
    Bookkeeping: boolean,
    CreatedAt?: string | null,
    DisciplinaryAction: boolean,
    DisciplinaryActionDesc?: string | null,
    EoCoverage: number,
    EoInsurance: boolean,
    FilingSoftware: Array< TaxProfessionalFilingSoftware >,
    Id: string,
    IrsAudit: boolean,
    IrsAuditDesc?: string | null,
    Languages: Array< Language >,
    Licenses: Array< TaxProfessionalLicense >,
    PartnerId: string,
    PrepCompetency: boolean,
    Ptin: string,
    ReturnForms: Array< TaxProfessionalReturnForm >,
    ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
    TaxResolution: boolean,
    TaxReturnsPrepared: number,
    UpdatedAt?: string | null,
    YearsOfExperience: number,
  },
};

export type DeleteOrganizationAddressMutationVariables = {
  OrganizationAddressId: string,
};

export type DeleteOrganizationAddressMutation = {
  DeleteOrganizationAddress:  {
    __typename: "OrganizationAddress",
    City: string,
    CreatedAt: string,
    Id: string,
    OrganizationId: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    OrganizationAddressTypes: OrganizationAddressType,
    UpdatedAt: string,
    ZipCode: string,
  },
};

export type IdentifyNoticeMutationVariables = {
  AmountDue?: number | null,
  Description?: string | null,
  DueDate?: string | null,
  Error?: string | null,
  Name?: string | null,
  OverDue?: boolean | null,
  ProfileId: string,
  RiskLevel?: NoticeAnalysisRiskLevel | null,
};

export type IdentifyNoticeMutation = {
  IdentifyNotice?:  {
    __typename: "NoticeAssessment",
    AmountDue?: number | null,
    Description?: string | null,
    DueDate?: string | null,
    Error?: string | null,
    Name?: string | null,
    OverDue?: boolean | null,
    ProfileId: string,
    RiskLevel?: NoticeAnalysisRiskLevel | null,
  } | null,
};

export type InitiateEnrollmentMutationVariables = {
  Input: InitiateEnrollmentInput,
};

export type InitiateEnrollmentMutation = {
  InitiateEnrollment?:  {
    __typename: "Enrollment",
    CancellationReason?: EnrollmentCancellationReason | null,
    CancelledAt?: string | null,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    InitiatedAt?: string | null,
    NextEnrollmentAction?: NextEnrollmentAction | null,
    NextForm?:  {
      __typename: "Form",
      Active: boolean,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      Name: FormName,
      S3Bucket?: string | null,
      S3Key?: string | null,
      S3VersionId?: string | null,
      Status?: FormStatus | null,
      TemplateId: string,
      UpdatedAt: string,
    } | null,
    Profile:  {
      __typename: "Profile",
      AccountId: string,
      Active: boolean,
      Birthdate?: string | null,
      CafStatus?: ProfileCafStatus | null,
      CallEnabled: boolean,
      ConsentToShare: boolean,
      CreatedAt: string,
      Email: string,
      EnrollmentSource: EnrollmentSource,
      FirstName: string,
      Id: string,
      Languages?: Array< Language > | null,
      LastName: string,
      MiddleName?: string | null,
      PartnerId?: string | null,
      Phone: string,
      PrimaryCognitoUserId: string,
      PrimaryUserPoolId: string,
      SecondaryCognitoUserId?: string | null,
      SecondaryUserPoolId?: string | null,
      SelfEnrolled: boolean,
      SmsEnabled: boolean,
      Ssn?: string | null,
      UpdatedAt: string,
    },
    ProfileId: string,
    ProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    ProtectionPlanId?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    Status: EnrollmentStatus,
    TranscriptRequestId?: string | null,
    Type: EnrollmentType,
    UpdatedAt: string,
  } | null,
};

export type InitiatePartnerEnrollmentMutationVariables = {
  Input: InitiatePartnerEnrollmentInput,
};

export type InitiatePartnerEnrollmentMutation = {
  InitiatePartnerEnrollment:  {
    __typename: "PartnerEnrollment",
    CancelledAt?: string | null,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    InitiatedAt?: string | null,
    NextEnrollmentAction?: NextPartnerEnrollmentAction | null,
    NextForm?:  {
      __typename: "Form",
      Active: boolean,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      Name: FormName,
      S3Bucket?: string | null,
      S3Key?: string | null,
      S3VersionId?: string | null,
      Status?: FormStatus | null,
      TemplateId: string,
      UpdatedAt: string,
    } | null,
    Partner:  {
      __typename: "Partner",
      Active: boolean,
      ApplicationStatus: PartnerApplicationStatus,
      Birthdate?: string | null,
      CallEnabled: boolean,
      ConsentToShare: boolean,
      CreatedAt: string,
      Email: string,
      FirstName: string,
      Id: string,
      LastName: string,
      MiddleName?: string | null,
      OrganizationId?: string | null,
      Phone: string,
      PhoneExt?: number | null,
      PrimaryCognitoUserId: string,
      PrimaryUserPoolId: string,
      ReferralCode: string,
      RegistrationCodeId?: string | null,
      RejectionReason?: RejectionReason | null,
      SecondaryCognitoUserId?: string | null,
      SecondaryUserPoolId?: string | null,
      SmsEnabled: boolean,
      Ssn?: string | null,
      Title: PartnerTitle,
      Type?: PartnerType | null,
      UpdatedAt: string,
    },
    PartnerId: string,
    Status: PartnerEnrollmentStatus,
    UpdatedAt: string,
  },
};

export type MigrateProfileMutationVariables = {
  Input: MigrateProfileInput,
};

export type MigrateProfileMutation = {
  MigrateProfile:  {
    __typename: "MigratedProfile",
    Id: string,
  },
};

export type PurchaseProtectionPlanMutationVariables = {
  Input: PurchaseProtectionPlanInput,
};

export type PurchaseProtectionPlanMutation = {
  PurchaseProtectionPlan:  {
    __typename: "Enrollment",
    CancellationReason?: EnrollmentCancellationReason | null,
    CancelledAt?: string | null,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    InitiatedAt?: string | null,
    NextEnrollmentAction?: NextEnrollmentAction | null,
    NextForm?:  {
      __typename: "Form",
      Active: boolean,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      Name: FormName,
      S3Bucket?: string | null,
      S3Key?: string | null,
      S3VersionId?: string | null,
      Status?: FormStatus | null,
      TemplateId: string,
      UpdatedAt: string,
    } | null,
    Profile:  {
      __typename: "Profile",
      AccountId: string,
      Active: boolean,
      Birthdate?: string | null,
      CafStatus?: ProfileCafStatus | null,
      CallEnabled: boolean,
      ConsentToShare: boolean,
      CreatedAt: string,
      Email: string,
      EnrollmentSource: EnrollmentSource,
      FirstName: string,
      Id: string,
      Languages?: Array< Language > | null,
      LastName: string,
      MiddleName?: string | null,
      PartnerId?: string | null,
      Phone: string,
      PrimaryCognitoUserId: string,
      PrimaryUserPoolId: string,
      SecondaryCognitoUserId?: string | null,
      SecondaryUserPoolId?: string | null,
      SelfEnrolled: boolean,
      SmsEnabled: boolean,
      Ssn?: string | null,
      UpdatedAt: string,
    },
    ProfileId: string,
    ProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    ProtectionPlanId?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    Status: EnrollmentStatus,
    TranscriptRequestId?: string | null,
    Type: EnrollmentType,
    UpdatedAt: string,
  },
};

export type QualifyEnrollmentRequestMutationVariables = {
  Input?: QualifyEnrollmentRequestInput | null,
};

export type QualifyEnrollmentRequestMutation = {
  QualifyEnrollmentRequest?:  {
    __typename: "QualifyingEnrollmentResult",
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    Result: QualifyingAnswerResult,
    ResultExplanation?: string | null,
  } | null,
};

export type ReferAdditionalServicesMutationVariables = {
  LeadId: string,
};

export type ReferAdditionalServicesMutation = {
  ReferAdditionalServices:  {
    __typename: "Lead",
    AccountId?: string | null,
    Active: boolean,
    ConsentToDisclose: boolean,
    ConvertedAt?: string | null,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    IsConverted: boolean,
    IsQualified: boolean,
    LastName: string,
    PartnerId?: string | null,
    Phone: string,
    PhoneExt?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    TaxDebt?: number | null,
    UpdatedAt: string,
  },
};

export type UpdateEnrollmentProtectionPlanMutationVariables = {
  EnrollmentId: string,
  ProtectionPlanId: string,
};

export type UpdateEnrollmentProtectionPlanMutation = {
  UpdateEnrollmentProtectionPlan:  {
    __typename: "Enrollment",
    CancellationReason?: EnrollmentCancellationReason | null,
    CancelledAt?: string | null,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    InitiatedAt?: string | null,
    NextEnrollmentAction?: NextEnrollmentAction | null,
    NextForm?:  {
      __typename: "Form",
      Active: boolean,
      CompletedAt?: string | null,
      CreatedAt: string,
      Id: string,
      Name: FormName,
      S3Bucket?: string | null,
      S3Key?: string | null,
      S3VersionId?: string | null,
      Status?: FormStatus | null,
      TemplateId: string,
      UpdatedAt: string,
    } | null,
    Profile:  {
      __typename: "Profile",
      AccountId: string,
      Active: boolean,
      Birthdate?: string | null,
      CafStatus?: ProfileCafStatus | null,
      CallEnabled: boolean,
      ConsentToShare: boolean,
      CreatedAt: string,
      Email: string,
      EnrollmentSource: EnrollmentSource,
      FirstName: string,
      Id: string,
      Languages?: Array< Language > | null,
      LastName: string,
      MiddleName?: string | null,
      PartnerId?: string | null,
      Phone: string,
      PrimaryCognitoUserId: string,
      PrimaryUserPoolId: string,
      SecondaryCognitoUserId?: string | null,
      SecondaryUserPoolId?: string | null,
      SelfEnrolled: boolean,
      SmsEnabled: boolean,
      Ssn?: string | null,
      UpdatedAt: string,
    },
    ProfileId: string,
    ProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    ProtectionPlanId?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    Status: EnrollmentStatus,
    TranscriptRequestId?: string | null,
    Type: EnrollmentType,
    UpdatedAt: string,
  },
};

export type UpdateOrganizationMutationVariables = {
  Input: UpdateOrganizationInput,
};

export type UpdateOrganizationMutation = {
  UpdateOrganization:  {
    __typename: "Organization",
    CreatedAt: string,
    Ein: string,
    Id: string,
    Name: string,
    OrganizationAddresses?:  Array< {
      __typename: "OrganizationAddress",
      City: string,
      CreatedAt: string,
      Id: string,
      OrganizationId: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      OrganizationAddressTypes: OrganizationAddressType,
      UpdatedAt: string,
      ZipCode: string,
    } | null > | null,
    Phone: string,
    PhoneExt?: string | null,
    Storefront: boolean,
    Type: OrganizationType,
    UpdatedAt: string,
  },
};

export type UpdateOrganizationAddressMutationVariables = {
  Input: UpdateOrganizationAddressInput,
};

export type UpdateOrganizationAddressMutation = {
  UpdateOrganizationAddress:  {
    __typename: "OrganizationAddress",
    City: string,
    CreatedAt: string,
    Id: string,
    OrganizationId: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    OrganizationAddressTypes: OrganizationAddressType,
    UpdatedAt: string,
    ZipCode: string,
  },
};

export type UpdatePartnerMutationVariables = {
  Input: UpdatePartnerInput,
};

export type UpdatePartnerMutation = {
  UpdatePartner?:  {
    __typename: "Partner",
    Active: boolean,
    ApplicationStatus: PartnerApplicationStatus,
    Birthdate?: string | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    LastName: string,
    MiddleName?: string | null,
    OrganizationId?: string | null,
    Phone: string,
    PhoneExt?: number | null,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    ReferralCode: string,
    RegistrationCodeId?: string | null,
    RejectionReason?: RejectionReason | null,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SmsEnabled: boolean,
    Ssn?: string | null,
    TaxProfessional?:  {
      __typename: "TaxProfessional",
      Bookkeeping: boolean,
      CreatedAt?: string | null,
      DisciplinaryAction: boolean,
      DisciplinaryActionDesc?: string | null,
      EoCoverage: number,
      EoInsurance: boolean,
      FilingSoftware: Array< TaxProfessionalFilingSoftware >,
      Id: string,
      IrsAudit: boolean,
      IrsAuditDesc?: string | null,
      Languages: Array< Language >,
      Licenses: Array< TaxProfessionalLicense >,
      PartnerId: string,
      PrepCompetency: boolean,
      Ptin: string,
      ReturnForms: Array< TaxProfessionalReturnForm >,
      ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
      TaxResolution: boolean,
      TaxReturnsPrepared: number,
      UpdatedAt?: string | null,
      YearsOfExperience: number,
    } | null,
    Title: PartnerTitle,
    Type?: PartnerType | null,
    UpdatedAt: string,
  } | null,
};

export type UpdatePaymentMethodMutationVariables = {
  Input: UpdatePaymentMethodInput,
};

export type UpdatePaymentMethodMutation = {
  UpdatePaymentMethod:  {
    __typename: "PaymentMethod",
    AchEntityType?: AchEntityType | null,
    AchNumber?: string | null,
    AchRoutingNumber?: string | null,
    AchType?: AchType | null,
    Active: boolean,
    CcExpMonth?: string | null,
    CcExpYear?: string | null,
    CcLastFourDigits?: string | null,
    CcType?: CcType | null,
    City: string,
    CreatedAt: string,
    Default: boolean,
    Id: string,
    Name: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    Token: string,
    Type: PaymentMethodType,
    UpdatedAt: string,
    Vendor: PaymentMethodVendor,
    ZipCode: string,
  },
};

export type UpdateProfileMutationVariables = {
  Input: UpdateProfileInput,
};

export type UpdateProfileMutation = {
  UpdateProfile?:  {
    __typename: "Profile",
    AccountId: string,
    Active: boolean,
    Birthdate?: string | null,
    CafStatus?: ProfileCafStatus | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    EnrollmentSource: EnrollmentSource,
    FirstName: string,
    Id: string,
    Languages?: Array< Language > | null,
    LastName: string,
    MiddleName?: string | null,
    PartnerId?: string | null,
    Phone: string,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SelfEnrolled: boolean,
    SmsEnabled: boolean,
    Ssn?: string | null,
    UpdatedAt: string,
  } | null,
};

export type UpdateTaxProfessionalMutationVariables = {
  Input: UpdateTaxProfessionalInput,
};

export type UpdateTaxProfessionalMutation = {
  UpdateTaxProfessional:  {
    __typename: "TaxProfessional",
    Bookkeeping: boolean,
    CreatedAt?: string | null,
    DisciplinaryAction: boolean,
    DisciplinaryActionDesc?: string | null,
    EoCoverage: number,
    EoInsurance: boolean,
    FilingSoftware: Array< TaxProfessionalFilingSoftware >,
    Id: string,
    IrsAudit: boolean,
    IrsAuditDesc?: string | null,
    Languages: Array< Language >,
    Licenses: Array< TaxProfessionalLicense >,
    PartnerId: string,
    PrepCompetency: boolean,
    Ptin: string,
    ReturnForms: Array< TaxProfessionalReturnForm >,
    ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
    TaxResolution: boolean,
    TaxReturnsPrepared: number,
    UpdatedAt?: string | null,
    YearsOfExperience: number,
  },
};

export type UpsertProfilePrimaryAddressMutationVariables = {
  Input: UpsertProfilePrimaryAddressInput,
};

export type UpsertProfilePrimaryAddressMutation = {
  UpsertProfilePrimaryAddress:  {
    __typename: "ProfileAddress",
    City: string,
    CreatedAt: string,
    Id: string,
    ProfileId: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    UpdatedAt: string,
    ZipCode: string,
  },
};

export type GetAppointmentAvailabilityQueryVariables = {
  Input: AppointmentAvailabilityRequest,
};

export type GetAppointmentAvailabilityQuery = {
  GetAppointmentAvailability?:  Array< {
    __typename: "AppointmentAvailability",
    AppointmentLength?: string | null,
    AvailableTimes?:  Array< {
      __typename: "AppointmentAvailabilityItem",
      AvailabilityTime?: string | null,
      DisplayTime?: string | null,
      TimeZone?: string | null,
      TimeZoneShort?: string | null,
    } | null > | null,
    Date?: string | null,
  } | null > | null,
};

export type GetAppointmentByIdQueryVariables = {
  AppointmentId: string,
};

export type GetAppointmentByIdQuery = {
  GetAppointmentById?:  {
    __typename: "Appointment",
    AgentId: string,
    AppointmentId: string,
    EndsAt: string,
    ProfileId: string,
    StartAtDisplayDate?: string | null,
    StartAtDisplayTime?: string | null,
    StartsAt: string,
    TimeZone?: string | null,
    TimeZoneShort?: string | null,
    Topic?: string | null,
  } | null,
};

export type GetAppointmentTopicsQuery = {
  GetAppointmentTopics?:  Array< {
    __typename: "DisplayProperty",
    DisplayText?: string | null,
    Value?: string | null,
  } | null > | null,
};

export type GetAppointmentsByProfileIdQueryVariables = {
  ProfileId: string,
};

export type GetAppointmentsByProfileIdQuery = {
  GetAppointmentsByProfileId?:  Array< {
    __typename: "Appointment",
    AgentId: string,
    AppointmentId: string,
    EndsAt: string,
    ProfileId: string,
    StartAtDisplayDate?: string | null,
    StartAtDisplayTime?: string | null,
    StartsAt: string,
    TimeZone?: string | null,
    TimeZoneShort?: string | null,
    Topic?: string | null,
  } | null > | null,
};

export type GetFreeTaxExtensionEligibilityQueryVariables = {
  ProfileId: string,
};

export type GetFreeTaxExtensionEligibilityQuery = {
  GetFreeTaxExtensionEligibility?:  {
    __typename: "TaxExtensionEligibilityResult",
    IneligibilityReason?: TaxExtensionIneligibilityReason | null,
    IsEligible: boolean,
    LastTaxExtension?:  {
      __typename: "TaxExtension",
      CreatedAt: string,
      FilingStatus: TaxExtensionFilingStatus,
      FilingType: TaxExtensionFilingType,
      Form1040Nr: boolean,
      Id: string,
      MaritalFilingStatus: TaxExtensionMaritalFilingStatus,
      OutOfCountry: boolean,
      PoBoxAddress?: string | null,
      TaxProgramId?: string | null,
      TaxProgramStage: TaxProgramStage,
      TotalLiability: number,
      TotalPayments: number,
      UpdatedAt: string,
      Year: number,
    } | null,
  } | null,
};

export type GetFreeTaxRiskReportEligibilityQueryVariables = {
  ProfileId: string,
};

export type GetFreeTaxRiskReportEligibilityQuery = {
  GetFreeTaxRiskReportEligibility?:  {
    __typename: "TaxRiskReportEligibilityResult",
    EnrollmentInitiated?: boolean | null,
    IsEligible: boolean,
    LastTaxRiskReport?:  {
      __typename: "SimplifiedTaxRiskReport",
      Id?: string | null,
      OverallRisk?: RiskIndicatorOverallRisk | null,
      OverallRiskDescription?: string | null,
      RequestedAt: string,
      Status: TaxRiskReportStatus,
    } | null,
  } | null,
};

export type GetLatestFormsByPartnerQueryVariables = {
  PartnerId: string,
};

export type GetLatestFormsByPartnerQuery = {
  GetLatestFormsByPartner?:  Array< {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } > | null,
};

export type GetLatestFormsByProfileQueryVariables = {
  ProfileId: string,
};

export type GetLatestFormsByProfileQuery = {
  GetLatestFormsByProfile?:  Array< {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } > | null,
};

export type GetLatestTaxRiskReportQueryVariables = {
  ProfileId: string,
};

export type GetLatestTaxRiskReportQuery = {
  GetLatestTaxRiskReport?:  {
    __typename: "SimplifiedTaxRiskReport",
    Id?: string | null,
    OverallRisk?: RiskIndicatorOverallRisk | null,
    OverallRiskDescription?: string | null,
    RequestedAt: string,
    Status: TaxRiskReportStatus,
  } | null,
};

export type GetLeadByEmailQueryVariables = {
  Email: string,
};

export type GetLeadByEmailQuery = {
  GetLeadByEmail?:  {
    __typename: "Lead",
    AccountId?: string | null,
    Active: boolean,
    ConsentToDisclose: boolean,
    ConvertedAt?: string | null,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    IsConverted: boolean,
    IsQualified: boolean,
    LastName: string,
    PartnerId?: string | null,
    Phone: string,
    PhoneExt?: string | null,
    RecommendedProtectionPlan?:  {
      __typename: "ProtectionPlan",
      Active: boolean,
      ChargeSalesTax: boolean,
      CreatedAt: string,
      Description?: string | null,
      Id: string,
      Link?: string | null,
      MonthlyPrice: number,
      Name: ProtectionPlanName,
      Title: string,
      UpdatedAt: string,
    } | null,
    RecommendedProtectionPlanId?: string | null,
    TaxDebt?: number | null,
    UpdatedAt: string,
  } | null,
};

export type GetLeadsByPartnerQueryVariables = {
  Limit?: number | null,
  NextToken?: string | null,
  PartnerId: string,
  Search?: string | null,
};

export type GetLeadsByPartnerQuery = {
  GetLeadsByPartner?:  {
    __typename: "LeadSummaryConnection",
    LeadSummaries?:  Array< {
      __typename: "LeadSummary",
      DangerFlag: boolean,
      Email: string,
      EnrolledAt?: string | null,
      FirstName: string,
      LastName: string,
      LeadId: string,
      LeadStatus: LeadSummaryStatus,
      MiddleName?: string | null,
      PartnerId?: string | null,
      PaymentHold?: boolean | null,
      Phone: string,
      ProfileId?: string | null,
      ReferredAt: string,
      TaxRiskReportRiskLevel?: RiskIndicatorOverallRisk | null,
    } > | null,
    NextToken?: string | null,
    TotalTokens?: number | null,
  } | null,
};

export type GetMonitoringBenefitsQuery = {
  GetMonitoringBenefits:  {
    __typename: "MonitoringBenefits",
    Items: Array< string >,
    Type: string,
  },
};

export type GetOrganizationQueryVariables = {
  OrganizationId: string,
};

export type GetOrganizationQuery = {
  GetOrganization:  {
    __typename: "Organization",
    CreatedAt: string,
    Ein: string,
    Id: string,
    Name: string,
    OrganizationAddresses?:  Array< {
      __typename: "OrganizationAddress",
      City: string,
      CreatedAt: string,
      Id: string,
      OrganizationId: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      OrganizationAddressTypes: OrganizationAddressType,
      UpdatedAt: string,
      ZipCode: string,
    } | null > | null,
    Phone: string,
    PhoneExt?: string | null,
    Storefront: boolean,
    Type: OrganizationType,
    UpdatedAt: string,
  },
};

export type GetPartnerQueryVariables = {
  PartnerId: string,
};

export type GetPartnerQuery = {
  GetPartner?:  {
    __typename: "Partner",
    Active: boolean,
    ApplicationStatus: PartnerApplicationStatus,
    Birthdate?: string | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    FirstName: string,
    Id: string,
    LastName: string,
    MiddleName?: string | null,
    OrganizationId?: string | null,
    Phone: string,
    PhoneExt?: number | null,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    ReferralCode: string,
    RegistrationCodeId?: string | null,
    RejectionReason?: RejectionReason | null,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SmsEnabled: boolean,
    Ssn?: string | null,
    TaxProfessional?:  {
      __typename: "TaxProfessional",
      Bookkeeping: boolean,
      CreatedAt?: string | null,
      DisciplinaryAction: boolean,
      DisciplinaryActionDesc?: string | null,
      EoCoverage: number,
      EoInsurance: boolean,
      FilingSoftware: Array< TaxProfessionalFilingSoftware >,
      Id: string,
      IrsAudit: boolean,
      IrsAuditDesc?: string | null,
      Languages: Array< Language >,
      Licenses: Array< TaxProfessionalLicense >,
      PartnerId: string,
      PrepCompetency: boolean,
      Ptin: string,
      ReturnForms: Array< TaxProfessionalReturnForm >,
      ReturnSchedules: Array< TaxProfessionalReturnSchedule >,
      TaxResolution: boolean,
      TaxReturnsPrepared: number,
      UpdatedAt?: string | null,
      YearsOfExperience: number,
    } | null,
    Title: PartnerTitle,
    Type?: PartnerType | null,
    UpdatedAt: string,
  } | null,
};

export type GetPaymentMethodQueryVariables = {
  PaymentMethodId: string,
};

export type GetPaymentMethodQuery = {
  GetPaymentMethod?:  {
    __typename: "PaymentMethod",
    AchEntityType?: AchEntityType | null,
    AchNumber?: string | null,
    AchRoutingNumber?: string | null,
    AchType?: AchType | null,
    Active: boolean,
    CcExpMonth?: string | null,
    CcExpYear?: string | null,
    CcLastFourDigits?: string | null,
    CcType?: CcType | null,
    City: string,
    CreatedAt: string,
    Default: boolean,
    Id: string,
    Name: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    Token: string,
    Type: PaymentMethodType,
    UpdatedAt: string,
    Vendor: PaymentMethodVendor,
    ZipCode: string,
  } | null,
};

export type GetPaymentMethodsByAccountQueryVariables = {
  AccountId: string,
  Domain: Domain,
  Limit?: number | null,
  NextToken?: string | null,
};

export type GetPaymentMethodsByAccountQuery = {
  GetPaymentMethodsByAccount?:  {
    __typename: "PaymentMethodConnection",
    NextToken?: string | null,
    PaymentMethods:  Array< {
      __typename: "PaymentMethod",
      AchEntityType?: AchEntityType | null,
      AchNumber?: string | null,
      AchRoutingNumber?: string | null,
      AchType?: AchType | null,
      Active: boolean,
      CcExpMonth?: string | null,
      CcExpYear?: string | null,
      CcLastFourDigits?: string | null,
      CcType?: CcType | null,
      City: string,
      CreatedAt: string,
      Default: boolean,
      Id: string,
      Name: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      Token: string,
      Type: PaymentMethodType,
      UpdatedAt: string,
      Vendor: PaymentMethodVendor,
      ZipCode: string,
    } | null >,
  } | null,
};

export type GetPendingFormsByProfileQueryVariables = {
  FormName: FormName,
  ProfileId: string,
};

export type GetPendingFormsByProfileQuery = {
  GetPendingFormsByProfile?:  Array< {
    __typename: "Form",
    Active: boolean,
    CompletedAt?: string | null,
    CreatedAt: string,
    Id: string,
    Name: FormName,
    PartnerForms?:  Array< {
      __typename: "PartnerForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      PartnerId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    ProfileForms?:  Array< {
      __typename: "ProfileForm",
      CompletedAt: string,
      FormId: string,
      Id: string,
      Ip: string,
      Owner: boolean,
      ProfileId: string,
      SignatureS3Bucket?: string | null,
      SignatureS3Key?: string | null,
      SignatureS3VersionId?: string | null,
    } | null > | null,
    S3Bucket?: string | null,
    S3Key?: string | null,
    S3VersionId?: string | null,
    Status?: FormStatus | null,
    TemplateId: string,
    UpdatedAt: string,
  } > | null,
};

export type GetPhoneNumbersQuery = {
  GetPhoneNumbers:  {
    __typename: "PhoneNumbers",
    PartnerContactUs: string,
    ProfileContactUs: string,
  },
};

export type GetProfileQueryVariables = {
  ProfileId: string,
};

export type GetProfileQuery = {
  GetProfile?:  {
    __typename: "Profile",
    AccountId: string,
    Active: boolean,
    Birthdate?: string | null,
    CafStatus?: ProfileCafStatus | null,
    CallEnabled: boolean,
    ConsentToShare: boolean,
    CreatedAt: string,
    Email: string,
    EnrollmentSource: EnrollmentSource,
    FirstName: string,
    Id: string,
    Languages?: Array< Language > | null,
    LastName: string,
    MiddleName?: string | null,
    PartnerId?: string | null,
    Phone: string,
    PrimaryCognitoUserId: string,
    PrimaryUserPoolId: string,
    SecondaryCognitoUserId?: string | null,
    SecondaryUserPoolId?: string | null,
    SelfEnrolled: boolean,
    SmsEnabled: boolean,
    Ssn?: string | null,
    UpdatedAt: string,
  } | null,
};

export type GetProfilePrimaryAddressQueryVariables = {
  ProfileId: string,
};

export type GetProfilePrimaryAddressQuery = {
  GetProfilePrimaryAddress?:  {
    __typename: "ProfileAddress",
    City: string,
    CreatedAt: string,
    Id: string,
    ProfileId: string,
    State: UsState,
    StreetAddress1: string,
    StreetAddress2?: string | null,
    UpdatedAt: string,
    ZipCode: string,
  } | null,
};

export type GetProtectionBenefitsQuery = {
  GetProtectionBenefits:  {
    __typename: "ProtectionBenefits",
    Items: Array< string >,
    Type: string,
  },
};

export type GetProtectionPlanEligibilityQueryVariables = {
  ProfileId: string,
};

export type GetProtectionPlanEligibilityQuery = {
  GetProtectionPlanEligibility:  {
    __typename: "ProtectionPlanEligibilityResult",
    EnrollmentInitiated?: boolean | null,
    EnrollmentType?: EnrollmentType | null,
    IsEligible: boolean,
    LastTaxRiskReport?:  {
      __typename: "SimplifiedTaxRiskReport",
      Id?: string | null,
      OverallRisk?: RiskIndicatorOverallRisk | null,
      OverallRiskDescription?: string | null,
      RequestedAt: string,
      Status: TaxRiskReportStatus,
    } | null,
  },
};

export type GetProtectionPlanFeaturesQueryVariables = {
  ProtectionPlanName: ProtectionPlanName,
};

export type GetProtectionPlanFeaturesQuery = {
  GetProtectionPlanFeatures?:  {
    __typename: "ProtectionPlanFeatures",
    Features:  {
      __typename: "ProtectionPlanFeature",
      Disclaimers?: string | null,
      Groups?: string | null,
    },
    Type: string,
  } | null,
};

export type GetProtectionPlanSubscriptionQueryVariables = {
  ProfileId: string,
};

export type GetProtectionPlanSubscriptionQuery = {
  GetProtectionPlanSubscription?:  {
    __typename: "ProtectionPlanSubscription",
    Active: boolean,
    Amount: number,
    CancellationReason?: SubscriptionCancellationReason | null,
    ChargeSalesTax?: boolean | null,
    CreatedAt: string,
    EndAt?: string | null,
    Id: string,
    NextPaymentDate?: string | null,
    PaymentDay?: number | null,
    StartAt: string,
    Status: SubscriptionStatus,
    UpdatedAt: string,
  } | null,
};

export type GetProtectionPlansQuery = {
  GetProtectionPlans?:  Array< {
    __typename: "ProtectionPlan",
    Active: boolean,
    ChargeSalesTax: boolean,
    CreatedAt: string,
    Description?: string | null,
    Id: string,
    Link?: string | null,
    MonthlyPrice: number,
    Name: ProtectionPlanName,
    Title: string,
    UpdatedAt: string,
  } | null > | null,
};

export type GetReferralSummaryQueryVariables = {
  PartnerId: string,
};

export type GetReferralSummaryQuery = {
  GetReferralSummary?:  {
    __typename: "ReferralSummary",
    ActiveCount: number,
    InactiveCount: number,
    NextPayoutAmount: number,
    NextPayoutDate: string,
    PartnerId: string,
    PendingCount: number,
    TotalCommissionEarned: number,
  } | null,
};

export type GetRegistrationCodeQueryVariables = {
  Code: string,
};

export type GetRegistrationCodeQuery = {
  GetRegistrationCode?:  {
    __typename: "RegistrationCode",
    Active: boolean,
    Code: string,
    CreatedAt: string,
    Id: string,
    Redeemed: boolean,
    RedeemedAt?: string | null,
    Type: RegistrationCodeType,
    UpdatedAt: string,
  } | null,
};

export type GetReportRiskIndicatorQueryVariables = {
  Type: ReportRiskIndicatorType,
};

export type GetReportRiskIndicatorQuery = {
  GetReportRiskIndicator?:  {
    __typename: "ReportRiskIndicator",
    Content?: string | null,
    Type: ReportRiskIndicatorType,
  } | null,
};

export type GetTaxExtensionByProfileQueryVariables = {
  ProfileId: string,
};

export type GetTaxExtensionByProfileQuery = {
  GetTaxExtensionByProfile?:  {
    __typename: "TaxExtension",
    CreatedAt: string,
    FilingStatus: TaxExtensionFilingStatus,
    FilingType: TaxExtensionFilingType,
    Form1040Nr: boolean,
    Id: string,
    MaritalFilingStatus: TaxExtensionMaritalFilingStatus,
    OutOfCountry: boolean,
    PoBoxAddress?: string | null,
    PrimaryEntity:  {
      __typename: "TaxFilingPrimaryEntity",
      City: string,
      Email: string,
      FirstName: string,
      LastName: string,
      Ssn: string,
      State: UsState,
      StreetAddress1: string,
      StreetAddress2?: string | null,
      Zipcode: string,
    },
    SpouseEntity?:  {
      __typename: "TaxFilingSpouseEntity",
      FirstName: string,
      LastName: string,
      Ssn: string,
    } | null,
    TaxProgramId?: string | null,
    TaxProgramStage: TaxProgramStage,
    TotalLiability: number,
    TotalPayments: number,
    UpdatedAt: string,
    Year: number,
  } | null,
};

export type GetTaxRiskReportQueryVariables = {
  TaxRiskReportId: string,
};

export type GetTaxRiskReportQuery = {
  GetTaxRiskReport?:  {
    __typename: "TaxRiskReport",
    Id: string,
    NoticesUpdates?:  Array< {
      __typename: "NoticesUpdate",
      Code?: string | null,
      Date: string,
      NoticeIssued?: string | null,
      NoticeDescription?: string | null,
      TaxYear: number,
    } | null > | null,
    ProfileId: string,
    RecentActivity?:  {
      __typename: "RecentActivity",
      Amount: number,
      DateReceived: string,
      TaxYear: number,
      Type: RecentActivityType,
    } | null,
    ReportDate: string,
    ReportType: ReportType,
    RiskIndicators?:  {
      __typename: "RiskIndicators",
      AuditReview: boolean,
      CollectionActivity: boolean,
      FederalCompliance: boolean,
      NoticeUpdate: boolean,
      OverallRisk: RiskIndicatorOverallRisk,
      OverallRiskDescription: string,
    } | null,
    TaxLiabilitiesByYear?:  Array< {
      __typename: "TaxLiability",
      CurrentBalance: number,
      EstCsed?: string | null,
      FilingStatus: ReturnFilingStatus,
      TaxYear: number,
    } | null > | null,
    TotalLiability?:  {
      __typename: "TotalLiability",
      Amount: number,
      TaxAgency: string,
      TaxType: TaxType,
    } | null,
  } | null,
};

export type GetTaxRiskReportsQueryVariables = {
  Limit?: number | null,
  NextToken?: string | null,
  ProfileId: string,
};

export type GetTaxRiskReportsQuery = {
  GetTaxRiskReports?:  {
    __typename: "TaxRiskReportConnection",
    NextToken?: string | null,
    TaxRiskReports:  Array< {
      __typename: "TaxRiskReportItem",
      Id: string,
      OverallRisk: RiskIndicatorOverallRisk,
      OverallRiskDescription: string,
      ReportDate: string,
    } | null >,
  } | null,
};

export type GetTermsAndConditionsQuery = {
  GetTermsAndConditions:  {
    __typename: "TermsAndConditions",
    Partner: string,
    Profile: string,
  },
};

export type IdentifiedNoticeSubscriptionVariables = {
  ProfileId: string,
};

export type IdentifiedNoticeSubscription = {
  IdentifiedNotice?:  {
    __typename: "NoticeAssessment",
    AmountDue?: number | null,
    Description?: string | null,
    DueDate?: string | null,
    Error?: string | null,
    Name?: string | null,
    OverDue?: boolean | null,
    ProfileId: string,
    RiskLevel?: NoticeAnalysisRiskLevel | null,
  } | null,
};
