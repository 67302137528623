import gql from "graphql-tag";

export const CreateTaxReturnBusinessEntity = gql`
  mutation CreateTaxReturnBusinessEntity(
    $ClientProfileId: ID!
    $TaxReturnId: String!
    $Year: String!
    $GrossIncome: Float!
    $ProfileId: String!
    $Name: String!
    $FirstYear: String!
    $TaxPrepPayrollTaxForms: [TaxPrepPayrollTaxFormsInput!]!
    $Form: IncomeTaxForms!
    $PartnersCount: Int
    $StateReturn: Boolean!
    $QuarterStateReturns: [QuarterStateReturnsInput]!
  ) {
    CreateTaxReturnBusinessEntity(ProfileId: $ClientProfileId,
      Input: {QuarterStateReturns: $QuarterStateReturns, 
              TaxPrepBusinessEntity: {FirstYear: $FirstYear, Name: $Name, ProfileId: $ProfileId}, 
              TaxPrepIncomeTaxForm: {Form: $Form, StateReturn: $StateReturn, PartnersCount: $PartnersCount}, 
              TaxPrepPayrollTaxForms: $TaxPrepPayrollTaxForms, 
              TaxReturnBusinessEntity: {GrossIncome: $GrossIncome, TaxReturnId: $TaxReturnId, Year: $Year}}
    ) {
      TaxPrepBusinessEntity {
        CreatedAt
        FirstYear
        Id
        Name
        ProfileId
        UpdatedAt
      }
      TaxPrepIncomeTaxForms {
        CreatedAt
        Form
        Id
        PartnersCount
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxPrepPayrollTaxForms {
        CreatedAt
        Form
        Id
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxReturnBusinessEntity {
        BusinessEntityId
        CreatedAt
        GrossIncome
        Id
        TaxReturnId
        UpdatedAt
        Year
      }
    }
  }
`;