import { useContext, useEffect } from "react";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import { US_STATES } from "../../../Common/States";
import '../requestcenter.css';
import { AdditionalQuestionsModel, MonthlyIncomeModel } from "./IncomeExpensesContext";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg';

const AdditionalQuestionsForm:React.FC<{inputData:AdditionalQuestionsModel, 
                                onChangeValue: (event:any) => void}> = (props) => {

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    const days = [];

    let startDay = 1;
    while (startDay <= 30) {
        if(startDay === 1){
            days.push(startDay + 'st');
        }else if(startDay === 2){
            days.push(startDay + 'nd');
        }else if (startDay === 3) {
            days.push(startDay + 'rd');
        }else {
            days.push(startDay + 'th');
        }
        startDay++;
    }
    
    return (
        <Form className="mt-2" id="clientInfoForm">
            <Row>
                <Col md={7} lg={7} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">How much can you afford per month on an installment agreement?</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.installmentAgreementAmount}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="installmentAgreementAmount"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={7} lg={7} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">When is your preferred resolution Payment Day?</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Select
                        name="paymentDay"
                        onChange={props.onChangeValue}
                        value={props.inputData.paymentDay}
                    >
                        <option value="0">- </option>
                        {days.map((day) => {
                        return <option value={day}>{day}</option>;
                        })}
                    </Form.Select>
                </Col>
            </Row>
            <hr className="mt-2"/>
        </Form>
    )
}

export default AdditionalQuestionsForm;