import { useContext, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import { US_STATES } from "../../../Common/States";
import '../requestcenter.css';
import { MonthlyExpensesModel, MonthlyIncomeModel } from "./IncomeExpensesContext";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg';

const MonthlyExpensesForm:React.FC<{inputData:MonthlyExpensesModel, 
                                onChangeValue: (event:any) => void}> = (props) => {

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    const [showVehicleForm, setShowVehicleForm] = useState(true);
    const [isMortgage, setIsMortgage] = useState(props.inputData.mortgage === 'Yes'?true: false);

    let years = [];

  let currentYear = new Date().getFullYear();

  let startYear = currentYear - 100;
  startYear = startYear || 1900;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
    
    return (
        <Form className="mt-2" id="clientInfoForm">
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Do you have a mortgage?'}</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name="mortgage-Yes"
                      onChange={(event) => (setIsMortgage(true),props.onChangeValue(event))}
                      checked={props.inputData.mortgage === "Yes" ? true : false}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name="mortgage-No"
                      onChange={(event) => (setIsMortgage(false), props.onChangeValue(event))}
                      checked={props.inputData.mortgage === "No" ? true : false}
                    />
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Mortgage (if no, then Rent)'}</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.rent}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="rent"
                    type="text"
                    disabled={isMortgage}
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Utilities (Gas, Electricity, Water, Fuel, Oil, Trash Collection, etc.)'}</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.utilities}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="utilities"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Services (TV/Internet/Cellphone)'}</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.services}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="services"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">What is the Total Estimated Values of your Assets?</Form.Label>
                </Col>
                <Col md={2} lg={2}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.assetsMonthlyPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="assetsMonthlyPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={2} lg={2}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Other</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.assetsOtherPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="assetsOtherPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Do you own one or more Vehicles?'}</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name="moreVehicles-Yes"
                      onChange={(event) => (setShowVehicleForm(true),props.onChangeValue(event))}
                      checked={props.inputData.moreVehicles === "Yes" ? true : false}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name="moreVehicles-No"
                      onChange={(event) => (setShowVehicleForm(false),props.onChangeValue(event))}
                      checked={props.inputData.moreVehicles === "No" ? true : false}
                    />
                </Col>
            </Row>
            {showVehicleForm && 
            <>
                <hr className="mt-2"/>
                <Row>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Year'}</Form.Label>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Make'}</Form.Label>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Model'}</Form.Label>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Ownership'}</Form.Label>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Monthly Payment'}</Form.Label>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Label className="fs-8 fw-bold">{'Remaining Balance'}</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Select
                            name="Yeat"
                            onChange={() => {}}
                            value={props.inputData.vehicleExpenses[0]?.year}
                        >
                            <option value="0">- </option>
                            {years.map((year) => {
                            return <option value={year}>{year}</option>;
                            })}
                        </Form.Select>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.make}
                        onChange={() => {}}
                        name="make"
                        type="text"
                        />
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.model}
                        onChange={() => {}}
                        name="model"
                        type="text"
                        />
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Select
                        value={props.inputData.vehicleExpenses[0]?.ownership}
                        onChange={() => {}}
                        name="ownership"
                        >
                            <option value="">Select One</option>
                            <option value="Ownership1">Ownership 1</option>
                            <option value="Ownership2">Ownership 2</option>
                            <option value="Ownership3">Ownership 3</option>
                            <option value="Ownership4">Ownership 4</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.monthlyPayment}
                        onChange={() => {}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="monthlyPayment"
                        type="text"
                        />
                        </InputGroup>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.remainingBalance}
                        onChange={(event) => props.onChangeValue(event)}
                        onKeyDown={(event) => validateAmount(event)}
                        name="remainingBalance"
                        type="text"
                        />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Select
                            name="Year"
                            onChange={() => {}}
                            value={props.inputData.vehicleExpenses[0]?.year}
                        >
                            <option value="0">- </option>
                            {years.map((year) => {
                            return <option value={year}>{year}</option>;
                            })}
                        </Form.Select>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.make}
                        onChange={() => {}}
                        name="make"
                        type="text"
                        />
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.model}
                        onChange={() => {}}
                        name="model"
                        type="text"
                        />
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <Form.Select
                        value={props.inputData.vehicleExpenses[0]?.ownership}
                        onChange={() => {}}
                        name="ownership"
                        >
                            <option value="">Select One</option>
                            <option value="Ownership1">Ownership 1</option>
                            <option value="Ownership2">Ownership 2</option>
                            <option value="Ownership3">Ownership 3</option>
                            <option value="Ownership4">Ownership 4</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.monthlyPayment}
                        onChange={() => {}}
                        onKeyDown={(event) => validateAmount(event)}
                        name="monthlyPayment"
                        type="text"
                        />
                        </InputGroup>
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center">
                        <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                        value={props.inputData.vehicleExpenses[0]?.remainingBalance}
                        onChange={(event) => props.onChangeValue(event)}
                        onKeyDown={(event) => validateAmount(event)}
                        name="remainingBalance"
                        type="text"
                        />
                        </InputGroup>
                    </Col>
                </Row>
            </>
            }
            <hr className="mt-2"/>
        </Form>
    )
}

export default MonthlyExpensesForm;