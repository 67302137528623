import { useContext, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { US_STATES } from "../../Common/States";
import { ProfileInformation } from "./TaxPreparationServiceFormContext";
import { clientConfig } from "../../awsClientConfig";
import Message from "../Message";

const ProfileInformationForm:React.FC<{
  inputData:any,
  clientError?: {
    validZipCode: boolean,
    validPhone: boolean,
    validEmail: boolean
  },
  spouseError?: {
    validZipCode: boolean,
    validPhone: boolean,
    validEmail: boolean
  },
  onChangeValue: (event:any) => void, type: string,
  onPhoneChange: (name: string, value: string, type: string) => void,
  onSameAddressCheck?: (checked: boolean) => void,
  onEmailCheck:(email: string) => void,
  paymentCompleted: boolean | undefined}> = (props) => {

    const [sameAddress, setSameAddress] = useState(false);

    function formatPhoneNumber(e: any) {
      const {name, value} = e.target;
      let phoneNumber = value.replace(/[^\d]/g, "");

      if (phoneNumber.length < 4) {
        props.onPhoneChange(name, phoneNumber, props.type);
        return;
      }

      if (phoneNumber.length < 7) {
        phoneNumber =  `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
        props.onPhoneChange(name, phoneNumber, props.type);
        return;
      }

      phoneNumber =  `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`,
      props.onPhoneChange(name, phoneNumber, props.type);
      return;
    }

    const onSameAddressHandler = (event:any) => {
      const {name, checked} = event.target;
      setSameAddress(checked);
      if(props.onSameAddressCheck){
        props.onSameAddressCheck(checked);
      }
    }

    const onBlurEvent = (event:any) => {
      if(props.type === 'spouse') {
        const client = new clientConfig();
        const targetValue = event.target.value;
        props.onEmailCheck(targetValue);
      }
    }

    return (
            <>
            <Row>
                <Col md={5}>
                    <Form.Label>First Name<span className="color-red">*</span></Form.Label>
                    <Form.Control
                    required
                    value={props.inputData?.FirstName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="FirstName"
                    disabled={props.paymentCompleted}
                    id={`${props.type}_FirstName`}
                    type="text"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide First Name.
                      </Form.Control.Feedback>
                </Col>
                <Col md={2}>
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                    value={props.inputData?.MiddleName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="MiddleName"
                    type="text"
                    disabled={props.paymentCompleted}
                    />
                </Col>
                <Col md={5}>
                    <Form.Label>Last Name<span className="color-red">*</span></Form.Label>
                    <Form.Control
                    required
                    value={props.inputData?.LastName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="LastName"
                    id={`${props.type}_LastName`}
                    type="text"
                    disabled={props.paymentCompleted}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Last Name.
                    </Form.Control.Feedback>
                </Col>  
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Label>Email<span className="color-red">*</span></Form.Label>
                    <Form.Control
                    required
                    value={props.inputData?.Email}
                    onChange={(event) => props.onChangeValue(event)}
                    onBlur={onBlurEvent}
                    disabled={props.type === 'client'?true:props.paymentCompleted}
                    name="Email" 
                    id={`${props.type}_Email`}
                    type="email"
                    isInvalid={props.type === 'client'?!props.clientError?.validEmail:!props.spouseError?.validEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Email address.
                    </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                    <Form.Label>Phone<span className="color-red">*</span></Form.Label>
                    <Form.Control
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            formatPhoneNumber(e);
                          }}
                          name="Phone"
                          required
                          id={`${props.type}_Phone`}
                          value={props.inputData?.Phone}
                          disabled={props.paymentCompleted}
                          isInvalid={props.type === 'client'?!props.clientError?.validPhone:!props.spouseError?.validPhone}
                    />
                 <Form.Control.Feedback type="invalid">
                  Please provide a valid Phone number.
                </Form.Control.Feedback>
                </Col>  
            </Row>
            <Row className="my-4"> 
                <Col md={'auto'}>
                    <Form.Label>Please confirm that your address matches the one on your most recent tax return or IRS notice.</Form.Label>
                </Col>
            </Row>
            {props.type === 'spouse' && 
              <Row className="mb-2"> 
                  <Col md={12}>
                      <Form.Group as={Col}>
                        <Form.Check
                          type="checkbox"
                          label="Same as Primary Account Holder's Address"
                          checked={sameAddress}
                          name="sameAddress"
                          disabled={props.paymentCompleted}
                          onChange={onSameAddressHandler}
                        />
                      </Form.Group>
                  </Col>
              </Row>
            }
            <Row className="mb-3">
            <Col md={12}>
              <Form.Group as={Col}>
                <Form.Label>Street Address<span className="color-red">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={props.inputData?.StreetAddress1}
                  name="StreetAddress1"
                  required
                  id={`${props.type}_StreetAddress1`}
                  disabled={props.paymentCompleted}
                  onChange={(event) => props.onChangeValue(event)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Street Address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Street Address 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={props.inputData?.StreetAddress2}
                  name="StreetAddress2"
                  disabled={props.paymentCompleted}
                  onChange={(event) => props.onChangeValue(event)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={5}>
              <Form.Group as={Col}>
                <Form.Label>City<span className="color-red">*</span> </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={props.inputData?.City}
                  name="City"
                  required
                  id={`${props.type}_City`}
                  disabled={props.paymentCompleted}
                  onChange={(event) => props.onChangeValue(event)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid City.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group as={Col}>
                <Form.Label>State<span className="color-red">*</span></Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={props.inputData?.State}
                  name="State"
                  required
                  id={`${props.type}_State`}
                  disabled={props.paymentCompleted}
                  onChange={(event) => props.onChangeValue(event)}
                >
                  <option value="">- </option>
                  {US_STATES.map((state) => {
                    return (
                      <option value={state.abbreviation}>
                        {state.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid State.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group as={Col}>
                <Form.Label>ZIP Code<span className="color-red">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={props.inputData?.ZipCode}
                  name="ZipCode"
                  required
                  id={`${props.type}_ZipCode`}
                  disabled={props.paymentCompleted}
                  onChange={(event) => props.onChangeValue(event)}
                  isInvalid={props.type === 'client'?!props.clientError?.validZipCode:!props.spouseError?.validZipCode}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid ZIP Code.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {props.type === 'spouse' && 
          <Row className="mb-1">
            <Col md={12}>
                    <Form.Label className="color-primary">*Please note: An email will be sent to the provided co-client's address to finalize the enrollment process.</Form.Label>
            </Col>
          </Row>
          }
          </>
    )
}

export default ProfileInformationForm;