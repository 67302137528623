import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import Alert from "react-bootstrap/Alert";
import { formatZipcode } from "../../functions/misc";
import { useLocation, useNavigate } from "react-router-dom";
import { US_STATES } from "../../Common/States";
import { GetPaymentMethod } from "../../queries/GetPaymentMethod";
import { clientConfig } from "../../awsClientConfig";
import { UpdatePaymentMethod } from "../../queries/UpdatePaymentMethod";
import moment from "moment";
import Loading from "../../Common/Loading";

function ChangeBillingAddress() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [validated, setValidated] = useState(false);
  const [saved, setSaved] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [updated, setUpdated] = useState("");
  const [loading, setLoading] = useState(true);
  console.log(state.paymentId);
  const client = new clientConfig();
  useEffect(() => {
    getPaymentInfo();
  }, []);
  const getPaymentInfo = () => {
    client
      .query({
        query: GetPaymentMethod,
        variables: { PaymentMethodId: state.paymentId },
      })
      .then((res: any) => {
        if (res.data.GetPaymentMethod !== null) {
          setCardInfo(res.data.GetPaymentMethod);
          setAddress({
            streetAddress1: res.data.GetPaymentMethod.StreetAddress1,
            streetAddress2: res.data.GetPaymentMethod.StreetAddress2,
            state: res.data.GetPaymentMethod.State,
            city: res.data.GetPaymentMethod.City,
            zipCode: res.data.GetPaymentMethod.ZipCode,
          });
          setUpdated(
            moment(res.data.GetPaymentMethod.UpdatedAt).format(
              "M/D/YYYY hh:mm a"
            )
          );
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitBillingAddress();
    }

    setValidated(true);
  };

  const submitBillingAddress = () => {
    const variables = {
      ...cardInfo,
      State: address.state,
      StreetAddress1: address.streetAddress1,
      StreetAddress2: address.streetAddress2 ? address.streetAddress2 : null,
      City: address.city,
      ZipCode: address.zipCode,
    };
    client
      .mutate({ mutation: UpdatePaymentMethod, variables: variables })
      .then((res: any) => {
        if (res.data.UpdatePaymentMethod) {
          setSaved(true);
          setTimeout(() => {
            navigate("/account-settings-payment");
          }, 5000);
        }
      })
      .catch((error: any) => {});
  };
  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings")}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate("/account-settings-payment")}
              >
                Payment Information
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Change Billing Address</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3">
              <Col lg={8}>
                <h4 className="text-main-dark ff-Montserrat fw-bold">
                  Change Billing Address
                </h4>
              </Col>
              {saved && (
                <Col lg={4} className="text-center">
                  <Alert variant="success">
                    <FaCheckCircle className="text-success me-2 fs-6" />
                    Your changes have been saved.
                  </Alert>
                </Col>
              )}
            </Row>
            {loading ? (
              <Loading />
            ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group as={Col} controlId="validationCustom01">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress1}
                        name="streetAddress1"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Street Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress2}
                        name="streetAddress2"
                        onChange={(e) => setAddressData(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom02">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.city}
                        name="city"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group as={Col} controlId="validationCustom03">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={address.state}
                        name="state"
                        onChange={(e) => setAddressData(e)}
                        required
                      >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom04">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.zipCode}
                        name="zipCode"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid ZIP Code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="d-flex flex-column justify-content-center mt-5 align-items-center">
                      <button
                        type="submit"
                        className="btn-continue"
                        style={{ width: "25vw" }}
                      >
                        Save New Billing Address
                      </button>
                      <p className="mt-5">
                        Last change made to your account was on{" "}
                        <strong>{updated} PST. </strong>
                        Changes made here will not affect your signed documents.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChangeBillingAddress;
