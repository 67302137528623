import gql from "graphql-tag";

export const GetTaxRiskReports = gql`
  query GetTaxRiskReports($NextToken: String, $ProfileId: ID!) {
    GetTaxRiskReports(ProfileId: $ProfileId, Limit: 12, NextToken: $NextToken) {
      NextToken
      TaxRiskReports {
        Id
        OverallRisk
        OverallRiskDescription
        ReportDate
      }
    }
  }
`;
