import gql from 'graphql-tag';

export const createEnrollmentAction = gql`
  mutation (
    $EnrollmentId: ID!, 
    $Name: EnrollmentActionName!,
    $EnrollmentProfiles: [EnrollmentProfileInput!]
  ) {
    CreateEnrollmentAction(Input: {EnrollmentId: $EnrollmentId, Name: $Name, EnrollmentProfiles: $EnrollmentProfiles}) {
      Enrollment {
        Id
        NextEnrollmentAction
        NextForm {
          Id
          S3Bucket
          S3Key
          S3VersionId
          Status
        }
      }
    }
  }
`;