import { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TaxReportForm from "../taxReport/TaxFormContext";
import moment from "moment";
import AppProgressBar from "../../Common/progressBar";
import { US_STATES } from "../../Common/States";
import { clientConfig } from "../../awsClientConfig";
import { GetProfile } from "../../queries/getProfile";
import { GetProfilePrimaryAddress } from "../../queries/GetProfilePrimaryAddress";
import { UpdateProfile } from "../../queries/updateProfile";
import { UpsertProfilePrimaryAddress } from "../../queries/UpsertProfilePrimaryAddress";
import { createEnrollmentAction } from "../../queries/CreateEnrollmentAction";
import { hideFormatSSN, formatZipcode } from "../../functions/misc";
import { userProfileData } from "../../Common/profileInfo";
import { useNavigate } from "react-router-dom";
import Message from "../Message";
import Loading from "../../Common/Loading";
import momentTz from "moment-timezone";

function PersonalInformation({ progressPercent = 0 }) {
  const { next, enrollmentId, setNextFormId, setS3Bucket, setS3Key } =
    useContext(TaxReportForm);
  const navigate = useNavigate();
  const [DOB, setDOB] = useState({ date: "", month: 0, year: 0 });
  const [socialSecurity, SetSocialSecurity] = useState("");
  const [hideSocialSecurity, SetHideSocialSecurity] = useState("");
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [personInfo, setPersonInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState({});
  const [error, setError] = useState({
    fullDOB: "",
    date: "",
    month: "",
    year: "",
    ssn: "",
    streetAddress1: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const client = new clientConfig();
  const months = moment.months();
  const currentYear = new Date().getFullYear();

  let years = [];

  const momentTz = require('moment-timezone');
  const pstDate = momentTz().tz("America/Los_Angeles");
  const pstCurrentDate = pstDate.year(pstDate.year() - 18);

  let startYear = currentYear - 100;
  startYear = startYear || 1900;
  while (startYear <= currentYear - 18) {
    years.push(startYear++);
  }

  const getmonth = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  useEffect(() => {
    getProfile();
    getProfilePrimaryAddress();
  }, []);
  const setDateOfBirth = (e: any) => {
    setDOB((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "date"
            ? e.target.value
            : e.target.value.replace(/[^\d*]/g, ""),
      };
    });
    let _error: any = error;
    _error[e.target.name] = "";
    _error["fullDOB"] = "";
  };

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
    let _error: any = error;
    _error[e.target.name] = "";
  };

  const getProfile = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setPersonInfo(res.data.GetProfile);
          if (res.data.GetProfile.Birthdate !== null) {
            const birthDate1 = new Date(res.data.GetProfile.Birthdate);
            const birthDate = new Date(
              birthDate1.valueOf() + birthDate1.getTimezoneOffset() * 60 * 1000
            );
            setDOB({
              month: birthDate.getMonth() + 1,
              date: birthDate.getDate().toString(),
              year: birthDate.getFullYear(),
            });
            SetSocialSecurity(hideFormatSSN(res.data.GetProfile.Ssn));
            SetHideSocialSecurity(res.data.GetProfile.Ssn || "");
          }
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddressInfo(res.data.GetProfilePrimaryAddress);
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const makeSocialSecurity = (e: any) => {
    
    let temp = hideSocialSecurity.replace(
      hideSocialSecurity.substring(0, 5),
      "*".repeat(hideSocialSecurity.substring(0, 5).length)
    );
    var currentValue = e.target.value.replace(/[^\d*]/g, "");
    var finalValue = "";
    if (hideSocialSecurity.length < currentValue.length) {
      finalValue = hideSocialSecurity
        .concat(currentValue.replace(temp, ""))
        .replace(/[^\d]/g, "");
    } else {
      finalValue = hideSocialSecurity.substring(0, currentValue.length);
    }
    if(finalValue.length===10){
      finalValue= finalValue.substring(0,9)
    }
    SetHideSocialSecurity(finalValue);
    SetSocialSecurity(hideFormatSSN(finalValue));
    let _error: any = error;
    _error[e.target.name] = "";
  };

  const submitInfo = async () => {
    setShowSpinner(true);
    if (
      DOB.date === "" ||
      DOB.month === 0 ||
      DOB.year === 0 ||
      hideSocialSecurity === "" ||
      hideSocialSecurity.length !== 9 ||
      address.streetAddress1 === "" ||
      address.city === "" ||
      address.streetAddress1.length < 2 ||
      address.city.length < 2 ||
      address.state === "" ||
      address.zipCode === ""
    ) {
      let _error = error;
      if (DOB.date === "") _error["date"] = "Date is mandatory";
      if (DOB.month === 0) _error["month"] = "Month is mandatory";
      if (DOB.year === 0) _error["year"] = "Year is mandatory";
      if (hideSocialSecurity.length !== 9)
        _error["ssn"] = "SSN requires 9 Characters";
      if (hideSocialSecurity === "") _error["ssn"] = "SSN is mandatory";
      if (address.streetAddress1 === "")
        _error["streetAddress1"] = "Street Address is mandatory";
      if (address.city === "") _error["city"] = "City is mandatory";
      if (address.streetAddress1.length < 2)
        _error["streetAddress1"] = "Street Address should be minimum 2 letters";
      if (address.city.length < 2) _error["city"] = "City be minimum 2 letters";
      if (address.state === "") _error["state"] = "State is mandatory";
      if (address.zipCode === "") _error["zipCode"] = "ZIP Code is mandatory";

      setError(_error);
      setError({ ...error });
      setShowSpinner(false);
      return;
    }

    let Birthdate =
      DOB.date.toString().length === 1 ? `0${DOB.date}` : DOB.date;
    let BirthMonth = getmonth(Number(DOB.month));
    let fullDob = `${DOB.year}-${BirthMonth}-${Birthdate}`;
    let date = moment(`${DOB.year}-${BirthMonth}-${Birthdate}`);

    if (date.format("YYYY-MM-DD") > pstCurrentDate.format("YYYY-MM-DD")) {
      let _error = error;
      _error["fullDOB"] = "You must be 18 years of age or older.";
      setError(_error);
      setError({ ...error });
      setShowSpinner(false);
      return;
    }

    if (date.isValid()) {
      const profileId = await userProfileData();
      const variables = {
        ...personInfo,
        Id: profileId,
        Birthdate: fullDob,
        Ssn: hideSocialSecurity.replaceAll("-", ""),
      };
      client
        .mutate({ mutation: UpdateProfile, variables: variables })
        .then((res: any) => {
          if (res.data.UpdateProfile) {
            submitAddress();
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
        });
    } else {
      let _error = error;
      _error["date"] = "Enter Date of Birth Correctly";
      setError(_error);
      setError({ ...error });

      setShowSpinner(false);
      return;
    }
  };

  const submitAddress = async () => {
    const profileId = await userProfileData();
    const variables = {
      ...addressInfo,
      ProfileId: profileId,
      State: address.state,
      City: address.city,
      StreetAddress1: address.streetAddress1,
      ZipCode: address.zipCode,
      StreetAddress2: address.streetAddress2 ? address.streetAddress2 : null,
    };
    client
      .mutate({ mutation: UpsertProfilePrimaryAddress, variables: variables })
      .then((res: any) => {
        if (res.data.UpsertProfilePrimaryAddress) {
          createEnrollment();
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
      });
  };

  const createEnrollment = () => {
    const variables = {
      EnrollmentId: enrollmentId,
      Name: "InformationCompleted",
    };

    client
      .mutate({ mutation: createEnrollmentAction, variables: variables })
      .then((res: any) => {
        if (res.data.CreateEnrollmentAction) {
          setNextFormId(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.Id
          );
          setS3Key(res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Key);
          setS3Bucket(
            res.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Bucket
          );
          setShowSpinner(false);
          next();
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
      });
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            {showSpinner ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading">Personal Information </h1>
                <p className="content-text">
                  We are committed to providing a safe and secure environment.
                  We safeguard your info by encrypting it while we store it in
                  our system. We use SSL encryption when sending your tax
                  returns to the IRS or state agency.
                </p>
                <Form>
                  <Row className="mb-3 justify-content-between">
                    <Col md={12}>
                      <Row>
                        <Col sm={12} md={7}>
                        {error.fullDOB && (
                            <Message type="error" message={error.fullDOB}></Message>
                        )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Month of Birth</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="month"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.month}
                        >
                          <option value="0">-</option>
                          {months.map((month, index) => {
                            return (
                              <option value={Number(index) + 1}>{month}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error.month && (
                        <Message type="error" message={error.month}></Message>
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Day Of Birth</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          maxLength={2}
                          name="date"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.date}
                        />
                      </Form.Group>
                      {error.date && (
                        <Message type="error" message={error.date}></Message>
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Year of Birth</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="year"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.year}
                        >
                          <option value="0">- </option>
                          {years.map((year) => {
                            return <option value={year}>{year}</option>;
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error.year && (
                        <Message type="error" message={error.year}></Message>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>Social Security Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={socialSecurity}
                          name="ssn"
                          onChange={(e) => {
                            //SetSocialSecurity(formatSSN(e));
                            makeSocialSecurity(e);
                          }}
                        />
                      </Form.Group>
                      {error.ssn && (
                        <Message type="error" message={error.ssn}></Message>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} className="mb-3">
                      <label>
                        Please confirm that your address matches the one on your
                        most recent tax return or IRS notice.
                      </label>
                      <Form.Group controlId="">
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.streetAddress1}
                          name="streetAddress1"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                      {error.streetAddress1 && (
                        <Message
                          type="error"
                          message={error.streetAddress1}
                        ></Message>
                      )}
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="">
                        <Form.Label>Street Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.streetAddress2}
                          name="streetAddress2"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={5}>
                      <Form.Group controlId="">
                        <Form.Label>City </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.city}
                          name="city"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                      {error.city && (
                        <Message type="error" message={error.city}></Message>
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={address.state}
                          name="state"
                          onChange={(e) => setAddressData(e)}
                        >
                          <option value="">- </option>
                          {US_STATES.map((state) => {
                            return (
                              <option value={state.abbreviation}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error.state && (
                        <Message type="error" message={error.state}></Message>
                      )}
                    </Col>
                    <Col md={5}>
                      <Form.Group controlId="">
                        <Form.Label>ZIP Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.zipCode}
                          name="zipCode"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                      {error.zipCode && (
                        <Message type="error" message={error.zipCode}></Message>
                      )}
                    </Col>
                  </Row>
                </Form>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={() => navigate("/dashboard")}
                  >
                    Lets do this later...
                  </span>
                  <button
                    disabled={showSpinner}
                    onClick={submitInfo}
                    className="btn-continue"
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PersonalInformation;
