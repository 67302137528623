import gql from "graphql-tag";

export const CreateProfileForm = gql`
  mutation CreateProfileForm(
    $ProfileId: ID!
    $FormName: FormName!
    $Owner: Boolean!
    $EnrollmentId: ID
  ) {
    CreateProfileForm(
      Input: {
        FormName: $FormName
        ProfileForms: { Owner: $Owner, ProfileId: $ProfileId }
        ProfileId: $ProfileId
      }
        EnrollmentId: $EnrollmentId
    ) {
      Active
      CompletedAt
      CreatedAt
      Id
      Name
      
      ProfileForms {
        
        FormId
        Id 
        Owner
        ProfileId
        SignatureS3Bucket
        SignatureS3Key
        SignatureS3VersionId
      }
      S3Bucket
      S3Key
      S3VersionId
      Status
      TemplateId
      UpdatedAt
    }
  }
`;
