import gql from "graphql-tag";

export const GetTaxReturnIncome = gql`
  query GetTaxReturnIncome($ProfileId: ID!, $EnrollmentId: ID!) {
    GetTaxReturnIncome(ProfileId: $ProfileId, EnrollmentId: $EnrollmentId) {
      TaxPrepIncome {
        AnnualGrossReceipts
        CreatedAt
        HasCapitalGainsOrLosses
        HasPartnershipIncomeOrLosses
        HasRentalIncomeOrLosses
        HasSelfEmploymentIncome
        Id
        NeedStateReturn
        OperatedFarm
        ProfileId
        QuoteId
        SoldRealEstate
        ReceivedRoyalties
        RentalsCount
        UpdatedAt
      }
      TaxPrepStateReturn {
        CreatedAt
        IncomeId
        State
        UpdatedAt
        Id
      }
    }
  }
`;
