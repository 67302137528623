import gql from "graphql-tag";

export const GetProfile = gql`
  query GetProfile($profileId: ID!) {
    GetProfile(ProfileId: $profileId) {
      AccountId
      Active
      Birthdate
      CallEnabled
      ConsentToShare
      CreatedAt
      Email
      EnrollmentSource
      FirstName
      Id
      Languages
      LastName
      MiddleName
      ParentId
      PartnerId
      Phone
      PrimaryCognitoUserId
      PrimaryUserPoolId
      SecondaryCognitoUserId
      SecondaryUserPoolId
      SelfEnrolled
      SmsEnabled
      Ssn
      UpdatedAt
      CafStatus
      CafStatusUpdatedAt
    }
  }
`;
