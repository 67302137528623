import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./navbar.css";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/OTR_optima_blue_solid.svg";
import Container from 'react-bootstrap/Container';
import TaxPrepTooltip from "../components/DashBoards/GlobalEnrollment/TaxPrepTooltip";
import { GetEnrollmentsByProfile } from "../queries/GetEnrollmentsByProfile";
import { clientConfig } from "../awsClientConfig";
import { userProfileData } from "../Common/profileInfo";
import { getCurrentTaxYear } from "../functions/misc";
import useEnrollmentStore, { EnrollmentStatus } from "../store/EnrollmentStore";
import { ProtectionPlanName } from "../API";
import useProfileStore, { useGroupStore } from "../store/ProfileStore";

type Props = {
  tpoIframeUrl: string | null;
  setTpoIframeUrl: Dispatch<SetStateAction<string>>;
}

function NavBar({tpoIframeUrl, setTpoIframeUrl}: Props) {
  const [taxPrepEnrollmentCompleted, setTaxPrepEnrollmentCompleted] = useState<boolean>(false);
  const { taxPrepEnrollment, setTaxPrepEnrollment, setProtectionPlanEnrollment } = useEnrollmentStore();
  const { clearPrimaryProfile, clearSpouseProfile, clearPartnerProfile } = useProfileStore();
  const { clearPrimaryGroup } = useGroupStore();
  const navigate = useNavigate();
  let { state } = useLocation();

  const fetchTooltipData = async () => {
    const client = new clientConfig();
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();

    client
      .query({
        query: GetEnrollmentsByProfile,
        variables: { ProfileId: profileId },
      })
      .then((res: any) => {
        const enrollments = res.data.GetEnrollmentsByProfile?.Enrollments;
        if (enrollments) {

          const protectionPlanEnrollment = enrollments.filter((enrollment: any) => enrollment?.Type === 'ProtectionPlan').pop();

          if (protectionPlanEnrollment && protectionPlanEnrollment?.Status === EnrollmentStatus.Initiated || protectionPlanEnrollment?.Status === EnrollmentStatus.Completed) {
            setProtectionPlanEnrollment({
              subscriptionPlan: protectionPlanEnrollment?.ProtectionPlanName,
              subscriptionStatus: "",
              enrollmentStatus: protectionPlanEnrollment?.Status,
            })
          }

          const taxPrepNetworkEnrollment = enrollments.filter((enrollment: any) => enrollment?.Type === 'TaxPrepNetwork').pop();
          if (taxPrepNetworkEnrollment?.Status === "Completed") {
            setTaxPrepEnrollment({
              taxPrepEnrollmentCompleted: true,
              showTaxPrepNextStepsModal: false,
              paymentCompleted: true
            });
          }
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      })
  }

  useEffect(() => {
    fetchTooltipData();
  }, []);

  const onLogout = () => {
    if (tpoIframeUrl) {
      setTpoIframeUrl(`${process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL}/login`)
    }
    Auth.signOut().then((res) => {
      useEnrollmentStore.setState({
        protectionPlanEnrollment: null,
        taxPrepEnrollment: null,
      });
      clearPrimaryGroup();
      clearPrimaryProfile();
      clearSpouseProfile();
      clearPartnerProfile();
      navigate("/login");
    });
  };

  return (
    <nav className="mx-lg-5 mx-md-3 mx-sm-10">
      <div>
        <img
          src={logo}
          alt="app-logo"
          className="logo"
        />
      </div>

      <Container className='d-flex justify-content-end mx-lg-10 col-lg-10 col-md-7 mx-sm-1'>
        <button onClick={onLogout} className="btn-logout align-self-end">
          Logout
        </button>
        {taxPrepEnrollment?.taxPrepEnrollmentCompleted ? (
          <TaxPrepTooltip text={`We are currently working on your ${getCurrentTaxYear()} Tax Return`}  />
        ) : (
          <div style={{ visibility: 'hidden' }}></div>
        )}
      </Container>
    </nav>
  );
}

export default NavBar;
