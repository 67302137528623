import React from "react";
import "../../pages/navbar.css";
import protectionPlan from "../../assets/protection-plan.svg";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motionContainer } from "./index";
import { AnimatePresence, motion } from "framer-motion";

function EnrolledProtectionPlan() {
  const navigate = useNavigate();

  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <motion.div key="content" {...motionContainer}>
            <Row className="px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="content-box-heading mb-3">
                  Congratulations! You're Enrolled.
                </h1>
                <p className="content-text">
                  You're now enrolled in the Optima Protection Plan. Head over to your
                  dashboard to check out your plan and other tax tools like our IRS Notice Analyser.
                </p>

                <div className="d-flex justify-content-start mt-5 align-items-center">
                  <button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="btn-xl px-1"
                  >
                    Continue to Dashboard
                  </button>
                </div>
              </Col>
              <Col lg={5}>
                <img
                  src={protectionPlan}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </Col>
            </Row>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </AnimatePresence>
  );
}

export default EnrolledProtectionPlan;
