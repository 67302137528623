import { useState, useEffect } from "react";
import { Navigate, useRoutes, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import VerifyCodePage from "../pages/VerifyCodePage";
import ForgotPage from "../pages/ForgotPage";
import ProfilePartners from "../pages/profilePartners";
import ProfileClient from "../pages/profileClient";
import CommonLayout from "../Common/CommonLayout";
import TaxReportPage from "../pages/TaxReportPage";
import PartnerEnrollment from "../pages/PartnerEnrollment";
import ReferalClient from "../pages/RefaralClient";
import Explanation from "../pages/explanation";
import NoticeAnalyzer from "../pages/noticeAnalyzer";
import TaxExtension from "../pages/TaxExtensionPage";
import TaxExtensionDeadline from "../pages/taxEntensionDeadline";
import OrganicClient from "../pages/OrganicClientPage";
import TaxRiskReport from "../pages/TaxRiskReport";
import ClientAppointment from "../pages/Appointment";
import ScheduleAppointmentPage from "../pages/ScheduleAppointement";
import DashBoardPage from "../pages/Dashboard";
import PartnerDashboardPage from "../pages/PartnerDashboardPage";
import PartnerReferalsPage from "../pages/PartnerReferalsPage";
import ClientAccountSettingsPage from "../pages/ClientAccountSettingsPage";
import CreditCardsOnFile from "../components/ClientAccountSetting/credirCradsOnFile";
import ChangeBillingAddress from "../components/ClientAccountSetting/changeBillingAddress";
import ClientDocuments from "../components/ClientAccountSetting/clientDocuments";
import ContactUs from "../components/ClientAccountSetting/contactUs";
import CancelSubscription from "../components/ClientAccountSetting/cancelSubscription";
import EditProfile from "../components/ClientAccountSetting/editProfile";
import Notifications from "../components/ClientAccountSetting/notifications";
import PartnerAccountSetting from "../components/PartnerAccountSetting/PartnerAccountSetting";
import LicenceAndInsurance from "../components/PartnerAccountSetting/licenceAndInsurance";
import PartnerContactUs from "../components/PartnerAccountSetting/PartnerContactUs";
import PartnerDeleteAccount from "../components/PartnerAccountSetting/PartnerDeleteAccount";
import PartnerDocuments from "../components/PartnerAccountSetting/PartnerDocuments";
import PartnerNotifications from "../components/PartnerAccountSetting/PartnerNotifications";
import PasswordReset from "../components/PartnerAccountSetting/passwordReset";
import ClientPasswordReset from "../components/ClientAccountSetting/ClientPasswordReset";
import TermsConditions from "../components/PartnerAccountSetting/TermsConditions";
import PartnerProfile from "../components/PartnerAccountSetting/Profile";
import ClientTermsConditions from "../components/ClientAccountSetting/ClientTermsConditions";
import AddNewPaymentMethod from "../components/ClientAccountSetting/addNewPaymentMethod";
import LoginLayout from "../Common/LoginLayout";
import NewReportForm from "../components/ClientAccountSetting/SubmitNewform";
import TaxRiskReportPartner from "../pages/TaxRiskReportPartner";
import ResetPassword from "../pages/ResetPasswordPage";
import PartnerURLQRSharing from "../components/PartnerAccountSetting/PartnerURLQRSharing";
import SetPassword from "../pages/SetPassword";
import ProtectionPlanEnroll from "../components/DashBoards/ProtectionPlan/protectionPlanEnroll";
import RequestCenter from "../pages/RequestCenterPage";
import TaxOrganizerPage from "../pages/TaxOrganizerPage";
import IncomeExpensePage from "../pages/IncomeExpensePage";
import MaintenancePage from "../pages/MaintenancePage";
import TaxPreparationServiceEnroll from "../components/TaxPreparationService/TaxPreparationServiceEnroll";
import TaxPreparationServiceForm from "../components/TaxPreparationService/TaxPreparationServiceForm";
import SpouseTaxPrepPage from "../pages/SpouseTaxPrepPage";

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const checkUserTokens = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        if (isAuthenticated && location.pathname !== "/login" && location.pathname !== "/signup"
          && error === "The user is not authenticated") {
          setTimeout(() => {
            setIsAuthenticated(false);
            navigate("/login");
          }, 0);
        } else if (error === "The user is not authenticated") {
          setIsAuthenticated(false);
        }
      }
    };

    checkUserTokens()
  }, [location]);

  return (
    <div>
      {useRoutes([
        {
          element: <LoginLayout />,
          children: [
            {
              element: <LoginPage />,
              path: "/login",
            },
            {
             element: <MaintenancePage/>,
             path: "/maintenance",
            },
            {
              element: <LoginPage />,
              path: "/login/:reset",
            },
            {
              element: <SignupPage />,
              path: "/signup",
            },
            {
              element: <SignupPage />,
              path: "/signup/:accountTypeParam",
            },
            {
              element: <VerifyCodePage />,
              path: "/verifycode/:email",
            },
            {
              element: <ResetPassword />,
              path: "/resetPassword/:key",
            },
            {
              element: <ForgotPage />,
              path: "/forgetpassword",
            },
            {
              element: <Navigate replace to="/login" />,
              path: "/",
            },
          ],
        },
        {
          element: <SetPassword />,
          path: "/setPassword/:subId",
        },
        {
          element: <CommonLayout />,
          children: [
            {
              element: <TaxReportPage />,
              path: "/tax-report",
            },
            {
              element: <ProfilePartners />,
              path: "/profilePartners",
            },
            {
              element: <ProfileClient />,
              path: "/profileClient",
            },
            {
              element: <PartnerEnrollment />,
              path: "/partner-enrollment",
            },
            {
              element: <ProtectionPlanEnroll />,
              path: "/protection-plan-enroll"
            },
            {
              element: <ReferalClient />,
              path: "/res-protection-plan",
            },
            {
              element: <Explanation />,
              path: "/contact-service",
            },
            {
              element: <NoticeAnalyzer />,
              path: "/notice-analyzer",
            },
            // {
            //   element: <TaxExtension />,
            //   path: "/tax-extension",
            // },
            {
              element: <TaxExtensionDeadline />,
              path: "/tax-extension-deadline-passed",
            },
            {
              element: <OrganicClient />,
              path: "/protection-plan",
            },
            {
              element: <TaxRiskReport />,
              path: "/tax-risk-report",
            },
            {
              element: <TaxRiskReportPartner />,
              path: "/tax-risk-report-partner",
            },
            {
              element: <ClientAppointment />,
              path: "/appointments",
            },
            {
              element: <ScheduleAppointmentPage />,
              path: "/schedule-appointment",
            },
            {
              element: <DashBoardPage />,
              path: "/dashboard",
            },
            {
              element: <PartnerDashboardPage />,
              path: "/partner-dashboard",
            },
            {
              element: <PartnerReferalsPage />,
              path: "/partner-referrals",
            },
            {
              element: <ClientAccountSettingsPage />,
              path: "/account-settings",
            },
            {
              element: <CreditCardsOnFile />,
              path: "/account-settings-payment",
            },
            {
              element: <ChangeBillingAddress />,
              path: "/account-settings-billing",
            },
            {
              element: <AddNewPaymentMethod />,
              path: "/account-settings-payment-add",
            },
            {
              element: <ClientDocuments />,
              path: "/account-settings-documents",
            },
            {
              element: <NewReportForm />,
              path: "/account-settings-new-report",
            },
            {
              element: <ContactUs />,
              path: "/account-settings-contactus",
            },
            {
              element: <CancelSubscription />,
              path: "/account-settings-cancel-subscription",
            },
            {
              element: <EditProfile />,
              path: "/account-settings-edit-profile",
            },
            {
              element: <Notifications />,
              path: "/account-settings-notifications",
            },
            {
              element: <ClientPasswordReset />,
              path: "/account-settings-reset-password",
            },
            {
              element: <ClientTermsConditions />,
              path: "/account-settings-terms-conditions",
            },
            {
              element: <PartnerAccountSetting />,
              path: "/partner-settings",
            },
            {
              element: <LicenceAndInsurance />,
              path: "/partner-settings-licence-insurance",
            },
            {
              element: <PartnerContactUs />,
              path: "/partner-settings-contact",
            },
            {
              element: <PartnerDeleteAccount />,
              path: "/partner-settings-delete-account",
            },
            {
              element: <PartnerURLQRSharing />,
              path: "/partner-settings-url-qrcode-sharing",
            },
            {
              element: <PartnerDocuments />,
              path: "/partner-settings-documents",
            },
            {
              element: <PartnerNotifications />,
              path: "/partner-settings-notifications",
            },
            {
              element: <PasswordReset />,
              path: "/partner-settings-reset-password",
            },
            {
              element: <TermsConditions />,
              path: "/partner-settings-terms-conditions",
            },
            {
              element: <PartnerProfile />,
              path: "/partner-profile-info",
            },
            {
              element: <RequestCenter />,
              path: "/requests",
            },
            {
              element: <TaxOrganizerPage />,
              path: "/tax-organizer",
            },
            {
              element: <IncomeExpensePage />,
              path: "/income-expense-form",
            },
            {
              element: <TaxPreparationServiceForm />,
              path: "/tax-preparation-service-enroll"
            },
            {
              element: <TaxPreparationServiceEnroll />,
              path: "/tax-preparation-service"
            },
            {
              element: <SpouseTaxPrepPage />,
              path: "/tax-preparation-service-co-client"
            },
          ],
        },
      ])}
    </div>
  );
};

export default AppRoutes;
