import gql from "graphql-tag";

export const CreateTaxReturnProfile = gql`
  mutation CreateTaxReturnProfile(
    $ClientProfileId: ID!
    $ProfileId: String!
    $TaxReturnId: String!
    $Spouse: Boolean!
    $FirstName: String!
    $MiddleName: String
    $LastName: String!
    $StreetAddress1: String!
    $StreetAddress2: String
    $City: String!
    $State: String!
    $ZipCode: String!
    $Phone: String!
    $Email: String!
  ) {
    CreateTaxReturnProfile(ProfileId: $ClientProfileId,
      Input: {TaxReturnProfile: {City: $City, FirstName: $FirstName, LastName: $LastName, MiddleName: $MiddleName,
           ProfileId: $ProfileId, Spouse: $Spouse, State: $State, StreetAddress1: $StreetAddress1, 
           StreetAddress2: $StreetAddress2, TaxReturnId: $TaxReturnId, ZipCode: $ZipCode, Phone: $Phone, Email: $Email}}
    ) {
        TaxReturnProfile {
        City
        CreatedAt
        FirstName
        Id
        LastName
        MiddleName
        ProfileId
        Spouse
        State
        StreetAddress1
        StreetAddress2
        TaxReturnId
        UpdatedAt
        ZipCode
        Phone
        Email
        }
    }
  }
`;