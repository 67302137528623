import { useContext, useEffect } from "react";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import { US_STATES } from "../../../Common/States";
import '../requestcenter.css';
import { MonthlyIncomeModel } from "./IncomeExpensesContext";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg';

const MonthlyIncomeForm:React.FC<{inputData:MonthlyIncomeModel, 
                                onChangeValue: (event:any) => void}> = (props) => {

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }
    
    return (
        <Form className="mt-2" id="clientInfoForm">
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">W-2*</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Income</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.w2}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="w2"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">1099/K-1* <QuestionMark /></Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Income</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.k1}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="k1"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">Pensions*</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Income</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.pensions}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="pensions"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">Social Security*</Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Income</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.socialSecurity}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="socialSecurity"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">Other Income* <QuestionMark /></Form.Label>
                </Col>
                <Col md={4} lg={4}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Income</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.otherIncome}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="otherIncome"
                    type="text"
                    />
                    </InputGroup>
                    <Form.Control className="mt-2"
                        as="textarea"
                        rows={4}
                        value={props.inputData.otherIncometext}
                        onChange={(event) => props.onChangeValue(event)}
                        name="otherIncometext"
                    />
                </Col>
            </Row>
            <hr className="mt-2"/>
        </Form>
    )
}

export default MonthlyIncomeForm;