import gql from "graphql-tag";
export const PurchaseTaxReturn = gql`
  mutation PurchaseTaxReturn(
    $PaymentMethodToken: String!
    $ProfileId: String!
    $TaxPrepQuoteId: String!
  ) {
    PurchaseTaxReturn(
      Input: {
        PaymentMethodToken: $PaymentMethodToken
        ProfileId: $ProfileId
        TaxPrepQuoteId: $TaxPrepQuoteId
      }
    ) {
      Active
      CreatedAt
      EnrollmentId
      FinalPrice
      Id
      QuotePrice
      Type
      UpdatedAt
      Year
    }
  }
`;
