import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTranslation } from "react-i18next";
import { emailIsValid } from "../functions/misc";
import { useNavigate } from "react-router-dom";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import VerifyCodePage from "../components/verifyCodeComponent";
import { Spinner } from "react-bootstrap";
import { AnimatePresence, motion } from 'framer-motion';
import useMotionStore from "../store/MotionStore";

interface FormError {
  email: string;
  passcode: string;
  password: string;
  confirmpassword: string;
}
const ForgotPage = () => {
  const STEP = {
    init: "init",
    sentCode: "sent_code",
    updatePassword: "updatePassword",
    done: "done"
  };
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const [error, setError] = useState<FormError>({
    email: "",
    passcode: "",
    password: "",
    confirmpassword: "",
  });
  const [submited, setSubmited] = useState(false);
  const [step, setStep] = useState(STEP.init);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [submited2, setSubmited2] = useState(false);
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [confirmPwdIcon, setConfirmPwdIcon] = useState(faEyeSlash);
  const [confirmPwdType, setConfirmPwdType] = useState("password");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [ShowSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    validateForm(email, verifyCode, password, confirmpassword);
  }, [email, verifyCode, password, confirmpassword]);

  const validateForm = (
    _email: string,
    _passcode: string,
    _password: string,
    _confirmpassword: string
  ) => {
    let _error = error;
    if (!_email) {
      _error["email"] = t("forgetPage.error.email.empty");
    } else if (!emailIsValid(_email)) {
      _error["email"] = t("forgetPage.error.email.invalid");
    } else {
      _error["email"] = "";
    }

    if (!_passcode) {
      _error["passcode"] = t("forgetPage.error.passcode.empty");
    } else {
      _error["passcode"] = "";
    }

    if (!_password) {
      _error["password"] = t("forgetPage.error.password.empty");
    } else {
      _error["password"] = "";
    }

    if (!_confirmpassword) {
      _error["confirmpassword"] = t("forgetPage.error.confirmPassword.empty");
    } else if (_password !== _confirmpassword) {
      _error["confirmpassword"] = t(
        "forgetPage.error.confirmPassword.mismatch"
      );
    } else {
      _error["confirmpassword"] = "";
    }

    setError(_error);
    setError({ ...error });
  };

  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const sendCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);
    if (error.email) {
      return;
    }

    setShowSpinner(true);

    Auth.forgotPassword(email.toLowerCase())
      .then((res) => {
        setShowSpinner(false);
        setEmailSent(true);
        setTimeout(() => {
          setStep(STEP.sentCode);
        }, 2000);
      })
      .catch((error) => {
        const code = error.code;
        switch (code) {
          case "UserNotFoundException" || "InvalidParameterException":
            setShowSpinner(false);
            return userNotFoundException();
          case "LimitExceededException":
            setShowSpinner(false);
            const errorCopy = { ...error };
            errorCopy.email =
              "Attempt limit exceeded, please try after some time.";
            return setError(errorCopy);
          default:
            setShowSpinner(false);
            return false;
        }
      });
  };

  const userNotFoundException = () => {
    const errorCopy = { ...error };
    errorCopy.email = "Email doesn't exist";
    setError(errorCopy);
  };


  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleInputConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  const toggleConfirmPassword = () => {
    if (confirmPwdType === "password") {
      setConfirmPwdType("text");
      setConfirmPwdIcon(faEye);
      return;
    }
    setConfirmPwdType("password");
    setConfirmPwdIcon(faEyeSlash);
  };

  const resetPassword = () => {
    setSubmited2(true);
    if (error.password || error.confirmpassword) {
      return;
    }

    Auth.forgotPasswordSubmit(email, verifyCode, password)
      .then((res) => {
        setTimeout(() => {
          setStep(STEP.done)
        }, 2000);
      })
      .catch((error) => {
        setSubmited2(false);
        const code = error.code;
        if (code === "CodeMismatchException") {
          const errorCopy = { ...error };
            errorCopy.passcode =
              "The code entered is invalid. Please verify the code or request a new passcode.";
            return setError(errorCopy);
        }

        if(code === "InvalidPasswordException") {
          const errorCopy = { ...error };
          errorCopy.passcode = error.message
          return setError(errorCopy);
        }
      });
    // Todo: send new password
  };

  const onverified = (val: any) => {
    if (val) {
      setVerifyCode(val);
      setStep(STEP.updatePassword);
    }
  };

  const motionContainer = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
    exit: { opacity: 0, transition: { duration: 0.2, ease: "easeInOut" } }
  };

  return (
    <DefaultLayout>
      <AnimatePresence mode="wait">
        { step === STEP.done && (
          <motion.div key={STEP.done} variants={motionContainer} initial="hidden" animate="visible" exit="exit">
            <PageBody>
              <form>
              <FormTitle>{t("forgetPage.verifySuccess")}</FormTitle>
              <Button
                className="w-100 mt-4"
                variant="primary"
                onClick={(e) => {
                  navigate("/login");
                }}
              >
                {t("forgetPage.btn.signin")}
              </Button>
            </form>
          </PageBody>
          </motion.div>)}
        {step === STEP.init && (
          <motion.div key={STEP.init} variants={motionContainer} initial="hidden" animate="visible" exit="exit">
            <PageBody>
               <form>
                <FormTitle style={{fontFamily: "Montserrat"}}>
                 {t("forgetPage.title")}
                </FormTitle>
                <p className="content-text mb-4">{t("forgetPage.enterSentence")}</p>
                <FormGroup className="mb-3" id="passcode">
                  <FormLabel>{t("forgetPage.email")}</FormLabel>
                  <Form.Control
                    value={email}
                    placeholder={t("forgetPage.email")}
                    onChange={handleInputEmail}
                    className={submited && error.email ? "is-invalid mb-4" : "mb-4"}
                  />
                  {submited && error.email && (
                    <motion.div key="errorEmail" variants={motionContainer} initial="hidden" animate="visible">
                      <Message message={error.email} type="error"></Message>
                    </motion.div>
                  )}
                  {emailSent && (
                    <motion.div key="validEmail" variants={motionContainer} initial="hidden" animate="visible">
                      <Message
                        message={t("forgetPage.sentMail")}
                        type="success"
                      ></Message>
                    </motion.div>
                  )}
                </FormGroup>
                <Button className="w-100" variant="primary" disabled={ShowSpinner} onClick={sendCode}>
                {ShowSpinner &&
                  <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-4"
                  />
                  {'  '}
                  </>
                  }
                  {t("forgetPage.btn.sendCode")}
                </Button>
               </form>
            </PageBody>
          </motion.div>
        )}
        {step === STEP.sentCode && (
          <motion.div key={STEP.sentCode} variants={motionContainer} initial="hidden" animate="visible" exit="exit">
            <VerifyCodePage email={email} setVerified={onverified} />
          </motion.div>
        )}
        {step === STEP.updatePassword && (
          <PageBody>
            <motion.div key={STEP.updatePassword} variants={motionContainer} initial="hidden" animate="visible" exit="exit">
            <FormTitle>{t("forgetPage.enterYourPassword")}</FormTitle>
            <p className="content-text">{t("forgetPage.enterSentenceForPassword")}</p>
            <FormGroup className="" id="password">
              <FormLabel>{t("forgetPage.password")}</FormLabel>
              <div className="position-relative">
                <Form.Control
                  value={password}
                  type={pwdType}
                  placeholder={t("forgetPage.password")}
                  onChange={handleInputPassword}
                  className={
                    "input-no-bg " +
                    (submited2 && error.password ? "is-invalid" : "")
                  }
                />
                <FontAwesomeIcon
                  onClick={togglePassword}
                  className="addOn"
                  icon={pwdIcon}
                />
              </div>
              {submited2 && error.password && (
                <motion.div key="errorPassword" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                  <Message message={error.password} type="error"></Message>
                </motion.div>
              )}
            </FormGroup>
            <FormGroup className="mb-3 mt-2" id="confirmpassword">
              <FormLabel>{t("forgetPage.confirmpassword")}</FormLabel>
              <div className="position-relative">
                <Form.Control
                  value={confirmpassword}
                  type={confirmPwdType}
                  placeholder={t("forgetPage.confirmpassword")}
                  onChange={handleInputConfirmPassword}
                  className={
                    "input-no-bg " +
                    (submited2 && error.confirmpassword ? "is-invalid" : "")
                  }
                />
                <FontAwesomeIcon
                  onClick={toggleConfirmPassword}
                  className="addOn"
                  icon={confirmPwdIcon}
                />
              </div>
              {submited2 && error.confirmpassword && (
                <motion.div key="errorConfirmPassword" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                  <Message message={error.confirmpassword} type="error"></Message>
                </motion.div>
              )}

              {submited && error.passcode && (
                <motion.div key="errorSubmittedPassword" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                  <Message message={error.passcode} type="error"></Message>
                </motion.div>
              )}
            </FormGroup>
            <Button className="w-100 mt-3" disabled={submited2} onClick={resetPassword}>
              {submited2 &&
                <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-4"
                />
                {'  '}
                </>
                }
                {t("forgetPage.btn.reset")}
            </Button>
            </motion.div>
          </PageBody>
        )}
      </AnimatePresence>
    </DefaultLayout>
  );
};

export default ForgotPage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

const FormTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-text-default);
  font-family: "Montserrat", sans-serif;
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;
