import gql from "graphql-tag";

export const GetProfilePrimaryAddress = gql`
  query GetProfilePrimaryAddress($profileId: ID!) {
    GetProfilePrimaryAddress(ProfileId: $profileId) {
      City
      CreatedAt
      Id
      ProfileId
      State
      StreetAddress1
      StreetAddress2
      UpdatedAt
      ZipCode
    }
  }
`;
