import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import background from "../assets/background.png";
import logo from "../assets/OTR_optima_color.svg";
import mask from "../assets/ring.png";

import { breakpoints } from "../styles/globalStyles";

const MaintenanceLayout = ({ children }: any) => {
  return (
    <Container className="d-flex flex-column align-items-center">
      <Mask src={mask} />
      <CardContainer className="m-auto">
        <Row className="justify-content-md-center">
            <LogoImage className="m-auto mt-4 text-center">
              <Image src={logo} style={{ height: 230 }}></Image>
            </LogoImage>
          <Row>{children}</Row>
        </Row>
      </CardContainer>

      <TermSentence style={{ zIndex: 10 }}>
        Copyright © Optima Tax Relief, LLC | All Rights Reserved
        <Dot>•</Dot>
        <ForgotPasswordLink
          href="https://optimataxrelief.com/about-us/terms-of-site/"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions{" "}
        </ForgotPasswordLink>
        <Dot>•</Dot>
        <ForgotPasswordLink
          href="https://optimataxrelief.com/about-us/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </ForgotPasswordLink>
        <p className="text-right">v0.7.1</p>
      </TermSentence>
    </Container>
  );
};

export default MaintenanceLayout;

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background: url(${background});
  background-size: cover;
`;

const Mask = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;
`;
const CardContainer = styled.div`
  border-radius: 10px;
  text-align: center;
  z-index: 1;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 90%;
    background: white;
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    width: 90%;
    background: white;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    width: 80%;
    background: white;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 60%;
    background: white;
  }
`;

const LogoImage = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 2rem !important;
  }
`;

const TermSentence = styled.div`
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  color: #ffff;
`;

const ForgotPasswordLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3px;
  color: white;
`;

const Dot = styled.span`
  margin-left: 3px;
`;
