import gql from "graphql-tag";

export const GetFreeTaxRiskReportEligibility = gql`
  query GetFreeTaxRiskReportEligibility($profileId: ID!) {
    GetFreeTaxRiskReportEligibility(ProfileId: $profileId) {
      EnrollmentInitiated
      IsEligible
      EnrollmentTranscriptRequestAttempts
      TranscriptRequestAttemptsAllowed
      LastTaxRiskReport {
        Id
        OverallRisk
        OverallRiskDescription
        RequestedAt
        Status
      }
    }
  }
`;
