import { createContext } from "react";
interface ContextState {
  next: () => void;
  enrollmentId: string;
  setEnrollmentId: (id: string) => void;
  setNextFormId: (id: string) => void;
  nextFormId: string;
  s3key: string;
  setS3Key: (id: string) => void;
  s3Bucket: string;
  setS3Bucket: (id: string) => void;
  billingAddress: any;
  setBillingAddress: (address: any) => void;
  priceDetail: any;
}

const ReferalClientFormContext = createContext({} as ContextState);

export default ReferalClientFormContext;

export const { Provider, Consumer } = ReferalClientFormContext;
