import "../../pages/sidebar.css";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import logo from "../../assets/OTR_optima_blue_solid.svg";
import TaxPreparationServiceFormContext from "./TaxPreparationServiceFormContext";

function TaxPrepServiceSideBar() {
  const { pageName } = useContext(TaxPreparationServiceFormContext);
  const { navigateToPage,completedPages  } = useContext(TaxPreparationServiceFormContext);

  const location = useLocation();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  
  const hamburgerMenu = () => {
    if (hamburgerMenuOpen)
      setHamburgerMenuOpen(false);
    else
      setHamburgerMenuOpen(true);
  };

  
  return (
    <div className={hamburgerMenuOpen ? "sidebar sidebar-mobile" : "sidebar"}>
      <ul className={hamburgerMenuOpen ? "bg-white" : "sidebar-items-hide"}>
        <li 
          className={!completedPages.includes('filingType')?'taxPrepSidebarDisabled':pageName === 'filingType' ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"} 
              onClick={() => navigateToPage('filingType')}>
          Filing Type
        </li>
        <li 
          className={!completedPages.includes('incomeForm1')?'taxPrepSidebarDisabled':pageName.startsWith("incomeForm") ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"} 
              onClick={() => navigateToPage('incomeForm1')}>
         Income
        </li>
        <li 
          className={!completedPages.includes('serviceQuote')?'taxPrepSidebarDisabled':pageName === 'serviceQuote' ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"} 
              onClick={() => navigateToPage('serviceQuote')}>
         Service Quote
        </li>
        <li 
          className={!completedPages.includes('serviceAgreement1')?'taxPrepSidebarDisabled':pageName.startsWith("serviceAgreement") ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"}
              onClick={() => navigateToPage('serviceAgreement1')}>
         Service Agreement
        </li>
        <li 
          className={!completedPages.includes('billingInfo')?'taxPrepSidebarDisabled':pageName === 'billingInfo' ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"} 
              onClick={() => navigateToPage('billingInfo')}>
           Payment
         </li>
         <li 
            className={!completedPages.includes('completed')?'taxPrepSidebarDisabled':pageName === 'completed' ? "taxPrepSidebarBold active me-2" : "taxPrepSidebar"} 
              onClick={() => navigateToPage('completed')}>
           Completed
         </li>
      </ul>
      <div id="hamburger-menu-div">
        <a id="hamburger-icon" className="pt-4 ps-4" onClick={hamburgerMenu}>
          <FontAwesomeIcon icon={faBars} className="fa-2xl" />
        </a>
        <img
            src={logo}
            alt="app-logo"
            className="sidebar-logo"
        />
      </div>
    </div>
  );
}

export default TaxPrepServiceSideBar;