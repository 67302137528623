import ProtectionPlanReport from "../components/TaxRiskReportPartner/protectionPlanReport";

function TaxRiskReportPartner() {
  return (
    <>
      <ProtectionPlanReport />
    </>
  );
}

export default TaxRiskReportPartner;
