import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import background from "../assets/background.png";
import logo from "../assets/OTR_optima_color.svg";
import mask from "../assets/ring.png";
import { breakpoints } from "../styles/globalStyles";
import { motion } from 'framer-motion';
import useMotionStore from "../store/MotionStore";

const DefaultLayout = ({ children }: any) => {
  const { motionLayout } = useMotionStore();

  const motionContainer = {
    initial: motionLayout?.layoutLoaded ? {} : { opacity: 0, y: 20, height: "auto"},
    animate: { opacity: 1, y: 0, height: "auto" },
  };

  return (
    <Container className="d-flex flex-column align-items-center media-container">
      <Mask src={mask} className="media-mask"/>
        <motion.div className="card-layout-container"
                      variants={motionContainer}
                      animate="animate"
                      exit="exit"
                      transition={{ duration: 0.5, ease: "easeInOut" }}>
            <Row className="d-flex justify-content-center align-items-center">
                <Col md className="d-flex">
                  <LogoImage className="m-auto text-center">
                    <Image src={logo} className='optima-logo'></Image>
                    <p></p>
                  </LogoImage>
                  <hr className="login-hr"></hr>
                </Col>
                <Col md>
                  {children}
                </Col>
            </Row>
        </motion.div>
      <TermSentence style={{ zIndex: 10 }} className="media-term-text">
        Copyright © Optima Tax Relief, LLC | All Rights Reserved
        <Dot>•</Dot>
        <ForgotPasswordLink
          href="https://optimataxrelief.com/about-us/terms-of-site/"
          target="_blank"
          rel="noreferrer"
          className="media-term-text"
        >
          Terms & Conditions{" "}
        </ForgotPasswordLink>
        <Dot>•</Dot>
        <ForgotPasswordLink
          href="https://optimataxrelief.com/about-us/privacy/"
          target="_blank"
          rel="noreferrer"
          className="media-term-text"
        >
          Privacy Policy
        </ForgotPasswordLink>
        <p className="text-right">v0.7.1</p>
      </TermSentence>
    </Container>
  );
};

export default DefaultLayout;

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  background: url(${background});
  background-size: cover;
`;

const Mask = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;
`;
const CardContainer = styled.div`
  width: 70%;
  max-width: 1420px;
  border-radius: 10px;
  z-index: 1;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 90%;
    background: white;
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    width: 90%;
    background: white;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    width: 80%;
    background: white;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 70%;
    background: white;
  }
`;

const LogoImage = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    margin-top: 2rem !important;
  }
`;

const TermSentence = styled.div`
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #ffff;
`;

const ForgotPasswordLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3px;
  color: white;
`;

const Dot = styled.span`
  margin-left: 3px;
`;
