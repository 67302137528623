import ProtectionPlanReport from "../components/TaxRiskReport/protectionPlanReport";

function TaxRiskReport() {
  return (
    <>
      <ProtectionPlanReport />
    </>
  );
}

export default TaxRiskReport;
