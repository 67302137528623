import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { GetProtectionPlanSubscription } from "../../queries/GetProtectionPlanSubscription";
import useEnrollmentStore, { EnrollmentStatus } from "../../store/EnrollmentStore";
import Loading from "../../Common/Loading";

function ClientAccountSetting() {
 let { state } = useLocation();
 const navigate = useNavigate();
 const [showCancelSubscription, setShowCancelSubscription] = useState(false);
 const client = new clientConfig();
 const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();
 const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSubscriptionStatus();
  },[]);

  const getSubscriptionStatus = async () => {
    setIsLoading(true);
    const profileId = await userProfileData();

    client
      .query({
        query: GetProtectionPlanSubscription,
        variables: {
          ProfileId: profileId,
        },
      })
      .then((res: any) => {
        if(res.data.GetProtectionPlanSubscription){
          const cancelReason = res.data.GetProtectionPlanSubscription.CancellationReason;
          if(cancelReason){
            setShowCancelSubscription(false);
          }else {
            setShowCancelSubscription(true);
          }
        }else {
          setShowCancelSubscription(false);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error, "error");
        setIsLoading(false);
      });
  }

  return (
    <>
    {isLoading? <Loading /> : 
      <Container className="mb-5 tax-risk-report">
        <Row className="justify-content-center g-2">
          <Col lg={10}>
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Account Settings</Breadcrumb.Item>
            </Breadcrumb>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-1">
              Account Settings
            </h4>
            <p className="content-text">
              Update your email, phone or address, set your privacy and language
              preferences, and more.
            </p>
            <Row className="g-2">
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0 px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Profile Information
                    </h6>
                    <Card.Text className="content-text">
                      Update your personal information, email, phone or address.
                    </Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-edit-profile", { state });
                      }}
                    >
                      Update Info {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Password Reset
                    </h6>
                    <Card.Text className="content-text">
                      We will send a temporary password to your email address.
                    </Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-reset-password", { state });
                      }}
                    >
                      Reset Password {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0  px-lg-3 w-100">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>Documents</h6>
                    <Card.Text className="content-text">
                      Manage your uploaded and signed documents.
                    </Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-documents", { state });
                      }}
                    >
                      Update Info {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
             {protectionPlanEnrollment?.enrollmentStatus === EnrollmentStatus.Completed &&
               <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0  px-lg-3">
                 <Card.Body>
                  <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                   Payment Information
                  </h6>
                  <Card.Text className="content-text">
                   Manage the payment information for your Optima Tax
                   Account.
                  </Card.Text>
                  <Card.Link
                   className="cursor-pointer text-decoration-none material-blue"
                   onClick={() => {
                    navigate("/account-settings-payment", { state });
                   }}
                  >
                   Update Info {">"}
                  </Card.Link>
                 </Card.Body>
                </Card>
             </Col>}
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0 w-100  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Notifications
                    </h6>
                    <Card.Text className="content-text">
                      Set alerts for the things you care about most.
                    </Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-notifications", { state });
                      }}
                    >
                      Manage Notifications {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              {showCancelSubscription && 
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0 w-100  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Cancel Subscription
                    </h6>
                    <Card.Text className="content-text">Cancel your subscription.</Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-cancel-subscription", { state });
                      }}
                    >
                      Cancel Subscription {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              }
              <h4 className="text-main-dark ff-Montserrat fw-bold mb-1 mt-5">
                Help & Support
              </h4>
              <p className="content-text">
                We’re here to help. Check out our additional support options
                below to get in touch with our award-winning customer service
                team.
              </p>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>Contact Us</h6>
                    <Card.Text className="content-text">
                      Please contact Optima regarding any questions you may
                      have.
                    </Card.Text>
                    <Card.Link
                      className="cursor-pointer text-decoration-none material-blue"
                      onClick={() => {
                        navigate("/account-settings-contactus", { state });
                      }}
                    >
                      Get in Touch {">"}
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      }
    </>
  );
}

export default ClientAccountSetting;
