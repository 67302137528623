import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { UpdateProfile } from "../../queries/updateProfile";
import { useNavigate } from "react-router-dom";
import Message from "../Message";
import { GetPartner } from "../../queries/GetPartner";
import { UpdatePartner } from "../../queries/updatePartner";

function PartnerNotifications() {
  const client = new clientConfig();
  const navigate = useNavigate();
  const [personInfo, setPersonInfo] = useState({});
  const [callEnabled, setCallEnabled] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [success, setSuccess] = useState("");
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetPartner,
        variables: { PartnerId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetPartner !== null) {
          setPersonInfo(res.data.GetPartner);
          setCallEnabled(res.data.GetPartner.CallEnabled);
          setSmsEnabled(res.data.GetPartner.SmsEnabled);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const submitInfo = async () => {
    const profileId = await userProfileData();
    const variables = {
      ...personInfo,
      Id: profileId,
      CallEnabled: callEnabled,
      SmsEnabled: smsEnabled,
    };
    client
      .mutate({ mutation: UpdatePartner, variables: variables })
      .then((res: any) => {
        if (res.data.UpdatePartner) {
          console.log("success");
          setSuccess("Updated Notification Information");
          setTimeout(() => {
            setSuccess("");
          }, 5000);
        }
      })
      .catch((error: any) => {});
  };
  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3">
              <Col lg={8}>
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Notifications
                </h4>
                <h6 className="text-main-dark ff-Montserrat fw-bold">
                  Phone Calls & Text Messages
                </h6>
              </Col>
            </Row>
            <Row className="mb-3 justify-content-between align-items-top">
              <Col md={5}>
                <p className="content-text">
                  We’ll use your primary email address to send you notices about
                  your account. You can also opt-in to receive updates via phone
                  calls and text messages.
                </p>
              </Col>
              <Col md={5}>
                <Form>
                  <Row className="mb-3">
                    <Col md={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                       <p className="color-gray ff-Roboto">Phone Calls</p>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={callEnabled}
                            onChange={() => {
                              setCallEnabled(!callEnabled);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="d-grid">
                         <p className="color-gray mb-0 ff-Roboto">Text Messages</p>
                          <small>
                           <i className="color-gray ff-Roboto" style={{fontSize: "0.8rem"}}>Msg & data rates may apply</i>
                          </small>
                        </div>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            checked={smsEnabled}
                            onChange={() => {
                              setSmsEnabled(!smsEnabled);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row className="mb-3 justify-content-between align-items-top">
              <Col md={5}>
                <button onClick={submitInfo} className="btn-xl mt-3">
                  Save Changes
                </button>
              </Col>
              <Col md={6}>
                {success && (
                  <Message type="success" message={success}></Message>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerNotifications;
