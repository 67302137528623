import React from 'react';
import DashBoard from "../components/DashBoards";
import TaxPrepEnrollmentCompletedModal from "../components/TaxPreparationService/TaxPrepEnrollmentCompletedModal";
import useEnrollmentStore from "../store/EnrollmentStore";

function DashBoardPage() {
  const { taxPrepEnrollment, setTaxPrepEnrollment } = useEnrollmentStore();

  const onCloseHandler = () => {
    setTaxPrepEnrollment({
      taxPrepEnrollmentCompleted: true,
      showTaxPrepNextStepsModal: false,
      paymentCompleted: true
    })
  }

  return (
    <>
      <DashBoard />
      <TaxPrepEnrollmentCompletedModal showModal={taxPrepEnrollment?.showTaxPrepNextStepsModal} onClose={onCloseHandler}/>
    </>
  );
}

export default DashBoardPage;
