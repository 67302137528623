import "../../../pages/navbar.css";
import "../../styles.css"
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import customerDashboard from "../../../assets/HeroTaxPrep-Trim.svg";
import useProfileStore from "../../../store/ProfileStore";
import { motion } from "framer-motion";

function SpouseTaxPrepDashboard() {
  const { spouseProfile } = useProfileStore();
  const navigate = useNavigate();

  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <motion.div variants={motionContainer} initial="initial" animate="animate">
          <Row className="justify-content-center">
            <Col md={10} className="content-box pb-5 position-relative">
              <Row className="px-lg-5 justify-content-lg-start justify-content-md-center justify-content-sm-center align-items-center">
                <Col lg={7} md={5} sm={8} className="pt-5 mt-3">
                  <h1 className="text-dark mb-3 ff-Montserrat fs-3 fw-bold pb-4">
                    Welcome {spouseProfile?.FirstName} to Your Tax Preparation Service!
                  </h1>
                  <p className="content-text pb-3 w-75">
                    Please click the “Continue” button below to proceed with your tax preparation service.
                  </p>
                  <div className="d-flex justify-content-start mt-5 align-items-center">
                    <button  className="btn-xl" onClick={() => navigate("/tax-preparation-service-co-client")}>
                      Continue
                    </button>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={4}>
                  <img
                    src={customerDashboard}
                    alt="Tax-Preparation"
                    className="tax-preparation-service-spouse-dashboard-bg"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </>
  );
}

export default SpouseTaxPrepDashboard;
