import Requests from "../components/RequestCenter/Requests";
import PortalRequests from "../components/RequestCenter/PortalRequests";

function RequestCenterPage() {
    return (
      <>
        <PortalRequests />
      </>
    );
  }
  
  export default RequestCenterPage;