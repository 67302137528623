import gql from "graphql-tag";

export const CreatePartnerEnrollementAction = gql`
  mutation CreatePartnerEnrollmentAction(
    $Name: PartnerEnrollmentActionName!
    $PartnerEnrollmentId: ID!
  ) {
    CreatePartnerEnrollmentAction(
      Input: {
        Name: $Name
        PartnerEnrollmentId: $PartnerEnrollmentId
      }
    ) {
      PartnerEnrollment {
        Id
        NextEnrollmentAction
        NextForm {
          Id
          Name
          S3Bucket
          S3Key
          S3VersionId
        }
      }
      Id
      Name
    }
  }
`;
