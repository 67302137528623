export const PAGE_RECORDS_COUNT = 5;
export const PAGE_NUMBERS_TO_SHOW = 4;

export const UPLOAD_DOCUMENT_HEADER = 'Upload Your Document';
export const UPLOAD_DOCUMENT_REQ_QUESTION = 'What is this request?';
export const UPLOAD_DOCUMENT_REQ_DESCRIPTION = 'We often need the previous year\'s returns to properly represent and identify your financial sitution. Please provie these documents ASAP.';
export const MAX_FILE_SIZE = 20;

export interface Request  {
    Name: string;
    Description: string,
    Status: string;
    action: string;
}


export enum FilingStatusTypes {
  Single='Single',
  HeadofHousehold='HeadofHousehold',
  MarriedFilingJointly='MarriedFilingJointly',
  MarriedFilingSeparately='MarriedFilingSeparately'
}