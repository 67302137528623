import React, {useState, useEffect} from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import irsNoticeIcon from "../../../assets/irs-notice-icon.svg";
import taxPrepServiceIcon  from "../../../assets/TaxPrepService.svg";
import ProtectionPlanIcon from '../../../assets/ProtectionPlan.svg';
import { userProfileData } from '../../../Common/profileInfo';
import { clientConfig } from '../../../awsClientConfig';
import { GetProfile } from '../../../queries/getProfile';
import useEnrollmentStore, { ProtectionPlanName } from "../../../store/EnrollmentStore";

const TaxTools:React.FC<{
  showProtectionPlanCard?: boolean,
  subscriptionStatus?:string,
  onClick?:() => void
}> = ({showProtectionPlanCard = false, subscriptionStatus='', onClick=() => {}}) => {
  const [showTaxPreparationCard, setShowTaxPreparationCard] = useState(true);
  const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (taxPrepEnrollment?.taxPrepEnrollmentCompleted ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Standard ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Professional) {
      setShowTaxPreparationCard(false);
    }
  }, []);

    return (
      <>
        <Row className="g-4  align-items-center">
         {process.env.REACT_APP_TAX_PREPNETWORK_ALLOWED === 'true' && showTaxPreparationCard &&
           <Col lg={6}>
             <Card
               className="border-0 BonusService cursor-pointer"
               onClick={() => navigate("/tax-preparation-service")}
             >
           <div className="position-absolute top-0 end-0">
            <button className="border-0 rounded-bottom py-2 px-3 rounded-end fw-bold text-muted">
             <small>
              <i>Service</i>
             </small>
            </button>
           </div>
           <Card.Body className="d-flex align-items-center justify-content-between mt-3">
            <div className="tax-tools-icon-margin">
             <img
              src={taxPrepServiceIcon}
              className="max-auto d-block"
              alt=""
             />
            </div>
            <div className="tax-tools-content-margin">
             <Card.Title className="fs-6 ff-Montserrat fw-bold">
              Tax Preparation Service
             </Card.Title>
             <Card.Text>
             Experience expert tax preparation services with the most reliable name in the industry. Rest easy with our $10,000 accuracy guarantee.
             </Card.Text>
            </div>
           </Card.Body>
          </Card>
         </Col>}
              {showProtectionPlanCard && 
              <Col lg={6}>
                <Card
                  className="border-0 BonusService  cursor-pointer"
                  onClick={onClick}
                >
                  <div className="position-absolute top-0 end-0">
                    <button className="border-0 rounded-bottom py-2 px-3 rounded-end fw-bold text-muted">
                      <small>
                        <i>Service</i>
                      </small>
                    </button>
                  </div>
                  <Card.Body className="d-flex align-items-center justify-content-between mt-3">
                    <div className="tax-tools-icon-margin">
                      <img
                        src={ProtectionPlanIcon}
                        width={72}
                        height={72}
                        className="max-auto d-block"
                        alt=""
                      />
                    </div>
                    <div className="tax-tools-content-margin">
                      <Card.Title className="fs-6 ff-Montserrat fw-bold">
                        Protection Plan
                      </Card.Title>
                      <Card.Text>
                      Your Tax Protection Service. Shield Your Finances with Expert Guidance, Proactive Strategies, and Ironclad Defense. Secure Tomorrow Today.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              }
              <Col lg={6}>
                <Card
                  className="border-0 BonusService  cursor-pointer"
                  onClick={() => navigate("/notice-analyzer")}
                >
                  <div className="position-absolute top-0 end-0">
                    <button className="border-0 rounded-bottom py-2 px-3 rounded-end fw-bold text-muted">
                      <small>
                        <i>TAX TOOLS</i>
                      </small>
                    </button>
                  </div>
                  <Card.Body className="d-flex align-items-center justify-content-between mt-3">
                    <div className="tax-tools-icon-margin">
                      <img
                        src={irsNoticeIcon}
                        className="max-auto d-block"
                        alt=""
                      />
                    </div>
                    <div className="tax-tools-content-margin">
                      <Card.Title className="fs-6 ff-Montserrat fw-bold">
                        IRS Notice Analyzer
                      </Card.Title>
                      <Card.Text>
                        Understand your IRS notice in just a few clicks,
                        determine the urgency level & potential risks to help
                        you figure out what to do next.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="g-2  align-items-center mt-2">
              {/*<Col lg={6}>*/}
              {/*  <Card*/}
              {/*    className="border-0 BonusService"*/}
              {/*    onClick={() => navigate("/tax-extension")}*/}
              {/*  >*/}
              {/*    <div className="position-absolute top-0 end-0">*/}
              {/*      <button className="border-0 rounded-bottom py-2 px-3 rounded-end fw-bold text-muted">*/}
              {/*        <small>*/}
              {/*          <i>TAX TOOLS</i>*/}
              {/*        </small>*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*    <Card.Body className="d-flex align-items-center justify-content-between mt-3">*/}
              {/*      <div className="me-5">*/}
              {/*        <img*/}
              {/*          src={CalendarIcon}*/}
              {/*          className="max-auto d-block"*/}
              {/*          alt=""*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <Card.Title className="fs-6 ff-Montserrat fw-bold">*/}
              {/*          File a Free Tax Extension*/}
              {/*        </Card.Title>*/}
              {/*        <Card.Text>*/}
              {/*          Life gets busy, and tax filing deadlines can sometimes*/}
              {/*          sneak up on you. Thankfully, you can file for an*/}
              {/*          extension for free using our app.*/}
              {/*        </Card.Text>*/}
              {/*      </div>*/}
              {/*    </Card.Body>*/}
              {/*  </Card>*/}
              {/*</Col>*/}
              {/*<Col md={12} className="text-center">*/}
              {/*  <p className="content-text text-center">*/}
              {/*    Get the FREE Optima Tax App and access your account on the go!*/}
              {/*  </p>*/}
              {/*  <Row className="justify-content-center">*/}
              {/*    <Col md={2}>*/}
              {/*      <span className="appstore-icon" style={{ cursor: "pointer" }}>*/}
              {/*        <img src={appstoreIcon} className="img-fluid" alt="" />*/}
              {/*      </span>*/}
              {/*    </Col>*/}
              {/*    <Col md={2}>*/}
              {/*      <span className="playstore-icon" style={{ cursor: "pointer" }}>*/}
              {/*        <img src={playstoreIcon} className="img-fluid" alt="" />*/}
              {/*      </span>*/}
              {/*    </Col>*/}
              {/*  </Row>*/}
              {/*</Col>*/}
            </Row>
          </>
    )
}

export default TaxTools;