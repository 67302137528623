import React, { useEffect, useState } from "react";
import "../../../pages/navbar.css";
import "../../styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TaxNoReportimg from "../../../assets/unable-generate-report.svg";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { userProfileData } from "../../../Common/profileInfo";
import { GetLatestFormsByProfile } from "../../../queries/GetLatestFormsByProfile";
import { clientConfig } from "../../../awsClientConfig";
import { GetProfile } from "../../../queries/getProfile";
import Loading from "../../../Common/Loading";
import TaxTools from "../ClientDashboards/TaxTools";
import { motion } from "framer-motion";

type Props = {
  taxPrepEnrollmentStatus: boolean | undefined;
}

function TaxNoReportAvailable({ taxPrepEnrollmentStatus }: Props) {
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const client = new clientConfig();
  const navigate = useNavigate();

 useEffect(() => {
  const fetchApi = async () => {
   await Auth.currentAuthenticatedUser({ bypassCache: true });
   const partnerId = await userProfileData();
   await client
    .query({
     query: GetLatestFormsByProfile,
     variables: {
      ProfileId: partnerId,
     },
    })
    .then((res: any) => {
     const formsData = res.data?.GetLatestFormsByProfile?.find((form: any) => form.Name === "Form8821");
     setForm(formsData);
     setLoading(false);
    })
   }

  fetchApi();
 }, []);

 const skipFreeReport = async () => {
  const profileId = await userProfileData();
  client
   .query({
    query: GetProfile,
    variables: { profileId: profileId },
   })
   .then((res: any) => {
    if (res.data.GetProfile !== null) {
     if (res.data.GetProfile.EnrollmentSource === "Referral") {
      navigate("/res-protection-plan");
     } else {
      navigate("/protection-plan");
     }
    }
   })
   .catch((error: any) => {
    console.log(error, "error");
   });
 };

  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

  return (
    <>
      <Container className="mt-3 mb-5">
       {loading ? <Loading /> :
         <motion.div variants={motionContainer} initial="initial" animate="animate">
           <Row className="justify-content-center">
             <Col lg={10} className="content-box pt-5 pb-5 px-lg-5">
              <Row className="px-lg-5 justify-content-center align-items-center">
               <Col lg={7}>
                <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                 We're Currently Unable to Generate Your Report.
                </h1>
                <p className="content-text">
                 Please review the details you entered on Form 8821 and ensure the information you provided matches
                 IRS records (check against your most recently filed tax return, especially your name and Social
                 Security Number information). There may be a mistake on the authorization that is preventing access
                 to your transcripts. Verifying the accuracy of your information will allow us to complete your set up for Comprenhensive Tax Risk Monitoring.
                </p>

                <div className="flex flex-column d-flex justify-content-start align-items-center">
                 <button className="btn-yellow w-50 mb-2 mt-3" onClick={() => navigate("/account-settings-documents", { state: form })}>
                  File a New Form 8821
                 </button>
                 <a className="letsdolater mb-2" onClick={() => navigate("/appointments")}>
                  Get Help From My Protection Specialist
                 </a>
                </div>
               </Col>
               <Col lg={5}>
                <img
                 src={TaxNoReportimg}
                 alt="Tax-report"
                 className="w-75 d-block mx-auto"
                />
               </Col>
              </Row>
             </Col>
             <Col md={10} className="px-0 my-3">
              <TaxTools />
             </Col>
          </Row>
         </motion.div>
       }
      </Container>
    </>
  );
}

export default TaxNoReportAvailable;
