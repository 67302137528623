import { createContext } from "react";
interface ContextState {
  next: () => void;
  enrollmentId:string
  setEnrollmentId:(id:string)=>void
  setNextFormId:(id:string)=>void
  nextFormId:string
  jointTaxReturn:string
  setJointTaxReturn:(value:string)=>void
  businessReport:string
  setBusinessReport:(value:string)=>void
  businessTaxReturn:string
  setBusinessTaxReturn:(value:string)=>void
  s3key:string
  setS3Key:(id:string)=>void
  s3Bucket:string
  setS3Bucket:(id:string)=>void
  NextEnrollmentAction:(action:string)=>void
}

const TaxFormContext = createContext({} as ContextState);

export default TaxFormContext;

export const { Provider, Consumer } = TaxFormContext;
