import gql from "graphql-tag";

export const GetPartnerWithoutTaxProfessional = gql`
  query GetPartner($PartnerId: ID!) {
    GetPartner(PartnerId: $PartnerId) {
      Active
      ApplicationStatus
      FirstName
      LastName
      MiddleName
      Email
      Phone
      CallEnabled
      SmsEnabled
      Title
      Type
      CallEnabled
      Id
      ConsentToShare
      SmsEnabled
      OrganizationId
      
    }
  }
`;
