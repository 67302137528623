import gql from "graphql-tag";

export const CreateLead = gql`mutation CreateLead(
    $Email: AWSEmail!
    $FirstName: String!
    $LastName: String!
    $PartnerId: ID
    $Phone: AWSPhone!
    $IsQualified: Boolean!
    $ConsentToDisclose: Boolean!
    $RecommendedProtectionPlanId: ID
) {
    CreateLead(Input: {
     Email: $Email, 
     FirstName: $FirstName, 
     IsQualified: $IsQualified, 
     LastName: $LastName, 
     PartnerId: $PartnerId, 
     Phone: $Phone,
     ConsentToDisclose: $ConsentToDisclose
     RecommendedProtectionPlanId: $RecommendedProtectionPlanId})
    {
      AccountId
      ConvertedAt
      PartnerId
      PhoneExt
      RecommendedProtectionPlanId
      TaxDebt
    }
  }`;
