import React, { useEffect, useRef, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FormGroup, FormLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { GetPartner } from "../../queries/GetPartner";
import Loading from "../../Common/Loading";
import QRCode from "react-qr-code";

function PartnerURLQRSharing() {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(true);
  const [url, setUrl] = useState("");
  const qrRef: any = useRef();

  useEffect(() => {
    const fetchPartnerData = async () => {
      const client = new clientConfig();
      const partnerId = await userProfileData();
      client
        .query({
          query: GetPartner,
          variables: {
            PartnerId: partnerId,
          },
        })
        .then((res: any) => {
          console.log(
            window.origin +
              "/signup?ReferralCode=" +
              res.data.GetPartner.ReferralCode
          );
          setUrl(
            window.origin +
              "/signup?ReferralCode=" +
              res.data.GetPartner.ReferralCode
          );
          setShowSpinner(false);
        });
    };
    fetchPartnerData();
  }, []);

  const copyURL = (e: any) => {
    e.preventDefault();

    var copyText: any = document.querySelector("#URLData");

    copyText.select();
    document.execCommand("copy");
  };

  const downloadQR = (e: any) => {
    e.preventDefault();
    // let canvas = qrRef.current.querySelector("canvas");
    // let image = canvas.toDataURL("image/png");
    // let anchor = document.createElement("a");
    // anchor.href = image;
    // anchor.download = `qr-code.png`;
    // document.body.appendChild(anchor);
    // anchor.click();
    // document.body.removeChild(anchor);
    // setUrl("");

    const canvas: any = document.createElement("canvas");
    const svg: any = qrRef.current.querySelector("svg");
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
    const w: any = parseInt(svg.getAttribute("width"));
    const h: any = parseInt(svg.getAttribute("height"));
    const img_to_download = document.createElement("img");
    img_to_download.src = "data:image/svg+xml;base64," + base64doc;
    console.log(w, h);
    img_to_download.onload = function () {
      console.log("img loaded");
      canvas.setAttribute("width", w);
      canvas.setAttribute("height", h);
      const context: any = canvas.getContext("2d");
      //context.clearRect(0, 0, w, h);
      context.drawImage(img_to_download, 0, 0, w, h);
      const dataURL = canvas.toDataURL("image/jpg");
      const windowval: any = window;
      if (windowval.navigator.msSaveBlob) {
        windowval.navigator.msSaveBlob(canvas.msToBlob(), "QRcode.jpg");
        e.preventDefault();
      } else {
        const a = document.createElement("a");
        const my_evt = new MouseEvent("click");
        a.download = "QRcode.jpg";
        a.href = dataURL;
        a.dispatchEvent(my_evt);
      }
      //canvas.parentNode.removeChild(canvas);
    };
  };

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          {showSpinner ? (
            <Loading />
          ) : (
            <Col lg={10} className="content-box py-5  px-lg-5">
              <Breadcrumb className="customBreadcrumb mt-0">
                <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>
                  Account Settings
                </Breadcrumb.Item>

                <Breadcrumb.Item active>
                  Easy Sharing URL and QR code
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row className="mb-3">
                <Col lg={12}>
                  <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                    Easy Sharing URL and QR code
                  </h4>
                  <h6 className="text-main-dark ff-Montserrat fw-bold mb-3">
                    Share this easy QR code and URL with your customers via
                    email or printed media
                  </h6>
                </Col>
              </Row>

              <Form>
                <Row className="mb-3">
                  <Col md={6}>
                    <FormGroup className="mb-3" id="partneruUrl">
                      <FormLabel>Unique URL</FormLabel>
                      <Form.Control
                        required
                        type="text"
                        value={url}
                        readOnly
                        placeholder="URL"
                        id="URLData"
                      />
                    </FormGroup>
                    <button
                      onClick={(e) => {
                        copyURL(e);
                      }}
                      className="btn-continue"
                    >
                      Copy URL
                    </button>
                  </Col>
                  <Col md={6} style={{display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: 64,
                        width: "100%",
                      }}
                      ref={qrRef}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                          border:"2px solid"
                        }}
                        value={url}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <button
                      onClick={(e) => {
                        downloadQR(e);
                      }}
                      className="btn-continue"
                    >
                      Download JPG
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default PartnerURLQRSharing;
