import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import Loading from "../../Common/Loading";
import { userProfileData } from "../../Common/profileInfo";
import { InitiateEnrollment } from "../../queries/InitiateEnrollment";
import { Provider, TaxPreparationData, initialTaxPreparationData } from "./TaxPreparationServiceFormContext";
import FilingTypeForm from "./FilingTypeForm";
import TaxPrepServiceSideBar from "./TaxPrepServiceSideBar";
import IncomeForm from "./IncomeForm";
import BusinessQuestionnaireForm from "./BusinessQuestionnaireForm";
import ServiceQuoteForm from "./ServiceQuoteForm";
import ProfileInformation from "./ProfileInformation";
import ServiceAgreement from "./ServiceAgreement";
import Signature from "./signature";
import BillingInformation from "./BillingInformation";
import EnrollCompletedPage from "./EnrollCompletedPage";
import PaymentDetails from "./PaymentDetails";
import { GetTaxReturn } from "../../queries/GetTaxReturn";
import ApplicationReview from "./ApplicationReview";
import useEnrollmentStore from "../../store/EnrollmentStore";


const renderStep = (step: number) => {
  switch (step) {
    case 0:
      return <FilingTypeForm />;
    case 1:
      return <IncomeForm />;
    case 2:
      return <BusinessQuestionnaireForm />;
    case 3:
      return <ServiceQuoteForm />;
    case 4:
      return <ProfileInformation />;
    case 5:
      return <ServiceAgreement />;
    case 6:
      return <Signature />;
    case 7:
      return <BillingInformation />;
    case 8:
      return <PaymentDetails />;
    case 9:
      return <EnrollCompletedPage />;
    case 10:
        return <Loading />;
    case 11:
      return <ApplicationReview />;
    default:
      return null;
  }
};

const TaxPreparationServiceForm = () => {
  const [currentStep, setCurrentStep] = useState(10);
  const [pageName, setPageName] = useState('');
  const [taxPreparationData, setTaxPreparationData] = useState<TaxPreparationData>(initialTaxPreparationData);
  const [enrollmentId, setEnrollmentId] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [nextFormId, setNextFormId] = useState("");
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [taxReturnId, setTaxReturnId] = useState("");
  const [completedPages, setCompletedPages] = useState<string[]>([]);
  const [quotePrice, setQuotePrice] = useState('0');
  const [quotePriceUpdated, setQuotePriceUpdated] = useState(false);
  const { taxPrepEnrollment, setTaxPrepEnrollment } = useEnrollmentStore();
  
  const client = new clientConfig();
  const navigate = useNavigate();

  useEffect(() => {
    InitiateOrganicEnrollment();
  }, []);

  const InitiateOrganicEnrollment = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();

    /*const getTaxReturnVariables = {
      EnrollmentId: enrollmentId
    };
    try{ 
      const res: any = await client.query({ query: GetTaxReturn, variables: getTaxReturnVariables });
      if(res.data.GetTaxReturn){
        setQuoteId(res.data.GetTaxReturn?.TaxReturn?.QuoteId);  
        setTaxReturnId(res.data.GetTaxReturn?.TaxPrepFilingType?.TaxReturnId);
      }
    }catch(e){}
    */
    const variables = {
      profileId: profileId,
      type: "TaxPrepNetwork",
    };
    client
      .mutate({ mutation: InitiateEnrollment, variables: variables })
      .then(async (res: any) => {
        if (res.data.InitiateEnrollment.Id) {
          const getTaxReturnVariables = {
            ProfileId: profileId,
            EnrollmentId: res.data.InitiateEnrollment.Id
          };
          setNextFormId(res.data.InitiateEnrollment.NextForm?.Id);
          setS3Bucket(res.data.InitiateEnrollment.NextForm?.S3Bucket);
          setS3Key(res.data.InitiateEnrollment.NextForm?.S3Key);
          try{
            const res: any = await client.query({ query: GetTaxReturn, variables: getTaxReturnVariables });
            if(res.data.GetTaxReturn){
              setQuoteId(res.data.GetTaxReturn?.TaxReturn?.QuoteId);  
              setTaxReturnId(res.data.GetTaxReturn?.TaxPrepFilingType?.TaxReturnId);
              setQuotePrice(res.data.GetTaxReturn?.TaxPrepQuote?.QuotePrice?res.data.GetTaxReturn?.TaxPrepQuote?.QuotePrice:'0');
              setTaxPreparationData({...taxPreparationData, jointFiling: res.data.GetTaxReturn?.TaxPrepFilingType?.Status});
            }
          }catch(e){}

          setEnrollmentId(res.data.InitiateEnrollment.Id);
          nextEnrollmentAction(
            res.data.InitiateEnrollment?.NextEnrollmentAction
          );

          
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        navigate("/dashboard");
        setCurrentStep(0);
      });
  };

  const nextEnrollmentAction = (nextAction: string) => {
    console.log(nextAction);
    if (nextAction === "CompleteTaxInformation") {
      completedPagesHandler('filingType');
      setCurrentStep(0);
      setPageName('filingType');
    }else if (nextAction === 'AcceptQuote') {
      completedPagesHandler('filingType');
      completedPagesHandler('incomeForm1');
      completedPagesHandler('serviceQuote');
      setCurrentStep(3);
      setPageName('serviceQuote');
    }else if (nextAction === 'CompleteInformation') {
      completedPagesHandler('filingType');
      completedPagesHandler('incomeForm1');
      completedPagesHandler('serviceAgreement1');
      setCurrentStep(4);
      setPageName('serviceAgreement1');
    }else if(nextAction === 'InitiateServiceAgreement') {
      completedPagesHandler('filingType');
      completedPagesHandler('incomeForm1');
      completedPagesHandler('serviceQuote');
      completedPagesHandler('serviceAgreement1');
      setCurrentStep(5);
      setPageName('serviceAgreement2');
    }else if(nextAction === 'CompletePayment') {
      completedPagesHandler('filingType');
      completedPagesHandler('incomeForm1');
      completedPagesHandler('serviceQuote');
      completedPagesHandler('serviceAgreement1');
      completedPagesHandler('billingInfo');
      setCurrentStep(7);
      setPageName('billingInfo');
    }else if(nextAction === 'CompleteServiceAgreement') {
      completedPagesHandler('filingType');
      completedPagesHandler('incomeForm1');
      completedPagesHandler('serviceQuote');
      completedPagesHandler('serviceAgreement1');
      completedPagesHandler('billingInfo');
      setTaxPrepEnrollment({
        taxPrepEnrollmentCompleted: taxPrepEnrollment?.taxPrepEnrollmentCompleted,
        showTaxPrepNextStepsModal: taxPrepEnrollment?.showTaxPrepNextStepsModal,
        paymentCompleted: true
      })
      setCurrentStep(11);
      setPageName('applicationReview');
    }
    if(!nextAction){
      navigate("/dashboard");
    }
  };

  const completedPagesHandler = (pageName:string) => {
    if(!completedPages.includes(pageName)){
      setCompletedPages(prevState => ([...prevState, pageName]));
    }
  }
  
  const navigateToPage = (pageName: string) => {
    setPageName(pageName);
    if (pageName === "filingType") {
      setCurrentStep(0);
    }
    if (pageName === "incomeForm1") {
      setCurrentStep(1);
    }
    if (pageName === "incomeForm2") {
      setCurrentStep(2);
    }
    if (pageName === "serviceQuote") {
      setCurrentStep(3);
    }
    if (pageName === "serviceAgreement1") {
      setCurrentStep(4);
    }
    if (pageName === "serviceAgreement2") {
      setCurrentStep(5);
    }
    if (pageName === "serviceAgreementSign") {
      setCurrentStep(6);
    }
    if (pageName === "billingInfo") {
      setCurrentStep(7);
    }
    if (pageName === "paymentDetails") {
      setCurrentStep(8);
    }
    if (pageName === "completed") {
      setCurrentStep(9);
    }
    if (pageName === "applicationReview") {
      setCurrentStep(11);
    }
    if(!pageName){
      navigate("/dashboard");
    }
  };

  return (
    <Provider
      value={{
        navigateToPage,
        pageName,
        setPageName,
        taxPreparationData,
        setTaxPreparationData,
        enrollmentId,
        setEnrollmentId,
        nextEnrollmentAction,
        quoteId,
        setQuoteId,
        taxReturnId,
        setTaxReturnId,
        nextFormId,
        setNextFormId,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
        completedPages,
        completedPagesHandler,
        quotePrice,
        setQuotePrice,
        quotePriceUpdated,
        setQuotePriceUpdated
      }}
    >
      <main>
        <TaxPrepServiceSideBar />
        {renderStep(currentStep)}
      </main>
    </Provider>
  );
};
export default TaxPreparationServiceForm;
