import React, { useContext, useState, useEffect } from "react";

import "../../pages/navbar.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Message from "../../components/Message";

const Col1Languages = ["English", "Spanish", "Chinese", "Vietnamese"];

const Col2Languages = ["Korean", "Hindi", "French", "Other"];

const FORM_ERROR = {
  language: "",
};

function Languages({ taxProfessional, setTaxProfessional, submitted, setLanguageValidated }: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional?.Languages?.length >= 1) {
        error.language = "";
      } else {
        error.language = "Languages can not be empty";
      }

      setFromErros(error);
      const isAnyError = taxProfessional?.Languages?.length >= 1;
      setLanguageValidated(isAnyError? true: false)
    };

    validateForm();
  }, [taxProfessional]);

  const onChange = (event: any) => {
    const language = [...taxProfessional.Languages];
    const index = language.indexOf(event.target.name);

    if (event.target.checked) {
      language.push(event.target.name);
    } else if (!event.target.checked) {
      language.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ Languages: [...language] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Languages You Speak
          </h6>
        </div>

        <Form style={{ marginLeft: 10 }} className="mt-1">
          <Row md={6}>
            {Col1Languages.map((language: string) => {
              const checked = taxProfessional?.Languages?.includes(language);
              return (
                <Form.Check
                  onChange={onChange}
                  name={language}
                  type="checkbox"
                  label={language}
                  checked={checked}
                />
              );
            })}
          </Row>
          <Row md={6}>
            {Col2Languages.map((language: string) => {
              const checked = taxProfessional?.Languages?.includes(language);
              return (
                <Form.Check
                  onChange={onChange}
                  name={language}
                  type="checkbox"
                  label={language}
                  checked={checked}
                />
              );
            })}
          </Row>
          {submitted && formErrors.language && (
            <Message message={formErrors.language} type="error"></Message>
          )}
        </Form>
      </Col>
    </Row>
  );
}

export default Languages;
