import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import appPending from "../../assets/application-pending.svg";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AppProgressBar from "../../Common/progressBar";
import TaxPreparationServiceFormContext from "./TaxPreparationServiceFormContext";
import { clientConfig } from "../../awsClientConfig";
import { GetTaxReturnProfile } from "../../queries/GetTaxReturnProfile";
import { userProfileData } from "../../Common/profileInfo";

function ApplicationReview() {

  const { enrollmentId } = useContext(TaxPreparationServiceFormContext);
  const navigate = useNavigate();
  const [spouseProfileInfo, setSpouseProfileInfo] = useState<any>();
  const client = new clientConfig();
  
  useEffect(() => {
    getTaxReturnProfile();
  },[]);

  const getTaxReturnProfile = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();
    //setIsLoading(true);
    const variables = {
      ProfileId: profileId,
      EnrollmentId: enrollmentId
    };
    try{ 
      const res: any = await client.query({ query: GetTaxReturnProfile, variables: variables });
      if(res.data.GetTaxReturnProfile && res.data.GetTaxReturnProfile.TaxReturnProfile 
            && res.data.GetTaxReturnProfile.TaxReturnProfile.length > 0){
        setSpouseProfileInfo(res.data.GetTaxReturnProfile?.TaxReturnProfile.find((taxProfile:any) => taxProfile.Spouse));
      }
    } catch (e) {
      console.log("error:", e);
    }
  };

  const onLogout = () => {
    Auth.signOut().then((res) => {
      navigate("/login");
    });
  };


  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
          <AppProgressBar progressPercent={80} />
            <Row className="px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="content-box-heading mb-3 fs-4">
                  {`Your Application is Pending for ${spouseProfileInfo?spouseProfileInfo.FirstName:''} ${spouseProfileInfo?spouseProfileInfo.LastName:''} Signature`}
                </h1>
                <p className="content-text">
                  {`Thank you for applying for our Tax Preparation Service. We've sent an email invitation to ${spouseProfileInfo?spouseProfileInfo.Email:''} with next steps. Tax Preparation is on hold until we receive their e-signature. We'll update you upon completion.`}
                </p>

                <div className="d-flex mt-5 align-items-center">
                  
                    <button onClick={onLogout} className="btn-continue">
                      Log Out
                    </button>
                  
                </div>
              </Col>
              <Col lg={5}>
                <img src={appPending} alt="Tax-report" className="img-fluid" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ApplicationReview;
