import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GetProfile } from "../queries/getProfile";
import { clientConfig } from "../awsClientConfig";
import { GetProtectionPlanEligibility } from "../queries/GetProtectionPlanEligibility";
import Loading from "./Loading";

const LoginLayout = () => {
  const [loading, setLoading] = useState(false);
  const client = new clientConfig();
  let location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
   if (process.env.REACT_APP_MAINTENANCE_MODE_ENABLE === "true") {
     if (location.search === "?admin/optimataxrelief") {
       userAuthCheck();
     } else {
       navigate("/maintenance");
     }
   }
    userAuthCheck();
  }, []);

  const userAuthCheck = () => {
    setLoading(true);
    if (window.location.pathname === "/signup/partner") {
      setLoading(false);
      navigate("/signup/partner");
      return;
    }
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res.attributes["custom:Id"]) {
          if (res.attributes["custom:Group"] === "profile") {
            client
              .query({
                query: GetProfile,
                variables: { profileId: res.attributes["custom:Id"] },
              })
              .then((res: any) => {
                if (res.data.GetProfile !== null) {
                  if (res.data.GetProfile.EnrollmentSource === "Referral") {
                    client
                      .query({
                        query: GetProtectionPlanEligibility,
                        variables: { ProfileId: res.data.GetProfile.Id },
                      })
                      .then((res: any) => {
                        if (res.data.GetProtectionPlanEligibility !== null) {
                          if (
                            res.data.GetProtectionPlanEligibility
                              ?.EnrollmentInitiated
                          ) {
                            navigate("/dashboard");
                            return;
                          } else {
                            navigate("/res-protection-plan");
                            return;
                          }
                        }
                      });
                  }
                  navigate("/dashboard");
                }
              });
          } else navigate("/partner-enrollment");
          return;
        }
        if (res.attributes["custom:Group"] === "profile")
          navigate("/profileClient");
        else navigate("/profilePartners");
        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
        }
      });
  };

  return loading ? <Loading /> : <Outlet />;
};

export default LoginLayout;
