import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import "./styles.css";
import {ReactComponent as HomeIcon} from "../../assets/home-icon.svg";
import {ReactComponent as DirectoryIcon} from "../../assets/directory-icon.svg";
import {ReactComponent as FileIcon } from "../../assets/file-icon.svg";
import {ReactComponent as MailIcon} from "../../assets/mail-icon.svg";

import {ReactComponent as DollarIcon } from "../../assets/dollar-icon.svg";
import {ReactComponent as CashIcon} from "../../assets/cash-icon.svg";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { QualifyEnrollmentRequest } from "../../queries/QualifyEnrollmentRequest";
import { clientConfig } from "../../awsClientConfig";
import { useNavigate } from "react-router-dom";
import AppProgressBar from "../../Common/progressBar";
import { AnimatePresence, motion } from 'framer-motion';
import { motionContainer } from "./index";

function ProtectionPlanEligibility({ progressPercent = 0 }) {
  const { next, enrollmentId, setRecommandedPlan, setUnavailableProtectionPlan } = useContext(
    OrganicClientFormContext
  );
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({
    BusinessOwner: false,
    TaxDeductionMaximization: false,
    SelfEmployee: false,
    StockSeller: false,
    W2Employee: false,
    HomeOwner: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSelectType = (type: string, isSelected: boolean) => {
    setSelectedOptions({ ...selectedOptions, [type]: !isSelected });
  };
  useEffect(() => {
    if (
      selectedOptions.BusinessOwner ||
      selectedOptions.HomeOwner ||
      selectedOptions.SelfEmployee ||
      selectedOptions.StockSeller ||
      selectedOptions.TaxDeductionMaximization ||
      selectedOptions.W2Employee
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [selectedOptions]);
  const client = new clientConfig();
  const OnQualifySubmit = () => {
    setShowButton(true);
    const variables = {
      enrolmentID: enrollmentId,
      answeredQuestions: [
        {
          QuestionKey: "BusinessOwner",
          AnswerKey: selectedOptions.BusinessOwner ? "Yes" : "No",
        },
        {
          QuestionKey: "HomeOwner",
          AnswerKey: selectedOptions.HomeOwner ? "Yes" : "No",
        },
        {
          QuestionKey: "SelfEmployee",
          AnswerKey: selectedOptions.SelfEmployee ? "Yes" : "No",
        },
        {
          QuestionKey: "StockSeller",
          AnswerKey: selectedOptions.StockSeller ? "Yes" : "No",
        },
        {
          QuestionKey: "TaxDeductionMaximization",
          AnswerKey: selectedOptions.TaxDeductionMaximization ? "Yes" : "No",
        },
        {
          QuestionKey: "W2Employee",
          AnswerKey: selectedOptions.W2Employee ? "Yes" : "No",
        },
      ],

    };

    client
      .mutate({ mutation: QualifyEnrollmentRequest, variables: variables })
      .then((res: any) => {
        if (res.data.QualifyEnrollmentRequest.Result === "Qualify") {
          setRecommandedPlan(
            res.data.QualifyEnrollmentRequest?.RecommendedProtectionPlan
          );
          if (res.data.QualifyEnrollmentRequest?.UnavailableProtectionPlan) {
           setUnavailableProtectionPlan(res.data.QualifyEnrollmentRequest?.UnavailableProtectionPlan);
          }
          setShowButton(false);
          next();
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        setShowButton(false);
        navigate("/contact-service");
      });
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
             <AppProgressBar progressPercent={5} />
              <Row className="justify-content-center">
                <Col md={12}>
                  <h1 className="content-box-heading mb-3">
                   {'Please select the scenario that apply to your tax situation'}
                  </h1>
                  <p className="content-text" style={{fontSize: '14px'}}>
                    With our tax protection plan, you can rest easy knowing your
                    most valuable assets are secure.
                  </p>
                  <Row className="justify-content-around my-4" style={{rowGap: "15px"}}>
                    <p className="content-text text-center mt-4 select-text fw-bold ff-Montserrat" style={{fontSize: '1rem'}}>
                      Please select all of the scenarios that apply to your current tax situation:
                    </p>
                    <Col
                      md={2}
                      className={
                        selectedOptions.BusinessOwner
                          ? "selectedCard tax-situation-tile"
                          : "tile-box tax-situation-tile"
                      }
                      onClick={() =>
                        onSelectType(
                          "BusinessOwner",
                          selectedOptions.BusinessOwner
                        )
                      }
                    >
                      <MailIcon />
                      <label className="ff-Montserrat" style={{fontWeight: "500"}}>I Own a Small Business</label>
                    </Col>

                    <Col
                      md={1}
                      className={
                        selectedOptions.SelfEmployee
                          ? "selectedCard tax-situation-tile ms-3"
                          : "tile-box tax-situation-tile ms-3"
                      }
                      onClick={() =>
                        onSelectType(
                          "SelfEmployee",
                          selectedOptions.SelfEmployee
                        )
                      }
                    >
                      <DirectoryIcon />
                      <p className="ff-Montserrat" style={{fontWeight: "500", fontSize: "0.7rem"}}>I Am Self-Employed / <br /> Ind. Contractor</p>
                    </Col>
                    <Col
                      md={2}
                      className={
                        selectedOptions.W2Employee
                            ? "selectedCard tax-situation-tile ms-3"
                            : "tile-box tax-situation-tile ms-3"
                      }
                      onClick={() =>
                        onSelectType("W2Employee", selectedOptions.W2Employee)
                      }
                    >
                      <FileIcon />
                      <label className="ff-Montserrat" style={{fontWeight: "500"}}>I Received a W2</label>
                    </Col>
                    <Col
                      md={2}
                      className={
                        selectedOptions.HomeOwner
                            ? "selectedCard tax-situation-tile ms-3"
                            : "tile-box tax-situation-tile ms-3"
                      }
                      onClick={() =>
                        onSelectType("HomeOwner", selectedOptions.HomeOwner)
                      }
                    >
                      <HomeIcon />
                      <p className="ff-Montserrat" style={{fontWeight: "500"}}>I Own <br /> A Home</p>
                    </Col>
                    <Col
                      md={2}
                      className={
                        selectedOptions.StockSeller
                          ? "selectedCard tax-situation-tile ms-3"
                          : "tile-box tax-situation-tile ms-3"
                      }
                      onClick={() =>
                        onSelectType("StockSeller", selectedOptions.StockSeller)
                      }
                    >
                      <DollarIcon/>
                      <label className="ff-Montserrat" style={{fontWeight: "500", fontSize: "0.7rem"}}>
                        I Sold Stocks, Crypto or Own Rental Property
                      </label>
                    </Col>
                    <Col
                      md={2}
                      className={
                        selectedOptions.TaxDeductionMaximization
                          ? "selectedCard tax-situation-tile ms-3"
                          : "tile-box tax-situation-tile ms-3"
                      }
                      onClick={() =>
                        onSelectType(
                          "TaxDeductionMaximization",
                          selectedOptions.TaxDeductionMaximization
                        )
                      }
                    >
                      <CashIcon />
                      <label className="ff-Montserrat" style={{fontWeight: "500", fontSize: "0.7rem"}}>I Want to Maximize Tax Deductions</label>
                    </Col>
                    <div className="d-flex justify-content-between mt-5 align-items-center">
                      <span onClick={handleShow}>
                        {" "}
                        <span className="letsdolater">Questions?</span>
                      </span>

                      <button
                        disabled={showButton}
                        className={showButton ?
                        "btn-disable fw-bold" :
                        "btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={OnQualifySubmit}>
                       Continue
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h5 className="content-box-heading mt-0">Determine if You're Self-employed:</h5>
          <p className="content-text">
            You are self-employed if you have one or more sources of
            self-employed income:
          </p>
          <ul className="general-guide content-text">
            <li>Independent contractor</li>
            <li>Gig worker (ride-share or delivery driver) </li>
            <li>Freelancer </li>
            <li>Consultant </li>
            <li>Small business owner </li>
            <li>Sole proprietor </li>
            <li>Entrepreneur </li>
            <li>1099-NEC</li>
            <li>1099-K </li>
            <li>Sale of goods/services </li>
            <li>Small Business Income</li>
          </ul>
        </Modal.Body>
      </Modal>
    </motion.div>
  );
}
export default ProtectionPlanEligibility;
