import React, {useEffect, useState} from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { formatZipcode } from "../../functions/misc";
import { useNavigate } from "react-router-dom";
import { US_STATES } from "../../Common/States";
import { userProfileData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { GetProfile } from "../../queries/getProfile";
import { CreatePaymentMethod } from "../../queries/CreatePaymentMethod";

function AddNewPaymentMethod() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [addPayment, setAddPayment] = useState(false);
  const windowValue: any = window;
  const environmentKey = process.env.REACT_APP_ENVIRONMENTKEY;
  const options = {
    company_name: "Optima Tax Relief",
    sidebar_top_description: "Please Provide Your Credit Card Information",
    sidebar_bottom_description: ``,
    amount: "",
    submit_label:"Add Card"
  };
  const client = new clientConfig();

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitBillingAddress();
    }

    setValidated(true);
  };

  const enableButton = () => {
    windowValue.SpreedlyExpress.openView();
  };

  const submitBillingAddress = () => {
    setAddPayment(true);
    windowValue.SpreedlyExpress.onInit(enableButton);

    const initializeSprredly = async () => {
      const profileId = await userProfileData();
      client
        .query({
          query: GetProfile,
          variables: { profileId: profileId },
        })
        .then((res: any) => {
          if (res.data.GetProfile !== null) {
            windowValue.SpreedlyExpress.init(environmentKey, options, {
              address1: address.streetAddress1,
              address2: address.streetAddress2,
              city: address.city,
              state: address.state,
              zip: address.zipCode,
              country: "US",
              email: res.data.GetProfile?.Email,
              // data: "something",
              phone_number: res.data.GetProfile?.Phone,
            });
          }
          windowValue.Spreedly.on('ready', function() {
            windowValue.Spreedly.setPlaceholder('number', '');
          });
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
    };
    initializeSprredly();
    windowValue.SpreedlyExpress.onPaymentMethod(handlePaymentToken);
  };

  const handlePaymentToken = async (token: any, formdata: any) => {
    console.log(token, formdata);
    const profileId = await userProfileData();

    const variables = {
      ProfileId: profileId,
      PaymentMethodToken: token,
      Domain: "ProtectionPlan",
    };
    client
      .mutate({ mutation: CreatePaymentMethod, variables: variables })
      .then((res: any) => {
        if (res.data.CreatePaymentMethod.Id) {
          // navigate("/account-settings-payment");
          window.location.href = "/account-settings-payment"
        }
      })
      .catch((error: any) => {});

    // windowValue.SpreedlyExpress.closeView();
  };

  window.onbeforeunload = function (e) {
    windowValue.SpreedlyExpress.unload();
  };

  window.onpopstate = () => {
    windowValue.SpreedlyExpress.unload();
  };

  let doc: any = document;
  doc.getElementById("spreedly-close-button")?.addEventListener("click", () => {
    closeview();
  });
  const closeview = () => {
    navigate("/account-settings-payment");
    windowValue.SpreedlyExpress.unload();
  };
  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings")}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => navigate("/account-settings-payment")}
              >
                Payment Information
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Add New Payment Method</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col lg={8}>
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  New Billing Address
                </h4>
              </Col>
            </Row>
            {!addPayment && (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group as={Col} controlId="validationCustom01">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress1}
                        name="streetAddress1"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Street Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress2}
                        name="streetAddress2"
                        onChange={(e) => setAddressData(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group as={Col} controlId="validationCustom02">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.city}
                        name="city"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group as={Col} controlId="validationCustom03">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={address.state}
                        name="state"
                        onChange={(e) => setAddressData(e)}
                        required
                      >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group as={Col} controlId="validationCustom04">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.zipCode}
                        name="zipCode"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid ZIP Code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex flex-column justify-content-center mt-5 align-items-center">
                  <button type="submit" className="btn-continue" style={{width: "auto"}}>
                    Save Billing Address & Continue to Payment Method
                  </button>
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddNewPaymentMethod;
