import gql from "graphql-tag";

export const UpdateOrganizationAddress = gql`
  mutation UpdateOrganizationAddress(
    $Id: ID!
    $City: String!
    $State: UsState!
    $StreetAddress1: String!
    $StreetAddress2: String
    $OrganizationAddressTypes: [OrganizationAddressTypeInput!]!
    $ZipCode: String!
  ) {
    UpdateOrganizationAddress(
      Input: {
        Id: $Id,
        City: $City
        State: $State
        StreetAddress1: $StreetAddress1
        StreetAddress2: $StreetAddress2
        OrganizationAddressTypes: $OrganizationAddressTypes
        ZipCode: $ZipCode
      }
    ) {
      City
      CreatedAt
      Id
    }
  }
`;
