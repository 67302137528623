import gql from "graphql-tag";

export const CreatePaymentMethod = gql`
  mutation CreatePaymentMethod(
    $ProfileId: ID!
    $PaymentMethodToken: ID!
    $Domain: Domain!
  ) {
    CreatePaymentMethod(
      Input: {
        Domain: $Domain
        PaymentMethodToken: $PaymentMethodToken
        ProfileId: $ProfileId
      }
    ) {
      AchEntityType
      AchNumber
      AchRoutingNumber
      AchType
      Active
      CcExpMonth
      CcExpYear
      CcLastFourDigits
      CcType
      City
      CreatedAt
      Default
      Id
      Name
      State
      StreetAddress1
      StreetAddress2
      Token
      Type
      UpdatedAt
      Vendor
      ZipCode
    }
  }
`;
