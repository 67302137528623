import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import AppProgressBar from "../../Common/progressBar";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { GetProtectionPlans } from "../../queries/GetProtectionPlans";
import { clientConfig } from "../../awsClientConfig";
import { UpdateEnrollmentProtectionPlan } from "../../queries/UpdateEnrollmentProtectionPlan";
import Loading from "../../Common/Loading";
import { formatPlanDescription } from "../../functions/misc";
import Circle from '../../assets/Circle.svg';
import CircleCheck from '../../assets/circle-check-fill.svg';
import useEnrollmentStore, {
  EnrollmentStatus,
  ProtectionPlanName,
  SubscriptionStatus
} from "../../store/EnrollmentStore";
import { AnimatePresence, motion } from "framer-motion";
import { motionContainer } from "./index";


function ProtectionPlanPricing({ progressPercent = 0 }) {
  const { next, enrollmentId, SetFinalPlan, recommandedPlan, unavailableProtectionPlan } = useContext(
    OrganicClientFormContext
  );
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(recommandedPlan.Name);
  const [showButton, setShowButton] = useState(false);
  const { setProtectionPlanEnrollment } = useEnrollmentStore();
  const client = new clientConfig();

  useEffect(() => {
    fetchPlans();
  }, []);
  const fetchPlans = async () => {
    client
      .query({
        query: GetProtectionPlans,
      })
      .then((res: any) => {
        setPlans(res.data.GetProtectionPlans);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const selectPlan = (plan: any, event: any) => {
    event.stopPropagation();
    setShowButton(true);
    const variables = {
      EnrollmentId: enrollmentId,
      ProtectionPlanId: plan?.Id,
    };

    client
      .mutate({
        mutation: UpdateEnrollmentProtectionPlan,
        variables: variables,
      })
      .then((res: any) => {
        if (res.data.UpdateEnrollmentProtectionPlan) {
          setProtectionPlanEnrollment({
            subscriptionPlan: plan.Name,
            subscriptionStatus: '',
            enrollmentStatus: ''
          });
          SetFinalPlan(plan);
          setShowButton(false);
          next();
        }
      })
      .catch((error: any) => {
        setShowButton(false);
      });
  };
  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <motion.div key={showButton.toString()} {...motionContainer}>
        <Row className="row justify-content-center">
          <Col md={12} className="content-box pt-5 pb-5 px-lg-5">
            {showButton ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading mb-2">
                  Protection Plan Pricing
                </h1>
                <p className="content-text">
                  Based on your current tax situation we recommend our{" "}
                  {recommandedPlan.Name} Plan.
                </p>
                <Row className="mt-5 justify-content-center row-flex align-items-strech">
                  {plans?.map((plan: any) => {
                    return (
                      <>
                        {plan?.Name !== "ProtectionPartnerMonitoring" && (
                          <Col md={4}>
                            <Card
                              className={plan?.Id === recommandedPlan.Id ? "PriceCard" : "PriceCard  bg-light"}
                              onClick={() => {
                                setCurrentPlan(plan?.Name);
                              }}
                            >
                              {plan?.Id === recommandedPlan.Id && (
                                <div className="ribbon-wrapper">
                                  <div className="ribbon">We Recommend</div>
                                </div>
                              )}

                              <Card.Header className={plan?.Id === recommandedPlan.Id ? "bg-white border-0 ms-auto" : "bg-light border-0 ms-auto"}>
                               {unavailableProtectionPlan?.Id !== plan?.Id &&
                                <img
                                  src={plan?.Name === currentPlan ? CircleCheck : Circle}
                                  alt="circle"
                                />
                              }
                              </Card.Header>
                              <Card.Body>
                                <div className="text-center">
                                  <label>
                                    {(plan?.Name).toUpperCase()} PLAN
                                  </label>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <h1 className="ff-Montserrat my-2 material-blue fw-normal fs-1">
                                      <strong>${plan?.MonthlyPrice}</strong>/mo
                                    </h1>
                                    {plan?.ChargeSalesTax && <h3>+ tax</h3>}
                                  </div>
                                  <span className="content-text"
                                    dangerouslySetInnerHTML={{
                                      __html: formatPlanDescription(
                                        plan?.Description,
                                        plan?.Name
                                      ),
                                    }}
                                  ></span>
                                </div>
                              </Card.Body>
                              <Card.Footer className={plan?.Id === recommandedPlan.Id ? "text-center bg-white border-0" : "text-center bg-light border-0"}
                              style={{marginBottom: '2rem'}}>
                               {unavailableProtectionPlan?.Id === plan?.Id
                                ?
                                <button disabled={true} className="btn-disable">
                                  Not Available
                                </button>
                               :
                                <button
                                 onClick={(event) => selectPlan(plan, event)}
                                 className={
                                  plan?.Name === currentPlan
                                   ? "btn-continue"
                                   : "btn-continue-outline"
                                 }
                                 disabled={plan?.Name !== currentPlan && showButton}>
                                  Continue
                                </button>
                               }
                              </Card.Footer>
                            </Card>
                          </Col>
                        )}
                      </>
                    );
                  })}

                  <Col>
                    <Table striped className="pricing-table my-5">
                      <thead>
                        <tr>
                          <th><h3 className="content-box-heading text-left ff-Montserrat mt-3 fs-4">
                              Plan Comparison
                            </h3>
                          </th>
                          <th className="content-text ff-Montserrat fw-bold fs-6" style={{textAlign: 'center', width: '8%'}}>Basic</th>
                          <th className="content-text ff-Montserrat fw-bold fs-6" style={{textAlign: 'center', width: '8%'}}>Standard</th>
                          <th className="content-text ff-Montserrat fw-bold fs-6" style={{textAlign: 'center', width: '9%'}}>Professional</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="content-text fs-6">$10 Million Lifetime Audit Defence</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Comprehensive Tax Risk Monitoring</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Filing Fraud Protection and Remediation</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">IRS Balance Monitoring</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Discounted Tax Resolution Fees</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">IRS Notice Analysis</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Live Phone Support</td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Professional Tax Return Preparation</td>
                          <td></td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">$10,000 Tax Preparation Accuracy Guarantee</td>
                          <td></td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">
                            Discounted Corporate or Partnership Return
                            Preparation
                          </td>
                          <td></td>
                          <td></td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                        <tr>
                          <td className="content-text fs-6">Quarterly Estimated Payment Analysis</td>
                          <td></td>
                          <td></td>
                          <td className="text-primary" style={{textAlign: 'center'}}>
                            <FaCheck />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <p className="fs-8">
                      <i>
                      * Lifetime dependent on being active customer of any Protection Plan. Please review Protection Agreement for details and limitations.
                      </i>
                    </p>
                    <p className="fs-8">
                      <i>
                      † Limited to one personal tax return per year per agreement. Standard Plan is eligible for a wage earner return; Professional Plan is eligible for an independent contractor return.
                      </i>
                    </p>
                    <p className="fs-8">
                      <i>
                      ‡ Applies to tax returns prepared by Optima Tax Relief.
                      </i>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
        </motion.div>
      </Container>
    </AnimatePresence>
  );
}
export default ProtectionPlanPricing;
