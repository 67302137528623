import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTranslation } from "react-i18next";
import { decodeFromBase64, emailIsValid } from "../functions/misc";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import passwordValidator from "password-validator";
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

interface FormError {
  email: string;
  password: string;
  accountType: string;
  partnerCode: string;
}
const SignupPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const UtmSource = searchParams.get("utm_source");
  const Network = searchParams.get("network");
  const UtmCampaign = searchParams.get("utm_campaign");
  const AdGroup = searchParams.get("adgroup");
  const Ad = searchParams.get("ad");
  const ReferralCode = searchParams.get("ReferralCode");
  const ReferenceCode = searchParams.get("reference");

  const { t, i18n } = useTranslation("common");

  const {accountTypeParam} = useParams();
  const [error, setError] = useState<FormError>({
    email: "",
    password: "",
    accountType: "",
    partnerCode: "",
  });
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [accountType, setAccountType] = useState(accountTypeParam === "partner" ? "2" : "1");
  const [partnerCode, setPartnerCode] = useState("");
  const [showPartnerInput, setShowPartnerInput] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const referalAccountTypes = [
    {
      key: "1",
      text: "Client",
    },
  ];
  const organicAccountType = [
    {
      key: "1",
      text: "Client",
    },
    {
      key: "2",
      text: "Partner",
    },
  ];
  const accountTypes = ReferralCode ? referalAccountTypes : organicAccountType
  var schema = new passwordValidator();

  schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has().digits()
    .has().symbols()
    .has().not().spaces() ;
  const createSentenceClient = t("signupPage.createSentence1");
  const createSentencePartner = t("signupPage.createSentence2");
  const [createSentence, setCreateSentence] = useState(accountTypeParam === "partner" ? createSentencePartner : createSentenceClient);
  const [customTitle, setCustomTitle] = useState('');
  const [customBody, setCustomBody] = useState('');
  let location = useLocation();

 useEffect(() => {
   const urlParams = new URLSearchParams(location.search);
   const number = urlParams.get('t');
   if (number) {
     const cleanNumber = number.replace(/\D/g, '');
     if (!t(`customSignUpHeaders.${cleanNumber}.Title`).includes("customSignUpHeaders")) {
       setCustomTitle(t(`customSignUpHeaders.${cleanNumber}.Title`));
       setCustomBody(t(`customSignUpHeaders.${cleanNumber}.Body`));
     }
   }
 }, [location]);

  useEffect(() => {
    if (ReferenceCode !== null) {
      setEmail(decodeFromBase64(ReferenceCode));
    }
  }, [ReferenceCode]);

  useEffect(() => {
    validateFormData(email, password, accountType, partnerCode);
  }, [email, password, accountType, partnerCode]);

  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePartnerCode = (e: any) => {
    setPartnerCode(e.target.value);
  };

  const validateFormData = (
    _email: string,
    _password: string,
    _accountType: string,
    _partnerCode: string
  ) => {
    let _error = error;
    if (!_email) {
      _error["email"] = t("signupPage.error.email.empty");
    } else if (!emailIsValid(_email)) {
      _error["email"] = t("signupPage.error.email.invalid");
    } else {
      _error["email"] = "";
    }
    if (!_password) {
      _error["password"] = t("signupPage.error.password.empty");
    } else {
      if(schema.validate(password)) {
        _error["password"] = "";
      } else {
        _error["password"] = "Password must have minimum of 8 characters and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character";
      }
    }
    if (!_accountType) {
      _error["accountType"] = t("signupPage.error.accountType.empty");
    }
    setError(_error);
    setError({ ...error });
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  const handleInputAccountType = (e: any) => {
    console.log(e.target.value);
    setAccountType(e.target.value);

    if (e.target.value === "2") {
     if (accountTypeParam !== "partner") {
      setShowPartnerInput(true);
     }
      setCreateSentence(createSentencePartner);
    } else {
      setCreateSentence(createSentenceClient);
      setShowPartnerInput(false);
      setPartnerCode("");
      setError((errors) => {
        return {
          ...errors,
          partnerCode: "",
        };
      });
    }
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if(accountType === "1" && (error.email || error.password)) {
      return
    }
    if(accountType === "2" && (error.email || error.password || error.partnerCode)) {
      return
    }
    if (form.checkValidity() === false) {
      return;
    }
    let attributes = {};
    if (accountType === "1") {
      const SignUpParama = {
         ReferralCode,
         UtmSource,
         Network,
         UtmCampaign,
         AdGroup,
         Ad,
      }
      attributes = {
        "custom:Group": "profile",
        "custom:SignUpParameters": JSON.stringify(SignUpParama),
      };
    } else {
      attributes = {
        "custom:Group": "partner",
      };
    }
    setSpinner(true);
    Auth.signUp({
      username: email.toLowerCase(),
      password: password,
      attributes: attributes,
    })
      .then((res) => {
        setSpinner(false);
        navigate(`/verifycode/${email}`);
      })
      .catch((error: any) => {
        setSpinner(false);
        console.log(error);
        const code = error.code;
        const message = error.message;
        switch (code) {
          case "UsernameExistsException":
            setError((errors) => {
              return {
                ...errors,
                email:
                  "There is an account associated with this email address. Please sign in to continue.",
              };
            });
            break;
          case "InvalidPasswordException":
            setError((errors) => {
              return {
                ...errors,
                password: message,
              };
            });
            break;
          case "UserLambdaValidationException":
            if (error.message.includes("UsernameExistsException")) {
              //   setShowSpinner(false);
              setError((errors) => {
                return {
                  ...errors,
                  email:
                    "There is an account associated with this email address. Please sign in to continue.",
                };
              });
            } else if (
              error.message.includes("InvalidRegistrationCodeException")
            ) {
              // Display Invalid Registration Code error.
              // //   setShowSpinner(false);
              setError((errors) => {
                return {
                  ...errors,
                  partnerCode: "Invalid Registration Code.",
                };
              });
            }
            break;
          default:
            return false;
        }
      });
  };

  const motionContainer = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
    exit: { opacity: 0, transition: { duration: 0.5, ease: "easeOut" }}
  };

  return (
    <DefaultLayout>
      <AnimatePresence mode="wait">
      <motion.div key="signUp" variants={motionContainer} initial="hidden" animate="visible">
      <PageBody className="page-body">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <FormTitle>{customTitle ? customTitle : t("signupPage.title")}</FormTitle>
         {customBody ? <p className="content-text" style={{fontSize: "0.9rem"}}>{customBody}</p> : <p className="content-text">{createSentence}</p>}
          <FormGroup className="mb-3 mt-3" id="formBasicEmail">
            <FormLabel style={{opacity: 1}}>{t("signupPage.email")}</FormLabel>
            <Form.Control
              className="input-no-bg"
              required
              type="email"
              value={email}
              onChange={handleInputEmail}
              placeholder="Enter email"
            />
            {validated && error.email && (
              <motion.div key="errorEmail" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                <Message type="error" message={error.email}></Message>
              </motion.div>
            )}
          </FormGroup>
          <FormGroup className="mb-3" id="password">
            <FormLabel style={{fontSize: "12px"}}>{t("signupPage.password")}</FormLabel>
            <div className="position-relative">
              <Form.Control
                className="form-control input-no-bg"
                type={pwdType}
                value={password}
                placeholder="Password"
                required
                onChange={handleInputPassword}
              />
              <FontAwesomeIcon
                onClick={togglePassword}
                className="addOn"
                icon={pwdIcon}
              />
            </div>
            {validated && error.password && (
              <motion.div key="errorPassword" variants={motionContainer} initial="hidden" animate="visible" exit="exit">
                <Message type="error" message={error.password}></Message>
              </motion.div>
            )}
          </FormGroup>
          <FormGroup className="mb-3" id="password">
            <FormLabel>{t("signupPage.accountType")}</FormLabel>
            <div className="position-relative">
              <Form.Select
                className="input-no-bg"
                value={accountType}
                onChange={handleInputAccountType}
              >
                {accountTypes.map((item) => {
                  return (
                    <option value={item.key} key={item.key}>
                      {item.text}
                    </option>
                  );
                })}
              </Form.Select>
              <FontAwesomeIcon className="addOn" icon={faAngleDown} />
            </div>
            <Form.Control.Feedback type="invalid">
              {error.password}
            </Form.Control.Feedback>
          </FormGroup>
         <Modal centered show={showPartnerInput} backdrop="static" keyboard={false}
                onHide={() => setShowPartnerInput(false)}>
          <Modal.Header className="ms-auto border-0 color-primary">
           <FaRegWindowClose onClick={() => {
            setAccountType("Client")
            setCreateSentence(createSentenceClient)
            setShowPartnerInput(false)
           }} className="fs-6"/>
          </Modal.Header>
          <Modal.Body className="px-5">
           <h5 className="mb-4" style={{ textAlign: "left", fontFamily: "Montserrat", fontWeight: 700 }}>
            Oops! It seems you're attempting to create a partner account.
           </h5>
           <p className="content-text" style={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: 500 }}>
            Please note that partner accounts are intended for specific business collaborations. As a valued customer, you don't need to create a partner account to access our services.
           </p>
           <p className="content-text ff-Montserrat mb-5" style={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: 500 }}>
            Simply proceed with your regular customer account creation process, and you'll be able to enjoy all the benefits and features available to our valued customers.</p>
           <div className="text-center row">
            <div className="col">
            <button onClick={() => {
             setAccountType("Client")
             setCreateSentence(createSentenceClient)
             setShowPartnerInput(false)
            }} className="btn-continue mb-2" style={{ fontSize: "14px" }}>
             Cancel
            </button>
            </div>
            <div className="col">
            <button onClick={() => setShowPartnerInput(false)} className="btn-continue-outline mb-2" style={{ fontSize: "14px" }}>
             Continue
            </button>
            </div>
           </div>
          </Modal.Body>
         </Modal>
          <TermSentence>
            {t("signupPage.sentence.part1")}
            <ForgotPasswordLink
              href="https://optimataxrelief.com/about-us/terms-of-site/"
              target="_blank"
              rel="noreferrer"
              className="text-reset text-decoration-underline"
            >
              {t("signupPage.terms")}{" "}
            </ForgotPasswordLink>
            {t("signupPage.sentence.part2")}
            <ForgotPasswordLink
              href="https://optimataxrelief.com/about-us/privacy/"
              target="_blank"
              rel="noreferrer"
              className="text-reset text-decoration-underline"
            >
              {t("signupPage.privacy")}{" "}
            </ForgotPasswordLink>
          </TermSentence>

          <Button disabled={spinner} type="submit" className="w-100">
            {spinner && (
              <span
                style={{ marginRight: 10 }}
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("signupPage.btn.signup")}
          </Button>
        </Form>
        <NotAccount>
          {t("signupPage.haveAccount")}
          <TextButton
            style={{color: "#1589d2"}}
            onClick={(e) => {
              navigate("/login");
            }}
          >
            {t("signupPage.btn.signin")}{" "}
          </TextButton>
        </NotAccount>
      </PageBody>
      </motion.div>
      </AnimatePresence>
    </DefaultLayout>
  );
};

export default SignupPage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 3rem 2rem;
`;

const FormTitle = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-text-default);
  font-family: "Montserrat", sans-serif;
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;
const ForgotPasswordLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const TextButton = styled.span`
  color: var(--color-text-default);
  text-align: center;
  cursor: pointer;
  font-weight: 700;
`;

const TermSentence = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
`;

const NotAccount = styled.div`
  align-items: center;
  text-align: center;
  margin-top: 1rem;
`;
