import React, { useEffect, useState } from "react";
import "../../../pages/navbar.css";
import "../../styles.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import irsNoticeIcon from "../../../assets/irs-notice-icon.svg";
import shieldMediumRisk from "../../../assets/shield-medium-risk.svg";
import shieldHighRisk from "../../../assets/shield-high-risk.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import shieldLowRisk from "../../../assets/shield-low-risk.svg";
import appstoreIcon from "../../../assets/appstore-icon.png";
import playstoreIcon from "../../../assets/playstore-icon.png";
import { GetTaxRiskReports } from "../../../queries/GetTaxRiskReports";
import { clientConfig, SetS3Config } from "../../../awsClientConfig";
import { TaxRiskReportItem } from "../types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { formatPhone } from "../../../functions/misc";
import { GetProfileReferral } from "../../../graphql/one-api/custom-queries/Profile";
import { GetPartnerOrganizationId } from "../../../graphql/one-api/custom-queries/Partner";
import { GetOrganizationPhone } from "../../../graphql/one-api/custom-queries/Organization";
import { API, Auth, Storage } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as types from "../../../API";
import Loading from "../../../Common/Loading";
import { GetLatestFormsByProfile } from "../../../queries/GetLatestFormsByProfile";
import Alert from "react-bootstrap/Alert";
import { GetProtectionPlanEligibility } from "../../../queries/GetProtectionPlanEligibility";
import { userProfileData } from "../../../Common/profileInfo";
import { GetLegacyTaxRiskReports } from "../../../queries/GetLegacyTaxRiskReports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import TaxTools from "../ClientDashboards/TaxTools";
import { motion } from "framer-motion";

type TaskRiskReports = {
  NextToken: String | null;
  TaxRiskReports: TaxRiskReportItem[];
};
type res = {
  data: {
    GetTaxRiskReports: TaskRiskReports;
  };
};
type OppTaxReportsProps = {
  userId: string;
  taxReports: TaxRiskReportItem[] | any;
  setTaxReports: (value: any) => void;
  isLegacy: boolean;
  taxPrepEnrollmentStatus: boolean | undefined;
};

function OppTaxReports({ userId, taxReports, isLegacy, setTaxReports, taxPrepEnrollmentStatus }: OppTaxReportsProps) {
  const client = new clientConfig();
  const navigate = useNavigate();
  const [risk, setRisk] = useState<string | null | undefined>("");
  const [S3Bucket, setS3Bucket] = useState('');
  const [S3Key, setS3Key] = useState('');
  const [PDFReport, setPDFReport] = useState("");
  const [currentReport, setCurrentReport] = useState<any>();
  const [organizationPhone, setOrganizationPhone] = useState<string | null>('');
  const [reportID, setReportID] = useState("");
  const [showFailedReportAlert, setShowFailedReportAlert] = useState(false);
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
     if (taxReports[0]?.OverallRisk) {
      setRisk(taxReports[0]?.OverallRisk);
      setReportID(taxReports[0]?.Id);
     }

  }, [taxReports])

  useEffect(() => {
    const fetchFailTranscript = async () => {
     try {
       const res = await API.graphql<GraphQLQuery<types.GetProtectionPlanEligibilityQuery>>({
        query: GetProtectionPlanEligibility,
        variables: { ProfileId: userId }
       });
       if (res.data?.GetProtectionPlanEligibility?.LastTaxRiskReport?.Status === types.TaxRiskReportStatus.Failed) {
         setShowFailedReportAlert(true);
       }
     } catch (error) {
       console.log('error:', error);
     }
    };

    const fetchOrganizationPhone = async () => {
      try {
        const resProfile = await API.graphql<GraphQLQuery<types.GetProfileQuery>>({
            query: GetProfileReferral,
            variables: { profileId: userId }
          });

        if (resProfile.data?.GetProfile?.EnrollmentSource === types.EnrollmentSource.Referral) {
          if (resProfile.data?.GetProfile?.PartnerId !== null) {
             const resPartner  = await API.graphql<GraphQLQuery<types.GetPartnerQuery>>({
              query: GetPartnerOrganizationId,
              variables: { PartnerId:  resProfile.data.GetProfile?.PartnerId }
            });

             if (resPartner.data?.GetPartner?.OrganizationId) {
               const resOrganization  = await API.graphql<GraphQLQuery<types.GetOrganizationQuery>>({
                 query: GetOrganizationPhone,
                 variables: { organizationId:  resPartner.data?.GetPartner?.OrganizationId }
               });
               setOrganizationPhone(formatPhone(resOrganization.data?.GetOrganization.Phone));
             }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

   const fetchForm = async () => {
    try {
      const res = await API.graphql<GraphQLQuery<types.GetLatestFormsByProfileQuery>>({
        query: GetLatestFormsByProfile,
        variables: { ProfileId: userId }
      });
      const formsData = res.data?.GetLatestFormsByProfile?.find((form: any) => form.Name === "Form8821");
      setForm(formsData);
    } catch (error) {
      console.log('error', error);
    }
   }

    fetchFailTranscript();
    fetchOrganizationPhone();
    fetchForm();
  }, []);

  useEffect(() => {

   const fetchLegacyTaxRiskReports = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();

    try {
     setLoading(true);
     const res: any = await client.query({
      query: GetLegacyTaxRiskReports,
      variables: { ProfileId: profileId, },
     })
     let pdfReports = res.data?.GetLegacyTaxRiskReports;

     if (pdfReports.length > 0) {
      if (taxReports.find((report: any) => report?.Id === pdfReports[0]?.Id)) {
       setLoading(false);
       return;
      }
      setS3Bucket(pdfReports[0].S3Bucket);
      setS3Key(pdfReports[0].S3Key);
      await fetchPDFReport(pdfReports[0].S3Bucket, pdfReports[0].S3Key);
      let reportsArray = taxReports.concat(pdfReports);
      setCurrentReport(reportsArray[0]);

      if (reportsArray.length > 12) {
       reportsArray.length = 12
      }
      setTaxReports(reportsArray);
      setTimeout(() => {
       setLoading(false);
      }, 1000);
     } else {
      setLoading(false);
      setCurrentReport(taxReports[0]);
     }
    } catch (error) {
     console.log("error:", error);
    }
   };
   if (isLegacy) {
    fetchLegacyTaxRiskReports();
   } else {
    setTimeout(() => {
     setLoading(false);
    }, 1500);
   }
  }, [isLegacy])

  const dateformat = (date: string) => {
    return moment(new Date(date)).utc().format("MMMM DD, YYYY");
  };

  const setReportData = async (e: any) => {
    let selectedIndex = e.target.selectedIndex;
    if (taxReports[selectedIndex]?.S3Key) {
      setLoading(true);
      setCurrentReport(taxReports[selectedIndex]);
      await fetchPDFReport(taxReports[selectedIndex]?.S3Bucket, taxReports[selectedIndex]?.S3Key);
      setLoading(false);
    } else {
      let risklevel: string | null | undefined =
       taxReports && taxReports[selectedIndex]?.OverallRisk;
      setCurrentReport(taxReports[selectedIndex]);
      setRisk(risklevel);
      setReportID(e.target.value);
    }
  };

 const fetchPDFReport = async (S3Bucket: string, S3Key: string) => {
  try {
   SetS3Config(S3Bucket, "public");
   const pdfReport = await Storage.get(S3Key, {
    customPrefix: { public: "" },
    level: "public",
    contentType: "application/pdf",
   });
   setPDFReport(pdfReport);
  } catch (error) {
   console.log("error", error);
  }
 };

 function printImage() {

  fetch(PDFReport)
   .then(async (response) => {
    return await response.arrayBuffer();
   })
   .then((result) => {
    const newBlob = new Blob([result], { type: "application/pdf" });
    const fileLink = window.URL.createObjectURL(newBlob);
    const iframe = document.createElement("iframe");
    iframe.src = fileLink;
    iframe.id = "print_pdf";
    iframe.name = "print_pdf";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const windowVal:any= window
    windowVal.frames["print_pdf"].focus();
    windowVal.frames["print_pdf"].print();
   })
   .catch((error) => console.log("error", error));
 }
 const onDownload = async () => {
  const date = " - " + dateformat(currentReport?.ReportDate) || '';
  try {
   SetS3Config(S3Bucket, "public");
   const Agreement = await Storage.get(S3Key, {
    customPrefix: { public: "" },
    download: true,
    level: "public",
    contentType: "application/pdf",
   });
   // @ts-ignore
   downloadBlob(Agreement.Body,  `Monthly Summary${date}`);
  } catch (error) {
   console.log("error:", error);
  }
 };

 function downloadBlob(blob: any, filename: any) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
   setTimeout(() => {
    URL.revokeObjectURL(url);
    a.removeEventListener("click", clickHandler);
   }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
 }

  const onViewReport = () => {
    navigate("/tax-risk-report", {
      state: { taskRiskReportId: reportID },
    });
  };
  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        {loading ? (
          <Loading />
        ) : (
        <motion.div variants={motionContainer} initial="initial" animate="animate">
          {showFailedReportAlert &&
            <Row className="justify-content-center mb-1 text-center">
              <Alert className="content-box w-auto fs-6 fw-bolder" variant="danger" dismissible onClose={() => setShowFailedReportAlert(false)}>
                We're Currently Unable to Generate Your Report. &nbsp;
                <Alert.Link className="fw-normal" style={{color: "#0077b6"}} onClick={() =>
                 navigate("/account-settings-documents", { state: form })}>
                  Click here to File a New Form 8821
                </Alert.Link>
              </Alert>
            </Row>
          }
          <Row className="justify-content-center">
               {currentReport?.S3Key ?
               <>
                 <Col lg={3} md={3} sm={3}>
                    <Form.Select className="mb-3" aria-label="Default select example" onChange={setReportData}>
                     {taxReports?.map((report: any, index: any) => {
                      return (
                       <option key={report.Id} value={report.Id} selected={report === currentReport}>
                        {dateformat(report.ReportDate)}
                        {index === 0 && " (Latest)"}
                       </option>
                      );
                     })}
                    </Form.Select>
                 </Col>
                 <div className="text-end">
                   <span className="letsdolater" onClick={printImage}>
                     <FontAwesomeIcon className="" icon={faPrint} /> Print
                   </span>
                  <span style={{ marginLeft: "10px" }} className="letsdolater" onClick={onDownload}>
                    <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                  </span>
                 </div>
                 <div style={{ marginTop: 10, height: 750 }}>
                   <iframe
                    src={PDFReport}
                    height="100%"
                    width="100%"
                    id="muimh"
                    title="test"
                   ></iframe>
                 </div>
               </>
                :
                <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
                <Row className="px-lg-5 justify-content-between align-items-center">
                {risk === "Low" && (
                 <Col lg={5}>
                   <div className="d-flex align-items-center">
                     <div className="me-2">
                       <img
                        src={shieldLowRisk}
                        alt="Tax-report"
                        className="img-fluid d-block mx-auto"
                       />
                     </div>

                     <div>
                       <h4 className="text-dark  ff-Montserrat fs-4 fw-bold">
                        Risk Level:{" "}
                       </h4>
                       <h4 className="text-success mb-3 ff-Montserrat fs-4 fw-bold text-uppercase">
                        LOW RISK{" "}
                       </h4>
                     </div>
                  </div>
                  <p className="content-text mt-3">
                   <span className="fw-bold">Low Risk:{" "}</span>
                   Based on our analysis you are currently at low risk of
                   enforcement activity. Please continue to file and pay on
                   time to stay in IRS compliance.
                  </p>
                 </Col>
                )}
                {risk === "Medium" && (
                 <Col lg={5}>
                  <div className="d-flex align-items-center">
                    <div className="me-2">
                      <img
                       src={shieldMediumRisk}
                       alt="Tax-report"
                       className="img-fluid d-block mx-auto"
                      />
                    </div>

                    <div>
                      <h4 className="text-dark  ff-Montserrat fs-4 fw-bold">
                       Risk Level:{" "}
                      </h4>
                      <h4 className="mb-3 ff-Montserrat fs-4 fw-bold text-uppercase text-yellow">
                       MEDIUM RISK{" "}
                      </h4>
                    </div>
                   </div>
                   <p className="content-text mt-3">
                     <span className="fw-bold">Medium Risk:{" "}</span>
                     Based on our analysis you are currently at some risk of
                     enforcement activity. We will continue to monitor all IRS
                     notices and alert you if this changes.
                   </p>
                 </Col>
                )}
                {risk === "High" && (
                 <Col lg={5}>
                   <div className="d-flex align-items-center">
                     <div className="me-2">
                       <img
                        src={shieldHighRisk}
                        alt="Tax-report"
                        className="img-fluid d-block mx-auto"
                       />
                     </div>

                    <div>
                      <h4 className="text-dark  ff-Montserrat fs-4 fw-bold">
                       Risk Level:{" "}
                      </h4>
                      <h4 className="mb-3 ff-Montserrat fs-4 fw-bold text-uppercase color-red">
                       HIGH RISK{" "}
                      </h4>
                    </div>
                   </div>
                    <p className="content-text mt-3">
                      <span className="fw-bold">High Risk:{" "}</span> Based on our
                      analysis you are at high risk of collection activity
                      and/or audit review.
                    </p>
                    <p className="content-text">
                     {" "}
                       <span className="fw-bold">We strongly recommend{"  "}</span>a
                       consultation to help evaluate your current tax situation
                       and IRS collections risk.
                    </p>
                    <p className="content-text fw-bold">
                     Please contact us immediately at {organizationPhone ? organizationPhone : '(800) 536-0734'}
                    </p>
                 </Col>
                )}
                <Col lg={4}>
                  <h6 className="text-dark  ff-Montserrat fs-4 fw-bold text-center">
                   View Your Report
                  </h6>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select a Tax Risk Report</Form.Label>
                      <Form.Select
                       aria-label="Default select example"
                       onChange={setReportData}
                      >
                        {taxReports?.map((report: any, index: any) => {
                         return (
                          <option key={report.Id} value={report.Id} selected={report === currentReport}>
                           {dateformat(report.ReportDate)}
                           {index === 0 && " (Latest)"}
                          </option>
                         );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <div className="d-grid">
                      <button className="btn-xl" style={{width: '100%'}} onClick={onViewReport}>
                       View Report
                      </button>
                    </div>
                  </Form>
                 </Col>
                </Row>
              </Col>
               }
            <Col md={10} className="px-0 my-3">
              <TaxTools />
            </Col>
          </Row>
        </motion.div>
        )}
      </Container>
    </>
  );
}

export default OppTaxReports;
