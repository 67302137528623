import gql from "graphql-tag";

export const UpdateTaxReturnIncome = gql`
  mutation UpdateTaxReturnIncome(
    $ClientProfileId: ID!,
    $Id: String!
    $ProfileId: String
    $HasSelfEmploymentIncome: String!
    $AnnualGrossReceipts: Float
    $HasCapitalGainsOrLosses: String
    $SoldRealEstate: String
    $HasRentalIncomeOrLosses: String
    $HasPartnershipIncomeOrLosses: String
    $OperatedFarm: String!
    $NeedStateReturn: String
    $ReceivedRoyalties: String!
    $RentalsCount: Int
    $TaxPrepStateReturn: [UpdateTaxPrepStateReturnInput]!
  ) {
    UpdateTaxReturnIncome(ProfileId: $ClientProfileId,
      Input: {TaxPrepIncome: {AnnualGrossReceipts: $AnnualGrossReceipts, HasCapitalGainsOrLosses: $HasCapitalGainsOrLosses, 
                            HasPartnershipIncomeOrLosses: $HasPartnershipIncomeOrLosses, HasRentalIncomeOrLosses: $HasRentalIncomeOrLosses, 
                            HasSelfEmploymentIncome: $HasSelfEmploymentIncome, Id: $Id, NeedStateReturn: $NeedStateReturn, 
                            OperatedFarm: $OperatedFarm, ProfileId: $ProfileId, SoldRealEstate: $SoldRealEstate,
                            ReceivedRoyalties: $ReceivedRoyalties, RentalsCount: $RentalsCount}, 
                            TaxPrepStateReturn: $TaxPrepStateReturn}
    ) {
      TaxPrepIncome {
        AnnualGrossReceipts
        CreatedAt
        HasCapitalGainsOrLosses
        HasPartnershipIncomeOrLosses
        HasRentalIncomeOrLosses
        HasSelfEmploymentIncome
        Id
        NeedStateReturn
        OperatedFarm
        ProfileId
        QuoteId
        SoldRealEstate
        UpdatedAt
      }
      TaxPrepStateReturn {
        CreatedAt
        Id
        IncomeId
        State
        UpdatedAt
      }
    }
  }
`;