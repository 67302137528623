import gql from "graphql-tag";

export const QualifyEnrollmentResult = gql`
  mutation QualifyEnrollmentResult(
    $enrolmentID: ID!
    $answeredQuestions: [AnsweredQuestion]!
  ) {
     QualifyEnrollmentRequest(
      Input: {
        EnrollmentId: $enrolmentID
        AnsweredQuestions: $answeredQuestions
      }
    ) {
      Result
      ResultExplanation
    }
  }
`;