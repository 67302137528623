import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function PartnerAccountSetting() {
  return (
    <>
      <Container className="mb-5 tax-risk-report">
        <Row className="justify-content-center g-2">
          <Col lg={10}>
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>

              <Breadcrumb.Item active>Account Settings</Breadcrumb.Item>
            </Breadcrumb>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-1">
              Account Settings
            </h4>
            <p className="content-text">
            Update your contact information, manage your licenses & insurance records, set your privacy preferences, and more.
            </p>
            <Row className="g-2">
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0 px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Profile Information
                    </h6>
                    <Card.Text className="content-text">
                      Update your personal information, email, phone or address.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                     href="/partner-profile-info">Update Info {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                     Licenses & Insurance
                    </h6>
                    <Card.Text className="content-text">
                      Manage your Licenses and Insurance records.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-licence-insurance">Update Info {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0  px-lg-3">
                  <Card.Body>
                   <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                    Documents
                   </h6>
                    <Card.Text className="content-text">
                      Manage your uploaded and signed documents.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-documents">Update Info {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Password Reset
                    </h6>
                    <Card.Text className="content-text">
                      We will send a temporary password to your email address.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-reset-password">Reset Password {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0 w-100  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                      Notifications
                    </h6>
                    <Card.Text className="content-text">
                      Set alerts for the things you care about most.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-notifications">Manage Notifications {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card style={{ width: "100%"}} className="text-left border-0 w-100  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                     Easy Sharing URL and QR code 
                    </h6>
                    <Card.Text className="content-text">View your simple QR code and link for customer referrals</Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-url-qrcode-sharing">Personalized URL {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <h4 className="text-main-dark ff-Montserrat fw-bold mb-1 mt-5">
                Help & Support
              </h4>
              <p className="content-text">
                We’re here to help. Check out our additional support options
                below to get in touch with our award-winning customer service
                team.
              </p>
              <Col lg={4} className="d-flex align-items-stretch">
                <Card className="text-left border-0  px-lg-3">
                  <Card.Body>
                    <h6 className="ff-Montserrat my-3 fs-6" style={{fontWeight: "600"}}>
                     Contact Us
                    </h6>
                    <Card.Text className="content-text">
                      Please contact Optima regarding any questions you may
                      have.
                    </Card.Text>
                    <Card.Link className="cursor-pointer text-decoration-none material-blue"
                      href="/partner-settings-contact">Get in Touch {">"}</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      
    </>
  );
}

export default PartnerAccountSetting;
