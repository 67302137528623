import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Envelop from "../../assets/envelope.svg";
import MailBox from "../../assets/mailbox.svg";
import Telephone from "../../assets/telephone-outbound.svg";
import userSupport from "../../assets/userSupport.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import { GetPhoneNumbers } from "../../queries/GetPhoneNumbers";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfile } from "../../queries/getProfile";
import { GetOrganization } from "../../queries/GetOrganization";
import { GetPartnerWithoutTaxProfessional } from "../../queries/GetPartnerWithoutTaxProfessional";

type PhoneNumberResponse = {
  data: {
    GetPhoneNumbers: {
      ProfileContactUs: string;
    };
  };
};

function ContactUs() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [partnerAddress, setPartnerAddress] = useState("");
  const [enrollmentSource, setEnrollmentSource] = useState("");
  const [partner, setPartner] = useState("");

  const client = new clientConfig();

  useEffect(() => {
    fetchContactInfo();
  }, []);

  const fetchContactInfo = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setEnrollmentSource(res.data.GetProfile.EnrollmentSource);
          if (res.data.GetProfile.EnrollmentSource === "Organic") {
            client
              .query({
                query: GetPhoneNumbers,
              })
              .then((data: any) => {
                setPhoneNumber(data?.data?.GetPhoneNumbers?.ProfileContactUs);
              });
            setEmail("customercare@optimataxrelief.com");
            setPartnerAddress("Optima Tax Relief");
            setAddress("6 Hutton Centre Dr., ");
            setAddress1("Suite 200, ");
            setAddress2("Santa Ana, CA, 92707");
          } else if (res.data.GetProfile.EnrollmentSource === "Referral") {
            client
              .query({
                query: GetPartnerWithoutTaxProfessional,
                variables: {
                  PartnerId: res.data.GetProfile.PartnerId,
                },
              })
              .then((res: any) => {
                const organizationId = res.data.GetPartner.OrganizationId;
                
                setEmail(res.data.GetPartner.Email);
                if (organizationId) {
                  client
                    .query({
                      query: GetOrganization,
                      variables: { organizationId },
                    })
                    .then((res: any) => {
                      setPartner(res.data.GetOrganization.Name);
                      setPhoneNumber(res.data.GetOrganization.Phone);
                      const organizationAddresses =
                        res.data.GetOrganization.OrganizationAddresses;

                      const mainAddress = organizationAddresses.find(
                        (address: any) => address.Type === "Main"
                      );

                      const storeFrontAddress = organizationAddresses.find(
                        (address: any) => address.Type === "Storefront"
                      );

                      if (res.data.GetOrganization.Storefront) {
                        let StreetAddress2 = storeFrontAddress.StreetAddress2 ? storeFrontAddress.StreetAddress2+", " : ""
                        setPartnerAddress("Partner Address");
                        setAddress(storeFrontAddress.StreetAddress1 + ", ");
                        setAddress1(StreetAddress2);
                        setAddress2(
                          storeFrontAddress.City +
                            ", " +
                            storeFrontAddress.State +
                            ", " +
                            storeFrontAddress.ZipCode
                        );
                      } else {
                        let StreetAddress2 = mainAddress.StreetAddress2 ? mainAddress.StreetAddress2+ ", " : ""
                        setPartnerAddress("Partner Address");
                        setAddress(mainAddress.StreetAddress1 + ", ");
                        setAddress1(StreetAddress2);
                        setAddress2(
                          mainAddress.City +
                            ", " +
                            mainAddress.State +
                            ", " +
                            mainAddress.ZipCode
                        );
                      }
                    })
                    .catch(() => {});
                }
              });
          }
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Help & Support
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3 justify-content-between">
              <Col lg={6}>
                {enrollmentSource && (
                  <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                    {enrollmentSource === "Organic"
                      ? "Contact Us"
                      : "Contact Partner"}
                  </h4>
                )}

                {enrollmentSource && (
                  <>
                    {enrollmentSource === "Organic" ? (
                      <p className="content-text">
                        Optima’s{" "}
                        <span className="fw-bold">
                          Client Services Department
                        </span>{" "}
                        is available <br /> Monday – Friday from 7:00 AM – 6:00
                        PM Pacific Time.
                      </p>
                    ) : (
                      <p className="content-text"> {partner} </p>
                    )}
                  </>
                )}
                <ul className="contactus-ul">
                  <li className="d-flex mb-3">
                    <span>
                      <img
                        src={Envelop}
                        className="me-2 material-blue fs-6"
                        alt="mailBox"
                      />
                    </span>
                    <hr className="hr-style"/>
                    <span>{email}</span>
                  </li>
                  <li className="d-flex mb-3">
                    <span>
                      <img
                        src={Telephone}
                        className="me-2 material-blue fs-6"
                        alt="telephone"
                      />
                    </span>
                    <hr className="hr-style"/>
                    <span>{phoneNumber}</span>{" "}
                  </li>
                  <li className="d-flex mb-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <img
                          src={MailBox}
                          className="me-2 material-blue fs-6"
                          alt="envelop"
                        />
                      </div>
                      <hr className="hr-style"/>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{partnerAddress}</span>
                        <span>{address}</span>
                        <span>{address1}</span>
                        <span>{address2}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col lg={6}>
                <img
                  src={userSupport}
                  className="w-50 d-block mx-auto"
                  alt="userSupport"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
