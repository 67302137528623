import { useContext, useState } from "react";
import "./signature.css";
import SignatureCanvas from "react-signature-canvas";
import {  useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Storage } from "aws-amplify";
import NewFormContext from "./NewFormContext";
import { clientConfig, SetS3Config } from "../../../awsClientConfig";
import { userProfileData } from "../../../Common/profileInfo";
import Loading from "../../../Common/Loading";
import Message from "../../Message";
import { CreateProfileForm } from "../../../queries/CreateProfileForm";
import { Breadcrumb } from "react-bootstrap";
import { Buffer } from 'buffer';
import { GetFreeTaxRiskReportEligibility } from "../../../queries/GetFreeTaxRiskReportEligibility";
import { InitiateEnrollment } from "../../../queries/InitiateEnrollment";


function Signature() {
  const { next,  setS3Bucket, setS3Key,setNextFormId } =
    useContext(NewFormContext);
    const navigate = useNavigate();
  const client = new clientConfig();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [signEmpty, setSignEmpty] = useState(false);
  const [signaturePending, setSignaturePending] = useState(true);

  var signCanvas: SignatureCanvas | null = null;

  const submitSign = async () => {
    if (signCanvas?.isEmpty()) {
      setSignEmpty(true);
      return;
    }
    setButtonDisable(true);
    let signature: any = signCanvas?.getTrimmedCanvas().toDataURL("image/png");

    const profileId = await userProfileData();

    SetS3Config(
      `signatures-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const buf = Buffer.from(
      signature.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    await Storage.put(`Profiles/${profileId}.png`, buf, {
      contentType: "image/png", // contentType is optional
      customPrefix: { public: "" },
      tagging: "ExternalClearance=Profile",
    });

   const variablesEnrollment = {
    profileId: profileId,
    type: "Investigation",
   };

   client
    .query({
     query: GetFreeTaxRiskReportEligibility,
     variables: { profileId: profileId },
    })
    .then((res: any) => {
     if (res.data.GetFreeTaxRiskReportEligibility.TranscriptRequestAttemptsAllowed) {
       client
        .mutate({ mutation: InitiateEnrollment, variables: variablesEnrollment })
        .then((res: any) => {
         if (res.data.InitiateEnrollment.Id) {
           let variables = {
            ProfileId: profileId,
            FormName: "Form8821",
            Owner:true,
            EnrollmentId: res.data.InitiateEnrollment.Id
           };
           client
            .mutate({ mutation: CreateProfileForm, variables: variables })
            .then((res: any) => {
             if (res.data.CreateProfileForm) {
               setS3Bucket(res.data.CreateProfileForm?.S3Bucket);
               setS3Key(res.data.CreateProfileForm?.S3Key);
               setNextFormId(res.data.CreateProfileForm?.ProfileForms[0]?.FormId)
               next();
               setButtonDisable(false);
             }
            })
         }
       })
     } else {
       let variables = {
        ProfileId: profileId,
        FormName: "Form8821",
        Owner:true
       };
       client
        .mutate({ mutation: CreateProfileForm, variables: variables })
        .then((res: any) => {
         if (res.data.CreateProfileForm) {
           setS3Bucket(res.data.CreateProfileForm?.S3Bucket);
           setS3Key(res.data.CreateProfileForm?.S3Key);
           setNextFormId(res.data.CreateProfileForm?.ProfileForms[0]?.FormId)
           next();
           setButtonDisable(false);
         }
        })
        .catch((error: any) => {
          setButtonDisable(false);
        });
     }
    })
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            {buttonDisable ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <Breadcrumb className="customBreadcrumb mt-0">
                  <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings")}
                  >
                    Account Settings
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings-documents")}
                  >
                    Documents
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{"Forms > 8821"}</Breadcrumb.Item>
                </Breadcrumb>
                
                <h1 className="content-box-heading mb-3">
                Create Your Signature
                </h1>
                <h6 className="content-box-heading mb-3">

                Signature Approving New Form 8821
                </h6>
                <SignatureCanvas
                  clearOnResize={false}
                  penColor="black"
                  canvasProps={{ className: "sigCanvas" }}
                  ref={(ref) => {
                    signCanvas = ref;
                  }}
                  onBegin={() => {
                   setSignEmpty(false);
                   setSignaturePending(false);
                  }}
                />
                <label className="signature-text d-block text-center">
                  Please draw your signature in the box above
                </label>
                {signEmpty && (
                  <Message
                    type="error"
                    message="Signature is mandatory"
                  ></Message>
                )}

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <button
                    onClick={() => {
                      signCanvas?.clear();
                      setSignaturePending(true);
                    }}
                    className="btn-continue-outline"
                  >
                    Clear Signature
                  </button>
                  <button
                    onClick={submitSign}
                    className={signaturePending ?
                     "btn-disable" :
                     "btn-continue"}
                    disabled={buttonDisable}
                  >
                    Submit Signature
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Signature;
