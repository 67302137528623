import { API, Storage } from "aws-amplify";
import awsConfig from "./aws-exports";
// const AppSync = require("aws-appsync");

// export const clientConfig = () => {
//   const client = new AppSync.AWSAppSyncClient({
//     url: awsConfig.Auth.awsAppSyncGraphqlEndpoint,
//     region: awsConfig.Auth.region,
//     auth: {
//       type: awsConfig.Auth.awsAppSyncAuthenticationType,
//       jwtToken: async () =>
//         (await Auth.currentSession()).getIdToken().getJwtToken(),
//     },
//     disableOffline: true,
//     complexObjectsCredentials: async () => await Auth.currentCredentials(),
//   });

//   return client;
// };

/*export const clientConfig = () => {
  return true
} */

export class clientConfig {

  constructor(){
    
  }

   

  async query(input: any) {
    return await API.graphql(input);
  }

  async mutate(input: any) {
    return await API.graphql({
      query: input.mutation,
      variables: input.variables
    });
  }
  
}

export function SetS3Config(bucket: string, level: string) {
  Storage.configure({
    bucket: bucket,
    // level: level,
    region: 'us-west-2',
    // identityPoolId: 'us-west-2:5f0cfd68-049a-4783-a246-a86f0e129c80',
  });
}
