import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-bootstrap/Modal";
import { IdentifiedNotice } from "../../queries/IdentifiedNotice";
import AppProgressBar from "../../Common/progressBar";
import { SetS3Config } from "../../awsClientConfig";
import unDrawWorkingReady from "../../assets/undraw_Working_re_ddwy.svg";
import { generateUUID } from "../../lib/generateUUID";
import IrsNoticeAnalyzerResult from "./irsNoticeAnalyzerResult";
import { IdentifiedNoticeType } from "./type";
import { userProfileData } from "../../Common/profileInfo";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import DragDropFiles from "../DragDropFiles";

const fileTypes = ["JPG", "JPG 2000", "PNG"];

type subscribeResponse = {
  value: {
    data: {
      IdentifiedNotice: IdentifiedNoticeType;
    };
  };
};

function IrsNoticeAnalyzer() {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File>();
  const [uploadImage, setUploadImage] = useState<string>();
  const [populateData, setPopulateData] = useState<IdentifiedNoticeType>();
  const [showError, setShowError] = useState(false);
  const [fileAdded, setFileAdded] = useState(null);

  useEffect(() => {
    const subscribe = async () => {
      const profileId = await userProfileData();
      const subscription = API.graphql(
        graphqlOperation(IdentifiedNotice, { profileId: profileId }),
        {}
        // @ts-ignore:
      ).subscribe({
        next: ({ value }: subscribeResponse) => {
          setShow(false);
          console.log(value.data.IdentifiedNotice);
          setShowError(Boolean(value.data.IdentifiedNotice.Error));
          setPopulateData(value.data.IdentifiedNotice);
        },

        error: (error: any) => console.warn(error),
      });

      return () => subscription.unsubscribe();
    };

    subscribe();
  }, []);

  const handleChange = async (file: File) => {
    setFile(file);
    setUploadImage(URL.createObjectURL(file));
    setFileAdded(null);
  };

  const handleClose = () => setShow(false);
  const handleAnalyze = async () => {
    setShow(true);
    SetS3Config(
      `irs-notices-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const profileId = await userProfileData();

    try {
      await Storage.put(`Uploads/${profileId}/${generateUUID()}.png`, file, {
        customPrefix: { public: "" },
        tagging: "ExternalClearance=Profile",
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const reset = () => {
    setPopulateData(undefined);
    setFile(undefined);
  };

  if (populateData && !populateData.Error) {
    return (
      <IrsNoticeAnalyzerResult reset={reset} identifiedNotice={populateData} />
    );
  }

  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5 px-lg-5">
            <Row className="row px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="content-box-heading mb-5">
                  IRS Notice Analyzer
                </h1>
                <h6 className="text-uppercase mb-0 irs-color fw-bold">1. PREPARE</h6>
                <p className="mb-4">Flatten your IRS notice to take a photo</p>
                <h6 className="text-uppercase mb-0 irs-color fw-bold">2. CAPTURE</h6>
                <p className="mb-4">
                  Upload your IRS notices from your computer
                </p>
                <h6 className="text-uppercase mb-0 irs-color fw-bold">3. RELAX</h6>
                <p className="mb-4">Let our Notice Analyzer do the work!</p>
              </Col>
              <Col lg={5}>
                <img
                  src={unDrawWorkingReady}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </Col>

              {/* <FileUploader
                handleChange={handleChange}
                fileOrFiles={fileAdded}
                name="file"
                types={fileTypes}
                onClick={handleClick}
                children={
                  <Col md={12} className="border-dashed choose-file mb-3">
                    <img
                      src={require("../../assets/placeholder-img.png")}
                      width="120"
                      alt="browse"
                      className="d-block mx-auto"
                    />

                    <h5 className="text-center browse">
                      Browse for a file or drag here
                    </h5>
                    <p className="text-center supported-files">
                      Supported File Types: JPG, JPG 2000, PNG, PDF.
                    </p>
                  </Col>
                }
              /> */}

              <DragDropFiles onChange={handleChange} acceptPDF={false}/>

              {file && (
                <div className="col px-lg-2 d-flex py-2">
                  <div
                    style={{
                      width: 215,
                      height: 142,
                      objectFit: "cover",
                      borderWidth: 2,
                      borderColor: "#ddd",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {isPdf(file.name) ? (
                      <iframe
                        src={uploadImage}
                        title="app-logo"
                        style={{
                          width: 212,
                          height: 138,
                          objectFit: "cover",
                          borderWidth: 2,
                          borderColor: "#ddd",
                        }}
                      />
                    ) : (
                      <img
                        src={uploadImage}
                        alt="app-logo"
                        style={{
                          width: 212,
                          height: 138,
                          objectFit: "cover",
                          borderWidth: 2,
                          borderColor: "#ddd",
                        }}
                      />
                    )}
                    <a
                      href="/"
                      className="fw-bold text-decoration-none mt-2 delete-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        setFile(undefined);
                      }}
                    >
                      <FaTrashAlt className="me-1" />
                      Delete
                    </a>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between mt-3 align-items-center px-lg-2">
                <a className="letsdolater" href="/dashboard">
                  Lets do this later...
                </a>
                <button
                  disabled={!Boolean(file)}
                  className={!Boolean(file) ?
                   "irs-btn-disable mt-2" :
                   "irs-btn-submit mt-2"}
                  style={{padding: '20px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                  onClick={handleAnalyze}>
                  Analyze
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6 className="text-dark text-center mb-4">Analyzing…</h6>
          <AppProgressBar />
        </Modal.Body>
      </Modal>
      <Modal centered show={showError} onHide={() => setShowError(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="text-muted text-center mt-3">
              We cannot analyze the file you provided, please try again or contact us.
            </p>
            <button
              className="irs-btn-submit"
              onClick={() => setShowError(false)}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default IrsNoticeAnalyzer;
