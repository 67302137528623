import gql from "graphql-tag";

export const GetPaymentMethod = gql`
  query GetPaymentMethod($PaymentMethodId: ID!) {
    GetPaymentMethod(PaymentMethodId: $PaymentMethodId) {
      AchEntityType
      AchNumber
      AchRoutingNumber
      AchType
      Active
      CcExpMonth
      CcExpYear
      CcLastFourDigits
      CcType
      City
      CreatedAt
      Default
      Id
      Name
      State
      ZipCode
      Vendor
      UpdatedAt
      Type
      Token
      StreetAddress2
      StreetAddress1
    }
  }
`;
