import gql from "graphql-tag";

export const GetOrganization = gql`
  query GetOrganization($organizationId: ID!) {
    GetOrganization(OrganizationId: $organizationId) {
      CreatedAt
      Ein
      Id
      Name
      Phone
      PhoneExt
      Type
      UpdatedAt
      Storefront
      OrganizationAddresses {
        City
        CreatedAt
        Id
        OrganizationId
        State
        StreetAddress1
        StreetAddress2
        UpdatedAt
        OrganizationAddressTypes {
          Type
        }
        ZipCode
      }
    }
  }
`;
