import { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../Message";
import { FormGroup, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import passwordValidator from "password-validator";
import { fetchQuery } from "../../functions/misc";
import { FormStatus, GetLatestFormsByProfile } from "../../queries/GetLatestFormsByProfile";
import useProfileStore from "../../store/ProfileStore";
import { CreateProfileChangeRecord, ChangedBy } from "../../queries/CreateProfileChangeRecord";

function ClientPasswordReset() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validate, setValidate] = useState("");
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [passwordError, setPasswordError] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [repwdIcon, setRePwdIcon] = useState(faEyeSlash);
  const [rePwdType, setRePwdType] = useState("password");
  const [rePasswordError, setRePasswordError] = useState("");
  const [samePasswordError, setSamePasswordError] = useState("");
  const { primaryProfile } = useProfileStore();
  var schema = new passwordValidator();
  schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()
    .has()
    .not()
    .spaces();
  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    setEmail((await Auth.currentAuthenticatedUser()).username);
  };

  const resetPassword = async () => {
    const variables = {
      ProfileId: primaryProfile?.Id,
      ChangedField: "Password",
      ChangedBy: ChangedBy.Profile
    }
    const createProfileChangeRecord = await fetchQuery(variables, CreateProfileChangeRecord);

    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setCurrentStep(1);
        setSuccess("A Password Reset Email Sent");
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };
  const validateCode = () => {
    if (!code) {
      setValidate("Code Is Mandartory");
      return;
    }
    setCurrentStep(2);
  };
  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
    setPasswordError("");
  };
  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };
  const handleInputRePassword = (e: any) => {
    setRePassword(e.target.value);
    setRePasswordError("");
  };
  const toggleRePassword = () => {
    if (rePwdType === "password") {
      setRePwdType("text");
      setRePwdIcon(faEye);
      return;
    }
    setRePwdType("password");
    setRePwdIcon(faEyeSlash);
  };
  const submitPassword = () => {
    if (!password || !rePassword) {
      if (!password) setPasswordError("Password is Mandatory");
      if (!rePassword) setRePasswordError("Password is Mandatory");
      return;
    }
    if (password !== rePassword) {
      setSamePasswordError("Both the passwords didn't match");
      setTimeout(() => {
        setSamePasswordError("");
      }, 5000);
      return;
    }
    // var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$");

    if (!schema.validate(password)) {
      setSamePasswordError(
        "Password must have minimum of 8 characters and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
      );
      setTimeout(() => {
        setSamePasswordError("");
      }, 5000);
      return;
    }

    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        console.log(data);
        Auth.signIn(email.toLowerCase(), password).then((res) => {
          console.log("success login");
        });

        setSuccess("Password Updated");
        setCode("");
        setPassword("");
        setRePassword("");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setCurrentStep(0);
      })
      .catch((err) => {
        const code = err.code;

        switch (code) {
          case "CodeMismatchException":
            setError("Invalid verification code provided");

            break;

          case "InvalidPasswordException":
            const message = err.message;
            setSamePasswordError(message);
            break;
          default:
            return false;
        }
        console.log(err);
      });
  };
  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/account-settings", { state });
                }}
              >
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Password Reset</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3 justify-content-between">
              <Col lg={6}>
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Password Reset
                </h4>

                {currentStep === 2 && (
                  <>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">
                      Enter New Password
                    </h6>

                    <p className="content-text">
                      A request has been made to reset your password. If you
                      made this request, please enter the new password. Password
                      must have minimum of 8 characters and contain 1 uppercase
                      letter, 1 lowercase letter, 1 number and 1 special
                      character
                    </p>

                    <FormGroup className="mb-3">
                      <FormLabel>Password</FormLabel>
                      <div className="position-relative">
                        <Form.Control
                          className="form-control input-no-bg"
                          type={pwdType}
                          value={password}
                          placeholder="Password"
                          required
                          onChange={handleInputPassword}
                        />
                        <FontAwesomeIcon
                          onClick={togglePassword}
                          className="addOn"
                          icon={pwdIcon}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <FormLabel>Re-enter Password</FormLabel>
                      <div className="position-relative">
                        <Form.Control
                          className="form-control input-no-bg"
                          type={rePwdType}
                          value={rePassword}
                          placeholder="Password"
                          required
                          onChange={handleInputRePassword}
                        />
                        <FontAwesomeIcon
                          onClick={toggleRePassword}
                          className="addOn"
                          icon={repwdIcon}
                        />
                      </div>
                    </FormGroup>

                    <button
                      onClick={submitPassword}
                      className="btn-continue mb-2"
                    >
                      Reset Password
                    </button>
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">
                      To Continue, Please Verify Your Email Address:
                    </h6>

                    <p className="content-text">
                      Please enter the passcode sent to your email {email}
                    </p>

                    <Form.Group className="mb-3">
                      <Form.Label>Please Enter the Passcode</Form.Label>
                      <Form.Control
                        placeholder=""
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                          setValidate("");
                        }}
                      />

                      <span className="letsdolater" onClick={resetPassword}>
                        Request a New Passcode
                      </span>
                    </Form.Group>
                    <button
                      onClick={validateCode}
                      className="btn-continue mb-2"
                    >
                      Enter the Passcode
                    </button>
                  </>
                )}

                {currentStep === 0 && (
                  <>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">
                      Update Your Password
                    </h6>

                    <p className="content-text">
                      When you update your password, be sure it’s one you don’t
                      use for other accounts
                    </p>

                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control placeholder="" disabled value={email} />
                    </Form.Group>
                    <button
                      onClick={resetPassword}
                      className="btn-continue mb-2"
                    >
                      Reset Password
                    </button>
                  </>
                )}
              </Col>
              <Col md={6}>
                {success && (
                  <Message type="success" message={success}></Message>
                )}
                {error && <Message type="error" message={error}></Message>}
                {validate && (
                  <Message type="error" message={validate}></Message>
                )}
                {passwordError && (
                  <Message type="error" message={passwordError}></Message>
                )}

                {rePasswordError && (
                  <Message type="error" message={rePasswordError}></Message>
                )}
                {samePasswordError && (
                  <Message type="error" message={samePasswordError}></Message>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ClientPasswordReset;
