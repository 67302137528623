import gql from "graphql-tag";

export const GetPhoneNumbers = gql`
  query GetPhoneNumbers {
    GetPhoneNumbers {
        PartnerContactUs
        ProfileContactUs
    }
  }
`;
