import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReferalClientFormContext from "./ReferalClientFormContext";
import AppProgressBar from "../../Common/progressBar";
import { formatZipcode } from "../../functions/misc";
import { US_STATES } from "../../Common/States";
import { clientConfig } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfilePrimaryAddress } from "../../queries/GetProfilePrimaryAddress";

function BillingInformation({ progressPercent = 0 }) {
  const { next, setBillingAddress } = useContext(ReferalClientFormContext);
  const [validated, setValidated] = useState(false);
  const client = new clientConfig();
  const [loading, setLoading] = useState(true);

  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  useEffect(() => {
    getProfilePrimaryAddress();
  },[])

  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
        setLoading(false);
      });
  };


  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
  };

  const submitBillingAddress = () => {
    setBillingAddress(address);
    next();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitBillingAddress();
    }

    setValidated(true);
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">Payment Information</h1>
              <p className="content-text">
                Please provide your billing address
              </p>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group as={Col} controlId="validationCustom01">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress1}
                        name="streetAddress1"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Street Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress2}
                        name="streetAddress2"
                        onChange={(e) => setAddressData(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom02">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.city}
                        name="city"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group as={Col} controlId="validationCustom03">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={address.state}
                        name="state"
                        onChange={(e) => setAddressData(e)}
                        required
                      >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Col} controlId="validationCustom04">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.zipCode}
                        name="zipCode"
                        onChange={(e) => setAddressData(e)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid ZIP Code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end mt-5 align-items-center">
                  <button type="submit" className="btn-continue">
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BillingInformation;
