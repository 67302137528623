import gql from "graphql-tag";

export const UpdateTaxReturn = gql`
  mutation UpdateTaxReturn(
    $ClientProfileId: ID!,
    $FilingId: String!
    $FilingStatus: TaxPrepFilingTypeStatus!
    $FilingType: TaxReturnFilingType!
    $Type: TaxReturnFilingType!
    $Active: Boolean!
    $EnrollmentId: String!
    $PrepQuoteId: ID!
    $Year: String!
    $ProfileId: String
    $ReturnId: String!
  ) {
    UpdateTaxReturn(ProfileId: $ClientProfileId,
      Input: { FilingType: {Id: $FilingId, Status: $FilingStatus, Type: $FilingType}, 
               TaxPrepQuote: {Active: $Active, EnrollmentId: $EnrollmentId, Id:$PrepQuoteId, Type: $Type, Year: $Year}, 
               TaxReturn:{Id:$ReturnId, ProfileId: $ProfileId, Year: $Year} 
             }
    ) {
      TaxPrepFilingType {
        CreatedAt
        Id
        Status
        TaxReturnId
        Type
        UpdatedAt
      }
      TaxPrepQuote {
        Active
        CreatedAt
        EnrollmentId
        FinalPrice
        Id
        QuotePrice
        Type
        UpdatedAt
        Year
      }
      TaxReturn {
        Active
        CancelledAt
        CompletedAt
        CreatedAt
        EntityId
        EntityType
        Id
        InitiatedAt
        Price
        ProfileId
        QuoteId
        Status
        TaxProfessionalId
        TaxProfessionalProvider
        UpdatedAt
        Year
      }
    }
  }
`;