import React, { useEffect, useState } from "react";

import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { GetPhoneNumbers } from "../../queries/GetPhoneNumbers";
import { clientConfig } from "../../awsClientConfig";

type PhoneNumberResponse = {
   data : {
    GetPhoneNumbers: {
      PartnerContactUs: string
    }
   }
}

function PartnerContactUs() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();
  const client = new clientConfig();

  useEffect(() => {
    client
    .query({
      query: GetPhoneNumbers,
    }).then((data:any) => {
      let contactUsNumber = data?.data?.GetPhoneNumbers?.PartnerContactUs;
      contactUsNumber = "1-"+contactUsNumber.replace("(","").replace(") ", "-");
      setPhoneNumber(contactUsNumber)

    })
  })


  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")} >Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Help & Support</Breadcrumb.Item>
              <Breadcrumb.Item active >Contact Us</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3 justify-content-between align-items-center">
              <Col lg={9}>
                <h4 style={{ color: "#0A579B", marginBottom: "3rem" }} className="text-main-dark ff-Montserrat fw-bold">
                  Contact Us
                </h4>

                <p className="content-text">
                  {`Please call Optima regarding any question you may have at ${phoneNumber} or email partnerprogram@optimataxrelief.com to get in touch with our award-winning customer service team.`}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerContactUs;
