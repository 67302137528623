import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
interface MessageOptions {
  message: string;
  type: string;
  rest?: any;
}
const Message = (option: MessageOptions) => {
  const { type, message , rest} = option;
  switch(type) {
    case "success":
      return <SuccessMessage message={message} type={type}></SuccessMessage>
    case "error":
      return <ErrorMessage message={message} type={type}></ErrorMessage>
    default:
      return <SuccessMessage message={message} type={type}></SuccessMessage>
  }
};

const ErrorMessage = (option: MessageOptions) => {
  const { message , rest} = option;
  return (
    <ErrorContainer>
      <FontAwesomeIcon className="m-2" icon={faExclamationCircle} />
      <span {...rest}>{ message }</span>
    </ErrorContainer>
  );
};

const SuccessMessage = (option: MessageOptions) => {
  const { message , rest} = option;
  return (
    <SuccessContainer>
      <FontAwesomeIcon className="m-2" icon={faCheckCircle} />
      <span {...rest}>{ message }</span>
    </SuccessContainer>
  );
};

export default Message;

const MsgContainer = styled.div`
  display: flex;
  flex-direction: initial;
  margin: .5rem 0;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  >span {
    display: inline-flex;
    align-items: center;
  }
`;

const ErrorContainer = styled(MsgContainer)`
  color: var(--color-danger);
  background: #ffeceb;
  border-color: #dc354559;
`;

const SuccessContainer = styled(MsgContainer)`
  color: var(--color-success);
  background: #b7f5a75c;
  border-color: #49ad3157;
  >span {
    color: var(--color-success);
  }
`;