import "./App.css";
import AppRoutes from "./routes/Routes";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/globalStyles";
import { lightTheme } from "./styles/Themes";

import { BrowserRouter as Router } from "react-router-dom";
import { ReactTagManager } from 'react-gtm-ts';

ReactTagManager.init({
    code: 'GTM-5C65XM3', // GTM Code
    debug: false, // debug mode (default false)
});

function App() {
  const themeMode = lightTheme;
  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
