import { useContext, useState } from "react";
import TaxOrganizerContext, { IncomeSourceModel, InitialIncomeSourceModel } from "./TaxOrganizerContext";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { formatZipcode } from "../../../functions/misc";
import IncomeSourceCard from "./IncomeSourceCard";
import { US_STATES } from "../../../Common/States";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg'; 

const IncomeSources = () => {
    const [validated, setValidated] = useState(false);
    const {selectedRequest, taxOrganizerData, setTaxOrganizerData} = useContext(TaxOrganizerContext);
    const [showIncomeSourceModal, setShowIncomeSourceModal] = useState(false);
    const [incomeSourcetAction, setIncomeSourceAction] = useState('edit');
    const [selectedIncomeSource, setSelectedIncomeSource] = useState<IncomeSourceModel>(InitialIncomeSourceModel);

    const incomeSourceModalCloseHandler = () => {
        setShowIncomeSourceModal(false);
    }

    const incomeSourceModalOpenHandler = (actionType: string, incomeSource:IncomeSourceModel) => {
        setShowIncomeSourceModal(true);
        setIncomeSourceAction(actionType);
        setSelectedIncomeSource(incomeSource);
    }

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    const changeDependentHandler = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        if(fieldName === 'companyZip'){
            setSelectedIncomeSource(prevState => ({...prevState, [fieldName]: formatZipcode(event)}));
        }else {
            setSelectedIncomeSource(prevState => ({...prevState, [fieldName]: fieldValue}));
        }
        
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            incomeSourcetAction === 'new'?addIncomeSourceToContext(): updateIncomeSourceToContext();
        }
        setValidated(true);
    }

    const addIncomeSourceToContext = () => {
        setTaxOrganizerData({...taxOrganizerData, 
            incomeSources: [...taxOrganizerData.incomeSources, 
                {...selectedIncomeSource, id: taxOrganizerData.incomeSources.length+1}]});
        setShowIncomeSourceModal(false);
        setSelectedIncomeSource(InitialIncomeSourceModel);
    }

    const updateIncomeSourceToContext = () => {
        const incomeSources = taxOrganizerData.incomeSources.filter(incomeSource => (incomeSource.id !== selectedIncomeSource.id));
        setTaxOrganizerData({...taxOrganizerData, 
            incomeSources: [...incomeSources, selectedIncomeSource ]});
        setShowIncomeSourceModal(false);
        setSelectedIncomeSource(InitialIncomeSourceModel);
    }

    const removeIncomeSourceFromContext = () => {
        const incomeSources = taxOrganizerData.incomeSources.filter(incomeSource => (incomeSource.id !== selectedIncomeSource.id));
        setTaxOrganizerData({...taxOrganizerData, 
            incomeSources: [...incomeSources]});
        setShowIncomeSourceModal(false);
        setSelectedIncomeSource(InitialIncomeSourceModel);
    } 

    return (
        <>
        <div style={{marginBottom: '3rem'}}>
            <Row>
            <Col xl={9} md={9}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name} Income Sources
            </h4>
            </Col>
            <Col xl={3} md={3} style={{textAlign: 'right'}}>
                {taxOrganizerData.incomeSources.length !== 0 &&
                    <button className="btn-continue" onClick={() => incomeSourceModalOpenHandler('new', selectedIncomeSource)}>{'+ADD Income Source'}</button>
                }
            </Col>
            </Row>
            <p className="content-text" style={{marginBottom: '3rem'}}>
                Please provide your personal information to complete the return. If you filed jointly, we will need both individual's information.
            </p>
            <div className="mt-6">
                <Row>
                    {taxOrganizerData.incomeSources.length !== 0 && 
                        <>
                        {taxOrganizerData.incomeSources.map((incomeSource: IncomeSourceModel) => {
                            return (
                            <Col lg={3} className="mb-2">
                                <IncomeSourceCard key={incomeSource.id} incomeSource= {incomeSource} onAction={incomeSourceModalOpenHandler}/>
                            </Col>
                            )
                        })}
                        </>
                    }
                    {taxOrganizerData.incomeSources.length === 0 && 
                         <Col lg={3}>
                            <IncomeSourceCard onAction={incomeSourceModalOpenHandler}/>
                        </Col>
                    }
                </Row>
            </div>
        </div>

        <Modal size="lg" centered show={showIncomeSourceModal} onHide={incomeSourceModalCloseHandler}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={incomeSourceModalCloseHandler} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="d-block mx-auto">
            <h6 className="fw-bold content-box-subtitle">Add an Income Source for {selectedRequest?.Name}</h6>
            
            <Form className="mt-4" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Col md={7}>
                    <Form.Label>Who received the income? *</Form.Label>
                    <Form.Select
                    required
                    value={selectedIncomeSource?.incomeSourcePerson}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="incomeSourcePerson"
                    >
                        <option value="">Select One</option>
                        <option value="Person1">Person 1</option>
                        <option value="Person2">Person 2</option>
                        <option value="Person3">Person 3</option>
                        <option value="Person4">Person 4</option>
                    </Form.Select>
                </Col>
                <Col md={5}>
                    <Form.Label>Income Source *</Form.Label>
                    <Form.Select
                    value={selectedIncomeSource?.incomeSource}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="incomeSource"
                    >
                        <option value="">Select One</option>
                        <option value="Source1">Source 1</option>
                        <option value="Source2">Source 2</option>
                        <option value="Source3">Source 3</option>
                        <option value="Source4">Source 4</option>
                    </Form.Select>
                </Col>
            </Row>
            <hr className="my-5"/>
            <Row className="mt-2">
                <Col md={4}>
                    <Form.Label>Company*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.company}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="company"
                    type="text"
                    />
                </Col>
                <Col md={3}>
                    <Form.Label>EIN* <QuestionMark /> </Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.ein}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="ein"
                    type="text"
                    />
                </Col>
                <Col md={5}>
                    <Form.Label>Occupation*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.occupation}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="occupation"
                    type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={6}>
                    <Form.Label>Company Address*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.companyAddress}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="companyAddress"
                    type="text"
                    />
                </Col>
                <Col md={6}>
                    <Form.Label>Company Address 2*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.companyAddress2}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="companyAddress2"
                    type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={6}>
                    <Form.Label>Company City*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.companyCity}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="companyCity"
                    type="text"
                    />
                </Col>
                <Col md={3}>
                    <Form.Label>Company State *</Form.Label>
                    <Form.Select
                    required
                    value={selectedIncomeSource?.companyState}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="companyState"
                    >
                        <option value="">Select State</option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                </Col>
                <Col md={3}>
                    <Form.Label>Company Zip*</Form.Label>
                    <Form.Control
                    required
                    value={selectedIncomeSource?.companyZip}
                    onChange={(event) => {changeDependentHandler(event)}}
                    name="companyZip"
                    type="text"
                    />
                </Col>
            </Row>
            {incomeSourcetAction === 'new' && 
                <div className="mt-5" style={{textAlign: 'right'}}>
                    <button className="btn-continue-outline me-2" onClick={incomeSourceModalCloseHandler}>Cancel</button>
                    <button className="btn-continue" type="submit">Save</button>
                </div>
                }
                {incomeSourcetAction === 'edit' && 
                <div className="mt-5" style={{textAlign: 'right'}}>
                    <button className="btn-continue-outline me-2" onClick={incomeSourceModalCloseHandler}>Cancel</button>
                    <button className="btn-continue me-2" type="submit">Update</button>
                    <button className="btn-red" onClick={removeIncomeSourceFromContext}>Remove Income Source</button>
                </div>
                }
        </Form>
            </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default IncomeSources;