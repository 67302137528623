import { Auth } from "aws-amplify";

export const userProfileData = async () => {
  const res = await Auth.currentAuthenticatedUser();
  if (res.attributes["custom:Id"]) {
    return res.attributes["custom:Id"];
  } else {
    const result = await Auth.updateUserAttributes(res, {});
    console.log(result);
    let user = await Auth.currentAuthenticatedUser();
    return user.attributes["custom:Id"];
  }
};

export const userAccountData = async () => {
  const res = await Auth.currentAuthenticatedUser();
  console.log(res);
  if (res.attributes["custom:AccountId"]) {
    return res.attributes["custom:AccountId"];
  } else {
    const result = await Auth.updateUserAttributes(res, {});
    console.log(result);
    let user = await Auth.currentAuthenticatedUser();
    return user.attributes["custom:AccountId"];
  }
};

export const userAccountEmail = async () => {
  const res = await Auth.currentAuthenticatedUser();
  console.log(res);
  if (res.attributes["email"]) {
    return res.attributes["email"];
  } else {
    const result = await Auth.updateUserAttributes(res, {});
    console.log(result);
    let user = await Auth.currentAuthenticatedUser();
    return user.attributes["email"];
  }
};
