import { createContext } from "react";

export const IntialtaxProfessional = {
  licenses: [],
  ptin: "",
  eoCoverage: 249999,
  eoInsurance: true,
  languages: [],
  filingSoftware: [],
  yearsOfExperience: 5,
  bookkeeping: true,
  taxReturnsPrepared: 100,
  returnSchedules: [],
  returnForms: [],
  irsAudit: true,
  irsAuditDesc: "",
  disciplinaryAction: true,
  disciplinaryActionDesc: "",
  prepCompetency: true,
  taxResolution: true,
};

export type TaxProfessional = {
  licenses: string[];
  ptin: string;
  eoCoverage: number;
  eoInsurance: boolean;
  languages: string[];
  filingSoftware: string[];
  yearsOfExperience: number;
  bookkeeping: boolean;
  taxReturnsPrepared: number;
  returnSchedules: string[];
  returnForms: string[];
  irsAudit: boolean;
  irsAuditDesc: string;
  disciplinaryAction: boolean;
  disciplinaryActionDesc: string;
  prepCompetency: boolean;
  taxResolution: boolean;
};
interface ContextState {
  next: () => void;
  enrollmentId: string;
  taxProfessionalId: string;
  setEnrollmentId: (id: string) => void;
  organizationId: null | string;
  setOrganizationId: (id: string | null) => void;
  taxProfessional: TaxProfessional;
  setTaxProfessional: (data: TaxProfessional) => void;
  setTaxProfessionalId: (data: string) => void;
  applicationStatus: string;
  setApplicationStatus: (data: string) => void
  s3key:string
  setS3Key:(id:string)=>void
  s3Bucket:string
  setS3Bucket:(id:string)=>void
  NextEnrollmentAction:(action:string)=>void
  setNextFormId:(id:string)=>void
  nextFormId:string
}

const PartnerEnrollmentFormContext = createContext({} as ContextState);

export default PartnerEnrollmentFormContext;

export const { Provider, Consumer } = PartnerEnrollmentFormContext;
