import "./navbar.css";
import userSupport from "../assets/userSupport.svg";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Explanation() {
  const navigate = useNavigate();
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box py-5 px-lg-5">
            <Row className="row px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="content-box-heading mb-3">
                  Based on Your Selections, You Don’t Qualify for the Report{" "}
                </h1>
                <p className="content-text">
                  We appreciate your interest but based on your previous
                  selections we are unable to provide a report for you at this
                  time.
                </p>
                <p className="content-text">
                  Currently, this report is only available for single taxpayers.
                </p>
                <p className="content-text">
                  But we can still help! We recommend a free consultation with
                  one of our Senior Tax Associates to help evaluate your current
                  tax situation and IRS collection risk.
                </p>
                <button
                  onClick={() => navigate("/appointments")}
                  className="btn-xl mb-sm-5"
                >
                  Get a Free Consultation
                </button>
              </Col>
              <Col lg={5}>
                <img
                  src={userSupport}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Explanation;
