import gql from "graphql-tag";
export const GetProtectionPlanEligibility = gql`
  query GetProtectionPlanEligibility($ProfileId: ID!) {
    GetProtectionPlanEligibility(ProfileId: $ProfileId) {
      EnrollmentInitiated
      EnrollmentType
      IsEligible
      LastTaxRiskReport {
        Id
        OverallRisk
        OverallRiskDescription
        RequestedAt
        Status
      }
    }
  }
`;
