import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import Loading from "../../Common/Loading";
import { Provider } from "./SpouseTaxPrepFormContext";
import ServiceAgreement from "../SpouseTaxPrepService/ServiceAgreement";
import Signature from "../SpouseTaxPrepService/Signature";
import EnrollCompletedPage from "../SpouseTaxPrepService/EnrollCompletedPage";
import { FormStatus, GetLatestFormsByProfile } from "../../queries/GetLatestFormsByProfile";
import { fetchQuery } from "../../functions/misc";
import useProfileStore from "../../store/ProfileStore";
import { GetEnrollmentsByProfile } from "../../queries/GetEnrollmentsByProfile";

const renderStep = (step: number) => {
  switch (step) {
    case 0:
      return <ServiceAgreement />;
    case 1:
      return <Signature />;
    case 2:
      return <EnrollCompletedPage />;
    case 3:
        return <Loading />;
    default:
      return null;
  }
};

const SpouseTaxPrepForm = () => {
  const [currentStep, setCurrentStep] = useState(3);
  const [pageName, setPageName] = useState('');
  const [enrollmentId, setEnrollmentId] = useState("");
  const [nextFormId, setNextFormId] = useState("");
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const { spouseProfile } = useProfileStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPendingForms();
  }, []);

  const fetchPendingForms = async (): Promise<void> => {
  const getEnrollmentsByProfile = await fetchQuery({ ProfileId: spouseProfile?.ParentId }, GetEnrollmentsByProfile);
  const taxPrepNetworkEnrollment = getEnrollmentsByProfile?.Enrollments.filter((enrollment: any) => enrollment?.Type === 'TaxPrepNetwork').pop();
  if (taxPrepNetworkEnrollment) {
    setEnrollmentId(taxPrepNetworkEnrollment.Id);
  }

    const variables = {
      ProfileId: spouseProfile?.Id,
      Status: FormStatus.Pending
    }
    try {
      const getLatestPendingFormsByProfile = await fetchQuery(variables, GetLatestFormsByProfile);
      if (getLatestPendingFormsByProfile?.length === 0) {
        const variables = {
          ProfileId: spouseProfile?.Id,
        }
        const getLatestCompletedFormsByProfile = await fetchQuery(variables, GetLatestFormsByProfile);
        const completedForm = getLatestCompletedFormsByProfile.pop();
        if (completedForm.Status === FormStatus.Completed) {
          setCurrentStep(2);
        }
      } else {
        const form = getLatestPendingFormsByProfile.pop();
        setNextFormId(form.Id);
        if (form?.S3Key) {
          setS3Key(form.S3Key);
          setS3Bucket(form.S3Bucket);
          SetS3Config(form.S3Bucket, "public");
          setCurrentStep(0);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const navigateToPage = (pageName: string) => {
    setPageName(pageName);
    if (pageName === "serviceAgreementSign") {
      setCurrentStep(1);
    }
    if (pageName === "completed") {
      setCurrentStep(2);
    }
    if(!pageName){
      navigate("/dashboard");
    }
  };

  return (
    <Provider
      value={{
        navigateToPage,
        enrollmentId,
        setEnrollmentId,
        nextFormId,
        setNextFormId,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
      }}
    >
      <main>
        {renderStep(currentStep)}
      </main>
    </Provider>
  );
};
export default SpouseTaxPrepForm;
