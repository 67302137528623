import gql from "graphql-tag";

export const ReferAdditionalServices = gql`
  mutation ReferAdditionalServices($LeadId: ID!) {
    ReferAdditionalServices(LeadId: $LeadId) {
    AccountId
    Active
    ConvertedAt
    CreatedAt
    Email
 }
}
`;
