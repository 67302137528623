import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Message from "../../components/Message";
import { formatZipcode } from "../../functions/misc";
import { StateOptions } from "../../contants";

const FORM_ERROR = {
  name: "",
  eni: "",
  phone: "",
  type: "",
  streetAddress1: "",
  zipCode: "",
  state: "",
  city: "",
};

function ProfileInfo({
  organization,
  submitted,
  setOrganization,
  setOrganizationValidated,
  mainOrganizationAddress,
  setMainOrganizationAddress,
}: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (organization?.Name) {
        error.name = "";
      } else {
        error.name = "Business Name can not be empty";
      }

      if (organization?.Ein) {
        if (organization?.Ein.length !== 9) {
          error.eni = "Federal Tax ID (EIN) must be 9 characters";
        } else {
          error.eni = "";
        }
      } else {
        error.eni = "Federal Tax ID (EIN) can not be empty";
      }

      if (organization?.Phone) {
        error.phone = "";
      } else {
        error.phone = "Phone can not be empty";
      }

      if (organization?.Type) {
        error.type = "";
      } else {
        error.type = "Business type can not be empty";
      }

      if (mainOrganizationAddress.StreetAddress1) {
        error.streetAddress1 = "";
      } else {
        error.streetAddress1 = "Street Address can not be empty";
      }

      if (mainOrganizationAddress.City) {
        error.city = "";
      } else {
        error.city = "City can not be empty";
      }

      if (mainOrganizationAddress.State) {
        error.state = "";
      } else {
        error.state = "State can not be empty";
      }

      if (mainOrganizationAddress.ZipCode) {
        error.zipCode = "";
      } else {
        error.zipCode = "ZIP Code can not be empty";
      }

      setFromErros(error);

      const isAnyError = Object.values(error).find((item) => Boolean(item));
      setOrganizationValidated(isAnyError ? false : true);
    };
    validateForm();
  }, [organization, mainOrganizationAddress]);

  function formatPhoneNumber(str: any) {
    if (str) {
      const phoneNumber = str.replace(/[^\d]/g, "");
      if (phoneNumber.length < 4) {
        return phoneNumber;
      }

      if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.length < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    }
  }

  const onChange = (event: any) => {
    const newBusiness = {
      ...organization,
      [event.target.name]: event.target.value,
    };

    setOrganization(newBusiness);
  };

  const onChangeAddress = (event: any) => {
    const newBusinessAddress = {
      ...mainOrganizationAddress,
      [event.target.name]:
        event.target.name !== "ZipCode"
          ? event.target.value
          : formatZipcode(event),
    };

    setMainOrganizationAddress(newBusinessAddress);
  };

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Business Information
          </h6>
        </div>
      </Col>
      <Row className="mb-3 justify-content-between">
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Business Name</Form.Label>
            <Form.Control
              name="Name"
              onChange={onChange}
              value={organization.Name}
              type="text"
            />
            {submitted && formErrors.name && (
              <Message message={formErrors.name} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Label>Business type</Form.Label>
          <Form.Select
            name="Type"
            onChange={onChange}
            value={organization.Type}
            aria-label="Default select example"
          >
            <option value="SoleProprietorship">Sole Proprietorship</option>
            <option value="Partnership">Partnership</option>
            <option value="SCorporation">Corporation</option>
            <option value="Corporation">S Corporation</option>
            <option value="LimitedLiabilityCompany">
              Limited Liability Company (LLC)
            </option>
          </Form.Select>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Federal Tax ID (EIN)</Form.Label>
            <Form.Control
              name="Ein"
              onChange={onChange}
              value={organization?.Ein}
              type="text"
              placeholder=""
            />
            {submitted && formErrors.eni && (
              <Message message={formErrors.eni} type="error"></Message>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-between">
        <Col lg={6}>
          <Form.Group controlId="">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="StreetAddress1"
              onChange={onChangeAddress}
              value={mainOrganizationAddress.StreetAddress1}
            />

            {submitted && formErrors.streetAddress1 && (
              <Message
                message={formErrors.streetAddress1}
                type="error"
              ></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group controlId="">
            <Form.Label>Street Address 2</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="StreetAddress2"
              onChange={onChangeAddress}
              value={mainOrganizationAddress.StreetAddress2}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-between">
        <Col>
          <Form.Group controlId="">
            <Form.Label>City </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="City"
              onChange={onChangeAddress}
              value={mainOrganizationAddress.City}
            />

            {submitted && formErrors.city && (
              <Message message={formErrors.city} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>State</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="State"
              onChange={onChangeAddress}
              value={mainOrganizationAddress.state}
            >
              {StateOptions.map((state) => {
                return <option value={state}>{state}</option>;
              })}

              {submitted && formErrors.state && (
                <Message message={formErrors.state} type="error"></Message>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>ZIP Code</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ZipCode"
              onChange={(e) => onChangeAddress(e)}
              value={mainOrganizationAddress.ZipCode}
            />
            {submitted && formErrors.zipCode && (
              <Message message={formErrors.zipCode} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            name="Phone"
            onChange={onChange}
            value={formatPhoneNumber(organization?.Phone)}
            type="phone"
          />

          {submitted && formErrors.phone && (
            <Message message={formErrors.phone} type="error"></Message>
          )}
        </Col>
      </Row>
    </Row>
  );
}

export default ProfileInfo;
