import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Message from "../Message";
import { formatZipcode } from "../../functions/misc";
import { StateOptions } from "../../contants";

const FORM_ERROR = {
  streetAddress1: "",
  zipCode: "",
  state: "",
  city: "",
};
function MailingAddress({
  organizationAddresses,
  setOrganizationMailingAddress,
  organizationMailingAddress,
  setOrganizationMailingValidated,
  mailingAddressType,
  setMailingAddressType,
  submitted
}: any) {
  const [formErrors, setFromErros] = useState(FORM_ERROR);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };
      if (organizationMailingAddress.StreetAddress1) {
        error.streetAddress1 = "";
      } else {
        error.streetAddress1 = "Street Address can not be empty";
      }

      if (organizationMailingAddress.City) {
        error.city = "";
      } else {
        error.city = "City can not be empty";
      }

      if (organizationMailingAddress.State) {
        error.state = "";
      } else {
        error.state = "State can not be empty";
      }

      if (organizationMailingAddress.ZipCode) {
        error.zipCode = "";
      } else {
        error.zipCode = "ZIP Code can not be empty";
      }

      setFromErros(error);

      const isAnyError = Object.values(error).find((item) => Boolean(item));
      setOrganizationMailingValidated(isAnyError ? false : true);
    };
    validateForm();
  }, [organizationMailingAddress]);

  const onChangeAddress = (event: any) => {
    const newBusinessAddress = {
      ...organizationMailingAddress,
      [event.target.name]:
        event.target.name != "ZipCode"
          ? event.target.value
          : formatZipcode(event),
    };

    setOrganizationMailingAddress(newBusinessAddress);
  };

  const onChangeUseAddress = (event: any) => {
    let type = event.target.value;
    const mailingAddress = { ...organizationMailingAddress };
    setMailingAddressType(type);

    if (type === 'Main' || type === 'Storefront') {
      const address = organizationAddresses?.find((address: any) => {
        return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === type);
       });
      setOrganizationMailingAddress(address);
      return;
    }
    mailingAddress.City = "";
    mailingAddress.State = "AL";
    mailingAddress.StreetAddress1 = "";
    mailingAddress.StreetAddress2 = "";
    mailingAddress.ZipCode = "";

    setOrganizationMailingAddress(mailingAddress);
  };



  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Mailing Address
          </h6>
        </div>
      </Col>
      <Row className="mb-3 justify-content-between">
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>
              Is your mailing address the same as a previous address?
            </Form.Label>
            <Form.Select
              onChange={onChangeUseAddress}
              aria-label="Default select example"
              value={mailingAddressType}
            >
              {organizationAddresses.map((address: any, index: any) => {
              return (
              <option key={index} value={address.OrganizationAddressTypes[0].Type}>{address.OrganizationAddressTypes[0].Type} - {address.StreetAddress1}</option>
              )
            })}
            <option value="Mailing">New Address</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              name="StreetAddress1"
              onChange={onChangeAddress}
              value={organizationMailingAddress.StreetAddress1}
              type="text"
              placeholder=""
            />

            {submitted && formErrors.streetAddress1 && (
              <Message
                message={formErrors.streetAddress1}
                type="error"
              ></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Street Address 2</Form.Label>
            <Form.Control
              name="StreetAddress2"
              onChange={onChangeAddress}
              value={organizationMailingAddress.StreetAddress2}
              type="text"
              placeholder=""
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-between">
        <Col>
          <Form.Group controlId="">
            <Form.Label>City </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="City"
              value={organizationMailingAddress.City}
              onChange={onChangeAddress}
            />

            {submitted && formErrors.city && (
              <Message message={formErrors.city} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>State</Form.Label>
            <Form.Select
              name="State"
              onChange={onChangeAddress}
              value={organizationMailingAddress.State}
              aria-label="Default select example"
            >
              {StateOptions.map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </Form.Select>

            {submitted && formErrors.state && (
              <Message message={formErrors.state} type="error"></Message>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="">
            <Form.Label>ZIP Code</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ZipCode"
              value={organizationMailingAddress.ZipCode}
              onChange={(e) => onChangeAddress(e)}
            />

            {submitted && formErrors.zipCode && (
              <Message message={formErrors.zipCode} type="error"></Message>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}

export default MailingAddress;
