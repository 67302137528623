import React, { useContext } from "react";
import "../../pages/navbar.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReferalClientFormContext from "./ReferalClientFormContext";
import Union from "../../assets/Union.svg";

function ReferralPlan() {
  const { next, priceDetail } = useContext(ReferalClientFormContext);

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Row className="px-lg-5 justify-content-between">
              <Col lg={6}>
                <h1
                  style={{ color: "#0A579B" }}
                  className="content-box-heading mb-3"
                >
                  Optima Tax Protection Plan
                </h1>
                <p className="content-text">
                  Whether it's up-to-date alerts on your tax file, or protection
                  from costly audits - Optima has you covered.
                </p>
                
                <button onClick={next} className="btn-continue mt-3">
                  Get Protected
                </button>
                
              </Col>
              <Col lg={5}>
                <h1
                  style={{ color: "#1589D2" }}
                  className="content-box-heading mb-2 material-blue"
                >
                  {`${priceDetail.MonthlyPrice}/mo. ${priceDetail.ChargeSalesTax?"+ tax":""}`}
                </h1>
                <p style={{ color: "#1C3B6B" }} className="lead-heading">
                  Protection & Monitoring Benefits
                </p>
                <ul style={{ padding: 0 }} className="monthly-plan">
                  <li className="flexBox">
                    <img
                      src={Union}
                      style={{ paddingRight: 8 }}
                      className="w-5 d-block"
                      alt="userSupport"
                    />
                    Monthly Tax Risk Report
                  </li>

                  <li className="flexBox">
                    <img
                      src={Union}
                      style={{ paddingRight: 8 }}
                      className="w-5 d-block"
                      alt="userSupport"
                    />
                    Individual Audit Defense*
                  </li>

                  <li className="flexBox">
                    <img
                      src={Union}
                      style={{ paddingRight: 8 }}
                      className="w-5 d-block"
                      alt="userSupport"
                    />
                    IRS Tax Authorization (8821) Filed
                  </li>

                  <li className="flexBox">
                    <img
                      src={Union}
                      style={{ paddingRight: 8 }}
                      className="w-5 d-block"
                      alt="userSupport"
                    />
                    IRS Balance Monitoring
                  </li>

                  <li className="flexBox">
                    <img
                      src={Union}
                      style={{ paddingRight: 8 }}
                      className="w-5 d-block"
                      alt="userSupport"
                    />
                    Discounted Tax Resolution Fees
                  </li>

                  <li>
                    *Limitations apply, please review the Optima Client Service
                    Agreement for full disclosure of services included.
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReferralPlan;
