import "../../pages/navbar.css";
import {  useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Auth } from "aws-amplify";
import useProfileStore, { useGroupStore } from "../../store/ProfileStore";

function ApplicationNotApproved() {
  const { clearPartnerProfile } = useProfileStore();
  const { clearPrimaryGroup } = useGroupStore();

  const navigate = useNavigate();

  const onLogout = () => {
    Auth.signOut().then((res) => {
      clearPrimaryGroup();
      clearPartnerProfile();
      navigate("/login");
    });
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
              <h1 className="content-box-heading mb-3">
                We Weren't Able to Approve Your Application
              </h1>
              <p className="content-text">
                Unfortunately, we are unable to approve your application at this time. Please contact our Partner Liaison team for more information at (800) 536-0734.
              </p>

              <div className="d-flex mt-5 align-items-center">
                <button onClick={onLogout} className="btn-continue">
                  Log Out
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ApplicationNotApproved;
