import { useContext, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext, { ReturnStates, initialReturnState } from './TaxPreparationServiceFormContext';
import StateReturnsForm from './StateReturnsForm';
import OverlayHelpTooltip from '../../Common/OverlayHelpTooltip';
import { Auth } from 'aws-amplify';
import { userProfileData } from '../../Common/profileInfo';
import { clientConfig } from '../../awsClientConfig';
import { createEnrollmentAction } from '../../queries/CreateEnrollmentAction';
import { GetTaxReturnIncome } from '../../queries/GetTaxReturnIncome';
import { UpdateTaxReturnIncome } from '../../queries/UpdateTaxReturnIncome';
import { CreateTaxReturnIncome } from '../../queries/CreateTaxReturnIncome';
import { getCurrentTaxYear } from '../../functions/misc';
import useEnrollmentStore from "../../store/EnrollmentStore";
import PaymentCompletedMessage from "./PaymentCompletedMessage";
import { NOT_SUPPORTED_STATES, US_STATES } from '../../Common/States';

const INCOME_FORM_LABELS =  {
  SELF_EMPLOYMENT: 'I.e., Did you earn income as an independent contractor or from your business that you operate? Alternateively, Last year did you have a Schedule C where you reported your income and expenses from your business?',
  CAPITAL_GAINS: 'Specify if you had any gains or losses from selling investments such as stocks, crypto, bonds, etc. These transactions are often reported on Form 1099-B.',
  REAL_ESTATE: 'This also includes short sales, foresclosures, and abandonment of real property.',
  RENTAL: 'Rental income can be generated from rental property, land, or equipment.',
  ROYALTIES: 'Royalty income is the amount received through a licensing or rights agreement for the use of copyrighted works, influencer endorsements, intellectual property like patents, or natural resources like oil and gas properties, often including an upfront payment and ongoing earnings and payments.',
  PARTNERSHIP: 'This includes income or losses from corporations, partnerships, or trusts.',
  FARM: 'This includes raising livestock as well general agriculture.',
  STATE_RETURN: 'Please select all applicable states.'
}

const INCOME_FORM_TOOLTIP_LABELS =  {
  SELF_EMPLOYMENT: 'Self-employment income is any income that you earn from a trade or business that you operate as your own boss. This would be income other than income earned from an employer (commonly issued on a W-2). Self-employment income can include income from a variety of activities, such as: Freelance work; consulting; contracting; or operating a business.',
  CAPITAL_GAINS: 'You\'ll commonly receive a Form 1099-B from the financial institution/broker that is used in the transactions that led to the capital gain or loss from the sale of common investments such as stocks. Howver, this form can also report sales of collectibles, securities contracts, and bartering transactions.',
  REAL_ESTATE: 'For a foreclosed property, you might receive both a Form 1099-A and a Form 1099-C. A lender would send you a 1099-C if they canceled any remaining mortgage debt after the foreclosure.',
  RENTAL: 'Select "No" if you received rent through another entity in form of a K-1. Instead please see question on partnership income or losses below.',
  ROYALTIES: 'Select "No" if you received royalties and are in business as a self-employed writer, inventor, artist, etc. as this is considered sel-employement income (Self-Employment Income question should be marked "Yes")',
  PARTNERSHIP: 'K-1 will normally be issued by applicable entities as result of filing any of the following forms (1120-S, 1065, or 1041)',
  STATE_RETURN:'If unsure, please talk to your assigned tax professional to add this at a later time.'
}

const IncomeForm = () => {
    const {taxPreparationData, setTaxPreparationData, navigateToPage, enrollmentId, nextEnrollmentAction, quoteId, taxReturnId, completedPagesHandler } = useContext(TaxPreparationServiceFormContext);
    const client = new clientConfig();

    const [formData, setFormData] = useState(taxPreparationData.incomeInfoData);
    const [showBusinessForm, setShowBusinessForm] = useState(taxPreparationData.incomeInfoData.selfIncone === 'Yes'?true:false);
    const [showRentalForm, setShowRentalForm] = useState(taxPreparationData.incomeInfoData.rentals === 'Yes'?true:false);
    const [showStateReturnsForm, setShowStateReturnsForm] = useState(taxPreparationData.incomeInfoData.stateReturn === 'Yes'?true:false);
    const [showError, setShowError] = useState({
      mainError: false,
      businessError: false,
      rentalError: false,
      stateError: false
    });
    const [taxReturnIncomeData, setTaxReturnIncomeData] = useState<any>();
    const { taxPrepEnrollment } = useEnrollmentStore();
    const [showStateAlert, setShowStateAlert] = useState(false);

    useEffect(() => {
      getTaxReturnIncomeFormData();
    },[]);

    const getTaxReturnIncomeFormData = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturnIncome, variables: variables });
        if(res.data.GetTaxReturnIncome){
          setTaxReturnIncomeData(res.data.GetTaxReturnIncome);
          const {TaxPrepIncome, TaxPrepStateReturn} = res.data.GetTaxReturnIncome;
          if(TaxPrepIncome && TaxPrepStateReturn) {
            let returnStates: ReturnStates[] = [];
            if(TaxPrepStateReturn.length > 0) {
              for(let index = 0; index < TaxPrepStateReturn.length; index++){
                returnStates.push({Id:TaxPrepStateReturn[index].Id,  State: TaxPrepStateReturn[index].State});
              }
            }
            setFormData(prevState => ({...prevState, selfIncone: TaxPrepIncome.HasSelfEmploymentIncome,
              grossReceiptsAmount: TaxPrepIncome.AnnualGrossReceipts,
              capitalGains: TaxPrepIncome.HasCapitalGainsOrLosses,
              realEstate: TaxPrepIncome.SoldRealEstate,
              rentals: TaxPrepIncome.HasRentalIncomeOrLosses,
              rentalsCount: TaxPrepIncome.RentalsCount,
              partnershipIncome: TaxPrepIncome.HasPartnershipIncomeOrLosses,
              farmIncome: TaxPrepIncome.OperatedFarm,
              royalties: TaxPrepIncome.ReceivedRoyalties,
              stateReturn: TaxPrepIncome.NeedStateReturn,
              returnStatesData: returnStates
            }));
            setShowBusinessForm(TaxPrepIncome.HasSelfEmploymentIncome === 'Yes'?true:false);
            setShowRentalForm(TaxPrepIncome.HasRentalIncomeOrLosses === 'Yes'?true:false);
            setShowStateReturnsForm(TaxPrepIncome.NeedStateReturn === 'Yes'?true:false);
          }
        }
        }catch(e){}
    }

    const continueHandler = async () => {
      if (taxPrepEnrollment?.paymentCompleted) {
        navigateToPage('serviceQuote');
        return;
      }
        if(validateForm()) {
          setTaxPreparationData({...taxPreparationData, incomeInfoData: formData});
          const profileId = await userProfileData();
          if(taxReturnIncomeData?.TaxPrepIncome?.Id){
            const variables = {
              ClientProfileId: profileId,
              Id: taxReturnIncomeData?.TaxPrepIncome?.Id,
              ProfileId: profileId,
              HasSelfEmploymentIncome: formData.selfIncone,
              AnnualGrossReceipts: formData.grossReceiptsAmount,
              HasCapitalGainsOrLosses: formData.capitalGains,
              SoldRealEstate: formData.realEstate,
              HasRentalIncomeOrLosses: formData.rentals,
              HasPartnershipIncomeOrLosses: formData.partnershipIncome,
              OperatedFarm: formData.farmIncome,
              NeedStateReturn: formData.stateReturn,
              ReceivedRoyalties: formData.royalties,
              RentalsCount: formData.rentalsCount,
              TaxPrepStateReturn: formData.returnStatesData
            };
            try{ 
              const res: any = await client.mutate({ mutation: UpdateTaxReturnIncome, variables: variables });
            }catch(e){}
          }else {
            const returnStates = formData.returnStatesData.map((state:any) => ({State: state.State}));
            const variables = {
              ClientProfileId: profileId,
              QuoteId: quoteId,
              ProfileId: profileId,
              HasSelfEmploymentIncome: formData.selfIncone,
              AnnualGrossReceipts: formData.grossReceiptsAmount,
              HasCapitalGainsOrLosses: formData.capitalGains,
              SoldRealEstate: formData.realEstate,
              HasRentalIncomeOrLosses: formData.rentals,
              HasPartnershipIncomeOrLosses: formData.partnershipIncome,
              OperatedFarm: formData.farmIncome,
              NeedStateReturn: formData.stateReturn,
              ReceivedRoyalties: formData.royalties,
              RentalsCount: formData.rentalsCount,
              TaxPrepStateReturn: returnStates
            };
            try{
              const res: any = await client.mutate({ mutation: CreateTaxReturnIncome, variables: variables });
            }catch(e){}
          } 
          if(taxPreparationData.filingType === 'Personal') {
            const variables = {
              EnrollmentId: enrollmentId,
              Name: "TaxInformationCompleted",
            };
            client
              .mutate({ mutation: createEnrollmentAction, variables: variables })
              .then((res: any) => {
                if (res.data.CreateEnrollmentAction.Enrollment.Id) {
                  nextEnrollmentAction(
                    res.data.CreateEnrollmentAction.Enrollment?.NextEnrollmentAction
                  );
                }
                completedPagesHandler('serviceQuote');
              })
              .catch((error: any) => {
                console.log("error", error);
              });
          }else {
            navigateToPage('incomeForm2');
          }
        } 
    }

    const validateForm = () => {
      let error1 = false;
      let error2 = false;
      let error3 = false;
      let mainError = false;
      if(formData.capitalGains && formData.farmIncome && formData.partnershipIncome 
          && formData.realEstate && formData.rentals && formData.royalties && formData.selfIncone && formData.stateReturn) {
        if(formData.selfIncone === 'Yes' && (!formData.grossReceiptsAmount || formData.grossReceiptsAmount === '0')) {
          error1 = true;
        }
        if(formData.rentals === 'Yes' && (!formData.rentalsCount || formData.rentalsCount === 0)) {
          error2 = true;
        }
        if(formData.stateReturn === 'Yes') {
          for(let index = 0; index < formData.returnStatesData.length; index++){
            if(!formData.returnStatesData[index].State){
              error3 = true;
              break;
            }
          }
        }
      }else {
        mainError = true;
      }
      setShowError({businessError: error1, rentalError: error2, stateError: error3, mainError: mainError});
      if(mainError || error1 || error2 || error3) {
        return false;
      }
      return true;
    }

    const onBackHandler = () => {
      navigateToPage('filingType');
    }

    const validateAmount = (event: any) => {
      const regex = new RegExp("^[0-9.]");
      const key = event.key;
      if (!regex.test(key) && !(key === 'Backspace')) {
          event.preventDefault();
          return false;
      }
    }

    useEffect(() => {
      if(showStateReturnsForm && formData.returnStatesData.length === 0){
        addReturnStateInfoCompoment();
      }
    },[]);

    const updateIncomeFormData = (event: any) => {
      let fieldName = event.target.name;
      let fieldValue = event.target.value;
      const fieldType = event.target.type;
      if(fieldType === 'radio') {
          fieldName = fieldName.substring(0, fieldName.indexOf('-'));
          fieldValue = event.target.name.substring(event.target.name.indexOf('-')+1);
      }
      setFormData(prevState => ({...prevState, [fieldName]: fieldValue}));
      if(fieldName === 'selfIncone'){
        if(fieldValue === 'Yes') {
          setShowBusinessForm(true);
        }else {
          setShowBusinessForm(false);
        }
      }
      else if(fieldName === 'stateReturn'){
        if(fieldValue === 'Yes') {
          setFormData(prevState => ({...prevState, returnStatesData: [initialReturnState]}));
          setShowStateReturnsForm(true);
        }else {
          setFormData(prevState => ({...prevState, returnStatesData: []}));
          setShowStateReturnsForm(false);
        }
      } 
      else if(fieldName === 'rentals'){
        if(fieldValue === 'Yes') {
          setShowRentalForm(true);
        }else {
          setShowRentalForm(false);
        }
      }
    }
    
    const stateReturnsValuesChangeHandler = (event:any, index:number) => {
      const {name, value} = event.target;
      let stateReturnsArray = [...formData.returnStatesData];
      const existedState = stateReturnsArray.find(state => state.State === value);
      if(existedState){
        setShowStateAlert(true);
      }else {
        setShowStateAlert(false);
        stateReturnsArray[index] = {...stateReturnsArray[index], [name]:value};
        setFormData(prevState => ({...prevState, returnStatesData: stateReturnsArray}));
      }
      
    }

    const addReturnStateInfoCompoment = () => {
      const stateReturn = initialReturnState;
      let stateReturnsArray = [...formData.returnStatesData];
      stateReturnsArray = [...stateReturnsArray, stateReturn];
      setFormData(prevState => ({...prevState, returnStatesData: stateReturnsArray}));
    }

    const removeReturnStateInfoCompoment = (index: number) => {
      let stateReturnsArray = [...formData.returnStatesData];
      stateReturnsArray.splice(index,1);
      setFormData(prevState => ({...prevState, returnStatesData: stateReturnsArray}));
    }

    useEffect(() => {
      if(showStateAlert) {
        const timer= setTimeout(() => {
          setShowStateAlert(false);
        },2000);

        return () => clearTimeout(timer);
      }
    },[showStateAlert])


    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
             <AppProgressBar progressPercent={15} />
              <Row className="justify-content-center">
                <Col md={12}>
                  <h1 className="content-box-heading mb-3">
                   {`Tell us about the ${getCurrentTaxYear()} tax year:`} 
                  </h1>
                  {taxPrepEnrollment?.paymentCompleted && <PaymentCompletedMessage />}
                  <Row className="justify-content-start my-4">
                    <Form className="mt-3" id="clientInfoForm">
                    {showError.mainError && 
                          <p className='fs-8 color-red mt-1 mb-2'>Please select either Yes or No for all the fields</p>
                    }
                    <Row>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold" disabled={taxPrepEnrollment?.paymentCompleted}>{'Do you generate' +
                            ' self-employment' +
                            ' income (Cash and' +
                            ' other forms of payment received from clients including forms 1099-NEC, 1099-MISC, 1099-K)?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.SELF_EMPLOYMENT}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="selfIncone-Yes"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.selfIncone === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="selfIncone-No"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.selfIncone === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.SELF_EMPLOYMENT}</p>
                      </Col>
                    </Row>
                    {showBusinessForm && 
                      <Row>
                        <Col md={12} lg={12} className="d-flex align-items-center mb-3">
                        <Form.Group> 
                          <Form.Label>What Is Your Annual Gross Receipts For This Business?</Form.Label>
                          <InputGroup>
                              <InputGroup.Text>$</InputGroup.Text>
                              <Form.Control
                              name="grossReceiptsAmount"
                              type="text"
                              value={formData.grossReceiptsAmount}
                              onChange={updateIncomeFormData}
                              disabled={taxPrepEnrollment?.paymentCompleted}
                              onKeyDown={(event) => validateAmount(event)}
                              />
                          </InputGroup>
                          {showError.businessError && 
                                <p className='fs-8 color-red mt-1 mb-0'>Please enter a valid amount</p>
                          }
                        </Form.Group>
                        </Col>
                      </Row>
                    }
                    <Row className='mt-3'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-2">
                          <Form.Label className="fs-10 fw-bold" disabled={taxPrepEnrollment?.paymentCompleted}>{'Did you have any capital gains or losses (Form' +
                            ' 1099-B)?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.CAPITAL_GAINS}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="capitalGains-Yes"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.capitalGains === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="capitalGains-No"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.capitalGains === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.CAPITAL_GAINS}</p>
                      </Col>
                    </Row>
                    <Row  className='mt-1'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold" disabled={taxPrepEnrollment?.paymentCompleted}>{'Did you sell any real estate (Form 1099-S or' +
                            ' 1099-A)?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.REAL_ESTATE}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="realEstate-Yes"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.realEstate === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="realEstate-No"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.realEstate === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.REAL_ESTATE}</p>
                      </Col>
                    </Row>
                    <Row  className='mt-1'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold">{'Did you directly have any rental income or losses?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.RENTAL}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="rentals-Yes"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.rentals === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="rentals-No"
                            onChange={updateIncomeFormData}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            checked={formData.rentals === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0 mb-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.RENTAL}</p>
                      </Col>
                    </Row>
                    {showRentalForm && 
                      <Row>
                        <Col md={12} lg={12} className="d-flex align-items-center mb-3">
                        <Form.Group> 
                            <Form.Label>How Many Rental Properties Do You Own?</Form.Label>
                            <Form.Control
                            name="rentalsCount"
                            type="number"
                            value={formData.rentalsCount}
                            min={0}
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            />
                            {showError.rentalError && 
                              <p className='fs-8 color-red mt-1 mb-0'>Please enter a valid number</p>
                            }
                        </Form.Group>
                        </Col>
                      </Row>
                    }
                    <Row  className='mt-3'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold">{'Did you receive any royalties?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.ROYALTIES}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="royalties-Yes"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.royalties === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="royalties-No"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.royalties === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.ROYALTIES}</p>
                      </Col>
                    </Row>
                    <Row  className='mt-2'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold">{'Did you have any partnership income or losses (Form K-1)?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.PARTNERSHIP}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="partnershipIncome-Yes"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.partnershipIncome === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="partnershipIncome-No"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.partnershipIncome === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.PARTNERSHIP}</p>
                      </Col>
                    </Row>
                    <Row  className='mt-1'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold">{'Did you operate a Farm?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="farmIncome-Yes"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.farmIncome === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="farmIncome-No"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.farmIncome === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.FARM}</p>
                      </Col>
                    </Row>
                    <Row  className='mt-1'>
                      <Col md={8} lg={8} className="d-flex align-items-center">
                          <Form.Label className="fs-10 fw-bold">{'Do you also need a STATE return prepared?'}</Form.Label>
                      </Col>
                      <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                          <OverlayHelpTooltip text={INCOME_FORM_TOOLTIP_LABELS.STATE_RETURN}/>
                      </Col>
                      <Col md={3} lg={3} className='d-flex justify-content-end'>
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="Yes"
                            name="stateReturn-Yes"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.stateReturn === 'Yes'?true:false}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            className='fs-14'
                            label="No"
                            name="stateReturn-No"
                            disabled={taxPrepEnrollment?.paymentCompleted}
                            onChange={updateIncomeFormData}
                            checked={formData.stateReturn === 'No'?true:false}
                          />
                      </Col>
                    </Row>
                    <Row className='mt-0'>
                      <Col md={8} lg={8} className="d-flex align-items-center mt-0">
                        <p className="content-text content-overflow-text">{INCOME_FORM_LABELS.STATE_RETURN}</p>
                      </Col>
                    </Row>
                    {showStateReturnsForm && 
                      <Row>
                        <Col md={12} lg={12} className="d-flex align-items-center mb-3">
                          <div className='informFormCard w-100'>
                            {showError.stateError && 
                              <p className='fs-8 color-red mt-1 mb-0'>Please select state in all fields</p>
                            }
                            {showStateAlert && 
                              <p className='fs-8 color-red mt-1 mb-0'>You already selected the same state in another field.</p>
                            }
                            {formData.returnStatesData.map((returnsState, index) => {
                              return <StateReturnsForm returnsState={returnsState} 
                                                index={index} 
                                                onValueChange={stateReturnsValuesChangeHandler}
                                                onAdd={addReturnStateInfoCompoment}
                                                onRemove={removeReturnStateInfoCompoment}
                                                paymentCompleted={taxPrepEnrollment?.paymentCompleted}
                                                recordsCount={formData.returnStatesData.length}/>
                            })}
                          </div>
                        </Col>
                      </Row>
                    }
                    </Form>
                    <div className="d-flex justify-content-between mt-3 align-items-center">
                      <span onClick={onBackHandler}>
                        {" "}
                        <span className="letsdolater">Back</span>
                      </span>
                      <button
                        className={"btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={continueHandler}>
                       Continue
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default IncomeForm;