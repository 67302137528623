import gql from "graphql-tag";

export const InitiateEnrollment = gql`
  mutation InitiateEnrollment($profileId: ID!, $type: EnrollmentType!) {
    InitiateEnrollment(Input: { ProfileId: $profileId, Type: $type }) {
      NextEnrollmentAction
      TranscriptRequestId
      Id
      NextForm {
        Id
        Name
        S3Bucket
        S3VersionId
        S3Key
      }
      ProtectionPlan {
        Active
        Description
        Id
        Link
        MonthlyPrice
        Name
        CreatedAt
        Title
        UpdatedAt
        ChargeSalesTax
      }
      RecommendedProtectionPlan {
        Active
        CreatedAt
        Description
        Id
        Link
        MonthlyPrice
        Name
        Title
        UpdatedAt
        ChargeSalesTax
      }
      UnavailableProtectionPlan {
        Active
        CreatedAt
        Description
        Id
        Link
        MonthlyPrice
        Name
        Title
        UpdatedAt
        ChargeSalesTax
      }
      ProtectionPlanId
      RecommendedProtectionPlanId
    }
  }
`;
