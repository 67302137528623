import gql from "graphql-tag";

export const GetAppointmentTopics = gql`
  query GetAppointmentTopics {
    GetAppointmentTopics {
      DisplayText
      Value
    }
  }
`;
