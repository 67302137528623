import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { clientConfig } from "../../awsClientConfig";
import { GetOrganization } from "../../queries/GetOrganization";
import { StateOptions } from "../../contants";
import { CreateOrganization } from "../../queries/CreateOrganization";
import { CreateOrganizationAddress } from "../../queries/CreateOrganizationAddress";
import { UpdateOrganization } from "../../queries/UpdateOrganization";
import { UpdateOrganizationAddress } from "../../queries/UpdateOrganizationAddress";
import { Auth } from "aws-amplify";
import { formatZipcode } from "../../functions/misc";
import Message from "../../components/Message";
//@ts-ignore
import { isValid } from 'ein-validator'
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";

type BUSINESS = {
  name: string;
  eni: string;
  phone: string;
  type:
    | "SoleProprietorship"
    | "Partnership"
    | "SCorporation"
    | "Corporation"
    | "LimitedLiabilityCompany";
};

type BUSINESS_ADDRESS = {
  streetAddress1: string;
  streetAddress2: string;
  zipCode: string;
  state: string;
  city: string;
  types: [];
};

const FORM_ERROR = {
  name: "",
  eni: "",
  phone: "",
  type: "",
  streetAddress1: "",
  // streetAddress2: "",
  zipCode: "",
  state: "",
  city: "",
};

function BusinessInformation({ progressPercent = 0 }) {
  const { next, organizationId, setOrganizationId } = useContext(
    PartnerEnrollmentFormContext
  );

  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [showSpinner, setShowSpinner] = useState(false);
  const [submited, setSubmited] = useState(false);

  const [business, setBusiness] = useState<BUSINESS>({
    name: "",
    eni: "",
    type: "SoleProprietorship",
    phone: "",
  });

  const [businessAddress, setBusinessAddress] = useState<BUSINESS_ADDRESS>({
    streetAddress1: "",
    streetAddress2: "",
    zipCode: "",
    state: "AL",
    city: "",
    types: []
  });
  const client = new clientConfig();

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (business.name) {
        error.name = "";
      } else {
        error.name = "Business Name can not be empty";
      }

      if (business.eni) {
        if (!isValid(business.eni)) {
          error.eni = "Federal Tax ID (EIN) is not valid";
        } else {
          error.eni = "";
        }
      } else {
        error.eni = "Federal Tax ID (EIN) can not be empty";
      }

      if (business.phone) {
        error.phone = "";
      } else {
        error.phone = "Phone can not be empty";
      }

      if (business.type) {
        error.type = "";
      } else {
        error.type = "Business type can not be empty";
      }

      if (businessAddress.streetAddress1) {
        error.streetAddress1 = "";
      } else {
        error.streetAddress1 = "Street Address can not be empty";
      }

      // if (businessAddress.streetAddress2) {
      //   error.streetAddress2 = "";
      // } else {
      //   error.streetAddress2 = "Street Address can not be empty";
      // }

      if (businessAddress.city) {
        error.city = "";
      } else {
        error.city = "City can not be empty";
      }

      if (businessAddress.state) {
        error.state = "";
      } else {
        error.state = "State can not be empty";
      }

      if (businessAddress.zipCode) {
        error.zipCode = "";
      } else {
        error.zipCode = "ZIP Code can not be empty";
      }

      setFromErros(error);
    };
    validateForm();
  }, [business, businessAddress]);

  useEffect(() => {
    if (organizationId) {
      client
        .query({
          query: GetOrganization,
          variables: { organizationId },
        })
        .then((res: any) => {
          const organizationData = res.data.GetOrganization;
          const organizationAddresses =
            res.data.GetOrganization.OrganizationAddresses;

         const mainAddress = organizationAddresses?.find((address: any) => {
          return address.OrganizationAddressTypes.some((addressTypes: any) => addressTypes.Type === "Main");
         });

          setBusiness({
            eni: organizationData.Ein,
            name: organizationData.Name,
            phone: organizationData.Phone,
            type: organizationData.Type,
          });

          if (mainAddress) {
            setBusinessAddress({
              streetAddress1: mainAddress.StreetAddress1,
              streetAddress2: mainAddress.StreetAddress2,
              zipCode: mainAddress.ZipCode,
              state: mainAddress.State,
              city: mainAddress.City,
              types: mainAddress.OrganizationAddressTypes,
            });
          }
        })
        .catch((error) => {
         console.log('error', error)
        });
    }
  }, [organizationId]);

  const onChange = (event: any) => {
    const newBusiness = {
      ...business,
      [event.target.name]: event.target.value,
    };

    setBusiness(newBusiness);
  };

  const onChangeAddress = (event: any) => {
    const newBusinessAddress = {
      ...businessAddress,
      [event.target.name]:
        event.target.name != "zipCode"
          ? event.target.value
          : formatZipcode(event),
    };

    setBusinessAddress(newBusinessAddress);
  };

  function formatPhoneNumber(e: any) {
    const str = e.target.value;

    const phoneNumber = str.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) {
      setBusiness({ ...business, [e.target.name]: phoneNumber });

      return;
    }

    if (phoneNumber.length < 7) {
      setBusiness({
        ...business,

        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
      });

      return;
    }

    if (phoneNumber.length < 11) {
      setBusiness({
        ...business,

        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,

          6
        )}-${phoneNumber.slice(6, 10)}`,
      });

      return;
    }
  }

  const onSubmit = async () => {
    setShowSpinner(true);
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
     setShowSpinner(false);
     return;
    }
    const res = await Auth.currentAuthenticatedUser();
    const partnerId = res.attributes["custom:Id"];


    const variables: any = {
      PartnerId: partnerId,
      Ein: business.eni.replace(/[^\d]/g, ""),
      Name: business.name,
      Phone: business.phone.replace(/[^\d]/g, ""),
      Type: business.type,
      Storefront: false,
    };

    if (organizationId) {
      variables.Id = organizationId;
    }
    client
      .mutate({
        mutation: organizationId ? UpdateOrganization : CreateOrganization,
        variables: variables,
      })
      .then((res: any) => {
        const organizationData = organizationId
          ? res.data.UpdateOrganization
          : res.data.CreateOrganization;
        const addressVariables: any = {
          City: businessAddress.city,
          OrganizationId: organizationData.Id,
          State: businessAddress.state,
          StreetAddress1: businessAddress.streetAddress1,
          OrganizationAddressTypes:  [{
            Type: "Main"
           }],
          ZipCode: businessAddress.zipCode,
        };

        if(businessAddress.streetAddress2) {
          addressVariables.StreetAddress2 = businessAddress.streetAddress2
        }

        const organizationAddresses = organizationData.OrganizationAddresses;

        const mainAddress = organizationAddresses?.find((address: any) => {
          return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === "Main");
        });

        if (mainAddress) {
          addressVariables.Id = mainAddress.Id;
        }

        client
          .mutate({
            mutation: mainAddress
              ? UpdateOrganizationAddress
              : CreateOrganizationAddress,
            variables: addressVariables,
          })
          .then(() => {
            next();
            setOrganizationId(organizationData.Id);
            next();
          })
          .catch(() => {});
      })
      .catch((error: any) => {
       setShowSpinner(false);
       console.log(error);
      });
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">
                Review Your Business Information{" "}
              </h1>
              <p className="content-text">
                Please tell us about your business.
              </p>
              <Form>
                <Row className="mb-3 justify-content-between">
                  <Col md={5}>
                    <Form.Group controlId="">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        name="name"
                        onChange={onChange}
                        value={business.name}
                        type="text"
                      />
                      {submited && formErrors.name && (
                        <Message
                          message={formErrors.name}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId="">
                      <Form.Label>Federal Tax ID (EIN)</Form.Label>
                      <Form.Control
                        name="eni"
                        onChange={onChange}
                        value={business.eni}
                        type="text"
                        placeholder=""
                      />
                      {submited && formErrors.eni && (
                        <Message
                          message={formErrors.eni}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3 justify-content-between">
                  <Col md={5}>
                    <Form.Label>Business Phone Number</Form.Label>
                    <Form.Control
                      name="phone"
                      onChange={formatPhoneNumber}
                      value={business.phone}
                      type="phone"
                    />

                    {submited && formErrors.phone && (
                      <Message
                        message={formErrors.phone}
                        type="error"
                      ></Message>
                    )}
                  </Col>

                  <Col md={5}>
                    <Form.Label>Business Type</Form.Label>
                    <Form.Select
                      name="type"
                      onChange={onChange}
                      value={business.type}
                      aria-label="Default select example"
                    >
                      <option value="SoleProprietorship">
                        Sole Proprietorship
                      </option>
                      <option value="Partnership">Partnership</option>
                      <option value="Corporation">Corporation</option>
                      <option value="SCorporation">S Corporation</option>
                      <option value="LimitedLiabilityCompany">
                        Limited Liability Company (LLC)
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} className="mb-3">
                    <label className="mb-2">Office Address</label>
                    <Form.Group controlId="">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="streetAddress1"
                        onChange={onChangeAddress}
                        value={businessAddress.streetAddress1}
                      />

                      {submited && formErrors.streetAddress1 && (
                        <Message
                          message={formErrors.streetAddress1}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="streetAddress2"
                        onChange={onChangeAddress}
                        value={businessAddress.streetAddress2}
                      />

                      {/* {submited && formErrors.streetAddress2 && (
                        <Message
                          message={formErrors.streetAddress2}
                          type="error"
                        ></Message>
                      )} */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={5}>
                    <Form.Group controlId="">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="city"
                        onChange={onChangeAddress}
                        value={businessAddress.city}
                      />

                      {submited && formErrors.city && (
                        <Message
                          message={formErrors.city}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        onChange={onChangeAddress}
                        value={businessAddress.state}
                      >
                        {StateOptions.map((state) => {
                          return <option key={state} value={state}>{state}</option>;
                        })}

                        {submited && formErrors.state && (
                          <Message
                            message={formErrors.state}
                            type="error"
                          ></Message>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId="">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="zipCode"
                        onChange={(e) => onChangeAddress(e)}
                        value={businessAddress.zipCode}
                      />
                      {submited && formErrors.zipCode && (
                        <Message
                          message={formErrors.zipCode}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
               <button disabled={showSpinner} onClick={onSubmit} className="btn-continue">
                {showSpinner &&
                 <Spinner
                   as="span"
                   animation="border"
                   size="sm"
                   role="status"
                   aria-hidden="true"
                   className="mx-2"
                 />}
                Continue
               </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BusinessInformation;
