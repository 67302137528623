import { useContext, useEffect, useState } from "react";
import "../../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import NewFormContext from "./NewFormContext";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../../awsClientConfig";
import {
  formatPhone,
  formatZipcode,
  hideFormatSSN,
} from "../../../functions/misc";
import { userProfileData } from "../../../Common/profileInfo";
import Loading from "../../../Common/Loading";
import { US_STATES } from "../../../Common/States";
import { GetProfile } from "../../../queries/getProfile";
import { GetProfilePrimaryAddress } from "../../../queries/GetProfilePrimaryAddress";
import { UpdateProfile } from "../../../queries/updateProfile";
import { UpsertProfilePrimaryAddress } from "../../../queries/UpsertProfilePrimaryAddress";
import Message from "../../Message";
import { Breadcrumb } from "react-bootstrap";
import momentTz from "moment-timezone";

interface info {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
}

function PersonalInformation() {
  const { next } = useContext(NewFormContext);
  const navigate = useNavigate();
  const [DOB, setDOB] = useState({ date: "", month: 0, year: 0 });
  const [socialSecurity, SetSocialSecurity] = useState("");
  const [formValue, setFormValue] = useState<info>({
    firstName: "",
    lastName: "",
    middleName: "",
    phone: "",
  });
  const [hideSocialSecurity, SetHideSocialSecurity] = useState("");
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [personInfo, setPersonInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState({});
  const [error, setError] = useState({
    fullDOB: "",
    date: "",
    month: "",
    year: "",
    ssn: "",
    streetAddress1: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const client = new clientConfig();
  const months = moment.months();
  const [validated, setValidated] = useState(false);

  let years = [];

  let currentYear = new Date().getFullYear();

  const momentTz = require('moment-timezone');
  const pstDate = momentTz().tz("America/Los_Angeles");
  const pstCurrentDate = pstDate.year(pstDate.year() - 18);

  let startYear = currentYear - 100;
  startYear = startYear || 1900;
  while (startYear <= currentYear - 18) {
    years.push(startYear++);
  }

  const getmonth = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  useEffect(() => {
    getProfile();
    getProfilePrimaryAddress();
  }, []);
  const setDateOfBirth = (e: any) => {
    setDOB((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "date"
            ? e.target.value
            : e.target.value.replace(/[^\d*]/g, ""),
      };
    });
    let _error: any = error;
    _error[e.target.name] = "";
    _error["fullDOB"] = "";
  };

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
    let _error: any = error;
    _error[e.target.name] = "";
  };

  const getProfile = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setPersonInfo(res.data.GetProfile);
          if (res.data.GetProfile.Birthdate !== null) {
            const birthDate1 = new Date(res.data.GetProfile.Birthdate);
            const birthDate = new Date(
              birthDate1.valueOf() + birthDate1.getTimezoneOffset() * 60 * 1000
            );
            setDOB({
              month: birthDate.getMonth() + 1,
              date: birthDate.getDate().toString(),
              year: birthDate.getFullYear(),
            });
            SetSocialSecurity(hideFormatSSN(res.data.GetProfile.Ssn));
            SetHideSocialSecurity(res.data.GetProfile.Ssn || "");
            setFormValue({
              firstName: res.data.GetProfile.FirstName,
              middleName: res.data.GetProfile.MiddleName,
              lastName: res.data.GetProfile.LastName,
              phone: formatPhone(res.data.GetProfile.Phone),
            });
          }
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddressInfo(res.data.GetProfilePrimaryAddress);
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const makeSocialSecurity = (e: any) => {
    let temp = hideSocialSecurity.replace(
      hideSocialSecurity.substring(0, 5),
      "*".repeat(hideSocialSecurity.substring(0, 5).length)
    );
    var currentValue = e.target.value.replace(/[^\d*]/g, "");
    var finalValue = "";
    if (hideSocialSecurity.length < currentValue.length) {
      finalValue = hideSocialSecurity
        .concat(currentValue.replace(temp, ""))
        .replace(/[^\d]/g, "");
    } else {
      finalValue = hideSocialSecurity.substring(0, currentValue.length);
    }
    if (finalValue.length === 10) {
      finalValue = finalValue.substring(0, 9);
    }
    SetHideSocialSecurity(finalValue);
    SetSocialSecurity(hideFormatSSN(finalValue));
    let _error: any = error;
    _error[e.target.name] = "";
  };

  const submitInfo = async () => {
    setShowSpinner(true);
    if (
      DOB.date === "" ||
      DOB.month === 0 ||
      DOB.year === 0 ||
      hideSocialSecurity === "" ||
      hideSocialSecurity.length !== 9 ||
      address.streetAddress1 === "" ||
      address.city === "" ||
      address.streetAddress1.length < 2 ||
      address.city.length < 2 ||
      address.state === "" ||
      address.zipCode === ""
    ) {
      let _error = error;
      if (DOB.date === "") _error["date"] = "Date is mandatory";
      if (DOB.month === 0) _error["month"] = "Month is mandatory";
      if (DOB.year === 0) _error["year"] = "Year is mandatory";
      if (hideSocialSecurity.length !== 9)
        _error["ssn"] = "SSN requires 9 Characters";
      if (hideSocialSecurity === "") _error["ssn"] = "SSN is mandatory";
      if (address.streetAddress1 === "")
        _error["streetAddress1"] = "Street Address is mandatory";
      if (address.city === "") _error["city"] = "City is mandatory";
      if (address.streetAddress1.length < 2)
        _error["streetAddress1"] = "Street Address should be minimum 2 letters";
      if (address.city.length < 2) _error["city"] = "City be minimum 2 letters";
      if (address.state === "") _error["state"] = "State is mandatory";
      if (address.zipCode === "") _error["zipCode"] = "ZIP Code is mandatory";
      setError(_error);
      setError({ ...error });
      setShowSpinner(false);
      return;
    }

    let Birthdate =
      DOB.date.toString().length === 1 ? `0${DOB.date}` : DOB.date;
    let BirthMonth = getmonth(Number(DOB.month));
    let fullDob = `${DOB.year}-${BirthMonth}-${Birthdate}`;
    let date = moment(`${DOB.year}-${BirthMonth}-${Birthdate}`);

    if (date.format("YYYY-MM-DD") > pstCurrentDate.format("YYYY-MM-DD")) {
      let _error = error;
      _error["fullDOB"] = "You must be 18 years of age or older.";
      setError(_error);
      setError({ ...error });
      setShowSpinner(false);
      return;
    }

    if (date.isValid()) {
      const profileId = await userProfileData();
      const variables = {
        ...personInfo,
        Id: profileId,
        Birthdate: fullDob,
        Ssn: hideSocialSecurity.replaceAll("-", ""),
        FirstName: formValue.firstName,
        MiddleName: formValue.middleName ? formValue.middleName : null,
        LastName: formValue.lastName,
        Phone: formValue.phone.replace(/[^0-9]/g, ""),
      };
      client
        .mutate({ mutation: UpdateProfile, variables: variables })
        .then((res: any) => {
          if (res.data.UpdateProfile) {
            submitAddress();
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
        });
    } else {
      let _error = error;
      _error["date"] = "Enter Date of Birth Correctly";
      setError(_error);
      setError({ ...error });

      setShowSpinner(false);
      return;
    }
  };

  const submitAddress = async () => {
    const profileId = await userProfileData();
    const variables = {
      ...addressInfo,
      ProfileId: profileId,
      State: address.state,
      City: address.city,
      StreetAddress1: address.streetAddress1,
      ZipCode: address.zipCode,
      StreetAddress2: address.streetAddress2 ? address.streetAddress2 : null,
    };
    client
      .mutate({ mutation: UpsertProfilePrimaryAddress, variables: variables })
      .then((res: any) => {
        if (res.data.UpsertProfilePrimaryAddress) {
          next();
        }
      })
      .catch((error: any) => {
        setShowSpinner(false);
      });
  };

  const handleSubmit = (event: any) => {
    setShowSpinner(true);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      submitInfo();
    }
    setShowSpinner(false);
    setValidated(true);
  };

  const setProfile = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  function formatPhoneNumber(e: any) {
    const str = e.target.value;
    const phoneNumber = str.replace(/[^\d]/g, "");

    if (phoneNumber.length < 4) {
      setFormValue({ ...formValue, [e.target.name]: phoneNumber });
      return;
    }

    if (phoneNumber.length < 7) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
      });
      return;
    }

    setFormValue({
      ...formValue,
      [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`,
    });
    return;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            {showSpinner ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <Breadcrumb className="customBreadcrumb mt-0">
                  <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings")}
                  >
                    Account Settings
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate("/account-settings-documents")}
                  >
                    Documents
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{"Forms > 8821"}</Breadcrumb.Item>
                </Breadcrumb>

                <h1 style={{ color: "#0A579B", fontSize: 26 }} className="mb-2">
                  Form 8821 - Review Your Information{" "}
                </h1>
               <p style={{ fontWeight: 400 }} className="content-text mb-4">
                Personal information -
                <span style={{color: "red"}}> As it appears on your IRS notice or last filed tax return</span>
               </p>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          value={formValue.firstName}
                          name="firstName"
                          onChange={setProfile}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide First Name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formValue.middleName}
                          name="middleName"
                          onChange={setProfile}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          value={formValue.lastName}
                          name="lastName"
                          onChange={setProfile}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Last Name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="">
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          value={address.streetAddress1}
                          name="streetAddress1"
                          onChange={(e) => setAddressData(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Street Address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="">
                        <Form.Label>Street Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.streetAddress2}
                          name="streetAddress2"
                          onChange={(e) => setAddressData(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>City </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          required
                          value={address.city}
                          name="city"
                          onChange={(e) => setAddressData(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide City.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required
                          value={address.state}
                          name="state"
                          onChange={(e) => setAddressData(e)}
                        >
                          <option value="">- </option>
                          {US_STATES.map((state) => {
                            return (
                              <option value={state.abbreviation}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please provide State.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="">
                        <Form.Label>ZIP Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={address.zipCode}
                          name="zipCode"
                          onChange={(e) => setAddressData(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Zip code.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            formatPhoneNumber(e);
                          }}
                          name="phone"
                          required
                          value={formValue.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide Phone Number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 justify-content-between">
                    <Col md={12}>
                      <Row>
                        <Col sm={12} md={7}>
                          {error.fullDOB && (
                              <Message type="error" message={error.fullDOB}></Message>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Month of Birth</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="month"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.month}
                        >
                          <option value="0">-</option>
                          {months.map((month, index) => {
                            return (
                              <option value={Number(index) + 1}>{month}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error.month && (
                        <Message type="error" message={error.month}></Message>
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Day Of Birth</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          maxLength={2}
                          name="date"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.date}
                        />
                      </Form.Group>
                      {error.date && (
                        <Message type="error" message={error.date}></Message>
                      )}
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId="">
                        <Form.Label>Year of Birth</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="year"
                          onChange={(e) => setDateOfBirth(e)}
                          value={DOB.year}
                        >
                          <option value="0">- </option>
                          {years.map((year) => {
                            return <option value={year}>{year}</option>;
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error.year && (
                        <Message type="error" message={error.year}></Message>
                      )}
                    </Col>

                    <Col md={4}>
                      <Form.Group controlId="">
                        <Form.Label>Social Security Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={socialSecurity}
                          name="ssn"
                          onChange={(e) => {
                            //SetSocialSecurity(formatSSN(e));
                            makeSocialSecurity(e);
                          }}
                        />
                      </Form.Group>
                      {error.ssn && (
                        <Message type="error" message={error.ssn}></Message>
                      )}
                    </Col>
                  </Row>
                </Form>

                <div className="d-flex justify-content-end mt-5 align-items-center">
                  <button
                    disabled={showSpinner}
                    onClick={handleSubmit}
                    className="btn-continue"
                  >
                    Verify Information
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PersonalInformation;
