import gql from "graphql-tag";

export const GetTaxReturnProfile = gql`
  query GetTaxReturnProfile($ProfileId: ID!, $EnrollmentId: ID!) {
    GetTaxReturnProfile(ProfileId: $ProfileId, EnrollmentId: $EnrollmentId) {
      TaxReturnProfile {
        City
        CreatedAt
        Email
        FirstName
        Id
        LastName
        MiddleName
        Phone
        ProfileId
        Spouse
        State
        StreetAddress1
        StreetAddress2
        TaxReturnId
        UpdatedAt
        ZipCode
      }
    }
  }
`;
