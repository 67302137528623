import { createContext } from "react";


export type ReturnStates = {
  Id: string;
  State: string;
}

export const initialReturnState: ReturnStates = {
  Id: '',
  State: ''
}

export type StateReturnYears= {
  id: string;
  year: number;
  quarter: number;
}

export const initialStateReturnYears: StateReturnYears = {
  id: '',
  year: 0,
  quarter: 0
}

export type BusinessQuestionnaireData = {
  businessEntityId: string;
  businessReturnEntityId: string;
  entityName: string;
  firstYearOfBusiness: string;
  grossRevenue: string;
  taxForm941Id: string;
  taxForm941: boolean;
  stateReturn941: string;
  returnYears941: StateReturnYears[];
  taxForm940Id: string;
  taxForm940: boolean;
  stateReturn940: string;
  taxForm943Id: string;
  taxForm943: boolean;
  stateReturn943: string;
  taxForm944Id: string;
  taxForm944: boolean;
  stateReturn944: string;
  taxFormId: string;
  taxForm: string;
  partnersCount: number;
  taxFormStateReturn: string;
}

export const initialBusinessQuestionnaireData:BusinessQuestionnaireData = {
  businessEntityId: "",
  entityName: '',
  firstYearOfBusiness: '',
  grossRevenue: '',
  taxForm941: false,
  stateReturn941: 'No',
  returnYears941: [],
  taxForm940: false,
  stateReturn940: 'No',
  taxForm943: false,
  stateReturn943: 'No',
  taxForm944: false,
  stateReturn944: 'No',
  taxForm: '',
  partnersCount: 0,
  taxFormStateReturn: 'Yes',
  businessReturnEntityId: "",
  taxForm941Id: "",
  taxForm940Id: "",
  taxForm943Id: "",
  taxForm944Id: "",
  taxFormId: ""
}

export type IncomeFormData = {
  selfIncone: string;
  grossReceiptsAmount: string;
  capitalGains: string;
  realEstate: string;
  rentals: string;
  rentalsCount: number;
  royalties: string;
  partnershipIncome: string;
  farmIncome: string;
  stateReturn: string;
  returnStatesData: ReturnStates[]
}

export const initialIncomeFormData = {
  selfIncone: '',
  grossReceiptsAmount: '0',
  capitalGains: '',
  realEstate: '',
  rentals: '',
  rentalsCount: 0,
  royalties: '',
  partnershipIncome: '',
  farmIncome: '',
  stateReturn: '',
  returnStatesData: []
}

export type ProfileInformation = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone:string
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const initialProfileInformationData: ProfileInformation = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  streetAddress: '',
  streetAddress2: '',
  city: '',
  state: '',
  zipCode: ''
}

export type BillingAddress = {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const initialBillingAddress: BillingAddress = {
  streetAddress: '',
  streetAddress2: '',
  city: '',
  state: '',
  zipCode: ''
}

export type TaxPreparationData = {
  filingType: string;
  jointFiling: string;
  incomeInfoData: IncomeFormData;
  businessQuestionnaireData: BusinessQuestionnaireData[];
  clientProfileInformation: ProfileInformation;
  spouseProfileInformation: ProfileInformation;
  billingAddress: BillingAddress;
  promoCode: string;
}

export const initialTaxPreparationData:TaxPreparationData = {
  filingType: 'Personal',
  jointFiling: '',
  incomeInfoData: initialIncomeFormData,
  businessQuestionnaireData: [],
  clientProfileInformation: initialProfileInformationData,
  spouseProfileInformation: initialProfileInformationData,
  billingAddress: initialBillingAddress,
  promoCode: ''
}

interface ContextState {
  navigateToPage: (pageName: string) => void;
  pageName: string;
  setPageName: (pageName: string) => void;
  taxPreparationData: TaxPreparationData,
  setTaxPreparationData: (taxPreparationData: TaxPreparationData) => void,
  enrollmentId: string;
  setEnrollmentId: (id: string) => void;
  nextEnrollmentAction: (nextAction: string) => void;
  quoteId: string;
  setQuoteId: (id: string) => void;
  taxReturnId: string;
  setTaxReturnId: (id: string) => void;
  quotePrice: string;
  setQuotePrice: (price: string) => void;
  quotePriceUpdated: boolean;
  setQuotePriceUpdated: (priceUpdated: boolean) => void;
  nextFormId: string;
  setNextFormId: (id: string) => void;
  s3key: string;
  setS3Key: (id: string) => void;
  s3Bucket: string;
  setS3Bucket: (id: string) => void;
  completedPages: string[];
  completedPagesHandler:(pageName: string) => void;

}

const TaxPreparationServiceFormContext = createContext({} as ContextState);

export default TaxPreparationServiceFormContext;

export const { Provider, Consumer } = TaxPreparationServiceFormContext;
