import gql from "graphql-tag";

export const GetLatestFormsByPartner = gql`
  query GetLatestFormsByPartner($PartnerId: ID!) {
    GetLatestFormsByPartner(PartnerId: $PartnerId) {
      Id
      TemplateId
      Name
      Status
      Active
      UpdatedAt
      Name
      S3Bucket
      S3Key
      S3VersionId
      PartnerForms {
        Owner
        FormId
      }
    }
  }
`;
