import gql from "graphql-tag";

export const CreateOrganizationAddress = gql`
  mutation CreateOrganizationAddress(
    $City: String!
    $OrganizationId: ID!
    $State: UsState!
    $StreetAddress1: String!
    $StreetAddress2: String
    $OrganizationAddressTypes: [OrganizationAddressTypeInput!]!
    $ZipCode: String!
  ) {
    CreateOrganizationAddress(
      Input: {
        City: $City
        OrganizationId: $OrganizationId
        State: $State
        StreetAddress1: $StreetAddress1
        StreetAddress2: $StreetAddress2
        OrganizationAddressTypes: $OrganizationAddressTypes
        ZipCode: $ZipCode
      }
    ) {
      City
      CreatedAt
      Id
    }
  }
`;
