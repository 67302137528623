import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppProgressBar from "../../Common/progressBar";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { clientConfig } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfile } from "../../queries/getProfile";
import { PurchaseProtectionPlan } from "../../queries/PurchaseProtectionPlan";
import Message from "../Message";
import Loading from "../../Common/Loading";
import { useNavigate } from "react-router-dom";
import { motionContainer } from "./index";
import { AnimatePresence, motion } from "framer-motion";

function PaymentDetails({ progressPercent = 0 }) {
  const { next, previous, finalPlan, billingAddress, enrollmentId, promoCode, promotionCodeType } = useContext(
    OrganicClientFormContext
  );
  var paid = false;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const windowValue: any = window;
  const environmentKey = process.env.REACT_APP_ENVIRONMENTKEY;
  let bottom_description = finalPlan.Description;
  bottom_description = bottom_description.replace(finalPlan.Name, `<b>${finalPlan.Name}</b>`).replace('Plan', `<b>Plan</b>`)
  
  bottom_description = bottom_description + `<br><br><button class='btn-payment-screen' id='chooseAnotherPlan' style='margin-right:2px'>Pick a different plan</button>`;
  const options = {
    company_name: finalPlan.Name.toUpperCase() + ' PLAN',
    sidebar_bottom_description: bottom_description,
    amount: "$" + finalPlan.MonthlyPrice + (finalPlan.ChargeSalesTax?" +Tax":"") + "/mo.",
    submit_label: 'Continue'
  };

  const client = new clientConfig();

  window.onbeforeunload = function (e) {
    windowValue.SpreedlyExpress.unload();
  };

  window.onpopstate = () => {
    windowValue.SpreedlyExpress.unload();
  };

  let doc: any = document;
  doc.getElementById("spreedly-close-button")?.addEventListener("click", () => {
    closeview();
  });
  doc.getElementById("chooseAnotherPlan")?.addEventListener("click", () => {
    goToProtectionPlan();
  });
  const closeview = () => {
    navigate("/dashboard");
    windowValue.SpreedlyExpress.unload();
    
  };

  const goToProtectionPlan = () => {
    previous(1);
    windowValue.SpreedlyExpress.unload();
    
  };

  const handlePaymentToken = (token: any, formdata: any) => {
    console.log(token, formdata);
    if (!paid) {
      setLoading(true);
      paid = true;
      const variables = {
        EnrollmentId: enrollmentId,
        PaymentMethodToken: token,
        PromotionCode: promoCode ? promoCode : null,
        PromotionCodeType: promotionCodeType ? promotionCodeType : null
      };
      client
        .mutate({ mutation: PurchaseProtectionPlan, variables: variables })
        .then((res: any) => {
          if (res.data.PurchaseProtectionPlan.Id) {
            setLoading(false);
            setSuccess("Payment Successful");
            setTimeout(() => {
              setSuccess("");
              next();
            }, 5000);
          }
        })
        .catch((error: any) => {
          setError("error in payment method");
          setLoading(false);
          paid = false;
          setTimeout(() => {
            windowValue.SpreedlyExpress.unload();
            setError("");
            initializeSprredly();
          }, 5000);
        });
    }
  };
  const enableButton = () => {
    setLoading(false);
    windowValue.SpreedlyExpress.openView();
  };

  useEffect(() => {
    initializeSprredly();
  }, []);

  const initializeSprredly = async () => {
    windowValue.SpreedlyExpress.onInit(enableButton);
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          windowValue.SpreedlyExpress.init(environmentKey, options, {
            address1: billingAddress.streetAddress1,
            address2: billingAddress.streetAddress2,
            city: billingAddress.city,
            state: billingAddress.state,
            zip: billingAddress.zipCode,
            country: "US",
            email: res.data.GetProfile?.Email,
            // data: "something",
            phone_number: res.data.GetProfile?.Phone,
          });
        }
        windowValue.Spreedly.on('ready', function() {
          windowValue.Spreedly.setPlaceholder('number', '');
        });
      })
      .catch((error: any) => {
        console.log(error, "error");
      });

    windowValue.SpreedlyExpress.onPaymentMethod(handlePaymentToken);
  };

  return (
    <AnimatePresence mode="wait">
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <motion.div key={loading.toString()} {...motionContainer}>
            {loading ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading">Payment Details</h1>
                <p className="content-text">
                  Please provide your billing address
                </p>
                {error && (
                  <Message type="error" message="Failed Payment"></Message>
                )}
                {success && (
                  <Message
                    type="success"
                    message="Payment Successful"
                  ></Message>
                )}
              </div>
            )}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </AnimatePresence>
  );
}

export default PaymentDetails;
