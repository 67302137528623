import gql from "graphql-tag";

export const InitiatePartnerEnrollmentInput = gql`
  mutation InitiatePartnerEnrollmentInput($partnerId: ID!) {
    InitiatePartnerEnrollment(Input: { PartnerId: $partnerId}) {
      PartnerId
      Partner {
        Email
        Id
        OrganizationId
        TaxProfessional {
          Id
        }
      }
      NextEnrollmentAction
      Id
      InitiatedAt
      CancelledAt
      CompletedAt
      Status
      NextForm {
        Id
        Name
        S3Bucket
        S3VersionId
        S3Key
      }
    }
  }
`;
