import gql from "graphql-tag";

export const IdentifiedNotice = gql`
  subscription IdentifiedNotice($profileId: ID!) {
    IdentifiedNotice(ProfileId: $profileId) {
      ProfileId
      RiskLevel
      Name
      Description
      DueDate
      AmountDue
      Error
      OverDue
    }
  }
`;
