import React from "react";
import styled from "styled-components";
import "./footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="copy_right text-center">
        {" "}
        Copyright <span>&copy;</span> Optima Tax Relief, LLC | All Rights Reserved
        <Dot>•</Dot>
        <a
          href="https://optimataxrelief.com/about-us/terms-of-site/"
          target="_blank"
          rel="noreferrer"
          className="terms text-decoration-none"
        >
          Terms & Conditions
        </a>
        <Dot>•</Dot>
        <a
          href="https://optimataxrelief.com/about-us/privacy/"
          target="_blank"
          rel="noreferrer"
          className="privacy text-decoration-none"
        >
          Privacy Policy
        </a>
      </p>
      <div className="bottom-bg"></div>
    </footer>
  );
}
export default Footer;

const Dot = styled.span`
  margin-left: 3px;
  margin-right: 3px;
`;
