import React from "react";
import "../../../pages/navbar.css";
import "../../styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import irsNoticeIcon from "../../../assets/irs-notice-icon.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import ServicePlan from "../../../assets/preparing-service-plan.svg";
import appstoreIcon from "../../../assets/appstore-icon.png";
import playstoreIcon from "../../../assets/playstore-icon.png";
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import TaxTools from "../ClientDashboards/TaxTools";
import { motion } from "framer-motion";

type Props = {
  taxPrepEnrollmentStatus: boolean | undefined;
}

function PreparingServicePlan({ taxPrepEnrollmentStatus }: Props) {

  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <motion.div variants={motionContainer} initial="initial" animate="animate">
          <Row className="justify-content-center">
            <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
              <Row className="px-lg-5 justify-content-between align-items-center">
                <Col lg={5}>
                  <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                    You're Protected!
                  </h1>
                  <p className="content-text">
                    <span className="fw-bold">
                      Report Status:{" "}
                    </span>
                    <span className="text-primary">
                      PREPARING
                    </span>
                  </p>
                  <p className="content-text">
                    While our team is preparing your
                    report, please check out our additional tax tools below.
                  </p>
                </Col>
                <Col lg={5}>
                  <img
                    src={ServicePlan}
                    alt="Tax-report"
                    className="img-fluid d-block mx-auto"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={10} className="px-0 my-3">
              <TaxTools />
            </Col>
          </Row>
        </motion.div>
      </Container>
    </>
  );
}

export default PreparingServicePlan;
