import { useContext } from "react";
import TaxOrganizerContext, { DependentModel, IncomeSourceModel } from "./TaxOrganizerContext";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { ReactComponent as QuestionMark } from '../../../assets/question-mark-fill.svg'; 
import '../requestcenter.css';
import { formatCurrency } from "../../../functions/misc";

const TaxOrganizerSummary = () => {
    const {selectedRequest, taxOrganizerData, setTaxOrganizerData, setPageNumber} = useContext(TaxOrganizerContext);
    
    return (
        <div style={{marginBottom: '3rem'}}>
            <Row>
            <Col xl={12} md={12}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              {selectedRequest.Name} Summary
            </h4>
            </Col>
            </Row>
            <p className="content-text" style={{marginBottom: '3rem'}}>
            Please verify and confirm the information below. After you’re done, press the “Submit” button to provide to Optima.
            </p>
            <div className="mt-6">
                <Row>
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">FILING STATUS</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(0)}>EDIT</button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">What is/was your Filing Status for the year 2021</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.filingStatus}</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">{`PERSONAL INFORMATION - ${taxOrganizerData.clientInfo.firstName} ${taxOrganizerData.clientInfo.lastName}`}</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(1)}>EDIT</button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={8}>
                        <p className="fs-8">Driver’s License Number</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.dlNumber}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">Driver’s License State</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.dlState}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">Driver’s License Expiration Date</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.dlExpirationDate}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">Social Security Number</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.ssn}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">Date of Birth</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.birthDate}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">Health Insurance Type</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.healthInsurance}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className="fs-8">How many months did you have health insurance coverage this year?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.clientInfo.healthCoverageMonths}</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">{`DEPENDENTS`}</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(2)}>EDIT</button>
                    </Col>
                </Row>
                {taxOrganizerData.dependents.map((dependent: DependentModel) => {
                    return (
                        <>
                            <Row className="mt-2">
                                <Col md={12} xl={12}>
                                    <p className="fs-8 fw-bold">{`${dependent.firstName.toUpperCase()} ${dependent.lastName.toUpperCase()}`}</p>
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col md={3} xl={3} className="padding-0">
                                    <p className="fs-8 my-1 p-0">DATE OF BIRTH</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8  my-1 p-0 ">MONTHS LIVING</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">MONTHS INSURED</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8  my-1 p-0">SSN</p>
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col md={3} xl={3} className="mt-0">
                                    <p className="fs-8 fw-bold">{dependent.birthDate}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{dependent.dependentLivingWithYouMonths}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{dependent.dependentHealthCoverageMonths}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{dependent.ssn}</p>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">FULL-TIME STUDENT</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">GROSS INCOME</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">GROSS INCOME</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{dependent.fullTimeStudent}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{formatCurrency(dependent.grossIncome)}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{dependent.disabled}</p>
                                </Col>
                            </Row>
                        </>
                    )
                })}
                <hr />
                <Row>
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">{`INCOME SOURCES`}</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(3)}>EDIT</button>
                    </Col>
                </Row>
                {taxOrganizerData.incomeSources.map((incomeSource: IncomeSourceModel) => {
                    return (
                        <>
                            <Row className="mt-2">
                                <Col md={12} xl={12}>
                                    <p className="fs-8 fw-bold">{`${incomeSource.incomeSourcePerson.toUpperCase()}`}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">INCOME TYPE</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8  my-1 p-0 ">COMPANY NAME</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">EIN</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8  my-1 p-0">ADDRESS</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{incomeSource.incomeSource}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{incomeSource.company}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{incomeSource.ein}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold my-0 py-0">{incomeSource.companyAddress}</p>
                                    <p className="fs-8 fw-bold my-0 py-0">{incomeSource.companyCity}, {incomeSource.companyState} {incomeSource.companyZip}</p>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">OCCUPATION</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 my-1 p-0">TYPE OF BUSINESS</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{incomeSource.occupation}</p>
                                </Col>
                                <Col md={3} xl={3}>
                                    <p className="fs-8 fw-bold">{incomeSource.occupation}</p>
                                </Col>
                            </Row>
                        </>
                    )
                })}
                <hr />
                <Row className="mb-2">
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">OTHER INFORMATION</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(4)}>EDIT</button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={8}>
                        <p className="fs-8">Annual Household Medical Expenses</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{formatCurrency(taxOrganizerData.otherInformation.medicalExpenses)}</p>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md={8}>
                        <p className="fs-8">Annual Charitable Contributions</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{formatCurrency(taxOrganizerData.otherInformation.charitableContributions)}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">State Taxes Withheld</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{formatCurrency(taxOrganizerData.otherInformation.stateTaxes)}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">Did you receive the 2021 Advanced Child Tax Credit?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.otherInformation.advancedTaxCredit}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">What was the amount received for the 2021 Advanced Child Tax Credit?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{'NA'}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">How many children did you claim for the 2021 Advanced Child Tax Credit?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{'#'}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={8}>
                        <p className="fs-8">Did you receive the 3rd Stimulus Payment in the amount of $1,400?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{taxOrganizerData.otherInformation.thirdStimulusPayment}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TaxOrganizerSummary;