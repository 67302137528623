import React, {useEffect, useState} from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LowRiskIcon from "../../assets/riskGaugeLOW.png";
import HighRiskIcon from "../../assets/riskGaugeHIGH.png";
import MediumIcon from "../../assets/riskGaugeMEDIUM.png";
import LowMediumRisk from "../../assets/LowmediumRisk.svg";
import MediumHighRisk from "../../assets/mediumHighRisk.svg";
import Table from "react-bootstrap/Table";
import { IdentifiedNoticeType } from "./type";
import { useNavigate } from "react-router-dom";
import {clientConfig} from "../../awsClientConfig";
import {Auth} from "aws-amplify";
import {userProfileData} from "../../Common/profileInfo";
import {GetProtectionPlanEligibility} from "../../queries/GetProtectionPlanEligibility";
import {GetFreeTaxRiskReportEligibility} from "../../queries/GetFreeTaxRiskReportEligibility";
import Modal from "react-bootstrap/Modal";
import {LastFreeRiskReports} from "../DashBoards/types";
import { GetEnrollmentsByProfile } from "../../queries/GetEnrollmentsByProfile";
import { EnrollmentStatus } from "../../API";

const RISK_ICON = {
  Low: LowRiskIcon,
  LowMedium: LowMediumRisk,
  Medium: MediumIcon,
  HighMedium: MediumHighRisk,
  High: HighRiskIcon,
};

const RISK_Text = {
  Low: "This Notice is considered LOW RISK",
  LowMedium: "This Notice is considered LOW to MEDIUM RISK",
  Medium: "This Notice is considered MEDIUM RISK",
  HighMedium: "This Notice is considered MEDIUM to HIGH RISK",
  High: "This Notice is considered HIGH RISK",
};

const RISK_Color = {
  Low: "#F2FFEF",
  LowMedium: "#F3C334",
  Medium: "#F3C334",
  HighMedium: "#F9D8D9",
  High: "#F9D8D9",
};

type Props = {
  identifiedNotice: IdentifiedNoticeType;
  reset: () => void;
};

function IrsNoticeAnalyzerResult({ identifiedNotice, reset }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [lastFreeTaxRiskReport, setLastFreeTaxRiskReport] =
      useState<LastFreeRiskReports>();
  const [isEnrolled, setIsEnrolled] = useState(false);

  useEffect(() => {
    const fetchGetTaxReports = async () => {
      const client = new clientConfig();
      await Auth.currentAuthenticatedUser({ bypassCache: true });

      const profileId = await userProfileData();

      client
          .query({
            query: GetFreeTaxRiskReportEligibility,
            variables: { profileId: profileId },
          })
          .then((res: any) => {
            if (res.data.GetFreeTaxRiskReportEligibility !== null) {
              setLastFreeTaxRiskReport(
                  res.data.GetFreeTaxRiskReportEligibility.LastTaxRiskReport
              );
            }
          })
          .catch((error: any) => {
            console.log(error, "error");
          });
    };

    fetchGetTaxReports();
  }, []);

  const navigate = useNavigate();
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getTaxHelpHandler = async () => {
    const client = new clientConfig();
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();

    client
      .query({
        query: GetEnrollmentsByProfile,
        variables: { ProfileId: profileId },
      })
      .then((res: any) => {
        const enrollments = res.data.GetEnrollmentsByProfile?.Enrollments;
        if (enrollments) {

          const protectionPlanEnrollment = enrollments.filter((enrollment: any) => enrollment?.Type === 'ProtectionPlan').pop();

          if (protectionPlanEnrollment && protectionPlanEnrollment?.Status === EnrollmentStatus.Initiated || protectionPlanEnrollment?.Status === EnrollmentStatus.Completed) {
            setIsEnrolled(true);
          }else {
            setIsEnrolled(false);
          }
          setShowModal(true);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      })
  }

  const onProceedHandler = () => {
    if(isEnrolled){
      navigate('/appointments');
    }else {
      navigate('/protection-plan-enroll');
    }
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5 px-lg-5">
            <Row className="px-lg-5 justify-content-between align-items-center">
              <Col lg={4}>
                <img
                  src={RISK_ICON[identifiedNotice.RiskLevel]}
                  alt="Tax-report"
                  className="img-fluid"
                />
              </Col>
              <Col lg={4} className="">
                <Table className="borderless">
                  <thead>
                    <tr>
                      <th>
                        <h1 className="content-box-heading mb-0">NOTICE:</h1>
                      </th>
                      <th>
                        <h1 className="content-box-heading mb-0">
                          {identifiedNotice.Name || "-"}
                        </h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Due Date </td>
                      <td>
                        {identifiedNotice.OverDue
                          ? "Due Immediately"
                          : identifiedNotice.DueDate || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                        {USDollar.format(identifiedNotice.AmountDue) || "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="row px-lg-5  align-items-center">
              <Col
                md={12}
                className="px-lg-5 py-4 d-flex mt-5 align-items-center"
                style={{
                  backgroundColor: RISK_Color[identifiedNotice.RiskLevel],
                }}
              >
                <div>
                  <h5 className="browse mb-3" style={{ color: "black" }}>
                    {RISK_Text[identifiedNotice.RiskLevel]}
                  </h5>
                  <p>{identifiedNotice.Description}</p>
                </div>
              </Col>
              <p className="text-center mt-3">
                <i>
                  Collection risk as determined by Optima based on several
                  factors, such as notice code and associated collections stage.
                </i>
              </p>

              <div className="d-flex justify-content-between mt-3 align-items-center px-lg-0">
                <button
                  style={{ width: "200px" }}
                  onClick={() => reset()}
                  className="btn-continue-outline"
                >
                  Analyze Another Notice
                </button>
                <button
                  style={{ width: "200px" }}
                  onClick={getTaxHelpHandler}
                  className="btn-continue"
                >
                  Get Tax Help
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 style={{color: "#1589D2", fontWeight: "bold"}}>We Can Help!</h4>
            <p className="text-muted text-center">
              {isEnrolled? 
              'Kindly arrange a meeting with your Client Liaison to gain a clearer insight into your current tax situation.'
              :'Your next step is to get your Tax Report which will give us a better understanding of your current tax situation.'
              }
              </p>
            <Col md={6} className="my-3">
            <button
                style={{width: "100%"}}
                className="irs-btn-submit"
                onClick={onProceedHandler}
            >
              Proceed
            </button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default IrsNoticeAnalyzerResult;
