import React, { useEffect, useState } from "react";
import TaxReport from "./taxReport";
import { Provider } from "./TaxFormContext";
import Qualify from "./qualify";
import QualifyBusiness from "./QualifyBusiness";
import PersonalInformation from "./personalInformation";
import TaskRiskReport from "./taskRiskReport";
import Signature from "./signature";
import GovermentPhotoId from "../organicClient/governmentPhotoId";
import TaxInformatioAuthorization from "../organicClient/taxInformationAuthorization";
import AppEnrollmentSuccessfull from "./appEnrollmentSuccessfull";
import Loading from "../../Common/Loading";
import { clientConfig } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { GetFreeTaxRiskReportEligibility } from "../../queries/GetFreeTaxRiskReportEligibility";
import { InitiateEnrollment } from "../../queries/InitiateEnrollment";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";

const renderStep = (step: number, completedPerecent: number) => {
  switch (step) {
    case 0:
      return <TaxReport />;
    case 1:
      return <QualifyBusiness progressPercent={completedPerecent} />;
    case 2:
      return <Qualify progressPercent={completedPerecent} />;
    case 3:
      return <PersonalInformation progressPercent={completedPerecent} />;
    case 4:
      return <TaskRiskReport progressPercent={completedPerecent} />;
    case 5:
      return <Signature progressPercent={completedPerecent} />;
    case 6:
      return <GovermentPhotoId progressPercent={completedPerecent} />;
    case 7:
      return <TaxInformatioAuthorization progressPercent={completedPerecent} />;
    case 8:
      return <AppEnrollmentSuccessfull />;
    case 9:
      return <Loading />;
    default:
      return null;
  }
};

const TaxReportForm = () => {
  const [enrollmentId, setEnrollmentId] = useState("");
  const [nextFormId, setNextFormId] = useState("");
  const [jointTaxReturn, setJointTaxReturn] = useState("No");
  const [businessReport, setBusinessReport] = useState("No");
  const [businessTaxReturn, setBusinessTaxReturn] = useState('No');
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [currentStep, setCurrentStep] = useState(9);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaxReportEligibility();
  }, []);
  const fetchTaxReportEligibility = async () => {
    const client = new clientConfig();
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();

    client
      .query({
        query: GetFreeTaxRiskReportEligibility,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res?.data.GetFreeTaxRiskReportEligibility?.EnrollmentInitiated) {
          const variables = {
            profileId: profileId,
            type: "Investigation",
          };

          client
            .mutate({ mutation: InitiateEnrollment, variables: variables })
            .then((res: any) => {
              if (res.data.InitiateEnrollment.Id) {
                setEnrollmentId(res.data.InitiateEnrollment.Id);
                setNextFormId(res.data.InitiateEnrollment.NextForm?.Id);
                setS3Bucket(res.data.InitiateEnrollment.NextForm?.S3Bucket);
                setS3Key(res.data.InitiateEnrollment.NextForm?.S3Key);
                NextEnrollmentAction(
                  res.data.InitiateEnrollment?.NextEnrollmentAction
                );
              }
            })
            .catch((error: any) => {
              navigate("/dashboard");
            });
        } else {
          if (res.data.GetFreeTaxRiskReportEligibility.IsEligible) {
            setCurrentStep(0);
          } else {
            setCurrentStep(8);
          }
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
        navigate("/dashboard");
      });
  };

  const next = () => {
    if (currentStep === 8) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const NextEnrollmentAction = (nextAction: string) => {
    if (nextAction === "AnswerQualifyingQuestions") {
      setCurrentStep(1);
    }
    if (nextAction === "CompleteInformation") {
      setCurrentStep(3);
    }
    if (nextAction === "CompleteServiceAgreement") {
      setCurrentStep(4);
    }
    if (nextAction === "UploadIdentification") {
      setCurrentStep(6);
    }
    if (nextAction === "CompleteForm8821") {
      setCurrentStep(7);
    }
    if (!nextAction) {
      setCurrentStep(8);
    }
  };

  const completedPerecent = (currentStep / 8) * 100;

  return (
    <Provider
      value={{
        next,
        enrollmentId,
        setEnrollmentId,
        nextFormId,
        setNextFormId,
        jointTaxReturn,
        setJointTaxReturn,
        businessReport,
        setBusinessReport,
        businessTaxReturn,
        setBusinessTaxReturn,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
        NextEnrollmentAction,
      }}
    >
      <main>{renderStep(currentStep, completedPerecent)}</main>
    </Provider>
  );
};
export default TaxReportForm;
