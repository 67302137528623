import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { BusinessQuestionnaireData, initialStateReturnYears } from "./TaxPreparationServiceFormContext";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/close-circle-icon.svg";
import "./styles.css";
import { compareCurrentDate, getCurrentTaxYear } from "../../functions/misc";
import OverlayHelpTooltip from "../../Common/OverlayHelpTooltip";
import BusinessQuestionnaireReturnsForm from "./BusinessQuestionnaireReturnsForm";

type Props = {
  show: boolean;
  onClose: () => void;
  businessEntity: BusinessQuestionnaireData;
  onSave: (businessEntity: BusinessQuestionnaireData) => void;
};

const BusinessQuestionnaireModal = ({
  show,
  onClose,
  businessEntity,
  onSave,
}: Props) => {
  const [step, setStep] = useState<number>(1);
  const [showError, setShowError] = useState(false);
  const [businessEntityData, setBusinessEntityData] = useState(businessEntity);
  const [show941ReturnYears, setShow941ReturnYears] = useState(businessEntity.taxForm941?true:false);
  const [disableSaveButton, setDisableSaveButton] = useState(false); 

  console.log(businessEntityData);

  const onStepChangeHandler = (type: string) => {
    let canContinue = true;
    if (step === 1) {
      if (
        businessEntityData.entityName &&
        businessEntityData.firstYearOfBusiness &&
        !compareCurrentDate(businessEntityData.firstYearOfBusiness) &&
        businessEntityData.grossRevenue
      ) {
        canContinue = true;
        setShowError(false);
      } else {
        canContinue = false;
        setShowError(true);
      }
    }else if(step === 2) {
      if(businessEntityData.taxForm941 && businessEntityData.stateReturn941 === 'Yes') {
        for(let index = 0; index < businessEntityData.returnYears941.length; index++){
          const returnYears941Obj = businessEntityData.returnYears941[index];
          if(returnYears941Obj.year === 0 || returnYears941Obj.quarter === 0){
            canContinue = false;
            setShowError(true);
            break;
          }
        }
      }
    }else if(step === 3) {
      if(businessEntityData.taxForm === "0") {
          canContinue = false;
          setShowError(true);
      }else if(businessEntityData.taxForm === "Form1065" && businessEntityData.partnersCount === 0){
        canContinue = false;
        setShowError(true);
      }else {
        canContinue = true;
      }
    }
    if (canContinue) {
      setShowError(false);
      setStep(type === "back" ? step - 1 : step + 1);
    }
  };

  useEffect(() => {
    if(step === 2 && show941ReturnYears && businessEntityData.returnYears941.length === 0){
      addReturnYears941InfoCompoment();
    }
  },[step]);

  const returnsYears941ValuesChangeHandler = (event:any, index:number) => {
    const {name, value} = event.target;
    let returnYears941Array = [...businessEntityData.returnYears941];
    returnYears941Array[index] = {...returnYears941Array[index], [name]:value};
    setBusinessEntityData(prevState => ({...prevState, returnYears941: returnYears941Array}));
  }

  const addReturnYears941InfoCompoment = () => {
    const stateReturnYears941 = initialStateReturnYears;
    let returnYears941Array = [...businessEntityData.returnYears941];
    returnYears941Array = [...returnYears941Array, stateReturnYears941];
    setBusinessEntityData(prevState => ({...prevState, returnYears941: returnYears941Array}));
  }

  const removeReturnYears941InfoCompoment = (index: number) => {
    let returnYears941Array = [...businessEntityData.returnYears941];
    returnYears941Array.splice(index,1);
    setBusinessEntityData(prevState => ({...prevState, returnYears941: returnYears941Array}));
  }

  const onChangeHandler = (event: any) => {
    let { name, value, type, checked } = event.target;
    if(type === 'radio') {
      name = name.substring(0, name.indexOf('-'));
      value = event.target.name.substring(event.target.name.indexOf('-')+1);
    }
    setBusinessEntityData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    if(name == 'taxForm941') {
      if(checked) {
        setBusinessEntityData(prevState => ({...prevState, returnYears941: [initialStateReturnYears]}));
        setShow941ReturnYears(true);
      }else {
        setBusinessEntityData(prevState => ({...prevState, returnYears941: []}));
        setShow941ReturnYears(false);
      }
    }

    /*if(name === 'stateReturn941') {
      if(value === 'Yes') {
        setBusinessEntityData(prevState => ({...prevState, returnYears941: [initialStateReturnYears]}));
        setShow941ReturnYears(true);
      }else {
        setBusinessEntityData(prevState => ({...prevState, returnYears941: []}));
        setShow941ReturnYears(false);
      }
    }*/
  };

  const closeHandler = () => {
    setStep(1);
    setShowError(false);
    onClose();
  };

  const handleSave = async () => {
    if(businessEntityData.taxForm === "0" || businessEntityData.taxForm === "") {
      setShowError(true);
    }else if(businessEntityData.taxForm === "Form1065" && businessEntityData.partnersCount === 0){
      setShowError(true);
    }else {
      setShowError(false);
      setDisableSaveButton(true);
      await onSave(businessEntityData);
      setDisableSaveButton(false);
      closeHandler();
    }
  };

  const validateAmount = (event: any) => {
    const regex = new RegExp("^[0-9.]");
    const key = event.key;
    if (!regex.test(key) && !(key === "Backspace")) {
      event.preventDefault();
      return false;
    }
  };

  let years = [];

  let currentYear = new Date().getFullYear();

  let startYear = currentYear - 100;
  startYear = startYear || 1980;
  while (currentYear >= startYear) {
    years.push(currentYear--);
  }

  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={onClose}
      size="lg"
    >
      <Modal.Body className="px-3">
        <div className="d-flex justify-content-between my-2">
          <h6 className="ff-Montserrat fs-11 color-primary">
            {"Business Entity"}
          </h6>
          <CloseIcon onClick={onClose} />
        </div>
        {showError && (
          <p className="fs-8 color-red">
            Please fill required and valid information in this form.
          </p>
        )}
        {step === 1 && (
          <Form as={Row}>
            <Row>
              <Col xl={12} lg={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Entity Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="entityName"
                    value={businessEntityData.entityName}
                    onChange={onChangeHandler}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>First Year of Business*</Form.Label>
                  <Form.Select
                  value={businessEntityData.firstYearOfBusiness}
                  name="firstYearOfBusiness"
                  onChange={onChangeHandler}
                  >
                    <option value="0">-</option>
                    {years.map((year) => {
                      return <option value={year}>{year}</option>;
                    })}
                </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{`Gross Revenue for ${getCurrentTaxYear()}*`}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      name="grossRevenue"
                      type="text"
                      value={businessEntityData.grossRevenue}
                      onChange={onChangeHandler}
                      onKeyDown={(event) => validateAmount(event)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
        {step === 2 && (
          <Form as={Row}>
            <div className='d-flex justify-content-between mb-1'>
              <h6 className="ff-Montserrat fs-10 color-primary">
                {"Payroll Tax Form(s) - Select All That Applies"}
              </h6>
              <div>
                <OverlayHelpTooltip text={'Payroll taxes are required to be filed where you have W2 employee employed by your business or sole prioprietorship.'}/>
              </div>
            </div>
            <div className="mb-1">
              <Form.Label>Payroll taxes are required to be filled where you have W2 employee employed by your business or sole-proprietorship.</Form.Label>
            </div>
            <Row className="d-flex align-items-center mt-2">
              <Col xl={12} lg={12}>
                <Form.Check
                  type="checkbox"
                  name="taxForm941"
                  checked={businessEntityData.taxForm941}
                  label="941: Employer’s Quarterly Federal Tax Return"
                  onChange={onChangeHandler}
                />
              </Col>
              <Form.Label className="ms-4 mt-0 pt-0">State-specific filings are not available for this form</Form.Label>
            </Row>
            {show941ReturnYears && 
              <Row>
                <Col md={12} lg={12} className="d-flex align-items-center mb-3">
                  <div className='informFormCard w-100'>
                    {businessEntityData.returnYears941.map((returnYear941, index) => {
                      return <BusinessQuestionnaireReturnsForm returnYearsData={returnYear941} 
                                        index={index} 
                                        onValueChange={returnsYears941ValuesChangeHandler}
                                        onAdd={addReturnYears941InfoCompoment}
                                        onRemove={removeReturnYears941InfoCompoment}
                                        recordsCount={businessEntityData.returnYears941.length}/>
                    })}
                  </div>
                </Col>
              </Row>
            }
            <Row className="d-flex align-items-center mt-2">
              <Col xl={12} lg={12}>
                <Form.Check
                  type="checkbox"
                  name="taxForm940"
                  checked={businessEntityData.taxForm940}
                  label="940: Employer’s Annual Federal Unemployment (FUTA) Tax Return"
                  onChange={onChangeHandler}
                />
                <Form.Label className="ms-4 mt-0 pt-0">State-specific filings are not available for this form</Form.Label>
              </Col>
            </Row>
            
            <Row className="d-flex align-items-center mt-2">
              <Col xl={12} lg={12}>
                <Form.Check
                  type="checkbox"
                  name="taxForm943"
                  checked={businessEntityData.taxForm943}
                  label="943: Employer’s Annual Federal Tax Return for Agricultural Employees"
                  onChange={onChangeHandler}
                />
                <Form.Label className="ms-4 mt-0 pt-0">State-specific filings are not available for this form</Form.Label>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mt-2">
              <Col xl={12} lg={12}>
                <Form.Check
                  type="checkbox"
                  name="taxForm944"
                  checked={businessEntityData.taxForm944}
                  label="944: Employer’s Annual Federal Tax Return (for withholdings less than $1,000 per year)"
                  onChange={onChangeHandler}
                />
                <Form.Label className="ms-4 mt-0 pt-0">State-specific filings are not available for this form</Form.Label>
              </Col>
            </Row>
          </Form>
        )}
        {step === 3 && (
          <Form as={Row}>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <h6 className="ff-Montserrat fs-10 color-primary">
                {"Income Tax Form"}
              </h6>
              <div className="me-4">
                <OverlayHelpTooltip text={'If you are a sole proprietor or A single member LLC you typically report your income and expenses on your form 1040 for your federal income tax return.'}/>
              </div>
            </div>
            <Row className="d-flex align-items-center mt-1">
              <Col xl={12} lg={12}>
                <Form.Select
                  size="sm"
                  name="taxForm"
                  value={businessEntityData.taxForm}
                  onChange={onChangeHandler}
                >
                  <option value={"0"}>Select a Form</option>
                  <option value={"Form1065"}>Form 1065: Partnership Federal Income Tax Return</option>
                  <option value={"Form1120"}>Form 1120: Corporation Federal Income Tax Return</option>
                  <option value={"Form1120S"}>Form 1120-S: S-Corporation Federal Income Tax Return</option>
                </Form.Select>
              </Col>
            </Row>
            {businessEntityData.taxForm === "Form1065" && (
              <Row className="d-flex align-items-center mt-3">
                <Col xl={6} lg={6}>
                  <Form.Label>How many partners are there?</Form.Label>
                </Col>
                <Col xl={3} lg={3}>
                  <Form.Control
                    type="number"
                    name="partnersCount"
                    value={businessEntityData.partnersCount}
                    onChange={onChangeHandler}
                  />
                </Col>
              </Row>
            )}
            <Row className="d-flex align-items-center mt-3">
            <Col md={6} lg={6} className="d-flex align-items-center">
                  <Form.Label>{'Do you also need a state return?'}</Form.Label>
            </Col>
            <Col md={4} lg={4} className='d-flex justify-content-start'>
                  <Form.Check
                  inline
                  type="radio"
                  className='fs-14'
                  label="Yes"
                  name="taxFormStateReturn-Yes"
                  onChange={onChangeHandler}
                  checked={businessEntityData.taxFormStateReturn === 'Yes'?true:false}
                  />
                  <Form.Check
                  inline
                  type="radio"
                  className='fs-14'
                  label="No"
                  name="taxFormStateReturn-No"
                  onChange={onChangeHandler}
                  checked={businessEntityData.taxFormStateReturn === 'No'?true:false}
                  />
            </Col>
            </Row>
          </Form>
        )}
        <Row className="mt-4">
          <Col
            xl={12}
            lg={12}
            md={12}
            className="d-flex align-items-center justify-content-end mt-2"
          >
            <span
              className="letsdolater"
              style={{ textDecoration: "underline" }}
              onClick={closeHandler}
            >
              Close
            </span>
            {step !== Number(1) && (
              <Button
                variant="btn btn-primary ms-2"
                style={{
                  borderRadius: "28px",
                  paddingInline: "20px",
                  paddingTop: "0.2rem",
                  paddingBottom: "0.2rem",
                }}
                onClick={() => onStepChangeHandler("back")}
              >
                Back
              </Button>
            )}
            {step !== Number(3) && (
              <Button
                variant="btn btn-primary ms-2"
                style={{
                  borderRadius: "28px",
                  paddingInline: "20px",
                  paddingTop: "0.2rem",
                  paddingBottom: "0.2rem",
                }}
                onClick={() => onStepChangeHandler("next")}
              >
                Next
              </Button>
            )}
            {step === Number(3) && (
              <Button
                variant="btn btn-primary ms-2"
                style={{
                  borderRadius: "28px",
                  paddingInline: "20px",
                  paddingTop: "0.2rem",
                  paddingBottom: "0.2rem",
                }}
                onClick={handleSave}
                disabled={disableSaveButton}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BusinessQuestionnaireModal;
