import React, { useContext, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TaxReportForm from "../taxReport/TaxFormContext";
import { clientConfig } from "../../awsClientConfig";
import { QualifyEnrollmentResult } from "../../graphql/one-api/custom-mutations/Qualify";
import { QualifyingAnswerResult }from '../../API';
import AppProgressBar from "../../Common/progressBar";
import Loading from "../../Common/Loading";

function Qualify({ progressPercent = 0 }) {
  const { next, enrollmentId,
    jointTaxReturn,
    setJointTaxReturn,
    businessReport,
    businessTaxReturn,
  } = useContext(TaxReportForm);
  const navigate = useNavigate();
  const [error, setError] = useState({
    JointTaxReturn: "",
    BusinessReport: "",
  });
  const [spinner, setSpinner] = useState(false);
  const client = new clientConfig();
  const validateForm = () => {
    let _error = true;
    if (jointTaxReturn === "") {
      setError({
        ...error,
        JointTaxReturn: "Joint Tax report Value is required",
      });
      _error = false;
    } else if (businessReport === "") {
      setError({
        ...error,
        BusinessReport: "Joint Tax report Value is required",
      });
      _error = false;
    }

    return _error;
  };

  const OnQualifySubmit = () => {
    if (validateForm()) {
      setSpinner(true);
      const variables = {
        enrolmentID: enrollmentId,
        answeredQuestions: [
          { QuestionKey: "BusinessReport", AnswerKey: businessReport },
          { QuestionKey: "JointTaxReturn", AnswerKey: jointTaxReturn },
          { QuestionKey: "BusinessTaxReturn", AnswerKey: businessTaxReturn },
        ],
      };
      client
       .mutate({ mutation: QualifyEnrollmentResult, variables: variables })
       .then((res: any) => {
         if (res.data.QualifyEnrollmentRequest.Result === QualifyingAnswerResult.Qualify) {
           next();
         } else {
           navigate("/contact-service");
         }
         setSpinner(false);
       })
       .catch((error: any) => {
         console.log("error", error);
         setSpinner(false);
         navigate("/contact-service");
       });
    }
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            {spinner ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />

                <h1 className="content-box-heading mb-5">Filing a Joint Tax Return?</h1>
                <Form>
                  <Row className="mb-1">
                    <Col md={'auto'}>
                      <Form.Group controlId="">
                        <Form.Label className='fw-bold'>
                         <p className="content-text mb-0">
                          Have You or Do You Plan on Filing a Joint Tax Return?
                         </p>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        onClick={() => setJointTaxReturn("Yes")}
                        checked={jointTaxReturn === "Yes" ? true : false}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        onClick={() => setJointTaxReturn("No")}
                        checked={jointTaxReturn === "No" ? true : false}
                      />
                    </Col>
                  </Row>
                  {jointTaxReturn === "Yes" ?
                      <Row className="mb-3">
                        <Col md={11} className="content-text">
                          Please be advised that your report will only identify account information associated with
                          your social security number. Click "Continue" to proceed.
                        </Col>
                      </Row>
                      : <Row className="mb-3 invisible">
                        <Col md={11} className="content-text">
                         Please be advised that your report will only identify account information associated with
                         your social security number. Click "Continue" to proceed.
                        </Col>
                      </Row>}
                </Form>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={() => navigate("/dashboard")}
                  >
                    Lets do this later...
                  </span>

                  <button
                    onClick={OnQualifySubmit}
                    className="btn-continue"
                    disabled={spinner}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Qualify;
