import React, { useContext, useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { clientConfig } from "../../awsClientConfig";
import { GetOrganization } from "../../queries/GetOrganization";
import { UpdateOrganization } from "../../queries/UpdateOrganization";
import { UpdateOrganizationAddress } from "../../queries/UpdateOrganizationAddress";
import { CreateOrganizationAddress } from "../../queries/CreateOrganizationAddress";
import { DeleteOrganizationAddress } from "../../queries/DeleteOrganizationAddress";
import { CreatePartnerEnrollementAction } from "../../queries/CreatePartnerEnrollementAction";
import { StateOptions } from "../../contants";
import { formatZipcode } from "../../functions/misc";
import Message from "../../components/Message";
import { Spinner } from "react-bootstrap";

type BUSINESS_ADDRESS = {
  streetAddress1: string;
  streetAddress2: string;
  zipCode: string;
  state: string;
  city: string;
  id: string;
};

const FORM_ERROR = {
  streetAddress1: "",
  // streetAddress2: "",
  zipCode: "",
  state: "",
  city: "",
};

function StoreFrontQuestion({ progressPercent = 0 }) {
  const { next, organizationId, setOrganizationId, enrollmentId } = useContext(
    PartnerEnrollmentFormContext
  );
  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);
  const client = new clientConfig();

  const [business, setBusiness] = useState({
    name: "",
    eni: "",
    type: "SoleProprietorship",
    phone: "",
  });

  const [businessAddress, setBusinessAddress] = useState<BUSINESS_ADDRESS>({
    streetAddress1: "",
    streetAddress2: "",
    zipCode: "",
    state: "",
    city: "",
    id: "",
  });

  const [storeFrontAddress, setStoreFrontAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    zipCode: "",
    state: "AL",
    city: "",
    id: "",
  });

  const [isStoreFrontLocation, setIsStoreFrontLocation] =
    useState<boolean>(true);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };
      if (storeFrontAddress.streetAddress1) {
        error.streetAddress1 = "";
      } else {
        error.streetAddress1 = "Street Address can not be empty";
      }

      // if (storeFrontAddress.streetAddress2) {
      //   error.streetAddress2 = "";
      // } else {
      //   error.streetAddress2 = "Street Address can not be empty";
      // }

      if (storeFrontAddress.city) {
        error.city = "";
      } else {
        error.city = "City can not be empty";
      }

      if (storeFrontAddress.state) {
        error.state = "";
      } else {
        error.state = "State can not be empty";
      }

      if (storeFrontAddress.zipCode) {
        error.zipCode = "";
      } else {
        error.zipCode = "ZIP Code can not be empty";
      }

      setFromErros(error);
    };
    validateForm();
  }, [storeFrontAddress]);
  useEffect(() => {
    if (organizationId) {
      client
        .query({
          query: GetOrganization,
          variables: { organizationId },
        })
        .then((res: any) => {
          const organizationData = res.data.GetOrganization;
          const organizationAddresses = res.data.GetOrganization.OrganizationAddresses;

         const mainAddress = organizationAddresses?.find((address: any) => {
          return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === "Main");
         });

         const storefrontAddress = organizationAddresses?.find((address: any) => {
          return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === "Storefront");
         });

          setBusiness({
            eni: organizationData.Ein,
            name: organizationData.Name,
            phone: organizationData.Phone,
            type: organizationData.Type,
          });

          if (storefrontAddress) {
           if (mainAddress?.Id !== storefrontAddress?.Id) {
            setStoreFrontAddress({
             streetAddress1: storefrontAddress.StreetAddress1,
             streetAddress2: storefrontAddress.StreetAddress2,
             zipCode: storefrontAddress.ZipCode,
             state: storefrontAddress.State,
             city: storefrontAddress.City,
             id: storefrontAddress.Id,
            });
           }
          }

          if (mainAddress) {
            setBusinessAddress({
              streetAddress1: mainAddress.StreetAddress1,
              streetAddress2: mainAddress.StreetAddress2,
              zipCode: mainAddress.ZipCode,
              state: mainAddress.State,
              city: mainAddress.City,
              id: mainAddress.Id,
            });
          }
        })
        .catch((error) => {
         console.log('error', error);
        });
    }
  }, [organizationId]);

  const onChangeAddress = (event: any) => {
    const newBusinessAddress = {
      ...storeFrontAddress,
      [event.target.name]:
        event.target.name != "zipCode"
          ? event.target.value
          : formatZipcode(event),
    };

    setStoreFrontAddress(newBusinessAddress);
  };

  const onStoreFrontChange = (event: any) => {
    if (event.target.value === "1") {
      setSameAddress(false);
      setIsStoreFrontLocation(true);
      return;
    }
    setIsStoreFrontLocation(false);
  };

  const onChangeUseAddress = (event: any) => {
    if (event.target.value === "1") {
     setSameAddress(true);
      setStoreFrontAddress({
        city: businessAddress.city,
        state: businessAddress.state,
        streetAddress1: businessAddress.streetAddress1,
        streetAddress2: businessAddress.streetAddress2,
        zipCode: businessAddress.zipCode,
        id: storeFrontAddress.id,
      });

      return;
    }
   setSameAddress(false);
    setStoreFrontAddress({
      city: "",
      state: "AL",
      streetAddress1: "",
      streetAddress2: "",
      zipCode: "",
      id: storeFrontAddress.id,
    });
  };

  const onSaveChanges = async () => {
    setShowSpinner(true);
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
     setShowSpinner(false);
    }
    const res = await Auth.currentAuthenticatedUser();
    const partnerId = res.attributes["custom:Id"];
    const variables: any = {
      PartnerId: partnerId,
      Ein: business.eni,
      Name: business.name,
      Phone: business.phone,
      Type: business.type,
      Storefront: true,
      Id: organizationId,
    };

    if (isStoreFrontLocation && !isAnyError && !sameAddress) {
      client
        .mutate({
          mutation: UpdateOrganization,
          variables: variables,
        })
        .then((res: any) => {
          const organizationData = res.data.UpdateOrganization;
          const addressVariables: any = {
            City: storeFrontAddress.city,
            OrganizationId: organizationData.Id,
            State: storeFrontAddress.state,
            StreetAddress1: storeFrontAddress.streetAddress1,
            OrganizationAddressTypes: [{
             Type: "Storefront"
            }],
            ZipCode: storeFrontAddress.zipCode,
          };

          if (storeFrontAddress.streetAddress2) {
            addressVariables.StreetAddress2 = storeFrontAddress.streetAddress2;
          }

          const organizationAddresses = organizationData.OrganizationAddresses;

         const storeAddress = organizationAddresses?.find((address: any) => {
          return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === "Storefront");
         });

          if (storeAddress) {
            addressVariables.Id = storeAddress.Id;
          }

          client
            .mutate({
              mutation: storeAddress
                ? UpdateOrganizationAddress
                : CreateOrganizationAddress,
              variables: addressVariables,
            })
            .then(() => {
              setOrganizationId(organizationData.Id);
              next();
            })
            .catch((error: any) => {
              setShowSpinner(false);
              console.log(error);
            });
        })
        .catch((error: any) => {
          setShowSpinner(false);
          console.log(error);
        });

      return;
    }

    variables.Storefront = false;

    if (storeFrontAddress.id && !isAnyError) {
      const deleteVariables = {
        OrganizationAddressId: storeFrontAddress.id,
      };
      client
        .mutate({
          mutation: DeleteOrganizationAddress,
          variables: deleteVariables,
        })
        .then(() => {
          next();
        });
      return;
    }

    if (isStoreFrontLocation && sameAddress) {
     variables.Storefront = true;
     client
      .mutate({
       mutation: UpdateOrganization,
       variables: variables,
      })
      .then((res: any) => {
         const organizationData = res.data.UpdateOrganization;
         const addressVariables: any = {
          City: businessAddress.city,
          OrganizationId: organizationData.Id,
          State: businessAddress.state,
          StreetAddress1: businessAddress.streetAddress1,
          OrganizationAddressTypes: [
           { Type: "Main" },
           { Type: "Storefront" },
          ],
          ZipCode: businessAddress.zipCode,
         };

         if (businessAddress.streetAddress2) {
          addressVariables.StreetAddress2 = businessAddress.streetAddress2
         }


       const organizationAddresses = organizationData.OrganizationAddresses;

       const mainAddress = organizationAddresses?.find((address: any) => {
        return address.OrganizationAddressTypes?.some((addressTypes: any) => addressTypes.Type === "Main");
       });

       if (mainAddress) {
        addressVariables.Id = mainAddress.Id;
       }
       client
        .mutate({
          mutation: mainAddress
           ? UpdateOrganizationAddress
           : CreateOrganizationAddress,
          variables: addressVariables,
        })
        .then(() => {
          next();
          setOrganizationId(organizationData.Id);
          next();
         })
         .catch((error) => {
           setShowSpinner(false);
           console.log('error', error);
         });
      })
      .catch((error) => {
       setShowSpinner(false);
       console.log('error', error);
      });
    }

    if (!isStoreFrontLocation) {
      client
        .mutate({
          mutation: UpdateOrganization,
          variables: variables,
        })
        .then(() => {
          next();
        })
        .catch((error) => {
         setShowSpinner(false);
         console.log(error);
        });
    }
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading mb-4">
                Do You Have a Storefront Location?{" "}
              </h1>

              <Form>
                <Row className="mb-5">
                  <Col md={2}>
                    <Form.Select
                      onChange={onStoreFrontChange}
                      aria-label="Default select example"
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Col>
                </Row>
                {isStoreFrontLocation && (
                  <>
                    <h6 className="content-box-subtitle mb-2">Storefront Address</h6>
                    <small>Same as the address provided previously?</small>
                    <Row className="mb-4">
                      <Col md={2}>
                        <Form.Select
                          onChange={onChangeUseAddress}
                          aria-label="Default select example"
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12} className="mb-3">
                        <label className="mb-2">
                          Provide your business storefront address if different
                          from your mailing address.
                        </label>
                        <Form.Group controlId="">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            name="streetAddress1"
                            onChange={onChangeAddress}
                            value={storeFrontAddress.streetAddress1}
                            type="text"
                            placeholder=""
                            disabled={sameAddress}
                          />

                          {submited && formErrors.streetAddress1 && (
                            <Message
                              message={formErrors.streetAddress1}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group controlId="">
                          <Form.Label>Street Address 2</Form.Label>
                          <Form.Control
                            name="streetAddress2"
                            onChange={onChangeAddress}
                            value={storeFrontAddress.streetAddress2}
                            type="text"
                            placeholder=""
                            disabled={sameAddress}
                          />
                          {/* 
                          {submited && formErrors.streetAddress2 && (
                            <Message
                              message={formErrors.streetAddress2}
                              type="error"
                            ></Message>
                          )} */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={5}>
                        <Form.Group controlId="">
                          <Form.Label>City </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="city"
                            value={storeFrontAddress.city}
                            onChange={onChangeAddress}
                            disabled={sameAddress}
                          />

                          {submited && formErrors.city && (
                            <Message
                              message={formErrors.city}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group controlId="">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            name="state"
                            onChange={onChangeAddress}
                            value={storeFrontAddress.state}
                            aria-label="Default select example"
                            disabled={sameAddress}
                          >
                            {StateOptions.map((state) => {
                              return <option value={state}>{state}</option>;
                            })}
                          </Form.Select>

                          {submited && formErrors.state && (
                            <Message
                              message={formErrors.state}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        <Form.Group controlId="">
                          <Form.Label>ZIP Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="zipCode"
                            value={storeFrontAddress.zipCode}
                            onChange={(e) => onChangeAddress(e)}
                            disabled={sameAddress}
                          />

                          {submited && formErrors.zipCode && (
                            <Message
                              message={formErrors.zipCode}
                              type="error"
                            ></Message>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>{" "}
                  </>
                )}
              </Form>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
               <button disabled={showSpinner} onClick={onSaveChanges} className="btn-continue">
                {showSpinner &&
                 <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mx-2"
                 />}
                Continue
               </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default StoreFrontQuestion;
