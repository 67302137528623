import "../../../pages/navbar.css";
import "../../styles.css"
import EnrollmentReminderImg from "../../../assets/enrollment-reminder.svg";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import irsNoticeIcon from "../../../assets/irs-notice-icon.svg";
import CalendarIcon from "../../../assets/calendar.svg";
import appstoreIcon from "../../../assets/appstore-icon.png";
import playstoreIcon from "../../../assets/playstore-icon.png";
import { clientConfig } from "../../../awsClientConfig";
import { userProfileData } from "../../../Common/profileInfo";
import { GetProfile } from "../../../queries/getProfile";
import TaxTools from "../ClientDashboards/TaxTools";
import { Carousel } from "react-bootstrap";
import TaxPreparationCard from "../ClientDashboards/TaxPreparationCard";
import useEnrollmentStore, { ProtectionPlanName } from "../../../store/EnrollmentStore";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

type Props = {
  isEnrollmentEligible: boolean;
  isEnrollmentInitiated: boolean;
  taxPrepEnrollmentStatus: boolean | undefined;
};

function EnrollmentReminder({
  isEnrollmentEligible,
  isEnrollmentInitiated,
  taxPrepEnrollmentStatus
}: Props) {
  const [hideCarousel, setHideCarousel] = useState(false);
  const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();
  const navigate = useNavigate();
  const client = new clientConfig();

  useEffect(() => {
    if (taxPrepEnrollment?.taxPrepEnrollmentCompleted ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Standard ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Professional) {
      setHideCarousel(true);
    }
  }, []);

  const onCompleteEnrollment = async () => {
    if (isEnrollmentEligible && isEnrollmentInitiated) {
      const profileId = await userProfileData();
      client
        .query({
          query: GetProfile,
          variables: { profileId: profileId },
        })
        .then((res: any) => {
          if (res.data.GetProfile !== null) {
            if (res.data.GetProfile.EnrollmentSource === "Referral") {
              navigate("/res-protection-plan");
            } else {
              navigate("/protection-plan");
            }
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
    }
  };
  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <motion.div {...motionContainer}>
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-3 px-lg-5">
            {process.env.REACT_APP_TAX_PREPNETWORK_ALLOWED === 'true' && !hideCarousel ?
          <Carousel slide={true} data-bs-theme="dark" controls={false} interval={null} className="pb-5">
          <Carousel.Item>
            <Row className="px-lg-5 justify-content-center align-items-center">
              <Col lg={7}>
                <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                  Reminder to Finish Enrollment
                </h1>
                <p className="content-text">
                  Please finish your enrollment to access Optima's services designed to take the stress out of tax.
                </p>

                <div className="d-flex justify-content-start mt-5 align-items-center">
                  <button
                    className="btn-xl"
                    onClick={onCompleteEnrollment}
                  >
                    Finish My Enrollment
                  </button>
                </div>
              </Col>
              <Col lg={5}>
                <img
                  src={EnrollmentReminderImg}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </Col>
            </Row>
            </Carousel.Item>
             <Carousel.Item>
               <TaxPreparationCard />
           </Carousel.Item>
          </Carousel>
            :
              <Row className="px-lg-5 justify-content-center align-items-center">
                <Col lg={7}>
                  <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                    Reminder to Finish Enrollment
                  </h1>
                  <p className="content-text">
                    Please finish your enrollment to access Optima's services designed to take the stress out of tax.
                  </p>

                  <div className="d-flex justify-content-start mt-5 align-items-center">
                    <button
                      className="btn-xl"
                      onClick={onCompleteEnrollment}
                    >
                      Finish My Enrollment
                    </button>
                  </div>
                </Col>
                <Col lg={5}>
                  <img
                    src={EnrollmentReminderImg}
                    alt="Tax-report"
                    className="w-75 d-block mx-auto"
                  />
                </Col>
              </Row>
          }
          </Col>
          <Col md={10} className="px-0 my-3">
            <TaxTools showProtectionPlanCard={true} />
          </Col>
        </Row>
        </motion.div>
      </Container>
    </>
  );
}

export default EnrollmentReminder;
