import gql from "graphql-tag";

export const CreateOrganization = gql`
  mutation CreateOrganization(
    $Ein: String!
    $Name: String!
    $Phone: AWSPhone!
    $Type: OrganizationType!
    $Storefront: Boolean!
    $PartnerId: ID!
  ) {
    CreateOrganization(
      Input: {
        Ein: $Ein
        Name: $Name
        Phone: $Phone
        Type: $Type
        Storefront: $Storefront
      }
      PartnerId: $PartnerId
    ) {
      CreatedAt
      Ein
      Id
      Name
      Phone
      PhoneExt
      Type
      UpdatedAt
      OrganizationAddresses {
        City
        CreatedAt
        Id
        OrganizationId
        State
        StreetAddress1
        StreetAddress2
        UpdatedAt
        ZipCode
      }
    }
  }
`;
