import gql from "graphql-tag";

export const UpdateTaxProfessional = gql`
  mutation UpdateTaxProfessional(
    $Id: ID!
    $Bookkeeping: Boolean!
    $DisciplinaryAction: Boolean!
    $DisciplinaryActionDesc: String
    $EoCoverage: Float!
    $EoInsurance: Boolean!
    $FilingSoftware: [TaxProfessionalFilingSoftware!]!
    $IrsAudit: Boolean!
    $IrsAuditDesc: String
    $Languages: [Language!]!
    $Licenses: [TaxProfessionalLicense!]!
    $PrepCompetency: Boolean!
    $Ptin: String!
    $ReturnForms: [TaxProfessionalReturnForm!]!
    $ReturnSchedules: [TaxProfessionalReturnSchedule!]!
    $TaxResolution: Boolean!
    $TaxReturnsPrepared: Int!
    $YearsOfExperience: Int!
  ) {
    UpdateTaxProfessional(
      Input: {
        Id: $Id
        Bookkeeping: $Bookkeeping
        DisciplinaryAction: $DisciplinaryAction
        DisciplinaryActionDesc: $DisciplinaryActionDesc
        EoCoverage: $EoCoverage
        EoInsurance: $EoInsurance
        FilingSoftware: $FilingSoftware
        IrsAudit: $IrsAudit
        IrsAuditDesc: $IrsAuditDesc
        Languages: $Languages
        Licenses: $Licenses
        PrepCompetency: $PrepCompetency
        ReturnForms: $ReturnForms
        Ptin: $Ptin
        ReturnSchedules: $ReturnSchedules
        TaxResolution: $TaxResolution
        TaxReturnsPrepared: $TaxReturnsPrepared
        YearsOfExperience: $YearsOfExperience
      }
    ) {
      Id
      PartnerId
      CreatedAt
      UpdatedAt
    }
  }
`;
