import gql from "graphql-tag";

export const UpdateEnrollmentProtectionPlan = gql`
  mutation UpdateEnrollmentProtectionPlan(
    $EnrollmentId: ID!
    $ProtectionPlanId: ID!
  ) {
    UpdateEnrollmentProtectionPlan(
      EnrollmentId: $EnrollmentId
      ProtectionPlanId: $ProtectionPlanId
    ) {
      CancellationReason
      InitiatedAt
      CancelledAt
      CompletedAt
      ProtectionPlanId
      RecommendedProtectionPlanId
      TranscriptRequestId
      NextEnrollmentAction
    }
  }
`;
