import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FileUploader } from "react-drag-drop-files";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import ListGroup from "react-bootstrap/ListGroup";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { userProfileData } from "../../Common/profileInfo";
import { SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { GetPartner } from "../../queries/GetPartner";
import { clientConfig } from "../../awsClientConfig";
import { Auth } from "aws-amplify";
import Message from "../../components/Message";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { UpdateTaxProfessional } from "../../queries/UpdateTaxProfessional";

const licenses = [
  {
    lable: "Enrolled Agent",
    value: "EnrolledAgent",
  },
  {
    lable: "Other (CTEC, CPA)",
    value: "Other",
  },
];

const FORM_ERROR = {
  licenses: "",
  ptin: "",
};

function LicenceAndInsurance() {
  const [licensesImages, setLicensesImages] = useState<any>([]);
  const [insuranceImages, setInsuranceImages] = useState<any>([]);
  const [taxProfessional, setTaxProfessional] = useState<any>();
  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);
  const [licensesFiles, setLicensesFiles] = useState<any>([]);
  const [insuranceFiles, setInsuranceFiles] = useState<any>([]);
  const [success, setSuccess] = useState("");
  const client = new clientConfig();
  const navigate = useNavigate();

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional?.Ptin) {
        error.ptin = "";
      } else {
        error.ptin = "Active PTIN can not be empty";
      }

      if (taxProfessional?.Licenses.length >= 1) {
        error.licenses = "";
      } else {
        error.licenses = "Licenses can not be empty";
      }

      setFromErros(error);
    };

    validateForm();
  }, [taxProfessional]);

  useEffect(() => {
    const fetchApi = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const partnerId = await userProfileData();
      client
        .query({
          query: GetPartner,
          variables: {
            PartnerId: partnerId,
          },
        })
        .then((res: any) => {
          console.log(res.data.GetPartner.TaxProfessional);
          setTaxProfessional(res.data.GetPartner.TaxProfessional);
        });
    };

    fetchApi();
  }, []);

  useEffect(() => {
    loadImages();
  }, []);

  useEffect(() => {
    loadInsuranceImages();
  }, []);

  const loadInsuranceImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    try {
      await Storage.list(`Partners/${profileId}/Insurance/`, {
        customPrefix: { public: "" },
        level: "public",
      }).then((res) => {
        setInsuranceImages(res.results);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const loadImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    try {
      await Storage.list(`Partners/${profileId}/Licenses/`, {
        customPrefix: { public: "" },
        level: "public",
      }).then((res) => {
        setLicensesImages(res.results);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const handleRemoveImage = async (imageUrl: string) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    try {
      await Storage.remove(imageUrl, {
        customPrefix: { public: "" },
        level: "public",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const handleRemoveInsuranceImage = async (imageUrl: string) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    try {
      await Storage.remove(imageUrl, {
        customPrefix: { public: "" },
        level: "public",
      }).then((res) => {
        loadInsuranceImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const onCheckBoxChange = (event: any) => {
    const licenses = [...taxProfessional.Licenses];
    const index = licenses.indexOf(event.target.name);

    if (event.target.checked) {
      licenses.push(event.target.name);
    } else if (!event.target.checked) {
      licenses.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ Licenses: [...licenses] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onSaveChanges = async () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
      return;
    }

    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();

    licensesFiles.forEach(async (file: any) => {
      const uuid = uuidv4();

      try {
        await Storage.put(`Partners/${profileId}/Licenses/${uuid}.png`, file, {
          contentType: "image/png", // contentType is optional
          customPrefix: { public: "" },
          tagging: "ExternalClearance=Partner",
        }).then((res) => {
          console.log(res);
        });
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    });

    insuranceFiles.forEach(async (file: any) => {
      const uuid = uuidv4();
      try {
        await Storage.put(`Partners/${profileId}/Insurance/${uuid}.png`, file, {
          contentType: "image/png", // contentType is optional
          customPrefix: { public: "" },
          tagging: "ExternalClearance=Partner",
        }).then((res) => {});
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    });

    client
      .mutate({
        mutation: UpdateTaxProfessional,
        variables: taxProfessional,
      })
      .then((res: any) => {
        if (res.data.UpdateTaxProfessional) {
          setLicensesFiles([]);
          setInsuranceFiles([]);
          loadImages();
          loadInsuranceImages();
          setSuccess("Updated Information");
          setTimeout(() => {
            setSuccess("");
          }, 5000);
        }
      });
  };

  const onPinChange = (event: any) => {
    const ptin: string = event.target.value;

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ Ptin: ptin },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onChange = (event: any) => {
    const value = Number(event.target.value);
    const InsuranceApplicable = event.target.value === "0" ? false : true;
    const newTaxProfessional = {
      ...taxProfessional,
      ...{ EoCoverage: value, EoInsurance: InsuranceApplicable },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const handleLicenceFileChange = (file: any) => {
    const files = [...licensesFiles, file];
    setLicensesFiles(files);
  };

  const handleFileRemoveImage = (fileIndex: number) => {
    const filterFiles = licensesFiles.filter(
      (_: any, index: number) => fileIndex !== index
    );

    setLicensesFiles(filterFiles);
  };

  const handleInsuranceFileChange = (file: any) => {
    const files = [...insuranceFiles, file];
    setInsuranceFiles(files);
  };

  const handleInsuranceFileRemoveImage = (fileIndex: number) => {
    const filterFiles = insuranceFiles.filter(
      (_: any, index: number) => fileIndex !== index
    );

    setInsuranceFiles(filterFiles);
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>Account Settings</Breadcrumb.Item>

              <Breadcrumb.Item active> Licenses & Insurance</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="content-box-heading mt-0 mb-3">
              Licenses & Insurance
            </h1>
            <h4 className="mt-3 ff-Montserrat fs-5  fw-bold text-main-dark d-inline me-2">
              Licenses
            </h4>
            <Row className="justify-content-between mb-3">
              <Col lg={4}>
                <Form className="mt-3">
                  {licenses.map((license) => {
                    const checked = taxProfessional?.Licenses.includes(
                      license.value
                    );
                    return (
                      <Form.Check
                        name={license.value}
                        onChange={onCheckBoxChange}
                        type="checkbox"
                        label={license.lable}
                        checked={checked}
                      />
                    );
                  })}
                  {submited && formErrors.licenses && (
                    <Message
                      message={formErrors.licenses}
                      type="error"
                    ></Message>
                  )}
                  <Form.Group controlId="">
                    <Form.Label>Active PTIN</Form.Label>
                    <Form.Control
                      onChange={onPinChange}
                      value={taxProfessional?.Ptin}
                      type="text"
                      placeholder=""
                    />
                    {submited && formErrors.ptin && (
                      <Message message={formErrors.ptin} type="error"></Message>
                    )}
                  </Form.Group>
                </Form>
              </Col>
              <Col lg={4}>
                <label>Files Uploaded</label>
                <div
                  className="overflow-auto border d-block align-items-stretch"
                  style={{ maxHeight: "220px", height: 220, overflowY: "auto" }}
                >
                  {licensesImages.map((result: any) => {
                    const data = result.key.split("Licenses/");

                    return (
                      <ListGroup as="ul" variant="flush">
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{data[1]}</div>
                          </div>
                          <FaTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveImage(result.key)}
                          />
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  })}
                </div>
              </Col>
              <Col lg={4}>
                <label>Files Selected</label>
                <div
                  className="overflow-auto border d-block align-items-stretch"
                  style={{ maxHeight: "220px", height: 220, overflowY: "auto" }}
                >
                  {licensesFiles.map((result: any, index: number) => {
                    return (
                      <ListGroup as="ul" variant="flush">
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{result.name}</div>
                          </div>
                          <FaTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() => handleFileRemoveImage(index)}
                          />
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  })}
                </div>

                <FileUploader
                  handleChange={handleLicenceFileChange}
                  name="file"
                  children={
                    <button className="btn-continue-outline d-block mx-auto my-3 w-100">
                      Select & Upload Files
                    </button>
                  }
                />
              </Col>
            </Row>
            <Row className="justify-content-between mb-3">
              <Col lg={12}>
                <h4 className="mt-3 ff-Montserrat fs-5  fw-bold text-main-dark d-inline me-2">
                  Insurance Information
                </h4>
              </Col>
              <Col lg={12}>
                <p>Please select and upload a copy of your Certificate of Insurance for your Errors & Omissions policy.</p>
              </Col>
              <Col lg={4}>
                <Form className="mt-3">
                  <Form.Group controlId="">
                    <Form.Label>Coverage Amount</Form.Label>

                    <Form.Select
                      name="eoCoverage"
                      onChange={onChange}
                      aria-label="Default select example"
                      value={taxProfessional?.EoCoverage}
                    >
                      <option value="0">$0</option>
                      <option value="249999">Less than 250,000</option>
                      <option value="250000">$250,000</option>
                      <option value="500000">$500,000</option>
                      <option value="1000000">$1,000,000</option>
                      <option value="1000001">$1,000,000+</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
              <Col lg={4}>
                <label>Files Uploaded</label>
                <div
                  className="overflow-auto border d-block align-items-stretch"
                  style={{ maxHeight: "220px", height: 220, overflowY: "auto" }}
                >
                  {insuranceImages.map((result: any) => {
                    const data = result.key.split("Insurance/");

                    return (
                      <ListGroup as="ul" variant="flush">
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{data[1]}</div>
                          </div>
                          <FaTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleRemoveInsuranceImage(result.key)
                            }
                          />
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  })}
                </div>
              </Col>
              <Col lg={4}>
                <label>Files Selected</label>
                <div
                  className="overflow-auto border d-block align-items-stretch"
                  style={{ height: "220px", overflowY: "auto" }}
                >
                  {insuranceFiles.map((result: any, index: number) => {
                    return (
                      <ListGroup as="ul" variant="flush">
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{result.name}</div>
                          </div>
                          <FaTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleInsuranceFileRemoveImage(index)
                            }
                          />
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  })}
                </div>
                <FileUploader
                  handleChange={handleInsuranceFileChange}
                  name="file"
                  children={
                    <button className="btn-continue-outline d-block mx-auto my-3 w-100">
                      Select & Upload Files
                    </button>
                  }
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-end my-5 align-items-center">
              <button onClick={onSaveChanges} className="btn-continue">
                Save Changes
              </button>
            </div>
            <div className="d-flex justify-content-end my-5 align-items-center">
              {success && <Message type="success" message={success}></Message>}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LicenceAndInsurance;
