import React from "react";

import "./navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TaxExtensionDeadline() {
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="tax-extension-bg py-5 px-lg-5">
            <Row className="px-lg-5 justify-content-between">
              <Col lg={8}>
                <h1 className="content-box-heading mb-3">
                  The Tax extension deadline has passed
                </h1>
                <p className="content-text">
                  We’re sorry but the deadline to file a tax extension has
                  passed. Please be advised that you are still required to file
                  your taxes before the deadline or risk accruing additional
                  penalties and interest.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TaxExtensionDeadline;
