import { useEffect, useState } from "react";
import { clientConfig } from "../../awsClientConfig";
import TaxNoReportAvailable from "./TaxReportEnrolled/taxNoreportavailable";
import { LastFreeRiskReports, TaxRiskReportItem } from "./types";
import { userProfileData } from "../../Common/profileInfo";
import EnrollmentReminder from "./GlobalEnrollment/enrollmentReminder";
import { GetProtectionPlanEligibility } from "../../queries/GetProtectionPlanEligibility";
import TaxReliefProtectionPlan from "./ProtectionPlan/TaxReliefProtectionPlan";
import PreparingServicePlan from "./PlanProtectionEnrolled/preparingServicePlan";
import { Auth } from "aws-amplify";
import { IsUserLegacy } from '../../Common/LegacyLogin';
import Loading from "../../Common/Loading";
import OppTaxReports from "./PlanProtectionEnrolled/OppTaxReports";
import { fetchQuery } from "../../functions/misc";
import {
  GetProfileCaf,
} from "../../graphql/one-api/custom-queries/Profile";
import { GetProfile } from "../../queries/getProfile";
import { GetProtectionPlanSubscription } from "../../queries/GetProtectionPlanSubscription";
import { GetTaxRiskReports } from "../../queries/GetTaxRiskReports";
import useEnrollmentStore from "../../store/EnrollmentStore";
import useProfileStore from "../../store/ProfileStore";
import SpouseTaxPrepDashboard from "./GlobalEnrollment/SpouseTaxPrepDashboard";


type TaskRiskReports = {
 TaxRiskReports: TaxRiskReportItem[];
};

function DashBoardMain() {
  const [isEnrollmentEligible, setIsEnrollmentEligible] = useState(false);
  const [isEnrollmentInitiated, setIsEnrollmentInitiated] = useState(false);
  const [enrollmentType, setEnrollmentType] = useState(null);
  const [enrollmentRiskReport, setEnrollmentRiskReport] =
    useState<LastFreeRiskReports>();
  const [taxReports, setTaxReports] = useState<TaxRiskReportItem[]>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLegacy, setIsLegacy] = useState(false);
  const [cafStatus, setCafStatus] = useState("");
  const [cafStatusUpdatedAt, setCafStatusUpdatedAt] = useState("");
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const { taxPrepEnrollment, setTaxPrepEnrollment } = useEnrollmentStore();
  const { spouseProfile, setSpouseProfile } = useProfileStore();

  useEffect(() => {

    const fetchGetTaxReports = async () => {
      const client = new clientConfig();
      await Auth.currentAuthenticatedUser({ bypassCache: true });

      const profileId = await userProfileData();
      setUserId(profileId);

     client
      .query({
        query: GetTaxRiskReports,
        variables: { ProfileId: profileId, },
      })
      .then((res: any) => {
        if (res.data.GetTaxRiskReports !== null) {
          setTaxReports(res.data.GetTaxRiskReports?.TaxRiskReports);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      })

     const legacyResponse = await IsUserLegacy();
     if (legacyResponse) {
      setIsLegacy(true);
     }

     const getProfile = await fetchQuery({ profileId: profileId }, GetProfile);
      if (getProfile) {
        setCafStatus(getProfile.CafStatus);
        if (getProfile.CafStatusUpdatedAt) {
          setCafStatusUpdatedAt(getProfile.CafStatusUpdatedAt)
        }
        if (getProfile.ParentId) {
          setSpouseProfile({
            Id: getProfile.Id,
            AccountId: getProfile.AccountId,
            ParentId: getProfile.ParentId,
            FirstName: getProfile.FirstName,
            LastName: getProfile.LastName,
          })
        }
      }

      client
        .query({
          query: GetProtectionPlanEligibility,
          variables: { ProfileId: profileId },
        })
        .then((res: any) => {
          if (res.data.GetProtectionPlanEligibility !== null) {
            setIsEnrollmentEligible(
              res.data.GetProtectionPlanEligibility?.IsEligible
            );
            setIsEnrollmentInitiated(
              res.data.GetProtectionPlanEligibility?.EnrollmentInitiated
            );
            setEnrollmentRiskReport(
              res.data.GetProtectionPlanEligibility?.LastTaxRiskReport
            );
            setEnrollmentType(
                res.data.GetProtectionPlanEligibility?.EnrollmentType
            );
          }
        })
        .catch((error: any) => {
          console.log('error:', error);
        });

     client
      .query({
        query: GetProtectionPlanSubscription,
        variables: { ProfileId: profileId },
      })
      .then((res: any) => {
        const status = res.data.GetProtectionPlanSubscription.Status;
        setSubscriptionStatus(status);
        if (status === 'Current') {
         setIsSubscribed(true);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      })

     setLoading(false);
    };

    fetchGetTaxReports();
  }, []);

  if (loading) {
   return <Loading />;
  }

  if (spouseProfile?.ParentId) {
  return <SpouseTaxPrepDashboard />
  }

  if (isEnrollmentEligible && !isEnrollmentInitiated && !isSubscribed) {
   return <TaxReliefProtectionPlan subscriptionStatus={subscriptionStatus} taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted} />;
  }

  if (taxReports && isSubscribed) {
    return <OppTaxReports userId={userId} taxReports={taxReports} setTaxReports={setTaxReports} isLegacy={isLegacy} taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted} />;
  }

  if (isEnrollmentEligible && isEnrollmentInitiated) {
    return (
      <EnrollmentReminder
        isEnrollmentEligible={isEnrollmentEligible}
        isEnrollmentInitiated={isEnrollmentInitiated}
        taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted}
      />
    );
  }

  if (
   isSubscribed &&
   (enrollmentRiskReport?.Status === "Pending") &&
   (enrollmentType === "ReferralProtectionPlan" || enrollmentType === "ProtectionPlan")
  ) {
    return <PreparingServicePlan taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted} />;
  }

  if (!taxReports && enrollmentRiskReport?.Status === "Failed" && isSubscribed) {
   return <TaxNoReportAvailable taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted} />;
  }
  if (enrollmentRiskReport?.Status === "Failed") {
    return <TaxNoReportAvailable taxPrepEnrollmentStatus={taxPrepEnrollment?.taxPrepEnrollmentCompleted} />;
  }

 return <Loading />;
}
export default DashBoardMain;
