import { useEffect, useState } from "react";
import "../../pages/navbar.css";
import taxreport from "../../assets/icon-tax-report.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { clientConfig } from "../../awsClientConfig";
import { GetFreeTaxRiskReportEligibility } from "../../queries/GetFreeTaxRiskReportEligibility";
import { LastFreeRiskReports } from "../DashBoards/types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userProfileData } from "../../Common/profileInfo";
function AppEnrollmentSuccessfull() {
  const navigate = useNavigate();
  const [lastTaxReport, setLastTaxReport] = useState<LastFreeRiskReports>();

  useEffect(() => {
    fetchStatus();
  }, []);
  const fetchStatus = async () => {
    const profileId = await userProfileData();
    const client = new clientConfig();

    const taxRiskData:any = await client.query({
      query: GetFreeTaxRiskReportEligibility,
      variables: { profileId: profileId },
    });

    console.log(taxRiskData);
    setLastTaxReport(
      taxRiskData.data.GetFreeTaxRiskReportEligibility.LastTaxRiskReport
    );
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box py-5 px-lg-5">
            <Row className="px-lg-5 justify-content-center align-items-center">
              <Col className="col-lg-7">
                <h1 className="content-box-heading mb-3">
                  Congratulations! You’re Enrolled.
                </h1>
                <p className="content-text">
                  You’re now enrolled in our tax
                  protection plan. Head over to your dashboard to check out your
                  plan and other tax tools like our IRS Notice Analyzer.
                </p>

                <button
                  onClick={() => navigate("/dashboard")}
                  className="btn-xl px-1"
                >
                  Continue to Dashboard
                </button>
              </Col>
              <Col className="col-lg-5">
                <img
                  src={taxreport}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AppEnrollmentSuccessfull;
