import NoticeAnalyzerForm from "../components/noticeAnalyzer/irsNoticeAnalyzer";

function NoticeAnalyzer() {
  return (
    <>
      <NoticeAnalyzerForm />;
    </>
  );
}

export default NoticeAnalyzer;
