import gql from "graphql-tag";

export const QualifyEnrollmentRequest = gql`
  mutation QualifyEnrollmentRequest(
    $enrolmentID: ID!
    $answeredQuestions: [AnsweredQuestion]!
  ) {
    QualifyEnrollmentRequest(
      Input: {
        EnrollmentId: $enrolmentID
        AnsweredQuestions: $answeredQuestions
      }
    ) {
      Result
      ResultExplanation
      RecommendedProtectionPlan {
        Active
        CreatedAt
        Description
        Id
        Link
        MonthlyPrice
        Name
        Title
        UpdatedAt
      }
      UnavailableProtectionPlan {
        Active
        CreatedAt
        Description
        Id
        Link
        MonthlyPrice
        Name
        Title
        UpdatedAt
      }
    }
  }
`;
