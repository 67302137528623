import { Card, Col, Row } from "react-bootstrap";
import '../requestcenter.css';
import { IncomeSourceModel, InitialIncomeSourceModel } from "./TaxOrganizerContext";
import { ReactComponent as EditIcon} from '../../../assets/edit-icon.svg';

const IncomeSourceCard:React.FC<{incomeSource?: IncomeSourceModel, 
        onAction:(actionType: string, incomeSource:IncomeSourceModel) => void}> = (props) => {
    return (
        <Card className="text-left border-0 light-bg w-100">
            <Card.Body className="px-lg-3">
                <div style={{textAlign: 'left'}}>

                <h6 className="ff-Montserrat fw-bold mb-3">
                    {props.incomeSource? `${props.incomeSource.incomeSource}`:'Add New Income Source'}
                </h6>
                {props.incomeSource && 
                    <>
                    <Row>
                        <Col lg={6} md={6} style={{textAlign: 'left'}}>{'EIN #:'}</Col>
                        <Col lg={6} md={6} style={{textAlign: 'right'}}>{'###-###-####'}</Col>
                    </Row>
                    </>
                }
                {!props.incomeSource && 
                    <span className="fw-8">{'Click the button below to add a new income source'}</span>
                }
                </div>
            </Card.Body>
            <Card.Footer className="cardfooter" onClick={() => props.onAction(props.incomeSource?'edit':'new' 
                                ,props.incomeSource?props.incomeSource:InitialIncomeSourceModel)}>
                {props.incomeSource && 
                    <span className="fs-8"><EditIcon /> {'  Edit Information'}</span>
                }
                {!props.incomeSource && 
                    <span className="fs-8">{'+ADD Income Source'}</span>
                }
            </Card.Footer>
        </Card>
    )
}

export default IncomeSourceCard;