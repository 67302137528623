import { useContext, useEffect } from "react";
import TaxOrganizerContext, { PersonalInformationModel } from "./TaxOrganizerContext";
import { Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { FilingStatusTypes } from "../Constants";
import { US_STATES } from "../../../Common/States";
import '../requestcenter.css';

const PersonalInformationForm:React.FC<{inputData:PersonalInformationModel | undefined, 
                                onChangeValue: (event:any) => void}> = (props) => {

    
    return (
        <Form className="mt-4" noValidate id="clientInfoForm">
            <Row>
                <Col md={'auto'}>
                    <Form.Label>Name as it appears on Social Security Card</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                    required
                    value={props.inputData?.firstName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="firstName"
                    type="text"
                    />
                </Col>
                <Col md={2}>
                    <Form.Label>Middle Initial</Form.Label>
                    <Form.Control
                    value={props.inputData?.middleName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="middleName"
                    type="text"
                    />
                </Col>
                <Col md={5}>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                    required
                    value={props.inputData?.lastName}
                    onChange={(event) => props.onChangeValue(event)}
                    name="lastName"
                    type="text"
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5}>
                    <Form.Label>Social Security Number</Form.Label>
                    <Form.Control
                    value={props.inputData?.ssn}
                    onChange={(event) => props.onChangeValue(event)}
                    name="ssn"
                    type="text"
                    />
                </Col>
                <Col md={4}>
                    <Form.Label>Driver’s License Number</Form.Label>
                    <Form.Control
                    value={props.inputData?.dlNumber}
                    onChange={(event) => props.onChangeValue(event)}
                    name="dlNumber"
                    type="text"
                    />
                </Col>
                <Col md={3}>
                    <Form.Label>Driver’s License State</Form.Label>
                    <Form.Select
                    value={props.inputData?.dlState}
                    onChange={(event) => props.onChangeValue(event)}
                    name="dlState"
                    >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4}>    
                    <Form.Label>Driver’s License Issue Date</Form.Label>
                    <Form.Control
                    value={props.inputData?.dlIssueDate}
                    onChange={(event) => props.onChangeValue(event)}
                    name="dlIssueDate"
                    type="date"
                    />
                </Col>
                <Col md={4}>
                    <Form.Label>Driver’s License Expiration Date</Form.Label>
                    <Form.Control
                    value={props.inputData?.dlExpirationDate}
                    onChange={(event) => props.onChangeValue(event)}
                    name="dlExpirationDate"
                    type="date"
                    />
                </Col>
                <Col md={4}>
                    <Form.Label>Birth Date</Form.Label>
                    <Form.Control
                    value={props.inputData?.birthDate}
                    onChange={(event) => props.onChangeValue(event)}
                    name="birthDate"
                    type="date"
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <Form.Label>Health Insurance</Form.Label>

                    <Form.Select
                    value={props.inputData?.healthInsurance}
                    onChange={(event) => props.onChangeValue(event)}
                    name="healthInsurance"
                    >
                        <option value={''}>Select One</option>
                        <option value={'Insurane1'}>Insurane 1</option>
                        <option value={'Insurane2'}>Insurane 2</option>
                        <option value={'Insurane3'}>Insurane 3</option>
                        <option value={'Insurane4'}>Insurane 4</option>
                    </Form.Select>
                </Col>
                <Col md={4}>
                    <Form.Label>Months of Health Coverage This Year</Form.Label>
                    <Form.Select
                    value={props.inputData?.healthCoverageMonths}
                    onChange={(event) => props.onChangeValue(event)}
                    name="healthCoverageMonths"
                    >
                        <option value={''}>Select One</option>
                        <option value={'3'}>3 Months</option>
                        <option value={'6'}>6 Months</option>
                        <option value={'9'}>9 Months</option>
                        <option value={'12'}>12 Months</option>
                    </Form.Select>
                </Col>
                
            </Row>
        </Form>
    )
}

export default PersonalInformationForm;