import React, { useState, useEffect } from "react";
import "../../../pages/navbar.css";
import "../../styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import irsNoticeIcon from "../../../assets/irs-notice-icon.svg";
import { useNavigate } from "react-router-dom";
import iconpp from "../../../assets/iconpp.svg";
import customerDashboardimg from "../../../assets/customer-dashboard-bg.png";
import { userProfileData } from "../../../Common/profileInfo";
import { GetProfile } from "../../../queries/getProfile";
import { clientConfig } from "../../../awsClientConfig";
import EnrollmentWarningModal from "./EnrollmentWarningModal";
import ProtectionPlanCard from "../ClientDashboards/ProtectionPlanCard";
import { Carousel, CarouselItem } from "react-bootstrap";
import TaxPreparationCard from "../ClientDashboards/TaxPreparationCard";
import TaxTools from "../ClientDashboards/TaxTools";
import useEnrollmentStore, { ProtectionPlanName } from "../../../store/EnrollmentStore";
import { motion } from "framer-motion";

function TaxProtectionPlan(props:any) {
 const [enrollmentWarning, setEnrollmentWarning] = useState(false);
 const [hideCarousel, setHideCarousel] = useState(false);
 const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();
 const navigate = useNavigate();
 const client = new clientConfig();

  useEffect(() => {
    if (taxPrepEnrollment?.taxPrepEnrollmentCompleted ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Standard ||
      protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Professional) {
      setHideCarousel(true);
    }
  }, []);

 const onCloseHandler = () => {
  setEnrollmentWarning(false);
 }

  const onCloseTaxPrepHandler = () => {
    setEnrollmentWarning(false);
  }

 const getProtected = async () => {
    
    if(props.subscriptionStatus === 'PendingCancellation'){
      setEnrollmentWarning(true);
      return;
    }
    const profileId = await userProfileData();
    client
        .query({
          query: GetProfile,
          variables: { profileId: profileId },
        })
        .then((res: any) => {
          if (res.data.GetProfile !== null) {
            if (res.data.GetProfile.EnrollmentSource === "Referral") {
              navigate("/res-protection-plan");
            } else {
              navigate("/protection-plan-enroll");
            }
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });

  };

  const motionContainer = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: "easeInOut" } },
  };

 return (
  <motion.div variants={motionContainer} initial="initial" animate="animate">
   <Container className="mt-3 mb-5">
    <Row className="justify-content-center">
      <Col md={10} className={`content-box px-lg-5 ${hideCarousel ? 'pb-lg-3' : ''}`}>
       {process.env.REACT_APP_TAX_PREPNETWORK_ALLOWED === 'true' && !hideCarousel ?
      <Carousel slide={true} data-bs-theme="dark" controls={false} interval={null} className="pb-5">
        <Carousel.Item>
          <ProtectionPlanCard onProtected={getProtected} />
        </Carousel.Item>
         <Carousel.Item>
           <TaxPreparationCard />
         </Carousel.Item>
      </Carousel>
        :
        <ProtectionPlanCard onProtected={getProtected} />
       }
     </Col>
     <Col md={10} className="px-0 my-3">
      <TaxTools showProtectionPlanCard={true} subscriptionStatus={props.subscriptionStatus} onClick={getProtected}/>
     </Col>
    </Row>
   </Container>
   <EnrollmentWarningModal showEnrollmentWarning={enrollmentWarning} onClose={onCloseHandler}/>
  </motion.div>
 );
}

export default TaxProtectionPlan;
