import { useContext, useState } from "react";
import "./signature.css";
import SignatureCanvas from "react-signature-canvas";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "../partnerEnrollment/PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { userProfileData } from "../../Common/profileInfo";
import { CompletePartnerForm } from "../../queries/CompletePartnerFrom";
import { InitiatePartnerEnrollmentInput } from "../../queries/InitiatePartnerEnrollmentInput";
import Message from "../Message";
import { Buffer } from 'buffer';

function Signature({ progressPercent = 0 }) {
  const {
    next,
    nextFormId,
    enrollmentId,
    setS3Bucket,
    setS3Key,
    setNextFormId,
    setEnrollmentId,
  } = useContext(PartnerEnrollmentFormContext);
  const client = new clientConfig();
  const [loading, setLoading] = useState(false);
  const [signEmpty, setSignEmpty] = useState(false);
  const [signaturePending, setSignaturePending] = useState(true);

  var signCanvas: SignatureCanvas | null = null;

  const submitSign = async () => {
    if (signCanvas?.isEmpty()) {
      setSignEmpty(true);
      return;
    }
    setLoading(true);
    let signature: any = signCanvas?.getTrimmedCanvas().toDataURL("image/png");

    const profileId = await userProfileData();

    SetS3Config(
      `signatures-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const buf = Buffer.from(
      signature.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    await Storage.put(`Partners/${profileId}.png`, buf, {
      contentType: "image/png", // contentType is optional
      customPrefix: { public: "" },
      tagging: "ExternalClearance=Partner",
    });

    const variables = {
      PartnerId: profileId,
      FormId: nextFormId,
      PartnerEnrollmentId: enrollmentId,
    };

    client
      .mutate({ mutation: CompletePartnerForm, variables: variables })
      .then((res: any) => {
        if (res.data.CompletePartnerForm) {
          const variable = {
            partnerId: profileId,
          };
          client
            .mutate({
              mutation: InitiatePartnerEnrollmentInput,
              variables: variable,
            })
            .then((res1: any) => {
              if (res1.data.InitiatePartnerEnrollment) {
                setS3Bucket(
                  res1.data.InitiatePartnerEnrollment.NextForm?.S3Bucket
                );
                setS3Key(res1.data.InitiatePartnerEnrollment.NextForm?.S3Key);
                setNextFormId(res1.data.InitiatePartnerEnrollment.NextForm?.Id);
                setEnrollmentId(res1.data.InitiatePartnerEnrollment.Id);
                next();
                setLoading(false);
              }
            });
        }
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading mb-3">
                Create Your Signature
              </h1>
              <SignatureCanvas
                clearOnResize={false}
                penColor="black"
                canvasProps={{ className: "sigCanvas" }}
                ref={(ref) => {
                  signCanvas = ref;
                }}
                onBegin={() => {
                 setSignEmpty(false);
                 setSignaturePending(false);
                }}
              />
              <label className="signature-text d-block text-center">
                Please draw your signature in the box above
              </label>
              {signEmpty && (
                  <Message
                    type="error"
                    message="Signature is mandatory"
                  ></Message>
                )}

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <button
                  onClick={() => {
                    signCanvas?.clear();
                    setSignaturePending(true);
                  }}
                  className="btn-continue-outline"
                >
                  Clear Signature
                </button>
                
                  <button
                   className={signaturePending ?
                    "btn-disable" :
                    "btn-continue"}
                   disabled={loading} onClick={submitSign}>
                    {loading && (
                      <span
                        style={{ marginRight: 10 }}
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Submit Signature
                  </button>
                
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Signature;
