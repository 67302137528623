import React, {useState} from 'react';
import { Form } from 'react-bootstrap';

const autoMaskSSN = (value: string | undefined) => {
    if (!value) {
      return '';
    }
    const len = value?.length;
  
    const stars =
      len > 0
        ? len > 1
          ? len > 2
            ? len > 3
              ? len > 4
                ? '***-**-'
                : '***-*'
              : '***-'
            : '**'
          : '*'
        : '';
    return stars + value.substring(5);
};

type TProps = {
  onChangeText: (ssn: string | undefined)  => void;
  required?: boolean
  initialValue: string | undefined;
  name: string;
};
export const SsnInput = (props: TProps) => {
  const {onChangeText, initialValue, name, ...remProps} = props;

  const [ssn, setSSn] = useState({
    actualSsn: initialValue,
    maskedSsn: autoMaskSSN(initialValue),
    ssnLocked: false,
  });

  const handleChange = (event: string) => {
    const prevValue = ssn.maskedSsn;
    let actual = ssn.actualSsn;
    if (event.length > 11) {
      return;
    }

    // typing forward
    if (event.length > prevValue.length) {
      const newChar = event.split('').pop();

      actual = `${actual}${newChar}`;
    }
    // backspacing / deleting
    else {
      const charsRemovedCount = prevValue.length - event.length;

      actual = actual?.substr(0, actual?.length - charsRemovedCount);
    }
    setSSn(ps => ({...ps, actualSsn: actual, maskedSsn: starredMask(actual)}));
    onChangeText(actual);
  };

  
  const starredMask = (inputSsn: string | undefined, showLast4 = true) => {
    // replace numeric digits with * asterisk
    const valueHidden = inputSsn?inputSsn.replace(/[\d]/g, '*'): '';

    if (valueHidden.length <= 3) {
      return valueHidden;
    }

    if (valueHidden.length <= 5) {
      return valueHidden.slice(0, 3) + '-' + valueHidden.slice(3, 5);
    }

    if (showLast4) {
      return (
        valueHidden.slice(0, 3) +
        '-' +
        valueHidden.slice(3, 5) +
        '-' +
        inputSsn?.substr(5)
      );
    } else {
      return (
        valueHidden.slice(0, 3) +
        '-' +
        valueHidden.slice(3, 5) +
        '-' +
        valueHidden.slice(5, 9)
      );
    }
  };

  const validateSsn = (event: any) => {
    const regex = new RegExp("^[0-9]");
    const key = event.key;
    if (!regex.test(key) && !(key === 'Backspace')) {
        event.preventDefault();
        return false;
    }
 }

  return (
    <Form.Control
        value={ssn.maskedSsn}
        onChange={(event) => handleChange(event.target.value)}
        name={props.name}
        type="text"
        onKeyDown={validateSsn}
        {...remProps}
        />
  );
};

export default SsnInput;
