import gql from "graphql-tag";

export const CancelSubscription = gql`
  mutation CancelSubscription($ProfileId: ID! $CancellationReason: SubscriptionCancellationReason!) {
   CancelSubscription(Input: {CancellationReason: $CancellationReason, ProfileId: $ProfileId }) {
    Id
    CancellationReason
    Status
    ProfileId
    CreatedAt
    UpdatedAt
    }
  }
`;
