import gql from "graphql-tag";

export const CreateAppointment = gql`
  mutation CreateAppointment(
    $ProfileId: String!
    $Name: String!
    $Email: AWSEmail!
    $Phone: AWSPhone!
    $ZipCode: String
    $StartAt: AWSDateTime!
    $Notes: String
    $AppointmentTopic: AppointmentTopicType!
  ) {
    CreateAppointment(
      Input: {
        Profile: {
          ZipCode: $ZipCode
          ProfileId: $ProfileId
          Phone: $Phone
          Name: $Name
          Email: $Email
        }
        StartAt: $StartAt
        Notes: $Notes
        AppointmentTopic: $AppointmentTopic
      }
    ) {
      AgentId
      AppointmentId
      EndsAt
      ProfileId
      StartAtDisplayDate
      StartAtDisplayTime
      StartsAt
      TimeZone
      TimeZoneShort
      Topic
    }
  }
`;
