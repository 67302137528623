import { createContext } from "react";
interface ContextState {
  next: () => void;
  previous: (index: number) => void;
  enrollmentId: string;
  setEnrollmentId: (id: string) => void;
  nextFormId: string;
  setNextFormId: (id: string) => void;
  s3key: string;
  setS3Key: (id: string) => void;
  s3Bucket: string;
  setS3Bucket: (id: string) => void;
  finalPlan: any;
  SetFinalPlan: (plan: any) => void;
  recommandedPlan: any;
  setRecommandedPlan: (plan: any) => void;
  unavailableProtectionPlan: any;
  setUnavailableProtectionPlan: (plan: any) => void;
  billingAddress: any;
  setBillingAddress: (address: any) => void;
  promoCode: string | undefined;
  setPromoCode: (promoCode: string | undefined) => void;
  promotionCodeType: string | undefined;
  setPromotionCodeType: (promotionCodeType: string | undefined) => void;
}

const OrganicClientFormContext = createContext({} as ContextState);

export default OrganicClientFormContext;

export const { Provider, Consumer } = OrganicClientFormContext;
