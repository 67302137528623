import gql from "graphql-tag";

export const GetPartner = gql`
  query GetPartner($PartnerId: ID!) {
    GetPartner(PartnerId: $PartnerId) {
      Active
      ApplicationStatus
      FirstName
      LastName
      MiddleName
      Email
      Phone
      CallEnabled
      SmsEnabled
      Title
      Type
      CallEnabled
      Id
      ConsentToShare
      SmsEnabled
      ReferralCode
      OrganizationId
      TaxProfessional {
        Bookkeeping
        DisciplinaryAction
        DisciplinaryActionDesc
        EoCoverage
        EoInsurance
        FilingSoftware
        IrsAudit
        IrsAuditDesc
        Languages
        Licenses
        PrepCompetency
        ReturnForms
        Ptin
        ReturnSchedules
        TaxResolution
        TaxReturnsPrepared
        YearsOfExperience
        Id
      }
    }
  }
`;
