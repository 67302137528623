import gql from "graphql-tag";

export const CompletePartnerForm = gql`
  mutation CompletePartnerForm(
    $PartnerEnrollmentId: ID
    $FormId: ID!
    $PartnerId: ID!
  ) {
    CompletePartnerForm(
      Input: { FormId: $FormId, PartnerId: $PartnerId }
      PartnerEnrollmentId: $PartnerEnrollmentId
    ) {
      Active
      Id
      Name
      S3Bucket
      S3Key
      S3VersionId
    }
  }
`;