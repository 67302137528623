import gql from "graphql-tag";

export const GetLeadsByPartner = gql`
  query GetLeadsByPartner(
    $PartnerId: ID!
    $Limit: Int
    $NextToken: String
    $Search: String
  ) {
    GetLeadsByPartner(
      PartnerId: $PartnerId
      Limit: $Limit
      NextToken: $NextToken
      Search: $Search
    ) {
      LeadSummaries {
        DangerFlag
        Email
        FirstName
        LeadId
        LeadStatus
        LastName
        MiddleName
        PartnerId
        PaymentHold
        Phone
        ProfileId
        ReferredAt
        TaxRiskReportRiskLevel
        EnrolledAt
      }
      NextToken
      TotalTokens
    }
  }
`;
