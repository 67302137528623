import { Col, Row, Tab, Tabs } from "react-bootstrap";
import '../requestcenter.css';
import { useContext } from "react";
import IncomeExpensesContext from "./IncomeExpensesContext";
import { FilingStatusTypes } from "../Constants";
import AdditionalQuestionsForm from "./AdditionalQuestionsForm";
import { formatCurrency } from "../../../functions/misc";

const ReviewIncomeExpensesTab:React.FC<{inputData: any, type: string}> = (props) => {
    
    const {setPageNumber, setDefaultTab} = useContext(IncomeExpensesContext);

    const onEditHandler = (pageNo: number) => {
        setDefaultTab(props.type);
        setPageNumber(pageNo);
    }
    
    return (
        <>
        <div className="mt-2">
            <Row>
                <Col md={8}>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">INCOME</h6>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <button className="btn-edit" onClick={() => onEditHandler(1)}>EDIT</button>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Monthly gross W-2'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.w2}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Monthly Business Income'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.k1}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Monthly Pension Income'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.pensions}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Monthly Social Security Income'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.socialSecurity}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Monthly Other Income'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.otherIncome}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Details:'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.income.otherIncometext}</p>
                </Col>
            </Row>
            <hr />
        </div>

        <div className="mt-2">
            <Row>
                <Col md={8}>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">EXPENSES</h6>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <button className="btn-edit" onClick={() => onEditHandler(2)}>EDIT</button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={8}>
                    <p className="fs-8">{'Are you paying any home loans?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.expenses.mortgage}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.expenses.rent)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Balance'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency('')}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Enter any utilities exprenses you may have for primary residence'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Utilities'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.expenses.utilities)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Services'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.expenses.services)}</p>
                </Col>
            </Row>
            <Row className="mt-1 mb-0">
                <Col md={8}>
                    <p className="fs-8">{'Do you own one or more Vehicles?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.expenses.moreVehicles}</p>
                </Col>
            </Row>
            <Row className="mt-0">
                <Col md={8}>
                    <p className="fs-8">{'You have not inputted any vehicle information'}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'What is the total estimated value of your assets?'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Cash'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.expenses.assetsMonthlyPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Other'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.expenses.assetsOtherPayment)}</p>
                </Col>
            </Row>
            <hr />
        </div>

        <div className="mt-2">
            <Row>
                <Col md={8}>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">OTHER EXPENSES</h6>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <button className="btn-edit" onClick={() => onEditHandler(3)}>EDIT</button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={8}>
                    <p className="fs-8">{'Do you have any student loans?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{'N/A'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.studentLoanMonthlyPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Balance'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.studentLoanBalance)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Enter any State/Local Tax Installment Agreement?'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.taxAgreementMonthlyPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Balance'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.taxAgreementBalance)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Health Insurance Premium'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.healthInsurancePremium)}</p>
                </Col>
            </Row>
            <Row className="mt-1 mb-0">
                <Col md={8}>
                    <p className="fs-8">{'Out of Pocket Health Expense'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.healthExpense)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'How much are you spending on child and/or dependent care?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.dependentCare)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Details: '}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.otherExpenses.dependentCareText}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'How many life insurance policies do you have?'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.lifeInsurancePayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Types(s)'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{
                        props.inputData.otherExpenses.lifeInsuranceTypes
                            ?props.inputData.otherExpenses.lifeInsuranceTypes.join(', ')
                            : ''}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Are you paying any monthly court ordered payment(s)'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.courtOrderedPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Balance'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.courtOrderedPaymentBalance)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'What is your monthly credit card debt?'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.creditCardPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Balance'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.creditCardBalance)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Available'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.creditCardAvailableBalance)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'Do you have any other expenses?'}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Monthly'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.otherExpenses.otherExpensesPayment)}</p>
                </Col>
            </Row>
            <Row className="ms-2">
                <Col md={8}>
                    <p className="fs-8">{'Details'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.otherExpenses.otherExpensesText}</p>
                </Col>
            </Row>
            <hr />
        </div>

        <div className="mt-2">
            <Row>
                <Col md={8}>
                    <h6 className="text-main-dark ff-Montserrat fw-bold">ADDITIONAL QUESTIONS</h6>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <button className="btn-edit" onClick={() => onEditHandler(4)}>EDIT</button>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'If things are tight, how much can you afford per month on an installment agreement?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{formatCurrency(props.inputData.additional.installmentAgreementAmount)}</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col md={8}>
                    <p className="fs-8">{'When is your preferred resolution payment day?'}</p>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    <p className="fs-8 fw-bold">{props.inputData.additional.paymentDay}</p>
                </Col>
            </Row>
            <hr />
        </div>
        </>
    )
}

export default ReviewIncomeExpensesTab;