import React, { useContext } from "react";

import "../../pages/navbar.css";
import ApplicationReview from "./applicationReview";
import ApplicationApproved from "./applicationApproved";
import ApplicationNotApproved from "./applicationNotApproved";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";

function ApplicationStatus({ progressPercent = 0}) {
  const {  applicationStatus, s3Bucket } = useContext(PartnerEnrollmentFormContext);

  if(applicationStatus === 'Rejected') {
    return <ApplicationNotApproved />
  }

  if(applicationStatus === 'Pending' || !s3Bucket) {
    return <ApplicationReview progressPercent={progressPercent} />
  }

  if(applicationStatus === 'Approved') {
    return <ApplicationApproved progressPercent={progressPercent} />
  }

  

  return null
}

export default ApplicationStatus;
