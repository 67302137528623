import gql from "graphql-tag";

export const CreateTaxReturn = gql`
  mutation CreateTaxReturn(
    $ClientProfileId: ID!,
    $FilingStatus: TaxPrepFilingTypeStatus!
    $Type: TaxReturnFilingType!
    $EnrollmentId: String!
    $Year: String!
    $ProfileId: String!
    $Status: TaxReturnStatus!
  ) {
    CreateTaxReturn(ProfileId: $ClientProfileId,
      Input: { FilingType: {Status: $FilingStatus, Type: $Type}, 
               TaxPrepQuote: {EnrollmentId: $EnrollmentId, Type: $Type, Year: $Year}, 
               TaxReturn:{ProfileId: $ProfileId, Status: $Status, Year: $Year} 
             }
    ) {
      TaxPrepFilingType {
        CreatedAt
        Id
        Status
        TaxReturnId
        Type
        UpdatedAt
      }
      TaxPrepQuote {
        Active
        CreatedAt
        EnrollmentId
        FinalPrice
        Id
        QuotePrice
        Type
        UpdatedAt
        Year
      }
      TaxReturn {
        Active
        CancelledAt
        CompletedAt
        CreatedAt
        EntityId
        EntityType
        Id
        InitiatedAt
        Price
        ProfileId
        QuoteId
        Status
        TaxProfessionalId
        TaxProfessionalProvider
        UpdatedAt
        Year
      }
    }
  }
`;