import { useLocation } from "react-router-dom";
import IncomeExpenseForm from "../components/RequestCenter/IncomeExpenseForm";

function IncomeExpensePage() {
    const location = useLocation();
    return (
      <>
        <IncomeExpenseForm request={location.state} />
      </>
    );
  }
  
  export default IncomeExpensePage;