import { Card, Col, Row } from "react-bootstrap";
import './styles.css';
import { BusinessQuestionnaireData, initialBusinessQuestionnaireData } from "./TaxPreparationServiceFormContext";

const BusinessQuestionnaireCard:React.FC<{businessEntity?: any, 
        onAction:(businessEntity:BusinessQuestionnaireData) => void}> = (props) => {
    return (
        <Card className="text-left border-0 light-bg w-100" style={{height: '150px'}}>
            <Card.Body className="px-lg-3">
                <div style={{textAlign: 'left'}}>

                <h6 className="ff-Montserrat fw-bold mb-3">
                    {props.businessEntity?.Name?`${props.businessEntity.Name}`:'Add New Business'}
                </h6>
                {props.businessEntity && 
                    <p className="fw-8">{'Started '+(new Date(props.businessEntity.FirstYear).getFullYear())}</p>
                }
                {!props.businessEntity && 
                    <span className="fw-8">{'Click the button below to add a new business entity'}</span>
                }
                </div>
            </Card.Body>
            <Card.Footer className={props.businessEntity?"cardfooter-grey":"cardfooter"} onClick={() => props.onAction(props.businessEntity?props.businessEntity:initialBusinessQuestionnaireData)}>
                {props.businessEntity && 
                    <span className="fs-8">{'Edit Entity'}</span>
                }
                {!props.businessEntity && 
                    <span className="fs-8">{'+ADD Business Entity'}</span>
                }
            </Card.Footer>
        </Card>
    )
}

export default BusinessQuestionnaireCard;