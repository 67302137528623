import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext, { BusinessQuestionnaireData, StateReturnYears, initialBusinessQuestionnaireData } from './TaxPreparationServiceFormContext';
import BusinessQuestionnaireCard from './BusinessQuestionnaireCard';
import BusinessQuestionnaireModal from './BusinessQuestionnaireModal';
import { clientConfig } from '../../awsClientConfig';
import { Auth } from 'aws-amplify';
import { userProfileData } from '../../Common/profileInfo';
import { createEnrollmentAction } from '../../queries/CreateEnrollmentAction';
import OverlayHelpTooltip from '../../Common/OverlayHelpTooltip';
import { GetTaxReturnBusinessEntities } from '../../queries/GetTaxReturnBusinessEntities';
import { CreateTaxReturnBusinessEntity } from '../../queries/CreateTaxReturnBusinessEntity';
import { getCurrentTaxYear } from '../../functions/misc';
import { UpdateTaxReturnBusinessEntity } from '../../queries/UpdateTaxReturnBusinessEntity';

const BusinessQuestionnaireForm = () => {
    const {taxPreparationData, setTaxPreparationData, navigateToPage, enrollmentId,nextEnrollmentAction, completedPagesHandler, taxReturnId } = useContext(TaxPreparationServiceFormContext);
    const [show, setShow] = useState(false);
    const [selectedBusinessEntity, setSelectedBusinessEntity] = useState<BusinessQuestionnaireData>(initialBusinessQuestionnaireData);
    const client = new clientConfig();
    const [businessEntitiesData, setBusinessEntitiesData] = useState<any>();

    useEffect(() => {
      getTaxReturnBusinessEntitiesData();
    },[]);

    const getTaxReturnBusinessEntitiesData = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturnBusinessEntities, variables: variables });
        if(res.data.GetTaxReturnBusinessEntities){
          setBusinessEntitiesData(res.data.GetTaxReturnBusinessEntities);
        }
      }catch(e){}
    }
    
    const continueHandler = async () => {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const variables = {
        EnrollmentId: enrollmentId,
        Name: 'TaxInformationCompleted'
      };
      client
        .mutate({ mutation: createEnrollmentAction, variables: variables })
        .then((res: any) => {
          if (res.data.CreateEnrollmentAction.Enrollment.Id) {
            nextEnrollmentAction(
              res.data.CreateEnrollmentAction.Enrollment?.NextEnrollmentAction
            );
          }
        })
        .catch((error: any) => {
        });
        completedPagesHandler('serviceQuote');
    }

    const onBackHandler = () => {
      navigateToPage('incomeForm1');
    }

    const businessEntityModalOpenHandler = (businessEntity:any) => {
      console.log(businessEntity);
      if(businessEntity.Id){
        const businessReturnEntity = businessEntitiesData?.TaxReturnBusinessEntity?.find((returnEntity:any) => (returnEntity.BusinessEntityId === businessEntity.Id));
        const incomeTaxForms = businessEntitiesData?.TaxPrepIncomeTaxForms?.find((incomeForm:any) => (incomeForm.TaxReturnBusinessEntityId === businessReturnEntity?.Id));
        const payrollTaxForms = businessEntitiesData?.TaxPrepPayrollTaxForms?.filter((payrollForm:any) => (payrollForm.TaxReturnBusinessEntityId === businessReturnEntity?.Id));
        
        const form941Data = payrollTaxForms?.find((payrollForm:any) => (payrollForm.Form === 'Form941'));
        const form940Data = payrollTaxForms?.find((payrollForm:any) => (payrollForm.Form === 'Form940'));
        const form943Data = payrollTaxForms?.find((payrollForm:any) => (payrollForm.Form === 'Form943'));
        const form944Data = payrollTaxForms?.find((payrollForm:any) => (payrollForm.Form === 'Form944'));

        let quarters941Data:StateReturnYears[] = [];
        if(form941Data){
          const quarterStateReturns = businessEntitiesData?.QuarterStateReturns?.filter((stateReturn:any) => (stateReturn.PayrollTaxFormId === form941Data?.Id));
          if(quarterStateReturns && quarterStateReturns.length > 0){
            quarters941Data = quarterStateReturns.map((stateReturn:any) => ({id:stateReturn.Id,year: stateReturn.Year,quarter: stateReturn.QuarterCount}));
          }
        } 
        const businessEntityObj:BusinessQuestionnaireData = {
          businessEntityId: businessEntity.Id,
          businessReturnEntityId: businessReturnEntity?.Id,
          entityName: businessEntity.Name,
          firstYearOfBusiness: businessEntity.FirstYear,
          grossRevenue: businessReturnEntity?.GrossIncome,
          taxForm941Id: form941Data?.Id,
          taxForm941: form941Data?true:false,
          stateReturn941: form941Data?.StateReturn?'Yes':'No',
          returnYears941: quarters941Data,
          taxForm940Id: form940Data?.Id,
          taxForm940: form940Data?true:false,
          stateReturn940: form940Data?.StateReturn?'Yes':'No',
          taxForm943Id: form943Data?.Id,
          taxForm943: form943Data?true:false,
          stateReturn943: form943Data?.StateReturn?'Yes':'No',
          taxForm944Id: form944Data?.Id,
          taxForm944: form944Data?true:false,
          stateReturn944: form944Data?.StateReturn?'Yes':'No',
          taxFormId: incomeTaxForms?.Id,
          taxForm: incomeTaxForms?.Form,
          partnersCount: incomeTaxForms?.PartnersCount,
          taxFormStateReturn: incomeTaxForms?.StateReturn?'Yes':'No'
        }
        setSelectedBusinessEntity(businessEntityObj);
      }else {
        setSelectedBusinessEntity(businessEntity);
      }
      setShow(true);
    }

    const businessEntityModalCloseHandler = () => {
      setSelectedBusinessEntity(initialBusinessQuestionnaireData);
      setShow(false);
    }

    const onSaveHandler = async (businessQuestionaireData: BusinessQuestionnaireData) => {
      console.log(businessQuestionaireData);
      const profileId = await userProfileData();
      const payRollTaxForms = [];
      if(businessQuestionaireData.businessEntityId){
        if(businessQuestionaireData.taxForm940){
          payRollTaxForms.push({Id:businessQuestionaireData.taxForm940Id?businessQuestionaireData.taxForm940Id:"", 
                      Form: 'Form940', StateReturn: businessQuestionaireData.stateReturn940 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm941){
          payRollTaxForms.push({Id:businessQuestionaireData.taxForm941Id?businessQuestionaireData.taxForm941Id:"",
                      Form: 'Form941', StateReturn: businessQuestionaireData.stateReturn941 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm943){
          payRollTaxForms.push({Id:businessQuestionaireData.taxForm943Id?businessQuestionaireData.taxForm943Id:"",
                    Form: 'Form943', StateReturn: businessQuestionaireData.stateReturn943 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm944){
          payRollTaxForms.push({Id:businessQuestionaireData.taxForm944Id?businessQuestionaireData.taxForm944Id:"",
                        Form: 'Form944', StateReturn: businessQuestionaireData.stateReturn944 === 'Yes'?true:false});
        }

        let quarterStateReturnsData:any[] = [];
        if(businessQuestionaireData.returnYears941 && businessQuestionaireData.returnYears941.length > 0) {
          quarterStateReturnsData = businessQuestionaireData.returnYears941.map((returnYear:any) => 
            ({Id:returnYear.id, Year: returnYear.year, QuarterCount: returnYear.quarter}));
        }

        const variables = {
          ClientProfileId: profileId,
          TaxPrepBusinessEntityId: businessQuestionaireData.businessEntityId,
          ProfileId: profileId,
          Name: businessQuestionaireData.entityName,
          FirstYear: businessQuestionaireData.firstYearOfBusiness,
          //GrossIncome: businessQuestionaireData.grossRevenue,
          TaxPrepIncomeTaxFormId: businessQuestionaireData.taxFormId,
          Form: businessQuestionaireData.taxForm,
          PartnersCount: businessQuestionaireData.partnersCount,
          StateReturn: businessQuestionaireData.taxFormStateReturn === 'Yes'?true:false,
          TaxPrepPayrollTaxForms: payRollTaxForms,
          TaxReturnBusinessEntityId: businessQuestionaireData.businessReturnEntityId,
          GrossIncome: businessQuestionaireData.grossRevenue,
          Year: getCurrentTaxYear(),
          QuarterStateReturns: quarterStateReturnsData
        }
        try{ 
          const res: any = await client.mutate({ mutation: UpdateTaxReturnBusinessEntity, variables: variables });
          if(res.data.UpdateTaxReturnBusinessEntity){
            await getTaxReturnBusinessEntitiesData();
          }
        }catch(e){}

      }else {
        if(businessQuestionaireData.taxForm940){
          payRollTaxForms.push({Form: 'Form940', StateReturn: businessQuestionaireData.stateReturn940 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm941){
          payRollTaxForms.push({Form: 'Form941', StateReturn: businessQuestionaireData.stateReturn941 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm943){
          payRollTaxForms.push({Form: 'Form943', StateReturn: businessQuestionaireData.stateReturn943 === 'Yes'?true:false});
        }
        if(businessQuestionaireData.taxForm944){
          payRollTaxForms.push({Form: 'Form944', StateReturn: businessQuestionaireData.stateReturn944 === 'Yes'?true:false});
        }

        let quarterStateReturnsData:any[] = [];
        if(businessQuestionaireData.returnYears941 && businessQuestionaireData.returnYears941.length > 0) {
          quarterStateReturnsData = businessQuestionaireData.returnYears941.map((returnYear:any) => 
            ({Year: returnYear.year, QuarterCount: returnYear.quarter}));
        }

        const variables = {
          ClientProfileId: profileId,
          TaxReturnId: taxReturnId,
          Year: getCurrentTaxYear(),
          GrossIncome: businessQuestionaireData.grossRevenue,
          ProfileId: profileId,
          Name: businessQuestionaireData.entityName,
          FirstYear: businessQuestionaireData.firstYearOfBusiness,
          TaxPrepPayrollTaxForms: payRollTaxForms,
          Form: businessQuestionaireData.taxForm,
          PartnersCount: businessQuestionaireData.partnersCount,
          StateReturn: businessQuestionaireData.taxFormStateReturn === 'Yes'?true:false,
          QuarterStateReturns: quarterStateReturnsData
        }
        try{ 
          const res: any = await client.mutate({ mutation: CreateTaxReturnBusinessEntity, variables: variables });
          if(res.data.CreateTaxReturnBusinessEntity){
            await getTaxReturnBusinessEntitiesData();
          }
        }catch(e){}
      }
    }

    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
             <AppProgressBar progressPercent={15} />
              <Row className="justify-content-center">
                <Col md={12}>
                  <div className='d-flex justify-content-between mb-2'>
                    <h1 className="content-box-heading">
                    {'Business Questionnaire'} 
                    </h1>
                    <div style={{marginTop: "2.5rem"}}>
                      <OverlayHelpTooltip text={'A partnership is the relationship between two or more people to do trade or business. Each person contributes money, property, labor or skill, and shares in the profits and losses of the business. A partnership typically will file Form 1065 for their federal income tax return.  For federal income tax purposes, a C corporation is recognized as a separate taxpaying entity. A corporation conducts business, realizes net income or loss, pays taxes and distributes profits to shareholders. A C corporation will typcially file Form 1120 for their federal income tax return. S corporations are corporations that elect to pass corporate income, losses, deductions, and credits through to their shareholders for federal tax purposes. Shareholders of S corporations report the flow-through of income and losses on their personal tax returns and are assessed tax at their individual income tax rates. A S corporation will file a form 1120-S for their federal income tax return.'}/>
                    </div>
                  </div>
                  <p className="content-text" style={{fontSize: '12px'}}>
                  Please add each business entity that you need tax returns prepared for. Common business entities include partnerships, C corporations, LLC, LLP, and S corporations. Please also add your sole proprietorship or single member LLC that needs payroll returns completed (forms 940, 941, or 944).
                  </p>
                  <Row className="justify-content-start my-4">
                  <div className="mt-6">
                      <Row>
                          {(businessEntitiesData && businessEntitiesData.TaxPrepBusinessEntity && businessEntitiesData.TaxPrepBusinessEntity.length !== 0) && 
                              <>
                              {businessEntitiesData.TaxPrepBusinessEntity.map((businessEntity: any) => {
                                  return (
                                  <Col md={4} lg={3} className="mb-2">
                                      <BusinessQuestionnaireCard key={businessEntity.businessEntityId} businessEntity={businessEntity} 
                                            onAction={businessEntityModalOpenHandler}/>
                                  </Col>
                                  )
                              })}
                              </>
                          }
                          <Col md={4} xl={3} lg={3}>
                              <BusinessQuestionnaireCard onAction={businessEntityModalOpenHandler} />
                          </Col>
                      </Row>
                  </div>
                    <div className="d-flex justify-content-between mt-5 pt-5 align-items-center">
                      <span onClick={onBackHandler}>
                        {" "}
                        <span className="letsdolater">Back</span>
                      </span>
                      <button
                        className={"btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={continueHandler}>
                       Continue
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {show && 
      <BusinessQuestionnaireModal show={show} 
          onClose={businessEntityModalCloseHandler} 
          businessEntity={selectedBusinessEntity}
          onSave={onSaveHandler}/>
      }
      </>
    )
}

export default BusinessQuestionnaireForm;