import { Col, Row, Tab, Tabs } from "react-bootstrap";
import '../requestcenter.css';
import { useContext, useEffect } from "react";
import IncomeExpensesContext from "./IncomeExpensesContext";
import { FilingStatusTypes } from "../Constants";
import AdditionalQuestionsForm from "./AdditionalQuestionsForm";
import ReviewIncomeExpensesTab from "./ReviewIncomeExpensesTab";

const ReviewIncomeExpenses = () => {
    
    const {incomeExpensesData, setIncomeExpensesData, setPageNumber} = useContext(IncomeExpensesContext);

    const clientData = {income: incomeExpensesData.clientMonthlyIncomes , expenses: incomeExpensesData.clientMonthlyExpenses,
                        otherExpenses: incomeExpensesData.clientMonthlyOtherExpenses, additional: incomeExpensesData.clientAdditionalQuestions};
    
    const spouseData = {income: incomeExpensesData.spouseMonthlyIncomes , expenses: incomeExpensesData.spouseMonthlyExpenses,
        otherExpenses: incomeExpensesData.spouseMonthlyOtherExpenses, additional: incomeExpensesData.spouseAdditionalQuestions};
    return (
        <div style={{marginBottom: '3rem'}}>
            <Row>
            <Col xl={12} md={12}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              Review & Complete
            </h4>
            </Col>
            </Row>
            <p className="content-text" style={{marginBottom: '1rem'}}>
            Please verify and confirm the information below. After you’re done, press the Complete button to provide to Optima.
            </p>
            <div className="mt-2">
                <Row>
                    <Col md={8}>
                        <h6 className="text-main-dark ff-Montserrat fw-bold">PERSONAL INFORMATION</h6>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <button className="btn-edit" onClick={() => setPageNumber(0)}>EDIT</button>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md={8}>
                        <p className="fs-8">What is/was your Filing Status for the year 2021</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{incomeExpensesData.maritalStatus}</p>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md={8}>
                        <p className="fs-8">How many people are living in the same house?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{incomeExpensesData.peoplelivingInHouse}</p>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md={8}>
                        <p className="fs-8">How many dependents do you have?</p>
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <p className="fs-8 fw-bold">{incomeExpensesData.dependentsCount}</p>
                    </Col>
                </Row>
                <hr />
                <Tabs defaultActiveKey="client">
                <Tab eventKey="client" 
                    title={'Client Name'}>
                    <ReviewIncomeExpensesTab inputData={clientData} type={'client'}/>
                </Tab>
                {(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly 
                    || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) && 
                <Tab eventKey="spouse" title={'Client Name #2'}>
                    <ReviewIncomeExpensesTab inputData={spouseData} type={'spouse'}/>
                </Tab>
                }
            </Tabs>
            </div>
        </div>
    )
}

export default ReviewIncomeExpenses;