import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faComments, faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { IsUserLegacy, RedirectToLegacy } from "../Common/LegacyLogin";
import { BsFolderSymlinkFill } from "@react-icons/all-files/bs/BsFolderSymlinkFill";
import React, { useEffect, useState } from "react";
import logo from "../assets/OTR_optima_blue_solid.svg";
import { Auth } from "aws-amplify";
import { ReactComponent as RequestIcon } from '../assets/requests.svg';
import { ReactComponent as InfoIcon } from '../assets/info-circle-fill.svg';
import { ReactComponent as TaxPortalIcon } from '../assets/box-arrow-up-right.svg';
import { GetProtectionPlanSubscription } from "../queries/GetProtectionPlanSubscription";
import { clientConfig } from "../awsClientConfig";
import { userProfileData } from "../Common/profileInfo";
import { GetEnrollmentsByProfile } from "../queries/GetEnrollmentsByProfile";
import useEnrollmentStore, { ProtectionPlanName } from "../store/EnrollmentStore";
import useProfileStore from "../store/ProfileStore";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [isLegacy, setIsLegacy] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();
  const { primaryProfile } = useProfileStore();

  useEffect( () => {
  const fetchLegacyUser = async () => {
   const legacyResponse = await IsUserLegacy();
    if (legacyResponse) {
     setIsLegacy(true);
    }
  }

   const fetchSubscription = async () => {
     const client = new clientConfig();
     const profileId = await userProfileData();

    client
     .query({
       query: GetProtectionPlanSubscription,
       variables: { ProfileId: profileId },
     })
       .then((res: any) => {
          if (res.data.GetProtectionPlanSubscription?.Status === 'Current') {
            setIsSubscribed(true);
          }
       })
     .catch((error: any) => {
       console.log('error', error);
     });

   }

   fetchLegacyUser();
   fetchSubscription();
 },[]);

  const homeLinks = [
    "/dashboard",
    "/notice-analyzer",
    "/tax-extension",
    "/tax-extension-deadline-passed",
    "/tax-risk-report"
  ];
  const homeActive = homeLinks.includes(location.pathname);
  const appointmentLinks = ["/appointments", "/schedule-appointment"];
  const appointmentActive = appointmentLinks.includes(location.pathname);

  const requestCenterLinks = ["/requests", "/tax-organizer", "/income-expense-form"];
  const requestCenterActive = requestCenterLinks.includes(location.pathname);

  const onHome = async () => {
    navigate("/dashboard");
  };

  const onAccountRedirect = async () => {
    navigate("/account-settings");
  };

  const hamburgerMenu = () => {
    if (hamburgerMenuOpen)
      setHamburgerMenuOpen(false);
    else
      setHamburgerMenuOpen(true);
  };

  const onClientPortal = () => {
   Auth.currentAuthenticatedUser()
    .then((res) => {
      if (res.attributes["custom:LegacyProfileId"]) {
       window.open(process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL,
        '_blank');
      }
    })
    .catch((error) => {
     console.log("error:", error);
    });
  }

  return (
    <div className={hamburgerMenuOpen ? "sidebar sidebar-mobile" : "sidebar"}>
      <ul className={hamburgerMenuOpen ? "bg-white" : "sidebar-items-hide"}>
        <li className={homeActive ? "active me-2" : ""} onClick={onHome}>
          <FontAwesomeIcon className="me-2 fs-8" icon={faHome} />
          Home
        </li>
        <li
          className={(!appointmentActive && !homeActive && !requestCenterActive) ? "active me-2" : ""}
          onClick={onAccountRedirect}
        >
          <FontAwesomeIcon className="me-2 fs-8" icon={faUser} />
         My Account
        </li>
        <li
          className={appointmentActive ? "active me-2" : ""}
          onClick={() => navigate("/appointments")}
        >
          <FontAwesomeIcon className="me-2 fs-8" icon={faComments} />
         Appointments
        </li>
        {process.env.REACT_APP_REQUEST_CENTER_ALLOWED === "true" &&
          (taxPrepEnrollment?.taxPrepEnrollmentCompleted && protectionPlanEnrollment?.subscriptionPlan !== ProtectionPlanName.Standard && protectionPlanEnrollment?.subscriptionPlan !== ProtectionPlanName.Professional) &&
        <li
          className={requestCenterActive ? "active me-2" : ""}
          onClick={() => navigate("/requests")}
        >
          <InfoIcon className="me-2 fs-8"/>
          Information Requests
        </li>
        }
       {isLegacy &&
         <li onClick={onClientPortal}>
           <TaxPortalIcon className="me-2 fs-6" />
           Tax Relief Portal
         </li>
       }
      </ul>
      <div id="hamburger-menu-div">
        <a id="hamburger-icon" className="pt-4 ps-4" onClick={hamburgerMenu}>
          <FontAwesomeIcon icon={faBars} className="fa-2xl" />
        </a>
        <img
            src={logo}
            alt="app-logo"
            className="sidebar-logo"
        />
      </div>
    </div>
  );
}

export default SideBar;