/**
 * Converts list of booleans into a number via binary representation
 * @param  {...any} booleans
 * @returns {number}
 */
import { API } from "aws-amplify";
import { Buffer } from 'buffer';

export const binarify = (...booleans: any[]) => {
  return booleans.reduce((res, x) => (res << 1) | x);
};

// export const formatDate = (date: any) => {
//   let options = {
//     weekday: "long",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   return new Date(date).toLocaleString("en-US", options);
// };

// export const formatDateShort = (date: any) => {
//   let options = {
//     weekday: "short",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   return new Date(date).toLocaleString("en-US", options);
// };

// export const formatStripeDate = (stripeDate: any, short = false) => {
//   if(short === true) {
//     return formatDateShort(stripeDate * 1000);
//   }else{
//     return formatDate(stripeDate * 1000);
//   }
// };

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

export const getStyleObjectFromString = (str: string) => {
  let style: any;
  str.split(";").forEach((el) => {
    const [property, value] = el.split(":");
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

export const getHash = function (str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  var hash = 4294967296 * (2097151 & h2) + (h1 >>> 0);
  return "" + hash;
};

export function formatSSN(str: string) {
  const ssn = str.replace(/[^\d*]/g, "");

  if (ssn.length < 4) {
    return ssn;
  }

  if (ssn.length < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }

  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
}

export function hideFormatSSN(str: string) {
  if (!str) return "";
  const ssnValue = str.replace(/[^\d*]/g, "");

  const ssn = ssnValue.replace(
    ssnValue.substring(0, 5),
    "*".repeat(ssnValue.substring(0, 5).length)
  );

  if (ssn.length < 4) {
    return ssn;
  }

  if (ssn.length < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }

  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
}

export function formatZipcode(e: any) {
  let zipcode = e.target.value;
  zipcode = zipcode.replace(/[^\d]/g, "");
  if (zipcode.length > 5) {
    return `${zipcode.slice(0, 5)}-${zipcode.slice(5, 9)}`
  } else {
    return `${zipcode.slice(0,5)}`;
  }
}

export function formatZipcodeStr(zipcode: any) {
  if(zipcode){
    zipcode = zipcode.replace(/[^\d]/g, "");
    if (zipcode.length > 5) {
      return `${zipcode.slice(0, 5)}-${zipcode.slice(5, 9)}`
    } else {
      return `${zipcode.slice(0,5)}`;
    }
  }else {
    return ""
  }
}

export function formatPhone(str: any) {
  const phoneNumber = str.replace(/[^\d]/g, "");

  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
}

export function formatCurrency(currencyValue:any){
  const nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2
  });
  if(currencyValue)
    return nf.format(currencyValue);
  else
    return nf.format(0.00);
}

export function formatPlanDescription(description: any, planName: any): any{
  return  description.replace(planName + " Plan", (planName + " Plan").bold());
}

export function currentUTCTimestamp() {
 const currentTimestamp = new Date();
 return `${currentTimestamp.getFullYear()}-${
  String(currentTimestamp.getMonth() + 1).padStart(2, '0')
 }-${
  String(currentTimestamp.getDate()).padStart(2, '0')
 } ${
  String(currentTimestamp.getHours()).padStart(2, '0')
 }:${
  String(currentTimestamp.getMinutes()).padStart(2, '0')
 }:${
  String(currentTimestamp.getSeconds()).padStart(2, '0')
 }`;
}

export function compareCurrentDate(mydate:any) {
  const q = new Date();
  const m = q.getMonth();
  const d = q.getDate();
  const y = q.getFullYear();
  var currentDate = new Date(y,m,d);

  const compareDate =new Date(mydate);
  if(compareDate > currentDate) {
    return true;
  }
  return false;
}

export const getCurrentTaxYear = () => {
  const currentDate = new Date();
  // Convert to Pacific Standard Time (PST)
  const options = { timeZone: 'America/Los_Angeles' };
  const currentYearPST = new Date(currentDate.toLocaleString('en-US', options));
  // Assuming the tax year starts on October 1st
  const taxYearStart = new Date(currentYearPST.getFullYear(), 9, 1); // October is month 9 (0-indexed)
  // Check if the current date is before or after the tax year start
  const taxYear = currentDate < taxYearStart ? currentYearPST.getFullYear() - 1 : currentYearPST.getFullYear();
  return taxYear;
};

export function formatPrice(price: number): string {
  return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const fetchQuery = async <T>(variables: any, query: any) => {
  const queryName = query.definitions[0].name.value;
  try {
    const res: any = await API.graphql<T>({
      query,
      variables,
    });
    return res.data[queryName];
  } catch (err: any) {
    const { errorType, message } = err.errors[0];
    console.log(`Error while trying to fetch ${queryName}\nError Type: ${errorType}\nError Message: ${message}`);
  }
};

export function encodeToBase64(text: string) {
  return Buffer.from(text).toString('base64');
}

export function decodeFromBase64(encodedText: string) {
  return Buffer.from(encodedText, 'base64').toString('utf-8');
}
