import gql from "graphql-tag";
export const UpdatePaymentMethod = gql`
  mutation UpdatePaymentMethod(
    $Id: ID!
    $AchEntityType: AchEntityType
    $AchNumber: String
    $AchRoutingNumber: String
    $AchType: AchType
    $Active: Boolean!
    $CcExpMonth: String
    $CcExpYear: String
    $CcLastFourDigits: String
    $CcType: CcType
    $City: String!
    $Name: String!
    $State: UsState!
    $StreetAddress1: String!
    $StreetAddress2: String
    $Type: PaymentMethodType!
    $ZipCode: String!
    $Default: Boolean!
  ) {
    UpdatePaymentMethod(
      Input: {
        AchEntityType: $AchEntityType
        AchNumber: $AchNumber
        AchRoutingNumber: $AchRoutingNumber
        AchType: $AchType
        Active: $Active
        CcExpMonth: $CcExpMonth
        CcExpYear: $CcExpYear
        CcLastFourDigits: $CcLastFourDigits
        CcType: $CcType
        City: $City
        Default: $Default
        Id: $Id
        Name: $Name
        State: $State
        StreetAddress1: $StreetAddress1
        StreetAddress2: $StreetAddress2
        Type: $Type
        ZipCode: $ZipCode
      }
    ) {
      AchEntityType
      AchNumber
      AchRoutingNumber
      AchType
      Active
      CcExpMonth
      CcExpYear
      CcLastFourDigits
      CcType
      City
      CreatedAt
      Default
      Id
      Name
      State
      StreetAddress1
      StreetAddress2
      Token
      Type
      UpdatedAt
      Vendor
      ZipCode
    }
  }
`;
