import gql from "graphql-tag";

export const UpdateOrganization = gql`
  mutation UpdateOrganization(
    $Id: ID!,
    $Ein: String!
    $Name: String!
    $Phone: AWSPhone!
    $Type: OrganizationType!
    $Storefront: Boolean!
  ) {
    UpdateOrganization(
      Input: {
        Id: $Id
        Ein: $Ein
        Name: $Name
        Phone: $Phone
        Type: $Type
        Storefront: $Storefront
      }
    ) {
      CreatedAt
      Ein
      Id
      Name
      Phone
      PhoneExt
      Type
      UpdatedAt
      OrganizationAddresses {
        City
        CreatedAt
        Id
        OrganizationId
        State
        StreetAddress1
        StreetAddress2
        UpdatedAt
        OrganizationAddressTypes {
          Type
        }
        ZipCode
      }
    }
  }
`;
