import React, { useState } from "react";
import { Provider } from "./NewFormContext";
import PersonalInformation from "./personalInformation";
import Signature from "./signature";
import TaxInformatioAuthorization from "./taxInformationAuthorization";

const renderStep = (step: number) => {
  switch (step) {
    case 0:
      return <PersonalInformation />;
    case 1:
      return <Signature />;
    case 2:
      return <TaxInformatioAuthorization />;
    default:
      return null;
  }
};

const NewReportForm = () => {
  const [nextFormId, setNextFormId] = useState("");
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 2) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Provider
      value={{
        next,
        prev,
        nextFormId,
        setNextFormId,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
      }}
    >
      <main>{renderStep(currentStep)}</main>
    </Provider>
  );
};
export default NewReportForm;
