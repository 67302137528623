import { useRef, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FilingStatusTypes, Request } from "../Constants";
import { IncomeExpenseDataModel, InitialIncomeExpensesData, MonthlyIncomeModel, Provider } from "./IncomeExpensesContext";
import PersonalInformation from "./PersonalInformation";
import MonthlyIncome from "./MonthlyIncome";
import MonthlyExpenses from "./MonthlyExpenses";
import OtherExpenses from "./OtherExpenses";
import AdditionalQuestions from "./AdditionalQuestions";
import ReviewIncomeExpenses from "./ReviewIncomeExpenses";
import CompletedPage from "./CompletedPage";


function IncomeExpenseForm(props:any) {
    const navigate = useNavigate();
    const [selectedRequest, setSelectedRequest] = useState<Request>(props.request);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [incomeExpensesData, setIncomeExpensesData] = useState<IncomeExpenseDataModel>(InitialIncomeExpensesData);
    const [showIncomeFormError, setShowIncomeFormError] = useState(false);
    const [showExpensesFormError, setShowExpensesFormError] = useState(false);
    const [showOtherExpensesFormError, setShowOtherExpensesFormError] = useState(false);
    const [showAdditionQuestionsFormError, setShowAdditionalQuestionsFormError] = useState(false);
    const [defaultTab, setDefaultTab] = useState('client');
    

    const nextHandler = () => {
      const isCurrentFormValidated = validateCurrentFormFields();
      if(isCurrentFormValidated) {
        setDefaultTab('client');
        setPageNumber(prevState => prevState+1);
      }
    }

    const previousHandler = () => {
      setDefaultTab('client');
      setPageNumber(prevState => prevState-1);
    }

    const validateCurrentFormFields = () => {
      let validated = true;
      switch(pageNumber){
        case 1: 
          validated = validateIncomeForm();
          break;
        case 2: 
          validated = validateExpensesForm();
          break;
        case 3: 
          validated = validateOtherExpensesForm();
          break;
        case 4: 
          validated = validateAdditionalQuestionsForm();
          break;
      }

      return validated;
    }

    const validateIncomeForm = () => {
      const clientIncomeData = incomeExpensesData.clientMonthlyIncomes;
      const spouseIncomeData = incomeExpensesData.spouseMonthlyIncomes;
      if(clientIncomeData.w2 === '' || clientIncomeData.k1 === '' || clientIncomeData.pensions === ''
            || clientIncomeData.socialSecurity === '' || clientIncomeData.otherIncome === '' || clientIncomeData.otherIncometext === ''){
        setShowIncomeFormError(true);
        return false;
      } 
      if(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) {
        if(spouseIncomeData.w2 === '' || spouseIncomeData.k1 === '' || spouseIncomeData.pensions === ''
            || spouseIncomeData.socialSecurity === '' || spouseIncomeData.otherIncome === '' || spouseIncomeData.otherIncometext === ''){
          setShowIncomeFormError(true);
          return false;
        }
      } 
      setShowIncomeFormError(false);
      return true;
    }

    const validateMortgageCondition = (type: string) => {
      if(type === 'client') {
        const clientExpensesData = incomeExpensesData.clientMonthlyExpenses;
        if(clientExpensesData.mortgage === 'Yes'){
          return false;
        }else if(clientExpensesData.mortgage === 'No' && clientExpensesData.rent === '') {
          return true;
        }
      }
      if(type === 'spouse') {
        const spouseExpensesData = incomeExpensesData.spouseMonthlyExpenses;
        if(spouseExpensesData.mortgage === 'Yes'){
          return false;
        }else if(spouseExpensesData.mortgage === 'No' && spouseExpensesData.rent === '') {
          return true;
        }
      }

      return false;
      
    }

    const validateExpensesForm = () => {
      const clientExpensesData = incomeExpensesData.clientMonthlyExpenses;
      const spouseExpensesData = incomeExpensesData.spouseMonthlyExpenses;
      if(validateMortgageCondition('client') || clientExpensesData.utilities === '' || clientExpensesData.services === ''
            || clientExpensesData.assetsMonthlyPayment === '' || clientExpensesData.assetsOtherPayment === ''){
        setShowExpensesFormError(true);
        return false;
      } 
      if(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) {
        if(validateMortgageCondition('spouse')  || spouseExpensesData.utilities === '' || spouseExpensesData.services === ''
            || spouseExpensesData.assetsMonthlyPayment === '' || spouseExpensesData.assetsOtherPayment === ''){
              setShowExpensesFormError(true);
          return false;
        }
      } 
      setShowExpensesFormError(false);
      return true;
    }


    const validateOtherExpensesForm = () => {
      const clientOtherExpensesData = incomeExpensesData.clientMonthlyOtherExpenses;
      const spouseOtherExpensesData = incomeExpensesData.spouseMonthlyOtherExpenses;
      if(clientOtherExpensesData.studentLoanMonthlyPayment === '' || clientOtherExpensesData.studentLoanBalance === '' || clientOtherExpensesData.taxAgreementMonthlyPayment === ''
            || clientOtherExpensesData.taxAgreementBalance === '' || clientOtherExpensesData.healthInsurancePremium === '' || clientOtherExpensesData.healthExpense === ''
            || clientOtherExpensesData.dependentCare === '' || clientOtherExpensesData.dependentCareText === '' || clientOtherExpensesData.lifeInsurancePayment === ''
            || clientOtherExpensesData.courtOrderedPayment === '' || clientOtherExpensesData.courtOrderedPaymentBalance === '' || clientOtherExpensesData.creditCardPayment === ''
            || clientOtherExpensesData.creditCardBalance === '' || clientOtherExpensesData.creditCardAvailableBalance === '' || clientOtherExpensesData.otherExpensesPayment === ''
            || clientOtherExpensesData.otherExpensesText === ''){
        setShowOtherExpensesFormError(true);
        return false;
      } 
      if(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) {
        if(spouseOtherExpensesData.studentLoanMonthlyPayment === '' || spouseOtherExpensesData.studentLoanBalance === '' || spouseOtherExpensesData.taxAgreementMonthlyPayment === ''
            || spouseOtherExpensesData.taxAgreementBalance === '' || spouseOtherExpensesData.healthInsurancePremium === '' || spouseOtherExpensesData.healthExpense === ''
            || spouseOtherExpensesData.dependentCare === '' || spouseOtherExpensesData.dependentCareText === '' || spouseOtherExpensesData.lifeInsurancePayment === ''
            || spouseOtherExpensesData.courtOrderedPayment === '' || spouseOtherExpensesData.courtOrderedPaymentBalance === '' || spouseOtherExpensesData.creditCardPayment === ''
            || spouseOtherExpensesData.creditCardBalance === '' || spouseOtherExpensesData.creditCardAvailableBalance === '' || spouseOtherExpensesData.otherExpensesPayment === ''
            || spouseOtherExpensesData.otherExpensesText === ''){
          setShowOtherExpensesFormError(true);
          return false;
        }
      } 
      setShowOtherExpensesFormError(false);
      return true;
    }

    const validateAdditionalQuestionsForm = () => {
      const clientAdditionQuestionsData = incomeExpensesData.clientAdditionalQuestions;
      const spouseAdditionQuestionsData = incomeExpensesData.spouseAdditionalQuestions;
      if(clientAdditionQuestionsData.installmentAgreementAmount === '' || clientAdditionQuestionsData.paymentDay === ''){
        setShowAdditionalQuestionsFormError(true);
        return false;
      } 
      if(incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly || incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately) {
        if(spouseAdditionQuestionsData.installmentAgreementAmount === '' || spouseAdditionQuestionsData.paymentDay === ''){
          setShowAdditionalQuestionsFormError(true);
          return false;
        } 
      } 
      setShowAdditionalQuestionsFormError(false);
      return true;
    }
    
    
    return (
        <>
      <Container className="mb-5">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/requests");
                }}
              >
                Request Center
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => setPageNumber(0)}>{'Income & Expense'}</Breadcrumb.Item>
              {pageNumber === 0 &&
                <Breadcrumb.Item active>Personal Information</Breadcrumb.Item>
              }
              {pageNumber === 1 &&
                <Breadcrumb.Item active>Monthly Income</Breadcrumb.Item>
              }
              {pageNumber === 2 &&
                <Breadcrumb.Item active>Income Source</Breadcrumb.Item>
              }
              {pageNumber === 3 &&
                <Breadcrumb.Item active>Other Information</Breadcrumb.Item>
              }
              {pageNumber === 4 &&
                <Breadcrumb.Item active>Additional Questions</Breadcrumb.Item>
              }
              {pageNumber === 5 &&
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
              }
              {pageNumber === 6 &&
                <Breadcrumb.Item active>View & Review</Breadcrumb.Item>
              }
            </Breadcrumb>
  
            <Provider value= {{
                pageNumber,
                selectedRequest,
                incomeExpensesData,
                setIncomeExpensesData,
                setPageNumber,
                defaultTab,
                setDefaultTab
            }}>
            <main>
            {pageNumber === 0 &&
                <PersonalInformation />
            }
            {pageNumber === 1 &&
                <MonthlyIncome  showIncomeFormError={showIncomeFormError}/>
            }
            {pageNumber === 2 &&
                <MonthlyExpenses  showExpensesFormError={showExpensesFormError}/>
            }
            {pageNumber === 3 && 
                <OtherExpenses showOtherExpensesFormError={showOtherExpensesFormError} />
            }
            {pageNumber === 4 && 
                <AdditionalQuestions showAdditionQuestionsFormError={showAdditionQuestionsFormError} />
            }
            {pageNumber === 5 && 
                <ReviewIncomeExpenses />
            }
            {pageNumber === 6 && 
                <CompletedPage />
            }
            </main>
            </Provider>
            {pageNumber !== 6 && 
            <div className="mt-4" style={{textAlign: 'center'}}>
              <>
                {(pageNumber !== 0 && pageNumber !== 5) &&
                <button className="btn-continue-outline me-2" 
                    disabled={pageNumber === 0? true: false}
                    onClick={previousHandler}>{'< Previous'}</button>
                }
                <button className="btn-continue" onClick={nextHandler}>{pageNumber === 5?'Complete':(pageNumber === 4?'Preview':'Next >')}</button>
              </>
            </div>
            }
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default IncomeExpenseForm;
