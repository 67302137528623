import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Message from "../components/Message";
import { clientConfig } from "../awsClientConfig";
import awsConfig from "../aws-exports";
import { createProfile } from "../queries/createProfile";
import { GetLeadByEmail } from "../queries/GetLeadByEmail";
import { GetProfile } from "../queries/getProfile";
import { currentUTCTimestamp, fetchQuery, formatPhone } from "../functions/misc";
import Loading from "../Common/Loading";
import { IsUserLegacy } from "../Common/LegacyLogin";
import { UpdateProfile } from "../queries/updateProfile";
import { Spinner } from "react-bootstrap";
import { GetProfileByEmail } from "../graphql/one-api/custom-queries/Profile";
import useProfileStore from "../store/ProfileStore";

const errorField = {
  firstName: "",
  lastName: "",
  phone: "",
};

const maxRetries = 50;

function ProfileClient() {
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    phone: "",
  });

  const [partnerId, setPartnerId] = useState();
  const [messageEnable, setMessageEnable] = useState(false);
  const [shareInfo, setShareInfo] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(errorField);
  const [loading, setLoading] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [languages, setLanguages] = useState<string[]>([]);
  const [isSpouse, setIsSpouse] = useState(false);
  const { primaryProfile, setSpouseProfile } = useProfileStore();
  const navigate = useNavigate();
  const client = new clientConfig();

  useEffect(() => {

    const getLeadEmail = async () => {
      const email = (await Auth.currentAuthenticatedUser()).username;
      await client
        .query({
          query: GetLeadByEmail,
          variables: { email },
        })
        .then((res: any) => {
          setPartnerId(res.data?.GetLeadByEmail?.PartnerId);
          setFormValue({
            firstName: res.data?.GetLeadByEmail?.FirstName,
            middleName: "",
            lastName: res.data?.GetLeadByEmail?.LastName,
            phone: formatPhoneNumberString(res.data?.GetLeadByEmail?.Phone),
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    };

   const getLegacyProfileData = async (retries = 0) => {
    if (retries >= maxRetries) {
     console.log("Max retries reached, no Id found.");
     setLoading(false);
     return;
    }
    const isLegacy = await IsUserLegacy();

    if (isLegacy) {
      setLoading(true);
      const res = await Auth.currentAuthenticatedUser({bypassCache: true});
      if (res.attributes['custom:Id']) {
        client
         .query({
          query: GetProfile,
          variables: { profileId: res.attributes['custom:Id'] },
         })
         .then((res: any) => {
          if (res.data.GetProfile !== null) {
           setPersonInfo(res.data.GetProfile);
           setLanguages(res.data.GetProfile?.Languages);
           setFormValue({
            firstName: res.data.GetProfile.FirstName,
            middleName: res.data.GetProfile.MiddleName,
            lastName: res.data.GetProfile.LastName,
            phone: formatPhone(res.data.GetProfile.Phone),
           });
          }
         })
         .catch((error: any) => {
           console.log(error, "error");
         });
        setLoading(false);
      } else {
       setTimeout(() => {
        getLegacyProfileData(retries + 1);
       }, 500);
      }
    }
   };

   const getSpouseProfile = async () => {
     if (primaryProfile?.Email) {
       const variables = {
         email: primaryProfile?.Email
       };
       const getProfileByEmail = await fetchQuery(variables, GetProfileByEmail);
       if (getProfileByEmail != null) {
         setFormValue({
           firstName: getProfileByEmail.FirstName,
           middleName: getProfileByEmail.MiddleName,
           lastName: getProfileByEmail.LastName,
           phone: formatPhoneNumberString(getProfileByEmail.Phone),
         });
         if (getProfileByEmail.ParentId) {
           setSpouseProfile({
             Id: getProfileByEmail.Id,
             AccountId: getProfileByEmail.AccountId,
             ParentId: getProfileByEmail.ParentId,
             FirstName: getProfileByEmail.FirstName,
             LastName: getProfileByEmail.LastName,
           })
           setIsSpouse(true);
         }
       }
     }
   }

    getLeadEmail();
    getLegacyProfileData();
    getSpouseProfile();
  }, []);

  const onChange = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    let _error: any = error;
    _error[e.target.name] = "";
  };

  function formatPhoneNumber(e: any) {
    const str = e.target.value;
    const phoneNumber = str.replace(/[^\d]/g, "");

    let _error: any = error;
    _error.phone = "";

    setError({ ...error });

    if (phoneNumber.length < 4) {
      setFormValue({ ...formValue, [e.target.name]: phoneNumber });
      return;
    }

    if (phoneNumber.length < 7) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
      });
      return;
    }
    if (phoneNumber.length < 11) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`,
      });
      return;
    }
  }

  function formatPhoneNumberString(str: string) {
    var finalnumber = "";
    const phoneNumber = str.replace(/[^\d]/g, "");

    if (phoneNumber.length < 4) {
      finalnumber = phoneNumber;
    }

    if (phoneNumber.length < 7) {
      finalnumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    if (phoneNumber.length < 11) {
      finalnumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
    return finalnumber;
  }

  const validateFormData = () => {
    let _error = error;
    const { firstName, lastName, phone } = formValue;
    if (!firstName) {
      _error["firstName"] = "First Name can not be empty";
    }
    if (!lastName) {
      _error["lastName"] = "Last Name Name can not be empty";
    }
    if (!phone) {
      _error["phone"] = "Phone can not be empty";
    }
    setError(_error);
    setError({ ...error });
    if (_error.firstName || _error.lastName || _error.phone) {
      return false;
    }

    return true;
  };

  const handleOnSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const user = await Auth.currentAuthenticatedUser();

    if (user.attributes['custom:LegacyProfileId']) {
      if (validateFormData()) {
        setShowSpinner(true);
        const variables = {
          ...personInfo,
          FirstName: formValue.firstName,
          MiddleName: formValue.middleName? formValue.middleName : null,
          LastName: formValue.lastName,
          Phone: formValue.phone.replace(/[^0-9]/g, ""),
          Languages: languages,
        };

       client
        .mutate({ mutation: UpdateProfile, variables: variables })
        .then((res: any) => {
          if (res.data.UpdateProfile) {
            Auth.updateUserAttributes(user, {
             'custom:FirstSignInDateTime': currentUTCTimestamp()
            })
             .then((res: any) => {
              navigate("/dashboard");
             })
             .catch((error: any)=> {
              console.log('error:', error);
             })
          }
        })
        .catch((error: any) => {
          console.log('error:', error);
        });
      }
      setShowSpinner(false);
      return;
    }

    const signUpParams = JSON.parse(user.attributes['custom:SignUpParameters'])
    if (validateFormData()) {
      const { firstName, lastName, middleName, phone } = formValue;
      const variables = {
        email: (await Auth.currentAuthenticatedUser()).username,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName ? middleName : null,
        phone: phone.replace(/[^0-9]/g, ""),
        primaryCognitoUserId: (await Auth.currentAuthenticatedUser()).attributes
          .sub,
        primaryUserPoolId: awsConfig.Auth.userPoolId,
        partnerId: partnerId,
        CallEnabled: messageEnable,
        SmsEnabled: messageEnable,
        ConsentToShare: shareInfo,
        Ad: signUpParams.Ad,
        AdGroup: signUpParams.AdGroup,
        Network: signUpParams.Network,
        ReferralCode: signUpParams.ReferralCode,
        UtmCampaign: signUpParams.UtmCampaign,
        UtmSource: signUpParams.UtmSource,
      };
      setShowSpinner(true);
      client
        .mutate({ mutation: createProfile, variables: variables })
        .then(async (res: any) => {
          if (res.data.CreateProfile !== null) {
            setShowSpinner(false);
            await Auth.currentAuthenticatedUser({ bypassCache: true });

            client
              .query({
                query: GetProfile,
                variables: { profileId: res.data.CreateProfile.Id },
              })
              .then((res: any) => {
                if (res.data.GetProfile !== null) {
                  navigate("/dashboard");
                }
              });
          }
        })
        .catch((error: any) => {
          console.log("error:", error);
          if (error.message?.includes("phone")) {
            setError((prev) => ({
              ...prev,
              phone: "Must be a valid phone number",
            }));
          } else if (error.errors?.some((e: any) => e.message.includes("phone"))) {
            setError((prev) => ({
              ...prev,
              phone: "Must be a valid phone number",
            }));
          }

          setShowSpinner(false);
          // console.log(error);
          // errorCopy.phone = 'Phone number is not valid';
          // setErrors(errorCopy);
        });
    }
  };

  return (
    <>
     {loading ? <Loading />
      :
      <Container className="mt-1 mb-5">
       <Row className="row justify-content-center">
        <Col md={10} className="content-box pt-3 pb-5 px-4">
         <div className="mx-lg-5">
          <h1 className="content-box-heading">Tell Us About Yourself:</h1>
          <p className="content-text">As listed on your tax return.</p>
          <Form onSubmit={handleOnSubmit}>
           <Row className="mb-3 justify-content-between">
            <Col md={6}>
             <Form.Group controlId="">
              <Form.Label>
               First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
               onChange={onChange}
               name="firstName"
               type="input"
               placeholder=""
               disabled={isSpouse}
               value={formValue.firstName}
              />
              {error.firstName && (
               <Message
                type="error"
                message={error.firstName}
               ></Message>
              )}
             </Form.Group>
            </Col>
            <Col md={6}>
             <Form.Group controlId="">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
               onChange={onChange}
               name="middleName"
               type="input"
               placeholder=""
               disabled={isSpouse}
               value={formValue.middleName}
              />
             </Form.Group>
            </Col>
           </Row>
           <Row className="mb-3 justify-content-between">
            <Col md={6}>
             <Form.Group controlId="">
              <Form.Label>
               Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
               onChange={onChange}
               name="lastName"
               type="input"
               placeholder=""
               disabled={isSpouse}
               value={formValue.lastName}
              />
              {error.lastName && (
               <Message
                type="error"
                message={error.lastName}
               ></Message>
              )}
             </Form.Group>
            </Col>
            <Col md={6}>
             <Form.Group controlId="">
              <Form.Label>
               Phone <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
               onChange={formatPhoneNumber}
               name="phone"
               type="text"
               placeholder=""
               disabled={isSpouse}
               value={formValue.phone}
              />
              {error.phone && (
               <Message type="error" message={error.phone}></Message>
              )}
             </Form.Group>
            </Col>
           </Row>
           <Row className="mb-3">
            <Col md={12}>
             <Form.Group className="mb-3 justify-item-center d-block">
              <Form.Check
               className="text-secondary"
               checked={messageEnable}
               onChange={() => setMessageEnable(!messageEnable)}
               type="checkbox"
               label="By checking this box, I expressly consent to receive marketing messages—including texts and calls made using an automatic telephone dialing system or pre-recorded or artificial voice messages—from Optima and its affiliates and tax services partners, directly or by third-parties acting on their behalf, and agree to the Automated Calls/Texts Terms."
              />
             </Form.Group>
            </Col>
            <Col md={12}>
             <Form.Group className="mb-3 justify-item-center d-block checkbox-xl">
              <Form.Check
               className="text-secondary"
               checked={shareInfo}
               onChange={() => setShareInfo(!shareInfo)}
               type="checkbox"
               label="By checking this box, I give Optima consent to share my personal information with its affiliates and tax services partners for the purposes of marketing to me."
              />
             </Form.Group>
            </Col>
           </Row>
           <div className="d-block text-center mx-auto">
            <button
             disabled={showSpinner}
             type="submit"
             className="btn-continue"
            >
             Save
            </button>
           </div>
          </Form>
         </div>
        </Col>
       </Row>
      </Container>
     }
    </>
  );
}

export default ProfileClient;
