import { ReactComponent as CheckCircle } from '../../../assets/check2-circle.svg';


const CompletedPage = () => {

    return (
        <div style={{marginBottom: '5rem'}}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
              Income & Expense
            </h4>
            <p className="content-text" style={{marginBottom: '3rem'}}>
               <CheckCircle /> You have completed this requirement.
            </p>
        </div>
    )
}

export default CompletedPage;