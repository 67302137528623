import gql from "graphql-tag";

export const CreateTaxReturnIncome = gql`
  mutation CreateTaxReturnIncome(
    $ClientProfileId: ID!
    $QuoteId: String!
    $ProfileId: String!
    $HasSelfEmploymentIncome: String!
    $AnnualGrossReceipts: Float
    $HasCapitalGainsOrLosses: String!
    $SoldRealEstate: String!
    $HasRentalIncomeOrLosses: String!
    $HasPartnershipIncomeOrLosses: String!
    $OperatedFarm: String!
    $NeedStateReturn: String!
    $ReceivedRoyalties: String!
    $RentalsCount: Int!
    $TaxPrepStateReturn: [TaxPrepStateReturnInput!]!
  ) {
    CreateTaxReturnIncome(ProfileId: $ClientProfileId,
      Input: {TaxPrepIncome: {AnnualGrossReceipts: $AnnualGrossReceipts, HasCapitalGainsOrLosses: $HasCapitalGainsOrLosses, 
                              HasPartnershipIncomeOrLosses: $HasPartnershipIncomeOrLosses, 
                              HasRentalIncomeOrLosses: $HasRentalIncomeOrLosses, HasSelfEmploymentIncome: $HasSelfEmploymentIncome, 
                              NeedStateReturn: $NeedStateReturn, OperatedFarm: $OperatedFarm, 
                              ProfileId: $ProfileId, QuoteId: $QuoteId, ReceivedRoyalties: $ReceivedRoyalties, 
                              SoldRealEstate: $SoldRealEstate, RentalsCount: $RentalsCount
                              }, 
                              TaxPrepStateReturn: $TaxPrepStateReturn}
    ) {
      TaxPrepIncome {
        AnnualGrossReceipts
        CreatedAt
        HasCapitalGainsOrLosses
        HasPartnershipIncomeOrLosses
        HasRentalIncomeOrLosses
        HasSelfEmploymentIncome
        Id
        NeedStateReturn
        OperatedFarm
        ProfileId
        QuoteId
        SoldRealEstate
        UpdatedAt
      }
      TaxPrepStateReturn {
        CreatedAt
        Id
        IncomeId
        State
        UpdatedAt
      }
    }
  }
`;