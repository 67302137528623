import { useRef, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FilingStatus from "./FilingStatus";
import { InitialTaxOrganizerData, PersonalInformationModel, Provider, TaxOrganizerData } from "./TaxOrganizerContext";
import { Request } from "../Constants";
import PersonalInformation from "./PersonalInformation";
import Dependents from "./Dependents";
import IncomeSources from "./IncomeSources";
import OtherInformation from "./OtherInformation";
import TaxOrganizerSummary from "./TaxOrganizerSummary";
import CompletedPage from "./CompletedPage";

function TaxOrganizer(props:any) {
    const navigate = useNavigate();
    const [selectedRequest, setSelectedRequest] = useState<Request>(props.request);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [taxOrganizerData, setTaxOrganizerData] = useState<TaxOrganizerData>(InitialTaxOrganizerData);
    const personalInfoRef = useRef();
    const [validated, setValidated] = useState(false);
    const [validatedCount, setValidatedCount] = useState(0);

    const nextHandler = () => {
      if(pageNumber === 1) {
        setValidatedCount(count => count+1);
        if(!validated){
          return false;
        }
      }
      setPageNumber(prevState => prevState+1);
    }

    const previousHandler = () => {
        setPageNumber(prevState => prevState-1);
    }

    
    return (
        <>
      <Container className="mb-5">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  navigate("/requests");
                }}
              >
                Request Center
              </Breadcrumb.Item>
              <Breadcrumb.Item active={pageNumber === 0? true:false}>{selectedRequest.Name}</Breadcrumb.Item>
              {pageNumber === 1 &&
                <Breadcrumb.Item active>Personal Information</Breadcrumb.Item>
              }
              {pageNumber === 2 &&
                <Breadcrumb.Item active>Dependents</Breadcrumb.Item>
              }
              {pageNumber === 3 &&
                <Breadcrumb.Item active>Income Source</Breadcrumb.Item>
              }
              {pageNumber === 4 &&
                <Breadcrumb.Item active>Other Information</Breadcrumb.Item>
              }
              {pageNumber === 5 &&
                <Breadcrumb.Item active>Summary</Breadcrumb.Item>
              }
            </Breadcrumb>
            <Provider value= {{
                pageNumber,
                selectedRequest,
                taxOrganizerData,
                setTaxOrganizerData,
                setPageNumber
            }}>
            <main>
            {pageNumber === 0 &&
                <FilingStatus />
            }
            {pageNumber === 1 &&
                <PersonalInformation validatedCount={validatedCount} onNext={setValidated}/>
            }
            {pageNumber === 2 &&
                <Dependents />
            }
            {pageNumber === 3 &&
                <IncomeSources />
            }
            {pageNumber === 4 &&
                <OtherInformation />
            }
            {pageNumber === 5 &&
                <TaxOrganizerSummary />
            }
            {pageNumber === 6 &&
                <CompletedPage />
            }
            </main>
            </Provider>
            <div className="mt-4" style={{textAlign: 'center'}}>
            {pageNumber === 5 && <button className="btn-continue" onClick={nextHandler}>{'Submit'}</button>}
            {(pageNumber !== 5 && pageNumber !== 6) &&
              <>
                <button className="btn-continue-outline me-2" 
                    disabled={pageNumber === 0? true: false}
                    onClick={previousHandler}>{'< Previous'}</button>
                <button className="btn-continue" onClick={nextHandler}>{'Next >'}</button>
              </>
            }
          </div>
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default TaxOrganizer;
