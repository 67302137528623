import React from "react";
import "../../pages/navbar.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PartnerFinished() {

  const onNavigate = () => {
  }
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
              <h1 className="content-box-heading mb-3">Finished!</h1>
              <p className="content-text">
                Head over to your dashboard where you’ll be able to add new
                referrals, review your payouts, and manage all of your referral
                clients from one easy to navigate interface.
              </p>

              <div className="d-flex mt-5 align-items-center">
                <Link to={"/partner-dashboard"}>
                  <button onClick={onNavigate} className="btn-xl px-1">
                    Continue to Dashboard
                  </button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerFinished;
