import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calendar from "react-calendar";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { clientConfig } from "../../awsClientConfig";
import { GetAppointmentTopics } from "../../queries/GetAppointmentTopics";
import { GetAppointmentAvailability } from "../../queries/GetAppointmentAvailability";
import moment from "moment";
import { CreateAppointment } from "../../queries/CreateAppointment";
import { GetProfile } from "../../queries/getProfile";
import { userProfileData } from "../../Common/profileInfo";
import { useNavigate } from "react-router-dom";
import Loading from "../../Common/Loading";
import Message from "../Message";
function ScheduleAppointment() {
  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, SetMaxDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [showTime, setShowTime] = useState("");
  const [showTimeZone, setShowTimeZone] = useState("");
  const [notes, setNotes] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [error, setError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e: any) => {
    e.preventDefault();
    setShow(true);
  };
  const client = new clientConfig();

  useEffect(() => {
    client
      .query({
        query: GetAppointmentTopics,
      })
      .then((res: any) => {
        if (res.data.GetAppointmentTopics !== null) {
          setTopics(res.data.GetAppointmentTopics);
          setSelectedTopic(res.data.GetAppointmentTopics[0].Value);
        }
      })
      .catch((error: any) => {
        setLoadData(false);
        console.log(error, "error");
      });
  }, []);

  useEffect(() => {
    if (selectedTopic) {
      client
        .query({
          query: GetAppointmentAvailability,
          variables: { AppointmentTopic: selectedTopic },
        })
        .then((res: any) => {
          if (res.data.GetAppointmentAvailability !== null) {
            setMinDate(new Date(res.data.GetAppointmentAvailability[0]?.Date));
            onChange(new Date(res.data.GetAppointmentAvailability[0]?.Date));
            const allDates = res.data.GetAppointmentAvailability.length;
            SetMaxDate(
              new Date(res.data.GetAppointmentAvailability[allDates - 1]?.Date)
            );
            setAvailableDates(res.data.GetAppointmentAvailability);
            setLoadData(false);
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
          setLoadData(false);
        });
    }
  }, [selectedTopic]);

  useEffect(() => {
    const filteredTimes: any = availableDates.filter((date: any) => {
      const selectedDate = new Date(value);
      const DateChecking = new Date(date?.Date);

      return selectedDate.getTime() === DateChecking.getTime();
    });
    console.log(filteredTimes[0]);
    setAvailableTimes(filteredTimes[0]);
    setSelectedTime(filteredTimes[0]?.AvailableTimes[0].AvailabilityTime);
    setShowTime(filteredTimes[0]?.AvailableTimes[0].DisplayTime);
    setShowTimeZone(filteredTimes[0]?.AvailableTimes[0].TimeZone);
  }, [availableDates, value]);

  const createAppointment = async () => {
    setSpinner(true);
    setLoadData(true);
    handleClose();
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          client
            .mutate({
              mutation: CreateAppointment,
              variables: {
                ProfileId: profileId,
                Name:
                  res.data.GetProfile.FirstName + res.data.GetProfile.LastName,
                Email: res.data.GetProfile.Email,
                Phone: res.data.GetProfile.Phone,
                StartAt: selectedTime,
                Notes: notes ? notes : null,
                AppointmentTopic: selectedTopic,
              },
            })
            .then(() => {
              handleClose();
              setSpinner(false);
              setLoadData(false);
              navigate("/appointments");
            })
            .catch((error: any) => {
              setSpinner(false);
              setLoadData(false);
              setError(error.graphQLErrors[0].message);
              setTimeout(() => {
                setError("");
              }, 5000);
            });
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box py-5  px-lg-5">
            {loadData ? (
              <Loading />
            ) : (
              <div className="mx-lg-5">
                <h1 className="content-box-heading">Schedule An Appointment</h1>
                <p className="content-text">
                  Please select a date and time to schedule a 30-minute meeting
                  with one of our Tax Associates.
                </p>

                <Form>
                  <Row className="justify-content-between align-items-center">
                    <Col md={5}>
                      <Calendar
                        onChange={onChange}
                        value={value}
                        minDate={minDate}
                        prev2Label={null}
                        next2Label={null}
                        maxDate={maxDate}
                      />
                    </Col>
                    <Col md={4}>
                      <div className="d-grid mx-auto mb-3">
                        <button
                          className="btn-continue"
                          style={{ width: "100%", height: "50px" }}
                          disabled={true}
                        >
                          30-min meeting
                        </button>
                      </div>

                      <Form.Group controlId="">
                        <Form.Label>Appointment Topic</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={selectedTopic}
                          onChange={(e) => {
                            setSelectedTopic(e.target.value);
                          }}
                        >
                          {topics?.map((topic: any) => {
                            return (
                              <option value={topic?.Value}>
                                {topic?.DisplayText}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group controlId="">
                        <Form.Label className="mt-3">
                          All times are in Pacific Time (US & Canada)
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          value={selectedTime}
                          onChange={(e) => {
                            setSelectedTime(e.target.value);
                            setShowTime(
                              availableTimes?.AvailableTimes[
                                e.target.selectedIndex
                              ].DisplayTime
                            );
                            setShowTimeZone(
                              availableTimes?.AvailableTimes[
                                e.target.selectedIndex
                              ].TimeZone
                            );
                          }}
                        >
                          {availableTimes?.AvailableTimes?.map((time: any) => {
                            return (
                              <option value={time?.AvailabilityTime}>
                                {time?.DisplayTime} {time?.TimeZoneShort}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {error && <Message type="error" message={error} />}
                    </Col>
                    <Col md={12} className="mt-4">
                      <Form.Group controlId="">
                        <Form.Label>
                          Please let us know if you have any special requests.
                          Thank you.
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Notes (optional)"
                          style={{ height: "100px", resize: "none" }}
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={8}></Col>
                    <Col md={4} className="mt-2 float-right">
                      <div className="d-grid mx-auto mb-3">
                        <button
                          className="btn-continue mt-2"
                          style={{ width: "100%" }}
                          onClick={handleShow}
                        >
                          Schedule Appointment
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5>{moment(value).format("MMMM, DD, YYYY")}</h5>
            <h5>
              {showTime} {showTimeZone}
            </h5>
          </div>

          <div className="d-block mx-auto  text-center">
            <button
              className="btn-xl mb-3 mt-4"
              onClick={createAppointment}
              disabled={spinner}
            >
              Confirm Appointment
            </button>
            <span
              className="material-blue mb-2 text-decoration-underline d-block mx-auto fw-bold ff-Montserrat cursor-pointer"
              onClick={handleClose}
            >
              Cancel
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScheduleAppointment;
