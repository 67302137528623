import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import { userAccountEmail, userProfileData } from "../../Common/profileInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { emailIsValid } from "../../functions/misc";
import { useTranslation } from "react-i18next";
import Message from "../Message";
import { FormGroup, FormLabel } from "react-bootstrap";
import { CreatePartnerDeletion } from "../../queries/CreatePartnerDeletion";

interface FormError {
  email: string;
  password: string;
}

function PartnerDeleteAccount() {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false) 
    navigate("/login")
  };
  const handleShow = () => setShow(true);
  const client = new clientConfig();
  const reasons = [
    { value: "TooExpensive", name: "It is too expensive" },
    { value: "PoorExperience", name: "Poor user experience" },
    { value: "DoNotUseApp", name: "I don't use the app anymore" },
    { value: "DidNotMeetExpectation", name: "It did not meet my expectation" },
    { value: "FoundAlternative", name: "Alternative solutions" },
    { value: "LifeCircumstance", name: "My life circumstance changed" },
    { value: "CreateByMistake", name: "I created this account by mistake" },
    { value: "NotSatisfied", name: "Not Satisfied" },
  ];
  const [reason, setReason] = useState(reasons[0].value);
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [error, setError] = useState<FormError>({ email: "", password: "" });
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    validateFormData(email, password);
  }, [email, password]);

  const validateFormData = (_email: string, _password: string) => {
    let _error = error;
    if (!email) {
      error["email"] = t("login.error.email.empty");
    } else if (!emailIsValid(email)) {
      error["email"] = t("login.error.email.invalid");
    } else {
      error["email"] = "";
    }
    if (!password) {
      error["password"] = t("login.error.password.empty");
    } else {
      error["password"] = "";
    }
    setError(_error);
    setError({ ...error });
  };
  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  const handleSubmit = async (event: any) => {
    setSubmited(true);
    event.preventDefault();
    event.stopPropagation();
    const accountEmail = await userAccountEmail();
    console.log(accountEmail);
    if(email.toLowerCase() && accountEmail.toLowerCase() !== email.toLowerCase()){
        let _error2 = error;
        _error2["email"] = "You are not authorized to cancel the account";
        setError(_error2);
        setError({ ...error });
        return;
    }
    setShowSpinner(true);
    Auth.signIn(email.toLowerCase(), password)
      .then((res) => {
        setShowSpinner(false);
        deleteAccount();
      })
      .catch((error) => {
        setShowSpinner(false);
        const code = error.code;
        console.log(error);
        switch (code) {
          case "NotAuthorizedException":
            let _error1 = error;
            _error1["password"] = "Incorrect username or password";
            setError(_error1);
            setError({ ...error });
            break;
          case "UserNotFoundException":
            let _error2 = error;
            _error2["email"] = "User does not exist";
            setError(_error2);
            setError({ ...error });
            break;
          default:
            let _error3 = error;
            _error3["email"] = error.message;
            setError(_error3);
            setError({ ...error });
            break;
        }
      });
  };

  const deleteAccount = async () => {
    const profileId = await userProfileData();
    const variables = { PartnerId: profileId, DeletionReason: reason };
    client
      .mutate({ mutation: CreatePartnerDeletion, variables: variables })
      .then((res: any) => {
        if (res.data.CreatePartnerDeletion) {
          handleShow();
        }
      })
      .catch((error: any) => {});
  };

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/partner-settings")}>
                Account Settings
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Cancel Account</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mb-3">
              <Col lg={12}>
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Cancel Your Account
                </h4>
                <h6 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Are You Sure You Want to Cancel Your Account?
                </h6>
                <p className="content-text">
                  You will lose all your account information including all
                  referral history.
                </p>
              </Col>
            </Row>

            <Form>
              <Row className="mb-3">
                <Col md={8}>
                  <FormGroup className="mb-3" id="formBasicEmail">
                    <FormLabel>{t("login.email")}</FormLabel>
                    <Form.Control
                      required
                      type="email"
                      value={email}
                      onChange={handleInputEmail}
                      placeholder="Enter email"
                      className={
                        "input-no-bg " +
                        (submited && error.email ? "is-invalid" : "")
                      }
                    />
                    {submited && error.email && (
                      <Message message={error.email} type="error"></Message>
                    )}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3" id="password">
                    <FormLabel>{t("login.password")}</FormLabel>
                    <div className="position-relative">
                      <Form.Control
                        type={pwdType}
                        value={password}
                        placeholder="Password"
                        required
                        onChange={handleInputPassword}
                        className={
                          "input-no-bg " +
                          (submited && error.password ? "is-invalid" : "")
                        }
                      />
                      <FontAwesomeIcon
                        onClick={togglePassword}
                        className="addOn"
                        icon={pwdIcon}
                      />
                    </div>
                    {submited && error.password && (
                      <Message message={error.password} type="error"></Message>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group controlId="">
                    <Form.Label>Reason for deletion</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    >
                      {reasons.map((reason) => {
                        return (
                          <option value={reason.value}>{reason.name}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="justify-content-end d-grid d-flex mt-4">
                <button
                  className="btn-continue-outline me-3"
                  onClick={() => navigate("/partner-settings")}
                >
                  No, Go Back
                </button>
                <button
                  className="btn-red"
                  onClick={handleSubmit}
                  disabled={showSpinner}
                >
                  <FaTrashAlt className="me-2" /> Yes, Delete
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="d-block mx-auto  text-center">
            <h5>Your Account Has Been Canceled.</h5>
            <h3 className="material-blue">We’re sorry to see you go.</h3>
            <p>
              Your Partner Liaison has been notified and will contact you
              regarding any outstanding commission or account related items.
            </p>
            <p>
              Please note that cancelling your account will not delete your
              data. Optima Tax Relief is subject to the rules and regulations of
              the IRS, state taxing authorities and federal statutes, which
              obligate us to maintain certain taxpayer information for up to 6
              years in some cases.
            </p>
          </div>
          <div className="d-block mx-auto  text-center">
            <button onClick={handleClose} className="btn-continue mb-2">
              Close
            </button>
          </div>
          <div className="d-block mx-auto  text-center">
            For more information please see our privacy notice:{" "}
            <a href="https://optimataxrelief.com/about-us/privacy/">
              https://optimataxrelief.com/about-us/privacy
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PartnerDeleteAccount;
