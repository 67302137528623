import gql from "graphql-tag";
export const GetProtectionPlans = gql`
  query GetProtectionPlans {
    GetProtectionPlans {
      Active
      CreatedAt
      Description
      Id
      Link
      MonthlyPrice
      Name
      Title
      UpdatedAt
      ChargeSalesTax
    }
  }
`;
