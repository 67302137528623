import gql from "graphql-tag";

export const DeleteOrganizationAddress = gql`
  mutation DeleteOrganizationAddress($OrganizationAddressId: ID!) {
    DeleteOrganizationAddress(
      OrganizationAddressId: $OrganizationAddressId
    ) {
      Id
    }
  }
`;
