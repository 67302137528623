import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import { GetProtectionPlanSubscription } from "../../queries/GetProtectionPlanSubscription";
import { userAccountEmail, userProfileData } from "../../Common/profileInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { emailIsValid } from "../../functions/misc";
import { useTranslation } from "react-i18next";
import Message from "../Message";
import { FormGroup, FormLabel, Spinner } from "react-bootstrap";
import { CancelSubscription as CancelProfileSubscription } from "../../queries/CancelSubscription";
import moment from "moment";
import Loading from "../../Common/Loading";
import EnrollmentWarningModal from "../DashBoards/ProtectionPlan/EnrollmentWarningModal";

interface FormError {
  email: string;
  password: string;
}

function CancelSubscription() {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [nextDate, setNextDate] = useState("");
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const client = new clientConfig();
  const reasons = [
    { value: "TooExpensive", name: "It is too expensive" },
    { value: "PoorExperience", name: "Poor user experience" },
    { value: "DoNotUseApp", name: "I don't use the app anymore" },
    { value: "DidNotMeetExpectation", name: "It did not meet my expectation" },
    { value: "FoundAlternative", name: "Alternative solutions" },
    { value: "LifeCircumstance", name: "My life circumstance changed" },
    { value: "CreateByMistake", name: "I created this account by mistake" },
    { value: "NotSatisfied", name: "Not Satisfied" },
  ];
  const [reason, setReason] = useState(reasons[0].value);
  const [password, setPassword] = useState("");
  const [pwdIcon, setPwdIcon] = useState(faEyeSlash);
  const [pwdType, setPwdType] = useState("password");
  const [error, setError] = useState<FormError>({ email: "", password: "" });
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [enrollmentWarning, setEnrollmentWarning] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  useEffect(() => {
    fetchSubscriptionDate();
  }, []);

  const fetchSubscriptionDate = async () => {
    const profileId = await userProfileData();

    client
      .query({
        query: GetProtectionPlanSubscription,
        variables: {
          ProfileId: profileId,
        },
      })
      .then((res: any) => {
        const status = res.data.GetProtectionPlanSubscription.Status;
        setSubscriptionStatus(status);
        if (res.data.GetProtectionPlanSubscription?.NextPaymentDate) {
         setIsSubscribed(true);
         setNextDate(res.data.GetProtectionPlanSubscription?.NextPaymentDate);
        } else setNextDate(moment(new Date()).format("YYYY-MM-DD"));
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error, "error");
      });
  };

  useEffect(() => {
    validateFormData(email, password);
  }, [email, password]);

  const validateFormData = (_email: string, _password: string) => {
    let _error = error;
    if (!email) {
      error["email"] = "Email cannot be empty";
    } else if (!emailIsValid(email)) {
      error["email"] = t("login.error.email.invalid");
    } else {
      error["email"] = "";
    }
    if (!password) {
      error["password"] = "Password cannot be empty";
    } else {
      error["password"] = "";
    }
    setError(_error);
    setError({ ...error });
  };
  const handleInputEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      setPwdIcon(faEye);
      return;
    }
    setPwdType("password");
    setPwdIcon(faEyeSlash);
  };

  const handleSubmit = async (event: any) => {
    setSubmited(true);
    setShowSpinner(true);
    event.preventDefault();
    event.stopPropagation();
    const accountEmail = await userAccountEmail();
    if(email.toLowerCase() && accountEmail.toLowerCase() !== email.toLowerCase()){
        setShowSpinner(false);
        let _error2 = error;
        _error2["email"] = "You are not authorized to cancel the subscription";
        setError(_error2);
        setError({ ...error });
        return;
    }
    Auth.signIn(email.toLowerCase(), password)
      .then((res) => {
        setShowSpinner(false);
        handleShow();
      })
      .catch((error) => {
        setShowSpinner(false);
        const code = error.code;
        console.log("error:", error);
        switch (code) {
          case "NotAuthorizedException":
            let _error1 = error;
            _error1["password"] = "Incorrect email or password";
            setError(_error1);
            setError({ ...error });
            break;
          case "UserNotFoundException":
            let _error2 = error;
            _error2["email"] = "User does not exist";
            setError(_error2);
            setError({ ...error });
            break;
          case "InvalidParameterException":
           let _error3 = error;
           _error3["password"] = "Password cannot be empty";
           setError(_error3);
           setError({ ...error });
           break;
          default:
            let _error4 = error;
            _error4["email"] = "Email cannot be empty";
            setError(_error4);
            setError({ ...error });
            break;
        }
      });
  };

  const cancelSubscription = async () => {
    setLoadingModal(true);
    const profileId = await userProfileData();
    const variables = { ProfileId: profileId, CancellationReason: reason };
    client
      .mutate({ mutation: CancelProfileSubscription, variables: variables })
      .then((res: any) => {
        if (res.data.CancelSubscription) {
          setIsSubscribed(false);
          setSubscriptionStatus("PendingCancellation");
          setLoadingModal(false);
        }
      })
      .catch((error: any) => {
       console.log('error', error);
        setLoadingModal(false);
        let _error2 = error;
        _error2["email"] = "You are not authorized to cancel the subscription";
        setError(_error2);
        setError({ ...error });
      });
  };

  const onGetProtectedHandler = (event:any) => {
    event.preventDefault();
    if(subscriptionStatus === 'PendingCancellation') {
      setEnrollmentWarning(true);
    }else {
      navigate("/protection-plan-enroll");
    }
  }

  const onCloseHandler = () => {
    setEnrollmentWarning(false);
  }

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state: { isSubscribed: isSubscribed }})}>
                Account Settings
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Cancel Your Subscription</Breadcrumb.Item>
            </Breadcrumb>
           {loading ? <Loading />
            :
            <>
             <Row className="mb-3">
              <Col lg={12}>
               {isSubscribed &&
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                 Cancel Your Subscription
                </h4>
               }
               {isSubscribed &&
                <h6 className="text-main-dark ff-Montserrat fw-bold mb-3">
                 Are you sure you want to cancel your subscription?
                </h6>
               }
               {isSubscribed ?
                <>
                 <p className="content-text">
                  If you cancel your subscription before the next billing
                  cycle {nextDate}, your Tax Authorization Form (8821)
                  will be withdrawn after this billing cycle concludes and we
                  will no longer monitor your tax situation. If you decide to
                  resubscribe to the Optima Protection Plan, please contact our
                  team at 800-536-0734.
                 </p>
                 <p className="content-text">
                  Please note that cancelling your subscription will not delete your
                  data. Optima Tax Relief is subject to the rules and
                  regulations of the IRS, state taxing authorities and federal
                  statutes, which obligate us to maintain certain taxpayer
                  information for up to 6 years in some cases. For more
                  information, please see our privacy notice:
                  <a className="letsdolater" href="https://optimataxrelief.com/about-us/privacy/" target="_blank" rel="noreferrer">
                   {" "}https://optimataxrelief.com/about-us/privacy/.{" "}
                  </a>
                 </p>
                </>
                :
                <>
                <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                  Oops! It Looks Like You Don't Have an Active Subscription.
                </h4>
                 <p className="content-text">
                 <br />That’s ok! It’s easy to subscribe and receive all the benefits and protection against IRS surprises. Our Protection Plans offer exclusive features to our members such as $10,000,000 Lifetime Audit Defense and Optima’s best-in-class service.
                 </p>
                 <p className="content-text fw-bold">
                 Ready to take the next step? Click the button below to get protected.
                 </p>
                </>
               }
              </Col>
             </Row>

             <Form>
              {isSubscribed &&
                <Row className="mb-3">
                  <Col md={8}>
                    <FormGroup className="mb-3" id="formBasicEmail">
                      <FormLabel>{t("login.email")}</FormLabel>
                      <Form.Control
                        required
                        type="email"
                        value={email}
                        onChange={handleInputEmail}
                        placeholder="Enter email"
                        className={
                         "input-no-bg " +
                         (submited && error.email ? "is-invalid" : "")
                        }
                      />
                     {submited && error.email && (
                      <Message message={error.email} type="error"></Message>
                     )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-3" id="password">
                      <FormLabel>{t("login.password")}</FormLabel>
                      <div className="position-relative">
                        <Form.Control
                          type={pwdType}
                          value={password}
                          placeholder="Password"
                          required
                          onChange={handleInputPassword}
                          className={
                           "input-no-bg " +
                           (submited && error.password ? "is-invalid" : "")
                          }
                        />
                        <FontAwesomeIcon
                          onClick={togglePassword}
                          className="addOn"
                          icon={pwdIcon}
                        />
                      </div>
                     {submited && error.password && (
                      <Message message={error.password} type="error"></Message>
                     )}
                    </FormGroup>
                  </Col>
                </Row>}
              {isSubscribed &&
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Reason for Cancellation</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={reason}
                        onChange={(e) => {
                         setReason(e.target.value);
                        }}
                      >
                       {reasons.map((reason) => {
                        return (
                         <option value={reason.value}>{reason.name}</option>
                        );
                       })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>}
              <div className="justify-content-end d-grid d-flex mt-4">
               {isSubscribed ?
                <>
                 <button className="btn-continue-outline me-3" onClick={() => navigate("/account-settings")}>
                  No, Go Back
                 </button>
                 <button className="btn-red cursor-pointer fw-bold" onClick={handleSubmit} disabled={showSpinner}>
                  {showSpinner &&
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mx-2"
                    />}
                  <FaTrashAlt className="me-1"/> Yes, Cancel
                 </button>
                </>
                :
                <button className="btn-xl" onClick={(event) => onGetProtectedHandler(event)}>
                 Get Protected
                </button>
               }
              </div>
             </Form>
            </>
           }
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4 w-100">
         {loadingModal ?
          <div className="mb-4">
            <Loading/>
          </div>
           : <>
          {isSubscribed ?
           <>
            <div className="text-center">
             <h6 className="ff-Montserrat fs-4 mt-0" style={{color: "#1589d2"}}>Are you sure you want to cancel?</h6>
             <p className="mt-3 mb-5 content-text text-center">
              Canceling today will leave you unprotected against future IRS surprises. <br /> <br />
              Are you sure you want to lose your $10,000,000 Lifetime Audit Defense and all of the other great benefits your Protection Plan currently provides?
             </p>
            </div>
            <div className="justify-content-end d-grid d-flex mt-4">
             <button onClick={handleClose} className="btn-xl fw-bold me-3"
                     style={{width: "200px", height: "50px"}}>
              Stay Protected
             </button>
             <button onClick={cancelSubscription} className="btn-red btn-xl border-0 fw-bold me-3"
                     style={{backgroundColor: "#ED2926", width: "200px", height: "50px"}}>
              Proceed
             </button>
            </div>
           </>
           :
           <>
            <div className="d-block mx-auto  text-center">
             <h6 className="fw-bold">Your Subscription Has Been Cancelled.</h6>
             <h3 className="material-blue fw-bold mt-3 ff-Montserrat">We’re Sorry to See You Go.</h3>
             <p className="mt-3 mb-4 content-text text-center">
             Luckily, canceling your subscription will not delete your data. When you return, you’ll be able to reactivate your subscription quickly and easily with just a few clicks.
             </p>
            </div>
            <div className="d-block mx-auto  text-center">
             <button onClick={handleClose} className="btn-xl mb-2">
              Close
             </button>
            </div>
            <div className="d-block mx-auto  text-center mt-3">
             <p className="text-secondary" style={{fontSize: 11}}>
              For more information please see our privacy notice:
              <a
               style={{fontSize: 11}}
               className="letsdolater"
               href="https://optimataxrelief.com/about-us/privacy/"
               target="_blank"
               rel="noreferrer"
              >
               {" "}https://optimataxrelief.com/about-us/privacy/.{" "}
              </a>
             </p>
            </div>
           </>}
         </>}
        </Modal.Body>
      </Modal>
      <EnrollmentWarningModal showEnrollmentWarning={enrollmentWarning} onClose={onCloseHandler}/>
    </>
  );
}

export default CancelSubscription;
