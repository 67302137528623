import { Row, Col } from "react-bootstrap";
import iconpp from "../../../assets/iconpp.svg";
import { useNavigate } from "react-router-dom";



const TaxPreparationCard = () => {
    const navigate = useNavigate();

    return (
        <Row className="px-4 justify-content-center align-items-center pt-5">
            <Col lg={7}>
                <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                File your taxes with a tax pro
                </h1>

                <p className="content-text">
                We'll guide you step-by-step and guarantee our calculations will be 100% accurate.
                </p>
                <div className="g-0 no-display-mobile">
                <div className="d-flex my-3">
                <div className="me-3">
                <ul className="list_items_align">
                <li className="protection_plan mb-2">
                    <img
                    src={iconpp}
                    className="max-auto d-block"
                    alt="tax-report-ready"
                    />
                    Connect with live tax expert, on demand
                </li>
                <li className="protection_plan mb-2">
                    <img
                    src={iconpp}
                    className="max-auto d-block"
                    alt="tax-report-ready"
                    />
                    Our experts can help fix any mistakes and explain your taxes
                </li>
                <li className="protection_plan mb-2">
                    <img
                    src={iconpp}
                    className="max-auto d-block"
                    alt="tax-report-ready"
                    />
                    Get an expert final review before you file by the #1 Tax Relief Company
                </li>
                </ul>
                </div>
                </div>
                </div>
                <div className="g-0 mobile-view-center">
                <button className="btn-xl" style={{width: '60%', fontWeight: 'bold'}} 
                        onClick={() => navigate("/tax-preparation-service")}>
                File My Taxes for Me
                </button>
                </div>
            </Col>
            
            <Col lg={5}  className="tax-preparation-service-bashboard-bg no-display-mobile">
                
            </Col>
            
      </Row>
    )
}

export default TaxPreparationCard;