import { createContext } from "react";

interface ContextState {
  navigateToPage: (pageName: string) => void;
  enrollmentId: string;
  setEnrollmentId: (id: string) => void;
  nextFormId: string;
  setNextFormId: (id: string) => void;
  s3key: string;
  setS3Key: (id: string) => void;
  s3Bucket: string;
  setS3Bucket: (id: string) => void;
}

const SpouseTaxPrepFormContext = createContext({} as ContextState);

export default SpouseTaxPrepFormContext;

export const { Provider } = SpouseTaxPrepFormContext;
