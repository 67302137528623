import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Message from "../../components/Message";

const profileErrorField = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

function ProfileInfo({
  partner,
  setPartner,
  setPartnerProfileValidated,
  submitted,
}: any) {
  const [profileError, setProfileError] = useState(profileErrorField);
  const onProfileChange = (e: any) => {
    setPartner({ ...partner, [e.target.name]: e.target.value });
    let _error: any = profileError;
    _error[e.target.name] = "";
  };

  function formatPhoneNumber(str: any) {
    if (str) {
      const phoneNumber = str.replace(/[^\d]/g, "");
      if (phoneNumber.length < 4) {
        return phoneNumber;
      }

      if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.length < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    }
  }

  useEffect(() => {
    const validateFormData = () => {
      let _error = { ...profileErrorField };
      if (partner) {
        const { FirstName, LastName, Phone, Email } = partner;
        if (!FirstName) {
          _error["firstName"] = "First Name can not be empty";
        }
        if (!LastName) {
          _error["lastName"] = "Last Name Name can not be empty";
        }
        if (!Phone) {
          _error["phone"] = "Phone can not be empty";
        }
        if (!Email) {
          _error["email"] = "Email can not be empty";
        }
        setProfileError(_error);
        if (
          _error.firstName ||
          _error.lastName ||
          _error.phone ||
          _error.email
        ) {
          return setPartnerProfileValidated(false);
        }

        return setPartnerProfileValidated(true);
      }
    };

    validateFormData();
  }, [partner, setPartnerProfileValidated]);

  return (
    <Row className="mb-3 justify-content-between">
      <Col lg={12}>
        <div style={{ display: "flex" }}>
          <h6
            style={{ color: "#0A579B" }}
            className="text-main-dark ff-Montserrat fw-bold mb-3"
          >
            Profile Information
          </h6>
        </div>
      </Col>
      <Row className="mb-3 justify-content-between">
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              onChange={onProfileChange}
              name="FirstName"
              type="input"
              placeholder=""
              value={partner?.FirstName}
            />
            {submitted && profileError.firstName && (
              <Message type="error" message={profileError.firstName}></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              onChange={onProfileChange}
              name="MiddleName"
              type="input"
              placeholder=""
              value={partner?.MiddleName}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              onChange={onProfileChange}
              name="LastName"
              type="input"
              placeholder=""
              value={partner?.LastName}
            />
            {submitted && profileError.lastName && (
              <Message type="error" message={profileError.lastName}></Message>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3 justify-content-between">
        <Col lg={3}>
          <Form.Group controlId="">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              onChange={onProfileChange}
              name="Phone"
              type="text"
              placeholder=""
              value={formatPhoneNumber(partner?.Phone)}
            />
            {submitted && profileError.phone && (
              <Message type="error" message={profileError.phone}></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={onProfileChange}
              name="email"
              type="text"
              placeholder=""
              value={partner?.Email}
              disabled
            />
            {submitted && profileError.email && (
              <Message type="error" message={profileError.email}></Message>
            )}
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="">
            <Form.Label>Are you a Tax Preparer?</Form.Label>
            <Form.Select
              onChange={onProfileChange}
              name="Type"
              aria-label="Default select example"
              value={
                partner?.Type === "TaxProfessionals"
                  ? "Yes"
                  : !partner?.Type
                  ? "No"
                  : partner?.Type
              }
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="">
            <Form.Label>Business Title</Form.Label>
            <Form.Select
              onChange={onProfileChange}
              name="Title"
              aria-label="Default select example"
              value={partner?.Title}
            >
              <option value="Owner">Owner</option>
              <option value="AuthorizedRepresentative">
                Authorized Representative
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}

export default ProfileInfo;
