import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FilingStatusTypes } from '../Constants';
import IncomeExpensesContext from "./IncomeExpensesContext";


const PersonalInformation = () => {
    const {selectedRequest, incomeExpensesData, setIncomeExpensesData } = useContext(IncomeExpensesContext);

    const maritalStatusHandler = (status:string) => {
        setIncomeExpensesData({...incomeExpensesData, maritalStatus: status});
    }

    const onChangetHandler = (event: any) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        setIncomeExpensesData({...incomeExpensesData, [fieldName]: fieldValue});
    }

    return (
        <div style={{marginTop: '1rem'}}>
            <h4 className="text-main-dark ff-Montserrat fw-bold mb-4">
              {'Income & Expense Worksheet: Personal Information'}
            </h4>
            <Form>
                <Row className="mb-2">
                    <Col md={'auto'}>
                    <Form.Group controlId="">
                        <Form.Label>
                        How many people are living in the same house?
                        </Form.Label> 
                        <Form.Control
                        value={incomeExpensesData.peoplelivingInHouse}
                        onChange={(event) => {onChangetHandler(event)}}
                        name="peoplelivingInHouse"
                        type="number"
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={'auto'}>
                    <Form.Group controlId="">
                        <Form.Label>
                        How many dependents do you have?
                        </Form.Label> 
                        <Form.Control
                        value={incomeExpensesData.dependentsCount}
                        onChange={(event) => {onChangetHandler(event)}}
                        name="dependentsCount"
                        type="number"
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={'auto'}>
                    <Form.Group controlId="">
                        <Form.Label className="fs-8">
                         What Is Your Current Marital Status?
                        </Form.Label> 
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Check
                            type="radio"
                            label="Single"
                            onChange={() => maritalStatusHandler(FilingStatusTypes.Single)}
                            checked={incomeExpensesData.maritalStatus === FilingStatusTypes.Single ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Head of Household"
                            onChange={() => maritalStatusHandler(FilingStatusTypes.HeadofHousehold)}
                            checked={incomeExpensesData.maritalStatus === FilingStatusTypes.HeadofHousehold ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Married Filing Jointly"
                            onChange={() => maritalStatusHandler(FilingStatusTypes.MarriedFilingJointly)}
                            checked={incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingJointly ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Married Filing Separately"
                            onChange={() => maritalStatusHandler(FilingStatusTypes.MarriedFilingSeparately)}
                            checked={incomeExpensesData.maritalStatus === FilingStatusTypes.MarriedFilingSeparately ? true : false}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default PersonalInformation;