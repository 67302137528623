import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext from './TaxPreparationServiceFormContext';
import ProfileInformationForm from './ProfileInformationForm';
import { Auth } from 'aws-amplify';
import { clientConfig } from '../../awsClientConfig';
import awsConfig from "../../aws-exports";
import { GetTaxReturnProfile } from '../../queries/GetTaxReturnProfile';
import { userAccountData, userProfileData } from '../../Common/profileInfo';
import { GetProfile } from '../../queries/getProfile';
import { formatPhone, formatZipcode, formatZipcodeStr } from '../../functions/misc';
import { UpsertProfilePrimaryAddress } from "../../queries/UpsertProfilePrimaryAddress";
import { GetProfilePrimaryAddress } from '../../queries/GetProfilePrimaryAddress';
import { CreateTaxReturnProfile } from '../../queries/CreateTaxReturnProfile';
import { UpdateTaxReturnProfile } from '../../queries/UpdateTaxReturnProfile';
import { createEnrollmentAction } from '../../queries/CreateEnrollmentAction';
import Loading from "../../Common/Loading";
import { createProfile } from '../../queries/createProfile';
import { GetProfileByEmail } from "../../graphql/one-api/custom-queries/Profile";
import Alert from 'react-bootstrap/Alert';
import PaymentCompletedMessage from './PaymentCompletedMessage'
import useEnrollmentStore from "../../store/EnrollmentStore";

const errorField = {
  firstName: "",
  lastName: "",
  phone: "",
};

const ProfileInformation = () => {
    const {taxPreparationData,
      setTaxPreparationData,
      navigateToPage,
      completedPagesHandler, enrollmentId,
      taxReturnId,
      nextEnrollmentAction,
      setNextFormId,
      setS3Key,
      setS3Bucket,
    } = useContext(TaxPreparationServiceFormContext);
    const [showError, setShowError] = useState(false);
    const client = new clientConfig();
    const [clientProfileInfo, setClientProfileInfo] = useState<any>();
    const [spouseProfileInfo, setSpouseProfileInfo] = useState<any>();
    const [disableContinue, setDisableContinue] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState(errorField);
    let spouseProfileId: string | null;
    const [validated, setValidated] = useState(false);
    const [activeTab, setActiveTab] = useState("clientInfo");
    const [clientErrors, setClientErrors] = useState({
      validZipCode: true,
      validPhone: true,
      validEmail: true
    });
    const [spouseErrors, setSpouseErrors] = useState({
      validZipCode: true,
      validPhone: true,
      validEmail: true
    });
    const { taxPrepEnrollment } = useEnrollmentStore();

    useEffect(() => {
      getTaxReturnProfile();
    },[]);

    const getTaxReturnProfile = async () => {
      setDisableContinue(true);
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      const profileId = await userProfileData();
      //setIsLoading(true);
      const variables = {
        ProfileId: profileId,
        EnrollmentId: enrollmentId
      };
      try{ 
        const res: any = await client.query({ query: GetTaxReturnProfile, variables: variables });
        if(res.data.GetTaxReturnProfile && res.data.GetTaxReturnProfile.TaxReturnProfile 
              && res.data.GetTaxReturnProfile.TaxReturnProfile.length > 0){
          const clientData = res.data.GetTaxReturnProfile?.TaxReturnProfile.find((taxProfile:any) => !taxProfile.Spouse);
          if(clientData){
            setClientProfileInfo({...clientData, Phone: formatPhone(clientData.Phone)});
          }
          const spouseData = res.data.GetTaxReturnProfile?.TaxReturnProfile.find((taxProfile:any) => taxProfile.Spouse);
          if(spouseData){
            setSpouseProfileInfo({...spouseData, Phone: formatPhone(spouseData.Phone)});
          }

          const spouseTaxProfile = res.data.GetTaxReturnProfile?.TaxReturnProfile.find((taxProfile:any) => taxProfile.Spouse);
          if (spouseTaxProfile) {
            const res: any = await client.query({ query: GetProfileByEmail, variables: { email: spouseTaxProfile.Email } });
            spouseProfileId = res.data?.GetProfileByEmail.Id ? res.data?.GetProfileByEmail.Id : null;
          }

        } else {
          await getProfileMain();
        }
        setDisableContinue(false);
      } catch (e) {
        console.log("error:", e);
        await getProfileMain();
        setDisableContinue(false);
      }
    };

    const getProfileMain = async () => {
      const profileId = await userProfileData();
      client
        .query({
          query: GetProfile,
          variables: { profileId: profileId},
        })
        .then(async (res: any) => {
          if (res.data.GetProfile !== null) {
            const addressRes:any = await client.query({query: GetProfilePrimaryAddress,variables: { profileId: profileId }});
            setClientProfileInfo((prevState:any) => ({...prevState, FirstName: res.data.GetProfile?.FirstName,
              MiddleName:res.data.GetProfile?.MiddleName, LastName:res.data.GetProfile?.LastName, 
              Email:res.data.GetProfile?.Email, Phone:formatPhone(res.data.GetProfile?.Phone),
              StreetAddress1: addressRes?.data?.GetProfilePrimaryAddress?.StreetAddress1,
              StreetAddress2: addressRes?.data?.GetProfilePrimaryAddress?.StreetAddress2,
              City: addressRes?.data?.GetProfilePrimaryAddress?.City,
              State: addressRes?.data?.GetProfilePrimaryAddress?.State,
              ZipCode: formatZipcodeStr(addressRes?.data?.GetProfilePrimaryAddress?.ZipCode)}))
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
    }

    const continueHandler = async () => {
      if (taxPrepEnrollment?.paymentCompleted) {
        navigateToPage('serviceAgreement2');
        return;
      }
      if(validateForm()){
        setDisableContinue(true);
        setShowSpinner(true);
        const profileId = await userProfileData();
        if(clientProfileInfo?.Id) {
          const variables = {
            ClientProfileId: profileId,
            Id: clientProfileInfo.Id,
            ProfileId: profileId,
            Spouse: false,
            FirstName: clientProfileInfo.FirstName,
            MiddleName: clientProfileInfo.MiddleName,
            LastName: clientProfileInfo.LastName,
            StreetAddress1: clientProfileInfo.StreetAddress1,
            StreetAddress2: clientProfileInfo.StreetAddress2,
            City: clientProfileInfo.City,
            State: clientProfileInfo.State,
            ZipCode: clientProfileInfo.ZipCode,
            Phone: clientProfileInfo.Phone.replace(/[^0-9]/g, ""),
            Email: clientProfileInfo.Email,
          }
          const res:any = await client.mutate({ mutation: UpdateTaxReturnProfile, variables: variables });
        }else {
          const variables = {
            ClientProfileId: profileId,
            ProfileId: profileId,
            TaxReturnId: taxReturnId,
            Spouse: false,
            FirstName: clientProfileInfo.FirstName,
            MiddleName: clientProfileInfo.MiddleName,
            LastName: clientProfileInfo.LastName,
            StreetAddress1: clientProfileInfo.StreetAddress1,
            StreetAddress2: clientProfileInfo.StreetAddress2,
            City: clientProfileInfo.City,
            State: clientProfileInfo.State,
            ZipCode: clientProfileInfo.ZipCode,
            Phone: clientProfileInfo.Phone.replace(/[^0-9]/g, ""),
            Email: clientProfileInfo.Email,
          }
          const res:any = await client.mutate({ mutation: CreateTaxReturnProfile, variables: variables });
        }
        if(taxPreparationData.jointFiling === 'MarriedFilingJointly'){
          if(spouseProfileInfo?.Id) {
            const variables = {
              ClientProfileId: profileId,
              Id: spouseProfileInfo.Id,
              ProfileId: profileId,
              Spouse: true,
              FirstName: spouseProfileInfo.FirstName,
              MiddleName: spouseProfileInfo.MiddleName,
              LastName: spouseProfileInfo.LastName,
              StreetAddress1: spouseProfileInfo.StreetAddress1,
              StreetAddress2: spouseProfileInfo.StreetAddress2,
              City: spouseProfileInfo.City,
              State: spouseProfileInfo.State,
              ZipCode: spouseProfileInfo.ZipCode,
              Phone: spouseProfileInfo.Phone.replace(/[^0-9]/g, ""),
              Email: spouseProfileInfo.Email,
            }
            const res:any = await client.mutate({ mutation: UpdateTaxReturnProfile, variables: variables });
          }else {
            const variables = {
              ClientProfileId: profileId,
              ProfileId: profileId,
              TaxReturnId: taxReturnId,
              Spouse: true,
              FirstName: spouseProfileInfo.FirstName,
              MiddleName: spouseProfileInfo.MiddleName,
              LastName: spouseProfileInfo.LastName,
              StreetAddress1: spouseProfileInfo.StreetAddress1,
              StreetAddress2: spouseProfileInfo.StreetAddress2,
              City: spouseProfileInfo.City,
              State: spouseProfileInfo.State,
              ZipCode: spouseProfileInfo.ZipCode,
              Phone: spouseProfileInfo.Phone.replace(/[^0-9]/g, ""),
              Email: spouseProfileInfo.Email,
            }
            const res:any = await client.mutate({ mutation: CreateTaxReturnProfile, variables: variables });
          }
        }

        const upsertProfileAddressVariables = {
          ProfileId: profileId,
          StreetAddress1: clientProfileInfo.StreetAddress1,
          StreetAddress2: clientProfileInfo.streetAddress2 ? clientProfileInfo.streetAddress2 : null,
          City: clientProfileInfo.City,
          State: clientProfileInfo.State,
          ZipCode: clientProfileInfo.ZipCode,
        }

        try {
          const upsertPrimaryProfileAddressRes: any = await client.mutate({ mutation: UpsertProfilePrimaryAddress, variables: upsertProfileAddressVariables })
          console.log("upsertPrimaryProfileAddressRes", upsertPrimaryProfileAddressRes);
          //create spouse profile
          if(taxPreparationData.jointFiling === 'MarriedFilingJointly'){
            const accountId = await userAccountData();
            const createProfileVariables = {
              email: spouseProfileInfo.Email,
              firstName: spouseProfileInfo.FirstName,
              lastName: spouseProfileInfo.LastName,
              middleName: spouseProfileInfo.MiddleName ? spouseProfileInfo.MiddleName : null,
              phone: spouseProfileInfo.Phone.replace(/[^0-9]/g, ""),
              parentId: profileId,
              accountId: accountId
            };
            if (!spouseProfileId) {
              const response: any = await client.mutate({ mutation: createProfile, variables: createProfileVariables });
              spouseProfileId = response.data?.CreateProfile?.Id;
            }
            if (spouseProfileId) {
              const upsertSpousePrimaryAddress = {
                ProfileId: spouseProfileId,
                StreetAddress1: spouseProfileInfo.StreetAddress1,
                StreetAddress2: spouseProfileInfo.StreetAddress2 ? spouseProfileInfo.StreetAddress2 : null,
                City: spouseProfileInfo.City,
                State: spouseProfileInfo.State,
                ZipCode: spouseProfileInfo.ZipCode,
              }
              const spouseUpsertPrimaryAddress: any = await client.mutate({ mutation: UpsertProfilePrimaryAddress, variables: upsertSpousePrimaryAddress });
              console.log("spouseUpsertPrimaryAddress", spouseUpsertPrimaryAddress);
            }
          }

          let variables = {
            EnrollmentId: enrollmentId,
            Name: "InformationCompleted",
            EnrollmentProfiles: [
              {
                EnrollmentId: enrollmentId,
                ProfileId: profileId,
                Owner: true
              }
            ]
          };
          if (spouseProfileId && taxPreparationData.jointFiling === 'MarriedFilingJointly') {
            variables.EnrollmentProfiles.push({
              EnrollmentId: enrollmentId,
              ProfileId: spouseProfileId,
              Owner: false
            });
          }

          const createEnrollmentActionRes: any = await client.mutate({ mutation: createEnrollmentAction, variables });
          if (createEnrollmentActionRes.data.CreateEnrollmentAction.Enrollment.Id) {
            nextEnrollmentAction(createEnrollmentActionRes.data.CreateEnrollmentAction.Enrollment?.NextEnrollmentAction);
            setNextFormId(createEnrollmentActionRes.data.CreateEnrollmentAction.Enrollment?.NextForm?.Id);
            setS3Key(createEnrollmentActionRes.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Key);
            setS3Bucket(createEnrollmentActionRes.data.CreateEnrollmentAction.Enrollment?.NextForm?.S3Bucket);
          }
          setDisableContinue(false);
          completedPagesHandler('serviceQuote');
          completedPagesHandler('serviceAgreement1');
        } catch (error: any) {
          console.log("error", error);
          if (error.errors?.some((e: any) => e.message.includes("phone"))) {
            setError((prev) => ({
              ...prev,
              phone: "Must be a valid phone number",
            }));
          }
          setShowError(true);
          setDisableContinue(false);
          setShowSpinner(false);
        }
      } else {
        setShowSpinner(false);
      }
    }

    function validateEmail(input: string) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (input && input.match(validRegex)) {
        return true;
      } 
      return false;
    
    }

    const validateZipCode = (zipCode:any) => {
      if(zipCode && (zipCode.length < 5 || (zipCode.length > 5 && zipCode.length < 10))){
        return false;
      }
      return true;
    }

    const validatePhone = (phone:any) => {
      if(phone.length < 14){
        return false;
      }
      return true;
    }

    const validateForm = () => {
      if(taxPreparationData.jointFiling === 'MarriedFilingJointly') {
        let clientError = {validZipCode: validateZipCode(clientProfileInfo.ZipCode),
          validPhone: validatePhone(clientProfileInfo.Phone),
          validEmail: validateEmail(clientProfileInfo.Email)};
        let spouseError = {validZipCode: validateZipCode(spouseProfileInfo.ZipCode),
          validPhone: validatePhone(spouseProfileInfo.Phone),
          validEmail: validateEmail(spouseProfileInfo.Email)};
        setClientErrors(clientError);
        setSpouseErrors(spouseError);
        if(!(clientError.validEmail && clientError.validPhone && clientError.validZipCode)){
          setActiveTab("clientInfo");
        }else if(!(spouseError.validEmail && spouseError.validPhone && spouseError.validZipCode)){
          setActiveTab("spouseInfo");
        }else {
          return true;
        }
      }else{
        let clientError = {validZipCode: validateZipCode(clientProfileInfo.ZipCode),
          validPhone: validatePhone(clientProfileInfo.Phone),
          validEmail: validateEmail(clientProfileInfo.Email)};
        setClientErrors(clientError);  
        if(clientError.validEmail && clientError.validPhone && clientError.validZipCode){
          return true;
        }
      }
      return false;
    }

    const onBackHandler = () => {
      navigateToPage('serviceQuote');
    }

    const onClientValuesChangeHandler = (event:any) => {
      const {name, value} = event.target;
      if(name === 'ZipCode') {
        setClientProfileInfo((prevState:any) => ({...prevState, [name]:formatZipcode(event)}));
      }else {
        setClientProfileInfo((prevState:any) => ({...prevState, [name]:value}));
      }
    }

    const onSpouseValuesChangeHandler = (event:any) => {
      const {name, value} = event.target;
      if(name === 'ZipCode') {
        setSpouseProfileInfo((prevState:any) => ({...prevState, [name]:formatZipcode(event)}));
      }else {
        setSpouseProfileInfo((prevState:any) => ({...prevState, [name]:value}));
      }
    }

    const phoneNumberChangeHandler = (name: string, value:string, type: string) => {
      if(type === 'client'){
        setClientProfileInfo((prevState:any) => ({...prevState, [name]:value}));
      }else {
        setSpouseProfileInfo((prevState:any) => ({...prevState, [name]:value}));
      }
    }

    const sameAddressCheckHandler = (checked: boolean) => {
      if(checked){
        setSpouseProfileInfo((prevState: any) => ({...prevState, 
              StreetAddress1:clientProfileInfo?.StreetAddress1,
              StreetAddress2:clientProfileInfo?.StreetAddress2,
              City:clientProfileInfo?.City,
              State:clientProfileInfo?.State,
              ZipCode:clientProfileInfo?.ZipCode}));
      }else {
        setSpouseProfileInfo((prevState: any) => ({...prevState, 
          StreetAddress1:'',
          StreetAddress2:'',
          City:'',
          State:'',
          ZipCode:''}))
      }
    }

    const spouseEmailCheckHandler = (email: string) => {
      client.query({ query: GetProfileByEmail, variables: { email: email } }).then((res: any) => {
        if(res.data.GetProfileByEmail) {
          const responeData = res.data.GetProfileByEmail;
          setSpouseProfileInfo((prevState:any) => ({...prevState, FirstName: responeData.FirstName,
                                                                  LastName: responeData.LastName,
                                                                  MiddleName: responeData.MiddleName,
                                                                  Phone: formatPhone(responeData.Phone)}));
        }

      }).catch((error) => {
        console.log(error);
      })
    }

    const onTabSelectHandler = (eventKey: any) => {
      setActiveTab(eventKey);
    }

    const onFormSubmit = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity()) {
        continueHandler();
      }else {
        if(taxPreparationData.jointFiling === 'MarriedFilingJointly') {
          const inputElements = event.target.querySelectorAll("input, select");
          const clientErroredElements = Array.from(inputElements)
                  .filter((element:any) => (element.id && element.id.startsWith("client_") && element.required && !element.value));
          const spouseErroredElements = Array.from(inputElements)
                  .filter((element:any) => (element.id && element.id.startsWith("spouse_") && element.required && !element.value));
          if(clientErroredElements && clientErroredElements.length > 0){
            setActiveTab("clientInfo");
          }else{
            setActiveTab("spouseInfo");
          }
        }
      }
      setValidated(true);
    }

    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            {showSpinner ? <Loading /> :
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={50} />
                <Row className="justify-content-center">
                  <Col md={12}>
                    <h1 className="content-box-heading mb-4">
                      {'Please Confirm Your Information'}
                    </h1>
                    {taxPrepEnrollment?.paymentCompleted && <PaymentCompletedMessage />}
                    {showError &&
                      <p className='fs-8 color-red'>Please fill required and valid information in the form.</p>
                    }
                    <Form className="mt-4" noValidate id="clientInfoForm" validated={validated} onSubmit={onFormSubmit}>
                    <Tabs activeKey={activeTab} onSelect={onTabSelectHandler}>
                      <Tab eventKey="clientInfo" title={'Primary'}>
                        <ProfileInformationForm inputData={clientProfileInfo}
                                                onChangeValue={onClientValuesChangeHandler} type={'client'}
                                                onPhoneChange={phoneNumberChangeHandler} onEmailCheck={spouseEmailCheckHandler} clientError={clientErrors} paymentCompleted={taxPrepEnrollment?.paymentCompleted} />
                      </Tab>
                      {taxPreparationData.jointFiling === 'MarriedFilingJointly' &&
                        <Tab eventKey="spouseInfo" title={'Spouse'} style={{marginLeft: '1rem !important'}}>
                          <ProfileInformationForm inputData={spouseProfileInfo}
                                                  onChangeValue={onSpouseValuesChangeHandler} type={'spouse'} onPhoneChange={phoneNumberChangeHandler}
                                                  onSameAddressCheck={sameAddressCheckHandler} onEmailCheck={spouseEmailCheckHandler} spouseError={spouseErrors} paymentCompleted={taxPrepEnrollment?.paymentCompleted} />
                        </Tab>
                      }
                    </Tabs>
                    <Row className="justify-content-start my-3">

                      <div className="d-flex justify-content-between mt-5 align-items-center">
                      <span onClick={onBackHandler}>
                        {" "}
                        <span className="letsdolater">Back</span>
                      </span>
                        <button
                          className={"btn-continue fw-bold"}
                          style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                          type="submit"
                          >
                          Continue
                        </button>
                      </div>
                    </Row>
                    </Form>
                    
                  </Col>
                </Row>
              </div>
            }
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default ProfileInformation;