import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { formatPhone, formatZipcode } from "../../functions/misc";
import { clientConfig } from "../../awsClientConfig";
import { userProfileData } from "../../Common/profileInfo";
import { GetProfile } from "../../queries/getProfile";
import { GetProfilePrimaryAddress } from "../../queries/GetProfilePrimaryAddress";
import { US_STATES } from "../../Common/States";
import Message from "../Message";
import { UpdateProfile } from "../../queries/updateProfile";
import { UpsertProfilePrimaryAddress } from "../../queries/UpsertProfilePrimaryAddress";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Common/Loading";
interface info {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
}

function EditProfile() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState({
    zipCode: "",
    languages: "",
    phone: ""
  });
  const [success, setSuccess] = useState("");
  const [address, setAddress] = useState({
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [personInfo, setPersonInfo] = useState({});
  const [formValue, setFormValue] = useState<info>({
    firstName: "",
    lastName: "",
    middleName: "",
    phone: "",
  });
  const [languages, setLanguages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const client = new clientConfig();
  const { state } = useLocation();

  useEffect(() => {
    getProfile();
    getProfilePrimaryAddress();
  }, []);

  const getProfile = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfile,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfile !== null) {
          setPersonInfo(res.data.GetProfile);
          setFormValue({
            firstName: res.data.GetProfile.FirstName,
            middleName: res.data.GetProfile.MiddleName,
            lastName: res.data.GetProfile.LastName,
            phone: formatPhone(res.data.GetProfile.Phone),
          });
          setLanguages(res.data.GetProfile?.Languages);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };
  const getProfilePrimaryAddress = async () => {
    const profileId = await userProfileData();
    client
      .query({
        query: GetProfilePrimaryAddress,
        variables: { profileId: profileId },
      })
      .then((res: any) => {
        if (res.data.GetProfilePrimaryAddress !== null) {
          setAddress({
            streetAddress1: res.data.GetProfilePrimaryAddress?.StreetAddress1,
            streetAddress2: res.data.GetProfilePrimaryAddress?.StreetAddress2,
            city: res.data.GetProfilePrimaryAddress?.City,
            state: res.data.GetProfilePrimaryAddress?.State,
            zipCode: res.data.GetProfilePrimaryAddress?.ZipCode,
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  function formatPhoneNumber(e: any) {
    let _error: any = error;
    _error[e.target.name] = "";

    const str = e.target.value;
    const phoneNumber = str.replace(/[^\d]/g, "");

    if (phoneNumber.length < 4) {
      setFormValue({ ...formValue, [e.target.name]: phoneNumber });
      return;
    }

    if (phoneNumber.length < 7) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
      });
      return;
    }

    setFormValue({
      ...formValue,
      [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`,
    });
    return;
  }

  const setAddressData = (e: any) => {
    setAddress((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]:
          e.target.name !== "zipCode" ? e.target.value : formatZipcode(e),
      };
    });
    let _error: any = error;
    _error[e.target.name] = "";
  };
  const setProfile = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onChange = (event: any) => {
    let language: any;
    if (languages?.length) language = [...languages];
    else language = [];

    const index = language.indexOf(event.target.value);

    if (event.target.checked) {
      language.push(event.target.value);
    } else if (!event.target.checked) {
      language.splice(index, 1);
    }

    setLanguages(language);
    let _error = error;
    _error["languages"] = "";
    setError(_error);
    setError({ ...error });
  };

  const submitInfo = async () => {
    const profileId = await userProfileData();
    const variables = {
      ...personInfo,
      Id: profileId,
      FirstName: formValue.firstName,
      MiddleName: formValue.middleName?formValue.middleName:null,
      LastName: formValue.lastName,
      Phone: formValue.phone.replace(/[^0-9]/g, ""),
      Languages: languages,
    };
    client
      .mutate({ mutation: UpdateProfile, variables: variables })
      .then((res: any) => {
        if (res.data.UpdateProfile) {
          submitAddress();
        }
      })
      .catch((error: any) => {});
  };

  const submitAddress = async () => {
    const profileId = await userProfileData();
    const variables = {
      ProfileId: profileId,
      State: address.state,
      City: address.city,
      StreetAddress1: address.streetAddress1,
      ZipCode: address.zipCode,
      StreetAddress2: address.streetAddress2 ? address.streetAddress2 : null,
    };
    client
      .mutate({ mutation: UpsertProfilePrimaryAddress, variables: variables })
      .then((res: any) => {
        if (res.data.UpsertProfilePrimaryAddress) {
          setSuccess("Updated Profile Information");
          setTimeout(() => {
            setSuccess("");
          }, 5000);
        }
      })
      .catch((error: any) => {});
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      let _error = error;

      if (
          address.zipCode.length < 5 ||
          (address.zipCode.length > 5 && address.zipCode.length < 10) ||
          languages?.length == 0 ||
          formValue.phone.length < 14
      ) {
        if (address.zipCode === "") _error["zipCode"] = "Please provide ZIP Code.";
        else if (address.zipCode.length < 5 || (address.zipCode.length > 5 && address.zipCode.length < 10)) _error["zipCode"] = "ZIP Code has invalid number of characters.";
        if (languages?.length == 0) _error["languages"] = "Select at least one language.";
        if (formValue.phone === "") _error["phone"] = "Please provide Phone Number.";
        else if (formValue.phone.length < 14) _error["phone"] = "Phone has invalid number of characters.";
        setError(_error);
        setError({ ...error });
        return;
      } else {
        _error["zipCode"] = "";
        _error["languages"] = "";
        _error["phone"] = "";
        setError(_error);
        setError({ ...error });
      }

      submitInfo();
    }

    setValidated(true);
  };
  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          {loading ? (
            <Loading />
          ) : (
            <Col lg={10} className="content-box py-5  px-lg-5">
              <Breadcrumb className="customBreadcrumb mt-0">
                <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                  Account Settings
                </Breadcrumb.Item>

                <Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
              </Breadcrumb>
              <Row className="mb-3">
                <Col lg={8}>
                  <h4 className="text-main-dark ff-Montserrat fw-bold mb-3">
                    Edit Profile
                  </h4>
                  <h6 className="text-main-dark ff-Montserrat fw-bold">
                    Name, Address, & Phone
                  </h6>
                </Col>
              </Row>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        required
                        value={formValue.firstName}
                        name="firstName"
                        onChange={setProfile}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide First Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={formValue.middleName}
                        name="middleName"
                        onChange={setProfile}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        required
                        value={formValue.lastName}
                        name="lastName"
                        onChange={setProfile}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Last Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        required
                        value={address.streetAddress1}
                        name="streetAddress1"
                        onChange={(e) => setAddressData(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Street Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group controlId="">
                      <Form.Label>Street Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.streetAddress2}
                        name="streetAddress2"
                        onChange={(e) => setAddressData(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        required
                        value={address.city}
                        name="city"
                        onChange={(e) => setAddressData(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="">
                      <Form.Label>State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        required
                        value={address.state}
                        name="state"
                        onChange={(e) => setAddressData(e)}
                      >
                        <option value="">- </option>
                        {US_STATES.map((state) => {
                          return (
                            <option value={state.abbreviation}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="">
                      <Form.Label>ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={address.zipCode}
                        name="zipCode"
                        onChange={(e) => setAddressData(e)}
                        isInvalid={!!error.zipCode}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.zipCode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) => {
                          formatPhoneNumber(e);
                        }}
                        isInvalid={!!error.phone}
                        name="phone"
                        required
                        value={formValue.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row className="mb-3">
                <h6 className="text-main-dark ff-Montserrat fw-bold my-3">
                  Preferred Languages
                </h6>
                <Col md={2}>
                  <Form.Check
                    type="checkbox"
                    label="English"
                    value="English"
                    onChange={onChange}
                    checked={languages?.includes("English")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Hindi"
                    value="Hindi"
                    onChange={onChange}
                    checked={languages?.includes("Hindi")}
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    type="checkbox"
                    label="Spanish"
                    value="Spanish"
                    onChange={onChange}
                    checked={languages?.includes("Spanish")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="French"
                    value="French"
                    onChange={onChange}
                    checked={languages?.includes("French")}
                  />
                </Col>
                <Col md={3}>
                  <Form.Check
                    type="checkbox"
                    label="Chinese (Mandarin)"
                    value="Chinese"
                    onChange={onChange}
                    checked={languages?.includes("Chinese")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Vietnamese"
                    value="Vietnamese"
                    onChange={onChange}
                    checked={languages?.includes("Vietnamese")}
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    type="checkbox"
                    label="Korean"
                    value="Korean"
                    onChange={onChange}
                    checked={languages?.includes("Korean")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Other"
                    value="Other"
                    onChange={onChange}
                    checked={languages?.includes("Other")}
                  />
                </Col>
              </Row>
              {error.languages && <Message type="error" message={error.languages}></Message>}
              <Row>
                <Col md={8}></Col>
                <Col md={4}>
                  <div className="d-flex justify-content-end align-items-center">
                    {success && (
                      <Message type="success" message={success}></Message>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end align-items-center">
                <button onClick={handleSubmit} className="btn-continue">
                  Save Changes
                </button>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default EditProfile;
