import React, { useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import Modal from "react-bootstrap/Modal";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { useNavigate, useLocation } from "react-router-dom";
import { userAccountData } from "../../Common/profileInfo";
import { clientConfig } from "../../awsClientConfig";
import { GetPaymentMethodsByAccount } from "../../queries/GetPaymentMethodsByAccount";
import { UpdatePaymentMethod } from "../../queries/UpdatePaymentMethod";
import { Alert } from "react-bootstrap";
import Loading from "../../Common/Loading";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

function CreditCardsOnFile() {
  const navigate = useNavigate();
  const client = new clientConfig();
  let { state } = useLocation();
  const [savedCards, setSavedCards] = useState<any>([]);
  const [cardSelected, setCardSelected] = useState<any>();
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseView = () => setView(false);
  const handleShowView = () => setView(true);

  useEffect(() => {
    getCardsSaved();
  }, []);

  const getCardsSaved = async () => {
    const AccountId = await userAccountData();
    client
      .query({
        query: GetPaymentMethodsByAccount,
        variables: { AccountId: AccountId, Domain: "ProtectionPlan" },
      })
      .then((res: any) => {
        setSavedCards(res.data.GetPaymentMethodsByAccount.PaymentMethods);
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const checkDateValidity = (cardCheck: any) => {
    const b = cardCheck.CcExpMonth;
    const c = cardCheck.CcExpYear;
    var d1 = new Date(c, +b - 1);
    var today = new Date();
    if (d1 > today) {
      return true;
    } else {
      return false;
    }
  };

  const makeCardDefault = (savedCard: any) => {
    setCardSelected(savedCard);
    handleShow();
  };

  const makeCardDeactivate = (savedCard: any) => {
    setCardSelected(savedCard);
    handleShowView();
  };
  const saveDefaultCard = () => {
    handleClose();
    const variables = {
      ...cardSelected,
      Default: true,
    };
    client
      .mutate({ mutation: UpdatePaymentMethod, variables: variables })
      .then((res: any) => {
        if (res.data.UpdatePaymentMethod) {
          setSaved(true);
          getCardsSaved();
          setTimeout(() => {
            setSaved(false);
          }, 5000);
        }
      })
      .catch((error: any) => {});
  };

  const saveDeactivateCard = () => {
    handleCloseView();
    const variables = {
      ...cardSelected,
      Active: false,
    };
    client
      .mutate({ mutation: UpdatePaymentMethod, variables: variables })
      .then((res: any) => {
        if (res.data.UpdatePaymentMethod) {
          setSaved(true);
          getCardsSaved();
          setTimeout(() => {
            setSaved(false);
          }, 5000);
        }
      })
      .catch((error: any) => {});
  };

  return (
    <>
      <Container className="mb-5 creditcard">
        <Row className="justify-content-center g-2">
          <Col lg={10} className="content-box py-5  px-lg-5">
            <Breadcrumb className="customBreadcrumb mt-0">
              <Breadcrumb.Item onClick={() => navigate("/dashboard")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate("/account-settings", { state })}>
                Account Settings
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Payment Information</Breadcrumb.Item>

              {/* <Breadcrumb.Item active>Credit Cards on File</Breadcrumb.Item> */}
            </Breadcrumb>
            <div className="d-flex justify-content-between">
              <h4 className="text-main-dark ff-Montserrat fw-bold mb-1">
                Credit Cards on File
              </h4>
              <span
                onClick={() => {
                  navigate("/account-settings-payment-add");
                }}
                className="material-blue fw-bold ff-Montserrat cursor-pointer"
              >
                + Add New Payment Method
              </span>
            </div>
            <Row>
              <Col md={8}></Col>
              {saved && (
                <Col lg={4} className="text-center">
                  <Alert variant="success">
                    <FaCheckCircle className="text-success me-2 fs-6" />
                    Your changes have been saved.
                  </Alert>
                </Col>
              )}
            </Row>

           {loading ?
            <Loading /> :
            <Row className="g-2 mt-3">
              {savedCards?.map((savedCard: any) => {
                return (
                  <Col lg={4} className="d-flex align-items-stretch">
                    <Card className="text-left border-0 info-bg w-100">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <span className="flex-grow-1">{(savedCard.Type).replace(/([a-z])([A-Z])/, '$1 $2')} </span>

                        {savedCard.Default && checkDateValidity(savedCard) && (
                          <>
                            <Badge pill bg="success me-2">
                              Default
                            </Badge>
                            {/* <Card.Link className="text-decoration-none text-muted">
                              <FaAngleRight />
                            </Card.Link> */}
                          </>
                        )}
                        {!savedCard.Default && checkDateValidity(savedCard) && (
                          <>
                            <Badge
                              pill
                              bg="secondary me-2"
                              className="cursor-pointer"
                              onClick={() => {
                                makeCardDefault(savedCard);
                              }}
                            >
                              Make Default
                            </Badge>
                            {/* <Card.Link className="text-decoration-none text-muted">
                              <FaAngleRight />
                            </Card.Link> */}
                          </>
                        )}
                      </Card.Header>
                      <Card.Body className=" px-lg-3">
                        <Table className="risk-report">
                          <tbody>
                            <tr>
                              <td>Service:</td>
                              <td className="fw-bold">{savedCard.CcType}</td>
                            </tr>
                            <tr>
                              <td> Number:</td>
                              <td className="fw-bold">
                                **** {savedCard.CcLastFourDigits}
                              </td>
                            </tr>
                            <tr>
                              <td>Expiration:</td>
                              {checkDateValidity(savedCard) ? (
                                <td className="fw-bold">
                                  {" "}
                                  {savedCard.CcExpMonth}/{savedCard.CcExpYear}
                                </td>
                              ) : (
                                <td
                                  className="fw-bold"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {savedCard.CcExpMonth}/{savedCard.CcExpYear}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                      <Card.Footer className="text-muted">
                        {checkDateValidity(savedCard) ? (
                          <>
                            <Card.Link
                              onClick={() => {
                                navigate("/account-settings-billing", {
                                  state: { paymentId: savedCard.Id },
                                });
                              }}
                              className="text-decoration-none cursor-pointer"
                            >
                              Change Billing Address
                            </Card.Link>
                            {!savedCard.Default && (
                              <Card.Link
                                onClick={() => {
                                  makeCardDeactivate(savedCard);
                                }}
                                className="text-decoration-none cursor-pointer"
                              >
                                Deactivate Card
                              </Card.Link>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
            </Row>}
          </Col>
        </Row>
      </Container>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h5 style={{ textAlign: "center" }}>
            Are You Sure You Want to Change Your Default Payment Method?
          </h5>
          <div className="d-block mx-auto  text-center">
            <button className="btn-continue mt-4 mb-3" onClick={saveDefaultCard}>
              Yes, Change Default
            </button>
            <span
              className="color-primary  text-decoration-underline d-block mx-auto fw-bold ff-Montserrat cursor-pointer"
              onClick={handleClose}
            >
              No, Cancel Change
            </span>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={view} onHide={handleCloseView}>
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose onClick={handleCloseView} />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h5 style={{ textAlign: "center" }}>
            Are You Sure You Want to Deactivate This Card?
          </h5>
          <div className="d-block mx-auto  text-center">
            <button className="btn-continue mt-4 mb-3" onClick={saveDeactivateCard}>
              Yes, Deactivate Card
            </button>
            <span
              className="color-primary  text-decoration-underline d-block mx-auto fw-bold ff-Montserrat cursor-pointer"
              onClick={handleCloseView}
            >
              No, Cancel Change
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreditCardsOnFile;
