import { useEffect, useState } from "react";
import "./requestcenter.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Auth } from "aws-amplify";
import { Buffer } from "buffer";
import { TpoIframeUrlType } from "../../Common/CommonLayout";

function PortalRequests() {
  const { tpoIframeUrl, setTpoIframeUrl } = useOutletContext<TpoIframeUrlType>();
  const [iframeKey, setIframeKey] = useState(0);
  const [requestCenterUrl, setRequestCenterUrl] = useState("");
  const [uploadCenterUrl, setUploadCenterUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_REQUEST_CENTER_ALLOWED !== "true") {
      navigate("/dashboard");
    }

    const getJWTToken = async () => {
      Auth.currentAuthenticatedUser()
        .then((res) => {
          const email = res.attributes["email"];
          let jwt = async () =>
            (await Auth.currentSession()).getIdToken().getJwtToken();
          jwt().then((token) => {
            let loginUrl = `${process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL}/one-web/login?Token=${token}&Email=${Buffer.from(email).toString("base64")}`;
            setTpoIframeUrl(loginUrl);
            setRequestCenterUrl(loginUrl);
            setUploadCenterUrl(`${process.env.REACT_APP_LEGACY_CLIENT_PORTAL_URL}/portal/files`);
          });
        })
    }

    getJWTToken();
    }, []);

  const handleTabChange = (tabKey: string | null) => {
      switch (tabKey) {
        case "0":
          setTpoIframeUrl(requestCenterUrl);
            break;
        case "1":
          setTpoIframeUrl(uploadCenterUrl);
            break;
      }
      setIframeKey((prevKey) => prevKey + 1);
  };

    const navigationHandler = (event: any) => {
      event.preventDefault();
      navigate("/account-settings-documents");
    }
    return (
      <>
        <Container className="mb-5" style={{ height: "85vh" }}>
          <Row className="justify-content-center py-1 h-100">
            <Col lg={10} className="content-box py-4 px-5 px-lg-4 h-100">
              {tpoIframeUrl &&
                <>
                  <Tabs className="border-0 iframe-tpo" onSelect={handleTabChange} defaultActiveKey={0}>
                    <Tab eventKey={0} title="Request Center"></Tab>
                    <Tab eventKey={1} title="Documents"></Tab>
                  </Tabs>
                  <iframe
                    key={iframeKey}
                    id="portal"
                    className="w-100 rounded"
                    style={{ height: "75vh" }}
                    title="Portal Request Center"
                    allowFullScreen
                    src={tpoIframeUrl}
                  ></iframe>
                </>
              }
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default PortalRequests;
