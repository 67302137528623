import React, { useState } from "react";
import BusinessInformation from "./businessInformatiom";
import Insurance from "./insurance";
import { Provider } from "./PartnerEnrollmentFormContext";
import PartnerExperience from "./partnerExperience";
import PartnerLanguage from "./partnerLanguage";
import PartnerProgramAccount from "./partnerProgramAccount";
import PartnerSoftware from "./partnerSoftware";
import ProfessionInfo from "./professionalInfo";
import StoreFrontQuestion from "./storeFrontQuestion";
import DefaultMailingAddress from "./DefaultMailingAddress";
import ApplicationStatus from "./applicationStatus";
import PartnerFinished from "./partnerFinished";
import TaskRiskReport from "./taskRiskReport";
import Signature from "./signature";
import TaxInformatioAuthorization from "./taxInformationAuthorization";
import { TaxProfessional, IntialtaxProfessional } from './PartnerEnrollmentFormContext'

const renderStep = (step: number, completedPerecent: number) => {
  switch (step) {
    case 0:
      return <PartnerProgramAccount />;
    case 1:
      return <BusinessInformation progressPercent={completedPerecent} />;
    case 2:
      return <StoreFrontQuestion progressPercent={completedPerecent} />;
    case 3:
      return <DefaultMailingAddress progressPercent={completedPerecent} />;
    case 4:
      return <ProfessionInfo progressPercent={completedPerecent} />;
    case 5:
      return <Insurance progressPercent={completedPerecent} />;
    case 6:
      return <PartnerExperience progressPercent={completedPerecent} />;
    case 7:
      return <PartnerLanguage progressPercent={completedPerecent} />;
    case 8:
      return <PartnerSoftware progressPercent={completedPerecent} />;
    case 9:
      return <ApplicationStatus progressPercent={completedPerecent} />
    case 10:
      return <TaskRiskReport progressPercent={completedPerecent}/>;
    case 11:
      return <Signature progressPercent={completedPerecent} />;
    case 12:
      return <TaxInformatioAuthorization progressPercent={completedPerecent} />;
    case 13:
      return <PartnerFinished />;
    default:
      return null;
  }
};

const PartnerEnrollmentForm = () => {
  const [taxProfessionalId, setTaxProfessionalId] = useState("");
  const [enrollmentId, setEnrollmentId] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [taxProfessional, setTaxProfessional] = useState<TaxProfessional>(IntialtaxProfessional);
  const [applicationStatus, setApplicationStatus] = useState("")
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [nextFormId, setNextFormId] = useState("");

  const next = () => {
    if (currentStep === 14) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const NextEnrollmentAction = (action: string) => {
    if(action === 'CompleteBusinessInformation') {
      setCurrentStep(1)
    }

    if(action === 'CompleteProfessionalInformation') {
      setCurrentStep(4)
    }

    if(action === 'WaitForApplicationApproval') {
      setCurrentStep(9)
    }
    
    if(action === 'CompleteServiceAgreement') {
      setCurrentStep(9)
    }

    if(action === 'CompleteFormW9') {
      setCurrentStep(12)
    }

  }

  const completedPerecent = (currentStep / 13) * 100;
  return (
    <Provider
      value={{
        s3key,
        setNextFormId,
        nextFormId,
        setS3Key,
        s3Bucket,
        setS3Bucket,
        next,
        enrollmentId,
        setEnrollmentId,
        organizationId,
        setOrganizationId,
        setTaxProfessional,
        taxProfessional,
        taxProfessionalId,
        setTaxProfessionalId,
        applicationStatus,
        setApplicationStatus,
        NextEnrollmentAction,
      }}
    >
      <main>{renderStep(currentStep, completedPerecent)}</main>
    </Provider>
  );
};
export default PartnerEnrollmentForm;
