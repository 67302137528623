import React, { useState } from "react";
import Message from "../Message";

export interface ObjectFile {
  name: string;
  url: string;
  type: string;
}
interface Props {
  onChange: (arg: any) => void;
  showFiles?: boolean;
  acceptPDF?:boolean;
  acceptOtherDocs?:boolean;
  maxFileSize?: number;
}

const DragDropFiles = ({ onChange,acceptPDF=true, acceptOtherDocs=false, maxFileSize=5 }: Props) => {
  const [sizeError, setSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const getObject = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      if((event.target.files[0].size / 1024) > (1024*maxFileSize)) {
        setSizeError(true);
        setTimeout(() => {
          setSizeError(false);
        }, 3000);
        return;
      }
      const fileType = event.target.files[0].type;
      const fileCanProcess = acceptOtherDocs?
                    (fileType.startsWith("image/") || fileType === 'application/pdf' 
                      || fileType === 'application/msword' || fileType.includes('officedocument.wordprocessingml')):
                      (acceptPDF?(fileType.startsWith("image/") || fileType === 'application/pdf')
                                :(fileType.startsWith("image/")));
      if(!fileCanProcess){
        setFileTypeError(true);
        setTimeout(() => {
          setFileTypeError(false);
        }, 3000);
        return;
      }
    }
    const fileObjectUrl = (event.target.files && event.target.files[0]) || null;
    onChange(fileObjectUrl);
  };


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          height: "200px",
          width: "100%",
          border: "1px dashed gray",
          position: "relative",
          backgroundColor: "#f4f9fc",
          borderRadius: "5px",
        }}
      >
        <input
          value={""}
          type="file"
          accept={acceptOtherDocs?".jpge,.png,.pdf,.jpg,.doc,.docx,.tiff":(acceptPDF?".jpge,.png,.pdf,.jpg":".jpge,.png,.jpg")}
          onChange={getObject}
          style={{
            height: "100%",
            width: "100%",
            opacity: 0,
            position: "absolute",
            left: 0,
            top: 0,
            cursor: "pointer",
          }}
        />
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../../assets/placeholder-img.png")}
            width="120"
            alt="browse"
            className="d-block mx-auto"
          />
          <h5 className="text-center browse">Browse for a file or drag here</h5>
          <p className="text-center supported-files">
            {acceptOtherDocs?'Supported File Types: JPG, JPG 2000, PNG, PDF, DOC, DOCX, TIFF.':
            (acceptPDF?'Supported File Types: JPG, JPG 2000, PNG, PDF.'
              :'Supported File Types: JPG, JPG 2000, PNG.')}
          </p>
        </div>
      </div>
      {sizeError && (
        <Message type="error" message={`File Size should be less than ${maxFileSize}MB`}/>
      )}
      {fileTypeError && (
        <Message type="error" message={
                acceptOtherDocs?'Supported File Types: JPG, JPG 2000, PNG, PDF, DOC, DOCX, TIFF.':
                (acceptPDF?'Supported File Types: JPG, JPG 2000, PNG, PDF.'
                :'Supported File Types: JPG, JPG 2000, PNG.')} />
      )}
    </div>
  );
};

export default DragDropFiles;
