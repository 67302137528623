import { createContext } from "react";
import { FilingStatusTypes, Request } from "../Constants";


export type MonthlyIncomeModel = {
    w2: string;
    k1: string;
    pensions: string;
    socialSecurity: string;
    otherIncome: string;
    otherIncometext: string;
}

export type vehicleExpensesModel = {
    year: number;
    make: string;
    model: string;
    ownership: string;
    monthlyPayment: string;
    remainingBalance: string;

}

export type MonthlyExpensesModel = {
    mortgage: string;
    rent: string;
    utilities: string;
    services: string;
    assetsMonthlyPayment: string;
    assetsOtherPayment: string;
    moreVehicles: string;
    vehicleExpenses: vehicleExpensesModel[]
}

export type MonthlyOtherExpensesModel = {
    studentLoanMonthlyPayment: string;
    studentLoanBalance: string;
    taxAgreementMonthlyPayment: string;
    taxAgreementBalance: string;
    healthInsurancePremium: string;
    healthExpense: string;
    dependentCare: string;
    dependentCareText: string;
    lifeInsurancePayment: string;
    lifeInsuranceTypes: string[];
    courtOrderedPayment: string;
    courtOrderedPaymentBalance: string;
    creditCardPayment: string;
    creditCardBalance: string;
    creditCardAvailableBalance: string;
    otherExpensesPayment: string;
    otherExpensesText: string;

}

export type AdditionalQuestionsModel = {
    installmentAgreementAmount: string;
    paymentDay: string;
}

export type IncomeExpenseDataModel = {
    peoplelivingInHouse: number;
    dependentsCount: number;
    maritalStatus: string;
    clientMonthlyIncomes: MonthlyIncomeModel;
    spouseMonthlyIncomes: MonthlyIncomeModel;
    clientMonthlyExpenses: MonthlyExpensesModel;
    spouseMonthlyExpenses: MonthlyExpensesModel;
    clientMonthlyOtherExpenses: MonthlyOtherExpensesModel;
    spouseMonthlyOtherExpenses: MonthlyOtherExpensesModel;
    clientAdditionalQuestions: AdditionalQuestionsModel;
    spouseAdditionalQuestions: AdditionalQuestionsModel;
}


export const InitialIncomeExpensesData:IncomeExpenseDataModel = {
    peoplelivingInHouse: 0,
    dependentsCount: 0,
    maritalStatus: FilingStatusTypes.MarriedFilingSeparately,
    clientMonthlyIncomes: {
        w2: '',
        k1: '',
        pensions: '',
        socialSecurity: '',
        otherIncome: '',
        otherIncometext: '',
    },
    spouseMonthlyIncomes: {
        w2: '',
        k1: '',
        pensions: '',
        socialSecurity: '',
        otherIncome: '',
        otherIncometext: '',
    },
    clientMonthlyExpenses: {
        mortgage: 'Yes',
        rent: '',
        utilities: '',
        services: '',
        assetsMonthlyPayment: '',
        assetsOtherPayment: '',
        moreVehicles: 'Yes',
        vehicleExpenses: []
    },
    spouseMonthlyExpenses: {
        mortgage: 'Yes',
        rent: '',
        utilities: '',
        services: '',
        assetsMonthlyPayment: '',
        assetsOtherPayment: '',
        moreVehicles: 'Yes',
        vehicleExpenses: []
    },
    clientMonthlyOtherExpenses: {
        studentLoanMonthlyPayment: '',
        studentLoanBalance: '',
        taxAgreementMonthlyPayment: '',
        taxAgreementBalance: '',
        healthInsurancePremium: '',
        healthExpense: '',
        dependentCare: '',
        dependentCareText: '',
        lifeInsurancePayment: '',
        lifeInsuranceTypes: [],
        courtOrderedPayment: '',
        courtOrderedPaymentBalance: '',
        creditCardPayment: '',
        creditCardBalance: '',
        creditCardAvailableBalance: '',
        otherExpensesPayment: '',
        otherExpensesText: '',
    },
    spouseMonthlyOtherExpenses: {
        studentLoanMonthlyPayment: '',
        studentLoanBalance: '',
        taxAgreementMonthlyPayment: '',
        taxAgreementBalance: '',
        healthInsurancePremium: '',
        healthExpense: '',
        dependentCare: '',
        dependentCareText: '',
        lifeInsurancePayment: '',
        lifeInsuranceTypes: [],
        courtOrderedPayment: '',
        courtOrderedPaymentBalance: '',
        creditCardPayment: '',
        creditCardBalance: '',
        creditCardAvailableBalance: '',
        otherExpensesPayment: '',
        otherExpensesText: '',
    },
    clientAdditionalQuestions: {
        installmentAgreementAmount: '',
        paymentDay: '',
    }
    ,
    spouseAdditionalQuestions: {
        installmentAgreementAmount: '',
        paymentDay: '',
    }
}



interface ContextState {
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    selectedRequest: Request
    incomeExpensesData: IncomeExpenseDataModel;
    setIncomeExpensesData: (data:IncomeExpenseDataModel) => void;
    defaultTab: string;
    setDefaultTab: (tabId: string)  => void;
}

const IncomeExpensesContext = createContext({} as ContextState);

export default IncomeExpensesContext;

export const { Provider, Consumer } = IncomeExpensesContext;