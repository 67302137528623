import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import AppProgressBar from '../../Common/progressBar';
import TaxPreparationServiceFormContext from './TaxPreparationServiceFormContext';
import { useNavigate } from 'react-router-dom';
import useEnrollmentStore from "../../store/EnrollmentStore";

const EnrollCompletedPage = () => {
  const {taxPreparationData, setTaxPreparationData, navigateToPage  } = useContext(TaxPreparationServiceFormContext);
  const { setTaxPrepEnrollment } = useEnrollmentStore();
  const navigate = useNavigate();

    const continueHandler = () => {
      setTaxPrepEnrollment({
        taxPrepEnrollmentCompleted: true,
        showTaxPrepNextStepsModal: true,
        paymentCompleted: true
      });
      navigate('/dashboard');
    }
    
    return (
        <>
        <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
             <AppProgressBar progressPercent={100} />
              <Row className="justify-content-center">
                <Col md={12}>
                  <h1 className="content-box-heading mb-4">
                   {'Thank You For Your Payment.'}
                  </h1>
                  <p className="content-text" style={{fontSize: '13px'}}>
                  {'Your case will now be assigned to a tax professional to review the details you provided and be in touch within 48 hours with a preliminary list of items they will need.'}</p>
                  <p className="content-text" style={{fontSize: '13px'}}>
                  {'In the meantime, please organize your tax documents so you have a head start.'}</p>
                  <Row className="justify-content-start my-4">
                    
                    <div className="d-flex justify-content-end mt-3 align-items-center">
                      <button
                        className={"btn-continue fw-bold"}
                        style={{padding: '25px 10px', fontSize: '14px', textAlign: 'center', lineHeight: '0px'}}
                        onClick={continueHandler}>
                       Finish
                      </button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      </>
    )
}

export default EnrollCompletedPage;