import gql from "graphql-tag";

export const UpdateTaxReturnProfile = gql`
  mutation UpdateTaxReturnProfile(
    $ClientProfileId: ID!
    $Id: String!
    $ProfileId: String
    $Spouse: Boolean!
    $FirstName: String!
    $MiddleName: String
    $LastName: String!
    $StreetAddress1: String!
    $StreetAddress2: String
    $City: String!
    $State: String!
    $ZipCode: String!
    $Email: String!
    $Phone: String!
  ) {
    UpdateTaxReturnProfile(ProfileId: $ClientProfileId, 
      Input: {TaxReturnProfile: {City: $City, FirstName: $FirstName, Id: $Id, LastName: $LastName, MiddleName: $MiddleName, 
        ProfileId: $ProfileId, Spouse: $Spouse, State: $State, StreetAddress1: $StreetAddress1, 
        StreetAddress2: $StreetAddress2, ZipCode: $ZipCode, Email: $Email, Phone: $Phone}}
    ) {
        TaxReturnProfile {
        City
        CreatedAt
        FirstName
        Id
        LastName
        MiddleName
        ProfileId
        Spouse
        State
        StreetAddress1
        StreetAddress2
        TaxReturnId
        UpdatedAt
        ZipCode
        }
    }
  }
`;