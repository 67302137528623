import React, { useContext } from "react";
import "../../pages/navbar.css";
import taxreport from "../../assets/icon-tax-report.svg";
import { Link, useNavigate } from "react-router-dom";
import TaxReportForm from "./TaxFormContext";
import { clientConfig } from "../../awsClientConfig";
import { InitiateEnrollment } from "../../queries/InitiateEnrollment";
import { userProfileData } from "../../Common/profileInfo";
import { Auth } from "aws-amplify";

function TaxReport() {
  const {
    setEnrollmentId,
    setNextFormId,
    NextEnrollmentAction,
    setS3Bucket,
    setS3Key,
  } = useContext(TaxReportForm);
  const client = new clientConfig();
  const navigate = useNavigate();

  const onInitiateTaxReport = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });

    const profileId = await userProfileData();
    const variables = {
      profileId: profileId,
      type: "Investigation",
    };
    client
      .mutate({ mutation: InitiateEnrollment, variables: variables })
      .then((res: any) => {
        if (res.data.InitiateEnrollment.Id) {
          setEnrollmentId(res.data.InitiateEnrollment.Id);
          setNextFormId(res.data.InitiateEnrollment.NextForm?.Id);
          setS3Bucket(res.data.InitiateEnrollment.NextForm?.S3Bucket);
          setS3Key(res.data.InitiateEnrollment.NextForm?.S3Key);
          NextEnrollmentAction(
            res.data.InitiateEnrollment?.NextEnrollmentAction
          );
        }
      })
      .catch((error: any) => {});
  };
  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5 px-lg-5">
            <div className="row px-lg-5 justify-content-center align-items-center">
              <div className="col-lg-7">
                <h1 className="content-box-heading mb-3">
                  Optima’s Tax Report{" "}
                </h1>
                <p className="content-text">
                  Worried about your federal tax liabilities, missing tax returns,
                  penalties & interest accruing, and current activities on your tax
                  account with the IRS?{" "}
                </p>
                <p className="content-text">
                  Get updates on key IRS notices, recent activities, audit alerts year by
                  year, and even your IRS collection risk level with our Tax Report.
                </p>
                <p className="content-text">
                  To use this service, you will need:
                </p>
                <ul className="content-text">
                  <li>Your Social Security Number</li>
                  <li>Mailing address from latest tax return</li>
                  <li>Government Photo ID</li>
                </ul>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={() => navigate("/dashboard")}
                  >
                    Skip Report
                  </span>

                  <button style={{
                   width: "200px", height: "45px"
                  }}
                    onClick={onInitiateTaxReport}
                    className="btn-continue"
                  >
                    Get Your Free Report
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  src={taxreport}
                  alt="Tax-report"
                  className="w-75 d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxReport;
