import { Row, Col } from "react-bootstrap";
import iconpp from "../../../assets/iconpp.svg";
import customerDashboardimg from "../../../assets/customer-dashboard-bg.png";



const ProtectionPlanCard:React.FC<{onProtected: () => void}> = (props) => {
    return (
        <Row className="px-4 justify-content-center align-items-center pt-5">
            <Col lg={6} sm={12} md={12}>
                <h1 className="text-dark mb-3 ff-Montserrat fs-4 fw-bold">
                Optima Tax Protection Plan
                </h1>

                <p className="content-text">
                With our tax protection plan, you can rest easy knowing your
                most valuable assets are secure. Plans include the following
                Protection and Monitoring Benefits:
                </p>
                <div className="no-display-mobile">
                <div className="d-flex my-3">
                <div className="me-4">
                <ul className="list_items_align">
                <li className="protection_plan mb-1" style={{whiteSpace: 'nowrap'}}>
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    $10MM Lifetime Audit Defense
                </li>
                <li className="protection_plan mb-1">
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    IRS Balance Monitoring
                </li>
                <li className="protection_plan">
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    Discounted Tax Resolution Services
                </li>
                </ul>
                </div>
                <div>
                <ul className="monthly-plan list_items_align">
                <li className="protection_plan mb-1">
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    Comprehensive Tax Risk Monitoring
                </li>
                <li className="protection_plan mb-1" style={{whiteSpace: 'nowrap'}}>
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    Filing Fraud Protection and Remediation
                </li>
                <li className="protection_plan">
                    <img
                    src={iconpp}
                    className="max-auto d-block mt-1"
                    alt="tax-report-ready"
                    />
                    IRS Notice Analysis
                </li>
                </ul>
                </div>
                </div>
                </div>
                <button className="btn-xl" style={{width: '60%', fontWeight: 'bold'}} onClick={props.onProtected}>
                Get Protected
                </button>
            </Col>
            <Col lg={6} className="text-center no-display-mobile">
                <img 
                src={customerDashboardimg}
                alt="Tax-report"
                className="img-fluid d-block mx-auto"
                />
            </Col>
      </Row>
    )
}

export default ProtectionPlanCard;