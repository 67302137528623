import React, { useState } from "react";
import { Auth } from "aws-amplify";
import "./navbar.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Message from "../components/Message";
import { clientConfig } from "../awsClientConfig";
import awsConfig from "../aws-exports";
import { CreatePartner } from "../queries/createPartnerProfile";

const errorField = {
  firstName: "",
  lastName: "",
  phone: "",
};

function ProfilePartners() {
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    phone: "",
    businessTitle: "Owner",
    taxPreparer: "Yes",
  });

  const client = new clientConfig();

  const navigate = useNavigate();

  const [error, setError] = useState(errorField);
  const [showSpinner, setShowSpinner] = useState(false);
  const [messageEnable, setMessageEnable] = useState(false);
  const [shareInfo, setShareInfo] = useState(false);

  const onChange = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    let _error: any = error;
    _error[e.target.name] = "";
  };

  function formatPhoneNumber(e: any) {
    const str = e.target.value;
    const phoneNumber = str.replace(/[^\d]/g, "");

    let _error: any = error;
    _error.phone = "";

    setError({ ...error });

    if (phoneNumber.length < 4) {
      setFormValue({ ...formValue, [e.target.name]: phoneNumber });
      return;
    }

    if (phoneNumber.length < 7) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`,
      });
      return;
    }
    if (phoneNumber.length < 11) {
      setFormValue({
        ...formValue,
        [e.target.name]: `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`,
      });
      return;
    }
  }

  const validateFormData = () => {
    let _error = error;
    const { firstName, lastName, phone } = formValue;
    if (!firstName) {
      _error["firstName"] = "First Name can not be empty";
    }
    if (!lastName) {
      _error["lastName"] = "Last Name Name can not be empty";
    }
    if (!phone) {
      _error["phone"] = "Phone can not be empty";
    }
    setError(_error);
    setError({ ...error });
    if (_error.firstName || _error.lastName || _error.phone) {
      return false;
    }

    return true;
  };

  const handleOnSubmit = async (event: any) => {
    console.log(formValue);
    event.preventDefault();
    event.stopPropagation();
    if (validateFormData()) {
      const {
        firstName,
        lastName,
        middleName,
        phone,
        businessTitle,
        taxPreparer,
      } = formValue;
      const variables = {
        email: (await Auth.currentAuthenticatedUser()).username,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName ? middleName : null,
        phone: phone.replace(/[^0-9]/g, ""),
        primaryCognitoUserId: (await Auth.currentAuthenticatedUser()).attributes
          .sub,
        primaryUserPoolId: awsConfig.Auth.userPoolId,
        Type: taxPreparer === "Yes" ? "TaxProfessionals" : null,
        title: businessTitle,
        CallEnabled: messageEnable,
        SmsEnabled: messageEnable,
        ConsentToShare: shareInfo,
      };
      setShowSpinner(true);
      client
        .mutate({ mutation: CreatePartner, variables: variables })
        .then(async(res: any) => {
          await Auth.currentAuthenticatedUser({ bypassCache: true });
          console.log(res);
          if (res.data.CreatePartner !== null) {
            navigate("/partner-enrollment");
          } else {
            setShowSpinner(false);
          }
        })
        .catch((error: any) => {
          if (error.errors[0].message.includes("phone")) {
            setError((prev) => ({
              ...prev,
              phone: "Must be a valid phone number",
            }));
          }
          if (error.errors[0].message.includes("LastName")) {
            setError((prev) => ({
              ...prev,
              lastName: "Must be a valid Last name. 'Last Name' must be between 2 and 255 characters",
            }));
          }
          setShowSpinner(false);
        });
    }
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <div className="mx-lg-5">
              <h1 className="content-box-heading">
                Tell Us About Yourself:
              </h1>
              <Form>
                <Row className="mb-3 justify-content-between">
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        onChange={onChange}
                        name="firstName"
                        type="input"
                      />
                      {error.firstName && (
                        <Message
                          type="error"
                          message={error.firstName}
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        onChange={onChange}
                        name="middleName"
                        type="input"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        onChange={onChange}
                        name="lastName"
                        type="input"
                      />
                      {error.lastName && (
                        <Message
                          type="error"
                          message={error.lastName}
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3 justify-content-between">
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        onChange={formatPhoneNumber}
                        name="phone"
                        type="text"
                        value={formValue.phone}
                      />
                      {error.phone && (
                        <Message type="error" message={error.phone}></Message>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Are you a Tax Preparer?</Form.Label>
                      <Form.Select
                        onChange={onChange}
                        name="taxPreparer"
                        aria-label="Default select example"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Business Title</Form.Label>
                      <Form.Select
                        onChange={onChange}
                        name="businessTitle"
                        aria-label="Default select example"
                      >
                        <option value="Owner">Owner</option>
                        <option value="AuthorizedRepresentative">Authorized Representative</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group className="mb-3 justify-item-center d-block">
                      <Form.Check
                        className="text-secondary"
                        checked={messageEnable}
                        onChange={() => setMessageEnable(!messageEnable)}
                        type="checkbox"
                        label="By checking this box, I expressly consent to receive marketing messages—including texts and calls made using an automatic telephone dialing system or pre-recorded or artificial voice messages—from Optima and its affiliates and tax services partners, directly or by third-parties acting on their behalf, and agree to the Automated Calls/Texts Terms."
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group className="mb-3 justify-item-center d-block">
                      <Form.Check
                        className="text-secondary"
                        checked={shareInfo}
                        onChange={() => setShareInfo(!shareInfo)}
                        type="checkbox"
                        label="By checking this box, I give Optima consent to share my personal information with its affiliates and tax services partners for the purposes of marketing to me."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>

              <div className="d-block text-center mx-auto">
                <button
                  disabled={showSpinner}
                  onClick={handleOnSubmit}
                  className="btn-continue"
                >
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProfilePartners;
