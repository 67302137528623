import gql from "graphql-tag";

export const CreatePartner = gql`
  mutation CreatePartner(
    $email: AWSEmail!
    $firstName: String!
    $lastName: String!
    $middleName: String
    $phone: AWSPhone!
    $primaryCognitoUserId: String!
    $primaryUserPoolId: String!
    $Type: PartnerType
    $title: PartnerTitle!
    $CallEnabled: Boolean
    $SmsEnabled: Boolean
    $ConsentToShare: Boolean
  ) {
    CreatePartner(
      Input: {
        PrimaryUserPoolId: $primaryUserPoolId
        PrimaryCognitoUserId: $primaryCognitoUserId
        Phone: $phone
        Email: $email
        FirstName: $firstName
        LastName: $lastName
        Title: $title
        Type: $Type
        MiddleName: $middleName
        CallEnabled: $CallEnabled
        SmsEnabled: $SmsEnabled
        ConsentToShare: $ConsentToShare
      }
    ) {
      ApplicationStatus
      FirstName
      Id
      LastName
      MiddleName
      Email
      OrganizationId
      Phone
      PrimaryCognitoUserId
      PrimaryUserPoolId
      PhoneExt
      RegistrationCodeId
      Title
      Email
    }
  }
`;
