import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Storage } from "aws-amplify";
import { FileUploader } from "react-drag-drop-files";
import Form from "react-bootstrap/Form";
import { FaTrashAlt } from "@react-icons/all-files/fa/FaTrashAlt";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { v4 as uuidv4 } from "uuid";
import { userProfileData } from "../../Common/profileInfo";
import { SetS3Config } from "../../awsClientConfig";
import Message from "../../components/Message";
import DragDropFiles from "../DragDropFiles";

const licenses = [
  {
    lable: "Enrolled Agent",
    value: "EnrolledAgent",
  },
  {
    lable: "Other (CTEC, CPA, Attorney, etc)",
    value: "Other",
  },
];

const FORM_ERROR = {
  licenses: "",
  ptin: "",
  licenseFiles: "",
};

function ProfessionInfo({ progressPercent = 0 }) {
  const { next, taxProfessional, setTaxProfessional } = useContext(
    PartnerEnrollmentFormContext
  );
  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);

  const [images, setimages] = useState<any>([]);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional.ptin) {
        error.ptin = "";
      } else {
        error.ptin = "Active PTIN # can not be empty";
      }

      if (taxProfessional.licenses.length >= 1) {
        error.licenses = "";
      } else {
        error.licenses = "Licenses can not be empty";
      }

      if (images.length >= 1) {
        error.licenseFiles = "";
      } else {
        error.licenseFiles = "Upload Your Licenses can not be empty";
      }

      setFromErros(error);
    };

    validateForm();
  }, [taxProfessional, images]);

  useEffect(() => {
    loadImages();
  }, []);

  const onCheckBoxChange = (event: any) => {
    const licenses = [...taxProfessional.licenses];
    const index = licenses.indexOf(event.target.name);

    if (event.target.checked) {
      licenses.push(event.target.name);
    } else if (!event.target.checked) {
      licenses.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ licenses: [...licenses] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onPinChange = (event: any) => {
    const ptin: string = event.target.value;

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ ptin: ptin },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const handleChange = async (file: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    const extension = getExtension(file.name);
    const ispdf = isPdf(file.name);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const profileId = await userProfileData();
    const uuid = uuidv4();

    try {
      await Storage.put(`Partners/${profileId}/Licenses/${uuid}.${extension}`, file, {
        contentType: contentType, // contentType is optional
        customPrefix: { public: "" },
        tagging: "ExternalClearance=Partner",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const loadImages = async () => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    const profileId = await userProfileData();
    try {
      await Storage.list(`Partners/${profileId}/Licenses/`, {
        // contentType: "image/png", // contentType is optional
        customPrefix: { public: "" },
        level: "public",
        // tagging: "ExternalClearance=Profile",
      }).then((res) => {
        console.log(res);
        getImages(res);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const getImages = async (url: any) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );
    let getAllImages: any = [];
    url?.results?.forEach((element: any) => {
      getAllImages.push(loadSingleImage(element));
    });
    Promise.all(getAllImages).then((values) => {
      setimages(values);
    });
  };

  const loadSingleImage = async (url: any) => {
    const ispdf = isPdf(url.key);
    let contentType = ispdf ? "application/pdf" : "image/png";
    const imageData: string = await Storage.get(url.key, {
      level: "public",
      contentType: contentType,
      customPrefix: { public: "" },
    });
    let imageObj = {
      src: imageData,
      key: url.key,
    };

    return imageObj;
  };

  function getExtension(filename: any) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  function isPdf(filename: any) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
    }
    return false;
  }

  const handleRemoveImage = async (imageUrl: string) => {
    SetS3Config(
      `documents-${process.env.REACT_APP_MODE}-optima-financial-group`,
      "public"
    );

    try {
      await Storage.remove(imageUrl, {
        // contentType: "image/png", // contentType is optional
        customPrefix: { public: "" },
        level: "public",
        // tagging: "ExternalClearance=Profile",
      }).then((res) => {
        console.log(res);
        loadImages();
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const onNext = () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
      return;
    }
    next();
  };

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">Professional Credentials </h1>
              <p className="content-text">
                What professional licenses/credentials do you currently hold?
                (Example E.A, CTEC)
              </p>
              <Form className="mt-3">
                <Row className="mb-3">
                  <Col md={8}>
                    {licenses.map((license) => {
                      const checked = taxProfessional.licenses.includes(
                        license.value
                      );
                      return (
                        <Form.Check
                          className="content-text fs-8"
                          name={license.value}
                          onChange={onCheckBoxChange}
                          type="checkbox"
                          label={license.lable}
                          checked={checked}
                        />
                      );
                    })}
                    {submited && formErrors.licenses && (
                      <Message
                        message={formErrors.licenses}
                        type="error"
                      ></Message>
                    )}
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label>Active PTIN #</Form.Label>
                      <Form.Control
                        onChange={onPinChange}
                        type="text"
                        placeholder=""
                        value={taxProfessional.ptin}
                      />
                      {submited && formErrors.ptin && (
                        <Message
                          message={formErrors.ptin}
                          type="error"
                        ></Message>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Col md={12}>
                <h6 className="ff-Montserrat color-primary">Upload Your Licenses</h6>
                <p className="content-text">
                  Please select and upload a copy of your licenses/credentials
                  that you currently hold.
                </p>
              </Col>

              <Col md={12} className="mb-3">
                {/* <FileUploader
                  handleChange={handleChange}
                  name="file"
                  mu
                  // types={fileTypes}
                  children={
                    <>
                      <img
                        src={require("../../assets/placeholder-img.png")}
                        width="120"
                        alt="browse"
                        className="d-block mx-auto"
                      />

                      <h5 className="text-center browse">
                        Browse for a file or drag here
                      </h5>
                      <p className="text-center privacy">
                        Supported File Types: JPG, JPG 2000, PNG, PDF
                      </p>
                    </>
                  }
                /> */}
                <DragDropFiles onChange={handleChange} />
              </Col>
              <div>
                <div style={{ flexWrap: "wrap"}} className="col px-lg-2 d-flex py-2">
                  {images?.map((image: any) => {
                    return (
                      <div
                        style={{
                          width: 215,
                          height: 142,
                          objectFit: "cover",
                          borderWidth: 2,
                          borderColor: "#ddd",
                          borderStyle: "solid",
                          textAlign: "center",
                          marginLeft: 10,
                          marginBottom: 50,
                        }}
                      >
                        {isPdf(image?.key) ? (
                            <iframe
                              src={image?.src}
                              title="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          ) : (
                            <img
                              src={image?.src}
                              alt="app-logo"
                              style={{
                                width: 212,
                                height: 138,
                                objectFit: "cover",
                                borderWidth: 2,
                                borderColor: "#ddd",
                              }}
                            />
                          )}
                        <a
                          href="/"
                          className="fw-bold text-decoration-none mt-2 delete-icon"
                          onClick={(e) => {
                            e.preventDefault();
                            handleRemoveImage(image?.key);
                          }}
                        >
                          <FaTrashAlt className="me-1" />
                          Delete
                        </a>
                      </div>
                    );
                  })}
                  {submited && formErrors.licenseFiles && (
                    <Message
                      message={formErrors.licenseFiles}
                      type="error"
                    ></Message>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
                <button onClick={onNext} className="btn-continue">
                  Submit
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProfessionInfo;
