import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnerEnrollmentFormContext from "./PartnerEnrollmentFormContext";

import Form from "react-bootstrap/Form";
import Message from "../../components/Message";
import AppProgressBar from "../../Common/progressBar";

const yearOfExp = [
  {
    label: "1-5",
    value: "5",
  },
  {
    label: "6-14",
    value: "10",
  },
  {
    label: "15+ years",
    value: "15",
  },
];

const taxReturnsPrepared = [
  {
    label: "100 -250",
    value: "100",
  },
  {
    label: "251-499",
    value: "350",
  },
  {
    label: "500-999",
    value: "750",
  },
  {
    label: "1000+",
    value: "1000",
  },
];

const col1TaxReturnForm = [
  {
    label: "1040",
    value: "Form1040",
  },
  {
    label: "1120",
    value: "Form1120",
  },
  {
    label: "1120s",
    value: "Form1120s",
  },
  {
    label: "1065",
    value: "Form1065",
  },
];

const col2TaxReturnForm = [
  {
    label: "1040x",
    value: "Form1040x",
  },
  {
    label: "940",
    value: "Form940",
  },
  {
    label: "941",
    value: "Form941",
  },
];

const col1ReturnSchedules = [
  {
    label: "Schedule A",
    value: "ScheduleA",
  },
  {
    label: "Schedule C",
    value: "ScheduleC",
  },
  {
    label: "Schedule D",
    value: "ScheduleD",
  },
  {
    label: "Schedule E",
    value: "ScheduleE",
  },
];

const col2ReturnSchedules = [
  {
    label: "Schedule EIC",
    value: "ScheduleEic",
  },
  {
    label: "Schedule F",
    value: "ScheduleF",
  },
  {
    label: "Schedule H",
    value: "ScheduleH",
  },
  {
    label: "Schedule J",
    value: "ScheduleJ",
  },
];

const col3ReturnSchedules = [
  {
    label: "Schedule R",
    value: "ScheduleR",
  },
  {
    label: "Schedule SE",
    value: "ScheduleSE",
  },
  {
    label: "Schedule 8821",
    value: "Schedule8821",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const FORM_ERROR = {
  returnForms: "",
  returnSchedules: "",
  irsAuditDesc: "",
  disciplinaryActionDesc: "",
};

function PartnerExperience({ progressPercent = 0 }) {
  const { next, taxProfessional, setTaxProfessional } = useContext(
    PartnerEnrollmentFormContext
  );

  const [formErrors, setFromErros] = useState(FORM_ERROR);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const error = { ...formErrors };

      if (taxProfessional.returnForms.length >= 1) {
        error.returnForms = "";
      } else {
        error.returnForms = "Tax Forms can not be empty";
      }

      if (taxProfessional.returnSchedules.length >= 1) {
        error.returnSchedules = "";
      } else {
        error.returnSchedules = "Return Schedules can not be empty";
      }

      if (taxProfessional.irsAudit) {
        if (taxProfessional.irsAuditDesc) {
          error.irsAuditDesc = "";
        } else {
          error.irsAuditDesc = "IRS Audit Desc Schedules can not be empty";
        }
      } else {
        error.irsAuditDesc = "";
      }

      if (taxProfessional.disciplinaryAction) {
        if (taxProfessional.disciplinaryActionDesc) {
          error.disciplinaryActionDesc = "";
        } else {
          error.disciplinaryActionDesc =
            "Disciplinary Action Desc can not be empty";
        }
      } else {
        error.disciplinaryActionDesc = "";
      }

      setFromErros(error);
    };

    validateForm();
  }, [taxProfessional]);

  const onBooleanChange = (event: any) => {
    const value = event.target.value === "1" ? true : false;

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ [event.target.name]: value },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onFormChange = (event: any) => {
    const returnForms = [...taxProfessional.returnForms];
    const index = returnForms.indexOf(event.target.name);

    if (event.target.checked) {
      returnForms.push(event.target.name);
    } else if (!event.target.checked) {
      returnForms.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ returnForms: [...returnForms] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onSchedulesChange = (event: any) => {
    const returnSchedules = [...taxProfessional.returnSchedules];
    const index = returnSchedules.indexOf(event.target.name);

    if (event.target.checked) {
      returnSchedules.push(event.target.name);
    } else if (!event.target.checked) {
      returnSchedules.splice(index, 1);
    }

    const newTaxProfessional = {
      ...taxProfessional,
      ...{ returnSchedules: [...returnSchedules] },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onDescChange = (event: any) => {
    const newTaxProfessional = {
      ...taxProfessional,
      ...{ [event.target.name]: event.target.value },
    };

    setTaxProfessional(newTaxProfessional);
  };

  const onNext = () => {
    setSubmited(true);
    const isAnyError = Object.values(formErrors).find((item) => Boolean(item));
    if (isAnyError) {
      return;
    }
    next();
  };
  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="row justify-content-center">
          <Col className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">Experience</h1>
              <p className="content-text">
                Tell us about your professional experience.
              </p>
              <Form className="mt-3">
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Label>Years of experience</Form.Label>

                    <Form.Select
                      value={taxProfessional.yearsOfExperience}
                      onChange={onDescChange}
                      name="yearsOfExperience"
                      aria-label="Default select example"
                    >
                      {yearOfExp.map((exp) => {
                        return <option value={exp.value}>{exp.label}</option>;
                      })}
                    </Form.Select>
                  </Col>
                  <Col md={4}>
                    <Form.Label>
                      Do you provide bookkeeping services?
                    </Form.Label>

                    <Form.Select
                      name="bookkeeping"
                      onChange={onBooleanChange}
                      aria-label="Default select example"
                      value={taxProfessional.bookkeeping ? "1" : "0"}
                    >
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Col>
                  <Col md={4}>
                    <Form.Label>
                      Do you provide tax resolution services?
                    </Form.Label>

                    <Form.Select
                      name="taxResolution"
                      onChange={onBooleanChange}
                      aria-label="Default select example"
                      value={taxProfessional.taxResolution ? "1" : "0"}
                    >
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </Form.Select>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Label>
                      How many tax returns did you prepare last year?
                    </Form.Label>

                    <Form.Select
                      value={taxProfessional.taxReturnsPrepared}
                      onChange={onDescChange}
                      aria-label="Default select example"
                      name="taxReturnsPrepared"
                    >
                      {taxReturnsPrepared.map((taxReturns) => {
                        return (
                          <option value={taxReturns.value}>
                            {taxReturns.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col md={6}>
                    <Form.Label>
                      Are you willing to take a tax preparation competency test?
                    </Form.Label>

                    <Form.Select
                      name="prepCompetency"
                      onChange={onBooleanChange}
                      aria-label="Default select example"
                      value={taxProfessional.prepCompetency ? "1" : "0"}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Select the tax forms that you have expertise in preparing.
                    Check all that apply.
                  </Form.Label>
                  <Col md={2}>
                    {col1TaxReturnForm.map((taxReturnForm) => {
                      const checked = taxProfessional.returnForms.includes(
                        taxReturnForm.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          name={taxReturnForm.value}
                          onChange={onFormChange}
                          type="checkbox"
                          label={taxReturnForm.label}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  <Col md={2}>
                    {col2TaxReturnForm.map((taxReturnForm) => {
                      const checked = taxProfessional.returnForms.includes(
                        taxReturnForm.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          name={taxReturnForm.value}
                          onChange={onFormChange}
                          type="checkbox"
                          label={taxReturnForm.label}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  {submited && formErrors.returnForms && (
                    <Message
                      message={formErrors.returnForms}
                      type="error"
                    ></Message>
                  )}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Select the return schedules that you have expertise in
                    preparing. Check all that apply.
                  </Form.Label>
                  <Col md={2}>
                    {col1ReturnSchedules.map((returnSchedules) => {
                      const checked = taxProfessional.returnSchedules.includes(
                        returnSchedules.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          name={returnSchedules.value}
                          onChange={onSchedulesChange}
                          type="checkbox"
                          label={returnSchedules.label}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  <Col md={2}>
                    {col2ReturnSchedules.map((returnSchedules) => {
                      const checked = taxProfessional.returnSchedules.includes(
                        returnSchedules.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          name={returnSchedules.value}
                          onChange={onSchedulesChange}
                          type="checkbox"
                          label={returnSchedules.label}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  <Col md={2}>
                    {col3ReturnSchedules.map((returnSchedules) => {
                      const checked = taxProfessional.returnSchedules.includes(
                        returnSchedules.value
                      );
                      return (
                        <Form.Check
                          className="content-text"
                          name={returnSchedules.value}
                          onChange={onSchedulesChange}
                          type="checkbox"
                          label={returnSchedules.label}
                          checked={checked}
                        />
                      );
                    })}
                  </Col>
                  {submited && formErrors.returnSchedules && (
                    <Message
                      message={formErrors.returnSchedules}
                      type="error"
                    ></Message>
                  )}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Has your practice ever been audited by the IRS?
                  </Form.Label>
                  <Col md={2}>
                    <Form.Select
                      name="irsAudit"
                      onChange={onBooleanChange}
                      aria-label="Default select example"
                      value={taxProfessional.irsAudit ? "1" : "0"}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Col>
                  <Col md={12} className="mt-3">
                    {taxProfessional.irsAudit && (
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>If yes, please describe</Form.Label>
                        <Form.Control
                          onChange={onDescChange}
                          name="irsAuditDesc"
                          as="textarea"
                          value={taxProfessional.irsAuditDesc}
                          rows={3}
                        />
                        
                        {submited && formErrors.irsAuditDesc && (
                          <Message
                            message={formErrors.irsAuditDesc}
                            type="error"
                          ></Message>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Have you ever been subject to disciplinary action by any
                    regulatory agency?
                  </Form.Label>
                  <Col md={2}>
                    <Form.Select
                      name="disciplinaryAction"
                      onChange={onBooleanChange}
                      aria-label="Default select example"
                      value={taxProfessional.disciplinaryAction ? "1" : "0"}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Form.Select>
                  </Col>
                  <Col md={12} className="mt-3">
                    {taxProfessional.disciplinaryAction && (
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>If yes, please describe</Form.Label>
                        <Form.Control
                          onChange={onDescChange}
                          name="disciplinaryActionDesc"
                          as="textarea"
                          rows={3}
                          value={taxProfessional.disciplinaryActionDesc}
                        />

                        {submited && formErrors.disciplinaryActionDesc && (
                          <Message
                            message={formErrors.disciplinaryActionDesc}
                            type="error"
                          ></Message>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Form>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/partner-enrollment">
                  Lets do this later...
                </a>
                <button onClick={onNext} className="btn-continue">
                  Continue
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PartnerExperience;
