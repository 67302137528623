import { Col, Form, InputGroup, Row } from "react-bootstrap";
import '../requestcenter.css';
import { MonthlyOtherExpensesModel } from "./IncomeExpensesContext";

const OtherExpensesForm:React.FC<{inputData:MonthlyOtherExpensesModel, 
                                onChangeValue: (event:any) => void}> = (props) => {

    const validateAmount = (event: any) => {
        const regex = new RegExp("^[0-9.]");
        const key = event.key;
        if (!regex.test(key) && !(key === 'Backspace')) {
            event.preventDefault();
            return false;
        }
    }

    return (
        <Form className="mt-2" id="clientInfoForm">
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Student Loans'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.studentLoanMonthlyPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="studentLoanMonthlyPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Total Balance</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.studentLoanBalance}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="studentLoanBalance"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'State/Local Tax Installment Agreement'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.taxAgreementMonthlyPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="taxAgreementMonthlyPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Total Balance</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.taxAgreementBalance}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="taxAgreementBalance"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Health Insurance Premium'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.healthInsurancePremium}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="healthInsurancePremium"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Out of Pocket Health Expense'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.healthExpense}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="healthExpense"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Child and/or Dependent Care'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.dependentCare}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="dependentCare"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Specify</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        value={props.inputData.dependentCareText}
                        onChange={(event) => props.onChangeValue(event)}
                        name="dependentCareText"
                    />
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Life Insurance'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.lifeInsurancePayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="lifeInsurancePayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">{'Type(s)'}</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Term"
                      name="Term"
                      onChange={(event) => props.onChangeValue(event)}
                      
                    />
                    <Form.Check
                      type="checkbox"
                      label="Whole"
                      name="Whole"
                      onChange={(event) => props.onChangeValue(event)}
                    />
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Court Ordered Payment(s)'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.courtOrderedPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="courtOrderedPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Total Balance</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.courtOrderedPaymentBalance}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="courtOrderedPaymentBalance"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={3} lg={3} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Credit Card'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.creditCardPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="creditCardPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Total Balance</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.creditCardBalance}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="creditCardBalance"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Available Balance</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.creditCardAvailableBalance}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="creditCardAvailableBalance"
                    type="text"
                    />
                    </InputGroup>
                </Col>
            </Row>
            <hr className="mt-2"/>
            <Row>
                <Col md={6} lg={6} className="d-flex align-items-center">
                    <Form.Label className="fs-9 fw-bold">{'Total Other Expenses'}</Form.Label>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Monthly Payment</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                    value={props.inputData.otherExpensesPayment}
                    onChange={(event) => props.onChangeValue(event)}
                    onKeyDown={(event) => validateAmount(event)}
                    name="otherExpensesPayment"
                    type="text"
                    />
                    </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                    <Form.Label className="fs-7 fw-bold my-0 py-0">Specify</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        value={props.inputData.otherExpensesText}
                        onChange={(event) => props.onChangeValue(event)}
                        name="otherExpensesText"
                    />
                </Col>
            </Row>
            <hr className="mt-2"/>
        </Form>
    )
}

export default OtherExpensesForm;