import gql from "graphql-tag";
export const GetReferralSummary = gql`
  query GetReferralSummary($partnerId: ID!) {
    GetReferralSummary(PartnerId: $partnerId) {
      ActiveCount
      InactiveCount
      NextPayoutAmount
      NextPayoutDate
      PartnerId
      PendingCount
      TotalCommissionEarned
    }
  }
`;
