import React, { useContext, useState } from "react";
import "../../pages/navbar.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TaxReportForm from "../taxReport/TaxFormContext";
import { clientConfig } from "../../awsClientConfig";
import { QualifyEnrollmentRequest } from "../../queries/QualifyEnrollmentRequest";
import AppProgressBar from "../../Common/progressBar";
import Loading from "../../Common/Loading";
import { QualifyEnrollmentResult } from "../../graphql/one-api/custom-mutations/Qualify";
import { QualifyingAnswerResult } from "../../API";

function QualifyBusiness({ progressPercent = 0 }) {
    const { next, enrollmentId,
      jointTaxReturn,
      businessReport,
      setBusinessReport,
      businessTaxReturn,
      setBusinessTaxReturn,
    } = useContext(TaxReportForm);
    const navigate = useNavigate();
    const [error, setError] = useState({
        JointTaxReturn: "",
        BusinessReport: "",
    });
    const [spinner, setSpinner] = useState(false);
    const client = new clientConfig();
    const validateForm = () => {
        let _error = true;
        if (jointTaxReturn === "") {
            setError({
                ...error,
                JointTaxReturn: "Joint Tax report Value is required",
            });
            _error = false;
        } else if (businessReport === "") {
            setError({
                ...error,
                BusinessReport: "Joint Tax report Value is required",
            });
            _error = false;
        }

        return _error;
    };

 const OnQualifySubmit = () => {
  setSpinner(true);
  const variables = {
   enrolmentID: enrollmentId,
   answeredQuestions: [
    { QuestionKey: "BusinessReport", AnswerKey: businessReport },
    { QuestionKey: "BusinessTaxReturn", AnswerKey: businessTaxReturn },
   ],
  };
  client
   .mutate({ mutation: QualifyEnrollmentResult, variables: variables })
   .then((res: any) => {
    if (res.data.QualifyEnrollmentRequest.Result === QualifyingAnswerResult.Qualify) {
     next();
    } else {
     navigate("/contact-service");
    }
    setSpinner(false);
   })
   .catch((error: any) => {
    console.log("error", error);
    setSpinner(false);
    navigate("/contact-service");
   });
 };

    return (
        <>
            <Container className="mt-3 mb-5">
                <Row className="row justify-content-center">
                    <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
                        {spinner ? (
                            <Loading />
                        ) : (
                            <div className="mx-lg-5">
                                <AppProgressBar progressPercent={progressPercent} />

                                <h1 className="content-box-heading mb-5">Do You Need a Report for Your Business?</h1>
                                <Form>
                                    <Row className="mb-1">
                                     <Col md={'auto'}>
                                       <Form.Group controlId="">
                                         <Form.Label className='fw-bold'>
                                          <p className="content-text mb-0">
                                            Are You interested in a Report for Your Business Entity?
                                          </p>
                                         </Form.Label>
                                      </Form.Group>
                                      </Col>
                                        <Col md={3}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Yes"
                                                onClick={() => setBusinessReport("Yes")}
                                                checked={businessReport === "Yes" ? true : false}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="No"
                                                onClick={() => {
                                                 setBusinessReport("No")
                                                 setBusinessTaxReturn("No")
                                                }}
                                                checked={businessReport === "No" ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                    {businessReport === "Yes" ?
                                     <>
                                      <Row className="mb-1">
                                         <Col md={'auto'}>
                                           <Form.Group controlId="">
                                             <Form.Label className='fw-bold'>
                                              <p className="content-text mb-0">
                                              Does your Business Entity file, need to file, or owe a balance related to 941, 940, 1065, 1120 or 1120S?
                                              </p>
                                             </Form.Label>
                                           </Form.Group>
                                         </Col>
                                         <Col md={2}>
                                           <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            onClick={() => setBusinessTaxReturn("Yes")}
                                            checked={businessTaxReturn === "Yes" ? true : false}/>
                                            <Form.Check
                                             style={{margin: 0}}
                                             inline
                                             type="radio"
                                             label="No"
                                             onClick={() => setBusinessTaxReturn("No")}
                                             checked={businessTaxReturn === "No" ? true : false}
                                            />
                                            </Col>
                                      </Row>
                                      <Row className="mb-3">
                                       <Col md={11} className="content-text">
                                        *Please be advised that your report will only identify account information
                                        associated with your social security number which will not display separate Business Entity information.
                                       </Col>
                                      </Row>
                                     </>
                                        : <>
                                     <Row className="mb-1 invisible">
                                      <Col md={'auto'}>
                                          <Form.Group controlId="">
                                              <Form.Label className='fw-bold'>
                                               Does your Business Entity file, need to file, or owe a balance related to 941, 940, 1065, 1120 or 1120S?
                                              </Form.Label>
                                          </Form.Group>
                                      </Col>
                                      <Col md={2}>
                                          <Form.Check
                                              inline
                                              type="radio"
                                              label="Yes"
                                              defaultChecked={false}
                                          />
                                          <Form.Check
                                              style={{margin: 0}}
                                              inline
                                              type="radio"
                                              label="No"
                                              defaultChecked={false}
                                          />
                                      </Col>
                                     </Row>
                                      <Row className="mb-3 invisible">
                                       <Col md={11}>
                                        *Please be advised that your report will only identify account information
                                        associated with your social security number which will not display separate Business Entity information.
                                       </Col>
                                      </Row>
                                     </>}
                                </Form>

                                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                      className="letsdolater"
                      onClick={() => navigate("/dashboard")}
                  >
                    Lets do this later...
                  </span>

                                    <button
                                        onClick={OnQualifySubmit}
                                        className="btn-continue"
                                        disabled={spinner}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default QualifyBusiness;
