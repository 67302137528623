import gql from "graphql-tag";

export const ValidatePromotionCode = gql`
    query ValidatePromotionCode($Code: String! $Type: PromotionCodeType!, $ProfileId: String!) {
        ValidatePromotionCode(Input: { Code: $Code Type: $Type, ProfileId: $ProfileId }) {
            Id
            Code
            Type
            Active
            ExpiresAt
            CreatedAt
            UpdatedAt   
        }
    }
`;