import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AppProgressBar from "../../Common/progressBar";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { useNavigate } from "react-router-dom";
import { SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { inRange } from "lodash";
import { motionContainer } from "./index";
import { motion } from "framer-motion";

function ServiceReport({ progressPercent = 0 }) {
  const { next,s3key, s3Bucket } = useContext(OrganicClientFormContext);
  const [agreed, setAgreed] = useState(false);

  const [agreement, setAgreement] = useState("");
  const navigate = useNavigate()

  const isPDF = s3key.includes(".pdf");

  useEffect(() => {
    fetchAgreement();
  }, []);

  let contentType = isPDF ?"application/pdf": "text/html"
  const fetchAgreement = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const serviceAgreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        // tagging: 'ExternalClearance=Profile',
         contentType: contentType,
      });
      if(isPDF){
        setAgreement(serviceAgreement);
      }else {
        const html = await fetch(serviceAgreement).then((res) => res.text());
        setAgreement(html);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const download = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const Agreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        download: true,
        contentType: contentType,
      });
      downloadBlob(Agreement.Body, "Service Agreement");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  function printPdf() {
    fetch(agreement)
    .then(async (response) => {
      return await response.arrayBuffer();
    })
    .then((result) => {
      const newBlob = new Blob([result], { type: "application/pdf" });
      const fileLink = window.URL.createObjectURL(newBlob);
      const iframe = document.createElement("iframe");
      iframe.src = fileLink;
      iframe.id = "print_pdf";
      iframe.name = "print_pdf";
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const windowVal:any= window
      windowVal.frames["print_pdf"].focus();
      windowVal.frames["print_pdf"].print();
    })
    .catch((error) => console.log("error", error));
  }

  function printImage() {

    if(isPDF){
      printPdf()
    } else {
      const tempHtml =
        "<html><head><scri" +
        "pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" +
        "pt></head><body onload='step1()'>\n" +
        "<div dangerouslySetInnerHTML={{ __html:" +
        agreement +
        " }} />" +
        "</body></html>";
      var Pagelink = "about:blank";
      var pwa: any = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(tempHtml);
      pwa.document.close();
    }
  }

  const onScrollHandler = (event:any) => {
    const target = event.target;
    const scrolledHeight = Math.trunc(target.scrollHeight - target.scrollTop);
    if(inRange(target.clientHeight, scrolledHeight-2, scrolledHeight+2)) {
      setAgreed(true);
    }
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={12} className="content-box py-5 px-lg-5">
            <div className="mx-lg-5">
              <motion.div {...motionContainer}>
              <AppProgressBar progressPercent={progressPercent} />
              <div className="text-end mt-4">
                <span className="letsdolater" onClick={printImage}>
                  <FontAwesomeIcon className="" icon={faPrint} /> Print
                </span>
                <span
                  className="letsdolater"
                  style={{ marginLeft: "10px" }}
                  onClick={download}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span>
              </div>
              <div className='mt-2' style={{ height: "100vh", overflow: "auto", border: "1px solid #ddd", borderRadius: "5px", padding: "20px" }} onScroll={onScrollHandler}>
                {isPDF? (<iframe
                  src={agreement}
                  title="app-logo"
                  style={{
                    width: '100%',
                    height: '95%',
                    objectFit: "cover",
                    border: "1px solid #ddd",
                  }}
                />):(
                  <div dangerouslySetInnerHTML={{ __html: agreement }} />
                )}
              </div>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <span className="letsdolater" onClick={()=>navigate("/dashboard")}>
                  Lets do this later...
                </span>
                <button onClick={next} disabled={!agreed} className="btn-continue">
                  Agree & Sign
                </button>
              </div>
              </motion.div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ServiceReport;
