import gql from 'graphql-tag';

export const UpdatePartner = gql`
  mutation UpdatePartner(
    $Id: ID!
    $Active: Boolean!
    $FirstName: String!
    $LastName: String!
    $MiddleName: String
    $Phone: AWSPhone!
    $Title: PartnerTitle!
    $CallEnabled: Boolean!
    $SmsEnabled: Boolean!
    $ConsentToShare: Boolean!
    $Type: PartnerType
  ) {
    UpdatePartner(
      Input: {
        Id: $Id
        Active: $Active
        FirstName: $FirstName
        LastName: $LastName
        MiddleName: $MiddleName
        Phone: $Phone
        Title: $Title
        CallEnabled: $CallEnabled
        SmsEnabled: $SmsEnabled
        ConsentToShare: $ConsentToShare
        Type: $Type
      }
    ) {
      Id
    }
  }
`;
