/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const identifiedNotice = /* GraphQL */ `
  subscription IdentifiedNotice($ProfileId: ID!) {
    IdentifiedNotice(ProfileId: $ProfileId) {
      AmountDue
      Description
      DueDate
      Error
      Name
      OverDue
      ProfileId
      RiskLevel
    }
  }
`;


export const CalculatedQuotePrice = /* GraphQL */ `
  subscription CalculatedQuotePrice(
    $ProfileId: ID!, 
    $QuoteId: ID!) {
      CalculatedQuotePrice(ProfileId: $ProfileId, QuoteId: $QuoteId) {
        ProfileId
        QuoteId
        QuotePrice
    }
  }
`;
