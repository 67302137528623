import gql from "graphql-tag";

export const GetLeadByEmail = gql`
  query GetLeadByEmail($email: AWSEmail!) {
    GetLeadByEmail(Email: $email) {
      AccountId
      Active
      ConsentToDisclose
      ConvertedAt
      CreatedAt
      Email
      FirstName
      Id
      IsConverted
      IsQualified
      LastName
      PartnerId
      Phone
      PhoneExt
      UpdatedAt
      TaxDebt
      RecommendedProtectionPlanId
      RecommendedProtectionPlan {
        UpdatedAt
        Title
        Name
        MonthlyPrice
        Active
        CreatedAt
        Description
        Id
        Link
      }
    }
  }
`;
