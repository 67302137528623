import React, { useEffect } from "react";
import "../../pages/navbar.css";
import "../styles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import useEnrollmentStore, { ProtectionPlanName } from "../../store/EnrollmentStore";

function TaxPreparationServiceEnroll() {
  const navigate = useNavigate();
  const { protectionPlanEnrollment, taxPrepEnrollment } = useEnrollmentStore();

 useEffect(() => {
  if (process.env.REACT_APP_TAX_PREPNETWORK_ALLOWED === 'false' ||
    protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName.Standard ||
    protectionPlanEnrollment?.subscriptionPlan === ProtectionPlanName?.Professional) {
   navigate("/dashboard")
  }
 }, []);

  return (
      <>
        <Container className="mt-3 mb-5">
          <Row className="justify-content-center">
            <Col md={10} className="d-flex tax-preparation-service-intro-bg py-5 px-lg-3">
              <Row className="px-lg-5 mt-5 pt-5">
                <Col lg={6}>
                  <h1 className="content-box-heading mb-3 mt-0" style={{color: 'white'}}>
                  Get Your Taxes Done Today
                  </h1>

                  <p className="content-text mb-5" style={{color: 'white'}}>
                  Get professional tax preparation services at the most trusted brand in Tax. Our network of licensed professionals are here to get your returns done quickly and accurately.
                  </p>
                  <div className="d-flex justify-content-between mt-5 pt-5 align-items-center">
                    <a className="letsdolater-taxprep mt-5" onClick={() => navigate("/dashboard")} style={{color: 'white'}}>Let's do this later...</a>
                    <button className="btn-continue mt-5" style={{width: '50%', fontWeight: 'bold'}} onClick={() => navigate('/tax-preparation-service-enroll')}>Get Started</button>
                  </div>
                </Col>
               
              </Row>
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default TaxPreparationServiceEnroll;
