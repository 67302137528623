import gql from "graphql-tag";
export const GetProtectionPlanSubscription = gql`
  query GetProtectionPlanSubscription($ProfileId: ID!) {
    GetProtectionPlanSubscription(ProfileId: $ProfileId) {
      Active
      Amount
      CancellationReason
      CreatedAt
      EndAt
      Id
      NextPaymentDate
      PaymentDay
      StartAt
      Status
      UpdatedAt
      Enrollment {
        ProtectionPlanName
      }
    }
  }
`;
