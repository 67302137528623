import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clientConfig } from "../../awsClientConfig";
import Loading from "../../Common/Loading";
import { userProfileData } from "../../Common/profileInfo";
import { InitiateEnrollment } from "../../queries/InitiateEnrollment";
import BillingInformation from "./billingInformation";
import EnrolledProtectionPlan from "./enrolledProtectionPlan";
import { Provider } from "./OrganicClientFormContext";
import PaymentDetails from "./paymentDetails";
import PersonalInformation from "./personalInformation";
import ProtectionPlanEligibility from "./protectionPlanEligibility";
import ProtectionPlanPricing from "./protectionPlanPricing";
import GovernmentPhotoId from "./governmentPhotoId";
import TaxInformationAuthorization from "./taxInformationAuthorization";
import ServiceReport from "./ServiceReport";
import Signature from "./signature";
import { GetProfileCaf } from "../../graphql/one-api/custom-queries/Profile";
import { motion } from "framer-motion";

export const motionContainer = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.2 } }
};

const renderStep = (step: number, completedPerecent: number) => {
  switch (step) {
    case 0:
      return <ProtectionPlanEligibility progressPercent={completedPerecent} />;
    case 1:
      return <ProtectionPlanPricing progressPercent={completedPerecent} />;
    case 2:
      return <PersonalInformation progressPercent={completedPerecent} />;
    case 3:
      return <ServiceReport progressPercent={completedPerecent} />;
    case 4:
      return <Signature progressPercent={completedPerecent} />;
    case 5:
     return <GovernmentPhotoId progressPercent={completedPerecent} />;
    case 6:
     return <TaxInformationAuthorization progressPercent={completedPerecent} />;
    case 7:
      return <BillingInformation progressPercent={completedPerecent} />;
    case 8:
      return <PaymentDetails progressPercent={completedPerecent} />;
    case 9:
      return <EnrolledProtectionPlan />;
    case 10:
      return <Loading />;
    default:
      return null;
  }
};

const OrganicClientForm = () => {
  const [enrollmentId, setEnrollmentId] = useState("");
  const [currentStep, setCurrentStep] = useState(11);
  const [nextFormId, setNextFormId] = useState("");
  const [s3key, setS3Key] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");
  const [finalPlan, SetFinalPlan] = useState();
  const [recommandedPlan, setRecommandedPlan] = useState();
  const [unavailableProtectionPlan, setUnavailableProtectionPlan] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [promoCode, setPromoCode] = useState<string | undefined>();
  const [promotionCodeType, setPromotionCodeType] = useState<string | undefined>();

  const client = new clientConfig();
  const navigate = useNavigate();
  useEffect(() => {
    InitiateOrganicEnrollment();
  }, []);

  const InitiateOrganicEnrollment = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    const profileId = await userProfileData();
    const variables = {
      profileId: profileId,
      type: "ProtectionPlan",
    };
    client
      .mutate({ mutation: InitiateEnrollment, variables: variables })
      .then((res: any) => {
        if (res.data.InitiateEnrollment.Id) {
          setEnrollmentId(res.data.InitiateEnrollment.Id);
          setNextFormId(res.data.InitiateEnrollment.NextForm?.Id);
          setS3Bucket(res.data.InitiateEnrollment.NextForm?.S3Bucket);
          setS3Key(res.data.InitiateEnrollment.NextForm?.S3Key);
          setRecommandedPlan(
            res.data.InitiateEnrollment?.RecommendedProtectionPlan
          );
         setUnavailableProtectionPlan(
          res.data.InitiateEnrollment?.UnavailableProtectionPlan
         )
          SetFinalPlan(res.data.InitiateEnrollment?.ProtectionPlan);
          NextEnrollmentAction(
            res.data.InitiateEnrollment?.NextEnrollmentAction
          );
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        navigate("/dashboard");
        setCurrentStep(0);
      });
  };

  const NextEnrollmentAction = (nextAction: string) => {
    if (nextAction === "AnswerQualifyingQuestions") {
      setCurrentStep(0);
    }
    if (nextAction === "SelectProtectionPlan") {
      setCurrentStep(1);
    }
    if (nextAction === "CompleteInformation") {
      setCurrentStep(2);
    }
    if (nextAction === "CompleteServiceAgreement") {
      setCurrentStep(3);
    }
    if (nextAction === "UploadIdentification") {
     setCurrentStep(5);
    }
    if (nextAction === "CompleteForm8821") {
     setCurrentStep(6);
    }
    if (nextAction === "CompletePayment") {
      setCurrentStep(7);
    }
    if(!nextAction){
      navigate("/dashboard");
    }
  };

  const next = () => {
    if (currentStep === 9) {
      setCurrentStep(0);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const previous = (index: number) => {
      setCurrentStep(index);
  };

  const completedPerecent = (currentStep / 9) * 100;
  return (
    <Provider
      value={{
        next,
        previous,
        enrollmentId,
        setEnrollmentId,
        nextFormId,
        setNextFormId,
        s3key,
        setS3Key,
        s3Bucket,
        setS3Bucket,
        finalPlan,
        SetFinalPlan,
        recommandedPlan,
        setRecommandedPlan,
        unavailableProtectionPlan,
        setUnavailableProtectionPlan,
        billingAddress,
        setBillingAddress,
        promoCode,
        setPromoCode,
        promotionCodeType,
        setPromotionCodeType
      }}
    >
      <main>{renderStep(currentStep, completedPerecent)}</main>
    </Provider>
  );
};
export default OrganicClientForm;
