import React, { useEffect, useMemo, useState } from "react";
import "../../pages/navbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Message from "../../components/Message";
import { FaSortAmountUpAlt } from "@react-icons/all-files/fa/FaSortAmountUpAlt";
import { FaSortAmountDown } from "@react-icons/all-files/fa/FaSortAmountDown";
import { FaSearchPlus } from "@react-icons/all-files/fa/FaSearchPlus";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft";
import { userProfileData } from "../../Common/profileInfo";
import { emailIsValid } from "../../functions/misc";
import { clientConfig } from "../../awsClientConfig";
import { GetLeadsByPartner } from "../../queries/GetLeadsByPartner";
import moment from "moment";
import orderBy from "lodash/orderBy";
import Modal from "react-bootstrap/Modal";
import IndividualClient from "./individualClientPage";
import { FaRegWindowClose } from "@react-icons/all-files/fa/FaRegWindowClose";
import { GetProtectionPlans } from "../../queries/GetProtectionPlans";
import { CreateLead } from "../../queries/CreateLead";
import ExclamationCircle from "../../assets/exclamation-circle-fill.svg";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const LEAD_STAUS = {
  Pending: "badge-pending",
  Enrolled: "badge-enrolled",
  Inactive: "badge-inactive",
};

const TableHeader = [
  {
    name: "CLIENT",
    key: "FirstName",
    sort: "asc",
  },
  {
    name: "REFERRED",
    key: "ReferredAt",
    sort: "asc",
  },
  {
    name: "ENROLLED",
    key: "EnrolledAt",
    sort: "asc",
  },
  {
    name: "PHONE",
    key: "Phone",
    sort: "asc",
  },
  {
    name: "EMAIL ADDRESS",
    key: "Email",
    sort: "asc",
  },
  {
    name: "STATUS",
    key: "LeadStatus",
    sort: "asc",
  },
];

type Lead = {
  FirstName: string;
  LastName: string;
  DangerFlag: boolean;
  LeadStatus: "Pending" | "Enrolled" | "Inactive";
  LeadId: string;
  Email: string;
  ReferredAt: string;
  EnrolledAt: string;
  Phone: string
  ProfileId: string
  TaxRiskReportRiskLevel: "Low" | "Medium" | "High" | null
};

function MyReferrals() {
  const client = new clientConfig();
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [nextMove, setNextMove] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLead, setCurrentLead] = useState<Lead | null>(null);
  const [sortHeaders, setSortHeaders] = useState(TableHeader);
  const [showAddModel, setShowAddModel] = useState(false);
  const [submited, setSubmited] = useState(false);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    checkBox1: false,
    checkBox2: false,
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [recommendedProtectionPlanId, setRecommendedProtectionPlanId] =
    useState();
 let { state } = useLocation();

 useEffect(() => {
  if (state !== null) {
   setCurrentLead(state);
   state = null;
  }
 }, [state]);

  useEffect(() => {
    validateFormData(
      profile.firstName,
      profile.lastName,
      profile.email,
      profile.phone
    );
  }, [profile]);

  const validateFormData = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) => {
    let _error = error;
    if (!email) {
      error["email"] = "Email can not be empty";
    } else if (!emailIsValid(email)) {
      error["email"] = "Email is invalid";
    } else {
      error["email"] = "";
    }
    if (!firstName) {
      error["firstName"] = "FirstName can not be empty";
    } else {
      error["firstName"] = "";
    }
    if (!lastName) {
      error["lastName"] = "LastName can not be empty";
    } else {
      error["lastName"] = "";
    }

    if (!phone) {
      error["phone"] = "Phone can not be empty";
    } else {
      error["phone"] = "";
    }
    setError(_error);
    setError({ ...error });
  };

  const handleOnChange = (event: any) => {
    const newProfile = { ...profile, [event.target.name]: event.target.value };
    setProfile(newProfile);
  };

  const handleOnCheck = (event: any) => {
    const newProfile = {
      ...profile,
      [event.target.name]: event.target.checked,
    };
    setProfile(newProfile);
  };

  const totalCountArray = useMemo(() => {
    let _totalCount = [];

    let movement = 0;

    if (totalCount <= 4) {
      movement = totalCount + 1;
    } else {
      movement = 4 + nextMove;
    }
    for (let i = nextMove; i < movement; i++) {
      _totalCount.push(i);
    }

    return _totalCount;
  }, [totalCount, nextMove]);

  useEffect(() => {
    const getPlans = async () => {
      const data:any = await client.query({
        query: GetProtectionPlans,
      });
      const protectionPartnerMonitoring = data.data.GetProtectionPlans.find(
        (plan: any) => plan.Name === "ProtectionPartnerMonitoring"
      );
      setRecommendedProtectionPlanId(protectionPartnerMonitoring.Id);
    };

    getPlans();
  }, []);

  useEffect(() => {
    const getLeadByPartner = async () => {
      if (!showAddModel) {
        const partnerId = await userProfileData();
        client
          .query({
            query: GetLeadsByPartner,
            variables: {
              PartnerId: partnerId,
              Limit: 10,
              NextToken: String(currentPage),
            },
          })
          .then((res: any) => {
            console.log("leads", res.data.GetLeadsByPartner);
            setLeads(res.data.GetLeadsByPartner.LeadSummaries);
            setTotalCount(Number(res.data.GetLeadsByPartner.TotalTokens));
          });
      }
    };
    getLeadByPartner();
  }, [currentPage, showAddModel]);

  const onLeadClick = (lead: Lead) => {
    console.log(lead);
    setCurrentLead(lead);
  };

  const goBack =() => {
    setCurrentLead(null);
  }

  const onSort = (key: string, sortMethod: "asc" | "desc") => {
    const headers = [...sortHeaders];

    headers.forEach((header) => {
      if (header.key === key) {
        header.sort = header.sort === "asc" ? "desc" : "asc";
      }
    });

    const sortLeads = orderBy(leads || [], [key], [sortMethod]);
    setLeads(sortLeads);
    setSortHeaders(headers);
  };

  const addReferral = async (event: any) => {
    const partnerId = await userProfileData();
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);

    const variables = {
      Email: profile.email,
      FirstName: profile.firstName,
      IsQualified: profile.checkBox1,
      LastName: profile.lastName,
      PartnerId: partnerId,
      Phone: profile.phone.replace(/[^\d]/g, ""),
      RecommendedProtectionPlanId: recommendedProtectionPlanId,
      ConsentToDisclose: profile.checkBox2,
    };

    client
      .mutate({
        mutation: CreateLead,
        variables,
      })
      .then((res: any) => {
        setProfile({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          checkBox1: false,
          checkBox2: false,
        });
        setSubmited(false);
        setShowAddModel(false);
      })
      .catch((err: any) => {
       if (err.errors[0].message.includes("Email is associated with an Account already.")) {
        let _error = error;
        error["email"] = "There is an account associated with this email address.";
        setError(_error);
        setError({ ...error });
        return;
       }
        if (
          err.errors[0].message.includes("Email is associated with an existing Lead.")
        ) {
          let _error = error;
          error["email"] = "Email is associated with an existing Lead.";
          setError(_error);
          setError({ ...error });
        }
      });
  };

  function formatPhoneNumber(str: any) {
    if (str) {
      const phoneNumber = str.replace(/[^\d]/g, "");
      if (phoneNumber.length < 4) {
        return phoneNumber;
      }

      if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      if (phoneNumber.length < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
      }
    }

    return ""
  }

  if (currentLead) {
    return <IndividualClient lead={currentLead} onBreadcrumbClick={goBack}/>;
  }

  return (
    <>
      <Container className="mt-3 mb-5">
        <Row className="justify-content-center">
          <Col md={10} className="content-box pt-5 pb-5 px-lg-5">
            <Row className="justify-content-center align-items-center mb-3">
              <Col lg={9} style={{ paddingLeft: 70 }}>
                <Breadcrumb className="customBreadcrumb mt-0">
                  <Breadcrumb.Item onClick={() => navigate("/partner-dashboard")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Referrals
                  </Breadcrumb.Item>
                </Breadcrumb>

                <h1 className="text-main-dark mb-3 ff-Montserrat fs-4 fw-bold">
                  My Referrals
                </h1>
                <p className="content-text">
                  Manage client referrals in one consolidated view to easily
                  understand their current enrollment status, notifications, and
                  view their latest Tax Risk Report.
                </p>
              </Col>
              <Col lg={3} className="text-end">
                <button
                  className="btn-xl"
                  style={{width: "240px"}}
                  onClick={() => setShowAddModel(!showAddModel)}
                >
                  + Add a Referral
                </button>
              </Col>
            </Row>
            <Table hover className="myReferral">
              <thead>
                <tr className="referral-tabel">
                  {sortHeaders.map((header) => {
                    return (
                      <th>
                        {header.name}
                        {header.sort === "asc" ? (
                          <span
                            style={{ marginLeft: 5 }}
                            onClick={() => onSort(header.key, "asc")}
                          >
                            <FaSortAmountUpAlt />
                          </span>
                        ) : (
                          <span
                            style={{ marginLeft: 5 }}
                            onClick={() => onSort(header.key, "desc")}
                          >
                            <FaSortAmountDown></FaSortAmountDown>
                          </span>
                        )}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead: Lead, index) => (
                  <AnimatePresence >
                    <tr>
                      <td className="py-3">
                        <motion.div key={lead.ReferredAt} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        <span
                          onClick={() => onLeadClick(lead)}
                          style={{ cursor: "pointer" }}
                          className="material-blue fs-8"
                        >{`${lead.FirstName} ${lead.LastName}`}</span>
                        </motion.div>
                      </td>
                      <td className="py-3">
                        <motion.div key={lead.ReferredAt} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {lead.ReferredAt
                          ? moment(lead.ReferredAt).format("MM/DD/YYYY")
                          : "-"}
                        </motion.div>
                      </td>
                      <td className="py-3">
                        <motion.div key={lead.EnrolledAt} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {lead.EnrolledAt
                          ? moment(lead.EnrolledAt).format("MM/DD/YYYY")
                          : "-"}
                        </motion.div>
                      </td>
                      <td className="py-3">
                        <motion.div key={lead.Phone} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                          {formatPhoneNumber(lead.Phone)}
                        </motion.div>
                      </td>
                      <td className="py-3">
                        <motion.div key={lead.Email} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                          {lead.Email}
                        </motion.div>
                        </td>
                      <td className="py-3">
                          <motion.span key={`${lead.DangerFlag}`} className="me-2 color-red" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                          {lead.DangerFlag ? (
                            <img
                              src={ExclamationCircle}
                              className="me-2 material-blue fs-6"
                              alt="Alert"
                            />
                          ) :
                            <img
                              src={ExclamationCircle}
                              className="me-2 material-blue fs-6"
                              alt="Alert"
                              style={{visibility:"hidden"}}
                            />
                          }
                          </motion.span>

                        <motion.span key={lead.ReferredAt} className={`${LEAD_STAUS[lead.LeadStatus]}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                          {lead.LeadStatus}
                        </motion.span>

                      </td>
                      <td className="py-3">
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <Form.Select>
                            {lead.TaxRiskReportRiskLevel && (
                              <option>09/15/2022 (Latest)</option>
                            )}
                          </Form.Select> */}
                          <motion.span
                            key={lead.ReferredAt}
                            onClick={() => onLeadClick(lead)}
                            className="material-blue ms-2"
                            initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                            <FaSearchPlus />
                          </motion.span>
                        </div>
                      </td>
                    </tr>
                  </AnimatePresence>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <button
                disabled={nextMove === 1}
                className="border-0 bg-white"
                onClick={() => {
                  setNextMove(nextMove - 1);
                }}
              >
                <FaAngleLeft
                  style={{ color: nextMove === 1 ? "#CFD8DC" : "#1589D2" }}
                />
              </button>
              {totalCountArray.map((count) => (
                <span
                  style={{
                    color: count === currentPage ? "#1589D2" : "#667380",
                  }}
                  onClick={() => setCurrentPage(count)}
                  className="p-2"
                  key={count}
                >
                  {count}
                </span>
              ))}
              <button
                disabled={totalCount <= 4 || nextMove + 3 === totalCount}
                onClick={() => {
                  setNextMove(nextMove + 1);
                }}
                className="border-0 bg-white"
              >
                <FaAngleRight
                  style={{
                    color:
                      totalCount <= 4 || nextMove + 3 === totalCount
                        ? "#CFD8DC"
                        : "#1589D2",
                  }}
                />
              </button>
            </div>
          </Col>

          <p className="text-muted text-center mt-3">
            All Referral information is updated daily. Please{" "}
            <a
              href="/partner-settings-contact"
              className="text-decoration-none fw-bold"
            >
              Contact Us
            </a>{" "}
            if you’d like to talk to an Optima account expert.
          </p>
        </Row>
      </Container>
      <Modal
        centered
        show={showAddModel}
        onHide={() => {
          setShowAddModel(false);
        }}
      >
        <Modal.Header className="ms-auto border-0 color-primary">
          <FaRegWindowClose
            onClick={() => {
              setShowAddModel(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-5">
          <h6 className="text-muted">Submit a Referral</h6>
          <p className="content-tex">
            Fill out your client’s contact information to refer them to Optima’s
            Protection Plan.
          </p>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="">
                <Form.Label>
                  First Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={profile.firstName}
                  name="firstName"
                  onChange={handleOnChange}
                  type="text"
                />

                {submited && error.firstName && (
                  <Message message={error.firstName} type="error"></Message>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="">
                <Form.Label>
                  Last Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={profile.lastName}
                  name="lastName"
                  onChange={handleOnChange}
                  type="text"
                />
                {submited && error.lastName && (
                  <Message message={error.lastName} type="error"></Message>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="">
                <Form.Label>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="email"
                  value={profile.email}
                  onChange={handleOnChange}
                  type="text"
                />
                {submited && error.email && (
                  <Message message={error.email} type="error"></Message>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="">
                <Form.Label>
                  Phone <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="phone"
                  value={formatPhoneNumber(profile.phone)}
                  onChange={handleOnChange}
                  type="text"
                />
                {submited && error.phone && (
                  <Message message={error.phone} type="error"></Message>
                )}
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked={profile.checkBox1}
                name="checkBox1"
                onChange={handleOnCheck}
                type="checkbox"
                label="I hereby represent and warrant that I have obtained the proper consent to disclose Client Information to Optima and its affiliates from the respective Client prior to submitting any Client Information contained in the Referral to Optima."
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                checked={profile.checkBox2}
                name="checkBox2"
                onChange={handleOnCheck}
                type="checkbox"
                label="I’ve reviewed the eligibility requirement with this client."
              />
            </Form.Group>
            <Form.Group as={Col} className="d-flex justify-content-end">
              <button onClick={addReferral} className="btn-continue">
                + Add a Referral
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyReferrals;
