import gql from "graphql-tag";

export enum FormStatus {
  Completed = "Completed",
  Pending = "Pending",
}

export const GetLatestFormsByProfile = gql`
  query GetLatestFormsByProfile($ProfileId: ID!, $Status: FormStatus) {
    GetLatestFormsByProfile(ProfileId: $ProfileId, Status: $Status) {
      Id
      TemplateId
      Name
      Status
      Active
      UpdatedAt
      Name
      S3Bucket
      S3Key
      S3VersionId
      ProfileForms {
        Owner
        ProfileId
        SignatureS3Bucket
        SignatureS3Key
        SignatureS3VersionId
        Id
        FormId
      }
    }
  }
`;
