import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MaintenanceLayout from "../layouts/MaintenanceLayout";

const MaintenancePage = () => {
  const navigate = useNavigate();

 useEffect(() => {
  if (process.env.REACT_APP_MAINTENANCE_MODE_ENABLE !== "true") {
   navigate("/");
  }
 }, []);

  return (
    <MaintenanceLayout>
      <PageBody>
          <Title className="text-center">
           We're going through scheduled <br />
           maintenance right now.
          </Title>
          <p className="content-text mt-2 text-center">
           Please come back later.</p>
      </PageBody>
    </MaintenanceLayout>
  );
};

export default MaintenancePage;

const PageBody = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Title = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-text-default);
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  text-align: center;
`;