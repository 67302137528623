import React, { useContext, useEffect, useState } from "react";

import "../../pages/navbar.css";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import PartnerEnrollmentFormContext from "../partnerEnrollment/PartnerEnrollmentFormContext";
import AppProgressBar from "../../Common/progressBar";
import { CompletePartnerForm } from "../../queries/CompletePartnerFrom";
import { userProfileData } from "../../Common/profileInfo";
import printJS from 'print-js'

function TaxInformatioAuthorization({ progressPercent = 0 }) {
  const { next, nextFormId, enrollmentId, s3key, s3Bucket } = useContext(
    PartnerEnrollmentFormContext
  );
  const client = new clientConfig();
  const [irsForm, setIRSForm] = useState("");

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const irsForm = await Storage.get(s3key, {
        customPrefix: { public: "" },
        level: "public",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onAgreeAndSign = async () => {
    const profileId = await userProfileData();
    const variables = {
      PartnerId: profileId,
      FormId: nextFormId,
      PartnerEnrollmentId: enrollmentId,
    };

    client
      .mutate({ mutation: CompletePartnerForm, variables: variables })
      .then((res: any) => {
        if (res.data.CompletePartnerForm) {
          next()
        }
      });
  };

  const printImage = () => {
    window.print();
  };
  const download = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const Agreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "image/png",
      });
      console.log(Agreement);
      downloadBlob(Agreement.Body, "Form W9");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }
  return (
    <>
      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <div className="mx-lg-5">
              <AppProgressBar progressPercent={progressPercent} />
              <h1 className="content-box-heading">
                Form W9 - Identification & Certification
              </h1>
              <div className="text-end">
                <span className="letsdolater" onClick={printImage}>
                  <FontAwesomeIcon className="" icon={faPrint} /> Print
                </span>
                <span
                  style={{ marginLeft: "10px" }}
                  className="letsdolater"
                  onClick={download}
                >
                  <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                </span>
              </div>
              <div>
                <img
                   id={"irsForm"}
                   className="section-to-print"
                  src={irsForm}
                  height="100%"
                  width="100%"
                  alt="Form**21"
                ></img>
              </div>

              <div className="d-flex justify-content-between mt-5 align-items-center">
                <a className="letsdolater" href="/">
                  Lets do this later...
                </a>
                <button onClick={onAgreeAndSign} className="btn-continue">
                  Click to Sign and Date
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxInformatioAuthorization;
