import gql from 'graphql-tag';

export const UpsertProfilePrimaryAddress = gql`
  mutation UpsertProfilePrimaryAddress(
    $ProfileId: ID!
    $City: String!
    $State: UsState!
    $StreetAddress1: String!
    $StreetAddress2: String
    $ZipCode: String!
  ) {
    UpsertProfilePrimaryAddress(
      Input: {
        State: $State
        City: $City
        ProfileId: $ProfileId
        StreetAddress1: $StreetAddress1
        ZipCode: $ZipCode
        StreetAddress2: $StreetAddress2
      }
    ) {
      City
      ProfileId
      State
      StreetAddress1
      StreetAddress2
      ZipCode
    }
  }
`;
