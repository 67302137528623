import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Message from "../components/Message";

interface FormError {
  passcode: string;
  newPasscode: string;
}

type Props = {
  email: string;
  setVerified: (data: any) => any;
};
const VerifyCodePage = ({ email, setVerified }: Props) => {
  const { t } = useTranslation("common");

  const [error, setError] = useState<FormError>({
    passcode: "",
    newPasscode: "",
  });
  const [submited, setSubmited] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [isresent, setIsResent] = useState(false);

  useEffect(() => {
    validateForm(passcode);
  }, [passcode]);

  const validateForm = (_passcode: string) => {
    let _error = error;
    if (!_passcode) {
      _error["passcode"] = t("verifyCodePage.error.passcode.empty");
    } else {
      _error["passcode"] = "";
    }
    setError(_error);
    setError({ ...error });
  };

  const handleInputPasscode = (e: any) => {
    setPasscode(e.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmited(true);
    if (error.passcode) {
      return;
    }

    setVerified(passcode);
  };

  const resendPasscode = () => {
    setIsResent(false);
    Auth.forgotPassword(email?.toLocaleLowerCase() || "")
      .then((res) => {
        setIsResent(true);
      })
      .catch((err) => {
        const code = err.code;
        switch (code) {
          case "LimitExceededException":
            setIsResent(false);
            const errorCopy = { ...error };
            errorCopy.newPasscode =
              "Attempt limit exceeded, please try after some time.";
            return setError(errorCopy);
          default:
            setIsResent(false);
            return false;
        }
      });
  };
  return (
    <PageBody>
      <FormTitle>{t("verifyCodePage.title")}</FormTitle>
      <p className="content-text">{t("verifyCodePage.enterSentence")}</p>
      <Email>{email}</Email>
      {isresent && (
        <Message
          message={t("verifyCodePage.resentMessage")}
          type="success"
        ></Message>
      )}
      <FormGroup className="mt-3" id="passcode">
        <FormLabel>{t("verifyCodePage.passcode")}</FormLabel>
        <Form.Control
          value={passcode}
          placeholder="Passcode"
          required
          onChange={handleInputPasscode}
          className={
            "input-no-bg " + (submited && error.passcode ? "is-invalid" : "")
          }
        />
        {submited && error.passcode && (
          <Message message={error.passcode} type="error"></Message>
        )}
        {error.newPasscode && (
          <Message message={error.newPasscode} type="error"></Message>
        )}
      </FormGroup>

      <NotAccount>
        <TextButton onClick={resendPasscode}>
          {t("verifyCodePage.btn.resend")}{" "}
        </TextButton>
      </NotAccount>

      <Button className="w-100" variant="primary" onClick={handleSubmit} >
        {t("verifyCodePage.btn.reEnter")}
      </Button>

    </PageBody>
  );
};

export default VerifyCodePage;

const PageBody = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2rem;
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

const FormTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-text-default);
  font-family: "Montserrat", sans-serif;
`;

const FormGroup = styled.div``;
const FormLabel = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
`;

const TextButton = styled.span`
  text-align: center;
  cursor: pointer;
`;


const NotAccount = styled.div`
  text-align: end;
  margin-bottom: 3rem;
  text-decoration: underline;
  color: #1589D2;
`;

const Email = styled.label`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;
