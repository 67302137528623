import gql from "graphql-tag";

export const GetTaxReturnBusinessEntities = gql`
  query GetTaxReturnBusinessEntities($ProfileId: ID!, $EnrollmentId: ID!) {
    GetTaxReturnBusinessEntities(EnrollmentId: $EnrollmentId) {
      QuarterStateReturns {
        Id
        PayrollTaxFormId
        QuarterCount
        Year
      }
      TaxPrepBusinessEntity {
        CreatedAt
        FirstYear
        Id
        Name
        ProfileId
        UpdatedAt
      }
      TaxPrepIncomeTaxForms {
        CreatedAt
        Form
        Id
        PartnersCount
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxPrepPayrollTaxForms {
        CreatedAt
        Form
        Id
        StateReturn
        TaxReturnBusinessEntityId
        UpdatedAt
      }
      TaxReturnBusinessEntity {
        BusinessEntityId
        CreatedAt
        GrossIncome
        Id
        TaxReturnId
        UpdatedAt
        Year
      }
    }
  }
`;
