import React, { useContext, useEffect, useState } from "react";
import "../../pages/navbar.css";
import { faArrowDown, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppProgressBar from "../../Common/progressBar";
import { clientConfig, SetS3Config } from "../../awsClientConfig";
import { Storage } from "aws-amplify";
import { CompleteProfileForm } from "../../queries/CompleteProfileForm";
import { userProfileData } from "../../Common/profileInfo";
import { useNavigate } from "react-router-dom";
import Loading from "../../Common/Loading";
import OrganicClientFormContext from "./OrganicClientFormContext";
import { AnimatePresence, motion } from "framer-motion";
import { motionContainer } from "./index";

function TaxInformatioAuthorization({ progressPercent = 0 }) {
  const { next, nextFormId, enrollmentId, s3key, s3Bucket } =
    useContext(OrganicClientFormContext);
  const [irsForm, setIRSForm] = useState("");
  const client = new clientConfig();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchIRSForm();
  }, []);

  const fetchIRSForm = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const irsForm = await Storage.get(s3key, {
        customPrefix: { public: "" },
        level: "public",
        contentType: "image/png",
      });
      setIRSForm(irsForm);
    } catch (error) {
      console.log("error", error);
    }
  };

  const printImage = () => {
    window.print();
  };
  const download = async () => {
    try {
      SetS3Config(s3Bucket, "public");
      const Agreement = await Storage.get(s3key, {
        customPrefix: { public: "" },
        download: true,
        level: "public",
        contentType: "image/png",
      });
      console.log(Agreement);
      downloadBlob(Agreement.Body, "Form 8821");
    } catch (error) {
      console.log("error", error);
    }
  };

  function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  const submitAuth = async () => {
    setSpinner(true);
    const profileId = await userProfileData();
    const variables = {
      ProfileId: profileId,
      FormId: nextFormId,
      EnrollmentId: enrollmentId,
    };

    client
      .mutate({ mutation: CompleteProfileForm, variables: variables })
      .then((res: any) => {
        if (res.data.CompleteProfileForm) {
          setSpinner(false);
          next();
        }
      })
      .catch((error: any) => {
        setSpinner(false);
      });
  };

  return (
    <AnimatePresence mode="wait">
      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xl-10 col-lg-10  content-box py-5  px-lg-5">
            <motion.div key={spinner.toString()} {...motionContainer}>
            {spinner ? (
                <Loading />
            ) : (
              <div className="mx-lg-5">
                <AppProgressBar progressPercent={progressPercent} />
                <h1 className="content-box-heading">
                  Tax Information Authorization (Form 8821)
                </h1>
                <div className="text-end">
                  <span className="letsdolater" onClick={printImage}>
                    <FontAwesomeIcon className="" icon={faPrint} /> Print
                  </span>
                  <span
                    style={{ marginLeft: "10px" }}
                    className="letsdolater"
                    onClick={download}
                  >
                    <FontAwesomeIcon className="" icon={faArrowDown} /> Download
                  </span>
                </div>
                <div>
                  <img
                    className="section-to-print"
                    id={"irsForm"}
                    src={irsForm}
                    height="100%"
                    width="100%"
                    alt="Form**21"
                  />
                </div>

                <div className="d-flex justify-content-between mt-5 align-items-center">
                  <span
                    className="letsdolater"
                    onClick={() => navigate("/dashboard")}
                  >
                    Lets do this later...
                  </span>
                  <button
                    onClick={submitAuth}
                    className="btn-continue"
                    disabled={spinner}
                  >
                    Click to Sign and Date
                  </button>
                </div>
              </div>
            )}
            </motion.div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
}

export default TaxInformatioAuthorization;
