import gql from "graphql-tag";

export const GetTaxReturn = gql`
  query GetTaxReturn($ProfileId: ID!, $EnrollmentId: ID!) {
    GetTaxReturn(ProfileId: $ProfileId, EnrollmentId: $EnrollmentId) {
        TaxPrepFilingType {
            CreatedAt
            Id
            Status
            TaxReturnId
            Type
            UpdatedAt
        }
        TaxPrepQuote {
            Active
            CreatedAt
            EnrollmentId
            FinalPrice
            Id
            QuotePrice
            Type
            UpdatedAt
            Year
        }
        TaxReturn {
            Active
            CancelledAt
            CompletedAt
            EntityId
            CreatedAt
            EntityId
            EntityType
            Id
            InitiatedAt
            Price
            ProfileId
            QuoteId
            Status
            TaxProfessionalId
            TaxProfessionalProvider
            UpdatedAt
            Year
        }
    }
  }
`;
